interface Props {
  color?: string
  width?: string
  height?: string
  strokeWidth?: string
}

const CheckSVG = ({ color = '#00112F', width = '7', height = '7', strokeWidth = '1' }: Props): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.539062 3.92323L2.23137 5.61553L6.03906 1.38477"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckSVG
