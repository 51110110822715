import { Chart, ChartData, ChartDataset, ChartOptions, registerables } from 'chart.js'
import { cloneDeep, isEmpty, isEqual, sortBy, xorWith } from 'lodash'
import { createRef, useEffect, useState } from 'react'

import customTeamLabelPlugin from '../../../utils/chartPlugins/customTeamLabelPlugin'
import { chartDefaultOptions } from '../../../utils/chartUtils'

export interface ChartDatasetType {
  reviewName: string
  jobTitle: string
  skippedReview?: boolean
  displayed: boolean
  values: number[]
  color: string
  backgroundColor?: string
  displayLabelValue: boolean
}

interface ChartRadarPropsType {
  datasets: ChartDatasetType[]
  labels: string[]
  minZoom?: number
  maxZoom?: number
  stepSize?: number
}

const TeamSummaryChartRadar = (props: ChartRadarPropsType): JSX.Element => {
  const { datasets, labels, minZoom = 0, maxZoom = 100, stepSize = 25 } = props
  const [chart, setChart] = useState<Chart<'radar'>>()
  const ref = createRef<HTMLCanvasElement>()
  const [previousProps, setPreviousProps] = useState<ChartRadarPropsType>()

  const displayedDatasetsFrom = (datasets: ChartDatasetType[]): ChartDatasetType[] => {
    return datasets.filter(review => review.displayed === true)
  }

  const formatDatasets = (datasets: ChartDatasetType[]): ChartDatasetType[] => {
    const result = displayedDatasetsFrom(datasets)
    return result
  }

  const getDatasets = (): ChartDataset<'radar'>[] => {
    return formatDatasets(datasets).map(d => {
      if (d.values.length > 0) {
        return {
          label: d.reviewName,
          data: d.values,
          fill: d.displayLabelValue,
          borderWidth: d.displayLabelValue ? '0.5' : '2',
          borderColor: d.color,
          backgroundColor: d.backgroundColor,
        } as unknown as ChartDataset<'radar'>
      }

      throw new Error('There is no data to show in the chart')
    })
  }

  const getData = (): ChartData<'radar'> => {
    return {
      labels: labels,
      datasets: getDatasets().reverse(),
    }
  }

  const getOptions = (): ChartOptions<'radar'> => {
    const options = JSON.parse(JSON.stringify(chartDefaultOptions))
    return options
  }

  const initializeChart = (): void => {
    if (!ref.current) return
    Chart.register(...registerables)

    chart?.destroy()
    const newChart = new Chart(ref.current, {
      type: 'radar',
      data: getData(),
      options: getOptions(),
      plugins: [customTeamLabelPlugin],
    })
    setChart(newChart)
  }

  const updateChart = (): void => {
    if (!chart?.options?.scales?.['r']?.ticks) return
    chart.options.scales.r.min = minZoom
    chart.options.scales.r.max = maxZoom
    chart.options.scales.r.ticks.stepSize = stepSize
    chart.data = getData()
    chart.update()
  }

  // This method may remind you of React.memo. And the truth is that I've also
  // tried recreating the same behaviour using It but with no success. For some reason
  // the previous props you get by using the CHECK_FUNCTION from React.memo(component, CHECK_FUNCTION)
  // will always be the same as the next props.
  const canUpdateChart = (): boolean => {
    if (!previousProps) return false
    const sameLabels = isEqual(sortBy(previousProps.labels), sortBy(props.labels))
    const sameDatasets = isEmpty(xorWith(previousProps.datasets, props.datasets, isEqual))
    const sameMinZoom = isEqual(previousProps.minZoom, props.minZoom)
    const sameMaxZoom = isEqual(previousProps.maxZoom, props.maxZoom)
    const sameStepSize = isEqual(previousProps.stepSize, props.stepSize)
    return !(sameDatasets && sameLabels && sameMinZoom && sameMaxZoom && sameStepSize)
  }

  useEffect(() => {
    initializeChart()
    setPreviousProps(cloneDeep(props))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!canUpdateChart()) return
    updateChart()
    setPreviousProps(cloneDeep(props))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasets])

  return (
    <div className="chart-radar-container max-h-[45rem]">
      <canvas ref={ref} className="chart-radar" />
    </div>
  )
}

export default TeamSummaryChartRadar
