import { useState } from 'react'
import { ChartDatasetType } from '../../atoms/Charts/TeamSummaryChartRadar'
import TeamToggleWithText from '../../atoms/ToggleWithText/TeamToggleWithText'

type ChartHeaderHeaderProps = {
  teamMembersReviews: ChartDatasetType[]
  onChange: (selectedReviewName: string) => void
  display: (display: boolean) => void
}

const TeamSummaryResultsChartHeader = ({
  teamMembersReviews,
  onChange,
  display,
}: ChartHeaderHeaderProps): JSX.Element => {
  const [displayAll, setDisplayAll] = useState<boolean>(false)

  const membersWithReviews = teamMembersReviews.filter(review => review.values.length > 0)
  const membersWithoutReviews = teamMembersReviews.filter(review => review.values.length <= 0)
  const membersReviews = [...membersWithReviews, ...membersWithoutReviews]

  const isChartAdviceVisible = teamMembersReviews.some(review => review.displayed)

  return (
    <div>
      <div className="grid grid-cols-2 gap-y-6 sm:grid-cols-1">
        {membersReviews?.map((review, index) => (
          <div key={index}>
            {index === 1 && (
              <div
                className="text-blue text-xl font-normal mb-6 cursor-pointer"
                key={`selector-${index}`}
                onClick={() => {
                  const newDisplay = !displayAll
                  setDisplayAll(newDisplay)
                  display(newDisplay)
                }}
              >
                Select all / none
              </div>
            )}
            <div className="flex items-center gap-3">
              <TeamToggleWithText
                skipped={review.skippedReview ?? false}
                color={review.color}
                checked={review.displayed}
                text={review.reviewName}
                subText={review.jobTitle}
                disabled={review.values.length === 0}
                onChange={() => onChange(review.reviewName)}
              />
            </div>
          </div>
        ))}
      </div>
      {isChartAdviceVisible && (
        <div className="text-toast-hover text-lg leading-6 mt-24 mb-20">
          We can’t compare apples to oranges. <b>This graph shows the overall team performance</b>, but different forms
          have been used depending on the role of each person. Comparisons between two different roles may not be
          totally accurate, particularly with Professional Skills.
        </div>
      )}
    </div>
  )
}

export default TeamSummaryResultsChartHeader
