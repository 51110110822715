import { FormEvent, useEffect, useState } from 'react'
import Validator from 'validator'

import LogoSVG from '../../assets/svgs/Logo.svg'
import { AppButton } from '../atoms/AppButton/AppButton'
import TextLine from '../atoms/Forms/TextLine'
import { useSelector } from 'react-redux'
import { AuthState, login } from '../../slices/auth-slice'
import { isFailure, isLoading } from '../../types/definitions/app-state'
import { RootState, useAppDispatch } from '../..'
import { AppInputPassword } from '../atoms/AppInput/AppInputPassword'
import WarningIconFilled from '../atoms/Icons/WarningIconFilled.svg'
import { classNames } from 'primereact/utils'

const LoginPage = (): JSX.Element => {
  const state = useSelector<RootState, AuthState>(state => state.auth)
  const dispatch = useAppDispatch()

  const [emailValidation, setEmailValidation] = useState<boolean>(true)
  const [passwordValidation, setPasswordValidation] = useState<boolean>(true)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [helperText, setHelperText] = useState<string>('')
  const [isEmailError, setIsEmailError] = useState<boolean>(false)
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(isLoading(state.status))
    const failure = isFailure(state.status)
    setIsEmailError(failure)
    setIsPasswordError(failure)
    if (failure) {
      setHelperText('Incorrect email address or password')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    // TODO: Improve this error management
    if (!email) {
      setIsEmailError(false)
    }
    if (!password) {
      setIsPasswordError(false)
    }
    if (!email || !password) {
      setHelperText('')
    }
  }, [email, password])

  const handleSignIn = (event: FormEvent): void => {
    event.preventDefault()
    if (emailValidation && passwordValidation) {
      dispatch(login({ email, password }))
    }
  }

  const checkEmail = (): void => {
    if (!Validator.isEmail(email)) {
      setIsEmailError(true)
    }
  }

  return (
    <div
      className={classNames({
        'flex-1 container flex justify-center bg-white': true,
      })}
    >
      <div className="flex flex-col justify-center w-176">
        <div className="w-full">
          <h1 hidden>Grapes | Your assessment tool</h1>
          <div className="pb-8 mb-12">
            <LogoSVG />
          </div>
          <p className="mb-8 lg:mb-16 text-5xl text-normal text-dark-blue">Talent Assessments</p>
        </div>
        <form onSubmit={handleSignIn}>
          <div className="flex flex-col pb-6">
            <TextLine
              className={`text-border${!isEmailError ? ' border-blue ' : ''} max-w-full mb-0 ${
                isEmailError ? ' border-red-button' : ''
              }`}
              type={'email'}
              autoComplete={'email'}
              value={email}
              onChange={value => setEmail(value)}
              setValidation={setEmailValidation}
              placeholder="Email"
            />
            <AppInputPassword
              autoComplete={'password'}
              value={password}
              onChange={value => setPassword(value)}
              setValidation={setPasswordValidation}
              placeholder="Password"
              isPasswordError={isPasswordError}
            />
            <div
              className={classNames({
                'flex items-center py-6 px-10 font-bold gap-3 w-full bg-red-button bg-opacity-10 rounded-md mt-5': true,
                'hidden ': !isPasswordError,
              })}
            >
              <WarningIconFilled />
              <p className="text-error-red text-2xl">{helperText}</p>
            </div>
          </div>
          <div className="button-container">
            <AppButton className="w-full" onClick={checkEmail} disabled={!email || !password || loading}>
              Log in
            </AppButton>
          </div>
        </form>
      </div>
    </div>
  )
}
export default LoginPage
