import { DataTable, DataTableRowClickEvent } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { EmployeeTableHeader } from '../molecules/EmployeeTable/EmployeeTableHeader'
import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import { TeamInfo, TeamService, TeamStatus } from '../../services/TeamService'
import { TeamIssuesTableCellBody } from '../molecules/TeamIssues/TeamIssuesTableCellBody'
import { TeamIssuesIssuesTableCellBody } from '../molecules/TeamIssues/TeamIssuesIssuesTableCellBody'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { CellText } from '../molecules/TeamIssues/CellText'
import { CellLink } from '../molecules/TeamIssues/CellLink'
import { useSelector } from 'react-redux'
import { RootState } from '../..'
import { Employee } from '../../services/EmployeeService'
import PencilSVG from '../atoms/Icons/Pencil.svg'
import { useState } from 'react'
import EditTeamName from '../molecules/EditTeamName/EditTeamName'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../services/EventTrackingService'
import { TeamSortType } from '../../utils/sortUtils'
import { getTeamStatusesFlags } from '../../utils/teams/teamsUtils'
import { classNames } from 'primereact/utils'
import { getHoverEffectClassNames } from '../../utils/hoverColorUtils'

interface TeamIssuesTableParams {
  data: TeamStatus[] | undefined
  selectedSort?: { property: TeamSortType; order: SortOrderEnum }
  handleOnRowClicked?: (row: TeamStatus) => void
  onSortSelected?: (field: TeamSortType, newSortOrder: SortOrderEnum) => void
  onUpdateTeamName: (newTeamStatus: TeamStatus) => void
}

const renderTeamNameBody = (onUpdateTeamName: (newTeamStatus: TeamStatus) => void) => {
  return (teamStatus: TeamStatus): JSX.Element => {
    const [isEditingTeamName, setIsEditingTeamName] = useState<boolean>(false)

    const handleEditTeamName = () => {
      trackEvent(TrackingCategoryEnum.TEAM_ISSUES, TrackingCategoryEventEnum.TEAM_ISSUES.CLICK_EDIT_TEAM_NAME)
      setIsEditingTeamName(!isEditingTeamName)
    }

    const handleSaveTeamNameChanged = async (teamId: number, newTeamName: string) => {
      const newTeamInfo: TeamStatus = { ...teamStatus, name: newTeamName }
      try {
        await TeamService.updateTeamName(teamId, newTeamName)
        onUpdateTeamName(newTeamInfo)
      } catch (error) {
        console.error(error)
      }
    }

    const teamName = teamStatus.name || 'No name'

    const currentUser = useSelector<RootState, Employee>(state => state.auth.user as Employee)
    const userCanEdit =
      teamStatus.leaders !== undefined ? Array.from(teamStatus.leaders.values()).includes(currentUser.name) : false

    const summaryLink = createPathWithLang(
      NetworkConstants.URL_TEAM_SUMMARY.replace(':teamId', teamStatus?.id?.toString() || '')
    )

    const handleClickOnTeam = () => {
      trackEvent(TrackingCategoryEnum.TEAM_ISSUES, TrackingCategoryEventEnum.TEAM_ISSUES.CLICK_TEAM_LINK, '')
    }

    const showLink = teamStatus?.finished && teamStatus?.finished > 0

    const { hasReviews } = getTeamStatusesFlags(teamStatus)

    const teamNameStyles = {
      'font-bold': true,
      'text-stone-gray': !hasReviews,
      'text-dark-blue': hasReviews,
    }

    if (!userCanEdit) return <div className={classNames({ teamNameStyles })}>{teamName}</div>

    if (isEditingTeamName)
      return (
        <EditTeamName
          team={{ id: teamStatus.id, name: teamStatus.name } as TeamInfo}
          onIsEditTeamName={handleEditTeamName}
          onSaveTeamNameChanged={handleSaveTeamNameChanged}
        />
      )

    if (showLink)
      return (
        <div className="">
          <CellLink
            link={summaryLink}
            text={teamName}
            textTooltip="Click to navigate to team’s report"
            tooltip
            textClassname={classNames(getHoverEffectClassNames(teamStatus), teamNameStyles)}
            iconClassname={classNames('clickable-hover-effect', getHoverEffectClassNames(teamStatus))}
            iconRightPosition
            onClick={handleClickOnTeam}
            onClickIcon={handleEditTeamName}
            icon={<PencilSVG color="black" width={14} height={14} />}
          />
        </div>
      )

    return (
      <div className="flex">
        <CellText
          text={teamName}
          icon={<PencilSVG color="black" width={14} height={14} />}
          textClassname={classNames(teamNameStyles)}
          iconClassname={classNames('clickable-hover-effect', getHoverEffectClassNames(teamStatus))}
          onClickIcon={handleEditTeamName}
        />
      </div>
    )
  }
}

export const TeamIssuesTable = ({
  data,
  selectedSort,
  handleOnRowClicked,
  onSortSelected,
  onUpdateTeamName,
}: TeamIssuesTableParams): JSX.Element => {
  // Current user
  const currentUser = useSelector<RootState, Employee>(state => state.auth.user as Employee)

  const handleRowSelected = (teamStatus: TeamStatus): void => {
    handleOnRowClicked && handleOnRowClicked(teamStatus)
  }

  const handleSortSelected = (field: TeamSortType) => (newSortOrder: SortOrderEnum) => {
    onSortSelected?.(field, newSortOrder)
  }

  const onRowClick = (e: DataTableRowClickEvent) => {
    handleRowSelected(e.data as TeamStatus)
  }

  const rowClassNames = (row: TeamStatus) => {
    const {
      hasError,
      hasAlert,
      hasReviews,
      finishedReviewsWithoutErrorsAndAlerts,
      missesReviewsWithoutErrorsAndAlerts,
    } = getTeamStatusesFlags(row)

    return {
      'rounded-xl cursor-pointer': true,
      'bg-peach bg-opacity-[20%]': hasError,
      'bg-pale-orange bg-opacity-[20%]': hasAlert,
      'bg-pale-green bg-opacity-[20%]': finishedReviewsWithoutErrorsAndAlerts,
      'bg-pearl-gray': missesReviewsWithoutErrorsAndAlerts,
    }
  }

  const textColor = (row: TeamStatus) => {
    const { hasReviews } = getTeamStatusesFlags(row)

    return hasReviews ? 'text-dark-blue' : 'text-stone-gray'
  }

  return (
    <DataTable
      className="app-table-container"
      tableClassName="app-table table-fixed"
      rowClassName={rowClassNames}
      onRowClick={onRowClick}
      value={data}
      dataKey="id"
    >
      <Column
        field="name"
        bodyClassName="truncate"
        header={EmployeeTableHeader({
          title: 'Name',
          sortable: selectedSort?.property === 'name' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('name'),
        })}
        className="flex-[2] px-6"
        body={renderTeamNameBody(onUpdateTeamName)}
      />
      <Column
        header={EmployeeTableHeader({
          title: 'Leader',
          sortable: selectedSort?.property === 'leaders' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('leaders'),
        })}
        field="leaders"
        className={`flex-[2] px-5`}
        bodyClassName={classNames('truncate', { 'text-stone-gray': !currentUser.name })}
        body={TeamIssuesTableCellBody({
          getValue: (row: TeamStatus) => {
            const leaders = Array.from(row.leaders?.values() || [])
            leaders.sort((a, b) => {
              // Current user it is the 1º element
              if (a === currentUser.name) return -1
              if (b === currentUser.name) return 1
              if (a > b) return 1
              if (a < b) return -1
              return 0
            })
            return <div className={textColor(row)}>{leaders.join(', ')}</div>
          },
          tooltip: true,
        })}
      />

      <Column
        header={EmployeeTableHeader({
          title: 'Progress',
          sortable: selectedSort?.property === 'progress' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('progress'),
        })}
        field="progress"
        className="flex-[1] px-5"
        body={(row: TeamStatus) => {
          if (row.finished !== undefined && row.teamSize !== undefined) {
            return (
              <span className={textColor(row)}>
                {row.finished}/{row.teamSize} · {Math.round((row.finished / row.teamSize) * 100)}%
              </span>
            )
          }
          return <span>-</span>
        }}
      />

      <Column
        header={EmployeeTableHeader({
          title: 'Score',
          sortable: selectedSort?.property === 'score' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('score'),
        })}
        field="score"
        className="flex-[1] px-5"
        body={(row: TeamStatus) => {
          if (row.score) {
            return <span className="font-bold">{row.score}</span>
          }
          return <span>-</span>
        }}
      />
      <Column
        header={EmployeeTableHeader({
          title: 'Issues',
          sortable: selectedSort?.property === 'issues' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('issues'),
        })}
        className="flex-[5]"
        headerClassName="flex-[5]"
        field="issues"
        body={TeamIssuesIssuesTableCellBody()}
      />
    </DataTable>
  )
}
