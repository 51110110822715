import { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react'
import Validator from 'validator'
import ClearIconSVG from './Icons/ClearIcon'
import classNames from 'classnames'
import SearchIcon from './Icons/SearchIcon'

interface TextLineProps {
  className?: string
  required?: boolean
  type?: string
  placeholder?: string
  searchIcon?: boolean
  label?: string
  value: string
  disabled?: boolean
  warningText?: string
  onChange: (inputValue: string) => void
  onSubmit?: (inputValue: string) => void
  setValidation?: (isValid: boolean) => void
  onClear?: () => void
}

const TextLine = (props: TextLineProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null)
  const maxLength = 240

  const isValid = (): boolean => {
    if (inputRef.current === null) {
      return false
    }
    const value = inputRef.current.value
    return !Validator.isEmpty(value) && Validator.isLength(value, { max: maxLength })
  }

  const onChange = (_: ChangeEvent<HTMLInputElement>): void => {
    if (inputRef.current != null) {
      props.onChange(inputRef.current.value)
    }
  }

  const onKeyUp = (keyPressed: KeyboardEvent): void => {
    if (keyPressed.key === 'Enter' && inputRef.current != null) {
      props.onSubmit?.(inputRef.current.value)
    }
  }

  useEffect(() => {
    props.setValidation?.(isValid())
  })

  return (
    <div className="input-content relative">
      {props.label && (
        <span
          className={classNames({
            label: true,
            'font-bold': props.required,
            'text-dark-blue': true,
          })}
        >
          {props.required ? '*' : ''}
          {props.label}
        </span>
      )}
      <input
        className={`${props.className ?? 'textline'} ${props.warningText ? 'error' : ''}`}
        type={props.type ?? 'text'}
        ref={inputRef}
        value={props.value}
        placeholder={props.placeholder}
        onChange={onChange}
        onKeyUp={onKeyUp}
        maxLength={maxLength}
        disabled={props.disabled}
      />
      {props.value && props.onClear ? (
        <button className="absolute mt-4 right-4" onClick={props.onClear}>
          <ClearIconSVG />
        </button>
      ) : (
        <div className="absolute mt-4 right-4">{props.searchIcon && <SearchIcon />}</div>
      )}
      {props.warningText ? (
        <span>
          <p className="warning-text">{props.warningText}</p>
        </span>
      ) : (
        <p className="warning-text">
          <br />
        </p>
      )}
    </div>
  )
}

export default TextLine
