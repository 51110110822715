import { FC, ReactElement } from 'react'
import { useDrag } from 'react-dnd'

import { ItemTypes } from '../../constants/drag-n-drop'
import { DropType } from '../atoms/DropArea'

interface ChartNodeProps {
  nodeId: number
  teamId: string
  name: string
}

export interface DropResult {
  nodeId: number
  teamId: number
  name: string
  type: DropType
}

export const DraggableNode: FC<ChartNodeProps> = ({ nodeId, name, teamId, children }): ReactElement => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.TEAM_NODE,
    item: { nodeId, name, teamId },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>()
      if (item && dropResult && dropResult.name) {
        alert(`You've ${dropResult.type} ${item.name} into ${dropResult.name}!`)
      } else if (item && dropResult) {
        // TODO invoke modal
      }
    },
    canDrag: () => false, // TODO remove when d&d is active
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  const opacity = isDragging ? 0.4 : 1

  return (
    <div className="draggable-node" ref={drag} style={{ opacity }}>
      {children}
    </div>
  )
}
