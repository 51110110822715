import React, { FC } from 'react'

interface NotificationIconProps {
  color?: string
  filledColor?: string
  width?: number
  height?: number
}

export const NotificationIcon: FC<NotificationIconProps> = ({
  color = '#FECD59',
  width = 60,
  height = 60,
  filledColor,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill={filledColor} />
      <path
        d="M10.0391 18.6191H18.8633C19.1367 18.6191 19.3516 18.5527 19.5078 18.4199C19.6641 18.2871 19.7422 18.1133 19.7422 17.8984C19.7422 17.6133 19.6309 17.3438 19.4082 17.0898C19.1855 16.832 18.9512 16.582 18.7051 16.3398C18.5801 16.2109 18.4824 16.0449 18.4121 15.8418C18.3418 15.6387 18.2891 15.4199 18.2539 15.1855C18.2188 14.9473 18.1934 14.7129 18.1777 14.4824C18.1543 13.791 18.0684 13.1719 17.9199 12.625C17.7754 12.0781 17.5527 11.6191 17.252 11.248C16.9551 10.877 16.5645 10.6016 16.0801 10.4219C15.9707 10.0625 15.7734 9.75781 15.4883 9.50781C15.207 9.25781 14.8613 9.13281 14.4512 9.13281C14.041 9.13281 13.6953 9.25781 13.4141 9.50781C13.1328 9.75781 12.9375 10.0625 12.8281 10.4219C12.3438 10.6016 11.9512 10.877 11.6504 11.248C11.3496 11.6191 11.125 12.0781 10.9766 12.625C10.832 13.1719 10.75 13.791 10.7305 14.4824C10.7109 14.7129 10.6836 14.9473 10.6484 15.1855C10.6172 15.4199 10.5664 15.6387 10.4961 15.8418C10.4258 16.0449 10.3281 16.2109 10.2031 16.3398C9.95703 16.582 9.72266 16.832 9.5 17.0898C9.27734 17.3438 9.16602 17.6133 9.16602 17.8984C9.16602 18.1133 9.24414 18.2871 9.40039 18.4199C9.55664 18.5527 9.76953 18.6191 10.0391 18.6191ZM14.4512 20.8691C14.7715 20.8691 15.0586 20.7988 15.3125 20.6582C15.5664 20.5215 15.7695 20.3398 15.9219 20.1133C16.0742 19.8906 16.1621 19.6465 16.1855 19.3809H12.7227C12.7461 19.6465 12.834 19.8906 12.9863 20.1133C13.1387 20.3398 13.3418 20.5215 13.5957 20.6582C13.8496 20.7988 14.1348 20.8691 14.4512 20.8691Z"
        fill={color}
      />
    </svg>
  )
}
