import { FormApi } from '../api'
import { FormResponse, FormCategoryResponse } from '../api/openapi'
import { Form } from '../types/definitions/form'
import { FormCategory } from '../types/definitions/formCategory'
import { QuestionService } from './QuestionService'

export class FormService {
  static findAll(tag?: string): Promise<Form[]> {
    return FormApi.findUsingGET3(tag).then(response => {
      return (response.data || []).map(formResponse => this.formResponseToForm(formResponse))
    })
  }

  static findAllByCategory(tag?: string): Promise<FormCategory[]> {
    return FormApi.findByCategoryUsingGET(tag).then(response => {
      return (response.data || []).map(formCategoryResponse =>
        this.formCategoryResponseToFormCategory(formCategoryResponse)
      )
    })
  }

  static findById(id: number): Promise<Form> {
    return FormApi.getByIdUsingGET1(id).then(response => {
      const result = response.data as Form
      result.questions = response.data.questions?.map(questionResponse =>
        QuestionService.questionResponseToQuestion(questionResponse)
      )
      return result
    })
  }

  static formResponseToForm(formResponse: FormResponse): Form {
    return {
      ...formResponse,
    } as Form
  }

  static formCategoryResponseToFormCategory(formCategoryResponse: FormCategoryResponse): FormCategory {
    return {
      ...formCategoryResponse,
    } as FormCategory
  }
}
