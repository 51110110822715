import { FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { NetworkConstants } from '../../utils/NetworkConstants'
import { AppButton } from '../atoms/AppButton/AppButton'
import { AuthService } from '../../services/AuthService'
import { AppInputPassword } from '../atoms/AppInput/AppInputPassword'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../services/EventTrackingService'

const ChangePasswordPage = (): JSX.Element => {
  const history = useHistory()
  const [passwordValidation, setPasswordValidation] = useState<boolean>(true)
  const [passwordConfirmValidation, setPasswordConfirmValidation] = useState<boolean>(true)
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [helperText, setHelperText] = useState<string>('')
  const [errors, setErrors] = useState<boolean>(false)
  const [isCurrentPasswordError, setIsCurrentPasswordError] = useState<boolean>(false)
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false)
  const [isPasswordConfirmError, setIsPasswordConfirmError] = useState<boolean>(false)

  useEffect(() => {
    // TODO: Improve this error management
    if (!currentPassword) {
      setIsCurrentPasswordError(false)
    }
    if (!password) {
      setIsPasswordError(false)
    }
    if (!passwordConfirm) {
      setIsPasswordConfirmError(false)
    }
    if (!currentPassword && !password && !passwordConfirm) {
      setHelperText('')
    }
  }, [currentPassword, password, passwordConfirm])

  const handleSetPassword = (event: FormEvent): void => {
    event.preventDefault()
    if (passwordValidation && passwordConfirmValidation && !errors) {
      AuthService.changePassword(currentPassword, password)
        .then(_ => history.push(NetworkConstants.URL_HOME_PATH))
        .catch(_ => {
          setHelperText('Current password is not valid')
          setIsCurrentPasswordError(true)
        })
    }
  }

  const checkPasswords = (): void => {
    if (currentPassword === password) {
      setHelperText('New password must be different')
      setIsCurrentPasswordError(true)
      setIsPasswordError(true)
      setErrors(true)
      trackEvent(
        TrackingCategoryEnum.AUTHENTICATION,
        TrackingCategoryEventEnum.AUTHENTICATION.USER_CHANGE_PASSWORD_ERROR,
        JSON.stringify({ details: 'New password equals old password' })
      )
      return
    }
    if (password !== passwordConfirm) {
      setHelperText('Passwords do not match')
      setIsPasswordError(true)
      setIsPasswordConfirmError(true)
      setErrors(true)
      trackEvent(
        TrackingCategoryEnum.AUTHENTICATION,
        TrackingCategoryEventEnum.AUTHENTICATION.USER_CHANGE_PASSWORD_ERROR,
        JSON.stringify({ details: 'Password and repeat password do not match' })
      )
      return
    }
    setErrors(false)
  }

  return (
    <div className="flex-1 container flex justify-center bg-white " onSubmit={handleSetPassword}>
      <form className="flex flex-col justify-center w-160">
        <div className="flex flex-col gap-5 mt-8 mb-3">
          <p className="text-5xl text-normal text-dark-blue mb-14">Welcome to Grapes!</p>
          <p className="text-5xl text-normal text-dark-blue font-bold">Before you start:</p>
        </div>
        <h3 className="text-3xl leading-10 mb-14">
          Please <span className="font-bold">create a custom password</span> to secure your account.
        </h3>
        <div className="flex flex-col gap-4">
          <AppInputPassword
            autoComplete={'password'}
            value={currentPassword}
            onChange={value => setCurrentPassword(value)}
            label="Current password"
            placeholder="Write current password"
            isPasswordError={isPasswordError}
          />
          <AppInputPassword
            autoComplete={'password'}
            value={password}
            onChange={value => setPassword(value)}
            setValidation={setPasswordValidation}
            label="New password"
            placeholder="Write new password"
            isPasswordError={isPasswordError}
          />
          <AppInputPassword
            autoComplete={'password'}
            value={passwordConfirm}
            onChange={value => setPasswordConfirm(value)}
            setValidation={setPasswordConfirmValidation}
            label="Confirm new password"
            placeholder="Write new password again"
            isPasswordError={isPasswordError}
          />
          <p className="text-peach text-3xl">{helperText}</p>
        </div>
        <div className="button-container mt-4">
          <AppButton onClick={checkPasswords} type="info" disabled={!currentPassword || !password || !passwordConfirm}>
            Confirm
          </AppButton>
        </div>
      </form>
    </div>
  )
}
export default ChangePasswordPage
