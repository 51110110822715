import { FC, useEffect, useState } from 'react'
import { AnswerT } from '../../../../types/definitions/answer'
import { Attachment, Question, QuestionTopic } from '../../../../types/definitions/question'
import { QuestionTypeEnum } from '../../../../types/enums/question-type.enum'
import Dot from '../../../atoms/Dot'

export interface MultiSelectQuestionProps {
  title: string
  options: string[]
  selectedOptions: string[]
  required?: boolean
  currentAnswers?: Record<string, AnswerT>
  currentTopic?: QuestionTopic
  questions?: Record<string, Question[]>
  onChange?: (value: string[]) => void
}

type ProcessedQuestion = {
  id: number
  type: QuestionTypeEnum
  topic?: QuestionTopic
  label: string
  title: string
  answer: string | number | true | string[] | Attachment
}

export const MultiSelectQuestion: FC<MultiSelectQuestionProps> = ({
  title,
  options,
  selectedOptions = [],
  required = true,
  currentAnswers,
  currentTopic,
  questions,
  onChange,
}) => {
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<string[]>([])
  const [answeredQuestions, setAnsweredQuestions] = useState<ProcessedQuestion[]>([])

  useEffect(() => {
    setCurrentSelectedOptions([...selectedOptions])
  }, [selectedOptions])

  useEffect(() => {
    if (questions && currentAnswers) {
      const ans_list: ProcessedQuestion[] = []
      // Gets all the questions to find the label, type, title and topic
      Object.entries(questions).forEach(([, list]) => {
        // Finds the question of each answered question
        Object.entries(currentAnswers).forEach(([, answer]) => {
          if (answer.answer) {
            // Checks if the question exists
            const question = list.find(item => item.id === answer.questionId)
            if (question) {
              const ans: ProcessedQuestion = {
                id: answer.questionId,
                answer: answer.answer,
                type: question.type,
                label: question.label,
                title: question.title,
                topic: question.topic,
              }
              ans_list.push(ans)
            }
          }
        })
      })
      setAnsweredQuestions(ans_list)
    }
  }, [questions, currentAnswers])

  const handleCheck = (value: string): void => {
    const localCurrentSelectedOptions = currentSelectedOptions
    const newSelectedOptions = localCurrentSelectedOptions.includes(value as string)
      ? localCurrentSelectedOptions.filter(option => option !== value)
      : [...localCurrentSelectedOptions, value as string]
    onChange?.(newSelectedOptions)
  }

  const getHighlightedDot = (option: string, i: number): JSX.Element => {
    const answeredOption = answeredQuestions?.find(
      answer => answer.type === 'RATE_SELECT' && answer.label === option && answer.title === title
    )

    if (answeredOption?.answer === 'Advanced' && currentTopic?.topicKey === 'want_learn_improve') {
      return <Dot color="#7A59FE" />
    }

    if (answeredOption?.answer === 'Basic' && currentTopic?.topicKey === 'want_learn_improve') {
      return <Dot color="#59DDFE" />
    }

    if (answeredOption?.answer === 'Medium' && currentTopic?.topicKey === 'want_learn_improve') {
      return <Dot color="#FE598B" />
    }

    return <></>
  }

  const getResponse = (option: string): string => {
    const answeredOption = answeredQuestions?.find(
      answer => answer.type === 'RATE_SELECT' && answer.label === option && answer.title === title
    )

    switch (answeredOption?.answer) {
      case 'Basic':
        return 'Basic'
      case 'Medium':
        return 'Medium'
      case 'Advanced':
        return 'Advanced'
      default:
        return ''
    }
  }

  return (
    <div className="mb-12 w-full">
      <span className="text-2xl font-bold text-toast-hover mb-14">{title}</span>
      <div className="flex flex-wrap">
        {options
          .sort((a, b) => (a > b ? 1 : -1))
          .map((option, i) => {
            return (
              <button
                className={`flex items-center gap-2 text-2xl p-4 mt-4 mr-6 border border-solid rounded-3xl ${
                  currentSelectedOptions.includes(option)
                    ? 'border-green text-white bg-green'
                    : getResponse(option) === 'Advanced' && currentTopic?.topicKey === 'love_teach'
                    ? 'text-purple border-purple border-[2px]'
                    : 'border-dark-blue'
                }`}
                onClick={() => handleCheck(option)}
                key={i}
              >
                {getHighlightedDot(option, i)}
                {option}
              </button>
            )
          })}
      </div>
    </div>
  )
}
