const CrossEditSVG = (): JSX.Element => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.94787 10.3965C1.0618 10.5104 1.19852 10.5856 1.35803 10.6221C1.52209 10.6631 1.68159 10.6631 1.83654 10.6221C1.99605 10.5856 2.13049 10.5127 2.23986 10.4033L5.99963 6.64355L9.75256 10.4033C9.92573 10.5765 10.1399 10.6608 10.3951 10.6562C10.6549 10.6562 10.8737 10.5674 11.0514 10.3896C11.2291 10.2119 11.318 9.99544 11.318 9.74023C11.318 9.48503 11.2314 9.27083 11.0582 9.09766L7.30529 5.33789L11.0582 1.58496C11.2314 1.41634 11.318 1.20215 11.318 0.942383C11.318 0.682617 11.2291 0.466146 11.0514 0.292969C10.8737 0.115234 10.6549 0.0263672 10.3951 0.0263672C10.1399 0.0218099 9.92573 0.10612 9.75256 0.279297L5.99963 4.03906L2.23986 0.279297C2.13049 0.169922 1.99605 0.0970052 1.83654 0.0605469C1.68159 0.0195312 1.52209 0.0172526 1.35803 0.0537109C1.19852 0.0901693 1.0618 0.167643 0.94787 0.286133C0.829381 0.40918 0.749628 0.550456 0.708612 0.709961C0.672154 0.864909 0.672154 1.02214 0.708612 1.18164C0.749628 1.34115 0.824823 1.47559 0.934198 1.58496L4.69396 5.33789L0.934198 9.09766C0.824823 9.21159 0.749628 9.34831 0.708612 9.50781C0.672154 9.66276 0.672154 9.81999 0.708612 9.97949C0.749628 10.139 0.829381 10.278 0.94787 10.3965Z"
        fill="#005ECA"
      />
    </svg>
  )
}

export default CrossEditSVG
