import { AppButton } from '../atoms/AppButton/AppButton'
import introGif from '../../assets/images/intro.gif'
import { useQuery } from '../../hooks/useQuery'
import { useHistory } from 'react-router-dom'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { createPathWithLang } from '../../utils/languagePathUtils'
interface NumberedItemProps {
  number: number
  label: string
  color: string
}
const NumberedItem = ({ number, label, color }: NumberedItemProps): JSX.Element => (
  <div className="flex flex-row gap-4 items-center">
    <div
      className="rounded-full h-10 flex flex-col justify-center items-center text-center w-10 text-2xl text-white font-bold"
      style={{ backgroundColor: color }}
    >
      {number}
    </div>
    <div className="text-xl text-2xl whitespace-nowrap"> {label} </div>
  </div>
)

const IntroductionPage = (): JSX.Element => {
  const query = useQuery()
  const history = useHistory()
  const handleOk = (): void => {
    const redirect = query.get('redirect') || NetworkConstants.URL_REVIEW_LIST
    history.push(createPathWithLang(redirect))
  }
  return (
    <div className="flex flex-col gap-6 items-center container">
      <div className="flex flex-col gap-8">
        <div>
          <img width={80} min-height={80} src={introGif} alt="Grapes"></img>
        </div>
        <div className="text-toast-hover text-3xl">Do you know how Grapes works? </div>
        <div className="text-6xl leading-snug font-medium">
          We make the reviews <br />
          simple and fast.
        </div>
        <div className="text-2xl leading-normal">
          At Grapes we give you the power to <b>review developers</b> and
          <br />
          their ability to work as part of a team by{' '}
          <b>
            completing a<br />
            questionnaire
          </b>{' '}
          based on seven basic areas:
        </div>
        <div className="flex flex-row">
          <div className="flex flex-1 flex-col gap-2">
            <NumberedItem number={1} label="Growth mindset" color={'#59DDFE'} />
            <NumberedItem number={2} label="Communication" color={'#FE598B'} />
            <NumberedItem number={3} label="Professional Skills" color={'#00C853'} />
            <NumberedItem number={4} label="Productivity" color={'#FECD59'} />
          </div>
          <div className="flex flex-1 flex-col gap-2">
            <NumberedItem number={5} label="Commitment" color={'#7A59FE'} />
            <NumberedItem number={6} label="Strengths & Weaknesses" color={'#CD59FE'} />
            <NumberedItem number={7} label="Final step" color={'#FF7F6D'} />
          </div>
        </div>
        <div className="mt-2">
          <AppButton onClick={handleOk} type="info">
            Next
          </AppButton>
        </div>
      </div>
    </div>
  )
}

export default IntroductionPage
