import { FC } from 'react'

export const EyeIcon: FC = () => {
  return (
    <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.1111 6.93749C22.1111 6.93749 17.71 12.875 11.5556 12.875C7.45875 12.8839 3.34426 9.89717 1 6.93749C1 6.93749 5.40113 1 11.5556 1C15.7616 1.04663 19.675 3.85789 22.1111 6.93749Z"
        stroke="#979797"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="11.5547" cy="7" r="3.5" stroke="#979797" />
    </svg>
  )
}
