import { AppButton, AppButtonType } from '../atoms/AppButton/AppButton'

import { Popup } from './Popup'

type OptionModalProps = {
  visible: boolean
  icon?: JSX.Element
  message?: JSX.Element
  firstButtonText?: string
  firstButtonType?: AppButtonType
  onFirstButtonClick: () => void
  secondButtonText?: string
  secondButtonType?: AppButtonType
  onSecondButtonClick: () => void
  onClose: () => void
}

const ConfirmModal = ({
  icon,
  visible,
  message,
  firstButtonText = 'Yes',
  firstButtonType = 'info',
  onFirstButtonClick,
  secondButtonText = 'No',
  secondButtonType = 'error',
  onSecondButtonClick,
  onClose,
}: OptionModalProps): JSX.Element => {
  return (
    <Popup visible={visible} onClose={onClose}>
      <div className="[&>svg]:m-auto [&_p]:text-center">
        {icon ? icon : null}
        {message && <div className="text-4xl leading-[40px] py-20">{message}</div>}
      </div>
      <div className="flex justify-center gap-8">
        <AppButton type={firstButtonType} onClick={onFirstButtonClick}>
          {firstButtonText}
        </AppButton>
        <AppButton type={secondButtonType} onClick={onSecondButtonClick}>
          {secondButtonText}
        </AppButton>
      </div>
    </Popup>
  )
}

export default ConfirmModal
