import { ColumnProps } from 'primereact/column'
import {
  EMPLOYEE_HIGHLIGHT_FLIGHT_RISK,
  EMPLOYEE_HIGHLIGHT_ICON_MAP,
  EMPLOYEE_HIGHLIGHT_MISMATCH,
} from '../../../constants/employee'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../../services/EventTrackingService'
import { TeamStatus } from '../../../services/TeamService'
import { createPathWithLang } from '../../../utils/languagePathUtils'
import { NetworkConstants } from '../../../utils/NetworkConstants'
import DocumentNavigationSVG from '../../atoms/Icons/DocumentNavigation.svg'
import { TableCellBodyParams } from '../AppTable/TableCellBody'
import { CellLink } from './CellLink'

export function TeamIssuesIssuesTableCellBody(cellProps: TableCellBodyParams<TeamStatus> = {}) {
  return (rowData: TeamStatus, props: ColumnProps): JSX.Element => {
    const hasIssues =
      (rowData.flightRisks !== undefined && rowData.flightRisks > 0) ||
      (rowData.mismatches !== undefined && rowData.mismatches > 0)

    const summaryLink = createPathWithLang(
      NetworkConstants.URL_TEAM_SUMMARY.replace(':teamId', rowData?.id?.toString() || '')
    )

    const summaryLinkButton = () => {
      const handleClickOnTeamReport = () => {
        trackEvent(TrackingCategoryEnum.TEAM_ISSUES, TrackingCategoryEventEnum.TEAM_ISSUES.CLICK_TEAM_REPORT_LINK, '')
      }

      return rowData.finished !== undefined && rowData.finished > 0 ? (
        <div className="flex items-center justify-end flex-row w-72">
          <CellLink
            link={summaryLink}
            onClick={handleClickOnTeamReport}
            text="Team report"
            textClassname="font-semibold"
            blockClassname="clickable-hover-effect clickable-hover-effect--soft-grey"
            icon={<DocumentNavigationSVG />}
            tooltip
            textTooltip="Click to navigate to team’s report"
          />
        </div>
      ) : (
        <div className="flex items-center justify-end w-72">
          <div className="flex w-48">
            <div className="flex gap-4 items-center leading-6">
              <DocumentNavigationSVG color="#979797" />
              <span className="text-stone-gray font-semibold">No report yet</span>
            </div>
          </div>
        </div>
      )
    }

    const getIssues = () => {
      return (
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col rounded-xl gap-2 font-medium">
            {!hasIssues && '-'}
            {rowData.flightRisks !== undefined && rowData.flightRisks > 0 && (
              <div>
                {EMPLOYEE_HIGHLIGHT_ICON_MAP[EMPLOYEE_HIGHLIGHT_FLIGHT_RISK]}{' '}
                {EMPLOYEE_HIGHLIGHT_FLIGHT_RISK + ' (' + rowData.flightRisks + ')'}
              </div>
            )}
            {rowData.mismatches !== undefined && rowData.mismatches > 0 && (
              <div>
                {EMPLOYEE_HIGHLIGHT_ICON_MAP[EMPLOYEE_HIGHLIGHT_MISMATCH]}{' '}
                {EMPLOYEE_HIGHLIGHT_MISMATCH + ' (' + rowData.mismatches + ')'}
              </div>
            )}
          </div>
          {summaryLinkButton()}
        </div>
      )
    }

    return hasIssues ? (
      <div className="rounded-xl bg-white m-1 p-4 text-xl text-dark-blue w-full">{getIssues()}</div>
    ) : (
      <div className="m-1 p-4 text-xl text-dark-blue w-full">{getIssues()}</div>
    )
  }
}
