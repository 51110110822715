interface ClearIconProps {
  width?: number
  height?: number
}

const ClearIconSVG = ({ width = 20, height = 20 }: ClearIconProps): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 14C10.8294 14 14 10.8225 14 7C14 3.17059 10.8225 0 6.99314 0C3.17059 0 0 3.17059 0 7C0 10.8225 3.17745 14 7 14ZM7 12.8333C3.76078 12.8333 1.17353 10.2392 1.17353 7C1.17353 3.76078 3.75392 1.16667 6.99314 1.16667C10.2324 1.16667 12.8265 3.76078 12.8333 7C12.8402 10.2392 10.2392 12.8333 7 12.8333ZM4.65294 9.90294C4.81078 9.90294 4.94804 9.84118 5.05098 9.73137L6.99314 7.78235L8.94902 9.73137C9.05196 9.83431 9.18235 9.90294 9.3402 9.90294C9.64216 9.90294 9.88922 9.65588 9.88922 9.34706C9.88922 9.18922 9.83431 9.06569 9.72451 8.95588L7.77549 7.00686L9.73137 5.04412C9.84804 4.92745 9.89608 4.81078 9.89608 4.6598C9.89608 4.35784 9.64902 4.11078 9.34706 4.11078C9.20294 4.11078 9.08628 4.16569 8.96961 4.28235L6.99314 6.23824L5.03726 4.28922C4.93431 4.17941 4.81078 4.12451 4.65294 4.12451C4.35098 4.12451 4.10392 4.36471 4.10392 4.66667C4.10392 4.81765 4.16569 4.94804 4.26863 5.05098L6.21765 7.00686L4.26863 8.96275C4.16569 9.06569 4.10392 9.19608 4.10392 9.34706C4.10392 9.65588 4.35098 9.90294 4.65294 9.90294Z"
        fill="#005ECA"
      />
    </svg>
  )
}

export default ClearIconSVG
