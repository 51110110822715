import { FC } from 'react'

interface RoundedPlusIconProps {
  width?: number
  height?: number
  fill?: string
  className?: string
}

const RoundedPlusIcon: FC<RoundedPlusIconProps> = ({ className, width = 16, height = 16, fill = '#005ECA' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0059 18.7109C11.2884 18.7109 12.4928 18.4668 13.6191 17.9785C14.7454 17.4902 15.7383 16.8164 16.5977 15.957C17.4635 15.0977 18.1406 14.1048 18.6289 12.9785C19.1172 11.8457 19.3613 10.638 19.3613 9.35547C19.3613 8.07943 19.1139 6.87826 18.6191 5.75195C18.1309 4.61914 17.4538 3.62305 16.5879 2.76367C15.7285 1.89779 14.7357 1.2207 13.6094 0.732422C12.4831 0.244141 11.2786 0 9.99609 0C8.72005 0 7.51888 0.244141 6.39258 0.732422C5.26628 1.2207 4.27018 1.89779 3.4043 2.76367C2.54492 3.62305 1.87109 4.61914 1.38281 5.75195C0.894531 6.87826 0.650391 8.07943 0.650391 9.35547C0.650391 10.638 0.894531 11.8457 1.38281 12.9785C1.8776 14.1048 2.55469 15.0977 3.41406 15.957C4.27344 16.8164 5.26628 17.4902 6.39258 17.9785C7.51888 18.4668 8.72331 18.7109 10.0059 18.7109ZM5.5625 9.36523C5.5625 9.15039 5.63086 8.97461 5.76758 8.83789C5.91081 8.70117 6.08984 8.63281 6.30469 8.63281H9.2832V5.6543C9.2832 5.43945 9.34831 5.26367 9.47852 5.12695C9.61523 4.98372 9.78776 4.91211 9.99609 4.91211C10.2109 4.91211 10.3867 4.98047 10.5234 5.11719C10.6602 5.25391 10.7285 5.43294 10.7285 5.6543V8.63281H13.7168C13.9316 8.63281 14.1074 8.70117 14.2441 8.83789C14.3809 8.97461 14.4492 9.15039 14.4492 9.36523C14.4492 9.58008 14.3776 9.75586 14.2344 9.89258C14.0977 10.0228 13.9251 10.0879 13.7168 10.0879H10.7285V13.0664C10.7285 13.2812 10.6602 13.457 10.5234 13.5938C10.3867 13.7305 10.2109 13.7988 9.99609 13.7988C9.78776 13.7988 9.61523 13.7305 9.47852 13.5938C9.34831 13.457 9.2832 13.2812 9.2832 13.0664V10.0879H6.30469C6.08984 10.0879 5.91081 10.0228 5.76758 9.89258C5.63086 9.75586 5.5625 9.58008 5.5625 9.36523Z"
        fill={fill}
      />
    </svg>
  )
}

export default RoundedPlusIcon
