import classNames from 'classnames'
import React from 'react'
import { EmployeeDetails } from '../../../../services/EmployeeService'
import { Period } from '../../../../types/definitions/Period'
import { AvailablePeriods } from '../../../../utils/periods/PeriodUtils'
import GeneralStatusPeriodOverview from './GeneralStatusPeriodOverview'

interface GeneralStatusHeaderProps {
  currentUser: EmployeeDetails
  currentPeriod?: Period
  previousPeriod?: Period
  selectedPeriod: AvailablePeriods
  handleSelectedPeriod: (period: AvailablePeriods) => void
}

const GeneralStatusHeader: React.FC<GeneralStatusHeaderProps> = ({
  currentUser,
  currentPeriod,
  previousPeriod,
  selectedPeriod,
  handleSelectedPeriod,
}) => {
  const titleClass = classNames('font-semibold mt-10 mb-10', {
    'text-4xl': currentUser.role === 'admin',
    'text-6xl': currentUser.role === 'reviewer',
  })

  return (
    <div>
      <h1 className={titleClass}>General Status</h1>
      <GeneralStatusPeriodOverview
        currentPeriod={currentPeriod}
        previousPeriod={previousPeriod}
        selectedPeriod={selectedPeriod}
        onChange={handleSelectedPeriod}
      />
    </div>
  )
}

export default GeneralStatusHeader
