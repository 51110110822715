import { FC, useEffect, useState } from 'react'
import { EmployeeOverviewProjection } from '../../../api/openapi'
import { EMPLOYEE_HIGHLIGHT_FLIGHT_RISK, EMPLOYEE_HIGHLIGHT_MISMATCH } from '../../../constants/employee'

interface TeamIssues {
  underperforming: string[]
  flightRisk: string[]
}

interface TeamSummaryIssuesProps {
  employees: EmployeeOverviewProjection[]
}

const TeamSummaryIssues: FC<TeamSummaryIssuesProps> = ({ employees }) => {
  const [teamIssues, setTeamIssues] = useState<TeamIssues>({ underperforming: [], flightRisk: [] })
  const teamHasIssues = teamIssues.flightRisk.length > 0 || teamIssues.underperforming.length > 0

  useEffect(() => {
    const employeesMismatch = employees
      .filter(employee => employee.mismatch)
      .map(employeeMismatch => employeeMismatch.name + ' (' + employeeMismatch.jobTitle + ')')
    const employeesFlightRisk = employees
      .filter(employee => employee.flightRisk)
      .map(employeeFlightRisk => employeeFlightRisk.name + ' (' + employeeFlightRisk.jobTitle + ')')

    const teamIssues: TeamIssues = {
      underperforming: employeesMismatch,
      flightRisk: employeesFlightRisk,
    }
    setTeamIssues(teamIssues)
  }, [employees])

  return teamHasIssues ? (
    <>
      <div className="flex items-end">
        <div className="text-2xl text-dark-blue font-semibold mr-4">Issues</div>
        <div className="flex-auto bg-just-gray-hover w-100 h-[1px] mb-2"></div>
      </div>
      {teamIssues.underperforming.length > 0 && (
        <div key="underperforming" className="flex flex-row mt-4">
          <div className="text-2xl font-bold text-dark-blue">
            {EMPLOYEE_HIGHLIGHT_MISMATCH} ({teamIssues.underperforming.length}):&nbsp;
            {teamIssues.underperforming.map((issue: string, index) => (
              <span key={issue} className="text-2xl font-normal text-dark-blue mb-4">
                {index > 0 && <span className="font-bold">&nbsp;&nbsp;·&nbsp;&nbsp;</span>}
                {issue}
              </span>
            ))}
          </div>
        </div>
      )}
      {teamIssues.flightRisk.length > 0 && (
        <div key="flightRisk" className="flex flex-row mt-4">
          <div className="text-2xl font-bold text-dark-blue">
            {EMPLOYEE_HIGHLIGHT_FLIGHT_RISK} ({teamIssues.flightRisk.length}):&nbsp;
            {teamIssues.flightRisk.map((issue: string, index) => (
              <span className="text-2xl font-normal text-dark-blue mb-4" key={issue}>
                {index > 0 && <span className="font-bold">&nbsp;&nbsp;·&nbsp;&nbsp;</span>}
                {issue}
              </span>
            ))}
          </div>
        </div>
      )}
      <div className="bg-just-gray-hover w-100 h-[1px] my-6"></div>
    </>
  ) : null
}

export default TeamSummaryIssues
