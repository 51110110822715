import { Tooltip } from '@material-ui/core'
import { ColumnProps } from 'primereact/column'
import { Link } from 'react-router-dom'
import { EmployeeScores } from '../../../services/EmployeeService'
import { createPathToEmployeeResults } from '../../../utils/employeePathUtils'
import { hasEmployeeReviews } from '../../../utils/employeeUtils'
import { ClockIcon } from '../../atoms/Icons/ClockIcon'

const MINIMUM_SCORE_VALUE = 30

export const TeamTableBody = (
  rowData: EmployeeScores,
  props: ColumnProps,
  toggleRowExpand?: (r: EmployeeScores) => void
): JSX.Element => {
  const handleExpandRow = (e: React.MouseEvent) => {
    toggleRowExpand?.(rowData)
    e.stopPropagation()
  }
  if (props.field === 'name') {
    const hasReviews = hasEmployeeReviews(rowData)
    const name = rowData?.name !== undefined ? rowData?.name + (hasReviews ? '' : '*') : '-'
    const textColor = hasReviews ? 'text-blue' : 'text-stone-gray'

    return (
      <div className="table-text-overflow">
        <Tooltip
          title={(rowData[props.field as keyof EmployeeScores] || '') + ' - ' + (rowData.jobTitle || '')}
          arrow
          enterDelay={500}
          enterNextDelay={500}
          classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
        >
          <Link to={createPathToEmployeeResults(rowData.id as number)}>
            <div className={'flex flex-col hover:underline ' + textColor}>
              <div className="flex flex-col">
                <span className={`${textColor}`}>{name}</span>
                <span className={`font-bold text-base leading-4 ${textColor}`}>{rowData.jobTitle}</span>
              </div>
            </div>
          </Link>
        </Tooltip>
      </div>
    )
  } else if (props.field === 'reviewer') {
    if (rowData.reviewsAmount === 1) {
      return (
        <div className="table-text-overflow">
          <Tooltip
            title={rowData[props.field as keyof EmployeeScores] || ''}
            arrow
            enterDelay={500}
            enterNextDelay={500}
            classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
          >
            <span>{rowData.lastReviewerName}</span>
          </Tooltip>
        </div>
      )
    } else if (rowData?.reviewsAmount !== undefined && rowData?.reviewsAmount > 1) {
      return (
        <div className="text-blue cursor-pointer hover:underline" onClick={handleExpandRow}>
          {rowData?.reviewsAmount} reviews
        </div>
      )
    } else {
      return <span>-</span>
    }
  } else if (props.field === 'lastReviewType') {
    if (rowData.reviewTypesAmount === 1) {
      return (
        <div className="table-text-overflow">
          <Tooltip
            title={rowData[props.field as keyof EmployeeScores] || ''}
            arrow
            enterDelay={500}
            enterNextDelay={500}
            classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
          >
            <span>{rowData.lastReviewType}</span>
          </Tooltip>
        </div>
      )
    } else if (rowData?.reviewTypesAmount !== undefined && rowData?.reviewTypesAmount > 1) {
      return (
        <div className="text-blue cursor-pointer hover:underline" onClick={handleExpandRow}>
          {rowData?.reviewTypesAmount} types
        </div>
      )
    } else if (rowData.skipped) {
      return (
        <span className="text-stone-gray flex gap-2 items-center">
          <ClockIcon color="#787878" /> Skipped
        </span>
      )
    } else {
      return <span>-</span>
    }
  } else if (props.field === 'score') {
    const result = rowData[props.field as keyof EmployeeScores] || '-'
    const scoreStyle = result <= MINIMUM_SCORE_VALUE ? 'text-error-red font-bold' : 'font-bold'
    return (
      <div className="table-text-overflow w-full flex justify-center">
        <span className={scoreStyle}>{result}</span>
      </div>
    )
  } else {
    const result = rowData[props.field as keyof EmployeeScores] ?? '-'
    const scoreStyle = result <= MINIMUM_SCORE_VALUE ? 'text-error-red font-bold' : ''
    return (
      <div className="table-text-overflow w-full flex justify-center">
        <span className={scoreStyle}>{Number.isNaN(result) ? '-' : result}</span>
      </div>
    )
  }
}
