import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../..'
import { Employee } from '../../api/openapi'
import { Role } from '../../types/definitions/employee'
import { ContentList, FaqCategory } from '../../utils/FaqContent'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { ArrowIcon } from '../atoms/Icons/ArrowIcon'
import { FaqCategoryContent } from '../molecules/FaqPage/FaqCategoryContent'
import { FaqCategorySelector } from '../molecules/FaqPage/FaqCategorySelector'

export const FaqPage = () => {
  const [selectedContent, setSelectedContent] = useState(0)
  const currentUser = useSelector<RootState, Employee>(state => state.auth.user as Employee)
  // Content list
  const [filteredContent, setFilteredContent] = useState<FaqCategory[]>()

  const getSections = useCallback(() => {
    if (filteredContent) {
      return
    }
    const filteredSections: FaqCategory[] = []
    ContentList.forEach(section => {
      const filteredSectionContent = section.content.filter(content => {
        return (
          content.allUsers ||
          (!currentUser && !content.logged && !content.leader) ||
          (content.logged && currentUser?.role === Role.User && !content.leader) ||
          (content.logged &&
            (currentUser?.role === Role.Reviewer || currentUser?.role === Role.Admin) &&
            content.leader)
        )
      })
      if (filteredSectionContent.length > 0) {
        filteredSections.push({ ...section, content: filteredSectionContent })
      }
    })
    setFilteredContent(filteredSections)
  }, [currentUser, filteredContent])

  useEffect(() => {
    getSections()
  }, [getSections])

  return (
    <>
      {!currentUser && (
        <div className={`container mx-auto flex 'justify-between' my-12`}>
          <div className="flex flex-nowrap">
            <Link
              to={createPathWithLang(NetworkConstants.URL_LOGIN)}
              className="text-stone-gray hover:text-stone-gray hover:underline text-2xl font-medium flex items-center"
            >
              <div className="mr-3">
                <ArrowIcon height={14} width={14} />
              </div>
              Back to login
            </Link>
          </div>
        </div>
      )}
      <div className="container flex flex-col items-center justify-center gap-16 w-full">
        {filteredContent && (
          <>
            <div className="flex w-full items-center justify-center gap-6">
              {filteredContent.map(({ name }, index) => (
                <FaqCategorySelector
                  selected={selectedContent === index}
                  category={name}
                  onClick={() => setSelectedContent(index)}
                  key={index}
                />
              ))}
            </div>
            <h1 className="text-dark-blue text-5xl font-[500]">{filteredContent[selectedContent].name}</h1>
            <div className="flex flex-col justify-center items-center gap-8 w-full">
              {filteredContent[selectedContent].content.map((cont, i) => (
                <FaqCategoryContent {...cont} key={`${cont.title}-${i}`} />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}
