interface TickProps {
  width?: number
  height?: number
  color?: string
}

const Tick = ({ width = 13, height = 13 }: TickProps): JSX.Element => {
  return (
    <svg width={width} height={width} viewBox="0 0 13 13" fill="none" version="1.1" id="svg4">
      <defs id="defs8" />
      <path
        d="M6.12478 12.9861C9.4859 12.9861 12.2359 10.2361 12.2359 6.87503C12.2359 3.51392 9.4859 0.763916 6.12478 0.763916C2.76367 0.763916 0.0136719 3.51392 0.0136719 6.87503C0.0136719 10.2361 2.76367 12.9861 6.12478 12.9861Z"
        fill="#00C853"
        id="path2"
      />
      <g id="g75" transform="translate(2.0078036,3.9668699)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M 7.72097,0.297871 C 8.0192,0.596276 8.01906,1.07994 7.72066,1.37817 L 3.64419,5.45225 C 3.50086,5.59549 3.30649,5.67592 3.10386,5.67583 2.90122,5.67574 2.70692,5.59514 2.56372,5.45177 L 0.529084,3.41473 C 0.230943,3.11624 0.231227,2.63257 0.52972,2.33443 0.828213,2.03629 1.31188,2.03657 1.61002,2.33507 l 1.49465,1.49641 3.536,-3.533926 c 0.29841,-0.29822976 0.78207,-0.298087521 1.0803,3.17e-4 z"
          fill="#ffffff"
          id="path66"
        />
      </g>
    </svg>
  )
}

export default Tick
