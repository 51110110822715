import classNames from 'classnames'
import { Dropdown } from 'primereact/dropdown'
import { FC, useState } from 'react'

export interface AppSelectOptionProps {
  value: string
  label: string
  extraLabels?: { label: string; color?: string }[]
}

export interface AppSelectProps {
  placeholder?: string
  label?: string
  value?: string
  options: AppSelectOptionProps[]
  disabled?: boolean
  appendTo?: 'self' | HTMLElement
  helpText?: boolean
  warningText?: string
  unfinished?: boolean
  required?: boolean
  filter?: boolean
  className?: string
  panelClassName?: string
  dropdownClassName?: string
  sortBy?: 'label' | 'value'
  labelBody?: JSX.Element
  customSortFn?: (options: { value: string; label: string }[]) => void
  onChange?: (inputValue: string) => void
}

export const AppSelect: FC<AppSelectProps> = ({
  placeholder,
  options,
  label,
  value,
  disabled,
  helpText,
  warningText,
  unfinished,
  required = true,
  filter = false,
  className = '',
  panelClassName,
  dropdownClassName,
  sortBy = 'label',
  labelBody,
  appendTo,
  onChange,
  customSortFn,
}: AppSelectProps): JSX.Element => {
  const [zIndex, setZIndex] = useState<number>(0)

  const handleChange = (value: string): void => {
    onChange?.(value)
  }

  if (customSortFn) {
    customSortFn(options)
  } else {
    options.sort((o1, o2) => {
      if (!Number.isNaN(parseFloat(o1[sortBy])) && !Number.isNaN(parseFloat(o2[sortBy])))
        return parseFloat(o1[sortBy]) - parseFloat(o2[sortBy])
      return o1[sortBy].localeCompare(o2[sortBy])
    })
  }

  const itemTemplate = (option: AppSelectOptionProps): React.ReactNode => {
    return (
      <div className="flex flex-row gap-x-2">
        <label className="leading-6">{option.label}</label>
        {option.extraLabels &&
          option.extraLabels.map((extraLabel, index) => {
            return (
              <>
                <span className={extraLabel.color || 'text-toast-hover'}>{extraLabel.label}</span>
                {option.extraLabels && index + 1 !== option.extraLabels.length && (
                  <span className="text-toast-hover">&nbsp;+&nbsp;</span>
                )}
              </>
            )
          })}
      </div>
    )
  }

  return (
    <div className={`app-select-wrapper ${className}`} style={{ zIndex: zIndex }}>
      {/** Custom label body */}
      {labelBody && labelBody}
      {/** Default label body */}
      {!labelBody && (label || !required) && (
        <div className="flex">
          {label && <span className="app-select-label">{label}</span>}
          {!required && <p className="optional-question ml-auto">Optional</p>}
        </div>
      )}
      <Dropdown
        className={classNames({
          'app-select-select': true,
          'app-select-select--unfinished': !value && unfinished,
          [`${dropdownClassName}`]: true,
        })}
        panelClassName={`[&_*]:text-2xl app-select-select-panel !z-[1500] ${panelClassName} `}
        value={value}
        options={options}
        disabled={disabled}
        filter={filter}
        onChange={e => handleChange(e.value)}
        // onShow={() => setZIndex(999)}
        // onHide={() => setZIndex(1)}
        optionLabel="label"
        optionValue="value"
        appendTo={appendTo}
        placeholder={placeholder}
        itemTemplate={itemTemplate}
        resetFilterOnHide
      />

      {warningText ? (
        <span>
          <p className="app-input-warning-text">{warningText}</p>
        </span>
      ) : (
        helpText && (
          <p className="app-input-warning-text">
            <br />
          </p>
        )
      )}
    </div>
  )
}
