import { Point } from './mathUtils'

interface Font {
  size: number
  family: string
  style: string
}

const font = (size: number, family: string, style: string): Font => {
  return { size, family, style }
}

const drawLine = (ctx: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number): void => {
  ctx.beginPath()
  ctx.strokeStyle = 'red'
  ctx.moveTo(x1, y1)
  ctx.lineTo(x2, y2)
  ctx.stroke()
  ctx.closePath()
}

const drawText = (ctx: CanvasRenderingContext2D, text: string, position: Point, color: string, font: Font): void => {
  ctx.save()
  ctx.font = font.style + ' ' + font.size + 'px ' + font.family
  ctx.fillStyle = color
  ctx.fillText(text, position.x, position.y)
  ctx.restore()
}

const widthOf = (ctx: CanvasRenderingContext2D, text: string): number => {
  return ctx.measureText(text).width
}

export { drawLine, drawText, font, widthOf }
export type { Font }
