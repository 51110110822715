import { ConstantIDs } from '../constants/employee'
import { checkReviewer, Employee } from '../services/EmployeeService'
import { createPathWithLang } from './languagePathUtils'
import { NetworkConstants } from './NetworkConstants'

export enum ReviewerTypeEnum {
  REQUESTED = 'requested',
}

export const createPathToEditUnfinishedReview = (employee: Employee): string => {
  if (employee.lastOwnReview) {
    return createPathWithLang(
      NetworkConstants.URL_EDIT_REVIEW.replace(
        ConstantIDs.REVIEW_ID_PATH_PLACEHOLDER,
        employee.lastOwnReview.id.toString()
      )
    )
  }
  return ''
}

export const createPathToEmployeeResults = (employeeId: number): string => {
  return createPathWithLang(
    NetworkConstants.URL_REPORT.replace(ConstantIDs.EMPLOYEE_ID_PATH_PLACEHOLDER, employeeId.toString())
  )
}

export const createPathToCreateNewReview = (employeeId: number): string => {
  return createPathWithLang(
    NetworkConstants.URL_NEW_REVIEW.replace(ConstantIDs.EMPLOYEE_ID_PATH_PLACEHOLDER, employeeId.toString())
  )
}

export const calculateEmployeeReviewPath = (
  employee: Employee,
  user: Employee,
  reviewType?: ReviewerTypeEnum
): string => {
  const hasNotReviews = checkReviewer(employee, user as Employee) && !employee.lastOwnReview
  const hasUnfinishedReview = employee.lastOwnReview && !employee.lastOwnReview?.finishDate
  if (hasNotReviews) return createPathToCreateNewReview(employee.id)
  if (reviewType === ReviewerTypeEnum.REQUESTED || hasUnfinishedReview)
    return createPathToEditUnfinishedReview(employee)
  return createPathToEmployeeResults(employee.id)
}

export const createPathToTeamSummary = (teamId: number): string => {
  return createPathWithLang(NetworkConstants.URL_TEAM_SUMMARY.replace(':teamId', teamId.toString()))
}
