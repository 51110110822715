export enum QuestionTypeEnum {
  SINGLE_SELECTION = 'SINGLE_SELECTION',
  MULTIPLE_SELECTION = 'MULTIPLE_SELECTION',
  TRUE_FALSE = 'TRUE_FALSE',
  TEXT_LINE = 'TEXT_LINE',
  PARAGRAPH = 'PARAGRAPH',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  NUMERIC = 'NUMERIC',
  SCALE = 'SCALE',
  TASK_DESCRIPTION = 'TASK_DESCRIPTION',
  RATE_SELECT = 'RATE_SELECT',
  PILL_SELECT = 'PILL_SELECT',
  DESCRIPTIVE_RATE = 'DESCRIPTIVE_RATE',
  NONE = 'NONE',
}
