import { QuestionApi } from '../api'
import { QuestionResponse } from '../api/openapi'
import { Question } from '../types/definitions/question'

export class QuestionService {
  static findAll(): Promise<Question[]> {
    return QuestionApi.getUsingGET().then(response => {
      return (response.data || []).map(questionResponse => this.questionResponseToQuestion(questionResponse))
    })
  }

  static questionResponseToQuestion(questionResponse: QuestionResponse): Question {
    return {
      ...questionResponse,
      id: questionResponse.id,
      order: questionResponse.questionOrder,
      attachments: [],
      topic: {
        name: questionResponse.topic.topicName,
        order: questionResponse.topic.topicOrder,
        topicKey: questionResponse.topic.key,
      },
      reportLayout: {
        type: questionResponse.reportLayoutType,
        order: questionResponse.reportLayoutOrder,
        itemOrder: questionResponse.reportLayoutItemOrder,
      },
      required: questionResponse.required,
      weight: questionResponse.weight,
      skipped: false, // FIXME: this shouldn't came from DB here
    } as Question
  }
}
