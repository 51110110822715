import { createSlice } from '@reduxjs/toolkit'
import { TutorialsStorageConfiguration } from '../utils/HelpContent'

const SliceConfiguration = {
  reducerTag: 'imageIntroduction',
}

export interface ImageIntroductionState {
  current: number | null
  completed: boolean
}

const initialState: ImageIntroductionState = {
  current: null,
  completed: localStorage.getItem(TutorialsStorageConfiguration.INTRODUCTION_TUTORIAL_COMPLETED) === 'true',
}

export const imageIntroductionSlice = createSlice({
  name: SliceConfiguration.reducerTag,
  initialState: initialState,
  reducers: {
    showImageIntroduction: state => {
      return {
        ...state,
        current: 0,
      }
    },
    showImageIntroductionId: (state, { payload }) => {
      return {
        ...state,
        current: payload,
      }
    },
    hideImageIntroduction: state => {
      return {
        ...state,
        current: null,
      }
    },
    finishImageIntroduction: state => {
      localStorage.setItem(TutorialsStorageConfiguration.INTRODUCTION_TUTORIAL_COMPLETED, 'true')
      return {
        ...state,
        completed: true,
      }
    },
  },
})

export const { hideImageIntroduction, showImageIntroduction, showImageIntroductionId, finishImageIntroduction } =
  imageIntroductionSlice.actions
