import { FC } from 'react'
import ReactMarkdown from 'react-markdown'

export interface AppMarkdownViewerProps {
  markdown: string
  className?: string
}

export const AppMarkdownViewer: FC<AppMarkdownViewerProps> = ({ markdown, className }) => {
  return (
    <div className={className}>
      <ReactMarkdown linkTarget="_blank">{markdown}</ReactMarkdown>
    </div>
  )
}
