import moment from 'moment'
import { Review } from '../../../types/definitions/review'
import { Form } from '../../../types/definitions/form'
import { Dropdown } from 'primereact/dropdown'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../../services/EventTrackingService'

const formatDate = (date: string, formatString = 'MMMM YYYY'): string => {
  return moment(date).format(formatString)
}

type EmployeeResultHistoryProps = {
  currentReview: Review
  reviewToCompare?: Review
  reviews: Review[]
  onChange?: (review: Review) => void
  onComparableChange?: (review: Review) => void
  allForms: Form[]
}

const EmployeeResultsHistory = ({
  currentReview,
  reviewToCompare,
  reviews,
  onChange,
  onComparableChange,
  allForms,
}: EmployeeResultHistoryProps): JSX.Element => {
  const getFormName = (formId: number | undefined): string => {
    return (
      allForms.filter(form => {
        return form.id === formId
      })[0]?.name || ''
    )
  }

  const selectOptions = reviews.map<{ value: number; label: string; disabled: boolean; periodAverage?: boolean }>(
    review => ({
      value: review.id,
      label: review.periodAverage
        ? review.reviewAuthor.name
        : `${formatDate(review.finishDate || '', 'MM/DD/YYYY')} ${review.reviewAuthor.name} · Form: ${getFormName(
            review?.form.id
          )}`,
      disabled: review.id === currentReview?.id,
      periodAverage: review.periodAverage,
    })
  )

  const handleOnChange = (reviewId: number): void => {
    const review = reviews.find(review => review.id === reviewId) as Review
    trackEvent(
      TrackingCategoryEnum.INDIVIUAL_REPORT,
      TrackingCategoryEventEnum.INDIVIUAL_REPORT.CLICK_REVIEW_OPTION,
      ''
    )
    onChange?.(review)
  }

  const handleOnComparableChange = (reviewId: number): void => {
    const review = reviews.find(review => review.id === reviewId) as Review
    onComparableChange?.(review)
  }

  const itemOptionTemplate = (option: { value: number; label: string; disabled: boolean; periodAverage?: boolean }) => {
    return <div className={option.periodAverage ? 'font-bold' : ''}>{option.label}</div>
  }

  if (onComparableChange) {
    return (
      <Dropdown
        className="w-full h-16 rounded-xl border-b border-transparent hover:border-blue bg-pale-blue mt-2 mb-6 print:my-2"
        panelClassName="app-select-select-panel"
        itemTemplate={itemOptionTemplate}
        valueTemplate={
          <div className="text-2xl text-blue font-bold ml-2">
            {selectOptions.find(item => item.value === reviewToCompare?.id)?.label}
          </div>
        }
        value={reviewToCompare?.id}
        options={selectOptions}
        optionDisabled={option => option.disabled}
        disabled={selectOptions.length < 2}
        onChange={e => handleOnComparableChange(e.value)}
      />
    )
  } else {
    return (
      <Dropdown
        className="w-full h-16 rounded-xl border-b border-transparent hover:border-blue bg-pale-blue mt-2 mb-6 print:my-2"
        panelClassName="app-select-select-panel"
        valueTemplate={
          <div className="text-2xl text-blue font-bold ml-2">
            {selectOptions.find(item => item.value === currentReview?.id)?.label}
          </div>
        }
        value={currentReview?.id}
        options={selectOptions}
        onMouseDown={() => {
          trackEvent(
            TrackingCategoryEnum.INDIVIUAL_REPORT,
            TrackingCategoryEventEnum.INDIVIUAL_REPORT.CLICK_REVIEW_SELECTOR,
            ''
          )
        }}
        onChange={e => handleOnChange(e.value)}
      />
    )
  }
}

export default EmployeeResultsHistory
