import { FormEvent, useEffect, useState } from 'react'
import { TeamService } from '../../services/TeamService'
import { AppSelect } from '../atoms/AppSelect/AppSelect'
import { AppMultiselect } from '../atoms/AppMultiselect/AppMultiselect'
import Spinner from '../atoms/Spinner'
import TextLine from '../atoms/TextLine'
import { Role } from '../../types/definitions/employee'
import Validator from '../../utils/forms/Validator'
import FormField from '../../utils/forms/FormField'
import { EmployeeService } from '../../services/EmployeeService'
import { useHistory } from 'react-router-dom'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { AxiosError } from 'axios'
import ErrorModal from '../molecules/ErrorModal'
import { ConfirmIcon } from '../atoms/Icons/ConfirmIcon'
import { AppButton } from '../atoms/AppButton/AppButton'

interface MultiSelectOption {
  value: number
  label: string
}

const CreateAccount = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true)
  const [serverError, setServerError] = useState<string>('')
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [jobTitle, setJobTitle] = useState<string>('')
  const [role, setRole] = useState<string>('user')
  const [teams, setTeams] = useState<MultiSelectOption[]>([])
  const [selectedTeams, setSelectedTeams] = useState<number[]>([])
  const rowClassNames = 'flex flex-row gap-20'
  const inputClassNames = 'p-3 rounded-xl border border-solid border-blue text-2xl h-14'
  const [fields, setFields] = useState<Record<string, FormField<string> | FormField<number[]>>>({})
  const history = useHistory()
  const initializeForm = () => {
    fields['name'] = new FormField<string>('name', name, [new Validator<string>('required')], setName)
    fields['email'] = new FormField<string>(
      'email',
      email,
      [new Validator('email'), new Validator('required')],
      setEmail
    )
    fields['password'] = new FormField<string>('password', password, [new Validator('required')], setPassword)
    fields['jobTitle'] = new FormField<string>('jobTitle', jobTitle, [new Validator('required')], setJobTitle)
    fields['role'] = new FormField<string>('role', role, [new Validator('required')], setRole)
    fields['teams'] = new FormField<number[]>('teams', selectedTeams, [], setSelectedTeams)
    setFields(fields)
  }

  const handleFieldChange = (field: string) => (value: string | number[] | undefined) => {
    fields[field]?.handleChange(value as string & number[])
  }

  useEffect(() => {
    TeamService.findAllFlat().then(teams => {
      const mapped = teams.map(value => {
        return { value: value.id, label: value.name }
      })
      setTeams(mapped)
      initializeForm()
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createNewAccount = () => {
    EmployeeService.create({
      name: name,
      email: email,
      role: role,
      jobTitle: jobTitle,
      password: password,
      salaryGrade: 'U',
      teams: selectedTeams,
      msId: '',
    })
      .then(() => {
        setLoading(false)
        history.push(createPathWithLang(NetworkConstants.URL_ADMIN_PANEL))
      })
      .catch((e: AxiosError) => {
        console.error('Error', e.response)
        setServerError(e.response?.data.message || 'Unknown server error')
        setShowErrorModal(true)
        setLoading(false)
      })
  }

  const handleSubmit = (e: FormEvent) => {
    setLoading(true)
    let formValid = true
    for (const key in fields) {
      fields[key].validate()
      if (fields[key].hasError) {
        formValid = false
      }
    }

    if (formValid) {
      createNewAccount()
    } else {
      setFields({ ...fields })
      setLoading(false)
    }
    e.preventDefault()
  }

  if (loading) {
    return <Spinner />
  }
  return (
    <form target="#" onSubmit={handleSubmit} className="px-4 flex-grow flex flex-col">
      <div className="container mt-10 flex flex-col items-start gap-20">
        <h1 id="title" className="text-6xl text-dark-blue font-semibold">
          Create a new member
        </h1>
        <div className="rounded-xl bg-just-gray p-32 flex flex-col gap-20">
          <div className={rowClassNames}>
            <TextLine
              className={inputClassNames}
              label="Name"
              value={name}
              required={true}
              placeholder="Write name here"
              searchIcon={false}
              warningText={fields['name'].errorMessages[0]}
              onChange={handleFieldChange('name')}
            ></TextLine>
            <TextLine
              className={inputClassNames}
              label="Email"
              value={email}
              required={true}
              type={'email'}
              placeholder="Write email here"
              searchIcon={false}
              warningText={fields['email'].errorMessages[0]}
              onChange={handleFieldChange('email')}
            ></TextLine>
          </div>
          <div className={rowClassNames}>
            <TextLine
              className={inputClassNames}
              type="password"
              label="Password"
              value={password}
              required={true}
              placeholder="Write a password here"
              searchIcon={false}
              warningText={fields['password'].errorMessages[0]}
              onChange={handleFieldChange('password')}
            ></TextLine>
            <TextLine
              className={inputClassNames}
              label="Job title"
              value={jobTitle}
              required={true}
              placeholder="Write job title here"
              searchIcon={false}
              warningText={fields['jobTitle'].errorMessages[0]}
              onChange={handleFieldChange('jobTitle')}
            ></TextLine>
          </div>
          <div className={rowClassNames}>
            <AppSelect
              label="*Role"
              placeholder="Select a role"
              value={role}
              required={true}
              options={Object.values(Role).map(role => ({ value: role, label: role }))}
              warningText={fields['role'].errorMessages[0]}
              onChange={handleFieldChange('role')}
              className="h-28"
            ></AppSelect>
            <AppMultiselect
              label="Teams"
              placeholder="Select teams"
              values={selectedTeams}
              options={teams}
              display={'comma'}
              warningText={fields['teams'].errorMessages[0]}
              onChange={handleFieldChange('teams')}
              className="h-28 text-2xl border-blue"
            ></AppMultiselect>
          </div>
        </div>
        <div className="flex gap-12">
          <AppButton type="clear" onClick={history.goBack}>
            Back
          </AppButton>
          <AppButton type="info" onClick={() => handleSubmit}>
            Create
          </AppButton>
        </div>
      </div>
      <div>
        {showErrorModal && (
          <ErrorModal
            icon={<ConfirmIcon />}
            visible={showErrorModal}
            message={<p>{serverError}</p>}
            onClose={() => setShowErrorModal(false)}
          />
        )}
      </div>
    </form>
  )
}

export default CreateAccount
