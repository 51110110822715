import { classNames } from 'primereact/utils'

interface FaqCategorySelectorProps {
  selected: boolean
  category: string
  onClick: () => void
}
export const FaqCategorySelector = ({ selected, category, onClick }: FaqCategorySelectorProps) => {
  const style = classNames({
    'text-2xl p-4 rounded-2xl border-solid border': true,
    'border-pale-blue': !selected,
    'bg-blue text-white': selected,
  })
  return (
    <button className={style} onClick={onClick}>
      {category}
    </button>
  )
}
