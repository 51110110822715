import { FC, useState } from 'react'
import { ReviewSimpleProjection } from '../../../api/openapi'
import { ConstantIDs } from '../../../constants/employee'
import { EmployeeKeys, EmployeeScores, EmployeeService, EmployeeSort } from '../../../services/EmployeeService'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../../services/EventTrackingService'
import { ReviewService } from '../../../services/ReviewService'
import { SortOrderEnum } from '../../../types/enums/sort-order.enum'
import history from '../../../utils/history'
import { createPathWithLang } from '../../../utils/languagePathUtils'
import { NetworkConstants } from '../../../utils/NetworkConstants'
import { TeamTable } from '../TeamTable'

interface TeamSummaryTableProps {
  teamEmployees: EmployeeScores[]
}

const TeamSummaryTable: FC<TeamSummaryTableProps> = ({ teamEmployees }) => {
  const [selectedSort, setSelectedSort] = useState<EmployeeSort[]>([
    { property: 'name', order: SortOrderEnum.Ascending },
  ])

  const sortEmployeesByScore = (employees: EmployeeScores[]): EmployeeScores[] => {
    const property = selectedSort[0].property
    const order = selectedSort[0].order

    if (property === 'score') {
      return employees.sort(function (a, b) {
        return order === 'asc' ? a.score - b.score : b.score - a.score
      })
    }
    return employees.sort(function (a, b) {
      const score1: number = Number(a[property as keyof EmployeeScores]) || 0
      const score2: number = Number(b[property as keyof EmployeeScores]) || 0
      return order === 'desc' ? score1 - score2 : score2 - score1
    })
  }

  const filterAndSortEmployees = (employeeScores: EmployeeScores[]): EmployeeScores[] => {
    const filteredAndSortedEmployees = EmployeeService.sortEmployees(
      employeeScores.filter(score => !score.isGlobalScore),
      selectedSort
    )

    return selectedSort[0].property === 'name'
      ? filteredAndSortedEmployees
      : sortEmployeesByScore(filteredAndSortedEmployees)
  }

  const handleSortSelected = (property: string, order: SortOrderEnum): void => {
    setSelectedSort([{ property: property as EmployeeKeys, order }])
  }

  const handleOnEmployeeClicked = (employeeId: number): void => {
    trackEvent(TrackingCategoryEnum.TEAM_REPORT, TrackingCategoryEventEnum.TEAM_REPORT.CLICK_EMPLOYEE_NAME, '')
    history.push(
      createPathWithLang(
        NetworkConstants.URL_REPORT.replace(ConstantIDs.EMPLOYEE_ID_PATH_PLACEHOLDER, employeeId.toString())
      )
    )
  }

  const handleExpandedRowClicked = async (row: ReviewSimpleProjection, employeeId: number) => {
    trackEvent(TrackingCategoryEnum.TEAM_REPORT, TrackingCategoryEventEnum.TEAM_REPORT.CLICK_EMPLOYEE_NAME, '')
    const selectedReview = await ReviewService.findByEmployeeId(employeeId)
    const filteredReviews = selectedReview.filter(review => review.reviewAuthor.name === row.reviewer)
    history.push({
      pathname: createPathWithLang(
        NetworkConstants.URL_REPORT.replace(ConstantIDs.EMPLOYEE_ID_PATH_PLACEHOLDER, employeeId.toString())
      ),
      search: `?reviewId=${filteredReviews[0].id}`,
    })
  }

  return (
    <div className="mx-auto">
      <TeamTable
        employeeScores={filterAndSortEmployees(teamEmployees)}
        selectedSort={selectedSort[0]}
        onSortSelected={handleSortSelected}
        handleOnEmployeeClicked={handleOnEmployeeClicked}
        handleExpandedRowClicked={handleExpandedRowClicked}
      />
    </div>
  )
}

export default TeamSummaryTable
