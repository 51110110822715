import { FC } from 'react'
import classNames from 'classnames'

export interface AppFilterButtonProps {
  active?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const AppFilterButton: FC<AppFilterButtonProps> = ({ children, active, disabled = false, onClick }) => (
  <button
    className={classNames({
      'px-6 py-3 rounded-lg text-2xl font-semibold duration-200': true,
      'bg-just-gray hover:bg-just-gray-hover text-stone-gray': !active,
      'bg-blue hover:bg-hover-blue text-white': active,
      'opacity-50 cursor-not-allowed': disabled,
    })}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
)

export default AppFilterButton
