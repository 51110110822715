const talentAtRiskData = (
  <div className="my-4">
    <p>
      There are several reasons why an individual might choose to leave. We monitor risk levels to prevent attrition:
    </p>
    <ul className="list-disc list-outside mt-4 pl-3">
      <li className="ml-[1.7rem] pl-3">
        <span className="font-bold">High:</span> There are +2 issues that may put retention of this resource at risk.
      </li>
      <li className="ml-[1.7rem] pl-3">
        <span className="font-bold">Medium:</span> There are at least 1 or 2 issues that may put retention of this
        resource at risk.
      </li>
      <li className="ml-[1.7rem] pl-3">
        <span className="font-bold">Low: </span>There are no perceived issues affecting retention of this resource.
      </li>
    </ul>
    <div key={`talent-at-risk-data`} className="my-4">
      <h3 className="font-bold">How do I assign a level of risk?</h3>
      <p>
        Think about the following areas: do any of the statements below reflect this individual’s situation? Is any of
        the issues serious enough to place this person’s retention at risk?
      </p>
      <ul className="list-disc list-outside mt-4 pl-3">
        <li className="ml-[1.7rem] pl-3">
          <span className="font-bold">Salary expectations: </span>has voiced a mismatch between expectations and current
          compensation.
        </li>
        <li className="ml-[1.7rem] pl-3">
          <span className="font-bold">Market value/demand of profile </span>is high and might be receiving regular
          outreach from recruiters.
        </li>
        <li className="ml-[1.7rem] pl-3">
          <span className="font-bold">Lack of motivation: </span>has voiced not being challenged by current tasks.
        </li>
        <li className="ml-[1.7rem] pl-3">
          Has had <span className="font-bold">issues with colleagues/management.</span>
        </li>
        <li className="ml-[1.7rem] pl-3">
          Has voiced discontent with <span className="font-bold">schedule flexibility.</span>
        </li>
        <li className="ml-[1.7rem] pl-3">
          Shows signs of <span className="font-bold">burnout/stress.</span>
        </li>
        <li className="ml-[1.7rem] pl-3">
          Unsatisfaction with <span className="font-bold">work/life balance.</span>
        </li>
        <li className="ml-[1.7rem] pl-3">
          Perceived <span className="font-bold">lack of career growth </span>options in their development plan.
        </li>
        <li className="ml-[1.7rem] pl-3">
          Shows signs of <span className="font-bold">clash with company culture.</span>
        </li>
        <li className="ml-[1.7rem] pl-3">
          There’s a mismatch in <span className="font-bold">technologies used </span>and technologies preferred.
        </li>
        <li className="ml-[1.7rem] pl-3">Any other areas you consider relevant - please share in the notes!</li>
      </ul>
    </div>
  </div>
)

const RetentionAtRiskMessage = () => {
  return (
    <div className="text-left max-h-[300px] overflow-x-hidden overflow-y-scroll modal-scroll">
      <h1 className="text-4xl mb-8">Retention at Risk</h1>
      <div className="text-2xl leading-10">{talentAtRiskData}</div>
    </div>
  )
}

export default RetentionAtRiskMessage
