import { AppSelect } from '../../atoms/AppSelect/AppSelect'
import ClearIconSVG from '../../atoms/Icons/ClearIcon'

interface filterType {
  condition: string
  option?: string
  level?: string
}

interface conditionType {
  value: string
  label: string
}
interface ResourcesFilterProps {
  filter: filterType
  conditions: conditionType[]
  jobTitles: string[]
  technologies: string[]
  onClick: (filter: filterType) => void
  onDelete: () => void
}

export const ResourcesFilter = ({
  filter,
  conditions,
  jobTitles,
  technologies,
  onClick,
  onDelete,
}: ResourcesFilterProps): JSX.Element => {
  const handleSelections = () => {
    onClick(filter)
  }

  const handleDeleteFilter = () => {
    onDelete()
  }

  const getOptionsByCondition = () => {
    if (filter.condition === 'Job position')
      return jobTitles.map(title => {
        return { value: title, label: title }
      })

    if (filter.condition === 'Technology')
      return technologies.map(tech => {
        return { value: tech, label: tech }
      })

    return [
      { value: 'High', label: 'High' },
      { value: 'Medium', label: 'Medium' },
      { value: 'Low', label: 'Low' },
    ]
  }

  return (
    <div className="flex flex-row items-center gap-5 mb-4">
      <AppSelect
        placeholder="Select condition"
        value={filter.condition}
        options={conditions}
        className="w-72"
        onChange={value => {
          filter.condition = value
          filter.option = ''
          filter.level = ''
          handleSelections()
        }}
      ></AppSelect>
      {filter.condition && (
        <AppSelect
          placeholder="Select option"
          value={filter.option}
          options={getOptionsByCondition()}
          filter={filter.condition === 'Technology' || filter.condition === 'Job position'}
          className="w-72"
          onChange={value => {
            filter.option = value
            handleSelections()
          }}
        ></AppSelect>
      )}
      {filter.condition === 'Technology' && filter.option && (
        <AppSelect
          placeholder="Select level"
          value={filter.level}
          options={[
            { value: 'Advanced', label: 'Advanced' },
            { value: 'Medium', label: 'Medium' },
            { value: 'Basic', label: 'Basic' },
          ]}
          className="w-72"
          onChange={value => {
            filter.level = value
            handleSelections()
          }}
        ></AppSelect>
      )}
      <span
        onClick={() => {
          handleDeleteFilter()
        }}
      >
        <ClearIconSVG width={15} height={15} />
      </span>
    </div>
  )
}
