import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { classNames } from 'primereact/utils'

interface CellLinkProps {
  link: string
  text: string
  tooltip?: boolean
  textTooltip?: string
  icon?: React.ReactNode
  iconRightPosition?: boolean
  tooltipBody?: React.ReactNode
  blockClassname?: string
  textClassname?: string
  iconClassname?: string
  onClickIcon?: () => void
  onClick?: () => void
}

export const CellLink: FC<CellLinkProps> = ({
  link,
  text,
  tooltip,
  textTooltip,
  icon,
  tooltipBody,
  textClassname = 'text-blue',
  iconClassname,
  iconRightPosition = false,
  onClickIcon,
  onClick,
  blockClassname,
}) => {
  const textStyleClassNames = {
    'cursor-pointer': onClick,
  }
  return (
    <Link to={link} onClick={onClick}>
      <div className={`flex flex-row w-48`}>
        {tooltip && (
          <Tooltip
            title={tooltipBody || textTooltip || text || ''}
            arrow
            enterDelay={500}
            enterNextDelay={500}
            classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
          >
            <div
              className={classNames(
                `flex gap-4 items-center leading-6 ${iconRightPosition ? 'flex-row-reverse' : null}`,
                blockClassname
              )}
            >
              <div
                className={classNames('cursor-pointer', iconClassname)}
                onClick={e => {
                  onClickIcon && onClickIcon()
                  e.stopPropagation()
                  e.preventDefault()
                }}
              >
                {icon && icon}
              </div>
              <span className={classNames(textClassname, textStyleClassNames)}>
                <span>{text}</span>
              </span>
            </div>
          </Tooltip>
        )}
      </div>
    </Link>
  )
}
