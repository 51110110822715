import { AnswerT } from '../types/definitions/answer'
import { Question } from '../types/definitions/question'

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

const getRandomMultipleSelectionValue = (question: Question & { options: string[] }) => {
  const amount = getRandomInt(0, question?.options.length - 1)
  const selectedOptions = []
  for (let i = 0; i < amount; i++) {
    const randomIndex = getRandomInt(0, question?.options.length - 1)
    selectedOptions.push(question.options[randomIndex])
  }
  return selectedOptions
}

const getRandomAnswerValue = (question: Question) => {
  switch (question.type) {
    case 'SCALE':
      return getRandomInt(question.min, question.max)
    case 'NUMERIC':
      return getRandomInt(question.min, question.max)
    case 'MULTIPLE_SELECTION':
      return getRandomMultipleSelectionValue(question)
    case 'SINGLE_SELECTION':
      return question.options[getRandomInt(0, question?.options.length - 1)]
    case 'RATE_SELECT':
      return question.options[getRandomInt(0, question?.options.length - 1)]
    case 'PILL_SELECT':
      return getRandomMultipleSelectionValue(question)
    default:
      console.error('Unknown question type, cannot get random answer value', question.type)
  }
}

export const fillAnswersRandomly = (questions: Question[], indexedAnswers: Record<string, AnswerT>) => {
  questions.map(question => {
    const indexedAnswer = indexedAnswers[question.id]
    indexedAnswer.answer = getRandomAnswerValue(question)
  })
}

export const _sortQuestions = (questions: Question[] = []): Question[] => {
  questions.sort((a, b): number => {
    if (!(a.order !== undefined && b.order !== undefined) || a.order === b.order) {
      return b.type.localeCompare(a.type)
    }
    return a.order > b.order ? 1 : -1
  })
  return questions
}
