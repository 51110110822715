import React, { FC } from 'react'
import { ArrowIcon } from '../atoms/Icons/ArrowIcon'
import classNames from 'classnames'

interface NextPrevArrowsProps {
  onNext: () => void
  onPrev: () => void
  isNextDisabled: boolean
  isPrevDisabled: boolean
  nextArrowLabel?: string
  prevArrowLabel?: string
}

export const NextPrevArrows: FC<NextPrevArrowsProps> = ({
  onNext,
  onPrev,
  isNextDisabled,
  isPrevDisabled,
  nextArrowLabel,
  prevArrowLabel,
}) => {
  const nextColor = isNextDisabled ? '#787878' : '#005ECA'
  const prevColor = isPrevDisabled ? '#787878' : '#005ECA'

  return (
    <div className="flex flex-1 justify-between text-2xl">
      <button
        onClick={onPrev}
        disabled={isPrevDisabled}
        className={classNames('flex items-center font-bold', {
          'text-stone-gray': isPrevDisabled,
          'text-blue': !isPrevDisabled,
        })}
      >
        <div className="mr-3">
          <ArrowIcon height={14} width={14} color={prevColor} />
        </div>
        {prevArrowLabel || 'Back'}
      </button>
      <button
        onClick={onNext}
        disabled={isNextDisabled}
        className={classNames('flex items-center font-bold', {
          'text-stone-gray': isNextDisabled,
          'text-blue': !isNextDisabled,
        })}
      >
        {nextArrowLabel || 'Next'}
        <div className="ml-3 rotate-180">
          <ArrowIcon height={14} width={14} color={nextColor} />
        </div>
      </button>
    </div>
  )
}
