import { useState, FC } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { talentRankData } from './talentRankData'

enum AccordionHeaderMessages {
  Collapsed = '+ Expand details',
  Expanded = '- Close details',
}

const TalentInfo: FC = ({ children }) => {
  const [headerTabContent, setHeaderTabContent] = useState<AccordionHeaderMessages>(AccordionHeaderMessages.Collapsed)

  return (
    <Accordion
      className="talent-accordion"
      onTabOpen={() => setHeaderTabContent(AccordionHeaderMessages.Expanded)}
      onTabClose={() => setHeaderTabContent(AccordionHeaderMessages.Collapsed)}
    >
      <AccordionTab
        header={headerTabContent}
        headerClassName="talent-accordion-header text-xl font-normal"
        contentClassName="talent-accordion-content text-2xl"
      >
        {children}
      </AccordionTab>
    </Accordion>
  )
}

const renderTalentRankData = () => {
  return talentRankData.map((talentRank, i) => {
    const { title, description, details, additionalInfo } = talentRank
    const showDetails = (details && !!details.length) || !!additionalInfo
    return (
      <div key={`rank-data-${title}-${i}`} className="my-4">
        <h3 className="font-bold">{title}</h3>
        <p>{description}</p>
        {showDetails && (
          <TalentInfo>
            {details && !!details.length && (
              <ul className="list-disc list-outside mt-4 pl-3">
                {details.map((detail, i) => (
                  <li key={`${title}-details-${i}`} className="ml-[1.7rem] pl-3">
                    {detail}
                  </li>
                ))}
              </ul>
            )}
            {additionalInfo && <p className="mt-4">{additionalInfo}</p>}
          </TalentInfo>
        )}
      </div>
    )
  })
}

const TalentRankMessage = () => {
  return (
    <div className="text-left max-h-[300px] overflow-x-hidden overflow-y-scroll modal-scroll">
      <h1 className="text-4xl mb-8">Talent Rank Definition</h1>
      <div className="text-2xl leading-10">{renderTalentRankData()}</div>
    </div>
  )
}

export default TalentRankMessage
