import { useEffect } from 'react'

import ChartRadar, { ChartDatasetType } from '../atoms/Charts/ChartRadar'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getData = () => {
  const d_2v_1: ChartDatasetType = {
    reviewDate: '',
    displayed: true,
    values: [74, 82],
    color: '#598AFE',
    reviewerName: '',
  }

  const d_3v_1: ChartDatasetType = {
    reviewDate: '',
    displayed: true,
    values: [90, 82, 40],
    color: '#598AFE',
    reviewerName: '',
  }

  const d_4v_1: ChartDatasetType = {
    reviewDate: '',
    displayed: true,
    values: [74, 82, 40, 33],
    color: '#598AFE',
    reviewerName: '',
  }

  const d_5v_1: ChartDatasetType = {
    reviewDate: '',
    displayed: true,
    values: [68, 80, 64, 72, 75],
    color: '#82E87A',
    reviewerName: '',
  }

  const d_5v_2: ChartDatasetType = {
    reviewDate: '',
    displayed: true,
    values: [33, 45, 20, 8, 42],
    color: '#FFA500',
    reviewerName: '',
  }

  const d_5v_3: ChartDatasetType = {
    reviewDate: '',
    displayed: true,
    values: [74, 82, 40, 80, 72],
    color: '#245AFE',
    reviewerName: '',
  }
  const d_5v_4: ChartDatasetType = {
    reviewDate: '',
    displayed: true,
    values: [80, 72, 74, 82, 40],
    color: '#82E87A',
    reviewerName: '',
  }

  const d_6v_1: ChartDatasetType = {
    reviewDate: '',
    displayed: true,
    values: [74, 82, 40, 80, 72, 23],
    color: '#598AFE',
    reviewerName: '',
  }

  const d_7v_1: ChartDatasetType = {
    reviewDate: '',
    displayed: true,
    values: [74, 82, 40, 80, 72, 23, 11],
    color: '#598AFE',
    reviewerName: '',
  }

  const d_8v_1: ChartDatasetType = {
    reviewDate: '',
    displayed: true,
    values: [74, 82, 40, 80, 72, 23, 11, 34],
    color: '#598AFE',
    reviewerName: '',
  }

  const d_9v_1: ChartDatasetType = {
    reviewDate: '',
    displayed: true,
    values: [74, 82, 40, 80, 72, 23, 11, 34, 95],
    color: '#598AFE',
    reviewerName: '',
  }

  return { d_2v_1, d_3v_1, d_4v_1, d_5v_1, d_5v_2, d_5v_3, d_5v_4, d_6v_1, d_7v_1, d_8v_1, d_9v_1 }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getLabels = () => {
  const l_2v1 = ['Capacity', 'Communication']
  const l_3v1 = ['Capacity', 'Communication', 'Best practices']
  const l_4v1 = ['Capacity', 'Communication', 'Best practices', 'Commitment']
  const l_5v1 = ['test', 'Communication', 'Best practices', 'Commitment', 'Tech stack skills']
  const l_6v1 = ['Capacity', 'Communication', 'Best practices', 'Commitment', 'Tech stack skills', 'Test']
  const l_7v1 = ['Capacity', 'Communication', 'Best practices', 'Commitment', 'Tech stack skills', 'Test', 'Cucumba']
  const l_8v1 = [
    'Capacity',
    'Communication',
    'Best practices',
    'Commitment',
    'Tech stack skills',
    'Test',
    'Cucumba',
    'Coby',
  ]
  const l_9v1 = [
    '|',
    'Communication',
    'Best practices',
    'Commitment',
    'Tech stack skills',
    'Test',
    'Cucumba',
    'Coby',
    'nine',
  ]

  return { l_2v1, l_3v1, l_4v1, l_5v1, l_6v1, l_7v1, l_8v1, l_9v1 }
}

const Charts = (): JSX.Element => {
  const { l_2v1, l_3v1, l_4v1, l_5v1, l_6v1, l_7v1, l_8v1, l_9v1 } = getLabels()
  const { d_2v_1, d_3v_1, d_4v_1, d_5v_1, d_5v_2, d_5v_3, d_5v_4, d_6v_1, d_7v_1, d_8v_1, d_9v_1 } = getData()

  useEffect(() => {
    const header = document.getElementById('landing-header')
    const main = document.getElementById('content-wrap')
    if (header && main) {
      header.setAttribute('dark-bg', '')
      main.setAttribute('dark-bg', '')
    }
  })

  return (
    <div className="charts">
      <ChartRadar labels={l_2v1} datasets={[d_2v_1]} />
      <ChartRadar labels={l_3v1} datasets={[d_3v_1]} />
      <ChartRadar labels={l_4v1} datasets={[d_4v_1]} />
      <ChartRadar labels={l_5v1} datasets={[d_5v_2]} />
      <ChartRadar labels={l_5v1} datasets={[d_5v_1, d_5v_2, d_5v_3, d_5v_4]} />
      <ChartRadar labels={l_6v1} datasets={[d_6v_1]} />
      <ChartRadar labels={l_7v1} datasets={[d_7v_1]} />
      <ChartRadar labels={l_8v1} datasets={[d_8v_1]} />
      <ChartRadar labels={l_9v1} datasets={[d_9v_1]} />
    </div>
  )
}

export default Charts
