import moment from 'moment'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { AppButton } from '../../atoms/AppButton/AppButton'
import { AppInput } from '../../atoms/AppInput/AppInput'
import { Popup } from '../../molecules/Popup'
import PeriodCalendar from './PeriodCalendar'
import { PeriodContext } from '../../pages/PeriodManagement'
import { Period } from '../../../types/definitions/Period'
import WarningIcon from '../../atoms/Icons/WarningIcon.svg'
import ErrorModal from '../../molecules/ErrorModal'
import SuccessModal from '../../molecules/SuccessModal'
import Spinner from '../../atoms/Spinner'
import classNames from 'classnames'
import WarningIconFilled from '../../atoms/Icons/WarningIconFilled.svg'
import { DateFormat } from '../../../utils/periods/PeriodUtils'
import Tick from '../../atoms/Icons/Tick'
import { CONTACT_EMAIL } from '../../../constants/general'

export const PeriodConfModalModeEnum = {
  CREATE: 'Create assessment period',
  EDIT: 'Edit assessment period',
  DELETE: 'Delete assessment period',
}

export const InfoTextModes = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
}

interface PeriodConfModalProps {
  period?: Period
  mode?: string
  nextAvailableDate?: Date
  onClose: () => void
}

const EMAIL = `mailto:${CONTACT_EMAIL}`

const PeriodConfModal = ({
  period,
  onClose,
  mode = PeriodConfModalModeEnum.CREATE,
  nextAvailableDate,
}: PeriodConfModalProps): JSX.Element => {
  const [validInputs, setValidInputs] = useState(false)
  const [validName, setValidName] = useState(false)
  const [name, setName] = useState<string>(mode === PeriodConfModalModeEnum.EDIT ? period?.name ?? '' : '')
  const [deleteName, setDeleteName] = useState('')
  const [startDate, setStartDate] = useState<Date | undefined>(period?.startDate)
  const [endDate, setEndDate] = useState<Date | undefined>(period?.endDate)
  const { createPeriod, updatePeriod, deletePeriod, pullPeriods, checkRangeOverlapsPeriods } = useContext(PeriodContext)
  const [minEndDate, setMinEndDate] = useState<Date>()
  const [showModal, setShowModal] = useState(true)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [infoMode, setInfoMode] = useState(InfoTextModes.INFO)
  const [infoMsg, setInfoMsg] = useState<ReactNode>()
  const [editSameInputs, setEditSameInputs] = useState(false)

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  useEffect(() => {
    if (mode === PeriodConfModalModeEnum.CREATE || mode === PeriodConfModalModeEnum.EDIT) {
      if (
        validName &&
        startDate &&
        endDate &&
        startDate <= endDate &&
        !checkRangeOverlapsPeriods(startDate, endDate, period?.id)
      ) {
        if (mode === PeriodConfModalModeEnum.CREATE) setValidInputs(true)
        else {
          // edit mode
          if (period && period.name === name && period.startDate === startDate && period.endDate === endDate) {
            setEditSameInputs(true)
            setValidInputs(false)
          } else {
            setEditSameInputs(false)
            setValidInputs(true)
          }
        }
      } else setValidInputs(false)
    } else {
      // delete mode
      if (period?.id && period?.name && period?.name === deleteName) setValidInputs(true)
    }
  }, [checkRangeOverlapsPeriods, period, deleteName, name, validName, startDate, endDate, mode])

  useEffect(() => {
    if (validInputs) {
      setInfoMode(InfoTextModes.SUCCESS)
      const msg = (
        <p>
          <b>This period looks great!</b> Click on <b>Confirm</b> to{' '}
          {mode === PeriodConfModalModeEnum.CREATE ? 'create' : 'edit'} it.
        </p>
      )
      setInfoMsg(msg)
    } else {
      // inputs not valid, so we must find the reason why and communicate
      // it to the user
      if (!startDate) {
        const msg = (
          <p>
            <b>{`The current period will end on ${moment(nextAvailableDate).format('MM/DD/YYYY')}.`}</b> The current
            period and new period should not overlap. If they do, please update the current period Finish Date
            accordingly.
          </p>
        )
        setInfoMsg(msg)
      } else if (startDate && endDate) {
        if (startDate > endDate) {
          setInfoMode(InfoTextModes.ERROR)
          const msg = (
            <p>
              <b>{`The start date is greater than the end date!`}</b> Perhaps you should swap them around.
            </p>
          )
          setInfoMsg(msg)
        }
        const collisionPeriod = checkRangeOverlapsPeriods(startDate, endDate, period?.id)
        if (collisionPeriod) {
          setInfoMode(InfoTextModes.ERROR)
          // overlaps with `period`
          const msg = (
            <p>
              <b>
                The selected time frame overlaps with the period {`"${collisionPeriod.name}"`} (
                {`${moment(collisionPeriod.startDate).format(DateFormat)} - ${moment(collisionPeriod.endDate).format(
                  DateFormat
                )}`}
                )
              </b>
              . If you wish to use these dates, please edit the period <b>{`"${collisionPeriod.name}"`}</b> first to
              avoid the overlap.
            </p>
          )
          setInfoMsg(msg)
        } else if (mode === PeriodConfModalModeEnum.EDIT && editSameInputs) {
          setInfoMode(InfoTextModes.INFO)
          const msg = (
            <p>
              <b>{`The values are the same!`}</b> Please change something to edit it.
            </p>
          )
          setInfoMsg(msg)
        } else {
          setInfoMode(InfoTextModes.ERROR)
          // the name must be empty
          const msg = (
            <p>
              <b>Assessment period name required.</b>
              <br />
              This is a required field and cannot be left empty.
            </p>
          )
          setInfoMsg(msg)
        }
      }
    }
  }, [checkRangeOverlapsPeriods, startDate, endDate, nextAvailableDate, validInputs, editSameInputs, mode, period])

  const handleConfirm = () => {
    setShowModal(false)
    setLoading(true)
    setShowSuccessModal(true)
    if (mode === PeriodConfModalModeEnum.DELETE) {
      period?.id &&
        validInputs &&
        deletePeriod(period?.id)
          .then(() => {
            setLoading(false)
          })
          .catch(() => {
            setShowSuccessModal(false)
            setShowErrorModal(true)
          })
    } else {
      if (name && startDate && endDate && validInputs) {
        if (mode === PeriodConfModalModeEnum.EDIT && period?.id)
          updatePeriod(period?.id, name, startDate, endDate)
            .then(() => {
              setLoading(false)
            })
            .catch(() => {
              setShowSuccessModal(false)
              setShowErrorModal(true)
            })
        else
          createPeriod(name, startDate, endDate)
            .then(() => {
              setLoading(false)
            })
            .catch(() => {
              setShowSuccessModal(false)
              setShowErrorModal(true)
            })
      }
    }
  }

  const handleSelectStartDate = (date: Date) => {
    setMinEndDate(new Date(date.getTime()))
    if (endDate && endDate <= date) setEndDate(undefined)
    setStartDate(date)
  }

  const operationPerformed =
    mode === PeriodConfModalModeEnum.CREATE ? 'created' : mode === PeriodConfModalModeEnum.EDIT ? 'edited' : 'deleted'

  const successModalMessage = !loading ? (
    <p>
      <span className="text-[2.5rem]">Period successfully {operationPerformed}</span>
    </p>
  ) : (
    <div className="flex flex-1 justify-center mt-32">
      <Spinner />
    </div>
  )

  const editAndCreateForm = (
    <>
      <div className="w-full flex flex-col gap-2 border-b-[1px] border-solid pb-8 border-[#D8D8D8]">
        <span className="text-2xl font-bold leading-6">Assessment period name</span>
        <AppInput
          value={name}
          className="w-full"
          setValidation={valid => setValidName(valid)}
          onChange={input => setName(input)}
        />
        {mode === PeriodConfModalModeEnum.CREATE && (
          <span className="text-base text-toast-hover">e.g. Winter period</span>
        )}
      </div>
      <div className="w-full flex flex-col gap-2">
        <span className="text-2xl font-bold leading-6">Start Date</span>
        <PeriodCalendar value={startDate} onSelect={handleSelectStartDate} className="w-full" />
      </div>
      <div className="w-full flex flex-col gap-2">
        <span className="text-2xl font-bold leading-6">Finish Date</span>
        <PeriodCalendar
          disabled={!startDate}
          viewDate={minEndDate}
          minDate={minEndDate}
          value={endDate}
          onSelect={date => setEndDate(date)}
          className="w-full"
        />
      </div>
    </>
  )

  const deleteForm = (
    <>
      <div className="mx-auto">
        <WarningIcon width={50} height={50} />
      </div>
      <p className="text-2xl leading-6">
        Be careful! <b>Deleting a period cannot be undone</b> and all the data associated with it will be lost!
      </p>
      <div>
        <p className="mb-3 text-2xl font-bold leading-6">Please type the name of the period to continue:</p>
        <AppInput autoFocus placeholder={period?.name} className="w-full" onChange={input => setDeleteName(input)} />
      </div>
    </>
  )

  return (
    <>
      {showModal && (
        <Popup visible onClose={onClose} showCloseButton={false}>
          <div className="flex flex-col items-center">
            <span className="font-medium text-5xl leading-10">{mode}</span>
            <div className="w-2/5 py-10 flex flex-col gap-8 items-start">
              {mode === PeriodConfModalModeEnum.DELETE ? deleteForm : editAndCreateForm}
            </div>
            {mode !== PeriodConfModalModeEnum.DELETE && (
              <div
                className={classNames('w-3/5 flex flex-row items-center justify-center py-6 px-10 gap-3 rounded-md', {
                  'bg-pale-blue': infoMode === InfoTextModes.INFO,
                  'bg-red-button bg-opacity-10 ': infoMode === InfoTextModes.ERROR,
                  'bg-[#F1FCF6]': infoMode === InfoTextModes.SUCCESS,
                })}
              >
                {infoMode === InfoTextModes.ERROR && (
                  <div>
                    <WarningIconFilled width={24} height={24} />
                  </div>
                )}
                {infoMode === InfoTextModes.SUCCESS && (
                  <div>
                    <Tick width={24} height={24} />
                  </div>
                )}
                <div
                  className={classNames('text-2xl leading-10', {
                    'text-blue': infoMode === InfoTextModes.INFO,
                    'text-error-red': infoMode === InfoTextModes.ERROR,
                    'text-[#00BE4F]': infoMode === InfoTextModes.SUCCESS,
                  })}
                >
                  {infoMsg}
                </div>
              </div>
            )}
            <div className="flex justify-center gap-8 mt-8">
              <AppButton type="clear" onClick={onClose}>
                Cancel
              </AppButton>
              <AppButton onClick={handleConfirm} disabled={!validInputs} type="info">
                Confirm
              </AppButton>
            </div>
          </div>
        </Popup>
      )}
      {showErrorModal && (
        <ErrorModal
          icon={<WarningIcon />}
          visible={showErrorModal}
          message={
            <p>
              <span className={`modal-title`}>Something went wrong when modifying periods.</span>
              <br />
              <span className="text-grey">
                <b>Try reloading the page and try again.</b> If the problem persists,{' '}
                <a href={EMAIL}>contact support</a>.
              </span>
            </p>
          }
          onClose={() => {
            setShowErrorModal(false)
            setShowModal(true)
          }}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          visible={showSuccessModal}
          onClose={() => {
            setShowSuccessModal(false)
            onClose()
            pullPeriods()
          }}
          loading={loading}
          message={successModalMessage}
        />
      )}
    </>
  )
}

export default PeriodConfModal
