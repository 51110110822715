import React from 'react'
import { Employee, EmployeeDetails } from '../../../services/EmployeeService'
import { TeamDetails } from '../../../services/TeamService'
import { Period } from '../../../types/definitions/Period'
import { SortOrderEnum } from '../../../types/enums/sort-order.enum'
import { AvailablePeriods } from '../../../utils/periods/PeriodUtils'
import { GeneralStatusSortingProps, GeneralStatusSortingType } from '../../../utils/sortUtils'
import Breadcrumb, { BreadcrumbItem } from '../../atoms/Breadcrumb/Breadcrumb'
import GeneralStatusHeader from '../../molecules/Dashboard/GeneralStatus/GeneralStatusHeader'
import GeneralStatusTable from './GeneralStatusTable'

interface GeneralStatusProps {
  teams: TeamDetails[]
  employees: Employee[]
  previousEmployees: Employee[]
  selectedSort: GeneralStatusSortingType
  breadcrumbData: BreadcrumbItem[]
  currentUser: EmployeeDetails
  currentPeriod?: Period
  previousPeriod?: Period
  selectedPeriod: AvailablePeriods
  setSelectedSort: (field: GeneralStatusSortingProps, newSortOrder: SortOrderEnum) => void
  handleHierarchyNavigation: (teams: TeamDetails) => void
  handleBreadCrumbClick?: (employeeId: number) => void
  handleSelectedPeriod: (period: AvailablePeriods) => void
}

export type ReportTableType = 'progressDetails' | 'notLoggedLeaders'

const GeneralStatusContent: React.FC<GeneralStatusProps> = ({
  teams,
  employees,
  previousEmployees,
  selectedSort,
  breadcrumbData,
  currentUser,
  currentPeriod,
  previousPeriod,
  selectedPeriod,
  setSelectedSort,
  handleHierarchyNavigation,
  handleBreadCrumbClick,
  handleSelectedPeriod,
}) => {
  const selectedEmployeesData = selectedPeriod === AvailablePeriods.Current ? employees : previousEmployees
  const renderTable = () => {
    return (
      <div className="py-4">
        <Breadcrumb
          items={breadcrumbData}
          onItemSelected={handleBreadCrumbClick}
          lastItemSuffix={` (${teams.length} results)`}
        />
        <GeneralStatusTable
          teams={teams}
          employees={selectedEmployeesData}
          selectedSort={selectedSort}
          onSortSelected={setSelectedSort}
          handleHierarchyNavigation={handleHierarchyNavigation}
        />
      </div>
    )
  }

  return (
    <div>
      <GeneralStatusHeader
        currentUser={currentUser}
        currentPeriod={currentPeriod}
        previousPeriod={previousPeriod}
        selectedPeriod={selectedPeriod}
        handleSelectedPeriod={handleSelectedPeriod}
      />
      <div className="flex flex-col mb-12">{renderTable()}</div>
    </div>
  )
}

export default GeneralStatusContent
