import { mapScoreAPI } from '../api'
import { MapScoreResponse } from '../api/openapi'

export class MapScoresService {
  static async getMapScoresForEmployee(employeeId: number): Promise<MapScoreResponse[]> {
    return mapScoreAPI.getMapScoreUsingGET(employeeId).then(response => {
      return response.data as MapScoreResponse[]
    })
  }
}
