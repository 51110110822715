import { useEffect, useRef, useState } from 'react'

import history from '../../utils/history'
import { useQuery } from '../../hooks/useQuery'
import TextLine from '../atoms/TextLine'
import TeamChart from '../organisms/TeamChart'
import { Employee, EmployeeService, EmployeeSort } from '../../services/EmployeeService'
import { TableIcon } from '../atoms/Icons/TableIcon'
import { DiagramIcon } from '../atoms/Icons/DiagramIcon'
import { useSelector } from 'react-redux'
import { RootState } from '../../index'
import { normalizeText } from '../../utils/text'
import EmployeeAdminTable from '../organisms/EmployeeAdminTable'
import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import Spinner from '../atoms/Spinner'
import { AppButton } from '../atoms/AppButton/AppButton'
import Breadcrumb, { BreadcrumbItem } from '../atoms/Breadcrumb/Breadcrumb'
import { TabFilter, Filter } from '../organisms/TabFilter'
import classNames from 'classnames'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { PlusIcon } from '../atoms/Icons/PlusIcon'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../services/EventTrackingService'
import { SectionType } from './ReviewsPage'
import UploadIcon from '../atoms/Icons/UploadIcon'
import toast, { ToastNotification, createToastBody } from '../molecules/ToastNotification'
import { RosterService } from '../../services/RosterService'
import { ROSTER_IMPORT_TRIES } from '../../utils/RosterConstants'

const AdminPanelPage = (): JSX.Element => {
  const user = useSelector<RootState, Employee>(state => state.auth.user as Employee)
  const query = useQuery()
  const [breadcrumbData, setBreadcrumbData] = useState<BreadcrumbItem[]>([])
  const [employees, setEmployees] = useState<Employee[]>([])
  const [employeesToShow, setEmployeesToShow] = useState<Employee[]>([])
  const [searchInput, setSearchInput] = useState<string>(query.get('keyword') || '')
  const [searchIcon, setSearchIcon] = useState<boolean>(true)
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [activeSection, setActiveSection] = useState<SectionType>(query.get('view') === 'diagram' ? 'diagram' : 'table')
  const [uploadingRoster, setUploadingRoster] = useState<boolean>(false)
  const [selectedSort, setSelectedSort] = useState<EmployeeSort[]>([
    { property: 'name', order: SortOrderEnum.Ascending },
  ])
  const hiddenImportInput = useRef<HTMLInputElement>(null)

  // Creating an inside feature flag for toggling 'My Team' Filter tab
  const showDirectTab = false
  const filters = [new Filter('all', 'All'), ...(showDirectTab ? [new Filter('direct', 'My Team')] : [])]

  const [enabledFilter, setEnabledFilter] = useState<Filter>(filters[0])
  const handleFilterSelection = (selectedFilter: Filter) => {
    if (selectedFilter.key !== 'all') {
      setSearchInput('')
    }
    setEnabledFilter(selectedFilter)
    switch (selectedFilter.key) {
      case 'all':
        loadAllEmployees()
        break
      case 'direct':
        loadMyEmployees()
        break
    }
  }
  useEffect(() => {
    trackEvent(TrackingCategoryEnum.ADMIN, TrackingCategoryEventEnum.ADMIN.PAGE_ON_LOAD)
    loadAllEmployees()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    generateBreadcrumb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledFilter])

  const generateBreadcrumb = (): void => {
    const newBreadcrumbData = []
    let currentLabel = 'All'
    if (enabledFilter && enabledFilter.key !== 'all') {
      currentLabel = enabledFilter.label as string
    }
    newBreadcrumbData.push(
      searchInput && searchInput.length > 0
        ? { label: `Search (${employeesToShow.length} results)` }
        : { label: `${currentLabel} (${employeesToShow.length} results)` }
    )
    setBreadcrumbData(newBreadcrumbData)
  }

  useEffect(() => {
    generateBreadcrumb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesToShow])

  useEffect(() => {
    if (filters && normalizeText(searchInput) !== '' && enabledFilter.key !== 'all') {
      handleFilterSelection(filters[0])
    } else {
      const fEmployees = searchInput
        ? employees.filter(({ name, email }) => {
            const normalizedSearchText = normalizeText(searchInput)

            const inName = normalizeText(name).includes(normalizedSearchText)
            const inMail = normalizeText(email).includes(normalizedSearchText)

            return inName || inMail
          })
        : employees
      setEmployeesToShow(() => EmployeeService.sortEmployees(fEmployees, selectedSort))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees, searchInput, user])

  useEffect(() => {
    setEmployeesToShow(() => EmployeeService.sortEmployees(employeesToShow, selectedSort))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort])

  const loadEmployees = async (employeesLoadFunction: () => Promise<Employee[]>): Promise<void> => {
    setLoadingTableData(true)
    try {
      const employees = await employeesLoadFunction()
      setEmployees(employees)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingTableData(false)
    }
  }

  const loadAllEmployees = async (showLoading = true): Promise<void> => {
    return loadEmployees(() => EmployeeService.findAllAsAdmin(0, 1000))
  }

  const loadMyEmployees = async (): Promise<void> => {
    return loadEmployees(() => EmployeeService.findAllWithAccess(0))
  }

  const navigateToSection = (section: SectionType): void => {
    if (!section) return

    setActiveSection(section)
    query.set('view', section)
    history.replace({
      pathname: history.location.pathname,
      search: query.toString(),
    })
  }

  const handleEmployeeEdited = async (editedEmployee: Employee): Promise<void> => {
    if (editedEmployee) {
      await EmployeeService.update(editedEmployee.id, {
        email: editedEmployee.email,
        jobTitle: editedEmployee.jobTitle,
        name: editedEmployee.name,
        role: editedEmployee.role,
        salaryGrade: editedEmployee.salaryGrade,
        // TODO: UNCOMMENT WHEN YOU WANT TO ALLOW EDITING OF TEAMS
        // teams: editedEmployee.teams?.map(team => team.id),
      })
      const updatedEmployeeIdx = employees.findIndex(e => e.id === editedEmployee.id)
      if (updatedEmployeeIdx !== undefined) {
        employees[updatedEmployeeIdx] = editedEmployee
        setEmployees([...employees])
      }
    }
  }

  const handleEmployeeDeleted = async (employeeId: number): Promise<void> => {
    try {
      await EmployeeService.delete(employeeId)
      setEmployees(employees.filter(e => e.id !== employeeId))
    } catch (e) {
      console.error(e)
    }
  }

  const handleSortSelected = (property: string, order: SortOrderEnum): void => {
    setSelectedSort([{ property: property as keyof Employee, order }])
  }

  const handleSearchIcon = (value: string): void => {
    setSearchIcon(!value.length)
  }

  const handleClear = (): void => {
    setSearchInput('')
  }

  const handleOrgChart = () => {
    trackEvent(TrackingCategoryEnum.ADMIN, TrackingCategoryEventEnum.ADMIN.CLICK_ORG_CHART_BTN)
    navigateToSection('diagram')
  }

  const handleTable = () => {
    trackEvent(TrackingCategoryEnum.ADMIN, TrackingCategoryEventEnum.ADMIN.CLICK_TABLE_BTN)
    navigateToSection('table')
  }

  const handleNew = () => {
    trackEvent(TrackingCategoryEnum.ADMIN, TrackingCategoryEventEnum.ADMIN.CLICK_NEW_BTN)
    history.push(createPathWithLang(NetworkConstants.URL_CREATE_ACCOUNT))
  }

  const processRosterImportResponse = (response: any) => {
    if (response.status >= 200 && response.status < 300) {
      return true
    } else {
      return false
    }
  }

  const createRosterErrorToast = () => {
    toast.error(
      createToastBody({
        title: 'Error',
        description: 'There was a problem uploading the file, please try again.',
      })
    )
  }

  // This should be done because the roster import sometimes may fail. So to prevent the user from trying again manually, we try it a few times.
  // Recursion logic:
  // if numberOfTimes == 0  -> error, stop and send error toast
  // if numberOfTimes > 0   -> send request
  //     -> send request:
  //        - if successful -> success toast
  //        - if not successful -> try again (run recursion with numberOfTimes - 1)
  const sendRosterImportRequest = (rosterFile: any, numberOfTimes: number, callback: () => void) => {
    if (numberOfTimes === 0) {
      createRosterErrorToast()
      callback()
      return false
    }

    RosterService.importRoster(rosterFile)
      .then(response => {
        const successfulImport = processRosterImportResponse(response)
        if (successfulImport) {
          toast.success(
            createToastBody({ title: 'Roster uploaded', description: 'The file has been uploaded successfully!' })
          )
          callback()
          return true
        } else {
          return sendRosterImportRequest(rosterFile, numberOfTimes - 1, callback)
        }
      })
      .catch(error => {
        return sendRosterImportRequest(rosterFile, numberOfTimes - 1, callback)
      })
  }

  const handleRosterImport = () => {
    trackEvent(TrackingCategoryEnum.ADMIN, TrackingCategoryEventEnum.ADMIN.CLICK_ROSTER_IMPORT_BTN)

    const rosterFile = hiddenImportInput.current?.files?.[0]

    if (!rosterFile) {
      toast.error(createToastBody({ title: 'Error', description: 'No file was selected.' }))
    } else {
      setUploadingRoster(true)
      const loadingToast = toast.loading('Uploading Roster file...')

      const closeImportAnimations = () => {
        setUploadingRoster(false)
        toast.dismiss(loadingToast)
      }

      sendRosterImportRequest(rosterFile, ROSTER_IMPORT_TRIES, closeImportAnimations)
    }
  }

  if (!user) {
    return <Spinner />
  }

  return (
    <div className="px-4 flex-grow flex flex-col">
      <ToastNotification />
      <div className="container mt-10 flex flex-wrap gap-4 md:gap-0">
        <h1 id="title" className="lg:absolute text-6xl text-dark-blue font-semibold">
          Manage users
        </h1>
        <div className="flex-1 flex justify-end lg:justify-center lg:mx-auto">
          <TextLine
            className="text-border border-blue chooseInput"
            type="text"
            value={searchInput}
            searchIcon={searchIcon}
            onChange={v => {
              setSearchInput(v)
              handleSearchIcon(v)
            }}
            onClear={handleClear}
            placeholder={'Quick find any team member'}
          />
        </div>
      </div>
      <div className="container mt-10 mb-4 relative flex flex-col gap-10">
        <div className={classNames({ flex: true, 'flex justify-end': activeSection === 'diagram' })}>
          <TabFilter
            filters={filters}
            onActiveFilterChange={handleFilterSelection}
            enabledFilter={enabledFilter}
          ></TabFilter>
          <div className="flex justify-end admin-button-container gap-4">
            {activeSection === 'diagram' ? (
              <AppButton type="clear" onClick={handleTable} className="gap-3">
                <TableIcon />
                <span>Table</span>
              </AppButton>
            ) : (
              <AppButton type="clear" onClick={handleOrgChart} className="gap-3">
                <DiagramIcon />
                <span>Org chart</span>
              </AppButton>
            )}
            <AppButton type="clear" onClick={handleNew} className="gap-3">
              <PlusIcon />
              <span>New</span>
            </AppButton>
            <>
              <AppButton
                type="info"
                onClick={() => {
                  hiddenImportInput.current?.click()
                }}
                className="gap-3"
                disabled={uploadingRoster}
              >
                <UploadIcon />
                <span>{uploadingRoster ? 'Uploading...' : 'Upload Roster'}</span>
              </AppButton>
              <input
                type="file"
                ref={hiddenImportInput}
                className="hidden"
                accept=".csv"
                onChange={handleRosterImport}
              />
            </>
          </div>
        </div>
        {activeSection === 'table' && breadcrumbData.length && !loadingTableData ? (
          <Breadcrumb items={breadcrumbData} />
        ) : null}
      </div>
      {loadingTableData ? (
        <Spinner />
      ) : (
        <div className="container flex-grow mx-auto">
          {activeSection === 'table' ? (
            <>
              <EmployeeAdminTable
                employees={employeesToShow}
                selectedSort={selectedSort[0]}
                onEdit={handleEmployeeEdited}
                onDelete={handleEmployeeDeleted}
                onSortSelected={handleSortSelected}
              />
            </>
          ) : (
            <>
              <TeamChart
                employees={employees}
                highlight={searchInput ? employeesToShow.map(employee => employee.id) : undefined}
                currentUser={user}
              />
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default AdminPanelPage
