import { ReviewInfo } from './review'
import { TeamInfo } from './team'

export type Employee = EmployeeInfo & EmployeeFields
export enum Role {
  User = 'user',
  Reviewer = 'reviewer',
  Admin = 'admin',
}
export interface EmployeeInfo {
  id: number
  name: string
  jobTitle?: string
  email: string
  salaryGrade?: string
}
interface EmployeeFields {
  companyName?: string
  teams: Array<TeamInfo>
  reviewIds: Array<number>
  reviews: Array<ReviewInfo>
  role: Role
}
