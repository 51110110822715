import { ColumnProps } from 'primereact/column'
import classNames from 'classnames'
import React from 'react'
import { Tooltip } from '@material-ui/core'
import { getValueFromLiteralPath } from '../../../utils/objectUtils'

export interface TableCellBodyParams<T> {
  tooltip?: boolean
  tooltipBody?: React.ReactNode
  getValue?: (rowData: T) => React.ReactNode
  numericStyles?: boolean
  onClick?: (rowData: T) => void
}
const MINIMUM_SCORE_VALUE = 30
export function TableCellBody<T>({
  getValue,
  tooltip,
  tooltipBody,
  numericStyles = false,
  onClick,
}: TableCellBodyParams<T> = {}) {
  return (rowData: T, props: ColumnProps): JSX.Element => {
    const handleClick = (e: React.MouseEvent) => {
      if (onClick !== undefined) {
        e.stopPropagation()
        onClick(rowData)
      }
    }

    const getCellValue = () => {
      if (getValue) return getValue(rowData)
      return getValueFromLiteralPath<string>(rowData, props.field || '')
    }
    const spanClassName = classNames({
      'text-ellipsis overflow-hidden text-xl': true,
      'text-error-red font-bold': Number(getCellValue()) < MINIMUM_SCORE_VALUE && numericStyles,
      'cursor-pointer hover:underline': onClick !== undefined,
    })
    const defaultTableCell = (cellValue: React.ReactNode) => {
      return tooltip ? (
        <Tooltip
          title={tooltipBody || cellValue || ''}
          arrow
          enterDelay={500}
          enterNextDelay={500}
          classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
        >
          <span onClick={handleClick} className={spanClassName}>
            {cellValue}
          </span>
        </Tooltip>
      ) : (
        <div className="truncate leading-8">
          <span onClick={handleClick} className={spanClassName}>
            {cellValue}
          </span>
        </div>
      )
    }

    return defaultTableCell(getCellValue())
  }
}
