import { classNames } from 'primereact/utils'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../..'
import { finishTutorial, showHelpId } from '../../slices/help-slice'
import { HelpContentKeys, HelpContent } from '../../utils/HelpContent'
import { SmallArrowButton } from '../atoms/SmallArrowButton/SmallArrowButton'
import { AppButton } from '../atoms/AppButton/AppButton'
import AppTooltip from '../atoms/AppTooltip'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

interface ParentPreviousState {
  element: HTMLElement
  position: string | undefined
  sticky: boolean
  relative: boolean
  absolute: boolean
  fixed: boolean
  zIndex: string | undefined
}

interface HelpTooltipProps {
  children: React.ReactElement
  childrenClassNames?: string
  containerClassNames?: string
  id: HelpContentKeys
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}
const HelpTooltip = ({
  id,
  children,
  childrenClassNames = 'relative z-50 bg-white flex flex-wrap w-fit items-center p-6 flex-1',
  containerClassNames = 'flex relative',
  placement = 'right-start',
}: HelpTooltipProps) => {
  const current = useSelector<RootState, string>(state => state.help.current as string)
  const [currentRef, setCurrentRef] = useState<HTMLDivElement>()
  const [parentsChecked, setParentsChecked] = useState<boolean>(false)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()

  const handleSkipClick = () => {
    window.scrollTo(0, 0)
    restoreParentPositioningStyles()
    dispatch(showHelpId(''))
    dispatch(finishTutorial(id))
  }

  const handleNextClick = () => {
    restoreParentPositioningStyles()
    dispatch(showHelpId(HelpContent[id].next || ''))
  }

  const handleBackClick = () => {
    restoreParentPositioningStyles()
    dispatch(showHelpId(HelpContent[id].previous || ''))
  }

  const handleCloseClick = () => {
    window.scrollTo(0, 0)
    restoreParentPositioningStyles()
    dispatch(showHelpId(''))
    HelpContent[id].finish && dispatch(finishTutorial(id))
  }

  useEffect(() => {
    if (tooltipRef.current && current === id) {
      window.scrollTo(0, tooltipRef.current.offsetTop - 258)
    }
  })

  const childContainerRef = useRef<HTMLDivElement>(null)

  const removeParentPositioningStyles = (element: HTMLElement, previousState: ParentPreviousState): void => {
    if (previousState.position && previousState.position !== '') {
      element.style.position = 'static'
    }
    if (previousState.zIndex && previousState.zIndex !== '') {
      element.style.zIndex = 'auto'
    }
    if (previousState.sticky) {
      element.classList.remove('sticky')
    }
    if (previousState.absolute) {
      element.classList.remove('absolute')
    }
    if (previousState.fixed) {
      element.classList.remove('fixed')
    }
    if (previousState.relative) {
      element.classList.remove('relative')
    }
  }

  const restoreParentPositioningStyles = (): void => {
    parentsPreviousState.forEach(previousState => {
      const element = previousState.element
      if (previousState.position && previousState.position !== '') {
        element.style.position = previousState.position
      }
      if (previousState.zIndex && previousState.zIndex !== '') {
        element.style.zIndex = previousState.zIndex
      }
      if (previousState.sticky) {
        element.classList.add('sticky')
      }
      if (previousState.absolute) {
        element.classList.add('absolute')
      }
      if (previousState.fixed) {
        element.classList.add('fixed')
      }
      if (previousState.relative) {
        element.classList.add('relative')
      }
    })
  }

  const [parentsPreviousState, setParentsPreviousState] = useState<ParentPreviousState[]>([])
  useEffect(() => {
    if (childContainerRef && !parentsChecked && current === id) {
      let parent = childContainerRef?.current?.parentElement
      while (parent) {
        const parentState: ParentPreviousState = {
          element: parent,
          position: parent.style.position,
          sticky: parent.classList.contains('sticky'),
          relative: parent.classList.contains('relative'),
          absolute: parent.classList.contains('absolute'),
          fixed: parent.classList.contains('fixed'),
          zIndex: parent.style.getPropertyValue('z-index'),
        }
        if (
          parentState.position ||
          parentState.sticky ||
          parentState.relative ||
          parentState.absolute ||
          parentState.fixed ||
          parentState.zIndex
        ) {
          removeParentPositioningStyles(parent, parentState)
          parentsPreviousState.push(parentState)
        }
        parent = parent.parentElement
      }

      setParentsPreviousState(parentsPreviousState)
      setParentsChecked(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childContainerRef, current, id])

  const HelpTooltipHeader = ({ id }: { id: HelpContentKeys }) => {
    return (
      <div className="flex mb-6 w-full">
        <div className="flex shrink-0 w-4">
          {HelpContent[id].previous && <SmallArrowButton type="previous" onClick={handleBackClick} />}
        </div>
        <div className="flex-grow text-center text-xl text-gray-3">{HelpContent[id].subtitle}</div>
        <div className="flex shrink-0 w-4">
          {HelpContent[id].next && <SmallArrowButton type="next" onClick={handleNextClick} />}
        </div>
      </div>
    )
  }

  const HelpTooltipBody = ({ id }: { id: HelpContentKeys }) => {
    return (
      <div
        className="flex flex-col items-start !min-h-[227px] !max-h-[265px] justify-between"
        ref={ref => ref && currentRef === undefined && setCurrentRef(ref)}
        id={id}
      >
        <div className="mb-6 w-full">
          <HelpTooltipHeader id={id} />
          <div className="text-3xl font-medium leading-8 mb-4">{HelpContent[id].title}</div>
        </div>
        <p className="text-xl font-medium mb-8 break-words whitespace-pre-line">
          <ReactMarkdown>{`${HelpContent[id].body}`}</ReactMarkdown>
        </p>
        <div className="flex flex-row w-full text-xl justify-start pt-2">
          {HelpContent[id].skip && (
            <AppButton
              className="!min-w-[0px] !w-1/2 !bg-white pl-0 !justify-start"
              type="clear"
              onClick={handleSkipClick}
            >
              Skip tutorial
            </AppButton>
          )}
          {HelpContent[id].next ? (
            <AppButton className="!min-w-[0px] !w-1/2" type="info" onClick={handleNextClick}>
              {HelpContent[id].nextButtonText || 'Next'}
            </AppButton>
          ) : (
            <div className="flex w-full !justify-end">
              <div className="flex w-full !justify-end">
                <AppButton className="!px-8 min-w-[8rem]" type="info" onClick={handleCloseClick}>
                  Start now
                </AppButton>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
  const open = current === id
  const tooltipClassName = classNames({
    'bg-white z-0 shadow-lg rounded-lg !w-[284px] !min-h-[227px] p-8 text-dark-blue text-2xl leading-normal': true,
    'my-14': placement.includes('bottom') || placement.includes('top'),
    'mr-12': placement.includes('left'),
    'ml-12': placement.includes('right'),
  })
  if (id) {
    return (
      <div className={containerClassNames} ref={tooltipRef}>
        <div ref={childContainerRef} className={open ? childrenClassNames : 'w-full'}>
          {children}
        </div>
        <AppTooltip
          classes={{
            tooltip: tooltipClassName,
            arrow: 'dot-tooltip-arrow',
          }}
          interactive={true}
          placement={placement}
          title={<HelpTooltipBody id={id} />}
          backdrop={true}
          open={open}
        >
          <span></span>
        </AppTooltip>
      </div>
    )
  }
  return null
}

export default HelpTooltip
