import { Redirect, Route, Switch, RouteComponentProps, Router } from 'react-router-dom'
import NotFound from './components/pages/NotFound'
import { createPathWithLang } from './utils/languagePathUtils'
import routes, { defaultRoute, loginTokenRoute, Roles, Route as RouteModel } from './utils/routes'
import Header from './components/organisms/Header'
import { useSelector } from 'react-redux'
import history from './utils/history'
import { useEffect } from 'react'
import { EmployeeDetails } from './services/EmployeeService'
import { RootState } from './'
import { useAuthJWT } from './utils/middlewares/authJWT'
import { NetworkConstants } from './utils/NetworkConstants'
import ProtectedRoute from './components/organisms/ProtectedRoute'
import Footer from './components/organisms/Footer'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from './services/EventTrackingService'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = (): JSX.Element => {
  useAuthJWT()
  const user = useSelector<RootState, EmployeeDetails>(state => state.auth.user as EmployeeDetails)

  useEffect(() => {
    console.log(`
  __ _ _ __ __ _ _ __   ___  ___
 / _\` | '__/ _\` | '_ \\ / _ \\/ __|
| (_| | | | (_| | |_) |  __/\\__ \\
 \\__, |_|  \\__,_| .__/ \\___||___/  ${process.env.REACT_APP_FE_VERSION ?? 'local'}
  __/ |         | |
 |___/          |_|
    `)
    const handleTabClose = (event: any) => {
      trackEvent(TrackingCategoryEnum.AUTHENTICATION, TrackingCategoryEventEnum.AUTHENTICATION.USER_CLOSE_APP)
      return true
    }

    window.addEventListener('beforeunload', handleTabClose)

    return () => {
      window.removeEventListener('beforeunload', handleTabClose)
    }
  }, [])

  useEffect(() => {
    const loginPath = createPathWithLang(defaultRoute.path)
    const loginTokenPath = createPathWithLang(loginTokenRoute.path)
    const isLogged = !!user
    const isAtLogin = history.location.pathname === loginPath || history.location.pathname === loginTokenPath
    if (!(isLogged || isAtLogin)) {
      history.push(history.location.pathname === loginPath ? loginPath : history.location.pathname)
    }
  }, [user])

  const buildRouteTag = (route: RouteModel, index: number): JSX.Element => {
    if (route.roles.length) {
      return (
        <ProtectedRoute key={index} path={route.path && createPathWithLang(route.path)} route={route}>
          {getRouteComponent(route)}
        </ProtectedRoute>
      )
    }

    return (
      <Route key={index} path={route.path && createPathWithLang(route.path)}>
        {getRouteComponent(route)}
      </Route>
    )
  }

  const buildRedirectTag = (route: RouteModel, index: number): JSX.Element | null => {
    return route.path?.length ? (
      <Route exact key={`redirect-${index}`} path={route.path} render={buildRedirectToLang(route.path)} />
    ) : null
  }

  const buildRedirectToLang = (path: string, preserveQueryParams = true) => {
    return (props: RouteComponentProps) => {
      return (
        <Redirect
          to={{ pathname: createPathWithLang(path), search: preserveQueryParams ? props.location.search : '' }}
        />
      )
    }
  }

  const getRouteComponent = (route: RouteModel): JSX.Element => {
    return (
      <div className="flex flex-col min-h-screen">
        {!route.fullPage && <Header iconURL={NetworkConstants.URL_HOME_PATH} />}
        <route.component />
        <Footer />
      </div>
    )
  }

  const buildHomeRedirect = (role?: string): JSX.Element => {
    if (role === Roles.USER_ROLE) {
      return <Redirect exact from="/" to={createPathWithLang(NetworkConstants.URL_TECH_PROFILE)} />
    } else if (role === Roles.ADMIN_ROLE) {
      return <Redirect exact from="/" to={createPathWithLang(NetworkConstants.URL_DASHBOARD)} />
    }
    return <Redirect exact from="/" to={createPathWithLang(NetworkConstants.URL_REVIEW_LIST)} />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <Switch>
          <Route exact path={NetworkConstants.URL_HOME_PATH}>
            {buildHomeRedirect(user?.role)}
          </Route>
          {routes.map(buildRedirectTag)}
          {routes.map(buildRouteTag)}
          <Route path="/*" component={NotFound} />
        </Switch>
      </Router>
    </QueryClientProvider>
  )
}

export default App
