interface QuestionIconProps {
  width?: number
  height?: number
}

export const QuestionIcon = ({ width = 12, height = 12 }: QuestionIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
        stroke="#005ECA"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.54504 4.4998C4.6626 4.16563 4.89462 3.88385 5.20002 3.70436C5.50542 3.52488 5.86449 3.45927 6.21363 3.51915C6.56277 3.57904 6.87945 3.76056 7.10758 4.03156C7.33571 4.30256 7.46057 4.64556 7.46004 4.9998C7.46004 5.9998 5.96004 6.4998 5.96004 6.4998"
        stroke="#005ECA"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 8.5H6.005" stroke="#005ECA" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
