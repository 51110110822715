import { AppButton } from '../atoms/AppButton/AppButton'
import SuccessIcon from '../atoms/Icons/SuccessIcon.svg'

import { Popup } from './Popup'

type SuccessModalProps = {
  visible: boolean
  icon?: boolean
  loading?: boolean
  message?: JSX.Element | React.ReactElement
  buttonText?: string
  onClose: () => void
}

const SuccessModal = ({
  visible,
  icon = true,
  message,
  buttonText = 'Ok',
  loading = false,
  onClose,
}: SuccessModalProps): JSX.Element => {
  return (
    <Popup visible={visible} onClose={onClose} showCloseButton={false}>
      <div className="text-center [&>svg]:m-auto">
        {icon && !loading && <SuccessIcon />}
        {message && <div className="text-2xl leading-[40px] py-20">{message}</div>}
      </div>
      <div className="flex justify-center gap-8">
        {!loading && (
          <AppButton type="info" onClick={onClose}>
            {buttonText}
          </AppButton>
        )}
      </div>
    </Popup>
  )
}

export default SuccessModal
