import { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react'
import Validator from 'validator'

interface TextLineProps {
  className?: string
  type?: string
  placeholder?: string
  searchIcon?: boolean
  label?: string
  value: string
  disabled?: boolean
  autoComplete?: string
  warningText?: string
  onChange: (inputValue: string) => void
  onSubmit?: (inputValue: string) => void
  setValidation?: (isValid: boolean) => void
}

const TextLine = (props: TextLineProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null)
  const maxLength = 240

  const isValid = (): boolean => {
    if (inputRef.current === null) {
      return false
    }
    const value = inputRef.current.value
    return !Validator.isEmpty(value) && Validator.isLength(value, { max: maxLength })
  }

  const onChange = (_: ChangeEvent<HTMLInputElement>): void => {
    if (inputRef.current != null) {
      props.onChange(inputRef.current.value)
    }
  }

  const onKeyUp = (keyPressed: KeyboardEvent): void => {
    if (keyPressed.key === 'Enter' && inputRef.current != null) {
      props.onSubmit?.(inputRef.current.value)
    }
  }

  useEffect(() => {
    props.setValidation?.(isValid())
  })

  return (
    <div className={'input-content'}>
      {props.label && <span className={'label'}>{props.label}</span>}
      <input
        className={`${props.className ?? 'textline'}${props.searchIcon ? ' searchIcon' : ''} ${
          props.warningText ? 'error' : ''
        }`}
        type={props.type ?? 'text'}
        ref={inputRef}
        value={props.value}
        placeholder={props.placeholder}
        onChange={onChange}
        onKeyUp={onKeyUp}
        maxLength={maxLength}
        disabled={props.disabled}
        autoComplete={props.autoComplete}
      />
      {props.warningText ? (
        <span>
          <p className="warning-text">{props.warningText}</p>
        </span>
      ) : (
        <p className="warning-text">
          <br />
        </p>
      )}
    </div>
  )
}

export default TextLine
