import { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import { RootState, useAppDispatch } from '../..'
import { ConstantIDs } from '../../constants/employee'
import { CONTACT_EMAIL } from '../../constants/general'
import useNextPrevKeyboard from '../../hooks/useNextPrevKeyboard'
import { useQuery } from '../../hooks/useQuery'
import { useTopics } from '../../hooks/useTopics'
import { EmployeeDetails, EmployeeService } from '../../services/EmployeeService'
import { TrackingCategoryEnum, TrackingCategoryEventEnum, trackEvent } from '../../services/EventTrackingService'
import { FormService } from '../../services/FormService'
import { AnswerToBeAddedToReview, ReviewService } from '../../services/ReviewService'
import { finishTutorial, hideTutorial, showHelpId } from '../../slices/help-slice'
import { AnswerT } from '../../types/definitions/answer'
import { Form } from '../../types/definitions/form'
import { FormCategory } from '../../types/definitions/formCategory'
import { AnsweredQuestion, Question, QuestionTopic } from '../../types/definitions/question'
import { Review } from '../../types/definitions/review'
import { QuestionTypeEnum } from '../../types/enums/question-type.enum'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { scoredCategories } from '../../utils/QuestionTopics'
import { getFormById, mapFormsToFormSelectOptions, sortFormCategories } from '../../utils/forms/FormUtils'
import { iconsEmployee } from '../../utils/iconsUtils'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { _sortQuestions, fillAnswersRandomly } from '../../utils/questionUtils'
import { AppButton } from '../atoms/AppButton/AppButton'
import { AppSelect } from '../atoms/AppSelect/AppSelect'
import { ArrowIcon } from '../atoms/Icons/ArrowIcon'
import DeleteIcon from '../atoms/Icons/DeleteIcon.svg'
import GarbageSVG from '../atoms/Icons/Garbage.svg'
import WarningIcon from '../atoms/Icons/WarningIcon.svg'
import Spinner from '../atoms/Spinner'
import { CategorySelector } from '../molecules/CategorySelector/CategorySelector'
import ErrorModal from '../molecules/ErrorModal'
import { NavigationBlocker } from '../molecules/ExitBlocker/NavigationBlocker'
import HelpTooltip from '../molecules/HelpTooltip'
import OptionsModal from '../molecules/OptionsModal'
import { ChecklistQuestion } from '../molecules/Questions/ChecklistQuestion/ChecklistQuestion'
import { TrueFalseQuestion } from '../molecules/Questions/ChecklistQuestion/TrueFalseQuestion'
import { DropdownQuestion } from '../molecules/Questions/DropdownQuestion/DropdownQuestion'
import { SelectQuestion } from '../molecules/Questions/SelectQuestion/SelectQuestion'
import { SliderQuestion } from '../molecules/Questions/SliderQuestion/SliderQuestion'
import SliderTextQuestion from '../molecules/Questions/SliderTextQuestion/SliderTextQuestion'
import { TextareaQuestion } from '../molecules/Questions/TextareaQuestion/TextareaQuestion'
import { TutorialModal } from '../molecules/TutorialModal'
import EmployeeReviewFormSelectionStep from '../organisms/EmployeeReviewFormSelectionStep'
import { NextPrevArrows } from '../organisms/NextPrevArrows'

const REVIEW_FORM_MAIN_PATH = RegExp(/\/[a-z]+\/(employee-)?review\/[0-9]+.*/)
const EMAIL = `mailto:${CONTACT_EMAIL}`
const SLIDER_QUESTION_ID = v4()

const deleteModalMessage = (
  <p className={`modal-title`}>
    Are you sure you want to delete all the data from this review and restart the assessment?
  </p>
)

const exitWithoutChangesModalMessage = (
  <p>
    <span className={`modal-title`}> Are you sure you want to leave without saving?</span>
    <br />
    <b>All the data from this review will be lost.</b>
  </p>
)

const TutorialIntroduction = () => {
  return (
    <div className="container flex justify-center my-auto">
      <div className="flex flex-col justify-center">
        <div className="w-full text-center leading-[3rem]">
          <p className="text-dark-blue text-5xl font-medium mb-10 leading-[4.5rem]">
            <b>Complete a check-up of your team members' capabilities</b> in 5-10 minutes.
          </p>
          <div className="text-3xl font-normal text-dark-blue mb-12 flex flex-col gap-8">
            <p>
              The resulting data will help you <b>evaluate, understand and coach your team better</b>.
            </p>
            <p>
              Please note: <b>this is not a formal review</b>. We encourage you to update information periodically so
              you can monitor progress.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const UserTutorialIntroduction = () => {
  return (
    <div className="container flex justify-center my-auto">
      <div className="flex flex-col justify-center">
        <div className="w-full text-center leading-[3rem]">
          <p className="text-6xl font-medium mb-16">Welcome to Selfie</p>
          <div className="text-3xl font-normal mb-12 flex flex-col gap-8">
            <p>
              In 10-15 minutes you can complete a <b>check-up of your current professional profile</b>.
            </p>
            <p>
              The resulting data will help you and your manager <b>work on your professional development plan</b> and{' '}
              <b>achieve growth goals</b>.
            </p>
            <p>
              Please note: <b>this is not a formal review</b>. We encourage you to update the information periodically
              so you can monitor progress.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

interface SliderValues {
  [key: string]: {
    value: number
    hasUserAnswer: boolean
  }
}

const checkIfExistUnsavedAnswers = (indexedAnswers: Record<string, AnswerT>, currentReview?: Review) => {
  const indexedSavedAnswers: Record<string, AnsweredQuestion> = {}

  ;(currentReview?.answers || []).forEach(savedAnswer => {
    indexedSavedAnswers[savedAnswer.id] = savedAnswer
  })

  return Object.entries(indexedAnswers).some(([answerId, answer]) => {
    return !(
      answer.answer === undefined ||
      JSON.stringify(indexedSavedAnswers[answerId]?.answer) === JSON.stringify(answer?.answer)
    )
  })
}

export const EmployeeReview = (): JSX.Element => {
  useEffect(() => {
    dispatch(hideTutorial())
    trackEvent(TrackingCategoryEnum.ASSESSMENT, TrackingCategoryEventEnum.ASSESSMENT.PAGE_ON_LOAD, '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let fillAnswersClickCounter = 0
  const history = useHistory()
  const query = useQuery()
  const dispatch = useAppDispatch()
  const currentTopicToDisplay = query.get('topic')
  const user = useSelector<RootState, EmployeeDetails | null>(state => state.auth.user)
  const { employeeId, reviewId } = useParams<{ employeeId: string; reviewId: string }>()
  const [groupedQuestions, setGroupedQuestions] = useState<Record<string, Question[]>>({})
  const [indexedQuestions, setIndexedQuestions] = useState<Record<string, Question>>({})
  const [topics, setTopics] = useState<QuestionTopic[]>([])
  const [indexedAnswers, setIndexedAnswers] = useState<Record<string, AnswerT>>({})
  const [reviewedEmployee, setReviewedEmployee] = useState<EmployeeDetails>()
  const [currentReview, setCurrentReview] = useState<Review>()
  const [loading, setLoading] = useState<boolean>(false)
  const [isUnfinishedTopic, setUnfinishedTopic] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [sliderValues, setSliderValues] = useState<SliderValues>({})
  const [allForms, setAllForms] = useState<Form[]>()
  const [allFormCategories, setAllFormCategories] = useState<FormCategory[]>()
  const [currentForm, setCurrentForm] = useState<Form>()
  const [selectedFormId, setSelectedFormId] = useState<number>()
  const [showChangeFormModal, setShowChangeFormModal] = useState<boolean>()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showBackToTop, setShowBackToTop] = useState(false)
  const [lastFormUsed, setLastFormUsed] = useState<Form>()
  const [reviewsFromUser, setReviewsFromUser] = useState<Review[]>([])
  const [questionsPreviousAnswers, setQuestionsPreviousAnswers] = useState<Record<string, AnsweredQuestion>>()
  const [isSelfAssessment, setIsSelfAssessment] = useState(false)

  const divContentQuestionsRef = useRef<HTMLDivElement>(null)
  const tutorialCompleted = useSelector<RootState, boolean>(state => state.help.completed)
  const userTutorialCompleted = useSelector<RootState, boolean>(state => state.help.userAssesmentCompleted)
  const [introductionView, setIntroductionView] = useState<boolean>(tutorialCompleted)
  const [userIntroductionView, setUserIntroductionView] = useState<boolean>(userTutorialCompleted)

  const isTutorialCompleted =
    (tutorialCompleted && user?.role !== 'user') || (userTutorialCompleted && user?.role === 'user')

  const {
    currentTopicIndex,
    currentTopic,
    handleBackToPreviousTopic,
    handleGoToNextTopic,
    isFirstTopic,
    isLastTopic,
    hasElementFocus,
    resetTopic,
  } = useTopics(topics, currentTopicToDisplay, divContentQuestionsRef)

  const isNextDisabled = hasElementFocus || isLastTopic
  const isPreviousDisabled = hasElementFocus || isFirstTopic

  useNextPrevKeyboard({
    isNextDisabled,
    isPreviousDisabled,
    handleBackToPreviousTopic,
    handleGoToNextTopic,
  })

  const initializeAnswers = (_questions: Question[], answeredQuestions: AnsweredQuestion[] = []): void => {
    const indexedAnsweredQuestions: Record<
      string,
      {
        value: AnsweredQuestion['answer']
        skipped: AnsweredQuestion['skipped']
        suggestedAnswer: AnsweredQuestion['suggestedAnswer']
      }
    > = {}
    answeredQuestions.forEach(q => {
      indexedAnsweredQuestions[q.id] = { value: q.answer, skipped: q.skipped, suggestedAnswer: q.suggestedAnswer }
    })
    const _indexedAnswers: Record<string, AnswerT> = {}
    _questions.forEach(q => {
      _indexedAnswers[q.id] = {
        questionId: q.id,
        answer: indexedAnsweredQuestions[q.id]?.value as AnswerT['answer'],
        weight: q.weight,
        skipped: indexedAnsweredQuestions[q.id]?.skipped || q.skipped,
        suggestedAnswer: indexedAnsweredQuestions[q.id]?.value as AnswerT['suggestedAnswer'],
      }
    })
    setIndexedAnswers(_indexedAnswers)
  }

  const saveReview = async (): Promise<Review> => {
    const currentReviewId = currentReview
      ? currentReview?.id
      : await ReviewService.createEmptyReview(reviewedEmployee?.id as number, currentForm?.id as number)
    const indexedSavedAnswers: Record<string, AnsweredQuestion> = {}
    ;(currentReview?.answers || []).forEach(savedAnswer => {
      indexedSavedAnswers[savedAnswer.id] = savedAnswer
    })
    const unsavedAnswersToSave: AnswerToBeAddedToReview[] = []
    Object.entries(indexedAnswers).forEach(([answerId, answer]) => {
      if (
        answer.answer === undefined ||
        (JSON.stringify(indexedSavedAnswers[answerId]?.answer) === JSON.stringify(answer?.answer) &&
          JSON.stringify(indexedSavedAnswers[answerId]?.suggestedAnswer) === JSON.stringify(answer?.suggestedAnswer))
      ) {
        return
      }
      if (indexedQuestions[answer.questionId].skippable && answer.skipped) {
        if (typeof answer.answer === 'object') {
          answer.answer = [indexedQuestions[answer.questionId].skippable as string]
        } else {
          answer.answer = indexedQuestions[answer.questionId].skippable
        }
      }
      const answerToSave: AnswerToBeAddedToReview = {
        review: currentReviewId,
        question: answer.questionId,
        answer: typeof answer.answer === 'string' ? answer.answer : JSON.stringify(answer.answer),
        skipped: answer.skipped,
        suggestedAnswer: answer.suggestedAnswer?.toString(),
      }
      unsavedAnswersToSave.push(answerToSave)
    })
    trackSaveEvent(unsavedAnswersToSave)
    await ReviewService.saveAnswers(currentReviewId, unsavedAnswersToSave)
    const savedReview = await ReviewService.findOne(currentReviewId)
    setCurrentReview(savedReview)
    return savedReview
  }

  const trackSaveEvent = (ansersToAdd: AnswerToBeAddedToReview[]) => {
    const details = {
      totalQuestions: groupedQuestions[currentTopic?.name || ''].length,
      answeredQuestions: ansersToAdd.length,
      reviewID: currentReview?.id,
      category: currentTopic?.name,
    }
    trackEvent(
      TrackingCategoryEnum.ASSESSMENT,
      TrackingCategoryEventEnum.ASSESSMENT.CLICK_SAVE_BTN,
      JSON.stringify(details)
    )
  }

  const getQuestionsDependsOnReviewer = (form: Form, userId: number | undefined, reviewerId: number | undefined) => {
    if (userId === reviewerId) {
      return form.questions?.filter((question: Question) => !question.hiddenWhenAuthorIsTheReviewedEmployee) || []
    }
    return form.questions || []
  }

  // this function gets the user's reviews and filters them
  // by the same form, discards the current review if we are
  // editing it, and picks the most recent one OR if we are
  // editing a review, the most recent one before the current one
  const getPreviousReview = (currentForm: Form): Review | undefined => {
    const previousReview = reviewsFromUser
      .filter(review => (review.form.id === currentForm.id && currentReview ? currentReview.id !== review.id : true))
      .sort((a, b) => (a.finishDate ?? '').localeCompare(b.finishDate ?? ''))
      .at(-1)
    return previousReview ?? undefined
  }

  const renderForm = async (id: number) => {
    setLoading(true)
    const currentForm = await FormService.findById(id)
    const allQuestions = getQuestionsDependsOnReviewer(currentForm, user?.id, reviewedEmployee?.id)
    const sortedQuestions = _sortQuestions(allQuestions)
    const _groupedQuestions = _groupQuestionsByTopic(allQuestions)
    const _indexedQuestions = _indexQuestions(allQuestions)
    initializeAnswers(allQuestions, currentReview?.answers)
    setGroupedQuestions(_groupedQuestions)
    setIndexedQuestions(_indexedQuestions)
    setSelectedFormId(id)
    const sortedTopics = _sortTopics(sortedQuestions)
    setTopics(sortedTopics)
    // to indicate previous answers to the same form by the same author to the same user,
    // we need to check that we are creating a new review, or editing an upublished one
    // (which will always be the latest one)
    const workingOnUnpublishedReview = (currentReview && !currentReview.finishDate) || !currentReview
    if (workingOnUnpublishedReview && reviewsFromUser) {
      const _lastReviewFromUser = getPreviousReview(currentForm)
      _lastReviewFromUser && setQuestionsPreviousAnswers(_storePreviousAnswers(_indexedQuestions, _lastReviewFromUser))
    }
    setLoading(false)
  }

  const deleteReview = async (id: number) => await ReviewService.deleteReview(id)

  useEffect(() => {
    if (currentForm && currentForm.id !== undefined) {
      renderForm(currentForm.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentForm, allForms])

  const _initReview = async (): Promise<void> => {
    try {
      setLoading(true)
      const review = reviewId
        ? await ReviewService.findOne(Number(reviewId)).then((r: Review) => {
            setCurrentReview(r)
            return r
          })
        : null

      if (review?.finishDate && review.reviewRequest && review?.reviewAuthor?.id === user?.id) {
        history.push(createPathWithLang(NetworkConstants.URL_REVIEW_FINISHED_MSG))
      }

      const _employeeId = review?.reviewedEmployee?.id || Number(employeeId)
      const employee = await EmployeeService.findOne(_employeeId)
      setReviewedEmployee(employee)

      const rawReviews = await ReviewService.findByEmployeeId(_employeeId)
      const finishedReviews = rawReviews.filter(review => review.finishDate)
      const lastForm = finishedReviews[finishedReviews.length - 1]?.form
      const reviewsFromUser = finishedReviews && finishedReviews.filter(review => review.reviewAuthor.id === user?.id)
      setLastFormUsed(lastForm)
      setReviewsFromUser(reviewsFromUser)

      let [forms, formCategories] = await Promise.all([FormService.findAll(), FormService.findAllByCategory('review')])

      if (user?.id === _employeeId) {
        setIsSelfAssessment(true)
        formCategories = formCategories.filter(category => category.name === 'SELF-ASSESSMENT')
        forms = forms.filter(form => form.formCategory?.name === 'SELF-ASSESSMENT')
      } else {
        setIsSelfAssessment(false)
        formCategories = formCategories.filter(category => category.name !== 'SELF-ASSESSMENT')
        forms = forms.filter(form => form.formCategory?.name !== 'SELF-ASSESSMENT')
      }

      setAllForms(forms)
      setAllFormCategories(sortFormCategories(formCategories))
      if (reviewId && forms) {
        const _currentFormId = review?.form?.id || 1
        setCurrentForm(getFormById(_currentFormId, forms))
        setSelectedFormId(_currentFormId)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const _indexQuestions = (questions: Question[] = []): Record<string, Question> => {
    const _indexedQuestions = {} as Record<string, Question>
    questions.forEach(question => {
      _indexedQuestions[question.id] = question
    })

    return _indexedQuestions
  }

  const _groupQuestionsByTopic = (questions: Question[] = []): Record<string, Question[]> => {
    const _groupedQuestions = {} as Record<string, Question[]>
    questions.forEach(question => {
      const topic = question.topic?.name
      if (topic) {
        _groupedQuestions[topic] = [...(_groupedQuestions[topic] || []), question]
      }
    })

    return _groupedQuestions
  }

  const _sortTopics = (questions: Question[] = []): QuestionTopic[] => {
    const topicsObj: Record<string, QuestionTopic> = questions.reduce(
      (topics, { topic }) => (topic?.order ? { ...topics, [topic.order]: topic } : topics),
      {}
    )
    return Object.values(topicsObj)
  }

  const _storePreviousAnswers = (
    indexedQuestions: Record<string, Question>,
    lastReviewFromUser: Review
  ): Record<string, AnsweredQuestion> => {
    const previousAnswers = {} as Record<string, AnsweredQuestion>
    for (const key in indexedQuestions) {
      const answer = lastReviewFromUser.answers.find(answer => answer.id === indexedQuestions[key].id)
      if (answer) previousAnswers[key] = answer
    }
    return previousAnswers
  }

  useEffect(() => {
    void _initReview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, reviewId])

  useEffect(() => {
    setUnfinishedTopic(isTopicUnfinished(currentTopic?.name || ''))
    const questionsColumn = divContentQuestionsRef.current
    let columnHeight = 0
    if (questionsColumn) {
      for (const child of questionsColumn.children) {
        columnHeight += child.clientHeight
      }
    }
    setShowBackToTop(columnHeight > window.innerHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTopic])

  const isTopicUnfinished = (topic: string): boolean => {
    if (topic === '' || groupedQuestions === undefined) {
      return false
    }
    const topicQuestions = groupedQuestions[topic]
    const topicAnswers = topicQuestions.map(question => indexedAnswers[question.id])
    const topicAnswersCompleted = areAllRequiredQuestionsAnswered(topicAnswers)
    return !topicAnswersCompleted && areAnyQuestionsAnswered(topicAnswers)
  }

  useEffect(() => {
    const currentQuestions = groupedQuestions[currentTopic?.name || ''] || []
    const sliderQuestion =
      currentQuestions.length && currentQuestions.find(question => question.type === QuestionTypeEnum.NUMERIC)

    if (sliderQuestion) {
      const questionSummary = currentQuestions
        .filter(({ id, type }) => type === QuestionTypeEnum.SCALE && !(indexedAnswers[id]?.skipped as boolean))
        .map(({ id, weight, min = 1, max = 4 }) => ({
          id,
          min,
          max,
          weight: weight ?? 1, // TODO verify assumption that value is 1 (as opposed to 0) if no value is set
          value: (indexedAnswers[id]?.answer as number) ?? 1, // 1 being the lowest score, 0 breaks everything
        }))
      let totalWeight = 0
      let totalValues = 0
      let newValue = 0
      if (questionSummary.length) {
        totalWeight = questionSummary?.reduce((total, question) => total + (question.weight ?? 1), 0)
        totalValues = questionSummary?.reduce((total, question) => {
          const proRatedValue = (question.value - question.min) / (question.max - question.min)
          return total + proRatedValue * question.weight
        }, 0)
        newValue = Math.round((totalValues / totalWeight) * 100)
      }
      setSliderValues({
        ...sliderValues,
        [sliderQuestion.id]: {
          hasUserAnswer: questionSummary.some(({ id }) => indexedAnswers[id]?.answer !== undefined),
          value: newValue,
        },
      })
      // FIXME: when all the scales are skipped the slider is fixed to 0. What should we do?
      // Skip slider? (what happen then when comparing reviews?)
      // Set to some value? This will affect the average
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexedAnswers, currentTopic])

  const handleSave = async (finish = true): Promise<void> => {
    try {
      setLoading(true)
      const savedReview = await saveReview()
      if (!reviewId) {
        history.replace(
          createPathWithLang(NetworkConstants.URL_EDIT_REVIEW).replace(
            ConstantIDs.REVIEW_ID_PATH_PLACEHOLDER,
            savedReview.id.toString()
          )
        )
      }

      if (!currentTopic || !getCompletedCategories().includes(currentTopic.name)) {
        setUnfinishedTopic(isTopicUnfinished(currentTopic?.name || ''))
      }

      changeTopic(currentTopicIndex)
    } catch (error) {
      setShowErrorModal(true)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const areAllRequiredQuestionsAnswered = (answers: AnswerT[] = []): boolean => {
    return !answers.some(answer => {
      const questionData = indexedQuestions[answer?.questionId]
      return (
        questionData?.required &&
        (answer?.answer === undefined || answer?.answer === '') &&
        (questionData?.skippable === undefined || answer?.skipped === false)
      )
    })
  }

  const areAnyQuestionsAnswered = (answers: AnswerT[] = []): boolean => {
    return answers.some(answer => {
      return (answer.answer !== undefined && answer.answer !== '') || answer.skipped
    })
  }

  const handleFinishReview = async (): Promise<void> => {
    try {
      setLoading(true)
      if (!areAllRequiredQuestionsAnswered(Object.values(indexedAnswers))) {
        setShowErrorModal(true)
        return
      }
      const savedReview = await saveReview()
      trackEvent(
        TrackingCategoryEnum.ASSESSMENT,
        TrackingCategoryEventEnum.ASSESSMENT.CLICK_FINISH_REVIEW_BTN,
        JSON.stringify({ reviewId: savedReview.id })
      )
      await ReviewService.finishReview(savedReview.id)
      if ((user?.id || savedReview.reviewAuthor.id) === savedReview.reviewedEmployee.id) {
        history.push(createPathWithLang(NetworkConstants.URL_TECH_PROFILE))
        return
      }
      const resultPath = !currentReview?.reviewRequest
        ? createPathWithLang(NetworkConstants.URL_REPORT_FORM)
            .replace(
              ConstantIDs.EMPLOYEE_ID_PATH_PLACEHOLDER,
              currentReview?.reviewedEmployee.id.toString() || employeeId
            )
            .replace(ConstantIDs.FORM_ID_PATH_PLACEHOLDER, selectedFormId?.toString() || '')
        : createPathWithLang(NetworkConstants.URL_REVIEW_FINISHED_MSG)
      history.push(resultPath)
    } catch (error) {
      setShowErrorModal(true)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleFinishEditingReview = async () => {
    checkIfExistUnsavedAnswers(indexedAnswers, currentReview) && (await saveReview())
    if ((user?.id || currentReview?.reviewAuthor.id) === currentReview?.reviewedEmployee.id) {
      history.push(createPathWithLang(NetworkConstants.URL_TECH_PROFILE))
      return
    }
    const resultPath = !currentReview?.reviewRequest
      ? createPathWithLang(NetworkConstants.URL_REPORT_FORM)
          .replace(
            ConstantIDs.EMPLOYEE_ID_PATH_PLACEHOLDER,
            currentReview?.reviewedEmployee.id.toString() || employeeId
          )
          .replace(ConstantIDs.FORM_ID_PATH_PLACEHOLDER, selectedFormId?.toString() || '')
      : createPathWithLang(NetworkConstants.URL_REVIEW_FINISHED_MSG)
    history.push(resultPath)
  }

  const handleCategorySelected = (categorySelected: string): void => {
    const topicIdx = topics.findIndex(({ name }) => categorySelected === name)
    trackEvent(
      TrackingCategoryEnum.ASSESSMENT,
      TrackingCategoryEventEnum.ASSESSMENT.CLICK_CATEGORY_BTN,
      JSON.stringify({
        category: topicIdx,
        reviewId: currentReview?.id,
        totalQuestions: Object.values(indexedAnswers).length,
        answeredQuestions: Object.values(indexedAnswers).filter(({ answer }) => answer !== undefined).length,
      })
    )

    changeTopic(topicIdx)
  }

  const changeTopic = (topicIdx: number) => {
    query.set('topic', `${topicIdx}`)
    history.replace({
      search: query.toString(),
    })
    window.scrollTo(0, 0)
  }

  const handleSaveSuggestedSlider = (questionId: number) => (value: number | undefined) => {
    const answer = indexedAnswers[questionId]
    if (answer) answer.suggestedAnswer = value
    setIndexedAnswers({ ...indexedAnswers })
  }

  const handleQuestionAnswered = (questionId: number) => (value: unknown, skipped?: boolean) => {
    const answer = indexedAnswers[questionId]
    if (answer) answer.answer = value as AnswerT['answer']
    if (skipped !== undefined) {
      answer.skipped = skipped
    }
    setIndexedAnswers({ ...indexedAnswers })
  }

  const handleMultiQuestionAnswered = (questionId: number) => (value: string[], skipped?: boolean) => {
    const uniqueValues = [...new Set(value)]
    handleQuestionAnswered(questionId)(uniqueValues.length > 0 ? uniqueValues : undefined, skipped)
  }

  const handleDeleteReview = () => {
    trackEvent(TrackingCategoryEnum.ASSESSMENT, TrackingCategoryEventEnum.ASSESSMENT.CLICK_DELETE_REVIEW_LINK, '')
    setShowDeleteModal(true)
  }
  const handleCancelDelete = () => {
    trackEvent(
      TrackingCategoryEnum.ASSESSMENT,
      TrackingCategoryEventEnum.ASSESSMENT.CLICK_DELETE_REVIEW_ACTION,
      JSON.stringify({ selectedAction: 'cancel' })
    )
    setShowDeleteModal(false)
  }
  const handleConfirmDelete = async () => {
    try {
      trackEvent(
        TrackingCategoryEnum.ASSESSMENT,
        TrackingCategoryEventEnum.ASSESSMENT.CLICK_DELETE_REVIEW_ACTION,
        JSON.stringify({ selectedAction: 'confirm' })
      )
      setIndexedAnswers({})
      if (currentReview?.id) {
        const reviewIdToDelete = currentReview.id
        await deleteReview(reviewIdToDelete)
        if ((user?.id || currentReview?.reviewAuthor.id) === currentReview?.reviewedEmployee.id) {
          history.push(createPathWithLang(NetworkConstants.URL_TECH_PROFILE))
          return
        }
        const reviewListRoute = createPathWithLang(NetworkConstants.URL_REVIEW_LIST)
        history.push(reviewListRoute)
        return
      }

      history.goBack()
    } catch (error) {
      setShowErrorModal(true)
      console.error(error)
    }
  }

  const getCompletedCategories = (): string[] => {
    return topics.reduce<string[]>((reminder, topic) => {
      const topicQuestions = groupedQuestions[topic.name]
      const topicAnswers = topicQuestions.map(question => indexedAnswers[question.id])
      const topicAnswersCompleted = areAllRequiredQuestionsAnswered(topicAnswers)
      return topicAnswersCompleted ? [...reminder, topic.name] : reminder
    }, [])
  }

  const getUnfinishedCategories = (): string[] => {
    return topics.reduce<string[]>((reminder, topic) => {
      const topicQuestions = groupedQuestions[topic.name]
      const topicAnswers = topicQuestions.map(question => indexedAnswers[question.id])
      const topicAnswersCompleted = areAllRequiredQuestionsAnswered(topicAnswers)
      return !topicAnswersCompleted && areAnyQuestionsAnswered(topicAnswers) ? [...reminder, topic.name] : reminder
    }, [])
  }

  const areScoredCategoriesFinished = (): boolean => {
    const completedCategories = getCompletedCategories()
    return scoredCategories.every(category => completedCategories.includes(category))
  }

  const calculateSuggestedAverageOfScoredCategories = (): number => {
    const result =
      scoredCategories.reduce<number>((sum, category) => {
        const scoreQuestion = groupedQuestions[category].at(-1)
        const suggestedScore = scoreQuestion?.id ? indexedAnswers[scoreQuestion.id] : undefined
        return sum + (suggestedScore?.suggestedAnswer ?? 0)
      }, 0) / (scoredCategories.length || 1)
    return result
  }

  const onFormSelectorChange = (value: number) => {
    trackEvent(
      TrackingCategoryEnum.ASSESSMENT,
      TrackingCategoryEventEnum.ASSESSMENT.CLICK_FORM_OPTION,
      JSON.stringify({ formId: value })
    )
    setSelectedFormId(value)
    if (currentForm && value !== currentForm.id) {
      const allAnswers = []
      for (const key in indexedAnswers) {
        allAnswers.push(indexedAnswers[key])
      }
      areAnyQuestionsAnswered(allAnswers) ? setShowChangeFormModal(true) : confirmFormChange(value)
    }
  }

  const cancelFormChange = () => {
    currentForm?.id && setSelectedFormId(currentForm.id)
    setShowChangeFormModal(false)
  }

  const resetReview = () => {
    currentReview &&
      ReviewService.resetReview(currentReview.id, currentReview.form.id || -1).then(() => {
        _initReview()
      })
  }

  const confirmFormChange = (formId = selectedFormId) => {
    if (formId !== undefined) {
      const form = getFormById(formId, allForms ?? [])
      if (currentReview && form) {
        currentReview.form = form
        resetReview()
        currentReview.answers = []
      }
      if (currentTopicToDisplay) {
        resetTopic()
      }
      formId && setCurrentForm(form)
    }
    setShowChangeFormModal(false)
  }

  const handleConfirmExit = (path: string) => {
    history.push({
      pathname: path,
    })
  }

  const handleCanExit = (): boolean => {
    const existUnsavedAnswers = checkIfExistUnsavedAnswers(indexedAnswers, currentReview)
    return !existUnsavedAnswers
  }

  const areRequiredQuestionsAnswered = areAllRequiredQuestionsAnswered(Object.values(indexedAnswers) ?? [])
  const existAnswers = Boolean(Object.keys(indexedAnswers).length)
  const renderFinishReleaseButton = (): JSX.Element => {
    return (
      <div className="flex flex-col flex-wrap items-center">
        <AppButton
          onClick={!currentReview?.finishDate ? handleFinishReview : handleFinishEditingReview}
          disabled={!areRequiredQuestionsAnswered || !existAnswers}
          className={isTutorialCompleted ? 'self-start' : 'm-6'}
          type="clear"
        >
          Publish
        </AppButton>
      </div>
    )
  }

  const arrowLabelProps = { ...(isLastTopic && { nextArrowLabel: 'Finish' }) }
  const handleGoTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  const handleAutoFillClickEvent = () => {
    fillAnswersClickCounter++
    if (fillAnswersClickCounter > 4) {
      setLoading(true)
      const questions: Question[] = Object.entries(indexedQuestions).map(value => value[1])
      const newIndexedAnswers = JSON.parse(JSON.stringify(indexedAnswers))
      if (areAllRequiredQuestionsAnswered(Object.values(newIndexedAnswers) || [])) {
        newIndexedAnswers[questions[1].id].answer = undefined
      } else {
        fillAnswersRandomly(questions, newIndexedAnswers)
      }
      setIndexedAnswers({ ...newIndexedAnswers })
      fillAnswersClickCounter = 0
      handleCategorySelected(currentTopic.name)
      setLoading(false)
    }
    if (fillAnswersClickCounter === 1) {
      setTimeout(() => {
        fillAnswersClickCounter = 0
      }, 2000)
    }
  }

  if (loading) {
    return <Spinner />
  }
  return (
    <div className="container">
      <TutorialModal
        visible={!introductionView && user?.role !== 'user'}
        onClose={() => {
          setIntroductionView(true)
          const currentTutorial =
            selectedFormId === undefined ? 'review-new-layout-tutorial-step-1' : 'review-tutorial-step-1'
          dispatch(showHelpId(currentTutorial))
        }}
        skipTutorial={() => {
          setIntroductionView(true)
          dispatch(finishTutorial('review-tutorial-step-1'))
        }}
        icon={false}
        message={<TutorialIntroduction />}
        startButtonText="Quick tutorial"
      />
      <TutorialModal
        visible={!userIntroductionView && user?.role === 'user' && selectedFormId !== undefined}
        onClose={() => {
          setUserIntroductionView(true)
          dispatch(showHelpId('user-assesment-tutorial-step-1'))
        }}
        skipTutorial={() => {
          setUserIntroductionView(true)
          dispatch(finishTutorial('user-assesment-tutorial-step-1'))
        }}
        icon={false}
        message={<UserTutorialIntroduction />}
        startButtonText="Quick tutorial"
      />
      <NavigationBlocker
        modalMessage={exitWithoutChangesModalMessage}
        securePathRegExp={REVIEW_FORM_MAIN_PATH}
        checkIfCanNavigate={handleCanExit}
        onConfirmNavigation={handleConfirmExit}
        isRefreshSensitive={true}
      />
      <div className="flex flex-col pb-14 gap-10">
        <div className={classNames('flex items-end relative gap-1', { 'flex-wrap': selectedFormId === undefined })}>
          <div
            className={classNames('flex min-w-96 flex-col w-1/3', {
              'basis-full': selectedFormId === undefined,
            })}
          >
            {!selectedFormId && (
              <>
                <span className="text-6xl text-dark-blue font-semibold m-0 leading-0">Assessment</span>
                <div className="mt-8">
                  {!isSelfAssessment && (
                    <p className="text-[1.4rem] leading-7 text-justify">
                      We want you to assess a team member's <b>communication, soft skills, and aptitude</b>. The goal is
                      to provide <b>constructive feedback</b> while remaining impartial and analytical. Please approach
                      this assessment with a <b>critical mindset</b>, regardless of your relationship with the person.
                      Remember, this evaluation aims to{' '}
                      <b>provide valuable feedback that helps the team member grow professionally</b>. Your unbiased
                      assessment will contribute to creating a culture of continuous improvement within our team. We
                      appreciate your cooperation and commitment to giving an honest evaluation. Thank you for
                      contributing to our team's growth!
                    </p>
                  )}
                  {isSelfAssessment && (
                    <p className="text-[1.4rem] leading-7 text-justify">
                      Start your self-assessment by picking the form that matches your technical skill level.
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
          <div
            className={classNames('flex flex-col flex-1 gap-y-5', {
              'mt-10': selectedFormId === undefined,
            })}
          >
            <div className="flex flex-row w-full gap-0 items-end">
              {selectedFormId === undefined && <p className="text-[2.4rem] font-normal flex-1">Select the form type</p>}
              <p className="text-2xl font-bold text-stone-gray flex-1">Team Member</p>
              <p className="text-2xl font-bold text-stone-gray flex-1">Job Title</p>
            </div>
            <div className="flex flex-row w-full gap-0 items-end">
              <p className={classNames({ 'flex-1': selectedFormId === undefined })}>
                {selectedFormId === undefined && lastFormUsed && (
                  <span className="text-2xl font-normal text-stone-gray mt-5 clock-icon-preceded">
                    Last form used: {lastFormUsed?.name}
                  </span>
                )}
              </p>
              <p className="text-4xl text-dark-blue font-medium flex-1">{reviewedEmployee?.name}</p>
              <p className="text-4xl text-dark-blue font-medium flex-1">{reviewedEmployee?.jobTitle}</p>
            </div>
          </div>
        </div>
        {selectedFormId === undefined ? (
          <div>
            <EmployeeReviewFormSelectionStep
              employeeId={employeeId}
              lastFormUsed={lastFormUsed}
              formCategories={allFormCategories ?? []}
              onConfirm={formId => {
                setSelectedFormId(formId)
                setCurrentForm(getFormById(formId, allForms ?? []))
              }}
              showTitle={false}
              disableSkipAssessment={isSelfAssessment}
            />
          </div>
        ) : (
          <div className="flex flex-1 relative gap-2">
            <div className="flex flex-col w-1/3 min-w-96 relative -top-20">
              <div className="sticky top-8">
                <div className="flex items-start w-1/3 min-w-96 flex-col gap-6 mb-10">
                  <h2
                    onClick={handleAutoFillClickEvent}
                    className="text-dark-blue font-semibold text-6xl m-0 leading-0"
                  >
                    Assessment
                  </h2>
                  <div
                    onClick={() => {
                      trackEvent(
                        TrackingCategoryEnum.ASSESSMENT,
                        TrackingCategoryEventEnum.ASSESSMENT.CLICK_FORM_SELECTOR,
                        ''
                      )
                    }}
                  >
                    {(allForms?.length || 0) > 1 && (
                      <HelpTooltip id={'review-tutorial-step-1'}>
                        <AppSelect
                          options={mapFormsToFormSelectOptions(allForms ?? [])}
                          onChange={value => onFormSelectorChange(Number(value))}
                          value={selectedFormId.toString()}
                          sortBy="value"
                          className="w-[30rem] xl:w-[38rem] !z-50"
                        />
                      </HelpTooltip>
                    )}
                  </div>
                </div>
                <CategorySelector
                  categories={topics.map(({ name }) => name)}
                  completedCategories={getCompletedCategories()}
                  selectedCategory={currentTopic?.name}
                  unfinishedCategories={getUnfinishedCategories()}
                  onCategorySelected={handleCategorySelected}
                  tutorialReview
                />
                <div className="mt-[6rem]">
                  <HelpTooltip
                    id={'review-tutorial-step-7'}
                    childrenClassNames="relative z-50 bg-white w-fit items-center flex-1"
                    placement="top-end"
                  >
                    <div className="flex justify-start items-start gap-2 mb-8">
                      <div className="flex flex-col justify-start">
                        <HelpTooltip
                          id={'user-assesment-tutorial-step-6'}
                          childrenClassNames="relative z-50 bg-white w-fit items-center flex-1"
                          placement="top-end"
                        >
                          <AppButton type="info" onClick={handleSave} className={isTutorialCompleted ? '' : 'm-6'}>
                            Save draft
                          </AppButton>
                        </HelpTooltip>

                        {user && reviewedEmployee && (
                          <button onClick={handleDeleteReview} className="flex items-center mt-4">
                            <GarbageSVG color="#005ECA" width={13} height={15} />
                            <span className="invisible lg:visible text-blue text-2xl font-semibold ml-3 font-manrope">
                              Delete assessment
                            </span>
                          </button>
                        )}
                      </div>
                      <div className="flex flex-wrap">
                        <HelpTooltip
                          id={'user-assesment-tutorial-step-7'}
                          childrenClassNames="relative z-50 bg-white w-fit items-center flex-1"
                          placement="top-end"
                        >
                          {renderFinishReleaseButton()}
                        </HelpTooltip>
                      </div>
                    </div>
                  </HelpTooltip>
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1" ref={divContentQuestionsRef}>
              <p className="text-4xl font-medium text-blue mb-10">{currentTopic?.name}</p>
              <div className="flex flex-wrap justify-between gap-1 mb-24 overflow-y-auto">
                {indexedAnswers &&
                  (groupedQuestions[currentTopic?.name || ''] || []).map((question, i) => {
                    switch (question.type) {
                      case QuestionTypeEnum.SCALE:
                        const showTutorial = i === 1 && !isTutorialCompleted
                        return (
                          <SelectQuestion
                            key={`employee-review-${currentTopic}-question-${i}`}
                            value={(indexedAnswers[question.id]?.answer as number) || 0}
                            index={i + 1}
                            max={question.max}
                            min={question.min}
                            title={question.title}
                            description={question.description}
                            minLabel={question.labels[0]}
                            maxLabel={question.labels[1]}
                            unfinished={isUnfinishedTopic}
                            required={question.required}
                            skippable={question.skippable}
                            skipped={indexedAnswers[question.id]?.skipped as boolean}
                            onChange={handleQuestionAnswered(question.id)}
                            tutorialButton={showTutorial}
                          />
                        )
                      case QuestionTypeEnum.NUMERIC:
                        if (!isTutorialCompleted) {
                          return (
                            <HelpTooltip
                              id={user?.role === 'user' ? 'user-assesment-tutorial-step-5' : 'review-tutorial-step-6'}
                              containerClassNames="flex flex-row-reverse w-full"
                              childrenClassNames="relative z-50 flex flex-wrap w-full items-center p-6"
                              placement="top-start"
                              key={SLIDER_QUESTION_ID}
                            >
                              <SliderQuestion
                                key={SLIDER_QUESTION_ID}
                                value={indexedAnswers[question.id]?.answer as number}
                                suggested={
                                  sliderValues[question.id]?.hasUserAnswer
                                    ? sliderValues[question.id]?.value
                                    : undefined
                                }
                                index={i + 1}
                                max={question.max}
                                min={question.min}
                                title={question.title}
                                description={question.description}
                                minLabel={question.labels ? question.labels[0] : ''}
                                maxLabel={question.labels ? question.labels[1] : ''}
                                unfinished={isUnfinishedTopic && indexedAnswers[question.id]?.answer === undefined}
                                required={question.required}
                                onChange={handleQuestionAnswered(question.id)}
                                onUpdateSuggested={handleSaveSuggestedSlider(question.id)}
                              />
                            </HelpTooltip>
                          )
                        }
                        return (
                          <SliderQuestion
                            key={SLIDER_QUESTION_ID}
                            value={indexedAnswers[question.id]?.answer as number}
                            suggested={
                              sliderValues[question.id]?.hasUserAnswer ? sliderValues[question.id]?.value : undefined
                            }
                            index={i + 1}
                            max={question.max}
                            min={question.min}
                            title={question.title}
                            description={question.description}
                            minLabel={question.labels ? question.labels[0] : ''}
                            maxLabel={question.labels ? question.labels[1] : ''}
                            unfinished={isUnfinishedTopic && indexedAnswers[question.id]?.answer === undefined}
                            required={question.required}
                            onChange={handleQuestionAnswered(question.id)}
                            onUpdateSuggested={handleSaveSuggestedSlider(question.id)}
                          />
                        )
                      case QuestionTypeEnum.DESCRIPTIVE_RATE:
                        return (
                          <SliderTextQuestion
                            key={`employee-review-${currentTopic}-question-${i}`}
                            index={i + 1}
                            title={question.title}
                            labels={question.labels || []}
                            unfinished={isUnfinishedTopic}
                            required={question.required}
                            onChange={handleQuestionAnswered(question.id)}
                          />
                        )
                      case QuestionTypeEnum.MULTIPLE_SELECTION:
                        return (
                          <ChecklistQuestion
                            key={`employee-review-${currentTopic}-question-${i}`}
                            selectedOptions={(indexedAnswers[question.id]?.answer as string[]) || []}
                            title={question.title}
                            options={question.options.map(opt => ({ label: opt, value: opt }))}
                            customOptionsPlaceholder={question.description}
                            unfinished={isUnfinishedTopic}
                            required={question.required}
                            skippable={question.skippable}
                            skipped={indexedAnswers[question.id]?.skipped as boolean}
                            onChange={handleMultiQuestionAnswered(question.id)}
                          />
                        )
                      case QuestionTypeEnum.SINGLE_SELECTION:
                        const options = question.options.map(opt => {
                          const labelValue = `${opt} ${iconsEmployee[question.title][opt] || ''}`
                          return { label: labelValue, value: opt }
                        })
                        return (
                          <DropdownQuestion
                            key={`employee-review-${currentTopic}-question-${i}`}
                            value={(indexedAnswers[question.id]?.answer as string) || ''}
                            title={question.title}
                            placeholder={question.description}
                            options={options}
                            unfinished={isUnfinishedTopic}
                            required={question.required}
                            onChange={handleQuestionAnswered(question.id)}
                            previousAnswer={
                              questionsPreviousAnswers &&
                              (questionsPreviousAnswers[Number(question.id)]?.answer as string)
                            }
                            suggested={question.title === 'Talent Rank' && areScoredCategoriesFinished()}
                            averageScore={
                              question.title === 'Talent Rank' && areScoredCategoriesFinished()
                                ? calculateSuggestedAverageOfScoredCategories()
                                : undefined
                            }
                          />
                        )
                      case QuestionTypeEnum.PARAGRAPH:
                        const { relatedQuestionId, relatedQuestionTargetOptions } = question
                        let showQuestion = true
                        if (relatedQuestionId && relatedQuestionTargetOptions) {
                          showQuestion = relatedQuestionTargetOptions.includes(
                            indexedAnswers[relatedQuestionId]?.answer?.toString() as string
                          )
                          if (!showQuestion) indexedAnswers[question.id].answer = ''
                        }
                        if (showQuestion) {
                          return (
                            <TextareaQuestion
                              key={`employee-review-${currentTopic}-question-${i}`}
                              value={(indexedAnswers[question.id]?.answer as string) || ''}
                              title={question.title}
                              placeholder={question.description}
                              required={question.required}
                              unfinished={isUnfinishedTopic}
                              onChange={handleQuestionAnswered(question.id)}
                            />
                          )
                        }
                        return null
                      case QuestionTypeEnum.TRUE_FALSE:
                        return (
                          <TrueFalseQuestion
                            title={question.title}
                            label={question.label}
                            value={(indexedAnswers[question.id]?.answer as boolean) || false}
                            required={question.required}
                            onChange={handleQuestionAnswered(question.id)}
                          ></TrueFalseQuestion>
                        )
                      default:
                        return <></>
                    }
                  })}
              </div>
              <div className="flex flex-col">
                <NextPrevArrows
                  onNext={isLastTopic ? handleFinishReview : handleGoToNextTopic}
                  onPrev={handleBackToPreviousTopic}
                  isNextDisabled={!areRequiredQuestionsAnswered ? isLastTopic : false}
                  isPrevDisabled={isFirstTopic}
                  {...arrowLabelProps}
                />
                {showBackToTop ? (
                  <div className="flex justify-center">
                    <button
                      className="flex justify-around items-center bg-pale-blue text-blue text-xl px-3 py-2 w-[100px] rounded-[8px]"
                      onClick={handleGoTop}
                    >
                      <div className="rotate-90">
                        <ArrowIcon height={8.5} width={10.5} color={'#005ECA'} />
                      </div>
                      Back to top
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
      <OptionsModal
        visible={showChangeFormModal === true}
        message={
          <p>
            <span className={`modal-title`}>Are you sure you want to change the form?</span>
            <br />
            <b>Your current assessment will be lost</b>
          </p>
        }
        icon={<WarningIcon />}
        firstButtonText="No"
        firstButtonType="clear"
        secondButtonText="Yes"
        secondButtonType="info"
        onFirstButtonClick={cancelFormChange}
        onSecondButtonClick={() => confirmFormChange()}
        onClose={cancelFormChange}
      />
      <div>
        {showErrorModal && (
          <ErrorModal
            icon={<WarningIcon />}
            visible={showErrorModal}
            message={
              <p>
                <span className={`modal-title`}>Something went wrong while saving your answers.</span>
                <br />
                <span className="text-grey">
                  <b>Try reloading the page and save again.</b> If the problem persists,{' '}
                  <a href={EMAIL}>contact support</a>.
                </span>
              </p>
            }
            onClose={() => setShowErrorModal(false)}
          />
        )}
      </div>
      <OptionsModal
        icon={<DeleteIcon />}
        secondButtonText="Delete"
        secondButtonType="error"
        firstButtonText="Cancel"
        firstButtonType="clear"
        visible={showDeleteModal}
        onFirstButtonClick={handleCancelDelete}
        onSecondButtonClick={handleConfirmDelete}
        onClose={handleCancelDelete}
        message={deleteModalMessage}
      />
    </div>
  )
}
