/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TeamTreeResponse } from '../../../api/openapi'
import { Employee, EmployeeKeys, EmployeeValues } from '../../../services/EmployeeService'
import { AppSelect } from '../../atoms/AppSelect/AppSelect'

const editableFields = new Set(['role', 'name', 'email', 'jobTitle'])
export const EmployeeTableEditor = (
  editingEmployees: Record<string, Employee>,
  onEmployeeEdited: (editedEmployeeId: number, field: EmployeeKeys, value: EmployeeValues) => void,
  options?: {
    possibleTeamsValues?: { value: number; label: string }[]
    possibleRoleValues?: { value: string; label: string }[]
    placeholder: string
  }
) => {
  // Library declared type does not match the actual value of props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (props: any): JSX.Element => {
    const employeeId = props.rowData.id as string
    const editingEmployeeKeys = editingEmployees[employeeId][props.field as EmployeeKeys]
    if (!editableFields.has(props.field) && typeof editingEmployeeKeys === 'string') {
      return <span className="text-toast-hover">{editingEmployeeKeys}</span>
    }
    if (props.field === 'teams') {
      return <span className="text-toast-hover">{props.rowData.teams.map((team: TeamTreeResponse) => team.name)}</span>
    }

    if (props.field === 'role') {
      return (
        <AppSelect
          className="h-10"
          placeholder={options?.placeholder}
          value={editingEmployeeKeys as string}
          options={options?.possibleRoleValues || []}
          onChange={newValue => onEmployeeEdited(props.rowData.id, props.field, newValue)}
        />
      )
    }

    return (
      <input
        className="bg-white border-none rounded-lg text-2x px-3 text-dark-blue w-full h-full"
        type="text"
        value={editingEmployeeKeys as string}
        onChange={e => onEmployeeEdited(props.rowData.id, props.field, e.target.value)}
      />
    )
  }
}
