import { OverlayPanel } from 'primereact/overlaypanel'
import { useRef, useState } from 'react'
import { SortOrderEnum } from '../../../types/enums/sort-order.enum'

import { CheckBox } from '../../atoms/CheckBox'
import FunnelSVG from '../../atoms/Icons/Funnel.svg'
import SmallArrowSVG from '../../atoms/Icons/SmallArrow.svg'
import SortIconSVG from '../../atoms/Icons/SortIcon.svg'
import ErrorModal from '../ErrorModal'

const FIREBASE_QUERY_ARRAY_LIMIT = 10

interface ColumnHeaderOptions {
  title: string
  sortable?: {
    sortOrder: SortOrderEnum
  }
  filter?: {
    selectedValues?: string[]
    type: 'select'
    values: { label: string; value: string }[]
  }
  onFilterSelected?: (selectedFilter: string) => void
  onSortSelected?: (selectedSort: SortOrderEnum) => void
}

export const EmployeeTableHeader = (
  { title, sortable, filter, onFilterSelected, onSortSelected }: ColumnHeaderOptions = { title: 'Column' }
): JSX.Element => {
  const overlayPanelRef = useRef<OverlayPanel>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setOverlayVisibilityStatus] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)

  const _handleHeaderFilterClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    overlayPanelRef?.current?.show(e, null)
  }

  const _handleSortClicked = (): void => {
    onSortSelected?.(
      sortable?.sortOrder === SortOrderEnum.Ascending ? SortOrderEnum.Descending : SortOrderEnum.Ascending
    )
  }

  const _handleFilterSelected = (selectedFilter: unknown): void => {
    if (!!filter?.selectedValues?.length && filter.selectedValues.length >= FIREBASE_QUERY_ARRAY_LIMIT) {
      setShowErrorModal(true)
      return
    }
    if (onFilterSelected) {
      onFilterSelected(selectedFilter as string)
    }

    setTimeout(() => {
      overlayPanelRef?.current?.hide()
    }, 200)
  }

  const _handleOverlayPanelHidden = (): void => {
    setOverlayVisibilityStatus(false)
  }

  const _handleOverlayPanelShown = (): void => {
    setOverlayVisibilityStatus(true)
  }

  if (sortable) {
    return (
      <div className="h-9 flex gap-2 items-center text-stone-gray cursor-pointer" onClick={_handleSortClicked}>
        <span className="bold text-blue text-xl">{title}</span>
        <SmallArrowSVG reverse={sortable.sortOrder === SortOrderEnum.Descending} transition="200ms" />
      </div>
    )
  }

  if (filter) {
    const sortedFilters = (filter.values || []).sort((filterA, filterB) => (filterA.label > filterB.label ? 1 : -1))

    return (
      <>
        <div
          className="h-9 flex gap-2 items-center text-stone-gray cursor-pointer"
          onClick={_handleHeaderFilterClicked}
        >
          <span className="text-2xl">{title}</span>
          <FunnelSVG />
          <OverlayPanel
            className="overlay-panel"
            ref={overlayPanelRef}
            onHide={_handleOverlayPanelHidden}
            onShow={_handleOverlayPanelShown}
          >
            {sortedFilters.map(input => (
              <CheckBox
                key={`${title}-${input.value}`}
                label={input.label}
                value={input.value}
                checked={!!filter.selectedValues?.includes(input.value)}
                onChecked={_handleFilterSelected}
                checkedStyle={false}
              />
            ))}
          </OverlayPanel>
        </div>
        {showErrorModal && (
          <ErrorModal
            visible={showErrorModal}
            message={<p>Sorry, you can't filter by more than 10 values</p>}
            onClose={() => setShowErrorModal(false)}
          />
        )}
      </>
    )
  }

  if (onSortSelected) {
    return (
      <div className="h-9 flex gap-2 items-center text-stone-gray cursor-pointer" onClick={_handleSortClicked}>
        <span className="text-xl">{title}</span>
        <SortIconSVG />
      </div>
    )
  }

  if (title === 'app-table-hierarchy') {
    return (
      <div className="h-9 flex gap-2 items-center text-stone-gray">
        <div className="w-12 h-full flex items-center justify-center text-2xl">{'+'}</div>
      </div>
    )
  }

  return (
    <div className="h-9 flex gap-2 items-center text-stone-gray text-xl">
      <span>{title}</span>
    </div>
  )
}
