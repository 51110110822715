import Slider from 'rc-slider'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import classNames from 'classnames'

import 'rc-slider/assets/index.css'
import React from 'react'

export interface AppSliderProps {
  min: number
  max: number
  value: number
  suggested?: number
  unfinished?: boolean
  onChange?: (newValue: number | undefined) => void
}

const preventStringsOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === '-' || e.key === ',' || e.key === '.') {
    e.preventDefault()
  }
}

const preventPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
  e.preventDefault()
}

export const AppSlider: FC<AppSliderProps> = ({ value, min, max, onChange, suggested = 0, unfinished }) => {
  const [currentValue, setCurrentValue] = useState(0)
  const container = React.createRef<HTMLDivElement>()

  useEffect(() => {
    if (value !== undefined) setCurrentValue(value)

    removeActiveFromExceededMark()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, suggested])

  const removeActiveFromExceededMark = () => {
    if (value > suggested) {
      container.current
        ?.getElementsByClassName('rc-slider-mark-text rc-slider-mark-text-active')[0]
        ?.classList.remove('rc-slider-mark-text-active')
    } else if (
      value === suggested &&
      container.current?.getElementsByClassName('rc-slider-mark-text rc-slider-mark-text-active').length === 0
    ) {
      container.current?.getElementsByClassName('rc-slider-mark-text')[0]?.classList.add('rc-slider-mark-text-active')
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target?.value
    if (newValue !== null && newValue !== undefined && newValue !== '' && !Number.isNaN(e.target?.value)) {
      handleChange(parseInt(e.target?.value))
    } else {
      if (onChange) onChange(undefined)
    }
  }

  const handleChange = (newValue: number): void => {
    let sanitizedValue = Math.round(newValue)

    if (sanitizedValue > max) {
      sanitizedValue = max
    } else if (sanitizedValue < min) {
      sanitizedValue = min
    }

    if (onChange) onChange(sanitizedValue)
  }

  const marks: Record<number, string> = {}

  if (suggested !== undefined && suggested !== null && suggested >= 0) {
    marks[suggested] = `${suggested} (suggested)`
  }

  return (
    <div
      className={classNames({
        'app-slider mt-4': true,
        'app-slider--unfinished': unfinished,
      })}
      ref={container}
    >
      <div className="mb-16 flex gap-4 items-center text-lg">
        <input
          className={classNames({
            'py-2 border border-blue border-off-blue rounded-lg text-2xl text-center !w-24': true,
            'bg-pale-yellow': unfinished,
          })}
          type="number"
          min={min}
          max={max}
          value={currentValue !== undefined ? currentValue : ''}
          onChange={handleInputChange}
          onKeyPress={preventStringsOnKeyPress}
          onPaste={preventPaste}
        />
        <span className="text-dark-blue font-[8pt] font-medium">{suggested === value ? 'Suggested' : null}</span>
      </div>
      <div className="pl-[2rem]">
        <Slider
          className={classNames({
            'pb-6 pt-5 font-[10px]': true,
            'border-solid border-y-0 border-x-2 rounded-none border-blue': true,
            '!border-r-inactive-gray': currentValue !== 100,
          })}
          min={min}
          max={max}
          value={currentValue}
          onChange={value => setCurrentValue(value)}
          onAfterChange={handleChange}
          marks={marks}
        />
      </div>
    </div>
  )
}
