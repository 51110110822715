import { FC } from 'react'
import { Review } from '../../../types/definitions/review'
import { AppButton } from '../../atoms/AppButton/AppButton'

interface TechProfileCardButtonsProps {
  reviewTechProfile: Review
  onUpdateReview?: () => void
  onPublishReview?: () => Promise<void>
}

const TechProfileCardButtons: FC<TechProfileCardButtonsProps> = ({
  reviewTechProfile,
  onUpdateReview,
  onPublishReview,
}) => {
  if (reviewTechProfile.reviewAuthor.id !== reviewTechProfile.reviewedEmployee.id) {
    return null
  }

  if (reviewTechProfile.finishDate) {
    return (
      <div className="employee-results__footer flex items-center">
        <AppButton type="info" onClick={onUpdateReview}>
          Update profile
        </AppButton>
      </div>
    )
  }

  return (
    <div className="employee-results__footer flex items-center">
      <AppButton type="clear" onClick={onUpdateReview}>
        Keep editing
      </AppButton>
      <AppButton type="info" onClick={onPublishReview}>
        Publish
      </AppButton>
    </div>
  )
}

export default TechProfileCardButtons
