import { EventTrackingApi } from '../api'
import { CreateTrackingEventRequest } from '../api/openapi'

export enum TrackingCategoryEnum {
  AUTHENTICATION = 'Authentication',
  REVIEW_LIST = 'ReviewList',
  ASSESSMENT = 'Assessment',
  TECH_EVALUATION = 'TechEvaluation',
  INDIVIUAL_REPORT = 'IndividualReport',
  NAVIGATION = 'Navigation',
  TEAM_REPORT = 'TeamReport',
  LEARN_RESOURCES = 'RecommendedResources',
  FIND_RESOURCES = 'FindResources',
  ADMIN = 'Admin',
  TEAM_ISSUES = 'TeamIssues',
}

enum TeamReportEventEnum {
  PAGE_ON_LOAD = 'PAGE_ON_LOAD',
  CLICK_CHART_SWITCHES = 'CLICK_CHART_SWITCHES',
  CLICK_EMPLOYEE_NAME = 'CLICK_EMPLOYEE_NAME',
  CLICK_EDIT_TEAM_NAME = 'CLICK_EDIT_TEAM_NAME',
}

enum AuthenticationEventEnum {
  USER_CHANGE_PASSWORD_ERROR = 'USER_CHANGE_PASSWORD_ERROR',
  USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
  USER_LOGOUT = 'USER_LOGOUT',
  USER_LOGIN_SSO_SUCCESS = 'USER_LOGIN_SSO_SUCCESS',
  USER_CLOSE_APP = 'USER_CLOSE_APP',
}

enum NavigationEventEnum {
  CLICK_SUPPORT_LINK = 'CLICK_SUPPORT_LINK',
  CLICK_NAVIGATION_BAR = 'CLICK_NAVIGATION_BAR',
  CLICK_GRAPES_LOGO = 'CLICK_GRAPES_LOGO',
}

enum ReviewListEventEnum {
  PAGE_ON_LOAD = 'PAGE_ON_LOAD',
  CLICK_HIERARCHY_ICON = 'CLICK_HIERARCHY_ICON',
  CLICK_EMPLOYEE_TEAM_LINK = 'CLICK_EMPLOYEE_TEAM_LINK',
  CLICK_NAVIGATION_TABS = 'CLICK_NAVIGATION_TABS',
  SEARCH_CHANGED = 'SEARCH_CHANGED',
  CLICK_EMPLOYEE_ROW = 'CLICK_EMPLOYEE_ROW',
  CLICK_EMPLOYEE_ACTION_LINK = 'CLICK_EMPLOYEE_ACTION_LINK',
  CLICK_EMPLOYEE_SEE_REPORT = 'CLICK_EMPLOYEE_SEE_REPORT',
  CLICK_EMPLOYEE_CONTINUE_REVIEW = 'CLICK_EMPLOYEE_CONTINUE_REVIEW',
  CLICK_EMPLOYEE_START_REVIEW = 'CLICK_EMPLOYEE_START_REPORT',
  CLICK_ORG_CHART_BTN = 'CLICK_ON_ORG_CHART_BTN',
  CLICK_TABLE_BTN = 'CLICK_TABLE_CHART_BTN',
}

enum AssessmentEventEnum {
  PAGE_ON_LOAD = 'PAGE_ON_LOAD',
  CLICK_SAVE_BTN = 'CLICK_SAVE_BTN',
  CLICK_FINISH_REVIEW_BTN = 'CLICK_FINISH_REVIEW_BTN',
  CLICK_DELETE_REVIEW_ACTION = 'CLICK_DELETE_REVIEW_ACTION',
  CLICK_DELETE_REVIEW_LINK = 'CLICK_DELETE_REVIEW_LINK',
  CLICK_CATEGORY_BTN = 'CLICK_CATEGORY_BTN',
  CLICK_FORM_SELECTOR = 'CLICK_FORM_SELECTOR',
  CLICK_FORM_OPTION = 'CLICK_FORM_OPTION',
}

enum TechEvaluationEventEnum {
  PAGE_ON_LOAD = 'PAGE_ON_LOAD',
  CLICK_SAVE_BTN = 'CLICK_SAVE_BTN',
  CLICK_CATEGORY_BTN = 'CLICK_CATEGORY_BTN',
  CLICK_FINISH_REVIEW_BTN = 'CLICK_FINISH_REVIEW_BTN',
  CLICK_START_OVER_BTN = 'CLICK_START_OVER_BTN',
  CLICK_DELETE_REVIEW_LINK = 'CLICK_DELETE_REVIEW_LINK',
  CLICK_UPDATE_BTN = 'CLICK_UPDATE_BTN',
}

enum IndividualReportEventEnum {
  CLICK_EDIT_BTN = 'CLICK_EDIT_BTN',
  CLICK_DETAILS = 'CLICK_DETAILS',
  PAGE_ON_LOAD = 'PAGE_ON_LOAD',
  CLICK_TEAM_LINK = 'CLICK_TEAM_LINK',
  CLICK_CHART_SWITCHES = 'CLICK_CHART_SWITCHES',
  CLICK_REVIEW_SELECTOR = 'CLICK_REVIEW_SELECTOR',
  CLICK_REVIEW_OPTION = 'CLICK_REVIEW_OPTION',
  CLICK_DOWNLOAD_REPORT = 'CLICK_DOWNLOAD_REPORT',
  CLICK_DELETE_ASSESSMENT = 'CLICK_DELETE_ASSESSMENT',
}

enum FindResourcesEventEnum {
  PAGE_ON_LOAD = 'PAGE_ON_LOAD',
  SEARCH_RESOURCES = 'SEARCH_RESOURCES',
  CLEAR_RESOURCES = 'CLEAR_RESOURCES',
  SELECT_CONDITION = 'SELECT_CONDITION',
}

enum AdminEventEnum {
  CLICK_EDIT_ICON = 'CLICK_EDIT_ICON',
  CLICK_CANCEL_EDIT_ICON = 'CLICK_CANCEL_EDIT_ICON',
  CLICK_SAVE_EDIT_ICON = 'CLICK_SAVE_EDIT_ICON',
  CLICK_DELETE_ICON = 'CLICK_DELETE_ICON',
  CLICK_DELETE_ACTION = 'CLICK_DELETE_ACTION',
  CLICK_CANCEL_DELETE = 'CLICK_CANCEL_DELETE',
  PAGE_ON_LOAD = 'PAGE_ON_LOAD',
  CLICK_NEW_BTN = 'CLICK_ON_NEW_BTN',
  CLICK_ORG_CHART_BTN = 'CLICK_ON_ORG_CHART_BTN',
  CLICK_TABLE_BTN = 'CLICK_TABLE_CHART_BTN',
  CLICK_ROSTER_IMPORT_BTN = 'CLICK_ROSTER_IMPORT_BTN',
}

enum TeamsIssuesEventEnum {
  PAGE_ON_LOAD = 'PAGE_ON_LOAD',
  CLICK_TEAM_LINK = 'CLICK_TEAM_LINK',
  CLICK_TEAM_REPORT_LINK = 'CLICK_TEAM_REPORT_LINK',
  CLICK_NAVIGATION_TABS = 'CLICK_NAVIGATION_TABS',
  CLICK_EDIT_TEAM_NAME = 'CLICK_EDIT_TEAM_NAME',
}

enum LearnResourcesEventEnum {
  CLICK_RESOURCE_LINK = 'CLICK_RESOURCE_LINK',
}

export const TrackingCategoryEventEnum = Object.freeze({
  AUTHENTICATION: AuthenticationEventEnum,
  REVIEW_LIST: ReviewListEventEnum,
  ASSESSMENT: AssessmentEventEnum,
  INDIVIUAL_REPORT: IndividualReportEventEnum,
  TECH_EVALUATION: TechEvaluationEventEnum,
  NAVIGATION: NavigationEventEnum,
  TEAM_REPORT: TeamReportEventEnum,
  LEARN_RESOURCES: LearnResourcesEventEnum,
  FIND_RESOURCES: FindResourcesEventEnum,
  ADMIN: AdminEventEnum,
  TEAM_ISSUES: TeamsIssuesEventEnum,
})

const UIEVENT_BUFFER_SIZE = 30
const minutes = 3
const UIEVENT_SAVE_INTERVAL = minutes * 60 * 1000

export class EventTrackingService {
  static trackingEvents: CreateTrackingEventRequest[] = []
  static lastSavedTime: number = new Date().getTime()

  private static clear() {
    this.trackingEvents = []
    this.lastSavedTime = new Date().getTime()
  }

  private static shouldSendTracking(bufferSize: number, saveIntervalTime: number): boolean {
    const eventBufferIsFull: boolean = this.trackingEvents.length >= bufferSize
    const saveTimeIntervalCompleted: boolean =
      this.trackingEvents.length > 0 && new Date().getTime() - this.lastSavedTime >= saveIntervalTime

    return eventBufferIsFull || saveTimeIntervalCompleted
  }

  private static postTrackingEvent(trackingEvents: CreateTrackingEventRequest[]): Promise<void> {
    return EventTrackingApi.saveTrackingEventUsingPOST(trackingEvents).then(response => {
      return
    })
  }

  static async registerTrackingEvent(category: string, event: string, payload: string) {
    const trackingEvent: CreateTrackingEventRequest = { category, event, payload }
    const hasLogOut = event === AuthenticationEventEnum.USER_LOGOUT
    const hasCloseApp = event === AuthenticationEventEnum.USER_CLOSE_APP
    this.trackingEvents.push(trackingEvent)

    if (this.shouldSendTracking(UIEVENT_BUFFER_SIZE, UIEVENT_SAVE_INTERVAL) || hasLogOut || hasCloseApp) {
      await EventTrackingService.postTrackingEvent(this.trackingEvents)
      EventTrackingService.clear()
    }
  }

  static async registerTrackingMultipleEvents(infoEvents: CreateTrackingEventRequest[]) {
    this.trackingEvents = [...this.trackingEvents, ...infoEvents]

    if (this.shouldSendTracking(UIEVENT_BUFFER_SIZE, UIEVENT_SAVE_INTERVAL)) {
      await EventTrackingService.postTrackingEvent(this.trackingEvents)
      EventTrackingService.clear()
    }
  }
}

export const trackEvent = async (category: string, event: string, payload = '') => {
  await EventTrackingService.registerTrackingEvent(category, event, payload)
}

export const trackMultipleEvents = (infoEvents: CreateTrackingEventRequest[]) => {
  EventTrackingService.registerTrackingMultipleEvents(infoEvents)
}
