//================================
// Index (Search using CTRL + F)
//
// 0. Calculations
// 1. View
// 2. Rendering
//================================

import { Review } from '../../../types/definitions/review'
import { Employee } from '../../../services/EmployeeService'
import { AnsweredQuestion } from '../../../types/definitions/question'
import { ReportLayoutTypeEnum } from '../../../types/enums/report-layout-type.enum'

import { cloneDeep } from 'lodash'

import ERLists, { List } from './EmployeeResultsData/Lists'
import ERParagraph from './EmployeeResultsData/Paragraph'
import ERTable from './EmployeeResultsData/Table'
import { _sortQuestions } from '../../../utils/questionUtils'

//================================
// 0. Calculations
//================================

const sortQuestionsAlphabetically = (questions: AnsweredQuestion[]): AnsweredQuestion[] => {
  return questions.sort((q1, q2) => q1.title.localeCompare(q2.title))
}

const getReportOrders = (questions: AnsweredQuestion[]): number[] => {
  const result = questions.reduce<number[]>((remainder, q) => {
    if (!q.reportLayout || !q.reportLayout.type || q.reportLayout.type === ReportLayoutTypeEnum.CHART) return remainder
    if (!remainder.includes(q.reportLayout.order)) return [...remainder, q.reportLayout.order]
    return remainder
  }, [])
  return result
}

const sortNumerically = (unordered: number[]): number[] => {
  return unordered.sort((a, b) => a - b)
}

const sortQuestionsByItemLayout = (questions: AnsweredQuestion[]): AnsweredQuestion[] => {
  return questions.sort((q1, q2) => {
    if (
      !q1.reportLayout ||
      q1.reportLayout.type === ReportLayoutTypeEnum.CHART ||
      q1.reportLayout.type === ReportLayoutTypeEnum.PARAGRAPH ||
      !q2.reportLayout ||
      q2.reportLayout.type === ReportLayoutTypeEnum.CHART ||
      q2.reportLayout.type === ReportLayoutTypeEnum.PARAGRAPH
    )
      return 1
    if (q1.reportLayout.itemOrder < q2.reportLayout.itemOrder) return -1
    if (q1.reportLayout.itemOrder > q2.reportLayout.itemOrder) return 1
    return 0
  })
}

const sortNoteQuestions = (questions: AnsweredQuestion[]): AnsweredQuestion[] => {
  return questions.sort((a, b) => {
    // Set the final notes as first
    if (a.topic?.topicKey === 'final_step') {
      return -1
    } else if (b.topic?.topicKey === 'final_step') {
      return 1
    }
    if (!(a.order !== undefined && b.order !== undefined) || a.order === b.order) {
      return b.type.localeCompare(a.type)
    }
    return a.order > b.order ? 1 : -1
  })
}

const groupQuestionsByReportOrder = (questions: AnsweredQuestion[], reportOrders: number[]): AnsweredQuestion[][] => {
  const result: AnsweredQuestion[][] = []
  reportOrders.forEach(reportOrder => {
    const group = questions.filter(
      q => q.reportLayout?.type !== ReportLayoutTypeEnum.CHART && q.reportLayout?.order === reportOrder
    )
    result.push(group)
  })
  return result
}

const questionsGroupedByReportOrderFrom = (questions: AnsweredQuestion[]): AnsweredQuestion[][] => {
  const reportOrders = getReportOrders(questions)
  const reportOrdersSortedNumerically = sortNumerically(reportOrders)
  const groupedQuestions = groupQuestionsByReportOrder(questions, reportOrdersSortedNumerically)
  const itemSortedGroupedQuestions = groupedQuestions.map(group => {
    const sorted = sortQuestionsByItemLayout(group)
    return sorted
  })

  return itemSortedGroupedQuestions
}

const enrichQuestions = (questions: AnsweredQuestion[], employee: Employee): AnsweredQuestion[] => {
  let result = cloneDeep(questions)
  result = sortQuestionsAlphabetically(result)

  return result
}

//================================
// 1. View
//================================

const motivationLevelTitle = 'Motivation level'

const generateList = (questions: AnsweredQuestion[]): JSX.Element => {
  const list = questions
    .sort((a, b) => (a.order as number) - (b.order as number))
    .map<List>(q => ({ headline: q.title, items: q.answer as string[] }))
  return <ERLists lists={list} />
}

const generateTable = (questions: AnsweredQuestion[]): JSX.Element => {
  const rows = questions.map<Record<string, { answer: string; styles: string }>>(q => {
    const answer = q.answer as string
    const styles = q.title === motivationLevelTitle ? getMotivationLevelColors(answer) : ''
    return { [q.title]: { answer, styles } }
  })
  return <ERTable rows={rows} />
}

const getMotivationLevelColors = (answer: any) => {
  const answerNumber = parseInt(answer)
  if (answerNumber < 5) return 'text-error-red-hover'
  if (answerNumber < 8) return 'text-burnt-orange'
  return 'text-green'
}

const generateParagraph = (questions: AnsweredQuestion[]) => {
  const currentTitle = 'Why do you think this person has High Retention at Risk?'
  const sortedQuestions = _sortQuestions(questions)
  return sortedQuestions
    .filter(question => question.answer)
    .map(question => {
      const title = question.title !== currentTitle ? question.title : 'High Retention at Risk comments'
      const styles =
        question.title === motivationLevelTitle && question.answer
          ? `font-bold  ${getMotivationLevelColors(question.answer)}`
          : ''
      return <ERParagraph headline={title} body={question.answer as string} classNames={styles} />
    })
}

const generateNoteSection = (questions: AnsweredQuestion[]) => {
  const sortedQuestions = sortNoteQuestions(questions)
  return sortedQuestions.map(question => {
    const isFinalStep = question.topic?.topicKey === 'final_step'
    const border = isFinalStep ? '' : 'border-dotted border-t border-toast-hover'
    return (
      <div className={`${border} py-4`}>
        <h4 className="text-toast-hover font-bold text-2xl">
          {}
          {isFinalStep ? question.title ?? 'General ' : `About ${question.topic?.name}`}
        </h4>
        <p className="text-dark-blue my-4 whitespace-pre-line">{question.answer as string}</p>
      </div>
    )
  })
}

const sectionsToRender = (groupedQuestions: AnsweredQuestion[][]): JSX.Element[] => {
  const result = groupedQuestions.map((group, index) => {
    const layoutType = group[0].reportLayout?.type

    switch (layoutType) {
      case ReportLayoutTypeEnum.LIST:
        return <div key={index}>{generateList(group)}</div>

      case ReportLayoutTypeEnum.PARAGRAPH:
        const noteQuestion = group.filter(q => q.label === 'notes' || q.title === 'Notes')
        const otherQuestions = group.filter(q => !noteQuestion.includes(q))
        return (
          <>
            <div key={index}>{generateParagraph(otherQuestions)}</div>
            {noteQuestion.length > 0 && (
              <div className="top-separator">
                <b>Notes</b>
                <div key={index}>{generateNoteSection(noteQuestion)}</div>
              </div>
            )}
          </>
        )

      case ReportLayoutTypeEnum.TABLE:
        return <div key={index}>{generateTable(group)}</div>

      default:
        return <div key={index}>The selected Report Layout Type doesn't exist</div>
    }
  })

  return result
}

//================================
// 2. Rendering
//================================

type QuizReviewResultsProps = {
  review: Review
  employee: Employee
}

const EmployeeResultsData = (props: QuizReviewResultsProps): JSX.Element => {
  const questions = enrichQuestions(props.review.answers, props.employee)
  const groupedQuestions = questionsGroupedByReportOrderFrom(questions)
  return <div>{sectionsToRender(groupedQuestions)}</div>
}

export default EmployeeResultsData
