import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react'
import Validator from 'validator'
import { ClosedEyeIcon } from '../Icons/ClosedEyeIcon'
import { EyeIcon } from '../Icons/EyeIcon'
import classNames from 'classnames'

interface AppInputPasswordProps {
  placeholder?: string
  searchIcon?: boolean
  label?: string
  value: string
  disabled?: boolean
  autoComplete?: string
  isPasswordError?: boolean
  onChange: (inputValue: string) => void
  onSubmit?: (inputValue: string) => void
  setValidation?: (isValid: boolean) => void
}

export const AppInputPassword: FC<AppInputPasswordProps> = props => {
  const inputRef = useRef<HTMLInputElement>(null)
  const maxLength = 240
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isFocus, setFocus] = useState<boolean>(false)
  const isValid = (): boolean => {
    if (inputRef.current === null) {
      return false
    }
    const value = inputRef.current.value
    return !Validator.isEmpty(value) && Validator.isLength(value, { max: maxLength })
  }

  const onChange = (_: ChangeEvent<HTMLInputElement>): void => {
    if (inputRef.current != null) {
      props.onChange(inputRef.current.value)
    }
  }

  const onKeyUp = (keyPressed: KeyboardEvent): void => {
    if (keyPressed.key === 'Enter' && inputRef.current != null) {
      props.onSubmit?.(inputRef.current.value)
    }
  }

  useEffect(() => {
    props.setValidation?.(isValid())
  })

  const handleTogglePassword = () => setShowPassword(!showPassword)
  const onFocus = () => setFocus(true)
  const onBlur = () => setFocus(false)

  const inputType = showPassword ? 'text' : 'password'
  const inputContainerBorder = classNames({
    'outline outline-[3px] outline-offset-[-1px] px-[1.6rem] outline-blue border-none':
      isFocus && !props.isPasswordError,
  })
  const inputContainerStyle = classNames('w-full mb-0 outline-none', {
    'border-blue': !props.isPasswordError,
    'text-dark-blue border border-solid border-red-button': props.isPasswordError,
  })
  const inputStyle = classNames('w-full mb-0 outline-none', {
    'border-blue': !props.isPasswordError,
    'text-dark-blue border border-solid border-none': props.isPasswordError,
  })

  return (
    <div className={'input-content'}>
      {props.label && <span className={'label'}>{props.label}</span>}
      <div
        className={`text-border flex justify-between max-w-full mb-[10px] ${inputContainerStyle} ${inputContainerBorder}`}
      >
        <input
          className={inputStyle}
          type={inputType}
          ref={inputRef}
          value={props.value}
          placeholder={props.placeholder}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={maxLength}
          disabled={props.disabled}
          autoComplete={props.autoComplete}
        />
        <div className={'flex items-center justify-center'} onClick={handleTogglePassword}>
          {showPassword ? <ClosedEyeIcon /> : <EyeIcon />}
        </div>
      </div>
    </div>
  )
}
