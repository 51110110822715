import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { RootState, useAppDispatch } from '../..'

import LogoSVG from '../../assets/svgs/Logo.svg'
import { AuthState, loginSSO } from '../../slices/auth-slice'
import { isFailure } from '../../types/definitions/app-state'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { AppButton } from '../atoms/AppButton/AppButton'
import Spinner from '../atoms/Spinner'
import OptionsModal from '../molecules/OptionsModal'
import { CONTACT_EMAIL } from '../../constants/general'

const SSOProcess = (): JSX.Element => {
  const SSO_CODE_PARAM = 'code'
  const EMAIL = `mailto:${CONTACT_EMAIL}`

  const history = useHistory()
  const state = useSelector<RootState, AuthState>(state => state.auth)
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState<boolean>(true)
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false)

  useEffect(() => {
    const failure = isFailure(state.status)

    if (failure) setLoading(false)
  }, [state])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)

    if (queryParams.get(SSO_CODE_PARAM) === null) {
      history.push(createPathWithLang(NetworkConstants.URL_LOGIN))
    }

    if (queryParams.get(SSO_CODE_PARAM) && queryParams.get(SSO_CODE_PARAM) != null) {
      dispatch(loginSSO({ code: queryParams.get(SSO_CODE_PARAM) as string }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSendEmail = () => {
    setShowSupportModal(false)
    window.open(EMAIL)
  }

  return (
    <div className="flex-1 container flex justify-center">
      <div className="flex flex-col justify-center w-176">
        {loading ? (
          <div>
            <div className="w-full">
              <div className="pb-8 mb-12">
                <LogoSVG />
              </div>
              <p className="mb-24 text-3xl font-medium text-dark-blue">Processing Enterprise SSO credentials...</p>
            </div>
            <div className="w-full h-24 relative">
              <Spinner />
            </div>
          </div>
        ) : (
          <div>
            <div className="w-full">
              <div className="pb-8 mb-12">
                <LogoSVG />
              </div>
              <p className="mb-12 text-4xl font-medium text-dark-blue">Something went wrong</p>
            </div>
            <div className="flex items-center py-6 px-10 mb-12 font-bold w-full bg-red-button bg-opacity-10 rounded-md">
              <p className="text-error-red text-2xl">An error occurred while processing the SSO credentials</p>
            </div>
            <div>
              <p className="mb-3 text-2xl font-bold text-dark-blue">Please try again in a few minutes.</p>
              <p className="mb-12 text-2xl text-dark-blue">
                If the problem persists,{' '}
                <span
                  className="text-blue underline cursor-pointer"
                  onClick={() => setShowSupportModal(!showSupportModal)}
                >
                  contact support.
                </span>
              </p>
            </div>
            <div className="button-container">
              <Link to={NetworkConstants.URL_REVIEW_LIST}>
                <AppButton className="w-full">Back to Log in</AppButton>
              </Link>
            </div>
          </div>
        )}
      </div>
      <div>
        {showSupportModal && (
          <OptionsModal
            visible={showSupportModal}
            message={
              <p className="text-[2.5rem]">
                If you have any questions or experience any issues with Grapes, please describe the issue to:{' '}
                <a href={EMAIL}>{CONTACT_EMAIL}</a> and we will solve it as soon as possible.
              </p>
            }
            firstButtonText="Close"
            firstButtonType="clear"
            secondButtonText="Send mail"
            secondButtonType="info"
            onFirstButtonClick={() => setShowSupportModal(false)}
            onSecondButtonClick={() => handleSendEmail()}
            onClose={() => setShowSupportModal(false)}
          />
        )}
      </div>
    </div>
  )
}
export default SSOProcess
