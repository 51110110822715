import { Calendar, CalendarDateTemplateEvent, CalendarSelectEvent } from 'primereact/calendar'
import classNames from 'classnames'

interface PeriodCalendarProps {
  disabled?: boolean
  viewDate?: Date
  value?: Date
  minDate?: Date
  maxDate?: Date
  className?: string
  onSelect: (date: Date) => void
}

const PeriodCalendar = ({
  onSelect,
  className,
  value,
  minDate,
  maxDate,
  viewDate,
  disabled = false,
}: PeriodCalendarProps): JSX.Element => {
  const dateTemplate = (params: CalendarDateTemplateEvent): JSX.Element => {
    const date = new Date(params.year, params.month, params.day)
    if ((minDate && date < minDate) || (maxDate && date > maxDate))
      return <span className="text-red-button opacity-90">{params.day}</span>
    else return <span>{params.day}</span>
  }

  const handleSelect = (e: CalendarSelectEvent) => {
    onSelect(e.value as Date)
  }

  return (
    <Calendar
      disabled={disabled}
      viewDate={viewDate && new Date(viewDate.getFullYear(), viewDate.getMonth())}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      placeholder="mm/dd/yyyy"
      inputClassName={classNames(
        'h-12 bg-white border-[0.1rem] border-solid border-blue rounded-[0.8rem] py-[0.8rem] px-[1.2rem] text-[1.2rem] text-dark-blue',
        { 'border-toast-hover': disabled }
      )}
      panelClassName="text-lg"
      className={`period-calendar ${className}`}
      dateTemplate={dateTemplate}
      onSelect={handleSelect}
    />
  )
}

export default PeriodCalendar
