import classNames from 'classnames'
import { OrganizationChart, OrganizationChartNodeData } from 'primereact/organizationchart'
import { FC, ReactNode, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { assignColor } from '../../utils/utils'
import { EmployeeNode } from '../molecules/EmployeeNode'
import { DnDTeam } from './DnDTeam'
import { Employee } from '../../services/EmployeeService'
import { TeamDetails, TeamService } from '../../services/TeamService'
import { FullScreenIcon } from '../atoms/Icons/FullScreenIcon'
import { CrossButton } from '../atoms/CrossButton'
import Spinner from '../atoms/Spinner'
import { flattenTeams, getRootTeam } from '../../utils/teams/teamsUtils'
import { useQuery } from '@tanstack/react-query'

interface BasicNode extends OrganizationChartNodeData {
  type: string
  color?: string
}

interface Node extends BasicNode {
  type: 'team' | 'root'
  teamId: number
  teamName: string
  leaders: EmployeeData[]
  employees: EmployeeData[]
  highlight?: number[]
  data?: EmployeeData
}

const nodeTemplate = (node: Node): ReactNode => {
  if (node.type === 'team') {
    return (
      <DnDTeam
        teamId={node.teamId}
        key={node.label}
        color={node.color}
        teamName={node.teamName}
        employees={node.employees}
        leaders={node.leaders}
        highlight={node.highlight}
      />
    )
  }

  return (
    <div className="team-panel-column" style={{ textAlign: 'left' }}>
      <EmployeeNode member={node.data} modifier={node.type} />
    </div>
  )
}

export interface TeamData extends TeamDetails {
  teamId: number
  teamName?: string
  teamColor?: string
}

export interface EmployeeData extends Employee {
  teamData: TeamData[]
}

interface TeamChartProps {
  employees: Employee[]
  highlight?: number[] // TODO change to dict store, etc.
  currentUser?: Employee | null
}

interface TeamDataStore {
  [key: number]: TeamData
}

interface EmployeeDataStore {
  [key: number]: EmployeeData
}

const TeamChart: FC<TeamChartProps> = ({ employees, currentUser, highlight }): JSX.Element => {
  const [rootTeam, setRootTeam] = useState<TeamDetails>()
  const [teamData, setTeamData] = useState<TeamDataStore>({})
  const [employeeData, setEmployeeData] = useState<EmployeeDataStore>({})
  const [children, setChildren] = useState<Node[]>([])
  const [fullScreen, setFullScreen] = useState<boolean>(false)
  const [data, setData] = useState<Node[]>([])
  const [teams, setTeams] = useState<TeamDetails[]>([])
  const { isLoading, error, data: dataTeams } = useQuery(['teamsData'], () => TeamService.findAllDetailed())

  useEffect(() => {
    setTeams(flattenTeams(dataTeams || []))
  }, [dataTeams])

  useEffect(() => {
    const data: TeamDataStore = {}

    teams.forEach((team, i) => {
      data[team.id] = {
        ...team,
        teamId: team.id,
        teamName: team.name,
        teamColor: assignColor(i),
      }
    })

    setTeamData(data)
    setRootTeam(getRootTeam(teams))
  }, [teams])

  useEffect(() => {
    const data: EmployeeDataStore = {}

    employees.forEach(employee => {
      data[employee.id] = {
        ...employee,
        teamData: employee.teams?.map(t => teamData[t.id]) || [],
      }
    })

    if (currentUser) {
      data[currentUser.id] = {
        ...currentUser,
        teamData: [],
      }
    }

    setEmployeeData(data)
  }, [employees, teamData, currentUser])

  useEffect(() => {
    const createTeamNode = ({ id, name, leaders, employees, children }: TeamDetails, keepCurrent = false): Node => {
      return {
        type: id === rootTeam?.id ? 'root' : 'team',
        teamId: id,
        teamName: name,
        color: teamData[id].teamColor,
        employees: employees
          .map(employee => employeeData[employee.id])
          .filter(l => !!l)
          .sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
          }),
        leaders: leaders
          .map(leader => employeeData[leader.id])
          .filter(l => !!l)
          .sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
          }),
        children: children.map(child => createTeamNode(child)),
        highlight,
        expanded: true,
      }
    }

    if (rootTeam) {
      setChildren([createTeamNode(rootTeam, true)])
    }
  }, [teams, teamData, rootTeam, employees, employeeData, currentUser, highlight])

  useEffect(() => {
    if (currentUser) {
      setData([
        {
          type: 'root',
          expanded: true,
          data: employeeData[currentUser?.id],
          children: children,
          // FIXME these fields below shouldn't be necessary
          leaders: [],
          employees: [],
          teamName: '0',
          teamId: 0,
        },
      ])
    } else {
      setData([])
    }
  }, [currentUser, children, employeeData])

  const wrapperClass = classNames({
    'overflow-hidden w-full round-lg': true,
    'org-chart-container': true,
    'org-chart-container--full': fullScreen,
    relative: !fullScreen,
  })

  const maximizeButtonClass = classNames({
    'px-3 py-2 rounded text-white text-xl': true,
    'bg-inactive-gray hover:bg-blue': !fullScreen,
    'bg-blue hover:bg-blue-hover': fullScreen,
  })

  if (error) console.error(error)

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={wrapperClass}>
        <TransformWrapper
          centerZoomedOut
          centerOnInit
          wheel={{ step: 0.05 }}
          minScale={0.5}
          initialScale={1}
          doubleClick={{ disabled: true }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <div className="flex absolute top-0 left-0 z-50 p-4 gap-2">
                <button
                  className="bg-inactive-gray hover:bg-blue px-3 py-2 rounded text-white text-xl"
                  onClick={() => zoomIn()}
                >
                  <i className="pi pi-plus" />
                </button>
                <button
                  className="bg-inactive-gray hover:bg-blue px-3 py-2 rounded text-white text-xl"
                  onClick={() => zoomOut()}
                >
                  <i className="pi pi-minus" />
                </button>
                <button className={maximizeButtonClass} onClick={() => setFullScreen(!fullScreen)}>
                  <FullScreenIcon />
                </button>
                <button
                  className="bg-inactive-gray hover:bg-blue px-3 py-2 rounded text-white text-xl"
                  onClick={() => resetTransform()}
                >
                  Reset
                </button>
              </div>
              <div className="flex absolute top-0 right-0 z-50 p-4 gap-2">
                {fullScreen ? <CrossButton onClick={() => setFullScreen(false)} /> : null}
              </div>
              <TransformComponent wrapperClass={`org-chart-wrapper ${fullScreen && 'max-h-full'}`}>
                <div>
                  {children.length && currentUser && data && (
                    <OrganizationChart value={data} nodeTemplate={nodeTemplate} />
                  )}
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </div>
    </DndProvider>
  )
}

export default TeamChart
