import { FC } from 'react'

interface Props {
  color?: string
  width?: string
  height?: string
}

export const ConfirmIcon: FC<Props> = ({ width = 62, height = 62, color = '#005ECA' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31 61C47.5685 61 61 47.5685 61 31C61 14.4315 47.5685 1 31 1C14.4315 1 1 14.4315 1 31C1 47.5685 14.4315 61 31 61Z"
        stroke={color}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31 14V38"
        stroke={color}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31 48C32.1046 48 33 47.1046 33 46C33 44.8954 32.1046 44 31 44C29.8954 44 29 44.8954 29 46C29 47.1046 29.8954 48 31 48Z"
        fill={color}
        stroke={color}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
