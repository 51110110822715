import { Tooltip, TooltipProps } from '@material-ui/core'
import FullPageBackdrop from './FullPageBackdrop'
interface AppTooltipProps extends TooltipProps {
  backdrop?: boolean
  backdropClassName?: string
  backdropClick?: () => void
}

const AppTooltip = ({ children, title, placement, backdrop = false, ...rest }: AppTooltipProps) => {
  const { backdropClick, ...others } = rest
  return (
    <>
      <Tooltip title={title} placement={placement} {...others}>
        {children}
      </Tooltip>
      {backdrop && <FullPageBackdrop {...rest} />}
    </>
  )
}

export default AppTooltip
