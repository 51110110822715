import { classNames } from 'primereact/utils'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../..'
import { finishIntroduction, showIntroductionId } from '../../slices/introduction-slice'
import { IntroductionContent, IntroductionContentKeys } from '../../utils/IntroductionContent'
import { SmallArrowButton } from '../atoms/SmallArrowButton/SmallArrowButton'
import { AppButton } from '../atoms/AppButton/AppButton'
import AppTooltip from '../atoms/AppTooltip'

interface ParentPreviousState {
  element: HTMLElement
  position: string | undefined
  sticky: boolean
  relative: boolean
  absolute: boolean
  fixed: boolean
  zIndex: string | undefined
}

interface IntroductionTooltipProps {
  children: React.ReactElement
  id: IntroductionContentKeys
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}
const IntroductionTooltip = ({ id, children, placement = 'right-start' }: IntroductionTooltipProps) => {
  const current = useSelector<RootState, string>(state => state.introduction.current as string)
  const [currentRef, setCurrentRef] = useState<HTMLDivElement>()
  const [parentsChecked, setParentsChecked] = useState<boolean>(false)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const handleClickPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const handleSkipClick = () => {
    restoreParentPositioningStyles()
    dispatch(showIntroductionId(''))
    dispatch(finishIntroduction(id))
  }

  const handleBackClick = () => {
    restoreParentPositioningStyles()
    dispatch(showIntroductionId(IntroductionContent[id].previous || ''))
  }

  const handleNextClick = () => {
    restoreParentPositioningStyles()
    dispatch(showIntroductionId(IntroductionContent[id].next || ''))
  }

  const handleCloseClick = () => {
    restoreParentPositioningStyles()
    dispatch(showIntroductionId(''))
    IntroductionContent[id].finish && dispatch(finishIntroduction(id))
  }

  useEffect(() => {
    if (tooltipRef.current && current === id) {
      window.scrollTo(0, tooltipRef.current.offsetTop - 258)
    }
  })

  const childContainerRef = useRef<HTMLDivElement>(null)

  const removeParentPositioningStyles = (element: HTMLElement, previousState: ParentPreviousState): void => {
    if (previousState.position && previousState.position !== '') {
      element.style.position = 'static'
    }
    if (previousState.zIndex && previousState.zIndex !== '') {
      element.style.zIndex = 'auto'
    }
    if (previousState.sticky) {
      element.classList.remove('sticky')
    }
    if (previousState.absolute) {
      element.classList.remove('absolute')
    }
    if (previousState.fixed) {
      element.classList.remove('fixed')
    }
    if (previousState.relative) {
      element.classList.remove('relative')
    }
  }

  const restoreParentPositioningStyles = (): void => {
    parentsPreviousState.forEach(previousState => {
      const element = previousState.element
      if (previousState.position && previousState.position !== '') {
        element.style.position = previousState.position
      }
      if (previousState.zIndex && previousState.zIndex !== '') {
        element.style.zIndex = previousState.zIndex
      }
      if (previousState.sticky) {
        element.classList.add('sticky')
      }
      if (previousState.absolute) {
        element.classList.add('absolute')
      }
      if (previousState.fixed) {
        element.classList.add('fixed')
      }
      if (previousState.relative) {
        element.classList.add('relative')
      }
    })
  }

  const [parentsPreviousState, setParentsPreviousState] = useState<ParentPreviousState[]>([])
  useEffect(() => {
    if (childContainerRef && !parentsChecked && current === id) {
      let parent = childContainerRef?.current?.parentElement
      while (parent) {
        const parentState: ParentPreviousState = {
          element: parent,
          position: parent.style.position,
          sticky: parent.classList.contains('sticky'),
          relative: parent.classList.contains('relative'),
          absolute: parent.classList.contains('absolute'),
          fixed: parent.classList.contains('fixed'),
          zIndex: parent.style.getPropertyValue('z-index'),
        }
        if (
          parentState.position ||
          parentState.sticky ||
          parentState.relative ||
          parentState.absolute ||
          parentState.fixed ||
          parentState.zIndex
        ) {
          removeParentPositioningStyles(parent, parentState)
          parentsPreviousState.push(parentState)
        }
        parent = parent.parentElement
      }

      setParentsPreviousState(parentsPreviousState)
      setParentsChecked(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childContainerRef, current, id])

  const IntroductionTooltipHeader = ({ id }: { id: IntroductionContentKeys }) => {
    return (
      <div className="flex w-full">
        <div className="flex shrink-0 w-4">
          {IntroductionContent[id].previous && <SmallArrowButton type="previous" onClick={handleBackClick} />}
        </div>
        <div className="flex-grow text-center text-xl text-gray-3">{IntroductionContent[id].subtitle}</div>
        <div className="flex shrink-0 w-4">
          {IntroductionContent[id].next && <SmallArrowButton type="next" onClick={handleNextClick} />}
        </div>
      </div>
    )
  }

  const IntroductionTooltipBody = ({ id }: { id: IntroductionContentKeys }) => {
    return (
      <div
        className="flex flex-col !max-w-none items-start gap-6 px-1 mx-2"
        ref={ref => ref && currentRef === undefined && setCurrentRef(ref)}
        id={id}
      >
        <IntroductionTooltipHeader id={id} />
        <div className="text-3xl font-normal">{IntroductionContent[id].title}</div>
        <div className="text-xl">{IntroductionContent[id].body}</div>
        <div className="flex flex-row w-full text-xl justify-start">
          {IntroductionContent[id].skip && (
            <AppButton
              className="!min-w-[0px] w-1/2 !bg-white pl-0 !justify-start"
              type="clear"
              onClick={handleSkipClick}
            >
              Skip tutorial
            </AppButton>
          )}
          {IntroductionContent[id].next ? (
            <AppButton className="!min-w-[0px] w-1/2" type="info" onClick={handleNextClick}>
              Next
            </AppButton>
          ) : (
            <div className="flex w-full !justify-end">
              <AppButton className="!px-8 min-w-[8rem]" type="info" onClick={handleCloseClick}>
                Finish
              </AppButton>
            </div>
          )}
        </div>
      </div>
    )
  }
  const open = current === id
  const tooltipClassName = classNames({
    'bg-white z-0 shadow-lg rounded-lg w-fit !max-w-[284px] p-8 text-dark-blue text-2xl leading-normal': true,
    'my-14': placement.includes('bottom') || placement.includes('top'),
    'mx-12 my-10': !placement.includes('bottom') && !placement.includes('top'),
  })
  return (
    <div className="flex" ref={tooltipRef} onClick={handleClickPropagation}>
      <div
        ref={childContainerRef}
        className={open ? 'relative z-50 bg-white flex flex-wrap w-fit items-center p-6 flex-1' : ''}
      >
        {children}
      </div>
      <AppTooltip
        classes={{
          tooltip: tooltipClassName,
          arrow: 'dot-tooltip-arrow',
        }}
        interactive={true}
        placement={placement}
        title={<IntroductionTooltipBody id={id} />}
        backdrop={true}
        open={open}
      >
        <span></span>
      </AppTooltip>
    </div>
  )
}

export default IntroductionTooltip
