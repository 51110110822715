import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AppStatus } from '../types/definitions/app-state'
import { EmployeeDetails } from '../services/EmployeeService'
import { AuthService, LoginParams, LoginTokenParams } from '../services/AuthService'
import history from '../utils/history'
import { createPathWithLang } from '../utils/languagePathUtils'
import { NetworkConstants } from '../utils/NetworkConstants'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../services/EventTrackingService'
import { SSOCodeLoginRequest } from '../api/openapi'

export const StorageConfiguration = {
  tokenStorageTag: 'token',
  userStorageTag: 'user',
}

const SliceConfiguration = {
  reducerTag: 'auth',
}

export interface AuthState {
  user: EmployeeDetails | null
  status: AppStatus
}

const initialState: AuthState = {
  user: JSON.parse(localStorage.getItem(StorageConfiguration.userStorageTag) || 'null'),
  status: { kind: 'idle' },
}

export const loginToken = createAsyncThunk(
  `${SliceConfiguration.reducerTag}/login`,
  async (request: LoginTokenParams, thunkAPI) => {
    const user = await AuthService.loginToken(request).then(token => {
      localStorage.setItem(StorageConfiguration.tokenStorageTag, token)

      return AuthService.getCurrentUser().then(user => {
        localStorage.setItem(StorageConfiguration.userStorageTag, JSON.stringify(user))
        return user
      })
    })

    return user
  }
)

export const login = createAsyncThunk(
  `${SliceConfiguration.reducerTag}/login`,
  async (request: LoginParams, thunkAPI) => {
    const user = await AuthService.login(request).then(response => {
      localStorage.setItem(StorageConfiguration.tokenStorageTag, response?.jwtToken || '')
      return AuthService.getCurrentUser().then(user => {
        localStorage.setItem(StorageConfiguration.userStorageTag, JSON.stringify(user))
        setTimeout(() => {
          trackEvent(
            TrackingCategoryEnum.AUTHENTICATION,
            TrackingCategoryEventEnum.AUTHENTICATION.USER_LOGIN_SUCCESS,
            ''
          )
          if (!response.lastUpdatedPassword) {
            history.push(createPathWithLang(NetworkConstants.URL_CHANGE_PASSWORD))
          } else {
            history.push(NetworkConstants.URL_HOME_PATH)
          }
        }, 100)
        return user
      })
    })

    return user
  }
)

export const loginSSO = createAsyncThunk(
  `${SliceConfiguration.reducerTag}/login`,
  async (request: SSOCodeLoginRequest, thunkAPI) => {
    const user = await AuthService.loginSSO(request).then(response => {
      localStorage.setItem(StorageConfiguration.tokenStorageTag, response?.jwtToken || '')
      return AuthService.getCurrentUser().then(user => {
        localStorage.setItem(StorageConfiguration.userStorageTag, JSON.stringify(user))
        setTimeout(() => {
          trackEvent(
            TrackingCategoryEnum.AUTHENTICATION,
            TrackingCategoryEventEnum.AUTHENTICATION.USER_LOGIN_SSO_SUCCESS,
            ''
          )
          history.push(NetworkConstants.URL_HOME_PATH)
        }, 100)
        return user
      })
    })

    return user
  }
)

export const authSlice = createSlice({
  name: SliceConfiguration.reducerTag,
  initialState: initialState,
  reducers: {
    logout: state => {
      localStorage.removeItem(StorageConfiguration.tokenStorageTag)
      localStorage.removeItem(StorageConfiguration.userStorageTag)
      return {
        ...state,
        user: null,
        token: null,
        status: { kind: 'idle' },
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        return {
          ...state,
          status: { kind: 'loading' },
        }
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        return {
          ...state,
          status: { kind: 'idle' },
          user: payload as EmployeeDetails,
        }
      })
      .addCase(login.rejected, (state, { error }) => {
        return {
          ...state,
          status: { kind: 'failure', error: error.message ?? 'unknown' },
        }
      })
  },
})

export const { logout } = authSlice.actions
