import { FC, useEffect, useState } from 'react'

import { AppInput } from '../../../atoms/AppInput/AppInput'
import { CheckBox } from '../../../atoms/CheckBox'

export interface ChecklistQuestionProps {
  title: string
  selectedOptions: string[]
  options: { label: string; value: string }[]
  customOptionsPlaceholder: string
  unfinished?: boolean
  required?: boolean
  skippable: string | undefined
  skipped: boolean
  onChange?: (value: string[], skipped?: boolean) => void
  previousAnswer?: string[]
}

export const ChecklistQuestion: FC<ChecklistQuestionProps> = ({
  title,
  options,
  selectedOptions = [],
  customOptionsPlaceholder,
  unfinished,
  required = true,
  skippable,
  skipped,
  onChange,
  previousAnswer,
}) => {
  const [customOptions, setCustomOptions] = useState<string[]>([''])
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState(selectedOptions)

  const getAnswersToAdd = (): string[] => {
    const allOptions = options.map(o => o.value)
    const answersToAdd: string[] = previousAnswer?.filter(answer => !allOptions.some(option => option === answer)) || []
    return answersToAdd
  }

  const newOptions = options.concat(
    getAnswersToAdd().map(answer => {
      return { value: answer, label: answer }
    })
  )

  useEffect(() => {
    if (!skipped) {
      const customOptions = selectedOptions?.filter(option => !newOptions.some(({ value }) => value === option))
      setCustomOptions([...customOptions, ''])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeCustomOption = (value: string, index: number) => {
    const data: string[] = [...customOptions]
    data[index] = value
    setCustomOptions(data)
  }

  const addFields = () => {
    if (customOptions.every(value => value.length > 2)) setCustomOptions([...customOptions, ''])
  }

  const removeField = (index: number) => {
    const data = [...customOptions]
    if (data.length > 1) {
      data.splice(index, 1)
      handleCustomOptionFill(index)('')
      setCustomOptions(data)
    } else {
      setCustomOptions([''])
    }
  }

  const handleCheck = (value: unknown): void => {
    let localCurrentSelectedOptions = currentSelectedOptions
    if (skipped) localCurrentSelectedOptions = []
    const newSelectedOptions = localCurrentSelectedOptions.includes(value as string)
      ? localCurrentSelectedOptions.filter(option => option !== value)
      : [...localCurrentSelectedOptions, value as string]
    onChange?.(
      newSelectedOptions.filter(o => o.length),
      false
    )
    setCurrentSelectedOptions(newSelectedOptions.filter(o => o.length))
  }

  const handleSkip = (): void => {
    onChange?.([skippable as string], !skipped)
    setCurrentSelectedOptions([])
  }

  const handleCustomOptionFill =
    (customOptionIdx: number) =>
    (value: string): void => {
      handleChangeCustomOption(value, customOptionIdx)
      const customOption = customOptions[customOptionIdx]
      const selectedOptionIdx = currentSelectedOptions.indexOf(customOption)
      let newSelectedOptions
      if (selectedOptionIdx >= 0) {
        currentSelectedOptions[selectedOptionIdx] = value
        newSelectedOptions = [...currentSelectedOptions]
      } else {
        newSelectedOptions = [...currentSelectedOptions, value]
      }

      onChange?.(
        newSelectedOptions.filter(o => o.length),
        false
      )
      setCurrentSelectedOptions([...new Set(newSelectedOptions.filter(o => o.length))])
    }

  return (
    <div className="checklist-question relative">
      <div className="flex self-start">
        <p className="checklist-question-title ">{title}</p>
        {!required && <p className="optional-question ml-auto">Optional</p>}
      </div>
      <div className="checklist-question-list text-2xl">
        {newOptions.map(({ label, value }, i) => (
          <CheckBox
            key={`${title}-checklist-question-${i}`}
            label={label}
            checked={currentSelectedOptions.includes(value)}
            unfinished={unfinished && !currentSelectedOptions.length && required && !!skippable && !skipped}
            value={value}
            onChecked={handleCheck}
            disabled={skipped}
            checkedStyle={true}
            extraLabel={previousAnswer?.some(a => a === value) ? 'Previous' : undefined}
          />
        ))}

        <div className={`flex flex-col gap-5 mb-[55px] ${skipped ? 'invisible' : 'visible'}`}>
          <div className="flex flex-col gap-[9px] mb-[80px] mt-5">
            {customOptions.map((customOption: string, index: number) => {
              return (
                <AppInput
                  disabled={false}
                  value={customOption}
                  placeholder={customOptionsPlaceholder}
                  onChange={handleCustomOptionFill(index)}
                  className="flex items-center pl-5 h-[30px] font-light bg-white border-solid border-[1px] border-blue rounded-[8px] text-2xl"
                  onSubmit={addFields}
                  onClear={() => removeField(index)}
                  autoFocus
                  key={index}
                />
              )
            })}
          </div>
        </div>
        <div className="flex flex-col gap-10 w-[100%] items-center">
          <div className="flex items-center justify-center absolute bottom-[101px]">
            <div className="w-[67px] h-[1px] bg-toast-hover "></div>
            <div className="text-2xl text-toast-hover ml-3 mr-3">or</div>
            <div className="w-[67px] h-[1px] bg-toast-hover "></div>
          </div>
          {skippable && (
            <div className="absolute bottom-[32px] flex self-start">
              <CheckBox
                key={`${title}-checklist-question-skip`}
                label={skippable}
                checked={skipped}
                unfinished={false}
                value={skippable}
                onChecked={handleSkip}
                checkedStyle={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
