import { Employee } from '../../../services/EmployeeService'
import { Review } from '../../../types/definitions/review'
import { EMPLOYEE_HIGHLIGHT_ICON_MAP, EMPLOYEE_HIGHLIGHT_TRIGGER_CRITERIA } from '../../../constants/employee'
import { createRef, useEffect, useState } from 'react'
import { PersonIcon } from '../../atoms/Icons/PersonIcon'
import { DownloadPDFButton } from './DownloadButton'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../../services/EventTrackingService'
import { trimEllip } from '../../../utils/utils'

type EmployeeHeaderProps = {
  employee: Employee
  employees?: Employee[]
  reviews?: Review[]
  addReviewer?: boolean
  showDownloadButton?: boolean
}

const NAME_LENGTH = 30
const JOB_TITLE_LENGTH = 25

const EmployeeResultsHeader = ({
  employee,
  employees,
  reviews,
  addReviewer = false,
  showDownloadButton = false,
}: EmployeeHeaderProps): JSX.Element => {
  const hiddenRef = createRef<HTMLDivElement>()
  const [visible, setVisibile] = useState(true)
  const emojis = employee?.highlights?.map(highlight => EMPLOYEE_HIGHLIGHT_ICON_MAP[highlight]) || []

  // Download button
  const [animationCompleted, setAnimationCompleted] = useState(false)

  const scrollHandler = () => {
    if (hiddenRef.current) {
      setVisibile(window.pageYOffset - 80 <= hiddenRef.current.offsetTop)
    }
  }

  useEffect(() => {
    // the chart animations are 1000ms long by default
    setTimeout(() => setAnimationCompleted(true), 1001)
  })

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  })

  const downloadPDF = () => {
    trackEvent(
      TrackingCategoryEnum.INDIVIUAL_REPORT,
      TrackingCategoryEventEnum.INDIVIUAL_REPORT.CLICK_DOWNLOAD_REPORT,
      ''
    )
    setAnimationCompleted(false)
    // if the user has changed the charts, we add an extra time
    setTimeout(() => {
      setAnimationCompleted(true)
      const reportDate = new Date().toLocaleDateString('en-US')
      const defTitle = document.title
      document.title = `Grapes report for ${employee?.name} downloaded at ${reportDate}`
      window.print()
      document.title = defTitle
    }, 500)
  }

  return (
    <>
      {!visible && (
        <div className="relative print:hidden">
          <div className="fixed top-0 left-0 right-0 z-50 bg-white drop-shadow-xl">
            <div className="flex container w-full mx-auto items-center justify-start">
              <div className="flex w-3/4 px-20 py-7 items-center text-3xl text-dark-blue">
                {!!emojis.length ? <span>{emojis.join('  ')}&nbsp;&nbsp;</span> : <PersonIcon width={15} height={15} />}
                <h3 className="font-bold mr-3 ml-4">{employee?.name} ·</h3>
                <h3 className="font-medium">{employee.jobTitle}</h3>
              </div>
              {showDownloadButton && (
                <div className="flex w-1/4 invisible lg:visible items-end justify-end mr-20">
                  <DownloadPDFButton downloadFunction={downloadPDF} disabled={!animationCompleted} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-between mb-12" ref={hiddenRef}>
        <div className="flex justify-start gap-40">
          <div className="my-2 min-w-1/4 flex-none">
            <h1 className="text-2xl font-bold text-stone-gray">Name</h1>
            <div className="text-4xl font-medium text-dark-blue mt-4 w-max">
              {!!emojis.length && <span>{emojis.join('  ')}&nbsp;&nbsp;</span>}
              {employee.name}
            </div>
          </div>
          <div className="my-2 min-w-2/4">
            <h1 className="text-2xl font-bold text-stone-gray">Job Title</h1>
            <div className="text-4xl font-medium text-dark-blue mt-4">{employee.jobTitle}</div>
          </div>
        </div>
        {showDownloadButton && (
          <div className="flex items-end justify-end min-w-1/4">
            <DownloadPDFButton downloadFunction={downloadPDF} disabled={!animationCompleted} />
          </div>
        )}
      </div>
    </>
  )
}

export default EmployeeResultsHeader
