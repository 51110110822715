import { classNames } from 'primereact/utils'
import { FC } from 'react'
import SmallArrowSVG from '../Icons/SmallArrow.svg'

export interface SmallArrowButtonProps {
  type: SmallArrowButtonType
  onClick: () => void
}

export type SmallArrowButtonType = 'previous' | 'next'

export const SmallArrowButton: FC<SmallArrowButtonProps> = ({ onClick, type }) => {
  const className = classNames({
    'hover:underline text-2xl font-medium flex items-center {rotateIfNeeded}': true,
    'rotate-180': type === 'next',
  })
  return (
    <button className={className} onClick={onClick}>
      <SmallArrowSVG direction="horizontal" horizontalDirection="left" color="rgb(140,140,140)" />
    </button>
  )
}
