import { FC } from 'react'

export interface BreadcrumbItem {
  label: string
  id?: number
}

interface BreadcrumbProps {
  items: BreadcrumbItem[]
  onItemSelected?: (employeeId: number) => void
  lastItemSuffix?: string
}

const Breadcrumb: FC<BreadcrumbProps> = ({ items, onItemSelected, lastItemSuffix = '' }) => (
  <ul className="flex">
    {items.map(({ id, label }, i) => (
      <li key={id}>
        {i > 0 && <span className="text-xl text-dark-blue-hover"> &nbsp;&nbsp;&nbsp;{'>'}&nbsp;&nbsp;&nbsp; </span>}
        <button
          className={`text-xl text-dark-blue-hover ${i === items.length - 1 ? 'font-bold' : ''}`}
          onClick={() => onItemSelected?.(id as number)}
        >
          {i + 1 === items.length ? label + lastItemSuffix : label}
        </button>
      </li>
    ))}
  </ul>
)

export default Breadcrumb
