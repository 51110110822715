import LogoSVG from '../../assets/svgs/Logo.svg'
import { ArrowIcon } from '../atoms/Icons/ArrowIcon'
const NotFound = (): JSX.Element => {
  return (
    <div className="container">
      <div className="flex flex-col py-14 gap-24">
        <a href="/">
          <LogoSVG></LogoSVG>
        </a>
        <h1 className="outline-text">404</h1>
        <div className="flex flex-col gap-20 w-3/5 max-w-[450px]">
          <p className="text-6xl text-blue font-medium text tracking-wide">
            Oops, We can't seem to find the page what you are looking for.
          </p>
          <p className="text-3xl font-medium">
            The page you are looking for might have been removed, had its name changed or is temporarily unavailable
          </p>
          <a className="text-white bg-blue rounded-lg text-2xl font-bold py-4 px-8 flex items-center w-64" href="/">
            <div className="mr-3">
              <ArrowIcon height={14} width={14} color="#FFFFFF" />
            </div>
            Back to Home
          </a>
        </div>
      </div>
    </div>
  )
}

export default NotFound
