interface MessageProps {
  title?: React.ReactNode
  body: React.ReactNode
  icon?: React.ReactNode
  className?: string
}
const Message = ({ body, title, icon, className }: MessageProps): JSX.Element => {
  return (
    <div className={`flex justify-center align-center items-center text-center flex-col gap-8 ${className || ''}`}>
      {icon !== undefined && icon}
      {title && <p className="text-5xl leading-normal font-medium">{title}</p>}
      <p className="text-2xl leading-[2.2rem]">{body}</p>
    </div>
  )
}

export default Message
