import { v4 as uuidv4 } from 'uuid'
// FIXME: We don't need to use inputSwitch anymore when we migrate to next.
// Tailwind allows to easily create a toggle button wich will be more "change color" friendly
import { InputSwitch } from 'primereact/inputswitch'
import { useEffect, useLayoutEffect, useState } from 'react'
import classNames from 'classnames'
import { ClockIcon } from '../Icons/ClockIcon'

interface ToggleWithTextProps {
  color: string
  checked?: boolean
  onChange: (checked: boolean, text: string) => void
  text: string
  subText: string
  disabled?: boolean
  skipped: boolean
}

const TeamToggleWithText = (props: ToggleWithTextProps): JSX.Element => {
  const disabled = props.text === 'Team Average' || props.disabled

  const [checked, setChecked] = useState(!!props.checked)
  // I'm using id and not useRef because InputSwitch is an HTML wrapper
  // and I can't reach the HTML otherwise
  const [id] = useState(`toggle-${uuidv4()}`)

  useEffect(() => {
    setChecked(!!props.checked)
  }, [props.checked])

  useLayoutEffect(() => {
    const style = document.createElement('style')

    if (disabled) {
      style.innerHTML = `
      #${id}.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
        background-color: ${props.color};
        opacity: 0.65;
      }
      #${id}.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        display: none;
      }
      #${id}.p-inputswitch .p-inputswitch-slider:before {
        display: none;
      }
    `
    } else {
      style.innerHTML = `
      #${id}.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
        background-color: ${props.color};
        opacity: 1;
      }
      #${id}.p-inputswitch .p-inputswitch-slider{
        background-color: ${props.color};
        opacity: 0.3;
      }
    `
    }

    document.getElementById(id)?.append(style)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnChange = (): void => {
    const newCheckedStatus = !checked
    setChecked(newCheckedStatus)
    props.onChange(newCheckedStatus, props.text)
  }

  return (
    <div className="toggle-with-text flex items-start gap-3">
      <div className="min-w-8">
        <InputSwitch
          id={id}
          checked={checked}
          disabled={disabled}
          onChange={handleOnChange}
          className="toggle-btn flex"
        />
      </div>
      <div className="flex flex-col -mt-2">
        <span className={classNames('text-xl', { 'text-toast-hover': props.disabled })}>{props.text}</span>
        <span className={classNames('font-bold text-base leading-tight', { 'text-toast-hover': props.disabled })}>
          {props.subText}
        </span>
        <ReportMissing isDisabled={props.disabled ?? false} skipped={props.skipped ?? false} />
      </div>
    </div>
  )
}

const ReportMissing = ({ isDisabled, skipped }: { isDisabled: boolean; skipped: boolean }) => {
  return (
    <div>
      {isDisabled && (
        <div className="leading-tight flex items-center mt-3">
          {isDisabled && !skipped && (
            <span className={classNames('text-base', { 'text-toast-hover': isDisabled })}>
              Assessment <b>uncompleted</b>
            </span>
          )}
          {skipped && (
            <div className="flex items-center gap-1">
              <span className={classNames('text-base', { 'text-toast-hover': isDisabled })}>
                Assessment <b>skipped</b>
              </span>
              <ClockIcon height={10} width={10} color="#979797" />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default TeamToggleWithText
