const SortIconSVG = (): JSX.Element => {
  return (
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 5L5 1L9 5M9 11L5 15L1 11"
        stroke="#D3D3D3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SortIconSVG
