import usingGrapesImg1 from '../assets/images/faq-images/using-grapes-1.png'
import assessmentImg1 from '../assets/images/faq-images/assessment-1.png'
import assessmentImg2 from '../assets/images/faq-images/assessment-2.png'
import assessmentImg3 from '../assets/images/faq-images/assessment-3.png'
import assessmentImg4 from '../assets/images/faq-images/assessment-4.png'
import { CONTACT_EMAIL } from '../constants/general'

const mailSnippet = `**[${CONTACT_EMAIL}](mailto:${CONTACT_EMAIL})**`

interface FaqContent {
  title: string
  content: string
  images?: string[]
  logged: boolean
  leader: boolean
  allUsers?: boolean
}

export interface FaqCategory {
  id: string
  name: string
  content: FaqContent[]
}

export const ContentList: FaqCategory[] = [
  {
    id: 'about',
    name: 'About Grapes',
    content: [
      {
        title: 'What is **GRAPES**?',
        content: `GRAPES is an IT talent assessment tool that was developed by The Agile Monkeys in partnership with Optum Financial Technology.`,
        logged: false,
        leader: false,
        allUsers: true,
      },
      {
        title: 'Who completes the **talent assessments**?',
        content: `Managers will complete the assessments for their staff. The manager may also ask other leaders familiar with a person's work to complete an assessment for additional perspective.`,
        logged: false,
        leader: false,
        allUsers: true,
      },
      {
        title: 'How **frequently will these assessments be** conducted?',
        content: `Managers should complete an assessment at least quarterly to facilitate frequent development conversations and show progress over time.`,
        logged: false,
        leader: false,
        allUsers: true,
      },
      {
        title: 'Who can see the **results of the assessments**?',
        content: `Currently results can be viewed by your manager and the leaders in your hierarchy. Your manager can also download a PDF of the report to share with you.`,
        logged: false,
        leader: false,
        allUsers: true,
      },
      {
        title: 'What **skills are assessed** in GRAPES?',
        content: `The tool considers professional skills appropriate for a particular role, along with productivity, commitment, communication and growth mindset. For some roles, the questions are tailored by job level based on leveling guides that have been established within Optum and UHC Tech.`,
        logged: false,
        leader: false,
        allUsers: true,
      },
      {
        title: 'How do we **intend to use the results** of the assessments? What **decisions** do they drive?',
        content: `The assessments are intended to give managers and leaders a view of the strengths and gaps at the individual and team level. This helps facilitate development conversations throughout the year, identifying opportunities to help promote growth and strengthen skills through training, coaching, project assignments and more.`,
        logged: false,
        leader: false,
        allUsers: true,
      },
      {
        title: 'We have MAP, CLL, Leveling Guides, etc - why do we **need another assessment tool**?',
        content: `MAP, CLL, and the Leveling Guides all focus on specific individual skills, serving different purposes - execution of business goals, leadership skills and technical skills. Grapes was developed to create a clear picture of individual and team strengths and gaps across multiple facets to maximize performance and build an Engineering Culture.`,
        logged: false,
        leader: false,
        allUsers: true,
      },
      {
        title: 'Does this **replace the annual review** in MAP?',
        content: `No, this does not replace the formal annual review process in MAP. However, the assessments and resulting career development conversations with managers throughout the year can support the annual review process, as they can demonstrate growth and strengths during the review period.`,
        logged: false,
        leader: false,
        allUsers: true,
      },
      {
        title: 'How do these assessments **impact my annual review**?',
        content: `There is not a direct correlation between these assessments and the annual review, but the assessments can be used to demonstrate or highlight growth and contributions to the team during the review period.`,
        logged: false,
        leader: false,
        allUsers: true,
      },
      {
        title: 'Will I be able to **see the results** of my assessment and provide feedback?',
        content: `Your manager will review the assessments with you via 1:1 conversations, during which you are encouraged to ask questions and provide feedback. Your manager can also download a PDF of the report to share with you.`,
        logged: false,
        leader: false,
        allUsers: true,
      },
    ],
  },
  {
    id: 'account-password',
    name: 'Account and password',
    content: [
      {
        title: "I **can't access Grapes** - my SSO credentials are not working.",
        content: `If your team uses Grapes you can access to the platform with your regular Optum Enterprise Single Sign On login details.\n\nPlease bear in mind that if you joined recently, your details might not be in the roster yet. The roster in Grapes is updated every week, so you should get access soon.\n\nIf you are having trouble accessing the platform, please reach out to ${mailSnippet} indicating your **full name** and the **team** you belong to, and explain your issue.`,
        logged: false,
        leader: false,
      },
      {
        title: 'How can I **sign up**?',
        content: `There are two reasons why you may not have a Grapes account:\n\n 1.Grapes is not deployed to your Optum team yet.\n\n 2.If you joined recently, your details might not be in the roster yet. You should receive credentials soon.\n\nIf you experience any of these issues, please send us an email to ${mailSnippet} from your Optum email address indicating the team ****you belong to, and explain your issue.`,
        logged: false,
        leader: false,
      },
      {
        title:
          'I would like to **invite someone from an organization** that doesn’t use Grapes to assess a team member',
        content: `The ***Delegate review*** feature is only available for other leaders within your organization and supervisors inside your own team.\n\nIf you need input from someone from a team that is not using Grapes yet, please get in touch with us at ${mailSnippet} and explain your situation.`,
        logged: true,
        leader: true,
      },
      {
        title: 'One of my team members is having **trouble accessing** Grapes',
        content: `All staff members of a team using Grapes can access Grapes using their regular Enterprise SSO credentials.\n\nIf your staff member is having trouble logging in, and they show up with the right details (name, job title) in your team list on Grapes, please reach out to us at ${mailSnippet} and explain the situation so we can solve the issue.`,
        logged: true,
        leader: true,
      },
    ],
  },
  {
    id: 'using-grapes',
    name: 'Using Grapes',
    content: [
      {
        title: 'My staff member has an **incorrect job title/level**',
        content: `We use data from the roster to fill in the job title, but roster files might not always reflect recent changes.\n\nPlease get in touch with us at ${mailSnippet} and explain your issue so we can inform the staff team and get this fixed until the roster file is up to date.`,
        leader: true,
        logged: true,
      },
      {
        title: 'Can I conduct assessments **more than once** per person?',
        content: `There is no limit to the number of assessments you can complete. We recommend conducting assessments at least quarterly to facilitate frequent development conversations and show progress over time. You can conduct assessments more often if you find it helpful to assist in your team development conversations.`,
        leader: true,
        logged: true,
      },
      {
        title: "I **can't find one of my staff** members in the Reviews page",
        content: `Staff lists are updated in Grapes every week using the latest roster file provided by the Staff team. Grapes creates teams according to the reporting lines in the roster file.\n\nYou can find the people who report directly to you under the “My reviews” tab in the “Reviews” page. You can find all the staff members in your reporting line inside the “All” tab in the “Reviews” page. \n\nIf you can't see someone who reports to you in the Reviews page, please let us know at ${mailSnippet} so we can fix this while the roster file gets updated.`,
        leader: true,
        logged: true,
        images: [usingGrapesImg1],
      },
      {
        title: 'I would like to **add a new employee** to my Grapes team',
        content: `New team members are added to Grapes automatically every week using the latest roster file provided by the staff team.\n\nIf you can't find new team members on Grapes, they might not have been added yet and you might need to wait a couple of days.\n\nIf you still cannot find someone who reports to you and it's been over a week since they joined, please send us an email to ${mailSnippet} confirming your **full name** and the **team** you belong to, and explain your issue including your **direct report's full name**. We will get back to you as soon as possible.`,
        leader: true,
        logged: true,
      },
      {
        title: 'How can I **delegate an assessment**?',
        content: `You may want another leader familiar with a person's work to complete an assessment for additional perspective, or to delegate specific assessments to senior people in your own teams who work more closely with some of your staff members.\n\nTo do this, first navigate to the “Reviews” page.\n\nIn the table you will see a column titled as “Reviewer”. If you want to add new reviewers you need to click on the “+” button on the left of the reviewers' names.\n\nPlease write the name of the person you want to delegate this review to in the text field, and click on “Add”.`,
        leader: true,
        logged: true,
      },
      {
        title: 'I have people on My Reviews that are **not part of my team**',
        content: `If your “My Reviews” page shows employees who are not under your hierarchy, please let us know at ${mailSnippet} so we can fix this while the roster file gets updated.`,
        leader: true,
        logged: true,
      },
      {
        title: 'How are the **Rockstar**, **Underperforming** and **Retention at Risk** icons assigned?',
        content: `This information is extracted from the **Final step** in the assessment form.\n\n-People assessed as having a “High” retention at risk will show a Retention at Risk icon.\n\n -People identified as “Mismatch” and “Top talent” in the talent rank question will show  “Underperforming” and “Rockstar” icons, respectively.`,
        leader: true,
        logged: true,
      },
      {
        title: "The **Technical Profile section is empty** in my team member's report.",
        content: `The information in the Technical Profile section needs to be provided by your team members. If you can't see any information, that means they haven't logged in to Grapes yet.\n\nPlease ask your staff members to log in to Grapes using their SSO credentials so they can share their Technical Profile information with you.`,
        leader: true,
        logged: true,
      },
      {
        title: "The **Github Activity section is empty** in my team member's report.",
        content: `The Github Activity section is completed with information from your team member's enterprise Github account. This integration isn't available to all teams yet.\n\nIf you can't see any information on this section, please get in touch with us so we can check if this feature is available for your team: ${mailSnippet}.`,
        leader: true,
        logged: true,
      },
    ],
  },
  {
    id: 'assessments',
    name: 'Conducting assessments',
    content: [
      {
        title: 'How can I **start an assessment**?',
        content: `You can start a new assessment by navigating to the **Reviews** page on the main navigation.\n\nUse the search box to find the person you want to create an assessment for, or find them in the table below. Click on their name or in the “Add review” button directly to start the assessment.`,
        logged: true,
        leader: true,
        images: [assessmentImg1],
      },
      {
        title: 'Which **assessment form** should I choose?',
        content: `You should select the form most appropriate to each person's role and job level. When in doubt, please double-check your selection with another leader in your hierarchy.\n\nIf none of the forms are relevant to your staff member's role, please contact us at ${mailSnippet} so we can review your situation together.`,
        logged: true,
        leader: true,
      },
      {
        title: "I finished all fields in the form but I still **can't click Publish.**",
        content: `Please make sure you have completed all fields in each of the sections, including confirming the score at the end of each **Category** section, selecting **Strengths & Weaknesses**, and the **Final Step**.\n\nYou can use the sidebar navigation to identify unfinished sections. Once a section is complete, you will see a green check icon and the word “Complete” below the finished category. If it's still missing information, you will see a yellow tag with the word “Incomplete” below the unfinished category.\n\nIf you are still having trouble, please get in touch with us at ${mailSnippet} and explain your situation. `,
        logged: true,
        leader: true,
        images: [assessmentImg2],
      },
      {
        title: 'What happens once I **hit the Publish button**?',
        content: `Once you publish a talent assessment, the information will show up on the staff member's individual report, and on the team's report.\n\nCurrently results can be viewed by the staff member's manager and the leaders in their hierarchy. Managers can also download a PDF of the report to share with their team members.`,
        logged: true,
        leader: true,
      },
      {
        title: 'Can I **edit** a published assessment?',
        content: `Yes, you can go back and edit published assessments. Just navigate to your team member's individual report (by clicking on your team member's name on any page), select the form you want to edit from the top dropdown. Then scroll down and click on “Edit assessment”. \n\n Please note you can only edit assessments you have published yourself.`,
        logged: true,
        leader: true,
        images: [assessmentImg3, assessmentImg4],
      },
      {
        title:
          "If I save an **assessment but don't publish it**, will this information be **visible to other leaders**?",
        content: `Currently this information isn't accessible by others. Only the information from assessments you have published will be **visible to other leaders** in your hierarchy.`,
        logged: true,
        leader: true,
      },
    ],
  },
]

export const getFaqContentList = (role = ''): FaqCategory[] => {
  const mocked: FaqCategory[] = []

  return mocked
}
