const CheckEditSVG = (): JSX.Element => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.68029 12.1758C5.09956 12.1758 5.42085 12.0094 5.64416 11.6768L11.6803 2.40039C11.7578 2.27734 11.8147 2.15885 11.8512 2.04492C11.8876 1.92643 11.9059 1.81478 11.9059 1.70996C11.9059 1.42741 11.8125 1.19499 11.6256 1.0127C11.4388 0.825846 11.1995 0.732422 10.9078 0.732422C10.7073 0.732422 10.5387 0.773438 10.402 0.855469C10.2653 0.932943 10.1354 1.06738 10.0123 1.25879L4.65295 9.73535L1.91857 6.31738C1.69982 6.0485 1.42639 5.91406 1.09826 5.91406C0.806594 5.91406 0.565058 6.00749 0.373652 6.19434C0.182245 6.38118 0.0865421 6.61589 0.0865421 6.89844C0.0865421 7.0306 0.109329 7.15592 0.154902 7.27441C0.200474 7.38835 0.277948 7.50911 0.387323 7.63672L3.72326 11.7109C3.98303 12.0208 4.30204 12.1758 4.68029 12.1758Z"
        fill="white"
      />
    </svg>
  )
}

export default CheckEditSVG
