type List = {
  headline: string
  items: string[]
}
type ListsProps = {
  lists: List[]
}

const EmployeeResultsLists = (props: ListsProps): JSX.Element => {
  const generateList = (list: List, index: number): JSX.Element => {
    return (
      <div className="list" key={index}>
        <b>{list.headline}</b>
        <ul className="list-outside w-[calc(100%-1rem)]">
          {list.items.map((item, index) => (
            <li key={`${item}${index}`} className="ml-[1.7rem] pl-3">
              {item}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div className="lists-container top-separator">{props.lists.map((list, index) => generateList(list, index))}</div>
  )
}

export default EmployeeResultsLists

export type { List }
