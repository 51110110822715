import { simpleTextCompareFn } from './text'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueFromLiteralPath = <T>(obj: any, path: string) => {
  const pathParts = path.split('.')
  let curr = obj
  for (let i = 0; i < pathParts.length; i++) {
    const pathPart = pathParts[i]
    if (curr[pathPart] === undefined) {
      console.warn(`Literal Path "${path}" not found in object`)
      return undefined
    }
    curr = curr[pathPart]
  }
  return curr as T
}
interface NamedObject {
  name: string
}

export const namedObjectsArrayCompareFn = (teams1: NamedObject[], teams2: NamedObject[]) => {
  const teamNames1: string = (teams1 || [])
    .map(({ name }) => name)
    .join('')
    .toLowerCase()
  const teamNames2: string = (teams2 || [])
    .map(({ name }) => name)
    .join('')
    .toLowerCase()
  return simpleTextCompareFn(teamNames1, teamNames2)
}
