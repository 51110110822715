import classNames from 'classnames'
import { useSelector } from 'react-redux'

import history from '../../utils/history'

import { useState } from 'react'
import { Link } from 'react-router-dom'
import { RootState, useAppDispatch } from '../../'
import LogoSVG from '../../assets/svgs/Logo.svg'
import { EmployeeDetails } from '../../services/EmployeeService'
import { TrackingCategoryEnum, TrackingCategoryEventEnum, trackEvent } from '../../services/EventTrackingService'
import { logout } from '../../slices/auth-slice'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { userCan } from '../../utils/security'
import { HamburgerIcon } from '../atoms/Icons/HamburgerIcon'
import { HeaderDropdown } from '../molecules/header/HeaderDropdown'
import { BackLink } from './BackLink'

export type HeaderProps = {
  iconURL: string
}

const menuItems = [
  {
    name: 'Dashboard',
    url: NetworkConstants.URL_DASHBOARD,
    role: ['admin'],
    permission: 'reviews-read',
  },
  {
    name: 'Selfie',
    url: NetworkConstants.URL_TECH_PROFILE,
    role: ['admin', 'reviewer'],
    permission: 'reviews-read',
  },
  {
    name: 'Assessments',
    url: NetworkConstants.URL_REVIEW_LIST,
    role: ['admin', 'reviewer'],
    permission: 'reviews-read',
  },
  {
    name: 'Teams',
    url: NetworkConstants.URL_TEAMS_DASHBOARD,
    role: ['admin'],
    permission: NetworkConstants.URL_TEAMS_DASHBOARD,
  },
  {
    name: 'Find resources',
    url: NetworkConstants.URL_FIND_RESOURCES,
    role: ['admin'],
    permission: NetworkConstants.URL_FIND_RESOURCES,
  },
]

const Header = ({ iconURL }: HeaderProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false)
  const user = useSelector<RootState, EmployeeDetails | null>(state => state.auth.user)
  const dispatch = useAppDispatch()
  const currentPath = history?.location?.pathname
  const hasDashboardLink = currentPath?.match(
    /\/(employee-report|employee-review|review|thanks|faq_logged|team-summary)(\/|$)/g
  )

  const buttonClassNames = (link: string) => {
    const isActive = currentPath?.match(link)

    return classNames({
      'hover:text-blue text-2xl font-semibold': true,
      'text-dark-blue': !isActive,
      'text-blue': isActive,
    })
  }

  const handleLogOut = async () => {
    await trackEvent(TrackingCategoryEnum.AUTHENTICATION, TrackingCategoryEventEnum.AUTHENTICATION.USER_LOGOUT, '')
    dispatch(logout())
  }

  const renderMenuItems = () => {
    return (
      <div className="ml-auto flex items-center lg:gap-16 xl:gap-20 2xl:gap-24 invisible lg:visible">
        {menuItems
          .filter(
            item =>
              userCan(user, item.permission) && item.role.includes(user?.role as string) && currentPath !== item.url
          )
          .map(item => (
            <Link to={item.url}>
              <div className={buttonClassNames(item.url)}>{item.name}</div>
            </Link>
          ))}
        {user?.role === 'admin' && (
          <HeaderDropdown
            links={[
              { url: NetworkConstants.URL_ADMIN_PANEL, name: 'Manage users' },
              { url: NetworkConstants.URL_PERIOD_MANAGEMENT, name: 'Manage periods' },
            ]}
          />
        )}
        <button className="text-dark-blue hover:text-blue text-2xl font-semibold" onClick={handleLogOut}>
          Log out
        </button>
      </div>
    )
  }

  const renderSmallMenuItems = () => {
    return (
      <div className="ml-auto lg:hidden">
        <div className="flex flex-col items-center">
          <button
            className="flex items-center justify-between gap-2 p-2 text-dark-blue hover:text-blue text-2xl font-semibold"
            onClick={() => setExpanded(!expanded)}
          >
            <HamburgerIcon />
          </button>
          {expanded && (
            <div className="absolute flex flex-col shadow-md top-28 bg-white z-10 mr-24">
              {menuItems
                .filter(
                  item =>
                    userCan(user, item.permission) &&
                    item.role.includes(user?.role as string) &&
                    currentPath !== item.url
                )
                .map(item => (
                  <Link
                    key={item.name}
                    to={item.url}
                    className={`${buttonClassNames(item.url)} hover:bg-just-gray px-5 py-3`}
                  >
                    <span>{item.name}</span>
                  </Link>
                ))}
              {user?.role === 'admin' && (
                <Link
                  to={NetworkConstants.URL_ADMIN_PANEL}
                  className={`${buttonClassNames(NetworkConstants.URL_ADMIN_PANEL)} hover:bg-just-gray px-5 py-3`}
                >
                  <span>Manage users</span>
                </Link>
              )}
              {user?.role === 'admin' && (
                <Link
                  to={NetworkConstants.URL_PERIOD_MANAGEMENT}
                  className={`${buttonClassNames(NetworkConstants.URL_PERIOD_MANAGEMENT)} hover:bg-just-gray px-5 py-3`}
                >
                  <span>Manage periods</span>
                </Link>
              )}
              <button
                className="text-dark-blue hover:text-blue text-2xl font-semibold text-left px-5 py-3"
                onClick={handleLogOut}
              >
                Log out
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <header className="container mx-auto flex flex-nowrap items-center py-14 leading-10" id="landing-header">
        <Link to={iconURL}>
          <div className="logo cursor-pointer">
            <h1 hidden>Grapes | Your assessment tool</h1>
            <LogoSVG />
          </div>
        </Link>
        {renderMenuItems()}
        {renderSmallMenuItems()}
      </header>
      <div className="container mx-auto flex mb-12">
        {hasDashboardLink && userCan(user, 'reviews-list') && <BackLink />}
      </div>
    </>
  )
}

export default Header
