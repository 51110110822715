import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../..'
import { Employee } from '../../api/openapi'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'
import MailIcon from '../atoms/Icons/MailIcon'
import { QuestionIcon } from '../atoms/Icons/QuestionIcon'
import OptionsModal from '../molecules/OptionsModal'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../services/EventTrackingService'
import { SuggestionsBox } from '../molecules/SuggestionsBox'
import { CONTACT_EMAIL } from '../../constants/general'

const Footer = (): JSX.Element => {
  const [showContactModal, setShowContactModal] = useState<boolean>(false)
  const currentUser = useSelector<RootState, Employee>(state => state.auth.user as Employee)
  const EMAIL = `mailto:${CONTACT_EMAIL}`

  const handleSendEmail = () => {
    setShowContactModal(false)
    window.open(EMAIL)
  }

  const handleClickSupport = () => {
    trackEvent(TrackingCategoryEnum.NAVIGATION, TrackingCategoryEventEnum.NAVIGATION.CLICK_SUPPORT_LINK)
    setShowContactModal(!showContactModal)
  }

  const getFaqLink = useMemo(
    () =>
      currentUser ? createPathWithLang(NetworkConstants.URL_FAQ_LOGGED) : createPathWithLang(NetworkConstants.URL_FAQ),
    [currentUser]
  )

  return (
    <footer className="flex flex-col items-center w-full mt-auto py-10">
      <div className="flex items-center justify-center w-full gap-4 mb-6">
        <Link to={getFaqLink}>
          <div className="flex items-center cursor-pointer">
            <QuestionIcon />
            <span className="ml-1 text-blue text-xl font-bold hover:underline">FAQs</span>
          </div>
        </Link>
        <span className="font-extrabold text-xl text-dark-blue">·</span>
        <div className="flex items-center cursor-pointer" onClick={handleClickSupport}>
          <MailIcon />
          <span className="ml-1 text-blue text-xl font-bold hover:underline">Contact</span>
        </div>
        <span className="font-extrabold text-xl text-dark-blue">·</span>
        <div className="flex items-center cursor-pointer">
          <SuggestionsBox />
        </div>
      </div>
      <div className="text-center text-xl">
        <p className="mb-4">
          Grapes is a spin-off project from <b>The Agile Monkeys</b>
        </p>
      </div>
      <div>
        {showContactModal && (
          <OptionsModal
            visible={showContactModal}
            message={
              <p className="text-[2.5rem]">
                If you have any questions or experience any issues with Grapes, please describe the issue to:{' '}
                <a href={EMAIL}>{CONTACT_EMAIL}</a> and we will solve it as soon as possible.
              </p>
            }
            firstButtonText="Close"
            firstButtonType="clear"
            secondButtonText="Send mail"
            secondButtonType="info"
            onFirstButtonClick={() => setShowContactModal(false)}
            onSecondButtonClick={() => handleSendEmail()}
            onClose={() => setShowContactModal(false)}
          />
        )}
      </div>
    </footer>
  )
}

export default Footer
