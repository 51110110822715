import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../..'
import { EmployeeDetails } from '../../../services/EmployeeService'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../../services/EventTrackingService'
import { TeamInfo, TeamService } from '../../../services/TeamService'
import { trimEllip } from '../../../utils/utils'
import PencilSVG from '../../atoms/Icons/Pencil.svg'
import EditTeamName from '../../molecules/EditTeamName/EditTeamName'

interface TeamSummaryInfoHeaderProps {
  teamInfo: TeamInfo
  onUpdateTeamInfo: (teamUpdate: TeamInfo) => void
}

const TeamSummaryInfoHeader: FC<TeamSummaryInfoHeaderProps> = ({ teamInfo, onUpdateTeamInfo }) => {
  const currentUser = useSelector<RootState, EmployeeDetails | null>(state => state.auth.user)
  const [isEditingTeamName, setIsEditingTeamName] = useState<boolean>(false)
  const leadersTitle = teamInfo && teamInfo.leaders?.length > 1 ? 'Leaders' : 'Leader'
  const TEAM_NAME_LENGTH = 20

  const handleEditTeamName = () => {
    trackEvent(TrackingCategoryEnum.TEAM_REPORT, TrackingCategoryEventEnum.TEAM_REPORT.CLICK_EDIT_TEAM_NAME)
    setIsEditingTeamName(!isEditingTeamName)
  }

  const handleSaveTeamNameChanged = (teamId: number, newTeamName: string) => {
    const newTeamInfo: TeamInfo = { ...teamInfo, name: newTeamName }
    onUpdateTeamInfo(newTeamInfo)
    TeamService.updateTeamName(teamId, newTeamName)
  }

  const checkLeader = (team: TeamInfo): boolean => {
    const isLeader = team.leaders.some(leader => leader.id === currentUser?.id)
    return isLeader
  }

  return (
    <div className="flex justify-start">
      <div className="mt-2 w-1/4">
        <h1 className="text-2xl text-toast-hover font-semibold">Team</h1>
        {isEditingTeamName ? (
          <div className="mt-4 ">
            <EditTeamName
              team={teamInfo}
              onIsEditTeamName={handleEditTeamName}
              onSaveTeamNameChanged={handleSaveTeamNameChanged}
            />
          </div>
        ) : (
          <div className="text-4xl font-semibold text-dark-blue mt-4 flex items-center gap-4">
            {trimEllip(teamInfo?.name, TEAM_NAME_LENGTH)}
            {checkLeader(teamInfo) && (
              <div className="cursor-pointer" onClick={handleEditTeamName}>
                <PencilSVG color="black" width={20} height={20} />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="mt-2 ml-20 w-3/4">
        <h1 className="text-2xl text-toast-hover font-semibold">{leadersTitle}</h1>
        <div className="text-3xl font-semibold text-dark-blue mt-4">
          {(teamInfo?.leaders || []).map(leader => leader.name).join(', ')}
        </div>
      </div>
    </div>
  )
}

export default TeamSummaryInfoHeader
