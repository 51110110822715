const mapToRange = (value: number, x1: number, y1: number, x2: number, y2: number): number =>
  ((value - x1) * (y2 - x2)) / (y1 - x1) + x2

export function cloneDeep<T>(array: T[]): T[] {
  return JSON.parse(JSON.stringify(array))
}

const generateColorInterval = (
  values: number[],
  min: number,
  max: number,
  r: number,
  g: number,
  b: number
): string[] => {
  return values.map(value => {
    const percentage = mapToRange(value, min, max, 0.3, 0.6)
    return `rgba(${r},${g},${b}, ${percentage})`
  })
}

enum DeviceResolutions {
  mobile,
  tablet,
  desktop,
}

const getCurrentDevice = (): DeviceResolutions => {
  const deviceWidth = document.documentElement.clientWidth

  if (deviceWidth <= 767) return DeviceResolutions.mobile
  if (deviceWidth <= 980) return DeviceResolutions.tablet
  return DeviceResolutions.desktop
}

const getRandomInt = (min: number, max: number): number => Math.floor(Math.random() * (max - min)) + min

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')

const AVAILABLE_COLORS = [
  '#005ECA',
  '#59DDFE',
  '#FFA600',
  '#CD59FE',
  '#6F7C12',
  '#FF7F6D',
  '#FECD59',
  '#BC5090',
  '#91BF7B',
  '#1481BA',
]

export const assignColor = (index: number): string => AVAILABLE_COLORS[index % AVAILABLE_COLORS.length]

const COLORS_TOPIC_TECH_PROFILE: { [key: string]: string } = {
  Backend: '#1481BA',
  Databases: '#CD59FE',
  DevOps: '#59DDFE',
  Frontend: '#FF7F6D',
  Mobile: '#FECD59',
  QA: '#91BF7B',
}

export {
  mapToRange,
  generateColorInterval,
  getRandomInt,
  alphabet,
  DeviceResolutions,
  getCurrentDevice,
  AVAILABLE_COLORS,
  COLORS_TOPIC_TECH_PROFILE,
}

export const trimEllip = (str: string, length: number) => {
  return str.length > length ? str.substring(0, length) + '...' : str
}
