import React, { useEffect, useState } from 'react'
import { QuestionTopic } from '../types/definitions/question'
import { useHistory } from 'react-router-dom'
import { useQuery } from './useQuery'

interface UseTopicsResult {
  currentTopicIndex: number
  currentTopic: QuestionTopic
  isFirstTopic: boolean
  isLastTopic: boolean
  hasElementFocus: boolean
  handleGoToNextTopic: () => void
  handleBackToPreviousTopic: () => void
  resetTopic: () => void
}

export const useTopics = (
  topics: QuestionTopic[] = [],
  initialTopicIndex: string | null,
  ref: React.RefObject<HTMLDivElement>
): UseTopicsResult => {
  const history = useHistory()
  const query = useQuery()
  const [currentTopic, setCurrentTopic] = useState<QuestionTopic>(topics[0])
  const [currentTopicIndex, setCurrentTopicIndex] = useState<number>(0)
  const [hasElementFocus, setHasElementFocus] = useState<boolean>(false)

  useEffect(() => {
    if (initialTopicIndex) {
      const topicPosition = parseInt(initialTopicIndex)
      if (topicPosition >= 0 && topicPosition < topics.length) {
        const newCurrentTopic = topics[topicPosition]
        setCurrentTopic(newCurrentTopic)
        setCurrentTopicIndex(topicPosition)
        return
      }
    }
    setCurrentTopic(topics[0])
    setCurrentTopicIndex(0)
  }, [topics, initialTopicIndex])

  useEffect(() => {
    const onFocusIn = () => {
      setHasElementFocus(true)
    }
    const onFocusOut = () => {
      setHasElementFocus(false)
    }

    const element = ref.current
    if (element === null) {
      return
    }

    element.addEventListener('focusin', onFocusIn)
    element.addEventListener('focusout', onFocusOut)

    return () => {
      element.removeEventListener('focusin', onFocusIn)
      element.removeEventListener('focusout', onFocusOut)
    }
  })

  const handleGoToNextTopic = () => {
    setCurrentTopic(topics[currentTopicIndex + 1])
    setCurrentTopicIndex(currentTopicIndex + 1)
    query.set('topic', `${currentTopicIndex + 1}`)
    history.replace({
      search: query.toString(),
    })
    window.scrollTo(0, 0)
  }

  const handleBackToPreviousTopic = () => {
    setCurrentTopic(topics[currentTopicIndex - 1])
    setCurrentTopicIndex(currentTopicIndex - 1)
    query.set('topic', `${currentTopicIndex - 1}`)
    history.replace({
      search: query.toString(),
    })
    window.scrollTo(0, 0)
  }

  const resetTopic = () => {
    query.delete('topic')
    history.replace({
      search: query.toString(),
    })
    setCurrentTopic(topics[0])
    setCurrentTopicIndex(0)
  }

  const isFirstTopic = currentTopicIndex === 0
  const isLastTopic = currentTopicIndex === topics.length - 1

  return {
    currentTopicIndex,
    currentTopic,
    handleGoToNextTopic,
    handleBackToPreviousTopic,
    resetTopic,
    isFirstTopic,
    isLastTopic,
    hasElementFocus,
  }
}
