import { FC } from 'react'
import { AppMarkdownViewer } from '../../../atoms/AppMarkdownViewer/AppMarkdownViewer'
import AppSliderText from '../../../atoms/AppSliderText/AppSliderText'

interface SliderTextQuestionProps {
  index: number
  title: string
  labels: string[]
  unfinished: boolean
  required?: boolean
  onChange?: (value: string | undefined) => void
}

const SliderTextQuestion: FC<SliderTextQuestionProps> = ({
  index,
  title,
  labels,
  unfinished,
  required = true,
  onChange,
}) => {
  const handleChange = (newValue: string | undefined): void => onChange?.(newValue)

  const colorIsUnfinished = unfinished ? 'bg-pale-yellow' : 'bg-pale-cyan'
  const stylesParentDiv = `w-[100%] flex flex-row gap-[1rem] m-h-[13rem] rounded-[0.8rem] pt-[1.6rem] pr-[2rem] pb-[1.6rem] pl-[2rem] ${colorIsUnfinished}`

  return (
    <div className={stylesParentDiv}>
      <span className="text-[1.6rem] leading-[2.2rem] text-dark-blue font-extrabold">{index}</span>
      <div className="flex flex-col gap-[2.6rem] w-[70%]">
        <div className="text-[1.6rem] leading-[2.2rem] gap-[1rem]">
          <AppMarkdownViewer markdown={title} />
          {!required && <p className="text-base font-semibold text-stone-gray ml-auto">Optional</p>}
        </div>
        <AppSliderText labels={labels} onChange={handleChange} />
      </div>
    </div>
  )
}

export default SliderTextQuestion
