type ParagraphProps = {
  headline: string
  body: string
  classNames?: string
}

const Paragraph = (props: ParagraphProps): JSX.Element => {
  return (
    <div className={'top-separator flex flex-col gap-[1rem]'}>
      <b className={props.classNames}>{props.headline}</b>
      <p className={`text-[1.5rem] leading-10 whitespace-pre-lin ${props.classNames}`}>{props.body}</p>
    </div>
  )
}

export default Paragraph
