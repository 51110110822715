export const PlusIcon = ({ width = 16, height = 16 } = {}): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 4.95703C0 5.16797 0.0742188 5.34766 0.222656 5.49609C0.371094 5.64062 0.548828 5.71289 0.755859 5.71289H4.20703V9.16406C4.20703 9.36719 4.2793 9.54297 4.42383 9.69141C4.56836 9.83984 4.74609 9.91406 4.95703 9.91406C5.16406 9.91406 5.3418 9.83984 5.49023 9.69141C5.63867 9.54297 5.71289 9.36719 5.71289 9.16406V5.71289H9.16406C9.36719 5.71289 9.54297 5.64062 9.69141 5.49609C9.83984 5.34766 9.91406 5.16797 9.91406 4.95703C9.91406 4.75 9.83984 4.57227 9.69141 4.42383C9.54297 4.27539 9.36719 4.20117 9.16406 4.20117H5.71289V0.755859C5.71289 0.548828 5.63867 0.371094 5.49023 0.222656C5.3418 0.0742188 5.16406 0 4.95703 0C4.74609 0 4.56836 0.0742188 4.42383 0.222656C4.2793 0.371094 4.20703 0.548828 4.20703 0.755859V4.20117H0.755859C0.548828 4.20117 0.371094 4.27539 0.222656 4.42383C0.0742188 4.57227 0 4.75 0 4.95703Z"
        fill="#005ECA"
      />
    </svg>
  )
}
