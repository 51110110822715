import {
  EMPLOYEE_STATUS_DONE,
  EMPLOYEE_STATUS_REQUESTED,
  EMPLOYEE_STATUS_SKIPPED,
  EMPLOYEE_STATUS_TODO,
  EMPLOYEE_STATUS_UNFINISHED,
} from '../constants/employee'
import { Employee } from '../services/EmployeeService'
import { TeamStatus } from '../services/TeamService'
import { getTeamStatusesFlags } from './teams/teamsUtils'

export function getHoverColorForEmployee(employee: Employee): string {
  switch (employee.status) {
    case EMPLOYEE_STATUS_TODO:
      return 'clickable-hover-effect--grey'
    case EMPLOYEE_STATUS_DONE:
    case EMPLOYEE_STATUS_REQUESTED:
      return 'clickable-hover-effect--blue'
    case EMPLOYEE_STATUS_UNFINISHED:
      return 'clickable-hover-effect--yellow'
    case EMPLOYEE_STATUS_SKIPPED:
      return 'clickable-hover-effect--green'
  }

  return '--grey'
}

export const hoverColorsObjectByEmployee = (employee: Employee) => {
  return {
    'clickable-hover-effect--grey': employee.status == EMPLOYEE_STATUS_TODO,
    'clickable-hover-effect--blue':
      employee.status == EMPLOYEE_STATUS_DONE || employee.status == EMPLOYEE_STATUS_REQUESTED,
    'clickable-hover-effect--yellow': employee.status == EMPLOYEE_STATUS_UNFINISHED,
    'clickable-hover-effect--green': employee.status == EMPLOYEE_STATUS_SKIPPED,
  }
}

export const getHoverEffectClassNames = (row: TeamStatus) => {
  const { hasError, hasAlert, hasReviews, finishedReviewsWithoutErrorsAndAlerts, missesReviewsWithoutErrorsAndAlerts } =
    getTeamStatusesFlags(row)

  return {
    'clickable-hover-effect': true,
    'clickable-hover-effect--red': hasError,
    'clickable-hover-effect--orange': hasAlert,
    'clickable-hover-effect--green': finishedReviewsWithoutErrorsAndAlerts,
    'clickable-hover-effect--soft-grey': missesReviewsWithoutErrorsAndAlerts || !hasReviews,
  }
}
