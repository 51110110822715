import { useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import useMeasure from 'react-use-measure'
import { AppMarkdownViewer } from '../../atoms/AppMarkdownViewer/AppMarkdownViewer'
import { v4 } from 'uuid'
import { classNames } from 'primereact/utils'

interface FaqCategoryContentProps {
  title: string
  content: string
  images?: string[]
}
export const FaqCategoryContent = ({ title, content, images }: FaqCategoryContentProps) => {
  const [expanded, setExpanded] = useState(false)
  const buttonStyle = expanded ? 'text-white bg-blue' : 'text-blue bg-white'

  // Arrow animation
  const arrowAnimationStyle = useSpring({
    transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
  })
  // Div animation
  const [ref, bounds] = useMeasure()
  const divAnimationStyle = useSpring({
    height: expanded ? bounds.height : 0,
    overflow: expanded ? 'clip' : 'hidden',
  })
  const containerClasses = classNames({
    'p-12 rounded-2xl w-full max-w-[896px]': true,
    'shadow-[5px_5px_16px_0_rgba(0,94,202,0.15)] outline outline-2 outline-blue': expanded,
    'shadow-[0px_5px_16px_0_rgba(8,15,52,0.06)]': !expanded,
  })

  return (
    <div className={containerClasses}>
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <h2 className="!m-0 text-3xl text-dark-blue whitespace-pre-line">
            <AppMarkdownViewer markdown={title} />
          </h2>
          <button className={`shadow-md p-8 rounded-full ${buttonStyle}`} onClick={() => setExpanded(!expanded)}>
            <animated.span style={arrowAnimationStyle} className={`pi pi-chevron-right font-bold`}></animated.span>
          </button>
        </div>
        <animated.div style={divAnimationStyle} key={v4()}>
          <div
            ref={ref}
            className="mt-8 text-[1.6rem] font-medium break-word leading-10 whitespace-pre-wrap w-10/12 max-w-[720px] pb-12"
          >
            <AppMarkdownViewer markdown={content} className="flex flex-col gap-4" />
            {images && images.map((image, i) => <img src={image} className="my-4 mb-8" alt="Faq example" key={i} />)}
          </div>
        </animated.div>
      </div>
    </div>
  )
}
