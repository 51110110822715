export class NetworkConstants {
  public static URL_HOME_PATH = '/'
  public static URL_ADMIN_PANEL = '/admin'
  public static URL_CREATE_ACCOUNT = '/admin/accounts/new'
  public static URL_REVIEW_LIST = '/review-list'
  public static URL_LOGIN = '/login'
  public static URL_LOGIN_TOKEN = '/login/:reviewId/:token'
  public static URL_NEW_REVIEW = '/employee-review/:employeeId'
  public static URL_EDIT_REVIEW = '/review/:reviewId'
  public static URL_REPORT = '/employee-report/:employeeId'
  public static URL_REPORT_FORM = '/employee-report/:employeeId/:formId'
  public static URL_NOT_FOUND = '/not-found'
  public static URL_CHARTS = '/charts'
  public static URL_CHANGE_PASSWORD = '/change-password'
  public static URL_TEAM_SUMMARY = '/team-summary/:teamId'
  public static URL_REVIEW_FINISHED_MSG = '/thanks'
  public static URL_UNAUTHORIZED = '/unauthorized'
  public static URL_INTRODUCTION = '/introduction'
  public static URL_TEAMS_DASHBOARD = '/teams-dashboard'
  public static URL_TECH_EVALUATION = '/tech-evaluation'
  public static URL_TECH_PROFILE = '/tech-profile'
  public static URL_FIND_RESOURCES = '/find-resources'
  public static URL_SSO_PROCESS = '/sso-process'
  public static URL_USAGE_REPORT = '/usage-report'
  public static URL_DASHBOARD = '/dashboard'
  public static URL_PERIOD_MANAGEMENT = '/period-management'
  public static URL_FAQ = '/faq'
  public static URL_FAQ_LOGGED = '/faq_logged'
}
