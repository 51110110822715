import { FC } from 'react'
import { Form } from '../../../types/definitions/form'
import { Review } from '../../../types/definitions/review'
import { formatDate } from '../../../utils/dateUtils'
import { TechProfileBody } from '../../molecules/TechProfile/TechProfileBody'
import TechProfileCardButtons from '../../molecules/TechProfile/TechProfileCardButtons'

interface TechProfileCardProps {
  reviewTechProfile: Review
  selfForm: Form
  onUpdateReview?: () => void
  onPublishReview?: () => Promise<void>
}

const TechProfileCard: FC<TechProfileCardProps> = ({
  reviewTechProfile,
  selfForm,
  onUpdateReview,
  onPublishReview,
}) => {
  return (
    <div className="p-20 shadow-default rounded-2xl mb-12">
      <div className="flex gap-2 text-4xl font-bold">
        <span className=" text-dark-blue">🛠️ Technical profile</span>
        {!reviewTechProfile.finishDate && <span className="text-error-red">DRAFT</span>}
      </div>
      <span className="text-xl font-medium text-stone-gray block mt-4">
        {formatDate(reviewTechProfile?.lastModifiedDate)}
      </span>
      <div className="employee-results pb-0">
        <div className="employee-results__content">
          <TechProfileBody review={reviewTechProfile} form={selfForm || {}} />
          <TechProfileCardButtons
            reviewTechProfile={reviewTechProfile}
            onUpdateReview={onUpdateReview}
            onPublishReview={onPublishReview}
          />
        </div>
      </div>
    </div>
  )
}

export default TechProfileCard
