//================================
// Index (Search using CTRL + F)
//
// 0. Interfaces
// 1. Helpers
// 2. Rendering
//================================

import { Tooltip } from '@material-ui/core'
import moment from 'moment'
import { useMemo } from 'react'
import { Form } from '../../../types/definitions/form'
import { Period } from '../../../types/definitions/Period'
import { AnsweredQuestion } from '../../../types/definitions/question'
import { Review } from '../../../types/definitions/review'
import { formatDate } from '../../../utils/dateUtils'
import { assignColor } from '../../../utils/utils'
import { ChartDatasetType } from '../../atoms/Charts/ChartRadar'
import ToggleWithText from '../../atoms/ToggleWithText/ToggleWithText'
import { sortReviewsByCreationDate } from '../../pages/EmployeeResults'
import EmployeeResultsHistory from './EmployeeResultsHistory'

//================================
// 0. Interfaces
//================================

type ChartHeaderHeaderProps = {
  reviews: ChartDatasetType[]
  currentReview: Review
  reviewToCompare?: Review
  selectableReviews: Review[]
  allForms: Form[]
  allPeriods: Period[]
  onChange: (selectedReviewDate: string) => void
  onComparableChange?: (review: Review) => void
}

//================================
// 1. Helpers
//================================

const formatToggleText = (
  dateString: string,
  reviewerName: string,
  formName = '',
  mismatch: boolean,
  periodAverage?: boolean
): Record<string, string> => {
  const mismatchIcon = mismatch ? '📍' : ''
  const formattedDate = formatDate(dateString || '', 'MM/DD/YYYY')

  const result = {
    text: periodAverage ? reviewerName.toUpperCase() : `${formattedDate} ${reviewerName.toUpperCase()} ${mismatchIcon}`,
    form: formName,
  }
  return result
}

//================================
// 2. Rendering
//================================

const EmployeeResultsChartHeader = ({
  reviews,
  currentReview,
  reviewToCompare,
  selectableReviews,
  allForms,
  allPeriods,
  onChange,
  onComparableChange,
}: ChartHeaderHeaderProps): JSX.Element => {
  const filteredReviews = useMemo(
    () =>
      reviews.map((review, i) => ({
        ...review,
        color: assignColor(i),
        mismatch: review.formName !== reviews[0].formName,
      })),
    [reviews]
  )

  const handleOnComparableChange = (comparableReview: Review): void => {
    onComparableChange?.(comparableReview)
  }

  // Calculates the average answer for a set of questions
  const getAverageAnswer = (questions: AnsweredQuestion[]): number => {
    const total = questions.reduce((acc, q) => acc + Number(q.answer), 0)
    return Math.round(total / questions.length)
  }

  // Filters the reviews to only include those within a specific period
  const filterReviewsByPeriod = (reviews: Review[], startDate: Date, endDate: Date): Review[] => {
    return reviews.filter(review => {
      const reviewDate = moment(review.createdDate)
      return reviewDate.isBetween(startDate, endDate, 'day', '[]')
    })
  }

  // Calculates the average review for a given period
  const calculatePeriodAverageReview = (reviewsInPeriod: Review[], period: Period): Review => {
    const periodEndDate = period.endDate
    const questionTitles = [...new Set(reviewsInPeriod.flatMap(r => r.answers).map(q => q.title))]

    const averageAnswers = questionTitles.map(title => {
      const questionsWithTitle = reviewsInPeriod.flatMap(r => r.answers).filter(q => q.title === title)
      const { ...rest } = questionsWithTitle[0]
      return { ...rest, title, answer: getAverageAnswer(questionsWithTitle) }
    })

    const reviewId = reviewsInPeriod.reduce((concatenatedId, review) => concatenatedId + review.id, '')

    return {
      id: parseInt(reviewId),
      createdDate: periodEndDate.toISOString(),
      lastModifiedDate: periodEndDate.toISOString(),
      finishDate: periodEndDate.toISOString(),
      reviewAuthor: { id: Date.now(), name: `🏁 ${period.name} ${periodEndDate.getFullYear()}` },
      reviewedEmployee: reviewsInPeriod[0].reviewedEmployee,
      answers: averageAnswers as AnsweredQuestion[],
      form: reviewsInPeriod[0].form,
      periodAverage: true,
    }
  }

  // Generates an array of average reviews for each period
  const generateAverageReviews = (reviews: Review[], periods: Period[]): Review[] => {
    const reviewsPerPeriod: Record<string, Review[]> = {}
    periods.forEach(period => {
      const reviewsInPeriod = filterReviewsByPeriod(reviews, period.startDate, period.endDate)
      if (reviewsInPeriod.length) reviewsPerPeriod[period.name] = reviewsInPeriod
    })

    const periodAverageAsReview = Object.keys(reviewsPerPeriod).map(period => {
      const reviewsInPeriod = reviewsPerPeriod[period]
      return calculatePeriodAverageReview(reviewsInPeriod, periods.find(p => p.name === period || '') as Period)
    })

    return [...reviews, ...periodAverageAsReview].sort(sortReviewsByCreationDate).reverse()
  }

  return (
    <div className="mb-20 print:!m-0 print:!mb-3 print:w-full print:!max-w-none">
      <div className="employee-results-history">
        <p>
          <b>Compare assessments</b>
        </p>
      </div>
      {selectableReviews.length >= 2 && (
        <EmployeeResultsHistory
          reviews={generateAverageReviews(selectableReviews, allPeriods)}
          currentReview={currentReview}
          reviewToCompare={reviewToCompare}
          onComparableChange={v => handleOnComparableChange(v)}
          allForms={allForms}
        />
      )}
      <div className="grid grid-cols-2 gap-x-10 gap-y-6 mt-4 print:gap-3">
        {filteredReviews.map((review, index) => (
          <>
            {review.mismatch ? (
              <Tooltip
                title="Different assessment type"
                arrow
                classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
                placement="top"
              >
                <div className="flex items-center gap-3" key={review.reviewDate}>
                  <ToggleWithText
                    color={review.color}
                    checked={review.displayed}
                    text={formatToggleText(
                      review.reviewDate,
                      review.reviewerName,
                      review.formName,
                      review.mismatch,
                      review.periodAverage
                    )}
                    onChange={() => onChange(review.reviewDate)}
                    periodAverage={review.periodAverage}
                  />
                </div>
              </Tooltip>
            ) : (
              <div className="flex items-center gap-3" key={review.reviewDate}>
                <ToggleWithText
                  color={review.color}
                  checked={review.displayed}
                  text={formatToggleText(
                    review.reviewDate,
                    review.reviewerName,
                    review.formName,
                    review.mismatch,
                    review.periodAverage
                  )}
                  onChange={() => onChange(review.reviewDate)}
                  disabled={selectableReviews.length === 1}
                  periodAverage={review.periodAverage}
                />
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  )
}

export default EmployeeResultsChartHeader
