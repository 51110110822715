import { FC } from 'react'

export interface CheckIconProps {
  filled?: boolean
  color?: string
  filledColor?: string
  width?: number
  height?: number
}

export const CheckIcon: FC<CheckIconProps> = ({ color = '#82E87A', width = 60, height = 60, filledColor }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="29" stroke={color} strokeWidth="2" fill={filledColor} />
      <path
        d="M19 31.5556L28.5617 40L40.0357 21"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
