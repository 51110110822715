export interface TalentRankData {
  title: string
  description: string
  details?: Array<string>
  additionalInfo?: string
}
export const talentRankData: TalentRankData[] = [
  {
    title: 'Top Talent',
    description:
      'This is our highest potential talent. Tested leadership talent with the capability and aspiration for advancement into leadership roles with significantly greater breadth, complexity and responsibility.',
    details: [
      'Restless for new challenges and takes risks.',
      'Performs well when placed in new and different situations.',
      'Stays ahead of business changes and recommends improvements and/or strategies.',
      'Networks across the organization to get things done.',
      'Thrives in challenging situations.',
      'Consistently outperform their peers.',
    ],
  },
  {
    title: 'Growth Talent',
    description:
      'Up and coming leadership talent whose capability is being tested through new experiences and broader scope.',
    details: [
      'Takes on new challenges with ease and gets up to speed quickly.',
      'Stays ahead of business changes and asks questions to seek deeper understanding.',
      'Networks within their business to get things done.',
      'Learns from and builds confidence in challenging situations.',
      'Versatility to move to different businesses, functions, teams.',
    ],
  },
  {
    title: 'Expert Talent',
    description: 'Highly competent, functional/technical expert who is a role model across the business/enterprise.',
    details: [
      'Advances/leads within their specialty or area of expertise.',
      'Thought leader who is always learning in their field.',
      'Proactively finds ways to drive innovation and showcase expertise (internal and/or external).',
      'Leverages strengths to coach, educate and develop others.',
      'Able to influence through expertise and knowledge.',
    ],
    additionalInfo:
      'Expert Talent may be more likely to equate career growth with deepening expertise versus expanding organizational leadership responsibilities.',
  },
  {
    title: 'Core Talent',
    description:
      'Steady contributor who is competent in current role and has a positive impact on business operations and outcomes.',
    details: [
      'Improves skills to keep pace with business change.',
      'Continuously develops skills through on-the-job experiences and working with others.',
      'Can experience lateral or moderate upward career growth.',
    ],
    additionalInfo:
      'Core Talent can be high performing talent. They are well placed. They do not have the aspiration and/or potential to lead in larger, more complex roles.',
  },
  {
    title: 'Mismatched Talent',
    description: "The role and employee's skills, fit and/or interests are not aligned.",
    details: [
      'Temporary placement.',
      'Goal of identifying a plan and re-evaluating the talent category when a change occurs.',
    ],
    additionalInfo:
      "Mismatched Talent can be meeting expectations and not underperforming (e.g., their interests aren't aligned with the role).",
  },
]
