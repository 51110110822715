import { Form } from '../../types/definitions/form'
import { FormCategory } from '../../types/definitions/formCategory'

export const getFormById = (id: number, formsList: Form[]): Form | undefined => {
  return formsList?.find(form => {
    return form.id === id
  })
}

// map forms to a more component-friendly object
export const mapFormsToFormSelectOptions = (forms: Form[]): { value: string; label: string }[] => {
  return forms.map<{ value: string; label: string }>(form => ({
    value: form.id?.toString() || '',
    label: form.name || '',
  }))
}

export const sortFormCategories = (formCategories: FormCategory[]): FormCategory[] => {
  return formCategories.sort((c1, c2) => (c1.order || -1) - (c2.order || -1))
}

export const sortFormsBySortKey = (forms: Form[]): Form[] => {
  return forms.sort((f1, f2) => {
    const key1 = f1.sortKey ?? 0
    const key2 = f2.sortKey ?? 0
    return key1 - key2
  })
}
