import { useEffect, useState } from 'react'
import { AppButton } from '../atoms/AppButton/AppButton'
import { Form } from '../../types/definitions/form'
import { CheckBox } from '../atoms/CheckBox'
import { AppTextarea } from '../atoms/AppTextarea/AppTextarea'
import { ReviewService } from '../../services/ReviewService'
import { useHistory } from 'react-router-dom'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'
import RadioButton from '../atoms/RadioButton/RadioButton'
import RadioCheckButton from '../atoms/RadioButton/RadioCheckButton'
import { FormCategory } from '../../types/definitions/formCategory'
import classNames from 'classnames'
import HelpTooltip from '../molecules/HelpTooltip'
import { sortFormCategoriesForms } from '../../utils/sortUtils'

export type EmployeeReviewFormSelectionStepProps = {
  employeeId: string
  formCategories: FormCategory[]
  lastFormUsed: Form | undefined
  onConfirm?: (formId: number) => void
  embedded?: boolean
  alreadySelectedForm?: number
  disableSkipAssessment?: boolean
  onFormCheck?: (formId: number) => void
  showTitle?: boolean
}

const skipOptions = [
  'This person is a newcomer.',
  'This person is leaving the company soon.',
  'This person is no longer on my team.',
  'Other (please explain below).',
].map(option => {
  return { label: option, value: option }
})

const EmployeeReviewFormSelectionStep = ({
  employeeId,
  formCategories,
  lastFormUsed,
  onConfirm,
  embedded = false,
  disableSkipAssessment = false,
  alreadySelectedForm,
  onFormCheck,
  showTitle = true,
}: EmployeeReviewFormSelectionStepProps): React.ReactElement => {
  const [selectedForm, setSelectedForm] = useState<number | undefined>(alreadySelectedForm ?? undefined)
  const [assessmentSkipped, setAssessmentSkipped] = useState<boolean>(false)
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<string[]>([])
  const [notes, setNotes] = useState<string>('')
  const history = useHistory()

  const handleConfirm = (): void => {
    if (selectedForm && onConfirm) onConfirm(selectedForm)
  }

  const handleSkipCheck = (value: unknown): void => {
    const localCurrentSelectedOptions = currentSelectedOptions
    const newSelectedOptions = localCurrentSelectedOptions.includes(value as string)
      ? localCurrentSelectedOptions.filter(option => option !== value)
      : [...localCurrentSelectedOptions, value as string]
    setCurrentSelectedOptions(newSelectedOptions.filter(o => o.length))
  }

  const handleSkipAssessment = async (): Promise<void> => {
    const reason = currentSelectedOptions.join(' ')
    await ReviewService.skipAssessment(employeeId, reason, notes)
    history.push(createPathWithLang(NetworkConstants.URL_REVIEW_LIST))
  }

  const handleSkipAssessmentRadioCheck = () => {
    setAssessmentSkipped(!assessmentSkipped)
    !assessmentSkipped ? setSelectedForm(undefined) : setSelectedForm(lastFormUsed?.id)
  }

  const handleCheck = (v: string) => {
    setSelectedForm(Number(v))
    onFormCheck && onFormCheck(Number(v))
  }

  useEffect(() => {
    lastFormUsed?.id && setSelectedForm(lastFormUsed.id)
  }, [lastFormUsed?.id])

  // when there is an odd number of categories, we add a
  // dummy div to align things properly
  const oddFormCategories = formCategories.length % 2 !== 0

  return (
    <div className="flex flex-col items-start gap-10">
      {showTitle && (
        <div
          className={classNames('font-semibold text-2xl leading-0', {
            'ml-0 mr-auto ': !embedded,
            'self-center': embedded,
          })}
        >
          {!embedded && <p className="text-[2.4rem] font-normal">Select the form type</p>}
          {lastFormUsed && (
            <p className="text-2xl font-normal text-stone-gray mt-5 clock-icon-preceded">
              Last form used: {lastFormUsed?.name}
            </p>
          )}
        </div>
      )}
      <div className="w-full flex flex-row text-3xl gap-16 xl:gap-x-0 flex-wrap lg:flex-nowrap mb-10 mt-10">
        <div
          className={classNames('overflow-y-auto w-full', {
            'w-full lg:w-2/3 xl:w-2/3 2xl:w-2/3': !disableSkipAssessment,
            'w-full': disableSkipAssessment,
          })}
        >
          <HelpTooltip id="review-new-layout-tutorial-step-1">
            <div
              className={classNames('flex flex-row flex-wrap w-full', {
                'xl:gap-x-0': !disableSkipAssessment,
                'justify-center text-2xl gap-x-12 gap-y-8 overflow-y-auto max-h-[400px]': embedded,
                'text-3xl gap-x-32 gap-y-16 max-h-[650px]': !embedded,
              })}
            >
              {sortFormCategoriesForms(formCategories).map((formCategory: FormCategory) => {
                return (
                  <div key={formCategory.id} className="flex flex-col w-[260px] xl:w-1/2">
                    <p className="font-semibold mb-5">{formCategory.name}</p>
                    {formCategory.forms?.map((form: Form) => {
                      return (
                        <div className="text-2xl">
                          <RadioButton
                            key={form.id}
                            label={form.name}
                            value={String(form.id)}
                            formValue={String(selectedForm || '')}
                            onChecked={handleCheck}
                            radius={6}
                            disabled={assessmentSkipped}
                          />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
              {oddFormCategories && embedded && <div className="w-[260px] h-0"></div>}
            </div>
          </HelpTooltip>
        </div>

        {!disableSkipAssessment && (
          <div className="w-full lg:w-2/3 xl:w-1/3 2xl:w-1/3 ">
            <HelpTooltip id="review-new-layout-tutorial-step-2" placement="top-end">
              <div className="flex flex-col gap-6 items-start w-full">
                <div className="w-full flex items-center justify-start bottom-[101px]">
                  <div className="w-[160px] h-[1px] bg-toast-hover "></div>
                  <div className="text-2xl text-toast-hover ml-3 mr-3">or</div>
                  <div className="w-[160px] h-[1px] bg-toast-hover "></div>
                </div>
                <div className="flex flex-row items-center">
                  <RadioCheckButton
                    label="Skip Assessment"
                    labelStyles="font-semibold"
                    checked={assessmentSkipped}
                    onClick={handleSkipAssessmentRadioCheck}
                    radius={6}
                  />
                </div>
                {assessmentSkipped && (
                  <div className="ml-8 flex flex-col items-start gap-6">
                    <div className="text-stone-gray font-bold text-2xl">Please specify why:</div>
                    <div className="flex flex-col select-none gap-1 leading-10 text-2xl">
                      {skipOptions.map(({ label, value }, i) => (
                        <CheckBox
                          key={`skip-option-${i}`}
                          label={label}
                          checked={currentSelectedOptions.includes(value)}
                          value={value}
                          onChecked={handleSkipCheck}
                          checkedStyle={true}
                        />
                      ))}
                      {currentSelectedOptions.includes('Other (please explain below).') && (
                        <>
                          <p className="text-base font-normal text-stone-gray ml-auto">Optional</p>
                          <AppTextarea placeholder="Add a note." value={notes} onChange={v => setNotes(v)} />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </HelpTooltip>
          </div>
        )}
      </div>
      {!assessmentSkipped && !embedded && (
        <AppButton onClick={handleConfirm} type="info" disabled={!Boolean(selectedForm)}>
          Confirm
        </AppButton>
      )}
      {assessmentSkipped && (
        <AppButton onClick={handleSkipAssessment} type="info" disabled={!Boolean(currentSelectedOptions.length)}>
          Skip
        </AppButton>
      )}
      {!embedded && <p className="text-2xl">The assessment questions are adapted according to the role selected.</p>}
    </div>
  )
}

export default EmployeeReviewFormSelectionStep
