import { infoTechnologiesLevels } from '../../utils/moreInformationTechLevelsUtils'

const TechnologiesLevelInfo = () => {
  return (
    <div className="px-[15%]">
      <h1 className="text-4xl mb-8">{infoTechnologiesLevels.title}</h1>
      {infoTechnologiesLevels.levels.map(level => {
        return (
          <div className="flex flex-col py-5 gap-2 text-2xl">
            <div className="flex items-center gap-2">
              <div className={`w-5 h-5 bg-${level.color} rounded-full`}></div>
              <span className="font-bold leading-10">{level.level}</span>
            </div>
            <p className="leading-8">{level.text}</p>
          </div>
        )
      })}
    </div>
  )
}

export default TechnologiesLevelInfo
