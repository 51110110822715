import { Tooltip } from '@material-ui/core'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import { Employee } from '../../../services/EmployeeService'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../../services/EventTrackingService'
import { trimEllip } from '../../../utils/utils'
import { createPathWithLang } from '../../../utils/languagePathUtils'
import { NetworkConstants } from '../../../utils/NetworkConstants'

type EmployeeResultsTeamsProps = {
  employee?: Employee
  disabled?: boolean
}

const TEAM_NAME_LENGTH = 25

const EmployeeResultsTeams = ({ disabled, employee }: EmployeeResultsTeamsProps): ReactElement => {
  const calculatePathToTeamSummary = (idx: number): string => {
    if (employee?.teams) {
      return createPathWithLang(
        NetworkConstants.URL_TEAM_SUMMARY.replace(':teamId', employee?.teams[idx].id.toString())
      )
    }
    return ''
  }

  const handleClickOnTeam = () => {
    trackEvent(TrackingCategoryEnum.INDIVIUAL_REPORT, TrackingCategoryEventEnum.INDIVIUAL_REPORT.CLICK_TEAM_LINK, '')
  }

  return (
    <div>
      <div className="employee-results-header print:!flex">
        <div>
          <b className="cell">Teams</b>
          {disabled ? (
            <p>{employee?.teams?.map(({ name }) => name).join(' \xa0•\xa0 ')}</p>
          ) : (
            <div className="flex mt-2 gap-1">
              {employee?.teams?.map(({ name }, idx) => (
                <span>
                  <Link to={calculatePathToTeamSummary(idx)} onClick={handleClickOnTeam}>
                    <Tooltip
                      title="Click here to navigate to the team's report."
                      arrow
                      enterDelay={500}
                      enterNextDelay={500}
                      classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
                    >
                      <div className="inline-block clickable-hover-effect clickable-hover-effect--soft-grey">
                        <span className="font-bold text-blue">{name}</span>
                      </div>
                    </Tooltip>
                  </Link>
                  {idx + 1 !== employee?.teams?.length ? ' \xa0•\xa0 ' : ''}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EmployeeResultsTeams
