interface MapScoreItemProps {
  year?: number
  score?: number
  description?: string
}

export const MapScoreItem = ({ year, score, description }: MapScoreItemProps) => {
  return (
    <tr className="border-t border-just-gray border-solid">
      <th scope="row" className="pr-6 py-11 font-bold text-3xl">
        {year}
      </th>
      <div className="rounded-full h-20 w-20 flex items-center justify-center bg-yellow-cell text-pale-orange text-4xl font-bold">
        {score}
      </div>
      <td className="pr-6 py-11 text-3xl">{description}</td>
    </tr>
  )
}
