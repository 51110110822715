import { createSlice } from '@reduxjs/toolkit'
import { TutorialsStorageConfiguration } from '../utils/HelpContent'

const SliceConfiguration = {
  reducerTag: 'reportTutorial',
}

export interface ReportTutorialState {
  current?: number
  completed: boolean
}

const initialState: ReportTutorialState = {
  completed: localStorage.getItem(TutorialsStorageConfiguration.REPORT_TUTORIAL_COMPLETED) === 'true',
}

export const reportTutorialSlice = createSlice({
  name: SliceConfiguration.reducerTag,
  initialState: initialState,
  reducers: {
    showReportTutorial: state => {
      return {
        ...state,
        current: 0,
      }
    },
    showReportTutorialIndex: (state, { payload }) => {
      return {
        ...state,
        current: payload,
      }
    },
    hideReportTutorial: state => {
      return {
        ...state,
        current: undefined,
      }
    },
    finishReportTutorial: state => {
      localStorage.setItem(TutorialsStorageConfiguration.REPORT_TUTORIAL_COMPLETED, 'true')
      return {
        ...state,
        completed: true,
      }
    },
  },
})

export const { hideReportTutorial, showReportTutorial, showReportTutorialIndex, finishReportTutorial } =
  reportTutorialSlice.actions
