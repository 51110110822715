import { FC, useEffect, useState } from 'react'
import { AppMarkdownViewer } from '../../../atoms/AppMarkdownViewer/AppMarkdownViewer'
import HelpTooltip from '../../HelpTooltip'
import { useSelector } from 'react-redux'
import { RootState } from '../../../..'
import { Employee } from '../../../../api/openapi'
import { RadioButton } from 'primereact/radiobutton'
import { classNames } from 'primereact/utils'

interface SelectQuestionProps {
  index: number
  max: number
  min: number
  value?: number
  title: string
  description?: string
  minLabel: string
  maxLabel: string
  unfinished?: boolean
  required?: boolean
  skippable?: string
  skipped?: boolean
  tutorialButton?: boolean
  onChange?: (value?: number, skip?: boolean) => void
}

export const SelectQuestion: FC<SelectQuestionProps> = ({
  index,
  max,
  min,
  title,
  description,
  minLabel,
  maxLabel,
  value = undefined,
  unfinished = false,
  required = true,
  skippable,
  skipped = false,
  tutorialButton = false,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<number | undefined>(value)
  const [questionSkipped, setQuestionSkipped] = useState(skipped)

  const currentUser = useSelector<RootState, Employee>(state => state.auth.user as Employee)
  const isUser = currentUser.role === 'user'

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  useEffect(() => {
    setQuestionSkipped(skipped)
  }, [skipped])

  const handleValueSelected = (selectedValue: number): void => {
    onChange?.(selectedValue, false)
    setSelectedValue(selectedValue)
    setQuestionSkipped(false)
  }

  const handleSkipQuestion = (): void => {
    const noValue = !questionSkipped ? min - 1 : undefined
    onChange?.(noValue, true)
    setQuestionSkipped(true)
  }

  const renderRange = () => {
    const range = max - min + 1
    return Array.from({ length: range }, (_, i) => {
      const value = min + i
      const isMin = value === min
      const isMax = value === max
      return (
        <div key={value} className="flex items-center">
          {isMin && <span className="text-xl font-semibold">{minLabel}</span>}
          <div className="flex flex-col w-4 gap-y-2 mx-8 items-center">
            <RadioButton value={value} onChange={() => handleValueSelected(value)} checked={selectedValue === value} />
          </div>
          {isMax && <span className="text-xl font-semibold mr-6">{maxLabel}</span>}
        </div>
      )
    })
  }

  const questionBody = (
    <div
      className={classNames('w-full flex min-h-[13rem] rounded-[0.8rem] bg-pale-cyan py-[1.6rem] px-[2.2rem] gap-4', {
        'bg-pale-yellow': required && !!skippable && !skipped && !value && unfinished,
      })}
    >
      <span className="text-dark-blue font-extrabold text-[1.6rem] leading-[2.2rem]">{index}</span>
      <div className="flex flex-col gap-3.5 w-full">
        <div className="flex flex-col gap-2 w-full">
          <div className="flex gap-4 leading-[2.2rem] font-normal text-[1.6rem]">
            <AppMarkdownViewer markdown={title} />
            {required && <p className="text-[10px] font-semibold text-stone-gray">Optional</p>}
          </div>
          {description && (
            <>
              <AppMarkdownViewer
                className="text-stone-gray font-normal text-[1.6rem] leading-[2.2rem]"
                markdown={description}
              />
            </>
          )}
        </div>
        <div className="w-fit flex flex-row gap-8">
          <div className="inline">
            <div className="flex h-[50px] my-8 align-middle">
              {renderRange()}
              {skippable && (
                <button
                  className={classNames(
                    'self-center bg-white border-solid border-[0.5px] border-toast-hover box-border rounded-[8px] h-8 w-[5.5rem] cursor-pointer text-xl duration-[200ms] unfinished:bg-pale-yellow hover:border-blue hover:text-white hover:bg-blue',
                    {
                      'border-blue text-white bg-blue': questionSkipped,
                    }
                  )}
                  onClick={() => handleSkipQuestion()}
                >
                  {skippable}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return tutorialButton ? (
    <div
      className={`w-full flex min-h-[13rem] border-[0.8rem] bg-pale-cyan py-[1.6rem] px-[2rem] gap-4 !p-0 ${
        required && skippable && !skipped && !value && unfinished ? 'bg-pale-yellow' : ''
      }`}
    >
      <HelpTooltip
        id={isUser ? 'user-assesment-tutorial-step-3' : 'review-tutorial-step-5'}
        childrenClassNames="relative z-50 bg-white flex flex-wrap w-fit items-center flex-1"
      >
        {questionBody}
      </HelpTooltip>
    </div>
  ) : (
    questionBody
  )
}
