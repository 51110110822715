import { DataTable, DataTableRowClickEvent } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { EmployeeTableHeader } from '../molecules/EmployeeTable/EmployeeTableHeader'
import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import { ReviewRequestTableCellBody } from '../molecules/ReviewRequestTable/ReviewRequestTableCellBody'
import { ReviewRequest } from '../../services/ReviewService'
import { EMPLOYEE_STATUS_DONE, EMPLOYEE_STATUS_TODO, EMPLOYEE_STATUS_UNFINISHED } from '../../constants/employee'
import { ReviewRequestTableOptionsBody } from '../molecules/ReviewRequestTable/ReviewRequestTableOptionsBody'
import { Employee } from '../../services/EmployeeService'
import { useHistory } from 'react-router-dom'

interface RequestedReviewTableParams {
  requests: ReviewRequest[] | undefined
  selectedSort?: { property: string; order: SortOrderEnum }
  handleOnEmployeeClicked: (reviewRequest: ReviewRequest) => string
  handleNewReview: (employee: Employee) => string
  onSortSelected?: (field: string, newSortOrder: SortOrderEnum) => void
  handleOnRequestedRowClicked: (reviewRequest: ReviewRequest) => void
}

export const RequestedReviewTable = ({
  requests,
  selectedSort,
  handleOnEmployeeClicked,
  handleOnRequestedRowClicked,
  handleNewReview,
  onSortSelected,
}: RequestedReviewTableParams): JSX.Element => {
  const history = useHistory()
  const getReviewPath = (reviewRequest: ReviewRequest): string => {
    return handleOnEmployeeClicked(reviewRequest)
  }

  const handleSortSelected = (field: string) => (newSortOrder: SortOrderEnum) => {
    onSortSelected?.(field, newSortOrder)
  }

  const createNewReview = (employee: Employee): string => {
    return handleNewReview(employee)
  }

  const onRowClick = (e: DataTableRowClickEvent) => {
    history.push(getReviewPath(e.data as ReviewRequest))
  }
  return (
    <DataTable
      className="user-list-container"
      tableClassName="user-list-table"
      rowClassName={(reviewRequest: ReviewRequest) => ({
        'user-list-table-row --todo': reviewRequest.status === EMPLOYEE_STATUS_TODO,
        'user-list-table-row --done': reviewRequest.status === EMPLOYEE_STATUS_DONE,
        'user-list-table-row --unfinished': reviewRequest.status === EMPLOYEE_STATUS_UNFINISHED,
        'cursor-pointer': true,
      })}
      onRowClick={onRowClick}
      value={requests}
      dataKey="id"
      responsiveLayout="scroll"
      paginator
      rows={5}
      rowsPerPageOptions={[5, 10, 30, 50, 500]}
      alwaysShowPaginator={false}
    >
      <Column
        headerClassName="user-list-table-header"
        field="reviewedEmployee.name"
        header={EmployeeTableHeader({
          title: 'Name',
          sortable: selectedSort?.property === 'reviewedEmployee.name' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('reviewedEmployee.name'),
        })}
        body={ReviewRequestTableCellBody()}
      />
      <Column
        headerClassName="user-list-table-header flex-1"
        header={EmployeeTableHeader({ title: 'app-table-hierarchy' })}
        field="+"
        className={'flex-1'}
        body={null}
      />
      <Column
        headerClassName="user-list-table-header "
        field="reviewedEmployee.jobTitle"
        header={EmployeeTableHeader({
          title: 'Job Title',
          sortable:
            selectedSort?.property === 'reviewedEmployee.jobTitle' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('reviewedEmployee.jobTitle'),
        })}
        body={ReviewRequestTableCellBody()}
      />
      <Column
        headerClassName="user-list-table-header"
        field="teams"
        header={EmployeeTableHeader({
          title: 'Teams',
          sortable: selectedSort?.property === 'teams' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('teams'),
        })}
        body={ReviewRequestTableCellBody({
          getValue: row => {
            return (row.reviewedEmployee.teams || []).map(team => team.name).join(', ') || '-'
          },
        })}
      />
      <Column
        headerClassName="user-list-table-header"
        field="requestedBy.name"
        header={EmployeeTableHeader({
          title: 'Requested By',
          sortable: selectedSort?.property === 'requestedBy.name' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('requestedBy.name'),
        })}
        body={ReviewRequestTableCellBody({
          tooltip: true,
        })}
      />
      <Column
        headerClassName="user-list-table-header"
        field="status"
        header={EmployeeTableHeader({
          title: 'Status',
          sortable: selectedSort?.property === 'status' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('status'),
        })}
        body={ReviewRequestTableCellBody()}
      />
      <Column
        rowEditor
        headerStyle={{ minWidth: '13rem' }}
        bodyStyle={{ textAlign: 'center', position: 'relative' }}
        body={ReviewRequestTableOptionsBody({ getReviewPath: handleOnEmployeeClicked })}
      />
    </DataTable>
  )
}
