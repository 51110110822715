import { FC } from 'react'

interface Props {
  color?: string
  width?: string
  height?: string
  strokeWidth?: string
}

const CrossSVG: FC<Props> = ({ width = 10, height = 10, color = '#00112F', strokeWidth = 1 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 1L1 9M9 9L1 1" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" />
    </svg>
  )
}

export default CrossSVG
