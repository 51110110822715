import { MapScoreResponse } from '../../api/openapi'
import { sortMapScores } from '../../utils/sortUtils'
import { MapScoreItem } from '../molecules/MapScoreItem'

interface MapScoreSectionProps {
  mapScores: MapScoreResponse[]
}

export const MapScoreSection = ({ mapScores }: MapScoreSectionProps): JSX.Element => {
  return (
    <div className="container mb-20 mx-auto p-20 shadow-default rounded-2xl print:shadow-none print:!p-0 print:break-before-page print:!pt-16">
      <div className="flex flex-col gap-10 w-full">
        <div className="flex flex-col gap-8">
          <span className="text-4xl font-bold text-dark-blue">MAP Score</span>
        </div>
        <div className="relative overflow-x-auto">
          <table className="w-11/12 text-left">
            <thead className="text-2xl text-blue-gray">
              <tr>
                <th scope="col" className="pr-6 py-5">
                  Year
                </th>
                <th scope="col" className="pr-6 py-5 text-left">
                  Score
                </th>
                <th scope="col" className="pr-6 py-5">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              {sortMapScores(mapScores, 'year').map(mapScore => (
                <MapScoreItem {...mapScore} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
