import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { rosterAPI } from '../api'

export class RosterService {
  static async importRoster(file: File): Promise<AxiosResponse> {
    const formData = new FormData()
    formData.append('file', file)

    const requestConfig: AxiosRequestConfig = {
      data: formData,
    }

    // This can take really long, but axios has a default timeout of 0 (no timeout)
    return rosterAPI.importRosterUsingPOST(requestConfig)
  }
}
