export interface RadioCheckButtonProps {
  checked: boolean
  onClick: () => void
  label?: string
  labelStyles?: string
  radius?: number
}

const RadioCheckButton = (props: RadioCheckButtonProps): JSX.Element => {
  const size = props.radius || 10

  return (
    <div className="my-1">
      <input
        type="radio"
        readOnly
        checked={props.checked}
        onClick={() => props.onClick()}
        className={`appearance-none mt-2 float-left  cursor-pointer bg-opacity-100 bg-white mr-2 w-${size} h-${size} border rounded-full border-hit-grey hover:scale-110 checked:bg-radio-gradient-radial`}
      />
      {props.label && (
        <label
          className={`block ml-8 leading-10 hover:text-hit-grey cursor-pointer ` + (props.labelStyles || '')}
          onClick={() => props.onClick()}
        >
          {props.label}
        </label>
      )}
    </div>
  )
}

export default RadioCheckButton
