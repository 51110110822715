import { EmployeeDetails } from './EmployeeService'
import { LoginRequest, LoginResponse, LoginTokenRequest, SSOCodeLoginRequest } from '../api/openapi'
import { employeeAPI, loginAPI } from '../api'

export type LoginParams = LoginRequest
export type LoginTokenParams = LoginTokenRequest

export class AuthService {
  static async login(login: LoginParams): Promise<LoginResponse> {
    return loginAPI.generateTokenUsingPOST(login).then(response => {
      if (response.data) {
        return response.data
      } else {
        throw Error('Unknown')
      }
    })
  }

  static async loginSSO(codeRequest: SSOCodeLoginRequest): Promise<LoginResponse> {
    return loginAPI.generateTokenSSOCodeUsingPOST(codeRequest).then(response => {
      if (response.data) {
        return response.data
      } else {
        throw Error('Unknown')
      }
    })
  }

  static async loginToken(login: LoginTokenParams): Promise<string> {
    return loginAPI.generateTokenUsingPOST1(login).then(response => {
      if (response.data?.jwtToken) {
        return response.data.jwtToken as string
      } else {
        throw Error('Unknown')
      }
    })
  }

  static async changePassword(currentPassword: string, newPassword: string): Promise<void> {
    return employeeAPI.changePasswordUsingPOST({ currentPassword, newPassword }).then()
  }

  static async getCurrentUser(): Promise<EmployeeDetails> {
    return await employeeAPI.getCurrentUserUsingGET().then(response => {
      return response.data as EmployeeDetails
    })
  }
}
