import { createBrowserHistory } from 'history'
import { EmployeeDetails } from '../services/EmployeeService'
import { createPathWithLang } from './languagePathUtils'
import { NetworkConstants } from './NetworkConstants'

interface HistoryState {
  stack: string[]
  prevPath: string
}

const loadHistoryState = (): HistoryState => {
  const state: HistoryState = {
    stack: [],
    prevPath: sessionStorage.getItem('prevPath') ?? '',
  }
  if (sessionStorage.getItem('sessionHistory') !== null) {
    state.stack = JSON.parse(sessionStorage.getItem('sessionHistory') ?? '[]')
  }
  if (state.stack.length <= 0) {
    state.stack.push(window.location.pathname)
  }
  return state
}

const saveHistoryState = (historyState: HistoryState) => {
  sessionStorage.setItem('sessionHistory', JSON.stringify(historyState.stack))
  sessionStorage.setItem('prevPath', historyState.prevPath)
}

const getDefaultRoute = (): string => {
  const userJSON = localStorage.getItem('user')
  if (userJSON) {
    const { role } = JSON.parse(userJSON) as EmployeeDetails
    if (role === 'admin') {
      return createPathWithLang(NetworkConstants.URL_REVIEW_LIST)
    } else {
      return createPathWithLang(NetworkConstants.URL_TECH_PROFILE)
    }
  }
  return createPathWithLang(NetworkConstants.URL_REVIEW_LIST)
}

const history = createBrowserHistory()
export const historyState: HistoryState = loadHistoryState()

// Maximum number of elementes allowed in the stack
const MAX_ELEMENTS_STACK = 50

// method to go back in path history
// forcePath is used to force goBack to go to a specific path
// this is because some pages like /thanks/ don't work properly with default behaviours
export function goBack(forcePath = '') {
  if (forcePath) {
    historyState.prevPath = forcePath
  } else {
    historyState.stack = historyState.stack.slice(0, historyState.stack.lastIndexOf(historyState.prevPath))
  }
  saveHistoryState(historyState)
  if (historyState.prevPath) {
    history.push(historyState.prevPath)
  } else {
    history.push(getDefaultRoute())
  }
}

// Listen window load event
window.onload = () => {
  if (historyState.stack.length <= 0) {
    historyState.stack.push(window.location.pathname)
  }
  saveHistoryState(historyState)
}

history.listen(({ pathname }, action) => {
  switch (action) {
    case 'POP':
      historyState.stack.pop()
      historyState.prevPath = historyState.stack.at(-2) ?? window.location.pathname
      break
    case 'PUSH':
      // Avoids repetitions in the stack
      const lastPath = historyState.stack.at(-1) ?? window.location.pathname
      const repeated = pathname.includes(lastPath) || lastPath.includes(pathname)
      if (!repeated && !lastPath.includes('employee-review')) {
        historyState.prevPath = lastPath
      }
      historyState.stack.push(pathname)
      break
    case 'REPLACE':
      historyState.stack = [...historyState.stack.slice(0, historyState.stack.length - 1), pathname]
      break
  }

  // Emptys the stack if it has too many elements
  if (historyState.stack.length > MAX_ELEMENTS_STACK) {
    historyState.stack = historyState.stack.slice(historyState.stack.length - 10, historyState.stack.length)
  }

  if (historyState.stack.length <= 0) {
    historyState.stack.push(window.location.pathname)
  }

  // Avoid prevPath to point to the same url
  const { prevPath, stack } = historyState
  const repeatedUrl = pathname.includes(prevPath) || prevPath.includes(pathname)
  if (repeatedUrl) {
    const newPrevPath =
      stack
        .filter(url => !url.includes('employee-review') && !(url.includes(prevPath) || prevPath.includes(url)))
        .at(-1) ?? getDefaultRoute()
    historyState.prevPath = newPrevPath
  }

  saveHistoryState(historyState)
})

export default history
