import React from 'react'

export enum TutorialsStorageConfiguration {
  REVIEW_TUTORIAL_COMPLETED = 'review-tutorial-completed',
  TECH_PROFILE_TUTORIAL_COMPLETED = 'tech-profile-tutorial-completed',
  INTRODUCTION_TUTORIAL_COMPLETED = 'introduction-tutorial-completed',
  REPORT_TUTORIAL_COMPLETED = 'report-tutorial-completed',
  USER_ASSESSMENT_TUTORIAL_COMPLETED = 'user-assesment-tutorial-completed',
}

interface HelpContentI {
  title: string
  subtitle: string
  body: React.ReactNode
  previous?: HelpContentKeys
  next?: HelpContentKeys
  nextButtonText?: string
  skip?: boolean
  finishKey?: TutorialsStorageConfiguration
  finish?: boolean
}

export const HelpContent: Record<string, HelpContentI> = {
  'review-new-layout-tutorial-step-1': {
    title: 'Choose a form type',
    subtitle: 'Step 1/7',
    body: `Select the form that best reflects your team member's role.\nYou can change the form type and start over later if needed.`,
    next: 'review-new-layout-tutorial-step-2',
    skip: true,
    finishKey: TutorialsStorageConfiguration.REVIEW_TUTORIAL_COMPLETED,
  },
  'review-new-layout-tutorial-step-2': {
    title: 'Not enough information?',
    subtitle: 'Step 2/7',
    body: `If you don't have the information you need to complete the assessment, you can skip it and mark it as "completed" during this period. You can then come back to it and complete it when you have more information.`,
    next: 'review-tutorial-step-3',
    nextButtonText: 'Ok',
    previous: 'review-new-layout-tutorial-step-1',
    skip: true,
    finishKey: TutorialsStorageConfiguration.REVIEW_TUTORIAL_COMPLETED,
  },
  'review-tutorial-step-1': {
    title: 'First, choose a form type',
    subtitle: 'Step 1/7',
    body: `Select the form that best reflects your team member's role.\nYou can change the form type and start over later if needed.`,
    next: 'review-tutorial-step-3',
    skip: true,
    finishKey: TutorialsStorageConfiguration.REVIEW_TUTORIAL_COMPLETED,
  },
  'review-tutorial-step-3': {
    title: 'Fill out the form in any order',
    subtitle: 'Step 3/7',
    body: `The first 5 categories create a complete overview of your team member's current professional profile.`,
    next: 'review-tutorial-step-4',
    previous: 'review-tutorial-step-1',
    skip: true,
    finishKey: TutorialsStorageConfiguration.REVIEW_TUTORIAL_COMPLETED,
  },
  'review-tutorial-step-4': {
    title: 'Add highlights and notes',
    subtitle: 'Step 4/7',
    body: `Complete the form with additional information you consider relevant to the current assessment.`,
    next: 'review-tutorial-step-5',
    previous: 'review-tutorial-step-3',
    skip: true,
    finishKey: TutorialsStorageConfiguration.REVIEW_TUTORIAL_COMPLETED,
  },
  'review-tutorial-step-5': {
    title: 'Make fast and simple assessments',
    subtitle: 'Step 5/7',
    body: `**Select from 1 to 5 how much you agree with each statement.** Has this person demonstrated this behavior over the assessment period?
    **Skip questions** if you don't have enough information.`,
    next: 'review-tutorial-step-6',
    previous: 'review-tutorial-step-4',
    skip: true,
    finishKey: TutorialsStorageConfiguration.REVIEW_TUTORIAL_COMPLETED,
  },
  'review-tutorial-step-6': {
    title: 'Confirm or tweak the calculated score',
    subtitle: 'Step 6/7',
    body: `This is an average category score based on your answers.
    You can refine the value if you feel it doesn't fully reflect this person's  capabilities in this category.`,
    next: 'review-tutorial-step-7',
    previous: 'review-tutorial-step-5',
    skip: true,
    finishKey: TutorialsStorageConfiguration.REVIEW_TUTORIAL_COMPLETED,
  },
  'review-tutorial-step-7': {
    title: 'Save as you go, publish when completed.',
    subtitle: 'Step 7/7',
    body: `You can save your progress at any point and return later to complete it. Publish the completed form to make it visible to the leaders you report to.`,
    previous: 'review-tutorial-step-6',
    skip: false,
    finishKey: TutorialsStorageConfiguration.REVIEW_TUTORIAL_COMPLETED,
    finish: true,
  },
  'tech-tutorial-step-1': {
    title: `Fill in any sections that apply to you`,
    subtitle: 'Step 1/4',
    body: "Including skills in areas that you don't use in your current role. They are part of your profile too!",
    next: 'tech-tutorial-step-2',
    skip: true,
    finishKey: TutorialsStorageConfiguration.TECH_PROFILE_TUTORIAL_COMPLETED,
  },
  'tech-tutorial-step-2': {
    title: `Share your learning/mentoring preferences`,
    subtitle: 'Step 2/4',
    body: "What's in your learning backlog? Which skills would you love to help other team members develop?",
    previous: 'tech-tutorial-step-1',
    next: 'tech-tutorial-step-3',
    skip: true,
    finishKey: TutorialsStorageConfiguration.TECH_PROFILE_TUTORIAL_COMPLETED,
  },
  'tech-tutorial-step-3': {
    title: `Rate your skills`,
    subtitle: 'Step 3/4',
    body: `Select applicable skills and assign a knowledge level.`,
    previous: 'tech-tutorial-step-2',
    next: 'tech-tutorial-step-4',
    skip: true,
    finishKey: TutorialsStorageConfiguration.TECH_PROFILE_TUTORIAL_COMPLETED,
  },
  'tech-tutorial-step-4': {
    title: `Save your progress and check the results before publish`,
    subtitle: 'Step 4/4',
    body: `Save the draft and review the current content. You will be able to publish the report to make it available to your manager and the leadership team. You can also update the information anytime you like.`,
    previous: 'tech-tutorial-step-3',
    skip: false,
    finishKey: TutorialsStorageConfiguration.TECH_PROFILE_TUTORIAL_COMPLETED,
    finish: true,
  },
  'user-assesment-tutorial-step-1': {
    title: `Fill out the form in any order you want`,
    subtitle: 'Step 1/7',
    body: `The first 5 categories can help you obtain a full overview of your current professional profile.`,
    next: 'user-assesment-tutorial-step-2',
    skip: true,
    finishKey: TutorialsStorageConfiguration.USER_ASSESSMENT_TUTORIAL_COMPLETED,
  },
  'user-assesment-tutorial-step-2': {
    title: `Add highlights and notes`,
    subtitle: 'Step 2/7',
    body: `Complete the form with any additional information and notes relevant to the current assessment.
    It can help you discuss key topics with your manager.`,
    previous: 'user-assesment-tutorial-step-1',
    next: 'user-assesment-tutorial-step-3',
    skip: true,
    finishKey: TutorialsStorageConfiguration.USER_ASSESSMENT_TUTORIAL_COMPLETED,
  },
  'user-assesment-tutorial-step-3': {
    title: `Make fast and simple assessments`,
    subtitle: 'Step 3/7',
    body: `Rate from 1 to 5 how much you agree with each statement.
    Have you demonstrated this behaviour over the last period?`,
    previous: 'user-assesment-tutorial-step-2',
    next: 'user-assesment-tutorial-step-4',
    skip: true,
    finishKey: TutorialsStorageConfiguration.USER_ASSESSMENT_TUTORIAL_COMPLETED,
  },
  'user-assesment-tutorial-step-4': {
    title: `Skip questions if not applicable`,
    subtitle: 'Step 4/7',
    body: `You can choose to skip specific questions if they are not applicable to your current role.`,
    previous: 'user-assesment-tutorial-step-3',
    next: 'user-assesment-tutorial-step-5',
    skip: true,
    finishKey: TutorialsStorageConfiguration.USER_ASSESSMENT_TUTORIAL_COMPLETED,
  },
  'user-assesment-tutorial-step-5': {
    title: `Confirm or tweak the calculated score`,
    subtitle: 'Step 5/7',
    body: `This is an average category score based on your answers.
    You can refine the value if you feel it doesn't fully reflect your capabilities in this category.`,
    previous: 'user-assesment-tutorial-step-4',
    next: 'user-assesment-tutorial-step-6',
    skip: true,
    finishKey: TutorialsStorageConfiguration.USER_ASSESSMENT_TUTORIAL_COMPLETED,
  },
  'user-assesment-tutorial-step-6': {
    title: `Save your progress anytime`,
    subtitle: 'Step 6/7',
    body: `You can save your progress at any point and come back to finish the form later.`,
    previous: 'user-assesment-tutorial-step-5',
    next: 'user-assesment-tutorial-step-7',
    skip: true,
    finishKey: TutorialsStorageConfiguration.USER_ASSESSMENT_TUTORIAL_COMPLETED,
  },
  'user-assesment-tutorial-step-7': {
    title: `Finish all sections to publish`,
    subtitle: 'Step 7/7',
    body: `The published report will be visible to you and any leaders that you and your team report into.`,
    previous: 'user-assesment-tutorial-step-6',
    skip: false,
    finishKey: TutorialsStorageConfiguration.USER_ASSESSMENT_TUTORIAL_COMPLETED,
    finish: true,
  },
}

export type HelpContentKeys = keyof typeof HelpContent
