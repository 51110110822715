interface InputCalendarProps {
  width?: number
  height?: number
  color?: string
}

const InputCalendar = ({ width = 18, height = 20, color = '#005ECA' }: InputCalendarProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      version="1.1"
      id="svg4"
    >
      <defs id="defs8" />
      <path
        d="M5.66667 1.66675V4.16675M12.3333 1.66675V4.16675M1.91667 7.57508H16.0833M16.5 7.08341V14.1667C16.5 16.6667 15.25 18.3334 12.3333 18.3334H5.66667C2.75 18.3334 1.5 16.6667 1.5 14.1667V7.08341C1.5 4.58341 2.75 2.91675 5.66667 2.91675H12.3333C15.25 2.91675 16.5 4.58341 16.5 7.08341Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="path2"
      />
      <g id="g59" transform="translate(3.9881618,10.281643)">
        <path
          d="m 8.07918,1.41675 h 0.0075 m -0.0075,2.5 h 0.0075 m -3.09083,-2.5 h 0.00833 m -0.00833,2.5 h 0.00833 m -3.0925,-2.5 h 0.00834 m -0.00834,2.5 h 0.00834"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          id="path50"
        />
      </g>
    </svg>
  )
}

export default InputCalendar
