import { classNames } from 'primereact/utils'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import history from '../../../utils/history'

type headerLink = {
  url: string
  name: string
}

interface HeaderDropdownProps {
  links: headerLink[]
}

export const HeaderDropdown = ({ links }: HeaderDropdownProps) => {
  const [expanded, setExpanded] = useState(false)
  const currentPath = history?.location?.pathname

  const buttonClassNames = (link: string) => {
    const isActive = currentPath?.match(link)

    return classNames({
      'hover:text-blue text-2xl font-semibold': true,
      'text-dark-blue': !isActive,
      'text-blue': isActive,
    })
  }

  const arrowClass = expanded ? 'pi-chevron-down' : 'pi-chevron-right'
  return (
    <div className="flex flex-col items-center">
      <button
        className="flex items-center justify-between gap-2 p-2 text-dark-blue hover:text-blue text-2xl font-semibold"
        onClick={() => setExpanded(!expanded)}
      >
        <span>Admin</span>
        <i className={`pi font-bold ${arrowClass}`} />
      </button>
      {expanded && (
        <div className="absolute flex flex-col shadow-md top-28 bg-white">
          {links.map((link, i) => (
            <Link to={link.url} className={`${buttonClassNames(link.url)} hover:bg-just-gray px-5 py-3`} key={i}>
              <span>{link.name}</span>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}
