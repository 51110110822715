import Backdrop from '@material-ui/core/Backdrop'
import { createPortal } from 'react-dom'

interface FullPageBackdropProps {
  backdropClassName?: string
  backdropClick?: () => void
  open?: boolean
}

const FullPageBackdrop = ({
  backdropClassName = 'bg-black/25',
  backdropClick: onBackdropClick,
  open = false,
}: FullPageBackdropProps) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    onBackdropClick && onBackdropClick()
  }
  return createPortal(
    <Backdrop className={`${backdropClassName} z-10`} invisible={true} onClick={handleClick} open={open || false} />,
    document.getElementById('afterContent') as HTMLElement
  )
}

export default FullPageBackdrop
