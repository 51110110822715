export type AppStatus = Idle | Loading | Failure

type Idle = { kind: 'idle' }
type Loading = { kind: 'loading' }
type Failure = { kind: 'failure'; error: string }

export const isLoading = (value: AppStatus): value is Loading => {
  return value.kind === 'loading'
}
export const isFailure = (value: AppStatus): value is Failure => {
  return value.kind === 'failure'
}
