import classNames from 'classnames'
import React, { FC, ReactElement, useState } from 'react'
import { Employee } from '../../services/EmployeeService'
import { TeamData } from '../organisms/TeamChart'
import Dot from '../atoms/Dot'
import { ConstantIDs, EMPLOYEE_HIGHLIGHT_ICON_MAP } from '../../constants/employee'
import { useHistory } from 'react-router-dom'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'

export interface EmployeeNodeProps {
  className?: string
  member?: Employee // FIXME
  modifier?: string
  teamData?: TeamData[]
  currentTeamColor?: string
}

interface ClickInterface {
  oldX: number
  oldY: number
}

export const EmployeeNode: FC<EmployeeNodeProps> = ({
  member,
  modifier,
  teamData,
  className = '',
  currentTeamColor,
}): ReactElement => {
  const history = useHistory()
  const [oldClick, setOldClick] = useState<ClickInterface>({
    oldX: 0,
    oldY: 0,
  })

  const wrapperClass = classNames({
    'org-node': true,
    [`org-node--${modifier}`]: modifier,
    [className]: className,
  })

  if (!member) return <></>

  const { name, jobTitle } = member
  const highlightsIcons = member?.highlights?.map(highlight => EMPLOYEE_HIGHLIGHT_ICON_MAP[highlight]).join('  ')

  // Mouse handlers to prevent redirect if not clicked
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    const { screenX, screenY } = event
    setOldClick({
      oldX: screenX,
      oldY: screenY,
    })
  }

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    const { screenX, screenY } = event
    if (oldClick.oldX === screenX && oldClick.oldY === screenY) {
      history.push(
        createPathWithLang(
          NetworkConstants.URL_REPORT.replace(ConstantIDs.EMPLOYEE_ID_PATH_PLACEHOLDER, member?.id.toString())
        )
      )
    }
  }

  return (
    <div className="cursor-pointer" onMouseUp={handleMouseUp} onMouseDown={handleMouseDown}>
      <div style={{ ['--tw-ring-color' as string]: currentTeamColor }} className={wrapperClass}>
        <div className="flex justify-between">
          <p className="org-node__name">{name ?? ''}</p>
          {highlightsIcons && <span>{highlightsIcons}</span>}
        </div>
        <p className="org-node__title">{jobTitle ?? ''}</p>
        <div className="org-node__dots">
          {teamData
            ?.filter(t => !!t)
            .map(({ teamColor, teamName }, i) => (
              <Dot key={i} color={teamColor} label={teamName} />
            ))}
        </div>
      </div>
    </div>
  )
}
