import { FC } from 'react'
import classNames from 'classnames'
import { CheckIcon } from '../../atoms/Icons/CheckIcon'
import { NotificationIcon } from '../../atoms/Icons/NotificationIcon'
import { CategorySelectorStep } from './CategorySelectorStep'

interface CategoryItemProps {
  category: string
  selectedCategory?: string
  isCompleted: boolean
  isUnfinished: boolean
  showEmpty?: boolean
  showStepLines?: boolean
  isFirstCategory: boolean
  isLastCategory: boolean
  onClick: (category: string) => void
}

export const CategoryItem: FC<CategoryItemProps> = ({
  category,
  selectedCategory,
  isCompleted,
  isUnfinished,
  showEmpty,
  showStepLines,
  isFirstCategory,
  isLastCategory,
  onClick,
}) => {
  const isSelected = category === selectedCategory
  const handleClick = () => onClick(category)

  const categoryNameStyle = classNames('text-dark-blue text-left text-3xl break-word', {
    'text-blue font-bold': isSelected,
  })
  const iconContainerStyle = 'category-selector-item items-start my-2 max-h-32 w-full !cursor-default'
  const buttonStyle = 'category-selector-item items-start gap-4 max-h-32'
  const tagStyle = 'rounded-lg p-2 text-m align-center font-medium mt-[0.5rem] text-stone-gray'
  const stepContainerClasses = classNames('min-w-[3rem]', {
    'mt-0': isCompleted || isUnfinished,
    'mt-2': !isCompleted || !isUnfinished,
  })

  const stepProps = {
    isFirstStep: isFirstCategory,
    isLastStep: isLastCategory,
    containerClasses: stepContainerClasses,
    stepsOffset: '5.5rem',
    showStepLines: showStepLines,
  }

  if (isSelected) {
    return (
      <button className="category-selector-item items-start gap-4 my-2 max-h-32 w-full" onMouseDown={handleClick}>
        <CategorySelectorStep {...stepProps}>
          {isCompleted && <CheckIcon width={23} height={23} color="#00C853" filledColor="#E6FAEE" />}
          {isUnfinished && <NotificationIcon width={23} height={23} color="#FECD59" filledColor="#FFF6DF" />}
          {!isCompleted && !isUnfinished && <div className="bg-blue border rounded-[50%] w-[1.2rem] h-[1.2rem]" />}
        </CategorySelectorStep>
        <div className="flex flex-col items-start">
          <p className={categoryNameStyle}>{category}</p>
          {isUnfinished ? <p className={`${tagStyle} bg-pale-yellow`}>Incomplete</p> : null}
          {isCompleted ? <p className={`${tagStyle} bg-just-gray`}>Completed</p> : null}
          {showEmpty && !isCompleted ? <p className={`${tagStyle} bg-just-gray`}>N/A</p> : null}
        </div>
      </button>
    )
  }

  if (isCompleted) {
    return (
      <div className={iconContainerStyle}>
        <button className={buttonStyle} onMouseDown={handleClick}>
          <CategorySelectorStep {...stepProps}>
            <CheckIcon width={23} height={23} color="#00C853" filledColor="#E6FAEE" />
          </CategorySelectorStep>
          <div className="flex flex-col items-start">
            <p className={categoryNameStyle}>{category}</p>
            <p className={`${tagStyle} bg-just-gray`}>Completed</p>
          </div>
        </button>
      </div>
    )
  }

  if (isUnfinished) {
    return (
      <div className={iconContainerStyle}>
        <button className={buttonStyle} onMouseDown={handleClick}>
          <CategorySelectorStep {...stepProps}>
            <NotificationIcon width={23} height={23} color="#FECD59" filledColor="#FFF6DF" />
          </CategorySelectorStep>
          <div className="flex flex-col items-start">
            <p className={categoryNameStyle}>{category}</p>
            <p className={`${tagStyle} bg-pale-yellow`}>Incomplete</p>
          </div>
        </button>
      </div>
    )
  }

  if (showEmpty) {
    return (
      <div className={iconContainerStyle}>
        <button className={buttonStyle} onMouseDown={handleClick}>
          <CategorySelectorStep {...stepProps}>
            <div className="bg-inactive-gray rounded-[50%] w-[1.2rem] h-[1.2rem]" />
          </CategorySelectorStep>
          <div className="flex flex-col items-start">
            <p className={categoryNameStyle}>{category}</p>
            <p className={`${tagStyle} bg-just-gray`}>N/A</p>
          </div>
        </button>
      </div>
    )
  }

  return (
    <div className={iconContainerStyle}>
      <button
        className={classNames({
          'category-selector-item items-start gap-4 max-h-32': true,
          isCompleted,
          active: isSelected,
          unfinished: isUnfinished,
        })}
        onMouseDown={handleClick}
      >
        <CategorySelectorStep {...stepProps}>
          <div className="bg-inactive-gray rounded-[50%] w-[1.2rem] h-[1.2rem]" />
        </CategorySelectorStep>
        <div className="flex flex-col items-start">
          <p className={categoryNameStyle}>{category}</p>
        </div>
      </button>
    </div>
  )
}
