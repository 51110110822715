import { Tooltip } from '@material-ui/core'
import React from 'react'
import { TeamDetails } from '../../../../services/TeamService'
import HierarchyIcon from '../../../atoms/Icons/HierarchyIcon'
import { Employee } from '../../../../services/EmployeeService'

interface EmployeeTableHierarchyBodyParams {
  handleHierarchyNavigation: (e: TeamDetails) => void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const GeneralStatusTableHierarchyBody = ({ handleHierarchyNavigation }: EmployeeTableHierarchyBodyParams) => {
  return (rowData: { team: TeamDetails; employees: Employee[] }): JSX.Element => {
    const team = rowData.team

    const handleHierarchyButtonClick = (e: React.MouseEvent): void => {
      handleHierarchyNavigation?.(team)
      e.stopPropagation()
    }

    if (!team.children?.length) {
      return <span className="w-12 flex mx-auto justify-center">-</span>
    }

    return (
      <div className="w-full flex justify-center">
        <div className="w-12 flex justify-center">
          <Tooltip
            title="Click to open their team members."
            arrow
            enterDelay={500}
            enterNextDelay={500}
            classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
          >
            <button
              onClick={handleHierarchyButtonClick}
              className="user-list-table-option-button  border-b border-transparent hover:border-blue"
            >
              <HierarchyIcon width={28} height={19} color="#005ECA" />
            </button>
          </Tooltip>
        </div>
      </div>
    )
  }
}
