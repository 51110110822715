import { FC } from 'react'

interface LabelSliderTextProps {
  value: number
  sliderValue: number
}

const LabelSliderText: FC<LabelSliderTextProps> = ({ value, sliderValue }) => {
  const style = sliderValue === value ? 'text-2xl text-blue font-bold' : ''
  return <span className={style}>{value}</span>
}

export default LabelSliderText
