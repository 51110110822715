import { FC } from 'react'

export interface FailIconProps {
  filled?: boolean
}

export const FailIcon: FC<FailIconProps> = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="29" stroke="#FF7F6D" strokeWidth="2" />
      <path d="M39 21L21 39M39 39L21 21" stroke="#FF7F6D" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}
