interface Point {
  x: number
  y: number
}

const point = (x: number, y: number): Point => {
  return { x, y }
}

export { point }
export type { Point }
