import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState, useAppDispatch } from '../..'
import { ReviewRequestResponse } from '../../api/openapi'
import { AVAILABLE_EMPLOYEE_HIGHLIGHTS, ConstantIDs, EMPLOYEE_HIGHLIGHT_ICON_MAP } from '../../constants/employee'
import { useQuery } from '../../hooks/useQuery'
import { Employee, EmployeeDetails, EmployeeKeys, EmployeeService, EmployeeSort } from '../../services/EmployeeService'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../services/EventTrackingService'
import { PeriodService } from '../../services/PeriodService'
import { ReviewRequest, ReviewService } from '../../services/ReviewService'
import {
  finishImageIntroduction,
  hideImageIntroduction,
  showImageIntroductionId,
} from '../../slices/image-introduction-slice'
import { Period } from '../../types/definitions/Period'
import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import {
  calculateEmployeeReviewPath,
  createPathToCreateNewReview,
  ReviewerTypeEnum,
} from '../../utils/employeePathUtils'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { normalizeText } from '../../utils/text'
import { AppButton } from '../atoms/AppButton/AppButton'
import AppFilterButton from '../atoms/AppFilterButton/AppFilterButton'
import Breadcrumb, { BreadcrumbItem } from '../atoms/Breadcrumb/Breadcrumb'
import { DiagramIcon } from '../atoms/Icons/DiagramIcon'
import { TableIcon } from '../atoms/Icons/TableIcon'
import Spinner from '../atoms/Spinner'
import TextLine from '../atoms/TextLine'
import ErrorModal from '../molecules/ErrorModal'
import { IntroductionImagesModal } from '../molecules/IntroductionImagesModal'
import { PeriodCounter } from '../molecules/PeriodCounter'
import { TutorialModal } from '../molecules/TutorialModal'
import { RequestedReviewTable } from '../organisms/RequestedReviewTable'
import { ReviewTable } from '../organisms/ReviewTable'
import TeamChart from '../organisms/TeamChart'

export interface Filter {
  property: string
  value: string
}

interface FilteredResults {
  employees: Employee[]
  reviewRequests: ReviewRequest[]
}

export type SectionType = 'table' | 'diagram'

export const ReviewsPage = (): JSX.Element => {
  const user = useSelector<RootState, Employee | null>(state => state.auth.user)
  const history = useHistory()
  const query = useQuery()
  const dispatch = useAppDispatch()
  const [selectedLeaders, setSelectedLeaders] = useState<number[]>([])
  const [breadcrumbData, setBreadcrumbData] = useState<BreadcrumbItem[]>([])
  const [employees, setEmployees] = useState<Employee[]>([])
  const [myEmployees, setMyEmployees] = useState<Employee[]>([])
  const [tableEmployees, setTableEmployees] = useState<Employee[]>([])
  const [searchedEmployees, setSearchedEmployees] = useState<Employee[]>([])
  const [filteredAndSortedEmployees, setFilteredAndSortedEmployees] = useState<Employee[]>([])
  const [reviewRequests, setReviewRequests] = useState<ReviewRequest[]>([])
  const [searchedReviewRequests, setSearchedReviewRequests] = useState<ReviewRequest[]>([])
  const [filteredAndSortedReviewRequests, setFilteredAndSortedReviewRequests] = useState<ReviewRequest[]>([])
  const [textSearch, setTextSearch] = useState<string>('')
  const [enabledFilter, setEnabledFilter] = useState<Filter>()
  const [selectedSort, setSelectedSort] = useState<EmployeeSort[]>([
    { property: 'status', order: SortOrderEnum.Ascending },
  ])
  const [selectedReviewRequestSort, setSelectedReviewRequestSort] = useState<EmployeeSort[]>([
    { property: 'reviewedEmployee.name', order: SortOrderEnum.Ascending },
  ])
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [activeSection, setActiveSection] = useState<SectionType>(query.get('view') === 'diagram' ? 'diagram' : 'table')
  const [totalTodoReviews, setTotalTodoReviews] = useState(0)
  const [completedReviews, setCompletedReviews] = useState(0)
  const [percentageOfCompletedReviews, setPercentageOfCompletedReviews] = useState(0)
  const [currentPeriod, setCurrentPeriod] = useState<Period>()
  const [loadedAllEmployees, setLoadedAllEmployees] = useState(false)
  const currentUser = useSelector<RootState, EmployeeDetails>(state => state.auth.user as EmployeeDetails)
  const userIsAdmin = currentUser.role === 'admin'
  // Introduction tutorial
  const tutorialCompleted = useSelector<RootState, boolean>(state => state.imageIntroduction.completed)
  const [introductionView, setIntroductionView] = useState<boolean>(tutorialCompleted)

  useEffect(() => {
    _loadPeriod()
    dispatch(hideImageIntroduction())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadData = async () => {
    setLoading(true)
    _loadAllEmployees(false)
    _loadReviewRequests()
    await _loadMyEmployees()
    setLoading(false)
  }

  useEffect(() => {
    currentPeriod && loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPeriod])

  // TODO expand filter complexity to cater for other non-status based filters
  useEffect(() => {
    if (!enabledFilter) {
      setFilteredAndSortedEmployees(EmployeeService.sortEmployees(searchedEmployees, selectedSort))
      setFilteredAndSortedReviewRequests(ReviewService.sortReviewRequests(reviewRequests, selectedSort))
    } else {
      const filteredResults: FilteredResults = {
        employees,
        reviewRequests,
      }
      if (enabledFilter.property === 'direct') {
        filteredResults.employees = tableEmployees
      } else if (enabledFilter.property === 'search') {
        filteredResults.employees = searchedEmployees
        filteredResults.reviewRequests = searchedReviewRequests
      } else if (enabledFilter.property === 'highlights') {
        filteredResults.employees = searchedEmployees.filter(
          // @ts-expect-error FIXME
          employee => employee[enabledFilter.property]?.some(h => h === enabledFilter.value)
        )
      } else {
        filteredResults.employees = searchedEmployees.filter(
          // @ts-expect-error FIXME
          employee => employee[enabledFilter.property] === enabledFilter.value
        )
        filteredResults.reviewRequests = reviewRequests
      }
      setFilteredAndSortedEmployees(EmployeeService.sortEmployees(filteredResults.employees, selectedSort))
      setFilteredAndSortedReviewRequests(ReviewService.sortReviewRequests(filteredResults.reviewRequests, selectedSort))
      if (!selectedLeaders.length) {
        const breadcrumbData = [
          { label: `${enabledFilter ? enabledFilter?.value : 'All'} (${filteredResults.employees.length} results)` },
        ]
        setBreadcrumbData([...breadcrumbData])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedEmployees, searchedReviewRequests, enabledFilter, selectedLeaders])

  useEffect(() => {
    trackEvent(TrackingCategoryEnum.REVIEW_LIST, TrackingCategoryEventEnum.REVIEW_LIST.SEARCH_CHANGED, '')
    if (textSearch.length === 0 && !loading) {
      // TODO: WE NEED TO IMPROVE VIEW REACTION CONTROL (REFACTORING COMPONENTS)
      if (enabledFilter?.property === undefined && breadcrumbData[0].label.includes('Search')) return
      if (enabledFilter?.property === 'search') {
        setEnabledFilter({ property: 'direct', value: 'My Team' })
        resetBreadcrumbData()
      }
      _loadAllEmployees()
    } else {
      searchResults()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textSearch])

  useEffect(() => {
    const reviewsToBeDone = reviewRequests.length + myEmployees.length
    setTotalTodoReviews(reviewsToBeDone)
    const completedReviewRequests = reviewRequests.filter(rr => {
      if (rr.status === 'Completed' || rr.status === 'Skipped') {
        return true
      } else {
        return false
      }
    }).length
    const completedTeamReviews = myEmployees.filter(rr => {
      if (rr.status === 'Completed' || rr.status === 'Skipped') {
        return true
      } else {
        return false
      }
    }).length
    const finishedReviews = completedReviewRequests + completedTeamReviews
    setCompletedReviews(finishedReviews)
    if (reviewsToBeDone === 0) {
      setPercentageOfCompletedReviews(0)
    } else {
      setPercentageOfCompletedReviews(Math.round((finishedReviews / reviewsToBeDone) * 100))
    }
  }, [myEmployees, reviewRequests])

  const resetBreadcrumbData = () => {
    setSelectedLeaders([])
    const breadcrumbData = [{ label: `All (${employees.length} results)` }]
    setBreadcrumbData([...breadcrumbData])
  }

  useEffect(() => {
    setFilteredAndSortedEmployees(() => EmployeeService.sortEmployees(filteredAndSortedEmployees, selectedSort))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort])

  useEffect(() => {
    setFilteredAndSortedReviewRequests(() =>
      ReviewService.sortReviewRequests(filteredAndSortedReviewRequests, selectedReviewRequestSort)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReviewRequestSort])

  const _loadAllEmployees = async (handleLoading = true) => {
    if (!employees.length) {
      handleLoading && setLoading(true)
      try {
        const employees = await EmployeeService.findAllWithAccess(undefined, currentPeriod)
        setEmployees(employees)
        setSearchedEmployees(employees)
      } catch (error) {
        console.error(error)
      } finally {
        trackEvent(TrackingCategoryEnum.REVIEW_LIST, TrackingCategoryEventEnum.REVIEW_LIST.PAGE_ON_LOAD, '')
        setLoadedAllEmployees(true)
        handleLoading && setLoading(false)
      }
    } else {
      setSearchedEmployees(employees)
    }
  }

  const _loadReviewRequests = async () => {
    if (!reviewRequests.length) {
      try {
        const reviewRequests = await ReviewService.getReviewRequests()
        setReviewRequests(reviewRequests)
        setSearchedReviewRequests(reviewRequests)
        setFilteredAndSortedReviewRequests(reviewRequests)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const _loadLeaders = async (employeeId?: number) => {
    if (employeeId === user?.id) {
      handleDirectFilterSelected()
    } else {
      let currentLeaders = selectedLeaders
      if (employeeId) {
        const idx = selectedLeaders.indexOf(employeeId)
        currentLeaders = selectedLeaders.slice(0, idx + 1)
        setSelectedLeaders(currentLeaders)
      }

      const leadersIds = currentLeaders[0] === user?.id ? currentLeaders.slice(1) : currentLeaders
      const leaders = leadersIds
        .map(leaderId => employees.find(e => e.id === leaderId))
        .filter(l => l !== undefined) as Employee[]

      const leadersDetails = leaders.map(leader => ({ label: leader.name, id: leader.id }))

      const isDirectTab = enabledFilter?.property === 'direct'
      const firstBreadcrumbElement = { label: isDirectTab ? enabledFilter?.value : 'All', id: user?.id }

      const breadcrumbData = [firstBreadcrumbElement, ...leadersDetails]

      const lastLeaderId = leadersIds.at(-1) || (user?.id as number)
      const filteredEmployees = employees.filter(e => e.teams?.some(t => t.leaders.some(l => l.id === lastLeaderId)))

      setTableEmployees(filteredEmployees)
      setSearchedEmployees(filteredEmployees)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      breadcrumbData.at(-1)!.label = `${breadcrumbData.at(-1)?.label} (${filteredEmployees.length} results)`
      setBreadcrumbData(breadcrumbData)
    }
  }

  const _loadPeriod = async () => {
    const period = await PeriodService.findActive()
    setCurrentPeriod(period)
  }

  const _loadMyEmployees = async () => {
    const myEmployees = await EmployeeService.findAllWithAccess(0, currentPeriod)
    setMyEmployees(myEmployees)
    handleDirectFilterSelected(myEmployees)
  }

  const handleLeadersSearch = (employeeId?: number) => {
    if (!employeeId) {
      if (!selectedLeaders.includes(user?.id as number)) selectedLeaders.push(user?.id as number)
    } else {
      if (enabledFilter?.property === 'direct') {
        if (!selectedLeaders.includes(user?.id as number)) selectedLeaders.push(user?.id as number)
        if (!selectedLeaders.includes(employeeId)) selectedLeaders.push(employeeId)
      } else {
        if (!selectedLeaders.includes(employeeId)) selectedLeaders.push(employeeId)
      }
    }
    _loadLeaders()
  }

  const trackTabSelected = (tab: string) => {
    trackEvent(
      TrackingCategoryEnum.REVIEW_LIST,
      TrackingCategoryEventEnum.REVIEW_LIST.CLICK_NAVIGATION_TABS,
      JSON.stringify({ selectedTab: tab })
    )
  }

  const handleDirectFilterSelected = async (_myEmployees?: Employee[]) => {
    const myTeam = _myEmployees || myEmployees
    setTableEmployees(myTeam)
    trackTabSelected('My Team')
    setEnabledFilter({ property: 'direct', value: 'My Team' })
    setTextSearch('')
    setBreadcrumbData([{ label: `My Team (${myTeam.length} results)` }])
    setSelectedLeaders([user?.id as number])
  }

  const handleHighlightFilterSelected = (highlight: string) => () => {
    trackTabSelected(highlight)
    setSelectedLeaders([])
    setSearchedEmployees(employees)
    setEnabledFilter({ property: 'highlights', value: highlight })
    setTextSearch('')
    setBreadcrumbData([{ label: `${highlight} (${highlightsCount[highlight]} results)` }])
  }

  const searchResults = (): void => {
    setSelectedLeaders([])
    setEnabledFilter({ property: 'search', value: 'Search' })
    const filterItem = (item: string, matchValue: string) => normalizeText(item).includes(normalizeText(matchValue))
    setSearchedEmployees(employees.filter(employee => filterItem(employee.name, textSearch)))
    setSearchedReviewRequests(
      reviewRequests.filter(reviewRequest => filterItem(reviewRequest.reviewedEmployee.name, textSearch))
    )
  }

  const handleClear = (): void => {
    setTextSearch('')
  }

  const handleSortSelected = (property: string, order: SortOrderEnum): void => {
    setSelectedSort([{ property: property as EmployeeKeys, order }])
  }

  const handleReviewRequestSortSelected = (property: string, order: SortOrderEnum): void => {
    setSelectedReviewRequestSort([{ property: property, order }])
  }

  const handleOnEmployeeClicked = (employee: Employee) => calculateEmployeeReviewPath(employee, user as Employee)

  const handleNewReview = (employee: Employee) => createPathToCreateNewReview(employee.id)

  const handleOnReviewRequestClicked = (reviewRequest: ReviewRequestResponse): string =>
    createPathWithLang(
      NetworkConstants.URL_EDIT_REVIEW.replace(
        ConstantIDs.REVIEW_ID_PATH_PLACEHOLDER,
        reviewRequest.review.id.toString()
      )
    )

  const handleOnReviewRequestRowClicked = (reviewRequest: ReviewRequestResponse): void => {
    const path = calculateEmployeeReviewPath(
      reviewRequest.reviewedEmployee as Employee,
      user as Employee,
      ReviewerTypeEnum.REQUESTED
    )
    history.push(path)
  }

  const handleOnHierarchyNavigate = (employee: Employee): void => {
    if (enabledFilter?.property !== 'direct') setEnabledFilter(undefined)
    handleLeadersSearch(employee.id)
    setTextSearch('')
    trackEvent(
      TrackingCategoryEnum.REVIEW_LIST,
      TrackingCategoryEventEnum.REVIEW_LIST.CLICK_HIERARCHY_ICON,
      JSON.stringify({ selectedEmployeeId: employee.id })
    )
  }

  const handleLeadersNavigation = (employeeId: number): void => {
    _loadLeaders(employeeId)
  }

  const navigateToSection = (section: SectionType): void => {
    if (!section) return

    setActiveSection(section)
    query.set('view', section)
    history.replace({
      search: query.toString(),
    })
  }

  const statusesCount: Record<string, number> = {}
  const highlightsCount: Record<string, number> = {}

  // FIXME
  employees.forEach(employee => {
    if (employee.status) {
      statusesCount[employee.status] = (statusesCount[employee.status] ?? 0) + 1
    }

    if (employee.highlights) {
      employee.highlights.forEach(highlight => (highlightsCount[highlight] = (highlightsCount[highlight] ?? 0) + 1))
    }
  })

  const TutorialIntroduction = () => {
    return (
      <div className="container flex justify-center my-auto">
        <div className="flex flex-col justify-center">
          <div className="w-full text-center leading-[3rem]">
            <p className="text-6xl font-medium mb-16">Welcome to Grapes</p>
            <div className="text-3xl font-normal mb-12 flex flex-col gap-8">
              <p>
                The platform is divided into different areas to help you assess your team members, analyze individual or
                team profiles, spot issues, and find suitable resources for new projects.
              </p>
              <p>Let's have a quick tour to get you started!</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handleOrgChart = () => {
    trackEvent(TrackingCategoryEnum.REVIEW_LIST, TrackingCategoryEventEnum.REVIEW_LIST.CLICK_ORG_CHART_BTN)
    navigateToSection('diagram')
  }

  const handleTable = () => {
    trackEvent(TrackingCategoryEnum.REVIEW_LIST, TrackingCategoryEventEnum.REVIEW_LIST.CLICK_TABLE_BTN)
    navigateToSection('table')
  }

  if (loading) {
    return <Spinner />
  }
  return (
    <div className="px-4 flex-grow flex flex-col">
      <div className="container mt-10 flex flex-wrap">
        <TutorialModal
          visible={!introductionView}
          onClose={() => {
            setIntroductionView(true)
            dispatch(showImageIntroductionId(0))
          }}
          skipTutorial={() => {
            setIntroductionView(true)
            dispatch(finishImageIntroduction())
          }}
          icon={false}
          startButtonText="Quick Tutorial"
          message={<TutorialIntroduction />}
        />
        {/* This images tutorial should be temporary. If needed to return to the other tutorial format, search before 15/12/22*/}
        <IntroductionImagesModal visible={!tutorialCompleted && introductionView} />
        <div className="flex flex-col gap-6">
          <h1 id="title" className=" text-6xl text-dark-blue font-semibold">
            Assessments
          </h1>
          <label className="text-xl font-bold align-left">
            Progress: ({completedReviews}/{totalTodoReviews}) · {percentageOfCompletedReviews}%
          </label>
        </div>
        <div className="flex flex-col items-center align-middle mx-auto gap-4">
          <TextLine
            disabled={!loadedAllEmployees}
            className="text-border border-blue chooseInput"
            type="text"
            value={textSearch}
            searchIcon={true}
            onChange={value => {
              setTextSearch(value)
            }}
            onClear={handleClear}
            onSubmit={searchResults}
            placeholder={loadedAllEmployees ? 'Quick find any team member' : 'Loading all employees...'}
          />
        </div>
        <PeriodCounter currentPeriod={currentPeriod} />
      </div>
      <div className="container mt-14 mb-4 relative flex flex-col gap-10">
        <div className={classNames({ flex: true, 'flex justify-end': activeSection === 'diagram' && userIsAdmin })}>
          {activeSection === 'table' && (
            <>
              <div className="flex flex-1 flex-wrap gap-4">
                <div className="flex flex-1 flex-wrap gap-4">
                  {/* lambda function below to avoid passing the event object to handleDirectFilterSelected */}
                  <AppFilterButton
                    active={enabledFilter?.property === 'direct'}
                    onClick={() => handleDirectFilterSelected()}
                  >
                    My Team
                  </AppFilterButton>
                  {AVAILABLE_EMPLOYEE_HIGHLIGHTS.map(highlight => {
                    const highlightValue = highlightsCount[highlight.value]
                    return (
                      <AppFilterButton
                        key={highlight.value}
                        active={enabledFilter?.value === highlight.value}
                        onClick={handleHighlightFilterSelected(highlight.value)}
                      >
                        {EMPLOYEE_HIGHLIGHT_ICON_MAP[highlight.value]}&nbsp;&nbsp;{highlight.label}
                        {!!highlightValue ? ` (${highlightValue})` : null}
                      </AppFilterButton>
                    )
                  })}
                </div>
              </div>
            </>
          )}
          {userIsAdmin && (
            <div className="admin-button-container">
              {activeSection === 'diagram' && userIsAdmin ? (
                <AppButton type="clear" onClick={handleTable}>
                  <TableIcon />
                  <span>Table</span>
                </AppButton>
              ) : (
                <AppButton type="clear" onClick={handleOrgChart}>
                  <DiagramIcon />
                  <span>Org chart</span>
                </AppButton>
              )}
            </div>
          )}
        </div>
      </div>
      {activeSection === 'table' &&
      ['direct', 'search'].some(filter => enabledFilter?.property === filter) &&
      filteredAndSortedReviewRequests.length > 0 ? (
        <>
          <div className="container mt-6 mb-4 relative flex flex-col gap-10">
            <Breadcrumb
              items={[{ label: `My requests (${filteredAndSortedReviewRequests.length} results)` }]}
              onItemSelected={handleLeadersNavigation}
            />
          </div>
          <div className="container mb-16">
            <RequestedReviewTable
              selectedSort={selectedReviewRequestSort[0]}
              onSortSelected={handleReviewRequestSortSelected}
              requests={filteredAndSortedReviewRequests}
              handleOnEmployeeClicked={handleOnReviewRequestClicked}
              handleOnRequestedRowClicked={handleOnReviewRequestRowClicked}
              handleNewReview={handleNewReview}
            />
          </div>
        </>
      ) : null}
      {activeSection === 'table' && breadcrumbData.length ? (
        <div className="container mt-6 mb-4 relative flex flex-col gap-10">
          <Breadcrumb items={breadcrumbData} onItemSelected={handleLeadersNavigation} />
        </div>
      ) : null}
      <div className="container flex-grow">
        {activeSection === 'table' && (
          <>
            <ReviewTable
              employees={filteredAndSortedEmployees}
              allEmployees={employees}
              selectedSort={selectedSort[0]}
              handleNewReview={handleNewReview}
              onHierarchyNavigate={handleOnHierarchyNavigate}
              handleOnEmployeeClicked={handleOnEmployeeClicked}
              onSortSelected={handleSortSelected}
            />
          </>
        )}
        {activeSection === 'diagram' && userIsAdmin && (
          <TeamChart
            employees={employees}
            highlight={textSearch ? filteredAndSortedEmployees.map(employee => employee.id) : undefined}
            currentUser={user}
          />
        )}
      </div>
      {showErrorModal && (
        <ErrorModal
          visible={showErrorModal}
          message={<p>Employee selected was not found</p>}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </div>
  )
}

export default ReviewsPage
