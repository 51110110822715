import { Review, ReviewEmployee } from '../../../types/definitions/review'
import { AnsweredQuestion } from '../../../types/definitions/question'
import { AppMarkdownViewer } from '../../atoms/AppMarkdownViewer/AppMarkdownViewer'
import { SortOrderEnum } from '../../../types/enums/sort-order.enum'

type EmployeeHeaderProps = {
  reviews?: Review[]
}

type AnswersByTopic = Map<string, InsightItem[]>

const getAnswersByTopic = (insightItems: InsightItem[]): AnswersByTopic => {
  insightItems
    .sort((a, b) => {
      return (a.answer?.topic?.order || 0) - (b.answer?.topic?.order || 0)
    })
    .sort((a, b) => {
      return (a.answer?.order || 0) - (b.answer?.order || 0)
    })
  const grouped: AnswersByTopic = insightItems.reduce((curr, value) => {
    const topicName = value.answer?.topic?.name || ''
    const topic = curr.get(topicName) || []
    if (value.answer.reportLayout?.type === null || value.answer.reportLayout?.type === 'CHART') topic.push(value)
    curr.set(topicName, topic)
    return curr
  }, new Map<string, InsightItem[]>())
  return grouped
}

type ReviewDetailsTableProps = {
  answersByTopic: AnswersByTopic
  skipLastBorder?: boolean
  sortOrder?: SortOrderEnum
}

type ReviewDetailsTableRowProps = {
  topic: string
  insightItems: InsightItem[]
  border?: boolean
}

const ReviewDetailsTableRow = ({ topic, insightItems, border = true }: ReviewDetailsTableRowProps): JSX.Element => {
  if (!insightItems.length) return <></>

  const borderStyle = border ? 'border-solid border-b border-dark-blue ' : ''

  return (
    <div className={`${borderStyle} pb-16 pt-5 print:pb-2 print:break-before-page`}>
      <div className="flex justify-between flex-row text-3xl px-6 mt-12 mb-4 gap-4 border-white border-solid border-2">
        <p className="w-8/12">
          <span className="capitalize">{topic}</span>
        </p>
        <p className="w-3/12 text-center font-bold">Authors</p>
        <p className="w-1/12 text-center font-bold">Value</p>
      </div>
      {insightItems
        .filter(item => item.answer.type !== 'NUMERIC')
        .map(item => {
          const rowClassName = item.answer.label ? 'bg-light-blue text-white' : 'bg-pale-cyan text-dark-blue'
          const classes = `flex gap-4 px-4 justify-between rounded-xl min-h-[4.8rem] border-white border-solid border-2 overflow-hidden p-4 ${rowClassName} flex-row text-2xl`
          const authors = item.authors.map(author => author.name).join(', ')
          return (
            <div className={classes} key={item.answer.id}>
              <div className="flex flex-row gap-3 w-8/12">
                <AppMarkdownViewer markdown={item.answer.label || item.answer.title} />
              </div>
              <p className="w-3/12 text-center">{authors}</p>
              <p className="min-w-[3rem] w-1/12 text-center">
                {item.answer.reportLayout?.type === 'LIST'
                  ? (item.answer.answer as string[]).join(', ')
                  : item.answer.answer}
              </p>
            </div>
          )
        })}
    </div>
  )
}

const sortAnswers = (array: InsightItem[], sortOrder: SortOrderEnum): InsightItem[] => {
  if (sortOrder === SortOrderEnum.None) {
    return array
  }

  return array.sort((a: InsightItem, b: InsightItem) => {
    if (sortOrder === SortOrderEnum.Ascending) {
      return a.value - b.value
    } else if (sortOrder === SortOrderEnum.Descending) {
      return b.value - a.value
    } else return 0
  })
}

const ReviewDetailsTable = ({
  answersByTopic,
  skipLastBorder = false,
  sortOrder = SortOrderEnum.None,
}: ReviewDetailsTableProps): JSX.Element => {
  const topics = [...answersByTopic.keys()].sort()

  return (
    <div>
      {topics.map((item, index) => (
        <ReviewDetailsTableRow
          key={item}
          topic={item}
          insightItems={sortAnswers(answersByTopic.get(item) ?? [], sortOrder)}
          border={!(index === topics.length - 1 && skipLastBorder)}
        ></ReviewDetailsTableRow>
      ))}
    </div>
  )
}

type InsightItem = {
  answer: AnsweredQuestion
  authors: ReviewEmployee[]
  value: number
}

const getInsights = (reviews: Review[], desiredFilter: (answer: AnsweredQuestion) => boolean) => {
  const insights: InsightItem[] = []

  reviews.forEach(review => {
    review.answers.filter(desiredFilter).forEach(answer => {
      const insight = insights.find(insight => insight.answer.id === answer.id)
      if (insight) {
        insight.authors.push(review.reviewAuthor)
        insight.value += answer.answer as number
      } else {
        insights.push({
          answer: answer,
          authors: [review.reviewAuthor],
          value: answer.answer as number,
        })
      }
    })
  })

  return insights
}

export const EmployeeResultsStrengthWekness = ({ reviews }: EmployeeHeaderProps): JSX.Element => {
  const strengthsFilter = (answer: AnsweredQuestion) => answer.type === 'SCALE' && answer.answer === 5
  const weaknessesFilter = (answer: AnsweredQuestion) => answer.type === 'SCALE' && answer.answer <= 3

  const strengths = getAnswersByTopic(getInsights(reviews || [], strengthsFilter))
  const weaknesses = getAnswersByTopic(getInsights(reviews || [], weaknessesFilter))

  return (
    <div className="container mb-20 mt-20 mx-auto p-20 shadow-default rounded-2xl print:shadow-none print:!p-0 print:break-before-page print:!pt-16">
      <div className="flex flex-col gap-20 w-full">
        <div className="flex flex-col gap-8">
          <span className="text-4xl font-bold text-dark-blue">Automatic Insights</span>
        </div>
        <div>
          {strengths.size > 0 && (
            <div className="flex flex-col">
              <span className="text-[2.1rem] font-bold text-dark-blue">Strengths</span>
              <ReviewDetailsTable answersByTopic={strengths} sortOrder={SortOrderEnum.Descending} />
            </div>
          )}
          {weaknesses.size > 0 && (
            <div className={`flex flex-col ${strengths.size > 0 ? 'mt-24' : ''}`}>
              <span className="text-[2.1rem] font-bold text-dark-blue">Areas to Improve</span>
              <ReviewDetailsTable
                answersByTopic={weaknesses}
                skipLastBorder={true}
                sortOrder={SortOrderEnum.Ascending}
              />
            </div>
          )}
          {weaknesses.size === 0 && strengths.size === 0 && (
            <span className="text-[1.5rem] text-dark-blue">No Strengths or Areas to Improve to show.</span>
          )}
        </div>
      </div>
    </div>
  )
}
