import { Employee, EmployeeDetails, EmployeeService } from '../../services/EmployeeService'
import { ReviewService } from '../../services/ReviewService'

import { Review } from '../../types/definitions/review'

import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AnsweredQuestion } from '../../types/definitions/question'
import { ReportLayoutTypeEnum } from '../../types/enums/report-layout-type.enum'
import history from '../../utils/history'

import { cloneDeep } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState } from '../..'
import { CONTACT_EMAIL } from '../../constants/general'
import { useQuery } from '../../hooks/useQuery'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../services/EventTrackingService'
import { FormService } from '../../services/FormService'
import { Form } from '../../types/definitions/form'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { assignColor } from '../../utils/utils'
import { AppButton } from '../atoms/AppButton/AppButton'
import ChartRadar, { ChartDatasetType } from '../atoms/Charts/ChartRadar'
import DeleteIcon from '../atoms/Icons/DeleteIcon.svg'
import GarbageSVG from '../atoms/Icons/Garbage.svg'
import PencilSVG from '../atoms/Icons/Pencil.svg'
import { PlusIcon } from '../atoms/Icons/PlusIcon'
import WarningIcon from '../atoms/Icons/WarningIcon.svg'
import Spinner from '../atoms/Spinner'
import EmployeeResultsData from '../molecules/EmployeeResults/EmployeeResultsData'
import EmployeeResultsDetails from '../molecules/EmployeeResults/EmployeeResultsDetails'
import EmployeeResultsHistory from '../molecules/EmployeeResults/EmployeeResultsHistory'
import EmployeeResultsTeams from '../molecules/EmployeeResults/EmployeeResultsTeams'
import ErrorModal from '../molecules/ErrorModal'
import OptionsModal from '../molecules/OptionsModal'
import TechProfileCard from '../organisms/TechProfile/TechProfileCard'
import TechProfileStartCard from '../organisms/TechProfile/TechProfileStartCard'

const redirectToNotFound = (): void => {
  history.push(createPathWithLang(NetworkConstants.URL_NOT_FOUND))
}

const deleteModalMessage = (
  <p className={`modal-title`}>Are you sure the you want to delete all the data from this review?</p>
)

interface RadarChartData {
  values: number[]
  labels: string[]
}

export const TechProfile = (): JSX.Element => {
  const query = useQuery()
  const currentUser = useSelector<RootState, EmployeeDetails | null>(state => state.auth.user)
  const { formId } = useParams<{ formId: string }>()
  const [employeeId] = useState<number | undefined>(currentUser?.id)
  const [selectedFormId, setSelectedFormId] = useState<string>()
  const [currentReview, setCurrentReview] = useState<Review>()
  const [unfinishedReview, setUnfinishedReview] = useState<Review>()
  const [reviews, setReviews] = useState<Review[]>()
  const [selfEvaluation, setSelfEvaluation] = useState<Review>()
  const [allReviews, setAllReviews] = useState<Review[]>()
  const [employee, setEmployee] = useState<Employee>()
  const [radarDataSet, setRadarDataSet] = useState<Record<string, ChartDatasetType>>({})
  const [radarLabels, setRadarLabels] = useState<string[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [selfForm, setSelfForm] = useState<Form>()
  const [loading, setLoading] = useState<boolean>(true)
  const [allForms, setAllForms] = useState<Form[]>()
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)

  const EMAIL = `mailto:${CONTACT_EMAIL}`

  const requestSelfReview = async (employeeId: number) => {
    try {
      if (employeeId) {
        const requestedForms = await FormService.findAll()
        const techBrief = await ReviewService.findSelfReviewByEmployeeId(employeeId)
        const selfForm = requestedForms.find(form => form.id === techBrief.form.id)
        setSelfEvaluation(techBrief)
        setSelfForm(selfForm)
      }
    } catch (error) {
      console.error('Request service error: ' + error)
    }
  }

  const getRadarChartData = (quiz: AnsweredQuestion[]): RadarChartData => {
    const values: number[] = []
    const labels: string[] = []
    quiz.forEach((question: AnsweredQuestion) => {
      if (question.reportLayout?.type === ReportLayoutTypeEnum.CHART) {
        values.push(question.answer as number)
        if (!labels.includes(question.label)) {
          labels.push(question.label)
        }
      }
    })
    return { values, labels }
  }

  const setChartData = (reviews: Review[], selectedReview: Review): void => {
    let labels: string[] = []
    const data: Record<string, ChartDatasetType> = {}

    reviews.forEach((review: Review, index: number) => {
      const sortedAnswers = review.answers.sort(sortAnswersByOrder)
      const reviewResult: RadarChartData = getRadarChartData(sortedAnswers)

      if (reviewResult.values.length === 0) {
        return
      }

      let values: number[] = []
      if (reviewResult.labels.length > labels.length) {
        labels = reviewResult.labels
        values = reviewResult.values
      } else {
        // Entering here means that we have more labels than values
        // therefore we need to fill the blank values with 0s to have
        // as many values as labels.
        const diff = labels.length - reviewResult.values.length
        const filler = new Array(diff).fill(0)
        values = reviewResult.values.concat(filler)
      }

      const reviewDate = review.finishDate || ''
      const dataSetDisplayed = selectedReview ? review.id === selectedReview.id : index === reviews.length - 1
      const dataset: ChartDatasetType = {
        reviewDate,
        displayed: dataSetDisplayed,
        values,
        color: assignColor(index),
        reviewerName: review.reviewAuthor.name,
      }
      data[reviewDate] = dataset
    })
    setRadarLabels(labels)
    setRadarDataSet(data)
  }

  useEffect(() => {
    const requestEmployee = async () => {
      const employee = await EmployeeService.findOne(Number(employeeId))
      setEmployee(employee as unknown as Employee)
    }

    requestEmployee().catch(error => {
      console.error('Employee service error: ' + error.message)
      redirectToNotFound()
    })
  }, [employeeId])

  useEffect(() => {
    const requestAllReviews = async () => {
      try {
        if (employee) {
          const [rawReviews, requestedForms] = await Promise.all([
            ReviewService.findByEmployeeId(employee.id),
            FormService.findAll(),
          ])
          const finishedReviews = rawReviews.filter(review => review.finishDate)
          const filteredForms = requestedForms.filter(form =>
            finishedReviews.some(review => review.form.id === form.id)
          )
          setAllForms(filteredForms)
          setAllReviews(finishedReviews)
          setUnfinishedReview(rawReviews.find(review => !review.finishDate))
          if (formId) {
            setSelectedFormId(formId)
          } else if (filteredForms[0]) {
            setSelectedFormId(filteredForms[0].id?.toString())
          }

          const techBrief = await ReviewService.findSelfReviewByEmployeeId(employee.id)
          const selfForm = requestedForms.find(form => form.id === techBrief.form.id)
          setSelfEvaluation(techBrief)
          setSelfForm(selfForm)
        }
      } catch (error) {
        console.error('Request service error: ' + error)
      }
    }

    requestAllReviews().catch(console.error)
  }, [employee, formId])

  useEffect(() => {
    trackEvent(TrackingCategoryEnum.INDIVIUAL_REPORT, TrackingCategoryEventEnum.INDIVIUAL_REPORT.PAGE_ON_LOAD, '')
  }, [])

  useEffect(() => {
    if (allReviews && selectedFormId !== undefined) {
      const orderedReviews = cloneDeep(allReviews).sort(sortReviewsByCreationDate)
      const filteredReviews = orderedReviews.filter(review => review?.form?.id === parseInt(selectedFormId))
      setReviews(orderedReviews)
      let reviewToSelect
      if (query.get('reviewId')) {
        const reviewFromQuery = filteredReviews.find(review => {
          return review.id.toString() === query.get('reviewId')
        })
        reviewToSelect = reviewFromQuery || filteredReviews[filteredReviews.length - 1]
      } else {
        reviewToSelect = filteredReviews[filteredReviews.length - 1]
      }
      setCurrentReview(reviewToSelect)
      setChartData(filteredReviews, reviewToSelect)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReviews, selectedFormId])

  useEffect(() => {
    if (currentReview || allReviews) {
      setLoading(false)
    }
  }, [currentReview, allReviews])

  function sortReviewsByCreationDate(r1: Review, r2: Review): number {
    const d1 = moment(r1.createdDate)
    const d2 = moment(r2.createdDate)
    if (d1 > d2) {
      return 1
    }
    if (d1 < d2) {
      return -1
    }
    return 0
  }

  function sortAnswersByOrder(q1: AnsweredQuestion, q2: AnsweredQuestion): number {
    if (!q1.order || !q2.order) {
      return 1
    }
    if (q1.order === q2.order) {
      return 0
    }
    return q1.order > q2.order ? -1 : 1
  }

  const handleOnCreate = (): void => {
    if (employee) {
      history.push(createPathWithLang(NetworkConstants.URL_NEW_REVIEW.replace(':employeeId', `${employee.id}`)))
    }
  }

  const goToTeachBrief = (): void => {
    if (employee) {
      history.push(createPathWithLang(NetworkConstants.URL_TECH_EVALUATION))
    }
  }

  const handleOnEdit = (reviewId: string): void => {
    trackEvent(
      TrackingCategoryEnum.INDIVIUAL_REPORT,
      TrackingCategoryEventEnum.INDIVIUAL_REPORT.CLICK_EDIT_BTN,
      JSON.stringify({ reviewId: currentReview?.id })
    )
    if (currentReview || unfinishedReview) {
      history.push(createPathWithLang(NetworkConstants.URL_EDIT_REVIEW.replace(':reviewId', reviewId)))
    }
  }

  const handleDeleteReview = () => setShowDeleteModal(true)
  const handleCancelDelete = () => setShowDeleteModal(false)
  const handleConfirmDelete = async () => {
    try {
      if (currentReview?.id) {
        const reviewIdToDelete = currentReview.id
        await deleteReview(reviewIdToDelete)
        setShowDeleteModal(false)
        setAllReviews([])
        return
      }

      history.goBack()
    } catch (error) {
      console.error(error)
    }
  }

  const handleTechBriefUpdateButton = () => {
    trackEvent(
      TrackingCategoryEnum.TECH_EVALUATION,
      TrackingCategoryEventEnum.TECH_EVALUATION.CLICK_UPDATE_BTN,
      JSON.stringify({
        reviewId: selfEvaluation?.id,
      })
    )
    history.push(createPathWithLang(NetworkConstants.URL_TECH_EVALUATION))
  }

  const handlePublishReview = async (): Promise<void> => {
    try {
      if (selfEvaluation) await ReviewService.finishReview(selfEvaluation.id)
      history.push(createPathWithLang(NetworkConstants.URL_TECH_PROFILE))
    } catch (error) {
      setShowErrorModal(true)
      console.error(error)
    } finally {
      employee?.id && requestSelfReview(employee?.id)
    }
  }

  const deleteReview = async (id: number) => await ReviewService.deleteReview(id)

  if (!allReviews || loading) {
    return <Spinner />
  }

  return (
    <div className="container mx-auto">
      <div className="employee-results">
        {employee && (
          <div className="employee-results__content">
            <div className="mb-16">
              <h1 className="text-6xl text-dark-blue font-semibold mb-3 mt-5">{employee.name}</h1>
              <span className="text-3xl text-gray block mt-8">This is where you can update your profile</span>
            </div>
            <div>
              {currentReview && reviews && Object.keys(currentReview).length > 1 && (
                <div className="p-20 shadow-default rounded-2xl mb-12">
                  <span className="text-4xl font-bold text-dark-blue">Assessment</span>
                  <EmployeeResultsHistory
                    reviews={reviews}
                    currentReview={currentReview}
                    onChange={v => {
                      setCurrentReview(v)
                      setSelectedFormId(v.form.id?.toString())
                    }}
                    allForms={allForms || []}
                  />
                  <div className="employee-results__body mb-12">
                    <div className="employee-results__body-data">
                      <EmployeeResultsTeams employee={employee} />
                      <EmployeeResultsData employee={employee} review={currentReview} />
                    </div>
                    {Object.keys(radarDataSet).length && (
                      <div className="employee-results__body-chart">
                        <ChartRadar labels={radarLabels} datasets={Object.values(radarDataSet)} />
                      </div>
                    )}
                  </div>
                  <EmployeeResultsDetails employee={employee} review={currentReview} />
                  <div className="employee-results__footer flex items-center">
                    <AppButton type="info" onClick={() => handleOnEdit(currentReview.id.toString())}>
                      <PencilSVG color="white" /> Edit version
                    </AppButton>
                    <AppButton type="clear" onClick={handleOnCreate}>
                      <PlusIcon /> New version
                    </AppButton>
                  </div>
                  <button onClick={handleDeleteReview} className="flex items-center mt-4">
                    <GarbageSVG color="#005ECA" width={13} height={15} />
                    <span className="invisible lg:visible text-blue text-2xl font-semibold ml-3 font-manrope">
                      Delete review
                    </span>
                  </button>
                </div>
              )}
              {selfEvaluation && (
                <TechProfileCard
                  reviewTechProfile={selfEvaluation}
                  selfForm={selfForm || {}}
                  onUpdateReview={handleTechBriefUpdateButton}
                  onPublishReview={handlePublishReview}
                />
              )}
            </div>
            <div className="flex gap-24">
              {(!allReviews || !allReviews.length) && (
                <div className="w-1/2 justify-between items-center p-20 shadow-default rounded-2xl">
                  <div className="w-3/4 h-3/4 mb-10">
                    <span className="text-4xl font-bold text-dark-blue">📷 Selfie</span>
                    <span className="text-3xl text-stone-gray block mt-8">
                      Use the self-assessment tool to think about your recent progress. Where are you in your software
                      engineering journey? What areas should you focus on to keep developing?
                    </span>
                  </div>
                  <div className="mt-12">
                    {unfinishedReview ? (
                      <AppButton onClick={() => handleOnEdit(unfinishedReview.id.toString())}>
                        Continue review
                      </AppButton>
                    ) : (
                      <AppButton onClick={handleOnCreate}>Start now</AppButton>
                    )}
                  </div>
                </div>
              )}
              {!selfEvaluation && <TechProfileStartCard onStart={goToTeachBrief} />}
            </div>
          </div>
        )}
      </div>
      {showErrorModal && (
        <ErrorModal
          icon={<WarningIcon />}
          visible={showErrorModal}
          message={
            <p>
              <span className={`modal-title`}>Something went wrong while saving your answers.</span>
              <br />
              <span className="text-grey">
                <b>Try reloading the page and save again.</b> If the problem persists,{' '}
                <a href={EMAIL}>contact support</a>.
              </span>
            </p>
          }
          onClose={() => setShowErrorModal(false)}
        />
      )}
      <OptionsModal
        icon={<DeleteIcon />}
        secondButtonText="Delete"
        secondButtonType="error"
        firstButtonText="Cancel"
        firstButtonType="clear"
        visible={showDeleteModal}
        onFirstButtonClick={handleCancelDelete}
        onSecondButtonClick={handleConfirmDelete}
        onClose={handleCancelDelete}
        message={deleteModalMessage}
      />
    </div>
  )
}
