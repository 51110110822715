import { FormEvent, useEffect, useState } from 'react'
import Validator from 'validator'

import LogoSVG from '../../assets/svgs/Logo.svg'
import { AppButton } from '../atoms/AppButton/AppButton'
import TextLine from '../atoms/Forms/TextLine'
import { useSelector } from 'react-redux'
import { AuthState, loginToken, StorageConfiguration, logout } from '../../slices/auth-slice'
import { isFailure, isLoading } from '../../types/definitions/app-state'
import { RootState, useAppDispatch } from '../..'
import { useHistory, useParams } from 'react-router-dom'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { createPathWithLang } from '../../utils/languagePathUtils'
import Spinner from '../atoms/Spinner'
import { ConstantIDs } from '../../constants/employee'

const LoginTokenPage = (): JSX.Element => {
  const state = useSelector<RootState, AuthState>(state => state.auth)
  const dispatch = useAppDispatch()
  const { token, reviewId } = useParams<{ token: string; reviewId: string }>()
  const [emailValidation, setEmailValidation] = useState<boolean>(true)
  const [email, setEmail] = useState<string>('')
  const [helperText, setHelperText] = useState<string>('')
  const [isEmailError, setIsEmailError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [didLogout, setDidLogout] = useState<boolean>(false)
  const history = useHistory()

  useEffect(() => {
    dispatch(logout())
    setDidLogout(true)
    localStorage.setItem(StorageConfiguration.tokenStorageTag, token)
  }, [token, dispatch])

  useEffect(() => {
    setLoading(isLoading(state.status))
    const failure = isFailure(state.status)
    setIsEmailError(failure)
    if (failure) setHelperText('Incorrect email address or invalid token')
    if (didLogout && !loading && !isLoading(state.status) && state.user) {
      history.push(
        createPathWithLang(
          NetworkConstants.URL_INTRODUCTION +
            '?redirect=' +
            NetworkConstants.URL_EDIT_REVIEW.replace(ConstantIDs.REVIEW_ID_PATH_PLACEHOLDER, reviewId)
        )
      )
    }
  }, [state, loading])

  useEffect(() => {
    // TODO: Improve this error management
    if (!email) setIsEmailError(false)
    if (!email) setHelperText('')
  }, [email])

  const handleSignIn = (event: FormEvent): void => {
    event.preventDefault()
    if (emailValidation) {
      dispatch(loginToken({ email }))
    }
  }

  const checkEmail = (): void => {
    if (!Validator.isEmail(email)) {
      setIsEmailError(true)
    }
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="flex-1 container flex justify-center bg-white">
      <form className="flex flex-col justify-center w-176" onSubmit={handleSignIn}>
        <div className="w-full">
          <h1 hidden>Grapes | Your assessment tool</h1>
          <div className="border-solid border-b border-dark-blue pb-8 mb-12">
            <LogoSVG />
          </div>
          <p className="mb-8 font-bold text-7xl text-normal text-dark-blue">Welcome to Grapes</p>
          <p className="mb-8 font-normal mb-16 text-3xl text-normal text-dark-blue">
            For security reasons you will need to <br />
            validate your email address.
          </p>
        </div>
        <div className="flex flex-col pb-6">
          <label className="text-blue mb-2 font-medium text-2xl">Confirm your email</label>
          <TextLine
            className={`text-border max-w-full mb-0 ${isEmailError ? 'error' : ''}`}
            type={'email'}
            autoComplete={'email'}
            value={email}
            onChange={value => setEmail(value)}
            setValidation={setEmailValidation}
            placeholder="Write your email"
          />
          <span>
            <p className="text-error-red text-3xl md:text-4xl">{helperText}</p>
          </span>
        </div>
        <div className="button-container">
          <AppButton onClick={checkEmail} disabled={!email || loading}>
            Confirm
          </AppButton>
        </div>
      </form>
    </div>
  )
}
export default LoginTokenPage
