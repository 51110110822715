import { FC, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { CrossButton } from '../atoms/CrossButton'

interface PopupProps {
  visible: boolean
  onClose?: () => void
  showCloseButton?: boolean
  backdrop?: boolean
  backdropClassName?: string
  onBackdropClick?: () => void
  classname?: string
  containerClassName?: string
}

export const Popup: FC<PopupProps> = ({
  visible,
  onClose,
  showCloseButton = true,
  children,
  onBackdropClick,
  containerClassName = '',
}) => {
  const handleBackdropClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    onBackdropClick && onBackdropClick()
  }

  const handleClose = () => {
    onClose
      ? onClose()
      : (() => {
          return
        })()
  }

  return (
    <Dialog
      visible={visible}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      modal
      draggable={false}
      resizable={false}
      closeOnEscape={true}
      closable={true}
      onHide={handleClose}
      onMaskClick={handleBackdropClick}
      showHeader={false}
      className={`p-8 fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white border rounded-[16px] w-[752px] ${containerClassName}`}
      contentClassName="p-8"
      maskClassName="!z-[1000]"
    >
      <div>
        {showCloseButton && <CrossButton onClick={handleClose} />}
        <div>{children}</div>
      </div>
    </Dialog>
  )
}
