import { InputTextarea } from 'primereact/inputtextarea'
import { ChangeEvent, FC, KeyboardEvent, useRef } from 'react'
import classNames from 'classnames'

export interface AppTextareaProps {
  placeholder?: string
  label?: string
  value?: string
  disabled?: boolean
  helpText?: boolean
  warningText?: string
  maxLength?: number
  unfinished?: boolean
  required?: boolean
  onChange?: (inputValue: string) => void
  onSubmit?: (inputValue: string) => void
}

export const AppTextarea: FC<AppTextareaProps> = ({
  placeholder,
  label,
  value,
  disabled,
  helpText,
  warningText,
  unfinished,
  required = true,
  onChange,
  onSubmit,
  maxLength,
}: AppTextareaProps): JSX.Element => {
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    onChange?.(e.target.value)
  }

  const handleKeyUp = (keyPressed: KeyboardEvent): void => {
    if (keyPressed.key === 'Enter' && inputRef.current != null) {
      onSubmit?.(inputRef.current.value)
    }
  }

  return (
    <div
      className={classNames({
        'app-textarea-wrapper': true,
        'app-textarea-wrapper--unfinished': !value && unfinished,
      })}
    >
      {(label || !required) && (
        <div className="flex mb-8">
          {label && <span className="app-textarea-label">{label}</span>}
          {!required && <p className="optional-question ml-auto">Optional</p>}
        </div>
      )}
      <InputTextarea
        value={value}
        onChange={handleChange}
        autoResize
        className="app-textarea-textarea"
        placeholder={placeholder}
        onKeyUp={handleKeyUp}
        maxLength={maxLength}
        disabled={disabled}
      />
      {warningText ? (
        <span>
          <p className="app-textarea-warning-text">{warningText}</p>
        </span>
      ) : (
        helpText && (
          <p className="app-textarea-warning-text">
            <br />
          </p>
        )
      )}
    </div>
  )
}
