import moment from 'moment'
import { Moment } from 'moment'
import { PeriodCard, PeriodCardStatus } from '../../components/organisms/PeriodManagement/PeriodCards'
import { Period } from '../../types/definitions/Period'

export const DateFormat = 'MM/DD/YYYY'

const isoWithoutTimezone = 'YYYY-MM-DDTHH:mm:ss.SSS'

export enum AvailablePeriods {
  Current = 'current',
  Previous = 'previous',
}

export const periodToPeriodCard = (period: Period): PeriodCard => {
  const startDate = moment(period.startDate).startOf('day')
  const endDate = moment(period.endDate).endOf('day')
  const today = moment()

  const range = `${startDate.format(DateFormat)} - ${endDate.format(DateFormat)}`

  let status: string
  if (endDate.isBefore(today)) status = PeriodCardStatus.PAST
  else if (startDate.isAfter(today)) status = PeriodCardStatus.UPCOMING
  else status = PeriodCardStatus.ACTIVE

  let partial: PeriodCard = {
    period: period,
    title: period.name,
    range: range,
    status: status,
  }
  if (status === PeriodCardStatus.ACTIVE) {
    partial = {
      ...partial,
      timeLeft: timeDifferenceAsString(today, endDate),
    }
  }
  return partial
}

export const timeDifferenceAsString = (startDate: Moment, endDate: Moment): string => {
  const units: moment.unitOfTime.Base[] = ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds']
  const remainingDuration = moment.duration(endDate.diff(startDate))

  const timeUnits = units.reduce<string[]>((result, unit) => {
    const value = Math.floor(remainingDuration.as(unit))
    if (value) {
      remainingDuration.subtract(moment.duration(value, unit))
      const timeUnit = value === 1 ? unit.slice(0, -1) : unit
      result.push(`${value} ${timeUnit}`)
    }
    return result
  }, [])
  return timeUnits.slice(0, 2).join(' and ')
}

export const sortPeriods = (periods: Period[]): Period[] => {
  return periods.sort((a, b) => moment(b.startDate).diff(moment(a.startDate)))
}

export const dateStringToDate = (dateString: string): Date => {
  return new Date(Date.parse(dateString))
}

export const DateToPeriodRequestDateString = (date: Date): string => moment(date).format(isoWithoutTimezone)

export const checkDateIsInclusiveBetweenRange = (date: Date, startDate: Date, endDate: Date): boolean => {
  const momentDate = moment(date)
  return (
    momentDate.isSame(startDate, 'day') ||
    momentDate.isSame(endDate, 'day') ||
    momentDate.isBetween(startDate, endDate, 'day')
  )
}

export const checkDateRangeInclusiveOverlapsRange = (
  startDate1: Date,
  endDate1: Date,
  startDate2: Date,
  endDate2: Date
): boolean => {
  return (
    checkDateIsInclusiveBetweenRange(startDate1, startDate2, endDate2) ||
    checkDateIsInclusiveBetweenRange(endDate1, startDate2, endDate2)
  )
}
