import classNames from 'classnames'
import { ColumnProps } from 'primereact/column'
import { EMPLOYEE_HIGHLIGHT_MISMATCH, EMPLOYEE_HIGHLIGHT_MISMATCH_LITERAL } from '../../../../constants/employee'
import { Employee, getHighlightCount } from '../../../../services/EmployeeService'
import { TeamDetails } from '../../../../services/TeamService'

type rowData = { team: TeamDetails; employees: Employee[] }

const FIELD_PREFIX = 'highlight-'

const filterFieldSelection = (field: string): string => {
  if (field.match('highlight-')) {
    return 'highlight'
  }
  return field
}

const getHighlightFromField = (field: string): string => {
  const newField = field.replace(FIELD_PREFIX, '')
  if (newField === EMPLOYEE_HIGHLIGHT_MISMATCH) {
    return EMPLOYEE_HIGHLIGHT_MISMATCH_LITERAL
  }
  return newField
}

export const GeneralStatusTableBody = (rowData: rowData, props: ColumnProps): JSX.Element => {
  const defaultClassNames = {
    'text-ellipsis overflow-hidden text-dark-blue text-xl': true,
  }
  const spanClassName = classNames(defaultClassNames)

  const { team, employees } = rowData

  const selectedField = filterFieldSelection(props.field as string)

  switch (selectedField) {
    case 'name': {
      return (
        <div className="truncate leading-8">
          <span className={spanClassName}>{team.name}</span>
        </div>
      )
    }
    case 'highlight': {
      return (
        <div className="flex justify-center">
          {getHighlightCount(employees, getHighlightFromField(props.field as string))}
        </div>
      )
    }
    default: {
      const result = team[props.field as keyof TeamDetails] ?? '-'
      return (
        <div className="table-text-overflow w-full">
          <span>{Number.isNaN(result) ? '-' : result}</span>
        </div>
      )
    }
  }
}
