export const infoTechnologiesLevels = {
  title: 'Rate technologies',
  levels: [
    {
      level: 'Basic',
      text: 'You’ve tried this tech before and can understand its principles and when to use it. You could use it on a new project, although it will require more learning.',
      color: 'cyan',
    },
    {
      level: 'Medium',
      text: 'You’ve created a serious project using this tech. You can apply that knowledge to new projects.',
      color: 'ruby',
    },
    {
      level: 'Advanced',
      text: 'You’ve been working on one or more serious projects using this tech for a long time. You know everything required to work with it and solve complex problems, and you could also train others.',
      color: 'purple',
    },
  ],
}
