import { DataTable, DataTableExpandedRows, DataTableRowClickEvent } from 'primereact/datatable'
import { Column, ColumnProps } from 'primereact/column'
import { EmployeeTableHeader } from '../molecules/EmployeeTable/EmployeeTableHeader'
import { TeamTableBody } from '../molecules/EmployeeTable/TeamTableBody'
import { Employee, EmployeeScores } from '../../services/EmployeeService'
import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import { useEffect, useState } from 'react'
import { EmployeeSimpleReviewsTable } from './EmployeeSimpleReviewsTable'
import SmallArrowSVG from '../atoms/Icons/SmallArrow.svg'
import { ReviewSimpleProjection } from '../../api/openapi'
import { hasEmployeeReviews } from '../../utils/employeeUtils'

interface ColumnTopic {
  label: string
  id: string
}

interface TeamTablePropsType {
  employeeScores: EmployeeScores[]
  selectedSort?: { property: string; order: SortOrderEnum }
  onSortSelected?: (field: string, newSortOrder: SortOrderEnum) => void
  handleOnEmployeeClicked?: (employeeId: number) => void
  handleExpandedRowClicked?: (row: ReviewSimpleProjection, employeeId: number) => void
}

export const TeamTable = ({
  employeeScores,
  selectedSort,
  onSortSelected,
  handleOnEmployeeClicked,
  handleExpandedRowClicked,
}: TeamTablePropsType): JSX.Element => {
  const [columnTopics, setColumnTopics] = useState<ColumnTopic[]>()

  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | undefined>(undefined)

  const onEmployeeSelected = (employee: Employee): void => {
    handleOnEmployeeClicked?.(employee.id)
  }

  const handleSortSelected = (field: string) => (newSortOrder: SortOrderEnum) => {
    onSortSelected?.(field, newSortOrder)
  }

  const toggleRowExpand = (r: EmployeeScores) => {
    let newExpandedRows = { ...expandedRows }
    if (newExpandedRows?.[r.id as number]) {
      delete newExpandedRows[r.id as number]
    } else {
      if (newExpandedRows) {
        newExpandedRows[r.id as number] = true
      } else {
        newExpandedRows = {}
        newExpandedRows[r.id as number] = true
      }
    }
    setExpandedRows(newExpandedRows)
  }

  const onRowClick = (e: DataTableRowClickEvent) => {
    onEmployeeSelected(e.data as Employee)
  }

  const onExpandedRowClick = (row: ReviewSimpleProjection, employeeId: number) => {
    handleExpandedRowClicked?.(row, employeeId)
  }

  useEffect(() => {
    const parsedTopics: ColumnTopic[] = []
    parsedTopics.push({ id: 'avgAttitude', label: 'Attitude' })
    parsedTopics.push({ id: 'avgCommunication', label: 'Communication' })
    parsedTopics.push({ id: 'avgAptitude', label: 'Aptitude' })
    setColumnTopics(parsedTopics)
  }, [employeeScores])

  return (
    <DataTable
      className="app-table-container"
      tableClassName="app-table team-table"
      expandedRows={expandedRows}
      rowClassName={(employee: EmployeeScores) => ({
        'user-list-table-row --done': hasEmployeeReviews(employee),
        'user-list-table-row --todo': !hasEmployeeReviews(employee),
        'bg-green-cell': employee.skipped,
        'cursor-pointer': true,
      })}
      onRowClick={onRowClick}
      value={employeeScores}
      dataKey="id"
      editMode="row"
      responsiveLayout="scroll"
      rowExpansionTemplate={(rowData: EmployeeScores) => (
        <div className="flex">
          <div className="flex-[1] min-w-[15.1%]"></div>
          <div className="flex-[10]">
            <EmployeeSimpleReviewsTable handleOnRowClicked={onExpandedRowClick} employeeId={rowData.id as number} />
          </div>
        </div>
      )}
    >
      <Column
        headerClassName="user-list-table-header w-[15rem] max-w-[130px]"
        field="name"
        className="flex-[1.3]"
        header={EmployeeTableHeader({
          title: 'Name',
          sortable: selectedSort?.property === 'name' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('name'),
        })}
        body={TeamTableBody}
      />
      <Column
        headerClassName="max-w-[2rem]"
        field="expander"
        className="max-w-[2rem] justify-center p-0 flex-row "
        body={rowData =>
          rowData.reviewsAmount > 1 ? (
            <div
              className="h-[100%] w-[100%] flex justify-center"
              onClick={e => {
                e.stopPropagation()
                toggleRowExpand(rowData)
              }}
            >
              <SmallArrowSVG
                direction={expandedRows && expandedRows[rowData.id] === true ? 'vertical' : 'horizontal'}
                transition="200ms"
                horizontalDirection="right"
              />
            </div>
          ) : null
        }
      />
      <Column
        headerClassName="user-list-table-header w-[15rem] max-w-[130px]"
        field="reviewer"
        header={EmployeeTableHeader({
          title: 'Reviewer',
          sortable: selectedSort?.property === 'reviewer' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('reviewer'),
        })}
        body={(rowData: EmployeeScores, props: ColumnProps) => {
          return TeamTableBody(rowData, props, toggleRowExpand)
        }}
      />
      <Column
        headerClassName="user-list-table-header w-[15rem] max-w-[130px]"
        field="lastReviewType"
        header={EmployeeTableHeader({
          title: 'Form Used',
          sortable: selectedSort?.property === 'lastReviewType' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('lastReviewType'),
        })}
        body={(rowData: EmployeeScores, props: ColumnProps) => {
          return TeamTableBody(rowData, props, toggleRowExpand)
        }}
      />
      {columnTopics?.map(topic => (
        <Column
          key={topic.id}
          headerClassName="user-list-table-header flex justify-center w-[15rem] max-w-[130px]"
          field={topic.id}
          header={
            <EmployeeTableHeader
              title={topic.label}
              sortable={selectedSort?.property === topic.id ? { sortOrder: selectedSort.order } : undefined}
              onSortSelected={handleSortSelected(topic.id)}
            />
          }
          body={TeamTableBody}
        />
      ))}
    </DataTable>
  )
}
