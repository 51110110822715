import React, { useEffect, useState } from 'react'
import { Employee } from '../../services/EmployeeService'
import { AppSelect } from '../atoms/AppSelect/AppSelect'
import classNames from 'classnames'
import { ReviewService } from '../../services/ReviewService'
import Spinner from '../atoms/Spinner'
import SuccessModal from '../molecules/SuccessModal'
import { Popup } from '../molecules/Popup'
import EmployeeReviewFormSelectionStep from './EmployeeReviewFormSelectionStep'
import { Form } from '../../types/definitions/form'
import { FormService } from '../../services/FormService'
import { sortFormCategories } from '../../utils/forms/FormUtils'
import { FormCategory } from '../../types/definitions/formCategory'
import { getFormById } from '../../utils/forms/FormUtils'
import { getEmployeeById } from '../../utils/employeeUtils'
import PencilSVG from '../atoms/Icons/Pencil.svg'

interface RequestReviewModalProps {
  employee: Employee
  allEmployees: Employee[]
  reviewersId: number[]
  onClose: (requestAdded: boolean) => void
}

interface SelectOption {
  value: string
  label: string
}

enum ModalStateEnum {
  selectReviewer = 'Select reviewer',
  selectForm = 'Select form type',
  confirm = 'Confirm request',
}

export const RequestReviewModal = ({
  employee,
  allEmployees,
  reviewersId,
  onClose,
}: RequestReviewModalProps): JSX.Element => {
  const [showAddModal, setShowAddModal] = useState<boolean>(true)
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false)
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>(0)
  const [employeeSelectOptions, setEmployeeSelectOptions] = useState<SelectOption[]>([])
  const [selectedForm, setSelectedForm] = useState<Form>()
  const [modalState, setModalState] = useState(ModalStateEnum.selectReviewer)
  const [lastFormUsed, setLastFormUsed] = useState<Form>()
  const [formCategories, setFormCategories] = useState<FormCategory[]>()
  const [forms, setForms] = useState<Form[]>()

  useEffect(() => {
    const _employeeSelectOptions: SelectOption[] = []
    allEmployees?.forEach(current => {
      if (current.id !== employee.id && !reviewersId.some(reviewerId => reviewerId === current.id)) {
        _employeeSelectOptions.push({
          value: current.id.toString(),
          label: current.name,
        })
      }
    })
    setEmployeeSelectOptions(_employeeSelectOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEmployees])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      // here we get the form categories and forms when the component mounts
      // but it would be ideal if we can get this once into redux
      // and reuse that, because forms and form categories rarely change
      // so we would only retrieve them once per page load
      const formCategories = await FormService.findAllByCategory('review')
      setFormCategories(sortFormCategories(formCategories))

      const forms = await FormService.findAll('review')
      setForms(forms)

      const rawReviews = await ReviewService.findByEmployeeId(employee.id)
      const finishedReviews = rawReviews.filter(review => review.finishDate)
      const lastForm = finishedReviews[finishedReviews.length - 1]?.form
      setLastFormUsed(lastForm)
    }
    fetchData().catch(console.error)
  }, [])

  useEffect(() => {
    setSelectedForm(lastFormUsed)
  }, [lastFormUsed])

  const handleConfirmAdd = () => {
    if (!isConfirmLoading && selectedForm?.id) {
      setShowAddModal(false)
      setShowSuccessModal(true)
      setIsConfirmLoading(true)
      ReviewService.createReviewRequest(selectedEmployeeId, employee.id, selectedForm.id).then(value => {
        setIsConfirmLoading(false)
      })
    }
  }

  const handlePopupClosed = () => {
    onClose(false)
  }

  const handleSuccessModalCLosed = () => {
    onClose(true)
  }

  const stopPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const getSelectedEmployeeName = (): string => {
    return allEmployees.filter(e => e.id === selectedEmployeeId)[0]?.name || ''
  }

  const handleFormCheck = (formId: number) => {
    setSelectedForm(getFormById(formId, forms ?? []))
  }

  const successModalMessage = !isConfirmLoading ? (
    <p>
      <span className="text-[2.5rem]">Review request successfully sent.</span>
      <br />
      <span className="text-stone-gray">
        An email granting access will be sent to <b>{getSelectedEmployeeName()}</b>.
      </span>
    </p>
  ) : (
    <div className="flex flex-1 justify-center mt-32">
      <Spinner />
    </div>
  )

  const modalHeader = (
    <>
      <p className="text-4xl mb-5 capitalize">Add new reviewer</p>
      <p className="text-3xl text-blue">{modalState}</p>
      <div className="flex flex-row gap-x-2 text-xl p-6 my-8 rounded-2xl bg-[#F8FDFF] items-center">
        <div className="flex flex-row items-center gap-x-2">
          <div
            className={classNames(
              'w-10 h-10 text-white border-[1px] border-solid rounded-full flex flex-col text-center justify-center',
              {
                'border-blue bg-review-request-status-todo': modalState === ModalStateEnum.selectReviewer,
                'border-pale-green bg-review-request-status-finished': modalState !== ModalStateEnum.selectReviewer,
              }
            )}
          >
            1
          </div>
          <span>Select reviewer</span>
        </div>
        <div
          className={classNames('flex flex-row items-center gap-x-2', {
            'opacity-30': modalState === ModalStateEnum.selectReviewer,
          })}
        >
          <div
            className={classNames('w-[76px] h-[1px] mx-2', {
              'bg-blue': modalState !== ModalStateEnum.confirm,
              'bg-pale-green': modalState === ModalStateEnum.confirm,
            })}
          ></div>
          <div
            className={classNames(
              'w-10 h-10 text-white border-[1px] border-solid rounded-full flex flex-col text-center justify-center',
              {
                'border-blue bg-review-request-status-todo': modalState !== ModalStateEnum.confirm,
                'border-pale-green bg-review-request-status-finished': modalState === ModalStateEnum.confirm,
              }
            )}
          >
            2
          </div>
          <span>Select form type</span>
        </div>
        <div
          className={classNames('flex flex-row items-center gap-x-2', {
            'opacity-30': modalState !== ModalStateEnum.confirm,
          })}
        >
          <div className={classNames('w-[76px] h-[1px] mx-2 bg-blue', {})}></div>
          <div className="w-10 h-10 text-white border-[1px] border-solid rounded-full flex flex-col text-center justify-center border-blue bg-review-request-status-todo">
            3
          </div>
          <span>Confirm request</span>
        </div>
      </div>
    </>
  )

  return (
    <>
      <Popup visible={showAddModal} onClose={handlePopupClosed} classname="">
        {/* onClick={stopPropagation} */}
        <div className="flex flex-col items-center gap-y-2">
          {modalHeader}
          {modalState === ModalStateEnum.selectReviewer && (
            <>
              <p className="text-2xl">
                First, select a <b>reviewer</b> for {employee.name}
              </p>
              <AppSelect
                className="w-2/5 my-6 z-10"
                filter={true}
                options={employeeSelectOptions || []}
                onChange={value => setSelectedEmployeeId(Number(value))}
                value={selectedEmployeeId.toString()}
                placeholder="Select reviewer"
              />
              <div>
                <button
                  onClick={handlePopupClosed}
                  className="w-52 mr-3 bg-just-gray px-16 py-3 rounded-xl text-2xl font-semibold hover:text-bold text-dark-blue"
                >
                  Cancel
                </button>
                <button
                  onClick={() => setModalState(ModalStateEnum.selectForm)}
                  disabled={!selectedEmployeeId || isConfirmLoading}
                  className={classNames(
                    'w-52 px-16 py-3 rounded-xl text-2xl font-semibold hover:text-bold bg-blue text-white',
                    {
                      'opacity-25': !selectedEmployeeId && !isConfirmLoading,
                    }
                  )}
                >
                  Next
                </button>
              </div>
            </>
          )}
          {modalState === ModalStateEnum.selectForm && (
            <>
              <p className="text-2xl">
                Now select the <b>form type to {employee.name}</b>
              </p>
              <EmployeeReviewFormSelectionStep
                employeeId={String(selectedEmployeeId)}
                formCategories={formCategories ?? []}
                lastFormUsed={lastFormUsed}
                onFormCheck={handleFormCheck}
                embedded
                disableSkipAssessment
                alreadySelectedForm={selectedForm?.id}
              />
              <div>
                <button
                  onClick={() => setModalState(ModalStateEnum.selectReviewer)}
                  className="w-52 mr-3 bg-just-gray px-16 py-3 rounded-xl text-2xl font-semibold hover:text-bold text-dark-blue"
                >
                  Back
                </button>
                <button
                  onClick={() => setModalState(ModalStateEnum.confirm)}
                  disabled={!selectedForm}
                  className={classNames(
                    'w-52 px-16 py-3 rounded-xl text-2xl font-semibold hover:text-bold bg-blue text-white',
                    {
                      'opacity-25': !selectedForm,
                    }
                  )}
                >
                  Next
                </button>
              </div>
            </>
          )}
          {modalState === ModalStateEnum.confirm && (
            <>
              <div className="w-9/12 text-2xl leading-8 mb-6">
                <p className="text-center">
                  It is important that you <b>review the details of your request</b> because once you have added this
                  new reviewer you will not be able to remove him/her. If everything is correct,&nbsp;
                  <b>please confirm the request.</b>
                </p>
                <br />
                <p>
                  <b>Employee to review</b>
                </p>
                <p>{employee.name}</p>
                <br />
                <p>
                  <b>New reviewer</b>
                </p>
                <div className="flex flex-row gap-3">
                  <span>{getEmployeeById(selectedEmployeeId, allEmployees)?.name}</span>
                  <button
                    title={'Edit reviewer'}
                    className="flex justify-center items-center gap-1 text-blue font-semibold"
                    onClick={() => setModalState(ModalStateEnum.selectReviewer)}
                  >
                    <PencilSVG width={12} height={12} />
                    Edit
                  </button>
                </div>
                <br />
                <p>
                  <b>Form type selected</b>
                </p>
                <div className="flex flex-row gap-3">
                  <span>{selectedForm?.name}</span>
                  <button
                    title={'Edit form type'}
                    className="flex justify-center items-center gap-1 text-blue font-semibold"
                    onClick={() => setModalState(ModalStateEnum.selectForm)}
                  >
                    <PencilSVG width={12} height={12} />
                    Edit
                  </button>
                </div>
              </div>
              <div>
                <button
                  onClick={() => setModalState(ModalStateEnum.selectForm)}
                  className="w-52 mr-3 bg-just-gray px-16 py-3 rounded-xl text-2xl font-semibold hover:text-bold text-dark-blue"
                >
                  Back
                </button>
                <button
                  onClick={handleConfirmAdd}
                  className="w-52 px-16 py-3 rounded-xl text-2xl font-semibold hover:text-bold bg-blue text-white"
                >
                  Confirm
                </button>
              </div>
            </>
          )}
        </div>
      </Popup>
      <SuccessModal
        visible={showSuccessModal}
        onClose={handleSuccessModalCLosed}
        loading={isConfirmLoading}
        message={successModalMessage}
      />
    </>
  )
}
