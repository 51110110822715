import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { v4 } from 'uuid'
import { RootState, useAppDispatch } from '../..'
import useNextPrevKeyboard from '../../hooks/useNextPrevKeyboard'
import { useQuery } from '../../hooks/useQuery'
import { useTopics } from '../../hooks/useTopics'
import { Employee } from '../../services/EmployeeService'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../services/EventTrackingService'
import { FormService } from '../../services/FormService'
import { AnswerToBeAddedToReview, ReviewService } from '../../services/ReviewService'
import { finishTutorial, hideTutorial, showHelpId } from '../../slices/help-slice'
import { AnswerT } from '../../types/definitions/answer'
import { Form } from '../../types/definitions/form'
import { AnsweredQuestion, Question, QuestionTopic } from '../../types/definitions/question'
import { Review } from '../../types/definitions/review'
import { QuestionTypeEnum } from '../../types/enums/question-type.enum'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { fillAnswersRandomly } from '../../utils/questionUtils'
import { AppButton } from '../atoms/AppButton/AppButton'
import { ArrowIcon } from '../atoms/Icons/ArrowIcon'
import DeleteIcon from '../atoms/Icons/DeleteIcon.svg'
import RestartIcon from '../atoms/Icons/RestartIcon'
import WarningIcon from '../atoms/Icons/WarningIcon.svg'
import Spinner from '../atoms/Spinner'
import { CategorySelector } from '../molecules/CategorySelector/CategorySelector'
import ErrorModal from '../molecules/ErrorModal'
import { NavigationBlocker } from '../molecules/ExitBlocker/NavigationBlocker'
import HelpTooltip from '../molecules/HelpTooltip'
import OptionsModal from '../molecules/OptionsModal'
import { MultiSelectQuestion } from '../molecules/Questions/MultiSelectQuestion/MultiSelectQuestion'
import { RateQuestion } from '../molecules/Questions/RateQuestion/RateQuestion'
import SuccessModal from '../molecules/SuccessModal'
import TechnologiesLevelInfo from '../molecules/TechnologiesLevelInfo'
import { TutorialModal } from '../molecules/TutorialModal'
import { NextPrevArrows } from '../organisms/NextPrevArrows'
import { CONTACT_EMAIL } from '../../constants/general'

const TECH_EVALUATION_FORM_MAIN_PATH = RegExp(/\/[a-z]+\/(tech-evaluation).*/)
const EMAIL = `mailto:${CONTACT_EMAIL}`

const deleteModalMessage = (
  <p className="modal-title">
    Are you sure the you want to delete all the data from this review and restart the evaluation?
  </p>
)

const exitWithoutChangesModalMessage = (
  <p>
    <span className={`modal-title`}> Are you sure you want to leave without saving?</span>
    <br />
    <b>All the data from this review will be lost.</b>
  </p>
)

const TutorialIntroduction = () => {
  return (
    <div className="container flex justify-center my-auto">
      <div className="flex flex-col justify-center">
        <div className="w-full text-center leading-[3rem]">
          <p className="text-6xl font-medium mb-16">Let's have a quick intro before starting</p>
          <div className="text-3xl font-normal mb-12 flex flex-col gap-8">
            <p>
              In this tool you can <b>update your technical profile</b>: what skills (programming languages, frameworks,
              etc.) you already know, regardless of whether you are an expert or a beginner, and which ones you would
              love to teach/learn.
            </p>
            <p>
              It takes 5 minutes on average. The information you share will be used to{' '}
              <b>develop initiatives to help you grow as a professional</b> and <b>build better and stronger teams</b>.
            </p>
            <p>Let's get started!</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export const TechEvaluation = (): JSX.Element => {
  const query = useQuery()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const currentTopicToDisplay = query.get('topic')
  const user = useSelector<RootState, Employee>(state => state.auth.user as Employee)
  const tutorialCompleted = useSelector<RootState, boolean>(state => state.help.techProfileCompleted)
  const divContentQuestionsRef = useRef<HTMLDivElement>(null)

  const [currentReview, setCurrentReview] = useState<Review>()
  const [groupedQuestions, setGroupedQuestions] = useState<Record<string, Question[]>>({})
  const [topics, setTopics] = useState<QuestionTopic[]>([])
  const [indexedAnswers, setIndexedAnswers] = useState<Record<string, AnswerT>>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [currentForm, setCurrentForm] = useState<Form>()
  const [introductionView, setIntroductionView] = useState<boolean>(tutorialCompleted)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showBackToTop, setShowBackToTop] = useState(false)
  const [showModalInfoTechLevels, setShowModalInfoTechLevels] = useState<boolean>(false)

  const { currentTopic, handleBackToPreviousTopic, handleGoToNextTopic, isFirstTopic, isLastTopic, hasElementFocus } =
    useTopics(topics, currentTopicToDisplay, divContentQuestionsRef)

  const isPreviousDisabled = hasElementFocus || isFirstTopic
  const isNextDisabled = hasElementFocus || isLastTopic

  useNextPrevKeyboard({
    isPreviousDisabled,
    isNextDisabled,
    handleGoToNextTopic,
    handleBackToPreviousTopic,
  })

  useEffect(() => {
    dispatch(hideTutorial())
    void _initReview()
    trackEvent(TrackingCategoryEnum.TECH_EVALUATION, TrackingCategoryEventEnum.TECH_EVALUATION.PAGE_ON_LOAD, '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentForm && currentForm.id !== undefined) {
      renderForm(currentForm.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentForm])

  useEffect(() => {
    const questionsColumn = divContentQuestionsRef.current
    let columnHeight = 0
    if (questionsColumn) {
      for (const child of questionsColumn.children) {
        columnHeight += child.clientHeight
      }
    }
    setShowBackToTop(columnHeight > window.innerHeight)
  }, [currentTopic])

  const _initReview = async (): Promise<void> => {
    try {
      setLoading(true)
      const review = await ReviewService.findSelfReview().catch(error => console.warn(error))
      if (review) setCurrentReview(review)
      const form = await FormService.findAll('self')
      setCurrentForm(form[0])
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const renderForm = async (id: number) => {
    setLoading(true)
    const currentForm = await FormService.findById(id)
    const allQuestions = currentForm.questions || []
    const sortedQuestions = _sortQuestions(allQuestions)
    const _groupedQuestions = _groupQuestionsByTopic(allQuestions)
    initializeAnswers(allQuestions, currentReview?.answers)
    setGroupedQuestions(_groupedQuestions)
    const sortedTopics = _sortTopics(sortedQuestions)
    // FIXME: Discuss an alternative way to display this information
    sortedTopics.forEach(topic => {
      topic.title = _groupedQuestions[topic.name][0].preTitle
      topic.subTitle = _groupedQuestions[topic.name][0].description
    })
    setTopics(sortedTopics)
    setLoading(false)
  }

  let fillAnswersClickCounter = 0
  const handleAutoFillClickEvent = () => {
    fillAnswersClickCounter++
    if (fillAnswersClickCounter > 4) {
      setLoading(true)
      const questions: Question[] = Object.values(groupedQuestions).flat()
      const newIndexedAnswers = JSON.parse(JSON.stringify(indexedAnswers))
      fillAnswersRandomly(questions, newIndexedAnswers)
      setIndexedAnswers({ ...newIndexedAnswers })
      fillAnswersClickCounter = 0
      handleCategorySelected(currentTopic.name)
      setLoading(false)
    }
    if (fillAnswersClickCounter === 1) {
      setTimeout(() => {
        fillAnswersClickCounter = 0
      }, 2000)
    }
  }

  const handleCategorySelected = (categorySelected: string): void => {
    const topicIdx = topics.findIndex(({ name }) => categorySelected === name)
    changeTopic(topicIdx)
  }

  const getCompletedCategories = (): string[] => {
    return topics.reduce<string[]>((reminder, topic) => {
      const topicQuestions = groupedQuestions[topic.name]
      const topicAnswers = topicQuestions.map(question => indexedAnswers[question.id])
      const topicAnswersCompleted = areAnyQuestionsAnswered(topicAnswers)
      return topicAnswersCompleted ? [...reminder, topic.name] : reminder
    }, [])
  }

  const areAnyQuestionsAnswered = (answers: AnswerT[] = []): boolean => {
    return answers.some(answer => {
      return answer.answer !== undefined || answer.skipped
    })
  }

  const changeTopic = (topicIdx: number) => {
    trackEvent(
      TrackingCategoryEnum.TECH_EVALUATION,
      TrackingCategoryEventEnum.TECH_EVALUATION.CLICK_CATEGORY_BTN,
      JSON.stringify({ category: topicIdx })
    )
    query.set('topic', `${topicIdx}`)
    history.replace({
      search: query.toString(),
    })
  }

  const _sortTopics = (questions: Question[] = []): QuestionTopic[] => {
    const topicsObj: Record<string, QuestionTopic> = questions.reduce(
      (topics, { topic }) => (topic?.order ? { ...topics, [topic.order]: topic } : topics),
      {}
    )
    return Object.values(topicsObj)
  }

  const _sortQuestions = (questions: Question[] = []): Question[] => {
    const orderedByName = questions.filter(question => question.type === QuestionTypeEnum.RATE_SELECT)
    const orderedByOrder = questions.filter(question => question.type === QuestionTypeEnum.PILL_SELECT)
    orderedByName.sort((a, b): number => {
      if (!(a.label !== undefined && b.label !== undefined) || a.label === b.label) return b.type.localeCompare(a.type)
      return a.label > b.label ? 1 : -1
    })
    orderedByOrder.sort((a, b): number => {
      if (!(a.order !== undefined && b.order !== undefined) || a.order === b.order) return b.type.localeCompare(a.type)
      return a.order > b.order ? 1 : -1
    })
    return [...orderedByName, ...orderedByOrder]
  }

  const _groupQuestionsByTopic = (questions: Question[] = []): Record<string, Question[]> => {
    const _groupedQuestions = {} as Record<string, Question[]>
    _sortQuestions(questions).forEach(question => {
      const topic = question.topic?.name
      if (topic) {
        _groupedQuestions[topic] = [...(_groupedQuestions[topic] || []), question]
      }
    })

    return _groupedQuestions
  }

  const arrowLabelProps = { ...(isLastTopic && { nextArrowLabel: 'Finish' }) }

  const initializeAnswers = (_questions: Question[], answeredQuestions: AnsweredQuestion[] = []): void => {
    const indexedAnsweredQuestions: Record<
      string,
      { value: AnsweredQuestion['answer']; skipped: AnsweredQuestion['skipped'] }
    > = {}
    answeredQuestions.forEach(q => {
      indexedAnsweredQuestions[q.id] = { value: q.answer, skipped: q.skipped }
    })
    const _indexedAnswers: Record<string, AnswerT> = {}
    _questions.forEach(q => {
      _indexedAnswers[q.id] = {
        questionId: q.id,
        answer: indexedAnsweredQuestions[q.id]?.value as AnswerT['answer'],
        weight: q.weight,
        skipped: indexedAnsweredQuestions[q.id]?.skipped || q.skipped,
      }
    })
    setIndexedAnswers(_indexedAnswers)
  }

  const handleQuestionAnswered = (questionId: number) => (value: unknown) => {
    const answer = indexedAnswers[questionId]
    if (value === '' && currentReview?.answers.some(answer => answer.id === questionId) === false) {
      answer.answer = undefined
      setIndexedAnswers({ ...indexedAnswers })
      return
    }
    answer.answer = value as AnswerT['answer']
    setIndexedAnswers({ ...indexedAnswers })
  }

  const trackSaveEvent = (ansersToAdd: AnswerToBeAddedToReview[], finish: boolean) => {
    const details = {
      totalQuestions: groupedQuestions[currentTopic?.name || ''].length,
      answeredQuestions: ansersToAdd.length,
      reviewID: currentReview?.id,
      category: currentTopic?.name,
    }
    const category = finish
      ? TrackingCategoryEventEnum.TECH_EVALUATION.CLICK_FINISH_REVIEW_BTN
      : TrackingCategoryEventEnum.TECH_EVALUATION.CLICK_SAVE_BTN
    trackEvent(TrackingCategoryEnum.TECH_EVALUATION, category, JSON.stringify(details))
  }

  const handleSaveDraft = async (finish = true): Promise<void> => {
    try {
      setLoading(true)
      await saveReview()
      history.push(createPathWithLang(NetworkConstants.URL_TECH_PROFILE))
    } catch (error) {
      setShowErrorModal(true)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const saveReview = async (finish = false): Promise<Review> => {
    const currentReviewId = currentReview
      ? currentReview?.id
      : await ReviewService.createEmptyReview(user.id as number, currentForm?.id as number)

    const indexedSavedAnswers: Record<string, AnsweredQuestion> = {}
    ;(currentReview?.answers || []).forEach(savedAnswer => {
      indexedSavedAnswers[savedAnswer.id] = savedAnswer
    })
    const unsavedAnswersToSave: AnswerToBeAddedToReview[] = []
    Object.entries(indexedAnswers).forEach(([answerId, answer]) => {
      if (
        answer.answer === undefined ||
        JSON.stringify(indexedSavedAnswers[answerId]?.answer) === JSON.stringify(answer?.answer)
      ) {
        return
      }
      const answerToSave: AnswerToBeAddedToReview = {
        review: currentReviewId,
        question: answer.questionId,
        answer: typeof answer.answer === 'string' ? answer.answer : JSON.stringify(answer.answer),
        skipped: false,
      }
      unsavedAnswersToSave.push(answerToSave)
    })
    trackSaveEvent(unsavedAnswersToSave, finish)
    await ReviewService.saveAnswers(currentReviewId, unsavedAnswersToSave)
    const savedReview = await ReviewService.findOne(currentReviewId)
    setCurrentReview(savedReview)
    return savedReview
  }

  const handleFinishReview = async (): Promise<void> => {
    try {
      setLoading(true)
      const savedReview = await saveReview(true)
      await ReviewService.finishReview(savedReview.id)
      history.push(createPathWithLang(NetworkConstants.URL_TECH_PROFILE))
    } catch (error) {
      setShowErrorModal(true)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGoTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  const handleConfirmExit = (path: string) => {
    history.push({
      pathname: path,
    })
  }

  const checkIfExistUnsavedAnswers = (indexedAnswers: Record<string, AnswerT>, currentReview?: Review) => {
    const indexedSavedAnswers: Record<string, AnsweredQuestion> = {}

    ;(currentReview?.answers || []).forEach(savedAnswer => {
      indexedSavedAnswers[savedAnswer.id] = savedAnswer
    })

    return Object.entries(indexedAnswers).some(([answerId, answer]) => {
      return !(
        answer.answer === undefined ||
        JSON.stringify(indexedSavedAnswers[answerId]?.answer) === JSON.stringify(answer?.answer)
      )
    })
  }

  const handleCanExit = (): boolean => {
    const existUnsavedAnswers = checkIfExistUnsavedAnswers(indexedAnswers, currentReview)
    return !existUnsavedAnswers
  }

  const handleDeleteReview = () => {
    trackEvent(
      TrackingCategoryEnum.TECH_EVALUATION,
      TrackingCategoryEventEnum.TECH_EVALUATION.CLICK_DELETE_REVIEW_LINK,
      ''
    )
    setShowDeleteModal(true)
  }

  const handleCancelDelete = () => {
    trackEvent(
      TrackingCategoryEnum.TECH_EVALUATION,
      TrackingCategoryEventEnum.TECH_EVALUATION.CLICK_START_OVER_BTN,
      JSON.stringify({ selectedAction: 'cancel' })
    )
    setShowDeleteModal(false)
  }

  const handleConfirmDelete = async () => {
    try {
      trackEvent(
        TrackingCategoryEnum.TECH_EVALUATION,
        TrackingCategoryEventEnum.TECH_EVALUATION.CLICK_START_OVER_BTN,
        JSON.stringify({ selectedAction: 'confirm' })
      )
      setIndexedAnswers({})
      if (currentReview?.id) {
        const reviewIdToDelete = currentReview.id
        await deleteReview(reviewIdToDelete)
        history.go(0)
        return
      }

      history.go(0)
    } catch (error) {
      setShowErrorModal(true)
      console.error(error)
    }
  }

  const deleteReview = async (id: number) => await ReviewService.deleteReview(id)

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="container">
      <TutorialModal
        visible={!introductionView}
        onClose={() => {
          setIntroductionView(true)
          dispatch(showHelpId('tech-tutorial-step-1'))
        }}
        skipTutorial={() => {
          setIntroductionView(true)
          dispatch(finishTutorial('tech-tutorial-step-1'))
        }}
        icon={false}
        message={<TutorialIntroduction />}
      />
      <NavigationBlocker
        modalMessage={exitWithoutChangesModalMessage}
        securePathRegExp={TECH_EVALUATION_FORM_MAIN_PATH}
        checkIfCanNavigate={handleCanExit}
        onConfirmNavigation={handleConfirmExit}
      />
      <div className="flex flex-col pb-4 gap-20">
        <div className="flex flex-1 gap-2">
          <div className="flex flex-col w-1/3 min-w-96">
            <div className="sticky top-8">
              <div className="flex items-start min-w-96 max-w-md flex-col gap-6 mb-10">
                <h2
                  onClick={handleAutoFillClickEvent}
                  className="text-dark-blue font-semibold text-6xl m-0 leading-snug"
                >
                  Highlight your knowledge
                </h2>
                <p className="text-2xl text-stone-gray m-0 leading-snug pb-6">
                  Complete the sections that are most suitable for you
                </p>
              </div>
              <CategorySelector
                categories={topics.map(({ name }) => name)}
                completedCategories={getCompletedCategories()}
                selectedCategory={currentTopic?.name}
                onCategorySelected={handleCategorySelected}
                showEmpty={true}
                showStepLines={false}
              />
              <div className="flex flex-wrap gap-2 mt-24">
                <div>
                  <HelpTooltip
                    id={'tech-tutorial-step-4'}
                    childrenClassNames="relative z-50 bg-white w-fit items-center flex-1"
                    placement="right-end"
                  >
                    <AppButton
                      type="info"
                      onClick={currentReview?.finishDate ? handleFinishReview : handleSaveDraft}
                      className={tutorialCompleted ? '' : 'm-6'}
                    >
                      {currentReview?.finishDate ? 'Update' : 'View draft'}
                    </AppButton>
                  </HelpTooltip>
                  <button
                    onClick={handleDeleteReview}
                    className={tutorialCompleted ? 'flex items-center mt-4' : 'flex items-center mx-6 -mt-2'}
                  >
                    <RestartIcon />
                    <span className="invisible lg:visible text-blue text-2xl font-semibold ml-3 font-manrope">
                      Start over
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1" ref={divContentQuestionsRef}>
            <div className="mb-12">
              <div className="flex flex-col">
                {currentTopic?.title === 'Rate your skills' ? (
                  <p className="text-4xl font-medium text-dark-blue mb-6">{currentTopic?.name}</p>
                ) : (
                  <p className="text-4xl font-medium text-dark-blue mb-6">{currentTopic?.title}</p>
                )}
                <p className="text-2xl font-semibold text-dark-blue mb-10">{currentTopic?.subTitle}</p>
              </div>

              {currentTopic?.title === 'Rate your skills' ? (
                <div className="text-xl text-white mb-4 flex flex-row">
                  <div className="flex flex-row">
                    <div className="font-bold bg-purple rounded-full py-4 px-6 mr-6">Advanced</div>
                    <div className="font-bold bg-ruby rounded-full py-4 px-6 mr-6">Medium</div>
                    <div className="font-bold bg-cyan rounded-full py-4 px-6 mr-6">Basic</div>
                  </div>
                  <button
                    onClick={() => setShowModalInfoTechLevels(true)}
                    className="p-2 rounded-md text-blue font-extrabold mx-2 underline decoration-1"
                  >
                    More information
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="gap-1 mb-12 flex flex-wrap">
              {(groupedQuestions[currentTopic?.name || ''] || []).map((question, i) => {
                switch (question.type) {
                  case QuestionTypeEnum.RATE_SELECT:
                    if (i === 0 && !tutorialCompleted) {
                      return (
                        <HelpTooltip id="tech-tutorial-step-3" key={v4()}>
                          <div className="flex flex-col items-center justify-center">
                            <RateQuestion
                              key={`employee-review-${currentTopic}-question-${i}`}
                              label={question.label}
                              options={question.options}
                              selectedOption={(indexedAnswers[question.id]?.answer as string) || ''}
                              required={question.required}
                              onChange={handleQuestionAnswered(question.id)}
                            />
                            <div className="flex flex-col bg-white border-dark-blue border border-solid rounded-b-xl -mt-9 mr-4 pt-4 w-[120px] gap-4 p-2 text-black text-xl z-[9]">
                              <h5 className="px-6 font-medium">Advanced</h5>
                              <h5 className="px-6 font-medium">Medium</h5>
                              <h5 className="px-6 font-medium">Basic</h5>
                              <h5 className="px-6 pb-2 font-medium">None</h5>
                            </div>
                          </div>
                        </HelpTooltip>
                      )
                    } else {
                      return (
                        <RateQuestion
                          key={`employee-review-${currentTopic}-question-${i}`}
                          label={question.label}
                          options={question.options}
                          selectedOption={(indexedAnswers[question.id]?.answer as string) || ''}
                          required={question.required}
                          onChange={handleQuestionAnswered(question.id)}
                        />
                      )
                    }
                  case QuestionTypeEnum.PILL_SELECT:
                    return (
                      <MultiSelectQuestion
                        key={`employee-review-${currentTopic}-question-${i}`}
                        title={question.title}
                        options={question.options}
                        selectedOptions={[...((indexedAnswers[question.id].answer as string[]) || [])]}
                        required={question.required}
                        currentTopic={currentTopic}
                        currentAnswers={indexedAnswers}
                        questions={groupedQuestions}
                        onChange={handleQuestionAnswered(question.id)}
                      />
                    )
                  default:
                    return <div>Other</div>
                }
              })}
            </div>
            <div className="flex flex-col">
              <NextPrevArrows
                onNext={isLastTopic ? handleSaveDraft : handleGoToNextTopic}
                onPrev={handleBackToPreviousTopic}
                isNextDisabled={false}
                isPrevDisabled={isFirstTopic}
                {...arrowLabelProps}
              />
              {showBackToTop ? (
                <div className="flex justify-center">
                  <button
                    className="flex justify-around items-center bg-pale-blue text-blue text-xl px-3 py-2 w-[100px] rounded-[8px]"
                    onClick={handleGoTop}
                  >
                    <div className="rotate-90">
                      <ArrowIcon height={8.5} width={10.5} color={'#005ECA'} />
                    </div>
                    Back to top
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div>
        {showErrorModal && (
          <ErrorModal
            icon={<WarningIcon />}
            visible={showErrorModal}
            message={
              <p>
                <span className={`modal-title`}>Something went wrong while saving your answers.</span>
                <br />
                <span className="text-grey">
                  <b>Try reloading the page and save again.</b> If the problem persists,{' '}
                  <a href={EMAIL}>contact support</a>.
                </span>
              </p>
            }
            onClose={() => setShowErrorModal(false)}
          />
        )}
      </div>
      <OptionsModal
        icon={<DeleteIcon />}
        secondButtonText="Delete"
        secondButtonType="error"
        firstButtonText="Close"
        firstButtonType="clear"
        visible={showDeleteModal}
        onFirstButtonClick={handleCancelDelete}
        onSecondButtonClick={handleConfirmDelete}
        onClose={handleCancelDelete}
        message={deleteModalMessage}
      />
      <SuccessModal
        visible={showModalInfoTechLevels}
        icon={false}
        onClose={() => {
          setShowModalInfoTechLevels(false)
        }}
        message={<TechnologiesLevelInfo />}
        buttonText="Close"
      />
    </div>
  )
}
