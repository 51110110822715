const createPathWithLang = (path: string): string => {
  const lang = 'en'
  const pathWithLanguage = `/${lang}${path}`
  return pathWithLanguage
}

const createPathForRouter = (path: string): string => {
  const pathWithLanguage = `/:langId(es|en)${path}`
  return pathWithLanguage
}

export { createPathWithLang, createPathForRouter }
