import {
  Configuration,
  EmployeeControllerApi,
  QuestionControllerApi,
  ReviewControllerApi,
  ReviewRequestControllerApi,
  TeamControllerApi,
  FormControllerApi,
  EventTrackingControllerApi,
  ConfigControllerApi,
  UsageReportControllerApi,
  SuggestionBoxControllerApi,
  PeriodControllerApi,
  MapScoreControllerApi,
  RosterControllerApi,
} from './openapi'

// FIXME token needs to be invalidated and re-fetched on LOGIN
export const basePath = process.env.REACT_APP_API_HOST || 'http://localhost:8080'

export const configuration = new Configuration({
  // TODO configure properly
  basePath,
})

export const employeeAPI = new EmployeeControllerApi(configuration)
export const teamAPI = new TeamControllerApi(configuration)
export const ReviewApi = new ReviewControllerApi(configuration)
export const QuestionApi = new QuestionControllerApi(configuration)
export const FormApi = new FormControllerApi(configuration)
export const EventTrackingApi = new EventTrackingControllerApi(configuration)
export const ReviewRequestApi = new ReviewRequestControllerApi(configuration)
export const PeriodApi = new PeriodControllerApi(configuration)
export const loginAPI = employeeAPI
export const configAPI = new ConfigControllerApi(configuration)
export const usageAPI = new UsageReportControllerApi(configuration)
export const suggestionAPI = new SuggestionBoxControllerApi(configuration)
export const mapScoreAPI = new MapScoreControllerApi(configuration)
export const rosterAPI = new RosterControllerApi(configuration)
