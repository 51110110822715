import { Employee, EmployeeScores } from '../services/EmployeeService'

export function hasEmployeeReviews(employee: EmployeeScores) {
  return employee.reviewsAmount && employee.reviewsAmount >= 1
}

export const getEmployeeById = (id: number, employeeList: Employee[]): Employee | undefined => {
  return employeeList?.find(employee => {
    return employee.id === id
  })
}
