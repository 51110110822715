import { MapScoreResponse } from '../api/openapi'
import { AVAILABLE_EMPLOYEE_HIGHLIGHTS_LABELS } from '../constants/employee'
import { Employee, EmployeeSort, getEmployeesFromTeam, getHighlightCount } from '../services/EmployeeService'
import { TeamAssessmentsReportResponseExtended, TeamDetails, TeamService, TeamStatus } from '../services/TeamService'
import { FormCategory } from '../types/definitions/formCategory'
import { SortOrderEnum } from '../types/enums/sort-order.enum'
import { sortFormsBySortKey } from './forms/FormUtils'
import { getValueFromLiteralPath } from './objectUtils'
import { simpleTextCompareFn } from './text'
import { cloneDeep } from './utils'

export type SortBy = { property: string; order: SortOrderEnum }

export type TeamSortType =
  | 'issues'
  | 'progress'
  | 'name'
  | 'leaders'
  | 'score'
  | 'default'
  | 'notStarted'
  | 'unfinished'
  | 'finished'
  | 'skipped'

export type GeneralStatusSortingProps = 'name' | typeof AVAILABLE_EMPLOYEE_HIGHLIGHTS_LABELS[number] | 'default'

export interface GeneralStatusSortingType {
  property: GeneralStatusSortingProps
  order: SortOrderEnum
}

// TODO: Same as SortBy remove one of them
export interface SortingType {
  property: TeamSortType
  order: SortOrderEnum
}

export const initialSortingEmployees: EmployeeSort = {
  property: 'name',
  order: SortOrderEnum.Ascending,
}

export const sort = <T>(
  records: T[],
  sorting: SortBy[],
  customSortFunctions?: Partial<Record<string, (item1: T, item2: T) => number>>
): T[] => {
  const result = cloneDeep<T>(records)
  return result.sort((record1, record2) => {
    try {
      for (const sort of sorting) {
        const sortFn = customSortFunctions?.[sort.property]
        const sortResult = sortFn
          ? sortFn?.(record1, record2) || 0
          : simpleTextCompareFn(
              getValueFromLiteralPath<string>(record1, sort.property) || '',
              getValueFromLiteralPath<string>(record2, sort.property) || ''
            )
        if (sortResult !== 0) {
          return sort.order === 'asc' ? sortResult : sortResult * -1
        }
      }
      return 0
    } catch (e) {
      console.error('Sorting error', e)
      return 0
    }
  }) as T[]
}

export const sortTeamIssuesByIssues = (records: TeamStatus[], order: string) => {
  const results = cloneDeep(records)
  return results.sort((a: any, b: any) => {
    const numberOfIssuesA = a.flightRisks + a.mismatches
    const numberOfIssuesB = b.flightRisks + b.mismatches
    if (numberOfIssuesA === numberOfIssuesB) {
      return order === 'desc' ? a.finished - b.finished : b.finished - a.finished
    }
    if (order === 'desc') {
      return numberOfIssuesA > numberOfIssuesB ? 1 : -1
    }
    return numberOfIssuesA < numberOfIssuesB ? 1 : -1
  })
}

export const sortTeamIssuesByProgress = (records: TeamStatus[], order: string) => {
  const results = cloneDeep(records)
  return results.sort((a: any, b: any) => {
    const progressA = a.finished / a.teamSize
    const progressB = b.finished / b.teamSize
    if (progressA === progressB) {
      return order === 'desc' ? a.finished - b.finished : b.finished - a.finished
    }
    if (order === 'desc') {
      return progressA > progressB ? 1 : -1
    }
    return progressA < progressB ? 1 : -1
  })
}

export const sortUsageReportByNumeric = (records: TeamStatus[], order: string, property: string) => {
  const results = cloneDeep(records)
  return results.sort((a: any, b: any) => {
    const progressA = a[property]
    const progressB = b[property]
    if (progressA === progressB) {
      return order === 'desc' ? a.finished - b.finished : b.finished - a.finished
    }
    if (order === 'desc') {
      return progressA > progressB ? 1 : -1
    }
    return progressA < progressB ? 1 : -1
  })
}

const sortByTypeTeamIssues = (type: TeamSortType) => (data: TeamStatus[], selectedSort: SortingType) => {
  switch (type) {
    case 'issues':
      return sortTeamIssuesByIssues(data, selectedSort.order)
    case 'progress':
      return sortTeamIssuesByProgress(data, selectedSort.order)
    default:
      return TeamService.sortTeamStatus(data, [selectedSort])
  }
}

export const sortData = (data: TeamStatus[], selectedSort: SortingType, initialSorting: SortingType) => {
  const typeToSort = selectedSort?.property as TeamSortType
  return sortByTypeTeamIssues(typeToSort)(data, selectedSort || initialSorting)
}

export const sortByTypeUsageReport = (
  sortedTeamAssessments: TeamAssessmentsReportResponseExtended[],
  selectedSort: SortingType,
  initialSorting: SortingType
) => {
  const USAGE_REPORT_DATA = {
    progress: sortTeamIssuesByProgress(sortedTeamAssessments, selectedSort?.order || initialSorting.order),
    notStarted: sortUsageReportByNumeric(
      sortedTeamAssessments,
      selectedSort?.order || initialSorting.order,
      'notStarted'
    ),
    unfinished: sortUsageReportByNumeric(
      sortedTeamAssessments,
      selectedSort?.order || initialSorting.order,
      'unfinished'
    ),
    finished: sortUsageReportByNumeric(sortedTeamAssessments, selectedSort?.order || initialSorting.order, 'finished'),
    skipped: sortUsageReportByNumeric(sortedTeamAssessments, selectedSort?.order || initialSorting.order, 'skipped'),
    default: TeamService.sortTeamStatus(sortedTeamAssessments, [selectedSort || initialSorting]),
  }
  return USAGE_REPORT_DATA
}

export const sortByTypeGeneralStatus = (
  sortedTeams: TeamDetails[],
  employees: Employee[],
  selectedSort: GeneralStatusSortingType
) => {
  const GENERAL_STATUS_DATA: any = {
    name: sortTeamByName(sortedTeams, selectedSort?.order),
    default: sortedTeams,
  }

  AVAILABLE_EMPLOYEE_HIGHLIGHTS_LABELS.forEach(label => {
    GENERAL_STATUS_DATA[label] = sortTeamByHighlights(sortedTeams, employees, label, selectedSort?.order)
  })

  return GENERAL_STATUS_DATA
}

export const sortTeamByHighlights = (
  records: TeamDetails[],
  employees: Employee[],
  highlight: string,
  order: string
) => {
  const teamsWithHighlights = cloneDeep(records).map(team => {
    const teamEmployees = getEmployeesFromTeam(team, employees)
    const highlightCount = getHighlightCount(teamEmployees, highlight)
    return { team: team, highlightCount: highlightCount }
  })

  teamsWithHighlights.sort((a: any, b: any) => {
    if (a.highlightCount === b.highlightCount) {
      return a.team.name.localeCompare(b.team.name)
    }
    return order === 'asc' ? a.highlightCount - b.highlightCount : b.highlightCount - a.highlightCount
  })

  const results = teamsWithHighlights.map(teamWithHighlight => {
    return teamWithHighlight.team
  })

  return results
}

export const sortTeamByName = (records: TeamDetails[], order: string) => {
  const results = cloneDeep(records)
  results.sort((a: any, b: any) => {
    return a.name.localeCompare(b.name)
  })
  return order === 'asc' ? results : results.reverse()
}

export const sortMapScores = (scores: MapScoreResponse[], sortBy: keyof MapScoreResponse): MapScoreResponse[] => {
  return scores.sort((a: MapScoreResponse, b: MapScoreResponse) => (b[sortBy] as number) - (a[sortBy] as number))
}

export const sortFormCategoriesForms = (formCategories: FormCategory[]): FormCategory[] =>
  sortFormCategoriesFormsBySortKey(sortBySalaryGrade(formCategories))

export const sortFormCategoriesFormsBySortKey = (formCategories: FormCategory[]): FormCategory[] => {
  formCategories.forEach(formCategory => sortFormsBySortKey(formCategory.forms ?? []))
  return formCategories
}

export const sortBySalaryGrade = (formCategories: FormCategory[]): FormCategory[] => {
  for (const category of formCategories) {
    for (const form of category.forms?.values() ?? []) {
      const match = form?.name?.match(/(US )?SG(\d+)/)
      form.salaryGrade = match ? parseInt(match[2], 10) : -1
    }
    category.forms?.sort((a, b) => (a.salaryGrade ?? -1) - (b.salaryGrade ?? -1))
  }
  return formCategories
}
