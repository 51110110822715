import { useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { CreateTrackingEventRequest, EmployeeMetaSearchResponse } from '../../api/openapi'
import { Employee, EmployeeService, EmployeeSort } from '../../services/EmployeeService'
import {
  TrackingCategoryEnum,
  TrackingCategoryEventEnum,
  trackEvent,
  trackMultipleEvents,
} from '../../services/EventTrackingService'
import { FormService } from '../../services/FormService'
import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import { createPathToEmployeeResults } from '../../utils/employeePathUtils'
import { AppButton } from '../atoms/AppButton/AppButton'
import Spinner from '../atoms/Spinner'
import { ResourcesFilter } from '../molecules/FindResources/ResourcesFilter'
import { ResourcesTable } from '../organisms/ResourcesTable'

interface filterType {
  condition: string
  option?: string
  level?: string
}

interface conditionType {
  value: string
  label: string
}

export const FindResources = (): JSX.Element => {
  const [filters, setFilters] = useState<filterType[]>([{ condition: '' }])
  const [technologies, setTechnologies] = useState<string[]>([])
  const [jobTitles, setJobTitles] = useState<string[]>([])
  const [displayTable, setDisplayTable] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [filteredEmployees, setFilteredEmployees] = useState<EmployeeMetaSearchResponse[]>([])
  const [selectedSort, setSelectedSort] = useState<EmployeeSort[]>([
    { property: 'name', order: SortOrderEnum.Ascending },
  ])
  // Default conditions
  const defaultConditions = [
    { value: 'Job position', label: 'Job position' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Aptitude', label: 'Aptitude' },
    { value: 'Communication', label: 'Communication' },
    { value: 'Attitude', label: 'Attitude' },
  ]
  // State to avoid repeated conditions
  const [availableConditions, setAvailableConditions] = useState<conditionType[]>(defaultConditions)
  const [isSearchDisabled, setIsSearchDisabled] = useState(true)

  useEffect(() => {
    trackEvent(TrackingCategoryEnum.FIND_RESOURCES, TrackingCategoryEventEnum.FIND_RESOURCES.PAGE_ON_LOAD, '')
    getFilterOptions()
  }, [])

  useEffect(() => {
    const disabled = !filters.every(filter => {
      if (filter.condition === 'Technology') {
        return filter.option && filter.option.length > 0 && filter.level && filter.level.length > 0
      }
      return filter.option && filter.option.length > 0
    })
    setIsSearchDisabled(disabled)
    setDisplayTable(false)
  }, [filters])

  useEffect(() => {
    setFilteredEmployees(() => EmployeeService.sortEmployees(filteredEmployees, selectedSort))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort])

  const getFilterOptions = async () => {
    const selfForm = await FormService.findAll('self')
    const form = await FormService.findById(selfForm[0].id || 5)
    const jobTitles = await EmployeeService.getJobTitles()

    const technologies = form.questions
      ?.filter(question => question.preTitle === 'Want to learn/improve')
      .map(question => question.options)
      .flat()
      .sort()

    setTechnologies(technologies as string[])
    setJobTitles(jobTitles)
  }

  const filterToCondition = (filter: filterType): conditionType => {
    return {
      value: filter.condition,
      label: defaultConditions.filter(condition => condition.value === filter.condition)[0].label,
    }
  }

  const setFiltersValue = (filter: filterType, i: number) => {
    filters[i] = filter
    setFilters([...filters])
    if (filter.condition !== 'Job position' && filter.condition !== 'Technology') {
      setAvailableConditions(
        defaultConditions.filter(
          def =>
            !filters.some(
              f => f.condition === def.value && f.condition !== 'Job position' && f.condition !== 'Technology'
            )
        )
      )
    }
  }

  const deleteFilter = (i: number) => {
    if (filters.length > 1) {
      // Adds again the removed condition
      const filter = filters[i]
      const newConditions = defaultConditions.filter(
        def => def.value === filter.condition || availableConditions.some(cond => cond.value === def.value)
      )
      setAvailableConditions(newConditions)
      filters.splice(i, 1)
      setFilters([...filters])
    } else {
      setFilters([{ condition: '' }])
      setAvailableConditions(defaultConditions)
    }
  }

  const handleSearchFilters = async () => {
    const searchEvent: CreateTrackingEventRequest = {
      category: TrackingCategoryEnum.FIND_RESOURCES,
      event: TrackingCategoryEventEnum.FIND_RESOURCES.SEARCH_RESOURCES,
      payload: '',
    }

    const conditionsEvents: CreateTrackingEventRequest[] = filters.map(event => {
      return {
        category: TrackingCategoryEnum.FIND_RESOURCES,
        event: TrackingCategoryEventEnum.FIND_RESOURCES.SELECT_CONDITION,
        payload: event.condition,
      }
    })

    trackMultipleEvents([searchEvent, ...conditionsEvents])

    setLoading(true)
    setDisplayTable(false)
    const jobTitles: string[] = []
    const technologies: Record<string, string> = {}
    const scores: Record<string, string> = {}

    filters.forEach(filter => {
      switch (filter.condition) {
        case 'Job position': {
          filter.option && jobTitles.push(filter.option)
          break
        }
        case 'Technology': {
          filter.option && (technologies[filter.option] = filter.level || 'Basic')
          break
        }
        default: {
          scores[filter.condition] = filter.option || 'Low'
          break
        }
      }
    })

    const employees = await EmployeeService.getFilteredEmployees({
      jobTitles,
      technologies,
      scores,
    })

    setFilteredEmployees(() => EmployeeService.sortEmployees(employees, selectedSort))
    setDisplayTable(true)
    setLoading(false)
  }

  const handleSortSelected = (property: string, order: SortOrderEnum): void => {
    setSelectedSort([{ property: property as keyof Employee, order }])
  }

  const handleOnEmployeeClicked = (employee: Employee) => createPathToEmployeeResults(employee.id)

  const getConditions = (filter: filterType): conditionType[] => {
    if (filter.condition !== '' && filter.condition !== 'Technology' && filter.condition !== 'Job position') {
      return [filterToCondition(filter), ...availableConditions]
    }
    return availableConditions
  }

  const getAvailableOptions = (filter: filterType, defaultOptions: string[]): string[] => {
    const availableOptions = defaultOptions.filter(option => !filters.some(f => f.option === option))
    if (filter.option) {
      return [filter.option, ...availableOptions]
    }
    return availableOptions
  }

  const handleClear = () => {
    trackEvent(TrackingCategoryEnum.FIND_RESOURCES, TrackingCategoryEventEnum.FIND_RESOURCES.CLEAR_RESOURCES, '')
    setAvailableConditions(defaultConditions)
    setFilters([{ condition: '' }])
    setDisplayTable(false)
  }

  return (
    <div className="container mx-auto">
      <h1 className="text-6xl text-dark-blue font-semibold mt-10 mb-20">Find resources</h1>
      <p className="text-3xl font-semibold mb-10">Add conditions to find matches</p>
      <div className="gap-10">
        {filters.map((filter, i) => (
          <ResourcesFilter
            filter={filter}
            conditions={getConditions(filter)}
            jobTitles={getAvailableOptions(filter, jobTitles)}
            technologies={getAvailableOptions(filter, technologies)}
            onClick={filter => setFiltersValue(filter, i)}
            onDelete={() => deleteFilter(i)}
            key={v4()}
          ></ResourcesFilter>
        ))}
      </div>
      {availableConditions.length > 0 && !filters.some(f => f.condition === '') && (
        <button onClick={() => setFilters([...filters, { condition: '' }])} className="flex items-center mt-6">
          <span className="text-blue text-2xl font-semibold ml-3 font-manrope">+ Add condition</span>
        </button>
      )}
      <div className="flex flex-wrap gap-5 mt-24">
        <AppButton disabled={isSearchDisabled} onClick={() => handleSearchFilters()}>
          Search
        </AppButton>
        <AppButton type="clear" onClick={handleClear} disabled={filters[0].condition === ''}>
          Clear
        </AppButton>
      </div>
      {!displayTable && loading && (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      )}
      {displayTable && (
        <div className="flex-grow mt-24">
          {filteredEmployees.length > 0 && (
            <div className="flex w-full">
              <h3 className="text-lg">
                {filteredEmployees.length === 1
                  ? `${filteredEmployees.length} result`
                  : `${filteredEmployees.length} results`}
              </h3>
            </div>
          )}
          <ResourcesTable
            employees={filteredEmployees}
            selectedSort={selectedSort[0]}
            onSortSelected={handleSortSelected}
            handleOnEmployeeClicked={handleOnEmployeeClicked}
          ></ResourcesTable>
        </div>
      )}
    </div>
  )
}
