/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivityCalendarDateResponse
 */
export interface ActivityCalendarDateResponse {
    /**
     * 
     * @type {number}
     * @memberof ActivityCalendarDateResponse
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityCalendarDateResponse
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof ActivityCalendarDateResponse
     */
    'level': number;
}
/**
 * 
 * @export
 * @interface ActivityCalendarResponse
 */
export interface ActivityCalendarResponse {
    /**
     * 
     * @type {Array<ActivityCalendarDateResponse>}
     * @memberof ActivityCalendarResponse
     */
    'dates': Array<ActivityCalendarDateResponse>;
    /**
     * 
     * @type {number}
     * @memberof ActivityCalendarResponse
     */
    'employeeId': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityCalendarResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityCalendarResponse
     */
    'year': number;
}
/**
 * 
 * @export
 * @interface ActivitySummaryResponse
 */
export interface ActivitySummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryResponse
     */
    'approvedPRs': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryResponse
     */
    'closedPRs': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryResponse
     */
    'createdPRs': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryResponse
     */
    'filesModified': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryResponse
     */
    'lineAdded': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryResponse
     */
    'lineRemoved': number;
    /**
     * 
     * @type {string}
     * @memberof ActivitySummaryResponse
     */
    'mid'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryResponse
     */
    'reviewComments': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryResponse
     */
    'reviewSuggestion': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryResponse
     */
    'reviewsApproved': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryResponse
     */
    'reviewsRequireChanges': number;
}
/**
 * 
 * @export
 * @interface AddActivityEventRequest
 */
export interface AddActivityEventRequest {
    /**
     * 
     * @type {number}
     * @memberof AddActivityEventRequest
     */
    'employee': number;
    /**
     * 
     * @type {string}
     * @memberof AddActivityEventRequest
     */
    'eventDate': string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityEventRequest
     */
    'payload'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityEventRequest
     */
    'repository'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityEventRequest
     */
    'type': AddActivityEventRequestTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AddActivityEventRequest
     */
    'value'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AddActivityEventRequestTypeEnum {
    ActivityFilesModified = 'ACTIVITY_FILES_MODIFIED',
    ActivityLinesAdded = 'ACTIVITY_LINES_ADDED',
    ActivityLinesRemoved = 'ACTIVITY_LINES_REMOVED',
    PrApproved = 'PR_APPROVED',
    PrClosed = 'PR_CLOSED',
    PrCreated = 'PR_CREATED',
    ReviewApproved = 'REVIEW_APPROVED',
    ReviewComment = 'REVIEW_COMMENT',
    ReviewRequireChanges = 'REVIEW_REQUIRE_CHANGES',
    ReviewSuggestions = 'REVIEW_SUGGESTIONS'
}

/**
 * 
 * @export
 * @interface AddAnswersRequest
 */
export interface AddAnswersRequest {
    /**
     * 
     * @type {Array<AnswerRequest>}
     * @memberof AddAnswersRequest
     */
    'answers': Array<AnswerRequest>;
}
/**
 * 
 * @export
 * @interface Answer
 */
export interface Answer {
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    'answer'?: string;
    /**
     * 
     * @type {AnswerId}
     * @memberof Answer
     */
    'id'?: AnswerId;
    /**
     * 
     * @type {Question}
     * @memberof Answer
     */
    'question'?: Question;
    /**
     * 
     * @type {Review}
     * @memberof Answer
     */
    'review'?: Review;
    /**
     * 
     * @type {boolean}
     * @memberof Answer
     */
    'skipped'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    'suggestedAnswer'?: string;
}
/**
 * 
 * @export
 * @interface AnswerId
 */
export interface AnswerId {
    /**
     * 
     * @type {number}
     * @memberof AnswerId
     */
    'questionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnswerId
     */
    'reviewId'?: number;
}
/**
 * 
 * @export
 * @interface AnswerRequest
 */
export interface AnswerRequest {
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    'answer': string;
    /**
     * 
     * @type {number}
     * @memberof AnswerRequest
     */
    'question': number;
    /**
     * 
     * @type {number}
     * @memberof AnswerRequest
     */
    'review': number;
    /**
     * 
     * @type {boolean}
     * @memberof AnswerRequest
     */
    'skipped'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    'suggestedAnswer'?: string;
}
/**
 * 
 * @export
 * @interface AnswerResponse
 */
export interface AnswerResponse {
    /**
     * 
     * @type {string}
     * @memberof AnswerResponse
     */
    'answer': string;
    /**
     * 
     * @type {QuestionResponse}
     * @memberof AnswerResponse
     */
    'question': QuestionResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AnswerResponse
     */
    'skipped'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnswerResponse
     */
    'suggestedAnswer'?: string;
}
/**
 * 
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'currentPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface ChangeReviewFormRequest
 */
export interface ChangeReviewFormRequest {
    /**
     * 
     * @type {number}
     * @memberof ChangeReviewFormRequest
     */
    'formId': number;
    /**
     * 
     * @type {number}
     * @memberof ChangeReviewFormRequest
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface CreateEmployeeRequest
 */
export interface CreateEmployeeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequest
     */
    'jobTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequest
     */
    'msId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequest
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeRequest
     */
    'salaryGrade': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateEmployeeRequest
     */
    'teams'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CreateQuestionRequest
 */
export interface CreateQuestionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionRequest
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionRequest
     */
    'preTitle': string;
    /**
     * 
     * @type {number}
     * @memberof CreateQuestionRequest
     */
    'questionOrder': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateQuestionRequest
     */
    'required': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionRequest
     */
    'tag': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionRequest
     */
    'topicName': string;
    /**
     * 
     * @type {number}
     * @memberof CreateQuestionRequest
     */
    'topicOrder': number;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionRequest
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof CreateQuestionRequest
     */
    'weight': number;
}
/**
 * 
 * @export
 * @interface CreateReviewRequest
 */
export interface CreateReviewRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateReviewRequest
     */
    'form': number;
    /**
     * 
     * @type {number}
     * @memberof CreateReviewRequest
     */
    'reviewedEmployee': number;
}
/**
 * 
 * @export
 * @interface CreateReviewRequestRequest
 */
export interface CreateReviewRequestRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateReviewRequestRequest
     */
    'employeeToReview': number;
    /**
     * 
     * @type {number}
     * @memberof CreateReviewRequestRequest
     */
    'formId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateReviewRequestRequest
     */
    'requestedTo': number;
}
/**
 * 
 * @export
 * @interface CreateReviewRequestResponse
 */
export interface CreateReviewRequestResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateReviewRequestResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface CreateReviewSkippedRequest
 */
export interface CreateReviewSkippedRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateReviewSkippedRequest
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReviewSkippedRequest
     */
    'reason': string;
    /**
     * 
     * @type {number}
     * @memberof CreateReviewSkippedRequest
     */
    'skippedEmployee': number;
}
/**
 * 
 * @export
 * @interface CreateTeamRequest
 */
export interface CreateTeamRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateTeamRequest
     */
    'employees'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateTeamRequest
     */
    'leaders': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTeamRequest
     */
    'parent': number;
}
/**
 * 
 * @export
 * @interface CreateTrackingEventRequest
 */
export interface CreateTrackingEventRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTrackingEventRequest
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrackingEventRequest
     */
    'event': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrackingEventRequest
     */
    'payload': string;
}
/**
 * 
 * @export
 * @interface Employee
 */
export interface Employee {
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'createdDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'email'?: string;
    /**
     * 
     * @type {Array<EmployeePermission>}
     * @memberof Employee
     */
    'employeePermissions'?: Array<EmployeePermission>;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'hashedPassword'?: string;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'lastLoginDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'lastModifiedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'lastUpdatedPassword'?: string;
    /**
     * 
     * @type {Set<Team>}
     * @memberof Employee
     */
    'leaderIn'?: Set<Team>;
    /**
     * 
     * @type {Set<EmployeeMapScore>}
     * @memberof Employee
     */
    'mapScores'?: Set<EmployeeMapScore>;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'mid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'normalizedName'?: string;
    /**
     * 
     * @type {Set<EmployeeReviewer>}
     * @memberof Employee
     */
    'reviewers'?: Set<EmployeeReviewer>;
    /**
     * 
     * @type {Set<Review>}
     * @memberof Employee
     */
    'reviewsDone'?: Set<Review>;
    /**
     * 
     * @type {Set<Review>}
     * @memberof Employee
     */
    'reviewsReceived'?: Set<Review>;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'salaryGrade'?: string;
    /**
     * 
     * @type {Role}
     * @memberof Employee
     */
    'securityRole'?: Role;
    /**
     * 
     * @type {Set<ReviewSkipped>}
     * @memberof Employee
     */
    'skippedReviewsReceived'?: Set<ReviewSkipped>;
    /**
     * 
     * @type {EmployeeStatus}
     * @memberof Employee
     */
    'status'?: EmployeeStatus;
    /**
     * 
     * @type {Set<Team>}
     * @memberof Employee
     */
    'teams'?: Set<Team>;
    /**
     * 
     * @type {Set<UniqueReview>}
     * @memberof Employee
     */
    'uniqueReviewsReceived'?: Set<UniqueReview>;
}
/**
 * 
 * @export
 * @interface EmployeeAlerts
 */
export interface EmployeeAlerts {
    /**
     * 
     * @type {string}
     * @memberof EmployeeAlerts
     */
    'lastUpdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeAlerts
     */
    'type'?: EmployeeAlertsTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EmployeeAlertsTypeEnum {
    Burnout = 'burnout',
    FlightRisk = 'flightRisk',
    Mismatch = 'mismatch',
    Rockstar = 'rockstar'
}

/**
 * 
 * @export
 * @interface EmployeeInfoResponse
 */
export interface EmployeeInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof EmployeeInfoResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeInfoResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeInfoResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeInfoResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeInfoResponse
     */
    'role': string;
}
/**
 * 
 * @export
 * @interface EmployeeMapScore
 */
export interface EmployeeMapScore {
    /**
     * 
     * @type {Employee}
     * @memberof EmployeeMapScore
     */
    'employee'?: Employee;
    /**
     * 
     * @type {EmployeeMapScoreId}
     * @memberof EmployeeMapScore
     */
    'id'?: EmployeeMapScoreId;
    /**
     * 
     * @type {MapScore}
     * @memberof EmployeeMapScore
     */
    'mapScore'?: MapScore;
}
/**
 * 
 * @export
 * @interface EmployeeMapScoreId
 */
export interface EmployeeMapScoreId {
    /**
     * 
     * @type {number}
     * @memberof EmployeeMapScoreId
     */
    'employeeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeMapScoreId
     */
    'mapScoreId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeMapScoreId
     */
    'year'?: number;
}
/**
 * 
 * @export
 * @interface EmployeeMetaSearchRequest
 */
export interface EmployeeMetaSearchRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof EmployeeMetaSearchRequest
     */
    'jobTitles'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EmployeeMetaSearchRequest
     */
    'scores'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EmployeeMetaSearchRequest
     */
    'technologies'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface EmployeeMetaSearchResponse
 */
export interface EmployeeMetaSearchResponse {
    /**
     * 
     * @type {number}
     * @memberof EmployeeMetaSearchResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeMetaSearchResponse
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {Array<EmployeeInfoResponse>}
     * @memberof EmployeeMetaSearchResponse
     */
    'leaders'?: Array<EmployeeInfoResponse>;
    /**
     * 
     * @type {number}
     * @memberof EmployeeMetaSearchResponse
     */
    'match'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeMetaSearchResponse
     */
    'name'?: string;
    /**
     * 
     * @type {Array<TeamInfoResponse>}
     * @memberof EmployeeMetaSearchResponse
     */
    'teams'?: Array<TeamInfoResponse>;
}
/**
 * 
 * @export
 * @interface EmployeeNotLoggedInResponse
 */
export interface EmployeeNotLoggedInResponse {
    /**
     * 
     * @type {number}
     * @memberof EmployeeNotLoggedInResponse
     */
    'finished'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeNotLoggedInResponse
     */
    'id'?: number;
    /**
     * 
     * @type {Set<string>}
     * @memberof EmployeeNotLoggedInResponse
     */
    'leaders'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeNotLoggedInResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeNotLoggedInResponse
     */
    'skipped'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeNotLoggedInResponse
     */
    'teamSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeNotLoggedInResponse
     */
    'unfinished'?: number;
}
/**
 * 
 * @export
 * @interface EmployeeOverviewProjection
 */
export interface EmployeeOverviewProjection {
    /**
     * 
     * @type {number}
     * @memberof EmployeeOverviewProjection
     */
    'avgAptitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeOverviewProjection
     */
    'avgAttitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeOverviewProjection
     */
    'avgCommunication'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeOverviewProjection
     */
    'burnout'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeOverviewProjection
     */
    'flightRisk'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmployeeOverviewProjection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeOverviewProjection
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeOverviewProjection
     */
    'lastReviewType'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeOverviewProjection
     */
    'lastReviewerName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeOverviewProjection
     */
    'mismatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeOverviewProjection
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeOverviewProjection
     */
    'reviewTypesAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeOverviewProjection
     */
    'reviewsAmount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeOverviewProjection
     */
    'rockstar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeOverviewProjection
     */
    'skipped'?: boolean;
}
/**
 * 
 * @export
 * @interface EmployeePermission
 */
export interface EmployeePermission {
    /**
     * 
     * @type {Employee}
     * @memberof EmployeePermission
     */
    'employee'?: Employee;
    /**
     * 
     * @type {EmployeePermissionId}
     * @memberof EmployeePermission
     */
    'id'?: EmployeePermissionId;
    /**
     * 
     * @type {Permission}
     * @memberof EmployeePermission
     */
    'permission'?: Permission;
}
/**
 * 
 * @export
 * @interface EmployeePermissionId
 */
export interface EmployeePermissionId {
    /**
     * 
     * @type {number}
     * @memberof EmployeePermissionId
     */
    'employeeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePermissionId
     */
    'permissionId'?: number;
}
/**
 * 
 * @export
 * @interface EmployeeResponse
 */
export interface EmployeeResponse {
    /**
     * 
     * @type {Array<EmployeeAlerts>}
     * @memberof EmployeeResponse
     */
    'alerts': Array<EmployeeAlerts>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'jobTitle': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'salaryGrade': string;
}
/**
 * 
 * @export
 * @interface EmployeeReviewer
 */
export interface EmployeeReviewer {
    /**
     * 
     * @type {Employee}
     * @memberof EmployeeReviewer
     */
    'employee'?: Employee;
    /**
     * 
     * @type {Review}
     * @memberof EmployeeReviewer
     */
    'lastReview'?: Review;
    /**
     * 
     * @type {Employee}
     * @memberof EmployeeReviewer
     */
    'reviewer'?: Employee;
}
/**
 * 
 * @export
 * @interface EmployeeReviewerResponse
 */
export interface EmployeeReviewerResponse {
    /**
     * 
     * @type {ReviewResponse}
     * @memberof EmployeeReviewerResponse
     */
    'lastReview'?: ReviewResponse;
    /**
     * 
     * @type {EmployeeInfoResponse}
     * @memberof EmployeeReviewerResponse
     */
    'reviewer': EmployeeInfoResponse;
}
/**
 * 
 * @export
 * @interface EmployeeStatus
 */
export interface EmployeeStatus {
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeStatus
     */
    'burnout'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    'burnoutDate'?: string;
    /**
     * 
     * @type {Employee}
     * @memberof EmployeeStatus
     */
    'employee'?: Employee;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeStatus
     */
    'flightRisk'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    'flightRiskDate'?: string;
    /**
     * 
     * @type {Review}
     * @memberof EmployeeStatus
     */
    'lastFinishedReview'?: Review;
    /**
     * 
     * @type {Review}
     * @memberof EmployeeStatus
     */
    'lastOwnReview'?: Review;
    /**
     * 
     * @type {Review}
     * @memberof EmployeeStatus
     */
    'lastReview'?: Review;
    /**
     * 
     * @type {Review}
     * @memberof EmployeeStatus
     */
    'lastUnfinishedReview'?: Review;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeStatus
     */
    'mismatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    'mismatchDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeStatus
     */
    'rockstar'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStatus
     */
    'rockstarDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeStatus
     */
    'skipped'?: boolean;
}
/**
 * 
 * @export
 * @interface EmployeeSummaryResponse
 */
export interface EmployeeSummaryResponse {
    /**
     * 
     * @type {Array<EmployeeAlerts>}
     * @memberof EmployeeSummaryResponse
     */
    'alerts': Array<EmployeeAlerts>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSummaryResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSummaryResponse
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeSummaryResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSummaryResponse
     */
    'jobTitle': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSummaryResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {ReviewResponse}
     * @memberof EmployeeSummaryResponse
     */
    'lastOwnReview'?: ReviewResponse;
    /**
     * 
     * @type {ReviewResponse}
     * @memberof EmployeeSummaryResponse
     */
    'lastReview'?: ReviewResponse;
    /**
     * 
     * @type {Array<TeamInfoResponse>}
     * @memberof EmployeeSummaryResponse
     */
    'leaderIn'?: Array<TeamInfoResponse>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSummaryResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<EmployeeReviewerResponse>}
     * @memberof EmployeeSummaryResponse
     */
    'reviewers'?: Array<EmployeeReviewerResponse>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSummaryResponse
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSummaryResponse
     */
    'salaryGrade': string;
    /**
     * 
     * @type {Array<ReviewSkippedResponse>}
     * @memberof EmployeeSummaryResponse
     */
    'skippedReviews'?: Array<ReviewSkippedResponse>;
    /**
     * 
     * @type {Array<TeamInfoResponse>}
     * @memberof EmployeeSummaryResponse
     */
    'teams'?: Array<TeamInfoResponse>;
}
/**
 * 
 * @export
 * @interface EmployeeWithTeamsAndReviewsResponse
 */
export interface EmployeeWithTeamsAndReviewsResponse {
    /**
     * 
     * @type {Array<EmployeeAlerts>}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'alerts': Array<EmployeeAlerts>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'jobTitle': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {Array<TeamInfoResponse>}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'leaderIn'?: Array<TeamInfoResponse>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<ReviewResponse>}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'reviewsDone'?: Array<ReviewResponse>;
    /**
     * 
     * @type {Array<ReviewResponse>}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'reviewsReceived'?: Array<ReviewResponse>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'salaryGrade': string;
    /**
     * 
     * @type {Array<ReviewSkippedResponse>}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'skippedReviewsReceived'?: Array<ReviewSkippedResponse>;
    /**
     * 
     * @type {Array<TeamInfoResponse>}
     * @memberof EmployeeWithTeamsAndReviewsResponse
     */
    'teams'?: Array<TeamInfoResponse>;
}
/**
 * 
 * @export
 * @interface EmployeeWithTeamsResponse
 */
export interface EmployeeWithTeamsResponse {
    /**
     * 
     * @type {Array<EmployeeAlerts>}
     * @memberof EmployeeWithTeamsResponse
     */
    'alerts': Array<EmployeeAlerts>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsResponse
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeWithTeamsResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsResponse
     */
    'jobTitle': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {Array<TeamInfoResponse>}
     * @memberof EmployeeWithTeamsResponse
     */
    'leaderIn'?: Array<TeamInfoResponse>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsResponse
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithTeamsResponse
     */
    'salaryGrade': string;
    /**
     * 
     * @type {Array<TeamInfoResponse>}
     * @memberof EmployeeWithTeamsResponse
     */
    'teams'?: Array<TeamInfoResponse>;
}
/**
 * 
 * @export
 * @interface Form
 */
export interface Form {
    /**
     * 
     * @type {number}
     * @memberof Form
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'createdDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Form
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {FormCategory}
     * @memberof Form
     */
    'formCategory'?: FormCategory;
    /**
     * 
     * @type {number}
     * @memberof Form
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Form
     */
    'lastModifiedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'name'?: string;
    /**
     * 
     * @type {Set<Question>}
     * @memberof Form
     */
    'questions'?: Set<Question>;
    /**
     * 
     * @type {number}
     * @memberof Form
     */
    'sortKey'?: number;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'tags'?: string;
}
/**
 * 
 * @export
 * @interface FormCategory
 */
export interface FormCategory {
    /**
     * 
     * @type {Set<Form>}
     * @memberof FormCategory
     */
    'forms'?: Set<Form>;
    /**
     * 
     * @type {number}
     * @memberof FormCategory
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormCategory
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormCategory
     */
    'order'?: number;
}
/**
 * 
 * @export
 * @interface FormCategoryInfoResponse
 */
export interface FormCategoryInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof FormCategoryInfoResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormCategoryInfoResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormCategoryInfoResponse
     */
    'order'?: number;
}
/**
 * 
 * @export
 * @interface FormCategoryResponse
 */
export interface FormCategoryResponse {
    /**
     * 
     * @type {Set<FormInfoResponse>}
     * @memberof FormCategoryResponse
     */
    'forms'?: Set<FormInfoResponse>;
    /**
     * 
     * @type {number}
     * @memberof FormCategoryResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormCategoryResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormCategoryResponse
     */
    'order'?: number;
}
/**
 * 
 * @export
 * @interface FormInfoResponse
 */
export interface FormInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof FormInfoResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FormInfoResponse
     */
    'deprecated'?: boolean;
    /**
     * 
     * @type {FormCategoryInfoResponse}
     * @memberof FormInfoResponse
     */
    'formCategory'?: FormCategoryInfoResponse;
    /**
     * 
     * @type {number}
     * @memberof FormInfoResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormInfoResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormInfoResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormInfoResponse
     */
    'sortKey'?: number;
}
/**
 * 
 * @export
 * @interface FormResponse
 */
export interface FormResponse {
    /**
     * 
     * @type {string}
     * @memberof FormResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FormResponse
     */
    'deprecated'?: boolean;
    /**
     * 
     * @type {FormCategoryInfoResponse}
     * @memberof FormResponse
     */
    'formCategory'?: FormCategoryInfoResponse;
    /**
     * 
     * @type {number}
     * @memberof FormResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormResponse
     */
    'name'?: string;
    /**
     * 
     * @type {Array<QuestionResponse>}
     * @memberof FormResponse
     */
    'questions'?: Array<QuestionResponse>;
    /**
     * 
     * @type {number}
     * @memberof FormResponse
     */
    'sortKey'?: number;
}
/**
 * 
 * @export
 * @interface LeadersSummaryUsage
 */
export interface LeadersSummaryUsage {
    /**
     * 
     * @type {number}
     * @memberof LeadersSummaryUsage
     */
    'leadersLogged'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadersSummaryUsage
     */
    'totalLeaders'?: number;
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'href'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    'templated'?: boolean;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'jwtToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'lastUpdatedPassword'?: string;
}
/**
 * 
 * @export
 * @interface LoginTokenRequest
 */
export interface LoginTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginTokenRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface MapScore
 */
export interface MapScore {
    /**
     * 
     * @type {boolean}
     * @memberof MapScore
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MapScore
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof MapScore
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MapScore
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @interface MapScoreResponse
 */
export interface MapScoreResponse {
    /**
     * 
     * @type {string}
     * @memberof MapScoreResponse
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof MapScoreResponse
     */
    'score'?: number;
    /**
     * 
     * @type {number}
     * @memberof MapScoreResponse
     */
    'year'?: number;
}
/**
 * 
 * @export
 * @interface MembersSummaryUsage
 */
export interface MembersSummaryUsage {
    /**
     * 
     * @type {number}
     * @memberof MembersSummaryUsage
     */
    'reviewsCompleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof MembersSummaryUsage
     */
    'reviewsNotStarted'?: number;
    /**
     * 
     * @type {number}
     * @memberof MembersSummaryUsage
     */
    'reviewsUnfinished'?: number;
    /**
     * 
     * @type {number}
     * @memberof MembersSummaryUsage
     */
    'totalMembers'?: number;
}
/**
 * 
 * @export
 * @interface PageEmployeeSummaryResponse
 */
export interface PageEmployeeSummaryResponse {
    /**
     * 
     * @type {Array<EmployeeSummaryResponse>}
     * @memberof PageEmployeeSummaryResponse
     */
    'content'?: Array<EmployeeSummaryResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PageEmployeeSummaryResponse
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEmployeeSummaryResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEmployeeSummaryResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeSummaryResponse
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeSummaryResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageEmployeeSummaryResponse
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeSummaryResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageEmployeeSummaryResponse
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeSummaryResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeSummaryResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'paged'?: boolean;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface Period
 */
export interface Period {
    /**
     * 
     * @type {boolean}
     * @memberof Period
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Period
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'createdDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Period
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Period
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Period
     */
    'lastModifiedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'name'?: string;
    /**
     * 
     * @type {Array<Review>}
     * @memberof Period
     */
    'reviews'?: Array<Review>;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface PeriodRequest
 */
export interface PeriodRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PeriodRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PeriodRequest
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PeriodRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodRequest
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodRequest
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface PeriodResponse
 */
export interface PeriodResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PeriodResponse
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PeriodResponse
     */
    'deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof PeriodResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodResponse
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof PeriodResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeriodResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodResponse
     */
    'startDate': string;
}
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {number}
     * @memberof Permission
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'alertKey'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'form'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    'hiddenWhenAuthorIsTheReviewedEmployee'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'preTitle'?: string;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'questionOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'relatedQuestionId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Question
     */
    'relatedQuestionTargetOptions'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'reportLayoutItemOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'reportLayoutOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'reportLayoutType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    'required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'skippable'?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'title'?: string;
    /**
     * 
     * @type {Topic}
     * @memberof Question
     */
    'topic'?: Topic;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'topicName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'topicOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    'visibleInLastReview'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'weight'?: number;
}
/**
 * 
 * @export
 * @interface QuestionResponse
 */
export interface QuestionResponse {
    /**
     * 
     * @type {string}
     * @memberof QuestionResponse
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponse
     */
    'form'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionResponse
     */
    'hiddenWhenAuthorIsTheReviewedEmployee'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof QuestionResponse
     */
    'label': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionResponse
     */
    'labels': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof QuestionResponse
     */
    'preTitle': string;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponse
     */
    'questionOrder': number;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponse
     */
    'relatedQuestionId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionResponse
     */
    'relatedQuestionTargetOptions'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponse
     */
    'reportLayoutItemOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponse
     */
    'reportLayoutOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionResponse
     */
    'reportLayoutType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionResponse
     */
    'required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionResponse
     */
    'skippable'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionResponse
     */
    'title': string;
    /**
     * 
     * @type {Topic}
     * @memberof QuestionResponse
     */
    'topic': Topic;
    /**
     * 
     * @type {string}
     * @memberof QuestionResponse
     */
    'topicKey': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionResponse
     */
    'topicName': string;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponse
     */
    'topicOrder': number;
    /**
     * 
     * @type {string}
     * @memberof QuestionResponse
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponse
     */
    'weight'?: number;
}
/**
 * 
 * @export
 * @interface Review
 */
export interface Review {
    /**
     * 
     * @type {Set<Answer>}
     * @memberof Review
     */
    'answers'?: Set<Answer>;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'createdDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Review
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'finishDate'?: string;
    /**
     * 
     * @type {Form}
     * @memberof Review
     */
    'form'?: Form;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    'lastModifiedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {Period}
     * @memberof Review
     */
    'period'?: Period;
    /**
     * 
     * @type {ReviewResult}
     * @memberof Review
     */
    'result'?: ReviewResult;
    /**
     * 
     * @type {Employee}
     * @memberof Review
     */
    'reviewAuthor'?: Employee;
    /**
     * 
     * @type {ReviewRequest}
     * @memberof Review
     */
    'reviewRequest'?: ReviewRequest;
    /**
     * 
     * @type {Employee}
     * @memberof Review
     */
    'reviewedEmployee'?: Employee;
}
/**
 * 
 * @export
 * @interface ReviewCountResponse
 */
export interface ReviewCountResponse {
    /**
     * 
     * @type {number}
     * @memberof ReviewCountResponse
     */
    'reviewedEmployees'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewCountResponse
     */
    'totalEmployees'?: number;
}
/**
 * 
 * @export
 * @interface ReviewRequest
 */
export interface ReviewRequest {
    /**
     * 
     * @type {string}
     * @memberof ReviewRequest
     */
    'creationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReviewRequest
     */
    'id'?: number;
    /**
     * 
     * @type {Employee}
     * @memberof ReviewRequest
     */
    'requestedBy'?: Employee;
    /**
     * 
     * @type {Employee}
     * @memberof ReviewRequest
     */
    'requestedTo'?: Employee;
    /**
     * 
     * @type {Review}
     * @memberof ReviewRequest
     */
    'review'?: Review;
    /**
     * 
     * @type {string}
     * @memberof ReviewRequest
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface ReviewRequestResponse
 */
export interface ReviewRequestResponse {
    /**
     * 
     * @type {number}
     * @memberof ReviewRequestResponse
     */
    'id': number;
    /**
     * 
     * @type {EmployeeSummaryResponse}
     * @memberof ReviewRequestResponse
     */
    'requestedBy': EmployeeSummaryResponse;
    /**
     * 
     * @type {ReviewWithAnswersResponse}
     * @memberof ReviewRequestResponse
     */
    'review': ReviewWithAnswersResponse;
    /**
     * 
     * @type {EmployeeSummaryResponse}
     * @memberof ReviewRequestResponse
     */
    'reviewedEmployee': EmployeeSummaryResponse;
}
/**
 * 
 * @export
 * @interface ReviewResponse
 */
export interface ReviewResponse {
    /**
     * 
     * @type {string}
     * @memberof ReviewResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewResponse
     */
    'finishDate'?: string;
    /**
     * 
     * @type {FormInfoResponse}
     * @memberof ReviewResponse
     */
    'form': FormInfoResponse;
    /**
     * 
     * @type {number}
     * @memberof ReviewResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ReviewResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {EmployeeInfoResponse}
     * @memberof ReviewResponse
     */
    'reviewAuthor': EmployeeInfoResponse;
    /**
     * 
     * @type {CreateReviewRequestResponse}
     * @memberof ReviewResponse
     */
    'reviewRequest'?: CreateReviewRequestResponse;
    /**
     * 
     * @type {EmployeeInfoResponse}
     * @memberof ReviewResponse
     */
    'reviewedEmployee': EmployeeInfoResponse;
}
/**
 * 
 * @export
 * @interface ReviewResult
 */
export interface ReviewResult {
    /**
     * 
     * @type {number}
     * @memberof ReviewResult
     */
    'aptitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewResult
     */
    'attitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewResult
     */
    'communication'?: number;
    /**
     * 
     * @type {Review}
     * @memberof ReviewResult
     */
    'review'?: Review;
}
/**
 * 
 * @export
 * @interface ReviewSimpleProjection
 */
export interface ReviewSimpleProjection {
    /**
     * 
     * @type {number}
     * @memberof ReviewSimpleProjection
     */
    'aptitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewSimpleProjection
     */
    'attitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewSimpleProjection
     */
    'communication'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewSimpleProjection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewSimpleProjection
     */
    'reviewType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewSimpleProjection
     */
    'reviewer'?: string;
}
/**
 * 
 * @export
 * @interface ReviewSkipped
 */
export interface ReviewSkipped {
    /**
     * 
     * @type {number}
     * @memberof ReviewSkipped
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewSkipped
     */
    'createdDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReviewSkipped
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReviewSkipped
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewSkipped
     */
    'lastModifiedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewSkipped
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewSkipped
     */
    'notes'?: string;
    /**
     * 
     * @type {Period}
     * @memberof ReviewSkipped
     */
    'period'?: Period;
    /**
     * 
     * @type {string}
     * @memberof ReviewSkipped
     */
    'reason'?: string;
    /**
     * 
     * @type {Employee}
     * @memberof ReviewSkipped
     */
    'skipAuthor'?: Employee;
    /**
     * 
     * @type {Employee}
     * @memberof ReviewSkipped
     */
    'skippedEmployee'?: Employee;
}
/**
 * 
 * @export
 * @interface ReviewSkippedResponse
 */
export interface ReviewSkippedResponse {
    /**
     * 
     * @type {EmployeeInfoResponse}
     * @memberof ReviewSkippedResponse
     */
    'author': EmployeeInfoResponse;
    /**
     * 
     * @type {string}
     * @memberof ReviewSkippedResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {EmployeeInfoResponse}
     * @memberof ReviewSkippedResponse
     */
    'employee': EmployeeInfoResponse;
    /**
     * 
     * @type {number}
     * @memberof ReviewSkippedResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ReviewSkippedResponse
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewSkippedResponse
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface ReviewWithAnswersResponse
 */
export interface ReviewWithAnswersResponse {
    /**
     * 
     * @type {Array<AnswerResponse>}
     * @memberof ReviewWithAnswersResponse
     */
    'answers': Array<AnswerResponse>;
    /**
     * 
     * @type {string}
     * @memberof ReviewWithAnswersResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewWithAnswersResponse
     */
    'finishDate'?: string;
    /**
     * 
     * @type {FormInfoResponse}
     * @memberof ReviewWithAnswersResponse
     */
    'form': FormInfoResponse;
    /**
     * 
     * @type {number}
     * @memberof ReviewWithAnswersResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ReviewWithAnswersResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {EmployeeInfoResponse}
     * @memberof ReviewWithAnswersResponse
     */
    'reviewAuthor': EmployeeInfoResponse;
    /**
     * 
     * @type {CreateReviewRequestResponse}
     * @memberof ReviewWithAnswersResponse
     */
    'reviewRequest'?: CreateReviewRequestResponse;
    /**
     * 
     * @type {EmployeeInfoResponse}
     * @memberof ReviewWithAnswersResponse
     */
    'reviewedEmployee': EmployeeInfoResponse;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'key'?: string;
    /**
     * 
     * @type {Array<RolePermission>}
     * @memberof Role
     */
    'permissions'?: Array<RolePermission>;
}
/**
 * 
 * @export
 * @interface RolePermission
 */
export interface RolePermission {
    /**
     * 
     * @type {RolePermissionId}
     * @memberof RolePermission
     */
    'id'?: RolePermissionId;
    /**
     * 
     * @type {Permission}
     * @memberof RolePermission
     */
    'permission'?: Permission;
    /**
     * 
     * @type {Role}
     * @memberof RolePermission
     */
    'role'?: Role;
}
/**
 * 
 * @export
 * @interface RolePermissionId
 */
export interface RolePermissionId {
    /**
     * 
     * @type {number}
     * @memberof RolePermissionId
     */
    'permissionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RolePermissionId
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface SSOCodeLoginRequest
 */
export interface SSOCodeLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof SSOCodeLoginRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface SendCredentialsEmailRequest
 */
export interface SendCredentialsEmailRequest {
    /**
     * 
     * @type {Set<string>}
     * @memberof SendCredentialsEmailRequest
     */
    'emails': Set<string>;
}
/**
 * 
 * @export
 * @interface SendCredentialsEmailResponse
 */
export interface SendCredentialsEmailResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof SendCredentialsEmailResponse
     */
    'emailsAlreadyActive': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendCredentialsEmailResponse
     */
    'emailsFound': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendCredentialsEmailResponse
     */
    'emailsNotFound': Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SendCredentialsEmailResponse
     */
    'erroredEmails': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface SourceControlActivityEvent
 */
export interface SourceControlActivityEvent {
    /**
     * 
     * @type {Employee}
     * @memberof SourceControlActivityEvent
     */
    'employee'?: Employee;
    /**
     * 
     * @type {string}
     * @memberof SourceControlActivityEvent
     */
    'eventDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof SourceControlActivityEvent
     */
    'eventDateInMillis'?: number;
    /**
     * 
     * @type {number}
     * @memberof SourceControlActivityEvent
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SourceControlActivityEvent
     */
    'payload'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceControlActivityEvent
     */
    'repository'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceControlActivityEvent
     */
    'type'?: SourceControlActivityEventTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SourceControlActivityEvent
     */
    'value'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SourceControlActivityEventTypeEnum {
    ActivityFilesModified = 'ACTIVITY_FILES_MODIFIED',
    ActivityLinesAdded = 'ACTIVITY_LINES_ADDED',
    ActivityLinesRemoved = 'ACTIVITY_LINES_REMOVED',
    PrApproved = 'PR_APPROVED',
    PrClosed = 'PR_CLOSED',
    PrCreated = 'PR_CREATED',
    ReviewApproved = 'REVIEW_APPROVED',
    ReviewComment = 'REVIEW_COMMENT',
    ReviewRequireChanges = 'REVIEW_REQUIRE_CHANGES',
    ReviewSuggestions = 'REVIEW_SUGGESTIONS'
}

/**
 * 
 * @export
 * @interface SuggestionBoxMessageRequest
 */
export interface SuggestionBoxMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof SuggestionBoxMessageRequest
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {Set<Team>}
     * @memberof Team
     */
    'children'?: Set<Team>;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'createdDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Team
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Set<Employee>}
     * @memberof Team
     */
    'employees'?: Set<Employee>;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'lastModifiedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {Set<Employee>}
     * @memberof Team
     */
    'leaders'?: Set<Employee>;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'name'?: string;
    /**
     * 
     * @type {Team}
     * @memberof Team
     */
    'parent'?: Team;
    /**
     * 
     * @type {Set<TeamMember>}
     * @memberof Team
     */
    'teamMembers'?: Set<TeamMember>;
}
/**
 * 
 * @export
 * @interface TeamAssessmentsReportResponse
 */
export interface TeamAssessmentsReportResponse {
    /**
     * 
     * @type {number}
     * @memberof TeamAssessmentsReportResponse
     */
    'finished'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamAssessmentsReportResponse
     */
    'id'?: number;
    /**
     * 
     * @type {Set<string>}
     * @memberof TeamAssessmentsReportResponse
     */
    'leaders'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof TeamAssessmentsReportResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamAssessmentsReportResponse
     */
    'parentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamAssessmentsReportResponse
     */
    'skipped'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamAssessmentsReportResponse
     */
    'teamSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamAssessmentsReportResponse
     */
    'unfinished'?: number;
}
/**
 * 
 * @export
 * @interface TeamDeepResponse
 */
export interface TeamDeepResponse {
    /**
     * 
     * @type {Array<TeamDeepResponse>}
     * @memberof TeamDeepResponse
     */
    'children': Array<TeamDeepResponse>;
    /**
     * 
     * @type {string}
     * @memberof TeamDeepResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {Array<EmployeeInfoResponse>}
     * @memberof TeamDeepResponse
     */
    'employees': Array<EmployeeInfoResponse>;
    /**
     * 
     * @type {number}
     * @memberof TeamDeepResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamDeepResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {Array<EmployeeInfoResponse>}
     * @memberof TeamDeepResponse
     */
    'leaders': Array<EmployeeInfoResponse>;
    /**
     * 
     * @type {string}
     * @memberof TeamDeepResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TeamEmployeesResponse
 */
export interface TeamEmployeesResponse {
    /**
     * 
     * @type {string}
     * @memberof TeamEmployeesResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {Array<EmployeeInfoResponse>}
     * @memberof TeamEmployeesResponse
     */
    'employees': Array<EmployeeInfoResponse>;
    /**
     * 
     * @type {number}
     * @memberof TeamEmployeesResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamEmployeesResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {Array<EmployeeInfoResponse>}
     * @memberof TeamEmployeesResponse
     */
    'leaders': Array<EmployeeInfoResponse>;
    /**
     * 
     * @type {string}
     * @memberof TeamEmployeesResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TeamInfoResponse
 */
export interface TeamInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof TeamInfoResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamInfoResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamInfoResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {Array<EmployeeInfoResponse>}
     * @memberof TeamInfoResponse
     */
    'leaders': Array<EmployeeInfoResponse>;
    /**
     * 
     * @type {string}
     * @memberof TeamInfoResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TeamMember
 */
export interface TeamMember {
    /**
     * 
     * @type {number}
     * @memberof TeamMember
     */
    'depth'?: number;
    /**
     * 
     * @type {Team}
     * @memberof TeamMember
     */
    'directParentTeam'?: Team;
    /**
     * 
     * @type {Team}
     * @memberof TeamMember
     */
    'directTeam'?: Team;
    /**
     * 
     * @type {Employee}
     * @memberof TeamMember
     */
    'employee'?: Employee;
    /**
     * 
     * @type {Team}
     * @memberof TeamMember
     */
    'team'?: Team;
}
/**
 * 
 * @export
 * @interface TeamResponse
 */
export interface TeamResponse {
    /**
     * 
     * @type {Array<TeamEmployeesResponse>}
     * @memberof TeamResponse
     */
    'children': Array<TeamEmployeesResponse>;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {Array<EmployeeInfoResponse>}
     * @memberof TeamResponse
     */
    'employees': Array<EmployeeInfoResponse>;
    /**
     * 
     * @type {number}
     * @memberof TeamResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {Array<EmployeeInfoResponse>}
     * @memberof TeamResponse
     */
    'leaders': Array<EmployeeInfoResponse>;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TeamStatusDTO
 */
export interface TeamStatusDTO {
    /**
     * 
     * @type {number}
     * @memberof TeamStatusDTO
     */
    'burnouts'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamStatusDTO
     */
    'finished'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamStatusDTO
     */
    'flightRisks'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamStatusDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamStatusDTO
     */
    'lastUpdate'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof TeamStatusDTO
     */
    'leaders'?: Set<string>;
    /**
     * 
     * @type {number}
     * @memberof TeamStatusDTO
     */
    'mismatches'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamStatusDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamStatusDTO
     */
    'parent'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamStatusDTO
     */
    'score'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamStatusDTO
     */
    'teamSize'?: number;
}
/**
 * 
 * @export
 * @interface TeamTreeResponse
 */
export interface TeamTreeResponse {
    /**
     * 
     * @type {Array<TeamTreeResponse>}
     * @memberof TeamTreeResponse
     */
    'children': Array<TeamTreeResponse>;
    /**
     * 
     * @type {string}
     * @memberof TeamTreeResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamTreeResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamTreeResponse
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {Array<EmployeeInfoResponse>}
     * @memberof TeamTreeResponse
     */
    'leaders': Array<EmployeeInfoResponse>;
    /**
     * 
     * @type {string}
     * @memberof TeamTreeResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Topic
 */
export interface Topic {
    /**
     * 
     * @type {number}
     * @memberof Topic
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'topicName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Topic
     */
    'topicOrder'?: number;
}
/**
 * 
 * @export
 * @interface TopicResponse
 */
export interface TopicResponse {
    /**
     * 
     * @type {number}
     * @memberof TopicResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TopicResponse
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicResponse
     */
    'topicName': string;
    /**
     * 
     * @type {number}
     * @memberof TopicResponse
     */
    'topicOrder': number;
}
/**
 * 
 * @export
 * @interface UniqueReview
 */
export interface UniqueReview {
    /**
     * 
     * @type {Review}
     * @memberof UniqueReview
     */
    'review'?: Review;
    /**
     * 
     * @type {Employee}
     * @memberof UniqueReview
     */
    'reviewedEmployee'?: Employee;
}
/**
 * 
 * @export
 * @interface UpdateEmployeeRequest
 */
export interface UpdateEmployeeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeRequest
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeRequest
     */
    'msId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeRequest
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeRequest
     */
    'salaryGrade'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateEmployeeRequest
     */
    'teams'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateTeamRequest
 */
export interface UpdateTeamRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateTeamRequest
     */
    'leaders': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UsageReportSummaryResponse
 */
export interface UsageReportSummaryResponse {
    /**
     * 
     * @type {LeadersSummaryUsage}
     * @memberof UsageReportSummaryResponse
     */
    'leadersSummaryUsage'?: LeadersSummaryUsage;
    /**
     * 
     * @type {MembersSummaryUsage}
     * @memberof UsageReportSummaryResponse
     */
    'membersSummaryUsage'?: MembersSummaryUsage;
}

/**
 * ActivityControllerApi - axios parameter creator
 * @export
 */
export const ActivityControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {AddActivityEventRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST: async (request: AddActivityEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createUsingPOST', 'request', request)
            const localVarPath = `/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityControllerApi - functional programming interface
 * @export
 */
export const ActivityControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {AddActivityEventRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsingPOST(request: AddActivityEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SourceControlActivityEvent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsingPOST(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityControllerApi - factory interface
 * @export
 */
export const ActivityControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {AddActivityEventRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST(request: AddActivityEventRequest, options?: any): AxiosPromise<Array<SourceControlActivityEvent>> {
            return localVarFp.createUsingPOST(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityControllerApi - object-oriented interface
 * @export
 * @class ActivityControllerApi
 * @extends {BaseAPI}
 */
export class ActivityControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {AddActivityEventRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityControllerApi
     */
    public createUsingPOST(request: AddActivityEventRequest, options?: AxiosRequestConfig) {
        return ActivityControllerApiFp(this.configuration).createUsingPOST(request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigControllerApi - axios parameter creator
 * @export
 */
export const ConfigControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get configuration values
         * @param {Array<string>} fields Comma separated list of requested configuration fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigValuesUsingGET: async (fields: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fields' is not null or undefined
            assertParamExists('getConfigValuesUsingGET', 'fields', fields)
            const localVarPath = `/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigControllerApi - functional programming interface
 * @export
 */
export const ConfigControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get configuration values
         * @param {Array<string>} fields Comma separated list of requested configuration fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigValuesUsingGET(fields: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigValuesUsingGET(fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigControllerApi - factory interface
 * @export
 */
export const ConfigControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get configuration values
         * @param {Array<string>} fields Comma separated list of requested configuration fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigValuesUsingGET(fields: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.getConfigValuesUsingGET(fields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigControllerApi - object-oriented interface
 * @export
 * @class ConfigControllerApi
 * @extends {BaseAPI}
 */
export class ConfigControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get configuration values
     * @param {Array<string>} fields Comma separated list of requested configuration fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigControllerApi
     */
    public getConfigValuesUsingGET(fields: Array<string>, options?: AxiosRequestConfig) {
        return ConfigControllerApiFp(this.configuration).getConfigValuesUsingGET(fields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmployeeControllerApi - axios parameter creator
 * @export
 */
export const EmployeeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary changePassword
         * @param {ChangePasswordRequest} passwordChangeRequest passwordChangeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST: async (passwordChangeRequest: ChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordChangeRequest' is not null or undefined
            assertParamExists('changePasswordUsingPOST', 'passwordChangeRequest', passwordChangeRequest)
            const localVarPath = `/employees/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an employee
         * @param {CreateEmployeeRequest} employeeRequest Data needed to create the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST1: async (employeeRequest: CreateEmployeeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeRequest' is not null or undefined
            assertParamExists('createUsingPOST1', 'employeeRequest', employeeRequest)
            const localVarPath = `/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an employee by Id
         * @param {number} id Id of the employee to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUsingDELETE', 'id', id)
            const localVarPath = `/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get basic info for all not deleted users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/employees/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all not deleted users
         * @param {number} [page] Page number
         * @param {number} [size] Size of the page
         * @param {string} [sortDirection] Direction of sort
         * @param {string} [sortField] Field to sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAsAdminUsingGET: async (page?: number, size?: number, sortDirection?: string, sortField?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/employees/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get employees by name
         * @param {string} name Name of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByNameUsingGET: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('findByNameUsingGET', 'name', name)
            const localVarPath = `/employees/byName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get job titles of all the not deleted users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findJobTitleUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/employees/jobTitles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all not deleted users you can access
         * @param {number} [depth] Depth of teams to explore
         * @param {number} [periodId] periodId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsingGET: async (depth?: number, periodId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/employees/periods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }

            if (periodId !== undefined) {
                localVarQueryParameter['periodId'] = periodId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all not deleted users you can access
         * @param {number} [depth] Depth of teams to explore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsingGET1: async (depth?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all not deleted users directly under an employee
         * @param {number} id Id of the employee
         * @param {number} [depth] Depth of teams to explore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsingGET2: async (id: number, depth?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findUsingGET2', 'id', id)
            const localVarPath = `/employees/direct/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GenerateTokenSSOCode
         * @param {SSOCodeLoginRequest} ssoCodeRequest ssoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenSSOCodeUsingPOST: async (ssoCodeRequest: SSOCodeLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ssoCodeRequest' is not null or undefined
            assertParamExists('generateTokenSSOCodeUsingPOST', 'ssoCodeRequest', ssoCodeRequest)
            const localVarPath = `/employees/login/ssoCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ssoCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GenerateToken
         * @param {LoginRequest} loginRequest loginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenUsingPOST: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('generateTokenUsingPOST', 'loginRequest', loginRequest)
            const localVarPath = `/employees/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GenerateToken
         * @param {LoginTokenRequest} loginTokenRequest loginTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenUsingPOST1: async (loginTokenRequest: LoginTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginTokenRequest' is not null or undefined
            assertParamExists('generateTokenUsingPOST1', 'loginTokenRequest', loginTokenRequest)
            const localVarPath = `/employees/login/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get activity calendar for user
         * @param {number} id Id of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityCalendarUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityCalendarUsingGET', 'id', id)
            const localVarPath = `/employees/{id}/activity/calendar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get activity summary for user
         * @param {string} before Filter events before this date
         * @param {number} id Id of the employee
         * @param {string} [after] Filter events after this date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityUsingGET: async (before: string, id: number, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'before' is not null or undefined
            assertParamExists('getActivityUsingGET', 'before', before)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityUsingGET', 'id', id)
            const localVarPath = `/employees/{id}/activity`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an employee by Id
         * @param {number} id Id of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getByIdUsingGET', 'id', id)
            const localVarPath = `/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/employees/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the information for the dashboard for the given period
         * @param {number} [periodId] Finds the highlights for the period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardInformationUsingGET: async (periodId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/employees/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (periodId !== undefined) {
                localVarQueryParameter['periodId'] = periodId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get reviews for reviewed employee
         * @param {string} before Filter reviews created before this date
         * @param {number} id Id of the employee
         * @param {string} [after] Filter reviews created after this date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsByReviewedEmployeeUsingGET: async (before: string, id: number, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'before' is not null or undefined
            assertParamExists('getReviewsByReviewedEmployeeUsingGET', 'before', before)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewsByReviewedEmployeeUsingGET', 'id', id)
            const localVarPath = `/employees/{id}/reviews`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get simplified reviews for employee
         * @param {number} id Id of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsSimpleByEmployeeUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReviewsSimpleByEmployeeUsingGET', 'id', id)
            const localVarPath = `/employees/{id}/reviews/simple`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary metaSearch
         * @param {EmployeeMetaSearchRequest} metaSearchRequest metaSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaSearchUsingPOST: async (metaSearchRequest: EmployeeMetaSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metaSearchRequest' is not null or undefined
            assertParamExists('metaSearchUsingPOST', 'metaSearchRequest', metaSearchRequest)
            const localVarPath = `/employees/metaSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metaSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sendCredentials
         * @param {SendCredentialsEmailRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCredentialsUsingPOST: async (request: SendCredentialsEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('sendCredentialsUsingPOST', 'request', request)
            const localVarPath = `/employees/sendCredentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update employee
         * @param {number} id Id of the employee to be updated
         * @param {UpdateEmployeeRequest} request Data needed to create the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmployeeTeamsUsingPATCH: async (id: number, request: UpdateEmployeeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setEmployeeTeamsUsingPATCH', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('setEmployeeTeamsUsingPATCH', 'request', request)
            const localVarPath = `/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set employee teams
         * @param {number} id Id of the employee to be updated
         * @param {Array<number>} teamsIds List of team IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmployeeTeamsUsingPUT: async (id: number, teamsIds: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setEmployeeTeamsUsingPUT', 'id', id)
            // verify required parameter 'teamsIds' is not null or undefined
            assertParamExists('setEmployeeTeamsUsingPUT', 'teamsIds', teamsIds)
            const localVarPath = `/employees/{id}/teams`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamsIds, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeControllerApi - functional programming interface
 * @export
 */
export const EmployeeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary changePassword
         * @param {ChangePasswordRequest} passwordChangeRequest passwordChangeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePasswordUsingPOST(passwordChangeRequest: ChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePasswordUsingPOST(passwordChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates an employee
         * @param {CreateEmployeeRequest} employeeRequest Data needed to create the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsingPOST1(employeeRequest: CreateEmployeeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeWithTeamsAndReviewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsingPOST1(employeeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an employee by Id
         * @param {number} id Id of the employee to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get basic info for all not deleted users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all not deleted users
         * @param {number} [page] Page number
         * @param {number} [size] Size of the page
         * @param {string} [sortDirection] Direction of sort
         * @param {string} [sortField] Field to sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAsAdminUsingGET(page?: number, size?: number, sortDirection?: string, sortField?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEmployeeSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAsAdminUsingGET(page, size, sortDirection, sortField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get employees by name
         * @param {string} name Name of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByNameUsingGET(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByNameUsingGET(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get job titles of all the not deleted users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findJobTitleUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findJobTitleUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all not deleted users you can access
         * @param {number} [depth] Depth of teams to explore
         * @param {number} [periodId] periodId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUsingGET(depth?: number, periodId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeSummaryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUsingGET(depth, periodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all not deleted users you can access
         * @param {number} [depth] Depth of teams to explore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUsingGET1(depth?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeSummaryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUsingGET1(depth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all not deleted users directly under an employee
         * @param {number} id Id of the employee
         * @param {number} [depth] Depth of teams to explore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUsingGET2(id: number, depth?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeSummaryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUsingGET2(id, depth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GenerateTokenSSOCode
         * @param {SSOCodeLoginRequest} ssoCodeRequest ssoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTokenSSOCodeUsingPOST(ssoCodeRequest: SSOCodeLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTokenSSOCodeUsingPOST(ssoCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GenerateToken
         * @param {LoginRequest} loginRequest loginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTokenUsingPOST(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTokenUsingPOST(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GenerateToken
         * @param {LoginTokenRequest} loginTokenRequest loginTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTokenUsingPOST1(loginTokenRequest: LoginTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTokenUsingPOST1(loginTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get activity calendar for user
         * @param {number} id Id of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityCalendarUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityCalendarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityCalendarUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get activity summary for user
         * @param {string} before Filter events before this date
         * @param {number} id Id of the employee
         * @param {string} [after] Filter events after this date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityUsingGET(before: string, id: number, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityUsingGET(before, id, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an employee by Id
         * @param {number} id Id of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeWithTeamsAndReviewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeWithTeamsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the information for the dashboard for the given period
         * @param {number} [periodId] Finds the highlights for the period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardInformationUsingGET(periodId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardInformationUsingGET(periodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get reviews for reviewed employee
         * @param {string} before Filter reviews created before this date
         * @param {number} id Id of the employee
         * @param {string} [after] Filter reviews created after this date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewsByReviewedEmployeeUsingGET(before: string, id: number, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReviewWithAnswersResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewsByReviewedEmployeeUsingGET(before, id, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get simplified reviews for employee
         * @param {number} id Id of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewsSimpleByEmployeeUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<ReviewSimpleProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewsSimpleByEmployeeUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary metaSearch
         * @param {EmployeeMetaSearchRequest} metaSearchRequest metaSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaSearchUsingPOST(metaSearchRequest: EmployeeMetaSearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeMetaSearchResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaSearchUsingPOST(metaSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sendCredentials
         * @param {SendCredentialsEmailRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendCredentialsUsingPOST(request: SendCredentialsEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendCredentialsEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendCredentialsUsingPOST(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update employee
         * @param {number} id Id of the employee to be updated
         * @param {UpdateEmployeeRequest} request Data needed to create the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEmployeeTeamsUsingPATCH(id: number, request: UpdateEmployeeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeWithTeamsAndReviewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEmployeeTeamsUsingPATCH(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set employee teams
         * @param {number} id Id of the employee to be updated
         * @param {Array<number>} teamsIds List of team IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEmployeeTeamsUsingPUT(id: number, teamsIds: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeWithTeamsAndReviewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEmployeeTeamsUsingPUT(id, teamsIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmployeeControllerApi - factory interface
 * @export
 */
export const EmployeeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary changePassword
         * @param {ChangePasswordRequest} passwordChangeRequest passwordChangeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST(passwordChangeRequest: ChangePasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changePasswordUsingPOST(passwordChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an employee
         * @param {CreateEmployeeRequest} employeeRequest Data needed to create the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST1(employeeRequest: CreateEmployeeRequest, options?: any): AxiosPromise<EmployeeWithTeamsAndReviewsResponse> {
            return localVarFp.createUsingPOST1(employeeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an employee by Id
         * @param {number} id Id of the employee to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get basic info for all not deleted users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUsingGET(options?: any): AxiosPromise<Array<EmployeeInfoResponse>> {
            return localVarFp.findAllUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all not deleted users
         * @param {number} [page] Page number
         * @param {number} [size] Size of the page
         * @param {string} [sortDirection] Direction of sort
         * @param {string} [sortField] Field to sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAsAdminUsingGET(page?: number, size?: number, sortDirection?: string, sortField?: string, options?: any): AxiosPromise<PageEmployeeSummaryResponse> {
            return localVarFp.findAsAdminUsingGET(page, size, sortDirection, sortField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get employees by name
         * @param {string} name Name of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByNameUsingGET(name: string, options?: any): AxiosPromise<Array<EmployeeResponse>> {
            return localVarFp.findByNameUsingGET(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get job titles of all the not deleted users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findJobTitleUsingGET(options?: any): AxiosPromise<Set<string>> {
            return localVarFp.findJobTitleUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all not deleted users you can access
         * @param {number} [depth] Depth of teams to explore
         * @param {number} [periodId] periodId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsingGET(depth?: number, periodId?: number, options?: any): AxiosPromise<Array<EmployeeSummaryResponse>> {
            return localVarFp.findUsingGET(depth, periodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all not deleted users you can access
         * @param {number} [depth] Depth of teams to explore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsingGET1(depth?: number, options?: any): AxiosPromise<Array<EmployeeSummaryResponse>> {
            return localVarFp.findUsingGET1(depth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all not deleted users directly under an employee
         * @param {number} id Id of the employee
         * @param {number} [depth] Depth of teams to explore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsingGET2(id: number, depth?: number, options?: any): AxiosPromise<Array<EmployeeSummaryResponse>> {
            return localVarFp.findUsingGET2(id, depth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GenerateTokenSSOCode
         * @param {SSOCodeLoginRequest} ssoCodeRequest ssoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenSSOCodeUsingPOST(ssoCodeRequest: SSOCodeLoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.generateTokenSSOCodeUsingPOST(ssoCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GenerateToken
         * @param {LoginRequest} loginRequest loginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenUsingPOST(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.generateTokenUsingPOST(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GenerateToken
         * @param {LoginTokenRequest} loginTokenRequest loginTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenUsingPOST1(loginTokenRequest: LoginTokenRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.generateTokenUsingPOST1(loginTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get activity calendar for user
         * @param {number} id Id of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityCalendarUsingGET(id: number, options?: any): AxiosPromise<ActivityCalendarResponse> {
            return localVarFp.getActivityCalendarUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get activity summary for user
         * @param {string} before Filter events before this date
         * @param {number} id Id of the employee
         * @param {string} [after] Filter events after this date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityUsingGET(before: string, id: number, after?: string, options?: any): AxiosPromise<ActivitySummaryResponse> {
            return localVarFp.getActivityUsingGET(before, id, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an employee by Id
         * @param {number} id Id of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET(id: number, options?: any): AxiosPromise<EmployeeWithTeamsAndReviewsResponse> {
            return localVarFp.getByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserUsingGET(options?: any): AxiosPromise<EmployeeWithTeamsResponse> {
            return localVarFp.getCurrentUserUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the information for the dashboard for the given period
         * @param {number} [periodId] Finds the highlights for the period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardInformationUsingGET(periodId?: number, options?: any): AxiosPromise<Array<EmployeeResponse>> {
            return localVarFp.getDashboardInformationUsingGET(periodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get reviews for reviewed employee
         * @param {string} before Filter reviews created before this date
         * @param {number} id Id of the employee
         * @param {string} [after] Filter reviews created after this date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsByReviewedEmployeeUsingGET(before: string, id: number, after?: string, options?: any): AxiosPromise<Array<ReviewWithAnswersResponse>> {
            return localVarFp.getReviewsByReviewedEmployeeUsingGET(before, id, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get simplified reviews for employee
         * @param {number} id Id of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsSimpleByEmployeeUsingGET(id: number, options?: any): AxiosPromise<Set<ReviewSimpleProjection>> {
            return localVarFp.getReviewsSimpleByEmployeeUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary metaSearch
         * @param {EmployeeMetaSearchRequest} metaSearchRequest metaSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaSearchUsingPOST(metaSearchRequest: EmployeeMetaSearchRequest, options?: any): AxiosPromise<Array<EmployeeMetaSearchResponse>> {
            return localVarFp.metaSearchUsingPOST(metaSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sendCredentials
         * @param {SendCredentialsEmailRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCredentialsUsingPOST(request: SendCredentialsEmailRequest, options?: any): AxiosPromise<SendCredentialsEmailResponse> {
            return localVarFp.sendCredentialsUsingPOST(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update employee
         * @param {number} id Id of the employee to be updated
         * @param {UpdateEmployeeRequest} request Data needed to create the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmployeeTeamsUsingPATCH(id: number, request: UpdateEmployeeRequest, options?: any): AxiosPromise<EmployeeWithTeamsAndReviewsResponse> {
            return localVarFp.setEmployeeTeamsUsingPATCH(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set employee teams
         * @param {number} id Id of the employee to be updated
         * @param {Array<number>} teamsIds List of team IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmployeeTeamsUsingPUT(id: number, teamsIds: Array<number>, options?: any): AxiosPromise<EmployeeWithTeamsAndReviewsResponse> {
            return localVarFp.setEmployeeTeamsUsingPUT(id, teamsIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeControllerApi - object-oriented interface
 * @export
 * @class EmployeeControllerApi
 * @extends {BaseAPI}
 */
export class EmployeeControllerApi extends BaseAPI {
    /**
     * 
     * @summary changePassword
     * @param {ChangePasswordRequest} passwordChangeRequest passwordChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public changePasswordUsingPOST(passwordChangeRequest: ChangePasswordRequest, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).changePasswordUsingPOST(passwordChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an employee
     * @param {CreateEmployeeRequest} employeeRequest Data needed to create the employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public createUsingPOST1(employeeRequest: CreateEmployeeRequest, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).createUsingPOST1(employeeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an employee by Id
     * @param {number} id Id of the employee to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public deleteUsingDELETE(id: number, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).deleteUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get basic info for all not deleted users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public findAllUsingGET(options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).findAllUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all not deleted users
     * @param {number} [page] Page number
     * @param {number} [size] Size of the page
     * @param {string} [sortDirection] Direction of sort
     * @param {string} [sortField] Field to sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public findAsAdminUsingGET(page?: number, size?: number, sortDirection?: string, sortField?: string, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).findAsAdminUsingGET(page, size, sortDirection, sortField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get employees by name
     * @param {string} name Name of the employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public findByNameUsingGET(name: string, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).findByNameUsingGET(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get job titles of all the not deleted users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public findJobTitleUsingGET(options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).findJobTitleUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all not deleted users you can access
     * @param {number} [depth] Depth of teams to explore
     * @param {number} [periodId] periodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public findUsingGET(depth?: number, periodId?: number, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).findUsingGET(depth, periodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all not deleted users you can access
     * @param {number} [depth] Depth of teams to explore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public findUsingGET1(depth?: number, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).findUsingGET1(depth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all not deleted users directly under an employee
     * @param {number} id Id of the employee
     * @param {number} [depth] Depth of teams to explore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public findUsingGET2(id: number, depth?: number, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).findUsingGET2(id, depth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GenerateTokenSSOCode
     * @param {SSOCodeLoginRequest} ssoCodeRequest ssoCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public generateTokenSSOCodeUsingPOST(ssoCodeRequest: SSOCodeLoginRequest, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).generateTokenSSOCodeUsingPOST(ssoCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GenerateToken
     * @param {LoginRequest} loginRequest loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public generateTokenUsingPOST(loginRequest: LoginRequest, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).generateTokenUsingPOST(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GenerateToken
     * @param {LoginTokenRequest} loginTokenRequest loginTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public generateTokenUsingPOST1(loginTokenRequest: LoginTokenRequest, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).generateTokenUsingPOST1(loginTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get activity calendar for user
     * @param {number} id Id of the employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getActivityCalendarUsingGET(id: number, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getActivityCalendarUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get activity summary for user
     * @param {string} before Filter events before this date
     * @param {number} id Id of the employee
     * @param {string} [after] Filter events after this date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getActivityUsingGET(before: string, id: number, after?: string, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getActivityUsingGET(before, id, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an employee by Id
     * @param {number} id Id of the employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getByIdUsingGET(id: number, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getCurrentUserUsingGET(options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getCurrentUserUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the information for the dashboard for the given period
     * @param {number} [periodId] Finds the highlights for the period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getDashboardInformationUsingGET(periodId?: number, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getDashboardInformationUsingGET(periodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get reviews for reviewed employee
     * @param {string} before Filter reviews created before this date
     * @param {number} id Id of the employee
     * @param {string} [after] Filter reviews created after this date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getReviewsByReviewedEmployeeUsingGET(before: string, id: number, after?: string, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getReviewsByReviewedEmployeeUsingGET(before, id, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get simplified reviews for employee
     * @param {number} id Id of the employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getReviewsSimpleByEmployeeUsingGET(id: number, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getReviewsSimpleByEmployeeUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary metaSearch
     * @param {EmployeeMetaSearchRequest} metaSearchRequest metaSearchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public metaSearchUsingPOST(metaSearchRequest: EmployeeMetaSearchRequest, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).metaSearchUsingPOST(metaSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sendCredentials
     * @param {SendCredentialsEmailRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public sendCredentialsUsingPOST(request: SendCredentialsEmailRequest, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).sendCredentialsUsingPOST(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update employee
     * @param {number} id Id of the employee to be updated
     * @param {UpdateEmployeeRequest} request Data needed to create the employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public setEmployeeTeamsUsingPATCH(id: number, request: UpdateEmployeeRequest, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).setEmployeeTeamsUsingPATCH(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set employee teams
     * @param {number} id Id of the employee to be updated
     * @param {Array<number>} teamsIds List of team IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public setEmployeeTeamsUsingPUT(id: number, teamsIds: Array<number>, options?: AxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).setEmployeeTeamsUsingPUT(id, teamsIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventTrackingControllerApi - axios parameter creator
 * @export
 */
export const EventTrackingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary saveTrackingEvent
         * @param {Array<CreateTrackingEventRequest>} trackingEventsRequest trackingEventsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrackingEventUsingPOST: async (trackingEventsRequest: Array<CreateTrackingEventRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackingEventsRequest' is not null or undefined
            assertParamExists('saveTrackingEventUsingPOST', 'trackingEventsRequest', trackingEventsRequest)
            const localVarPath = `/uievent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackingEventsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventTrackingControllerApi - functional programming interface
 * @export
 */
export const EventTrackingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventTrackingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary saveTrackingEvent
         * @param {Array<CreateTrackingEventRequest>} trackingEventsRequest trackingEventsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTrackingEventUsingPOST(trackingEventsRequest: Array<CreateTrackingEventRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTrackingEventUsingPOST(trackingEventsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventTrackingControllerApi - factory interface
 * @export
 */
export const EventTrackingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventTrackingControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary saveTrackingEvent
         * @param {Array<CreateTrackingEventRequest>} trackingEventsRequest trackingEventsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrackingEventUsingPOST(trackingEventsRequest: Array<CreateTrackingEventRequest>, options?: any): AxiosPromise<void> {
            return localVarFp.saveTrackingEventUsingPOST(trackingEventsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventTrackingControllerApi - object-oriented interface
 * @export
 * @class EventTrackingControllerApi
 * @extends {BaseAPI}
 */
export class EventTrackingControllerApi extends BaseAPI {
    /**
     * 
     * @summary saveTrackingEvent
     * @param {Array<CreateTrackingEventRequest>} trackingEventsRequest trackingEventsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTrackingControllerApi
     */
    public saveTrackingEventUsingPOST(trackingEventsRequest: Array<CreateTrackingEventRequest>, options?: AxiosRequestConfig) {
        return EventTrackingControllerApiFp(this.configuration).saveTrackingEventUsingPOST(trackingEventsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FormControllerApi - axios parameter creator
 * @export
 */
export const FormControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary findByCategory
         * @param {string} [tag] Filter by tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCategoryUsingGET: async (tag?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forms/byCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary find
         * @param {string} [tag] Filter by tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsingGET3: async (tag?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getById
         * @param {number} id Id of the form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getByIdUsingGET1', 'id', id)
            const localVarPath = `/forms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormControllerApi - functional programming interface
 * @export
 */
export const FormControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary findByCategory
         * @param {string} [tag] Filter by tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByCategoryUsingGET(tag?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByCategoryUsingGET(tag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary find
         * @param {string} [tag] Filter by tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUsingGET3(tag?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUsingGET3(tag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getById
         * @param {number} id Id of the form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdUsingGET1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdUsingGET1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FormControllerApi - factory interface
 * @export
 */
export const FormControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary findByCategory
         * @param {string} [tag] Filter by tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCategoryUsingGET(tag?: string, options?: any): AxiosPromise<Array<FormCategoryResponse>> {
            return localVarFp.findByCategoryUsingGET(tag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary find
         * @param {string} [tag] Filter by tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsingGET3(tag?: string, options?: any): AxiosPromise<Array<FormInfoResponse>> {
            return localVarFp.findUsingGET3(tag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getById
         * @param {number} id Id of the form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET1(id: number, options?: any): AxiosPromise<FormResponse> {
            return localVarFp.getByIdUsingGET1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FormControllerApi - object-oriented interface
 * @export
 * @class FormControllerApi
 * @extends {BaseAPI}
 */
export class FormControllerApi extends BaseAPI {
    /**
     * 
     * @summary findByCategory
     * @param {string} [tag] Filter by tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormControllerApi
     */
    public findByCategoryUsingGET(tag?: string, options?: AxiosRequestConfig) {
        return FormControllerApiFp(this.configuration).findByCategoryUsingGET(tag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary find
     * @param {string} [tag] Filter by tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormControllerApi
     */
    public findUsingGET3(tag?: string, options?: AxiosRequestConfig) {
        return FormControllerApiFp(this.configuration).findUsingGET3(tag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getById
     * @param {number} id Id of the form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormControllerApi
     */
    public getByIdUsingGET1(id: number, options?: AxiosRequestConfig) {
        return FormControllerApiFp(this.configuration).getByIdUsingGET1(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GithubControllerApi - axios parameter creator
 * @export
 */
export const GithubControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/github`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GithubControllerApi - functional programming interface
 * @export
 */
export const GithubControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GithubControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddActivityEventRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GithubControllerApi - factory interface
 * @export
 */
export const GithubControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GithubControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET(options?: any): AxiosPromise<Array<AddActivityEventRequest>> {
            return localVarFp.getUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GithubControllerApi - object-oriented interface
 * @export
 * @class GithubControllerApi
 * @extends {BaseAPI}
 */
export class GithubControllerApi extends BaseAPI {
    /**
     * 
     * @summary get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GithubControllerApi
     */
    public getUsingGET(options?: AxiosRequestConfig) {
        return GithubControllerApiFp(this.configuration).getUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MapScoreControllerApi - axios parameter creator
 * @export
 */
export const MapScoreControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a list of scores by year for the given employee
         * @param {number} employeeId Employee ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapScoreUsingGET: async (employeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getMapScoreUsingGET', 'employeeId', employeeId)
            const localVarPath = `/map-scores/employee/{employeeId}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Imports a CSV file with scores by year for each employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importMapScoresUsingPOST: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/map-scores/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MapScoreControllerApi - functional programming interface
 * @export
 */
export const MapScoreControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MapScoreControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a list of scores by year for the given employee
         * @param {number} employeeId Employee ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMapScoreUsingGET(employeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MapScoreResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMapScoreUsingGET(employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Imports a CSV file with scores by year for each employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importMapScoresUsingPOST(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importMapScoresUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MapScoreControllerApi - factory interface
 * @export
 */
export const MapScoreControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MapScoreControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a list of scores by year for the given employee
         * @param {number} employeeId Employee ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapScoreUsingGET(employeeId: number, options?: any): AxiosPromise<Array<MapScoreResponse>> {
            return localVarFp.getMapScoreUsingGET(employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Imports a CSV file with scores by year for each employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importMapScoresUsingPOST(options?: any): AxiosPromise<void> {
            return localVarFp.importMapScoresUsingPOST(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MapScoreControllerApi - object-oriented interface
 * @export
 * @class MapScoreControllerApi
 * @extends {BaseAPI}
 */
export class MapScoreControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get a list of scores by year for the given employee
     * @param {number} employeeId Employee ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapScoreControllerApi
     */
    public getMapScoreUsingGET(employeeId: number, options?: AxiosRequestConfig) {
        return MapScoreControllerApiFp(this.configuration).getMapScoreUsingGET(employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Imports a CSV file with scores by year for each employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapScoreControllerApi
     */
    public importMapScoresUsingPOST(options?: AxiosRequestConfig) {
        return MapScoreControllerApiFp(this.configuration).importMapScoresUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OperationHandlerApi - axios parameter creator
 * @export
 */
export const OperationHandlerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET: async (body?: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET1: async (body?: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/health/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET2: async (body?: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperationHandlerApi - functional programming interface
 * @export
 */
export const OperationHandlerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperationHandlerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleUsingGET(body?: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleUsingGET(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleUsingGET1(body?: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleUsingGET1(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleUsingGET2(body?: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleUsingGET2(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OperationHandlerApi - factory interface
 * @export
 */
export const OperationHandlerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperationHandlerApiFp(configuration)
    return {
        /**
         * 
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET(body?: { [key: string]: string; }, options?: any): AxiosPromise<object> {
            return localVarFp.handleUsingGET(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET1(body?: { [key: string]: string; }, options?: any): AxiosPromise<object> {
            return localVarFp.handleUsingGET1(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary handle
         * @param {{ [key: string]: string; }} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET2(body?: { [key: string]: string; }, options?: any): AxiosPromise<object> {
            return localVarFp.handleUsingGET2(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperationHandlerApi - object-oriented interface
 * @export
 * @class OperationHandlerApi
 * @extends {BaseAPI}
 */
export class OperationHandlerApi extends BaseAPI {
    /**
     * 
     * @summary handle
     * @param {{ [key: string]: string; }} [body] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationHandlerApi
     */
    public handleUsingGET(body?: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return OperationHandlerApiFp(this.configuration).handleUsingGET(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary handle
     * @param {{ [key: string]: string; }} [body] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationHandlerApi
     */
    public handleUsingGET1(body?: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return OperationHandlerApiFp(this.configuration).handleUsingGET1(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary handle
     * @param {{ [key: string]: string; }} [body] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationHandlerApi
     */
    public handleUsingGET2(body?: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return OperationHandlerApiFp(this.configuration).handleUsingGET2(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PeriodControllerApi - axios parameter creator
 * @export
 */
export const PeriodControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create period
         * @param {PeriodRequest} periodRequest New Period data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST: async (periodRequest: PeriodRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodRequest' is not null or undefined
            assertParamExists('createUsingPOST', 'periodRequest', periodRequest)
            const localVarPath = `/periods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(periodRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete period by ID
         * @param {number} id Id of the period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUsingDELETE', 'id', id)
            const localVarPath = `/periods/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get active period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findActiveUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/periods/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all periods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUsingGET1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/periods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get period by ID
         * @param {number} id Id of the period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsingGET4: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findUsingGET4', 'id', id)
            const localVarPath = `/periods/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update period
         * @param {number} id Id of the period to be updated
         * @param {PeriodRequest} periodRequest New Period data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsingPUT: async (id: number, periodRequest: PeriodRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUsingPUT', 'id', id)
            // verify required parameter 'periodRequest' is not null or undefined
            assertParamExists('updateUsingPUT', 'periodRequest', periodRequest)
            const localVarPath = `/periods/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(periodRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PeriodControllerApi - functional programming interface
 * @export
 */
export const PeriodControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PeriodControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create period
         * @param {PeriodRequest} periodRequest New Period data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsingPOST(periodRequest: PeriodRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsingPOST(periodRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete period by ID
         * @param {number} id Id of the period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get active period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findActiveUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findActiveUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all periods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllUsingGET1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeriodResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllUsingGET1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get period by ID
         * @param {number} id Id of the period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUsingGET4(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUsingGET4(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update period
         * @param {number} id Id of the period to be updated
         * @param {PeriodRequest} periodRequest New Period data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUsingPUT(id: number, periodRequest: PeriodRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUsingPUT(id, periodRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PeriodControllerApi - factory interface
 * @export
 */
export const PeriodControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PeriodControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create period
         * @param {PeriodRequest} periodRequest New Period data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST(periodRequest: PeriodRequest, options?: any): AxiosPromise<PeriodResponse> {
            return localVarFp.createUsingPOST(periodRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete period by ID
         * @param {number} id Id of the period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get active period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findActiveUsingGET(options?: any): AxiosPromise<PeriodResponse> {
            return localVarFp.findActiveUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all periods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUsingGET1(options?: any): AxiosPromise<Array<PeriodResponse>> {
            return localVarFp.findAllUsingGET1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get period by ID
         * @param {number} id Id of the period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsingGET4(id: number, options?: any): AxiosPromise<PeriodResponse> {
            return localVarFp.findUsingGET4(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update period
         * @param {number} id Id of the period to be updated
         * @param {PeriodRequest} periodRequest New Period data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsingPUT(id: number, periodRequest: PeriodRequest, options?: any): AxiosPromise<PeriodResponse> {
            return localVarFp.updateUsingPUT(id, periodRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PeriodControllerApi - object-oriented interface
 * @export
 * @class PeriodControllerApi
 * @extends {BaseAPI}
 */
export class PeriodControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create period
     * @param {PeriodRequest} periodRequest New Period data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeriodControllerApi
     */
    public createUsingPOST(periodRequest: PeriodRequest, options?: AxiosRequestConfig) {
        return PeriodControllerApiFp(this.configuration).createUsingPOST(periodRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete period by ID
     * @param {number} id Id of the period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeriodControllerApi
     */
    public deleteUsingDELETE(id: number, options?: AxiosRequestConfig) {
        return PeriodControllerApiFp(this.configuration).deleteUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get active period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeriodControllerApi
     */
    public findActiveUsingGET(options?: AxiosRequestConfig) {
        return PeriodControllerApiFp(this.configuration).findActiveUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all periods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeriodControllerApi
     */
    public findAllUsingGET1(options?: AxiosRequestConfig) {
        return PeriodControllerApiFp(this.configuration).findAllUsingGET1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get period by ID
     * @param {number} id Id of the period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeriodControllerApi
     */
    public findUsingGET4(id: number, options?: AxiosRequestConfig) {
        return PeriodControllerApiFp(this.configuration).findUsingGET4(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update period
     * @param {number} id Id of the period to be updated
     * @param {PeriodRequest} periodRequest New Period data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeriodControllerApi
     */
    public updateUsingPUT(id: number, periodRequest: PeriodRequest, options?: AxiosRequestConfig) {
        return PeriodControllerApiFp(this.configuration).updateUsingPUT(id, periodRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionControllerApi - axios parameter creator
 * @export
 */
export const QuestionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a question
         * @param {CreateQuestionRequest} questionRequest Data needed to create the question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST2: async (questionRequest: CreateQuestionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionRequest' is not null or undefined
            assertParamExists('createUsingPOST2', 'questionRequest', questionRequest)
            const localVarPath = `/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a question by Id
         * @param {number} id Id of the question to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUsingDELETE1', 'id', id)
            const localVarPath = `/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a question by Id
         * @param {number} id Id of the question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getByIdUsingGET', 'id', id)
            const localVarPath = `/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all not deleted questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionControllerApi - functional programming interface
 * @export
 */
export const QuestionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a question
         * @param {CreateQuestionRequest} questionRequest Data needed to create the question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsingPOST2(questionRequest: CreateQuestionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsingPOST2(questionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a question by Id
         * @param {number} id Id of the question to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUsingDELETE1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUsingDELETE1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a question by Id
         * @param {number} id Id of the question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all not deleted questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionControllerApi - factory interface
 * @export
 */
export const QuestionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a question
         * @param {CreateQuestionRequest} questionRequest Data needed to create the question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST2(questionRequest: CreateQuestionRequest, options?: any): AxiosPromise<QuestionResponse> {
            return localVarFp.createUsingPOST2(questionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a question by Id
         * @param {number} id Id of the question to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE1(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUsingDELETE1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a question by Id
         * @param {number} id Id of the question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET(id: number, options?: any): AxiosPromise<QuestionResponse> {
            return localVarFp.getByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all not deleted questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET(options?: any): AxiosPromise<Array<QuestionResponse>> {
            return localVarFp.getUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionControllerApi - object-oriented interface
 * @export
 * @class QuestionControllerApi
 * @extends {BaseAPI}
 */
export class QuestionControllerApi extends BaseAPI {
    /**
     * 
     * @summary Creates a question
     * @param {CreateQuestionRequest} questionRequest Data needed to create the question
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionControllerApi
     */
    public createUsingPOST2(questionRequest: CreateQuestionRequest, options?: AxiosRequestConfig) {
        return QuestionControllerApiFp(this.configuration).createUsingPOST2(questionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a question by Id
     * @param {number} id Id of the question to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionControllerApi
     */
    public deleteUsingDELETE1(id: number, options?: AxiosRequestConfig) {
        return QuestionControllerApiFp(this.configuration).deleteUsingDELETE1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a question by Id
     * @param {number} id Id of the question
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionControllerApi
     */
    public getByIdUsingGET(id: number, options?: AxiosRequestConfig) {
        return QuestionControllerApiFp(this.configuration).getByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all not deleted questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionControllerApi
     */
    public getUsingGET(options?: AxiosRequestConfig) {
        return QuestionControllerApiFp(this.configuration).getUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReviewControllerApi - axios parameter creator
 * @export
 */
export const ReviewControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a list of answers to a review
         * @param {number} id Id of the review owner of answer
         * @param {AddAnswersRequest} addAnswersRequest Data needed to add answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnswerToReviewUsingPUT: async (id: number, addAnswersRequest: AddAnswersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addAnswerToReviewUsingPUT', 'id', id)
            // verify required parameter 'addAnswersRequest' is not null or undefined
            assertParamExists('addAnswerToReviewUsingPUT', 'addAnswersRequest', addAnswersRequest)
            const localVarPath = `/reviews/{id}/answers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAnswersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clear review answers and set new form id
         * @param {number} id Id of the review to reset
         * @param {ChangeReviewFormRequest} reviewRequest Data needed to reset the review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeReviewFormUsingPUT: async (id: number, reviewRequest: ChangeReviewFormRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeReviewFormUsingPUT', 'id', id)
            // verify required parameter 'reviewRequest' is not null or undefined
            assertParamExists('changeReviewFormUsingPUT', 'reviewRequest', reviewRequest)
            const localVarPath = `/reviews/{id}/changeform`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary countReviews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countReviewsUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reviews/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delay a Review
         * @param {CreateReviewSkippedRequest} createReviewSkippedRequest Data needed to create the skip review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewSkippedUsingPOST: async (createReviewSkippedRequest: CreateReviewSkippedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReviewSkippedRequest' is not null or undefined
            assertParamExists('createReviewSkippedUsingPOST', 'createReviewSkippedRequest', createReviewSkippedRequest)
            const localVarPath = `/reviews/skip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReviewSkippedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Review
         * @param {CreateReviewRequest} createReviewRequest Data needed to create the review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewUsingPOST: async (createReviewRequest: CreateReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReviewRequest' is not null or undefined
            assertParamExists('createReviewUsingPOST', 'createReviewRequest', createReviewRequest)
            const localVarPath = `/reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Review
         * @param {number} id Id of the review to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReviewUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteReviewUsingDELETE', 'id', id)
            const localVarPath = `/reviews/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the accessible reviews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReviewsUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finalize a Review
         * @param {number} id Id of the review to be finalized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishReviewUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('finishReviewUsingPOST', 'id', id)
            const localVarPath = `/reviews/{id}/finish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a review by ID
         * @param {number} id Id of the review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getByIdUsingGET1', 'id', id)
            const localVarPath = `/reviews/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get self-evaluation of an employee given their id
         * @param {number} id Id of the reviewed employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelfReviewOfEmployeeUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSelfReviewOfEmployeeUsingGET', 'id', id)
            const localVarPath = `/reviews/self/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current employee self-evaluation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelfReviewUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reviews/self`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewControllerApi - functional programming interface
 * @export
 */
export const ReviewControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a list of answers to a review
         * @param {number} id Id of the review owner of answer
         * @param {AddAnswersRequest} addAnswersRequest Data needed to add answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAnswerToReviewUsingPUT(id: number, addAnswersRequest: AddAnswersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAnswerToReviewUsingPUT(id, addAnswersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clear review answers and set new form id
         * @param {number} id Id of the review to reset
         * @param {ChangeReviewFormRequest} reviewRequest Data needed to reset the review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeReviewFormUsingPUT(id: number, reviewRequest: ChangeReviewFormRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeReviewFormUsingPUT(id, reviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary countReviews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countReviewsUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countReviewsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delay a Review
         * @param {CreateReviewSkippedRequest} createReviewSkippedRequest Data needed to create the skip review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReviewSkippedUsingPOST(createReviewSkippedRequest: CreateReviewSkippedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewSkippedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReviewSkippedUsingPOST(createReviewSkippedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a Review
         * @param {CreateReviewRequest} createReviewRequest Data needed to create the review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReviewUsingPOST(createReviewRequest: CreateReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReviewUsingPOST(createReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Review
         * @param {number} id Id of the review to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReviewUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReviewUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the accessible reviews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReviewsUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReviewResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReviewsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finalize a Review
         * @param {number} id Id of the review to be finalized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishReviewUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishReviewUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a review by ID
         * @param {number} id Id of the review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdUsingGET1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewWithAnswersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdUsingGET1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get self-evaluation of an employee given their id
         * @param {number} id Id of the reviewed employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelfReviewOfEmployeeUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewWithAnswersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelfReviewOfEmployeeUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current employee self-evaluation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelfReviewUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewWithAnswersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelfReviewUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReviewControllerApi - factory interface
 * @export
 */
export const ReviewControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a list of answers to a review
         * @param {number} id Id of the review owner of answer
         * @param {AddAnswersRequest} addAnswersRequest Data needed to add answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnswerToReviewUsingPUT(id: number, addAnswersRequest: AddAnswersRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addAnswerToReviewUsingPUT(id, addAnswersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clear review answers and set new form id
         * @param {number} id Id of the review to reset
         * @param {ChangeReviewFormRequest} reviewRequest Data needed to reset the review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeReviewFormUsingPUT(id: number, reviewRequest: ChangeReviewFormRequest, options?: any): AxiosPromise<ReviewResponse> {
            return localVarFp.changeReviewFormUsingPUT(id, reviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary countReviews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countReviewsUsingGET(options?: any): AxiosPromise<ReviewCountResponse> {
            return localVarFp.countReviewsUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delay a Review
         * @param {CreateReviewSkippedRequest} createReviewSkippedRequest Data needed to create the skip review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewSkippedUsingPOST(createReviewSkippedRequest: CreateReviewSkippedRequest, options?: any): AxiosPromise<ReviewSkippedResponse> {
            return localVarFp.createReviewSkippedUsingPOST(createReviewSkippedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Review
         * @param {CreateReviewRequest} createReviewRequest Data needed to create the review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewUsingPOST(createReviewRequest: CreateReviewRequest, options?: any): AxiosPromise<ReviewResponse> {
            return localVarFp.createReviewUsingPOST(createReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Review
         * @param {number} id Id of the review to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReviewUsingDELETE(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReviewUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the accessible reviews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReviewsUsingGET(options?: any): AxiosPromise<Array<ReviewResponse>> {
            return localVarFp.findReviewsUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finalize a Review
         * @param {number} id Id of the review to be finalized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishReviewUsingPOST(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.finishReviewUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a review by ID
         * @param {number} id Id of the review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET1(id: number, options?: any): AxiosPromise<ReviewWithAnswersResponse> {
            return localVarFp.getByIdUsingGET1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get self-evaluation of an employee given their id
         * @param {number} id Id of the reviewed employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelfReviewOfEmployeeUsingGET(id: number, options?: any): AxiosPromise<ReviewWithAnswersResponse> {
            return localVarFp.getSelfReviewOfEmployeeUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current employee self-evaluation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelfReviewUsingGET(options?: any): AxiosPromise<ReviewWithAnswersResponse> {
            return localVarFp.getSelfReviewUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewControllerApi - object-oriented interface
 * @export
 * @class ReviewControllerApi
 * @extends {BaseAPI}
 */
export class ReviewControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add a list of answers to a review
     * @param {number} id Id of the review owner of answer
     * @param {AddAnswersRequest} addAnswersRequest Data needed to add answers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public addAnswerToReviewUsingPUT(id: number, addAnswersRequest: AddAnswersRequest, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).addAnswerToReviewUsingPUT(id, addAnswersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clear review answers and set new form id
     * @param {number} id Id of the review to reset
     * @param {ChangeReviewFormRequest} reviewRequest Data needed to reset the review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public changeReviewFormUsingPUT(id: number, reviewRequest: ChangeReviewFormRequest, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).changeReviewFormUsingPUT(id, reviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary countReviews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public countReviewsUsingGET(options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).countReviewsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delay a Review
     * @param {CreateReviewSkippedRequest} createReviewSkippedRequest Data needed to create the skip review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public createReviewSkippedUsingPOST(createReviewSkippedRequest: CreateReviewSkippedRequest, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).createReviewSkippedUsingPOST(createReviewSkippedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Review
     * @param {CreateReviewRequest} createReviewRequest Data needed to create the review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public createReviewUsingPOST(createReviewRequest: CreateReviewRequest, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).createReviewUsingPOST(createReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Review
     * @param {number} id Id of the review to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public deleteReviewUsingDELETE(id: number, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).deleteReviewUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the accessible reviews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public findReviewsUsingGET(options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).findReviewsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finalize a Review
     * @param {number} id Id of the review to be finalized
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public finishReviewUsingPOST(id: number, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).finishReviewUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a review by ID
     * @param {number} id Id of the review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public getByIdUsingGET1(id: number, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).getByIdUsingGET1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get self-evaluation of an employee given their id
     * @param {number} id Id of the reviewed employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public getSelfReviewOfEmployeeUsingGET(id: number, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).getSelfReviewOfEmployeeUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current employee self-evaluation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public getSelfReviewUsingGET(options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).getSelfReviewUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReviewRequestControllerApi - axios parameter creator
 * @export
 */
export const ReviewRequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createReviewRequest
         * @param {CreateReviewRequestRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewRequestUsingPOST: async (request: CreateReviewRequestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createReviewRequestUsingPOST', 'request', request)
            const localVarPath = `/reviews/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getReviewRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewRequestsUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reviews/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewRequestControllerApi - functional programming interface
 * @export
 */
export const ReviewRequestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewRequestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createReviewRequest
         * @param {CreateReviewRequestRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReviewRequestUsingPOST(request: CreateReviewRequestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateReviewRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReviewRequestUsingPOST(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getReviewRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewRequestsUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReviewRequestResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewRequestsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReviewRequestControllerApi - factory interface
 * @export
 */
export const ReviewRequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewRequestControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary createReviewRequest
         * @param {CreateReviewRequestRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewRequestUsingPOST(request: CreateReviewRequestRequest, options?: any): AxiosPromise<CreateReviewRequestResponse> {
            return localVarFp.createReviewRequestUsingPOST(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getReviewRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewRequestsUsingGET(options?: any): AxiosPromise<Array<ReviewRequestResponse>> {
            return localVarFp.getReviewRequestsUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewRequestControllerApi - object-oriented interface
 * @export
 * @class ReviewRequestControllerApi
 * @extends {BaseAPI}
 */
export class ReviewRequestControllerApi extends BaseAPI {
    /**
     * 
     * @summary createReviewRequest
     * @param {CreateReviewRequestRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewRequestControllerApi
     */
    public createReviewRequestUsingPOST(request: CreateReviewRequestRequest, options?: AxiosRequestConfig) {
        return ReviewRequestControllerApiFp(this.configuration).createReviewRequestUsingPOST(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getReviewRequests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewRequestControllerApi
     */
    public getReviewRequestsUsingGET(options?: AxiosRequestConfig) {
        return ReviewRequestControllerApiFp(this.configuration).getReviewRequestsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RosterControllerApi - axios parameter creator
 * @export
 */
export const RosterControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary importRoster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRosterUsingPOST: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roster/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RosterControllerApi - functional programming interface
 * @export
 */
export const RosterControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RosterControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary importRoster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importRosterUsingPOST(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importRosterUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RosterControllerApi - factory interface
 * @export
 */
export const RosterControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RosterControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary importRoster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRosterUsingPOST(options?: any): AxiosPromise<void> {
            return localVarFp.importRosterUsingPOST(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RosterControllerApi - object-oriented interface
 * @export
 * @class RosterControllerApi
 * @extends {BaseAPI}
 */
export class RosterControllerApi extends BaseAPI {
    /**
     * 
     * @summary importRoster
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RosterControllerApi
     */
    public importRosterUsingPOST(options?: AxiosRequestConfig) {
        return RosterControllerApiFp(this.configuration).importRosterUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuggestionBoxControllerApi - axios parameter creator
 * @export
 */
export const SuggestionBoxControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create
         * @param {SuggestionBoxMessageRequest} messageRequest messageRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST1: async (messageRequest: SuggestionBoxMessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageRequest' is not null or undefined
            assertParamExists('createUsingPOST1', 'messageRequest', messageRequest)
            const localVarPath = `/suggestionBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuggestionBoxControllerApi - functional programming interface
 * @export
 */
export const SuggestionBoxControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuggestionBoxControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create
         * @param {SuggestionBoxMessageRequest} messageRequest messageRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsingPOST1(messageRequest: SuggestionBoxMessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsingPOST1(messageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuggestionBoxControllerApi - factory interface
 * @export
 */
export const SuggestionBoxControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuggestionBoxControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary create
         * @param {SuggestionBoxMessageRequest} messageRequest messageRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST1(messageRequest: SuggestionBoxMessageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createUsingPOST1(messageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuggestionBoxControllerApi - object-oriented interface
 * @export
 * @class SuggestionBoxControllerApi
 * @extends {BaseAPI}
 */
export class SuggestionBoxControllerApi extends BaseAPI {
    /**
     * 
     * @summary create
     * @param {SuggestionBoxMessageRequest} messageRequest messageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuggestionBoxControllerApi
     */
    public createUsingPOST1(messageRequest: SuggestionBoxMessageRequest, options?: AxiosRequestConfig) {
        return SuggestionBoxControllerApiFp(this.configuration).createUsingPOST1(messageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeamControllerApi - axios parameter creator
 * @export
 */
export const TeamControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add existing employees to the team
         * @param {number} id Id of the team
         * @param {Array<number>} members List of Employees\&#39; id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployeesToTeamUsingPOST: async (id: number, members: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addEmployeesToTeamUsingPOST', 'id', id)
            // verify required parameter 'members' is not null or undefined
            assertParamExists('addEmployeesToTeamUsingPOST', 'members', members)
            const localVarPath = `/teams/{id}/employees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(members, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Team
         * @param {CreateTeamRequest} createTeamRequest Data needed to create the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeamUsingPOST: async (createTeamRequest: CreateTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTeamRequest' is not null or undefined
            assertParamExists('createTeamUsingPOST', 'createTeamRequest', createTeamRequest)
            const localVarPath = `/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Team
         * @param {number} id Id of the team to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTeamUsingDELETE', 'id', id)
            const localVarPath = `/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get teams you can access with employees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDeepUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/teams/detailed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get teams you can access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamStatusesUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/teams/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get teams you can access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsingGET5: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a team by Id and below
         * @param {number} id Id of the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdDeepUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getByIdDeepUsingGET', 'id', id)
            const localVarPath = `/teams/{id}/deep`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a team by Id
         * @param {number} id Id of the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET2: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getByIdUsingGET2', 'id', id)
            const localVarPath = `/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the root team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootTeamUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/teams/root`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get existing employees of a team
         * @param {number} id Id of the team to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamEmployeesUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTeamEmployeesUsingGET', 'id', id)
            const localVarPath = `/teams/{id}/employees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a team info by Id
         * @param {number} id Id of the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamInfoByIdUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTeamInfoByIdUsingGET', 'id', id)
            const localVarPath = `/teams/{id}/info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the team name
         * @param {number} id Id of the team to be updated
         * @param {string} newTeamName New Team Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamNameUsingPUT: async (id: number, newTeamName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTeamNameUsingPUT', 'id', id)
            // verify required parameter 'newTeamName' is not null or undefined
            assertParamExists('updateTeamNameUsingPUT', 'newTeamName', newTeamName)
            const localVarPath = `/teams/{id}/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (newTeamName !== undefined) {
                localVarQueryParameter['newTeamName'] = newTeamName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Team
         * @param {number} id Id of the team to be updated
         * @param {UpdateTeamRequest} updateTeamRequest New Team Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamUsingPUT: async (id: number, updateTeamRequest: UpdateTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTeamUsingPUT', 'id', id)
            // verify required parameter 'updateTeamRequest' is not null or undefined
            assertParamExists('updateTeamUsingPUT', 'updateTeamRequest', updateTeamRequest)
            const localVarPath = `/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamControllerApi - functional programming interface
 * @export
 */
export const TeamControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add existing employees to the team
         * @param {number} id Id of the team
         * @param {Array<number>} members List of Employees\&#39; id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEmployeesToTeamUsingPOST(id: number, members: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEmployeesToTeamUsingPOST(id, members, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a Team
         * @param {CreateTeamRequest} createTeamRequest Data needed to create the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeamUsingPOST(createTeamRequest: CreateTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTeamUsingPOST(createTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Team
         * @param {number} id Id of the team to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeamUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeamUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get teams you can access with employees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDeepUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamDeepResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findDeepUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get teams you can access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamStatusesUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<TeamStatusDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamStatusesUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get teams you can access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUsingGET5(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamTreeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUsingGET5(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a team by Id and below
         * @param {number} id Id of the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdDeepUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamDeepResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdDeepUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a team by Id
         * @param {number} id Id of the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdUsingGET2(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdUsingGET2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the root team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRootTeamUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRootTeamUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get existing employees of a team
         * @param {number} id Id of the team to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamEmployeesUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<EmployeeOverviewProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamEmployeesUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a team info by Id
         * @param {number} id Id of the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamInfoByIdUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamInfoByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the team name
         * @param {number} id Id of the team to be updated
         * @param {string} newTeamName New Team Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeamNameUsingPUT(id: number, newTeamName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeamNameUsingPUT(id, newTeamName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a Team
         * @param {number} id Id of the team to be updated
         * @param {UpdateTeamRequest} updateTeamRequest New Team Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeamUsingPUT(id: number, updateTeamRequest: UpdateTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeamUsingPUT(id, updateTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamControllerApi - factory interface
 * @export
 */
export const TeamControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add existing employees to the team
         * @param {number} id Id of the team
         * @param {Array<number>} members List of Employees\&#39; id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmployeesToTeamUsingPOST(id: number, members: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.addEmployeesToTeamUsingPOST(id, members, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Team
         * @param {CreateTeamRequest} createTeamRequest Data needed to create the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeamUsingPOST(createTeamRequest: CreateTeamRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.createTeamUsingPOST(createTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Team
         * @param {number} id Id of the team to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamUsingDELETE(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeamUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get teams you can access with employees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDeepUsingGET(options?: any): AxiosPromise<Array<TeamDeepResponse>> {
            return localVarFp.findDeepUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get teams you can access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamStatusesUsingGET(options?: any): AxiosPromise<Set<TeamStatusDTO>> {
            return localVarFp.findTeamStatusesUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get teams you can access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsingGET5(options?: any): AxiosPromise<Array<TeamTreeResponse>> {
            return localVarFp.findUsingGET5(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a team by Id and below
         * @param {number} id Id of the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdDeepUsingGET(id: number, options?: any): AxiosPromise<TeamDeepResponse> {
            return localVarFp.getByIdDeepUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a team by Id
         * @param {number} id Id of the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdUsingGET2(id: number, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.getByIdUsingGET2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the root team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootTeamUsingGET(options?: any): AxiosPromise<Array<TeamResponse>> {
            return localVarFp.getRootTeamUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get existing employees of a team
         * @param {number} id Id of the team to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamEmployeesUsingGET(id: number, options?: any): AxiosPromise<Set<EmployeeOverviewProjection>> {
            return localVarFp.getTeamEmployeesUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a team info by Id
         * @param {number} id Id of the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamInfoByIdUsingGET(id: number, options?: any): AxiosPromise<TeamInfoResponse> {
            return localVarFp.getTeamInfoByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the team name
         * @param {number} id Id of the team to be updated
         * @param {string} newTeamName New Team Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamNameUsingPUT(id: number, newTeamName: string, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.updateTeamNameUsingPUT(id, newTeamName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Team
         * @param {number} id Id of the team to be updated
         * @param {UpdateTeamRequest} updateTeamRequest New Team Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamUsingPUT(id: number, updateTeamRequest: UpdateTeamRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.updateTeamUsingPUT(id, updateTeamRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamControllerApi - object-oriented interface
 * @export
 * @class TeamControllerApi
 * @extends {BaseAPI}
 */
export class TeamControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add existing employees to the team
     * @param {number} id Id of the team
     * @param {Array<number>} members List of Employees\&#39; id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public addEmployeesToTeamUsingPOST(id: number, members: Array<number>, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).addEmployeesToTeamUsingPOST(id, members, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Team
     * @param {CreateTeamRequest} createTeamRequest Data needed to create the team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public createTeamUsingPOST(createTeamRequest: CreateTeamRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).createTeamUsingPOST(createTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Team
     * @param {number} id Id of the team to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public deleteTeamUsingDELETE(id: number, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).deleteTeamUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get teams you can access with employees
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public findDeepUsingGET(options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).findDeepUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get teams you can access
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public findTeamStatusesUsingGET(options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).findTeamStatusesUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get teams you can access
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public findUsingGET5(options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).findUsingGET5(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a team by Id and below
     * @param {number} id Id of the team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getByIdDeepUsingGET(id: number, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).getByIdDeepUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a team by Id
     * @param {number} id Id of the team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getByIdUsingGET2(id: number, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).getByIdUsingGET2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the root team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getRootTeamUsingGET(options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).getRootTeamUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get existing employees of a team
     * @param {number} id Id of the team to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getTeamEmployeesUsingGET(id: number, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).getTeamEmployeesUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a team info by Id
     * @param {number} id Id of the team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getTeamInfoByIdUsingGET(id: number, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).getTeamInfoByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the team name
     * @param {number} id Id of the team to be updated
     * @param {string} newTeamName New Team Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public updateTeamNameUsingPUT(id: number, newTeamName: string, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).updateTeamNameUsingPUT(id, newTeamName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a Team
     * @param {number} id Id of the team to be updated
     * @param {UpdateTeamRequest} updateTeamRequest New Team Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public updateTeamUsingPUT(id: number, updateTeamRequest: UpdateTeamRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).updateTeamUsingPUT(id, updateTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TopicControllerApi - axios parameter creator
 * @export
 */
export const TopicControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getTopicById
         * @param {number} id Id of the topic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicByIdUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTopicByIdUsingGET', 'id', id)
            const localVarPath = `/topics/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTopics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicsUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/topics/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TopicControllerApi - functional programming interface
 * @export
 */
export const TopicControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TopicControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getTopicById
         * @param {number} id Id of the topic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopicByIdUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopicByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getTopics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopicsUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopicResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopicsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TopicControllerApi - factory interface
 * @export
 */
export const TopicControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TopicControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary getTopicById
         * @param {number} id Id of the topic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicByIdUsingGET(id: number, options?: any): AxiosPromise<TopicResponse> {
            return localVarFp.getTopicByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getTopics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicsUsingGET(options?: any): AxiosPromise<Array<TopicResponse>> {
            return localVarFp.getTopicsUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TopicControllerApi - object-oriented interface
 * @export
 * @class TopicControllerApi
 * @extends {BaseAPI}
 */
export class TopicControllerApi extends BaseAPI {
    /**
     * 
     * @summary getTopicById
     * @param {number} id Id of the topic
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicControllerApi
     */
    public getTopicByIdUsingGET(id: number, options?: AxiosRequestConfig) {
        return TopicControllerApiFp(this.configuration).getTopicByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getTopics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicControllerApi
     */
    public getTopicsUsingGET(options?: AxiosRequestConfig) {
        return TopicControllerApiFp(this.configuration).getTopicsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsageReportControllerApi - axios parameter creator
 * @export
 */
export const UsageReportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all not deleted leaders directly under an employee who have not logged in yet
         * @param {string} [after] Filter reviews created after this date
         * @param {string} [before] Filter reviews created before this date
         * @param {number} [depth] Depth of teams to explore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLeadersNotLoggedInUsingGET: async (after?: string, before?: string, depth?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usageReport/leadersNotLoggedIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get team Assessments report details
         * @param {string} before Filter reviews created before this date
         * @param {string} [after] Filter reviews created after this date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamStatusesUsingGET1: async (before: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'before' is not null or undefined
            assertParamExists('findTeamStatusesUsingGET1', 'before', before)
            const localVarPath = `/usageReport/teamAssessments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get summary of reviews and leaders
         * @param {string} before Filter reviews created before this date
         * @param {string} [after] Filter reviews created after this date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usageReportSummaryUsingGET: async (before: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'before' is not null or undefined
            assertParamExists('usageReportSummaryUsingGET', 'before', before)
            const localVarPath = `/usageReport/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsageReportControllerApi - functional programming interface
 * @export
 */
export const UsageReportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsageReportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all not deleted leaders directly under an employee who have not logged in yet
         * @param {string} [after] Filter reviews created after this date
         * @param {string} [before] Filter reviews created before this date
         * @param {number} [depth] Depth of teams to explore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLeadersNotLoggedInUsingGET(after?: string, before?: string, depth?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeNotLoggedInResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLeadersNotLoggedInUsingGET(after, before, depth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get team Assessments report details
         * @param {string} before Filter reviews created before this date
         * @param {string} [after] Filter reviews created after this date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamStatusesUsingGET1(before: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<TeamAssessmentsReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamStatusesUsingGET1(before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get summary of reviews and leaders
         * @param {string} before Filter reviews created before this date
         * @param {string} [after] Filter reviews created after this date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usageReportSummaryUsingGET(before: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsageReportSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usageReportSummaryUsingGET(before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsageReportControllerApi - factory interface
 * @export
 */
export const UsageReportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsageReportControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all not deleted leaders directly under an employee who have not logged in yet
         * @param {string} [after] Filter reviews created after this date
         * @param {string} [before] Filter reviews created before this date
         * @param {number} [depth] Depth of teams to explore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLeadersNotLoggedInUsingGET(after?: string, before?: string, depth?: number, options?: any): AxiosPromise<Array<EmployeeNotLoggedInResponse>> {
            return localVarFp.findLeadersNotLoggedInUsingGET(after, before, depth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get team Assessments report details
         * @param {string} before Filter reviews created before this date
         * @param {string} [after] Filter reviews created after this date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamStatusesUsingGET1(before: string, after?: string, options?: any): AxiosPromise<Set<TeamAssessmentsReportResponse>> {
            return localVarFp.findTeamStatusesUsingGET1(before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get summary of reviews and leaders
         * @param {string} before Filter reviews created before this date
         * @param {string} [after] Filter reviews created after this date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usageReportSummaryUsingGET(before: string, after?: string, options?: any): AxiosPromise<UsageReportSummaryResponse> {
            return localVarFp.usageReportSummaryUsingGET(before, after, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsageReportControllerApi - object-oriented interface
 * @export
 * @class UsageReportControllerApi
 * @extends {BaseAPI}
 */
export class UsageReportControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get all not deleted leaders directly under an employee who have not logged in yet
     * @param {string} [after] Filter reviews created after this date
     * @param {string} [before] Filter reviews created before this date
     * @param {number} [depth] Depth of teams to explore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsageReportControllerApi
     */
    public findLeadersNotLoggedInUsingGET(after?: string, before?: string, depth?: number, options?: AxiosRequestConfig) {
        return UsageReportControllerApiFp(this.configuration).findLeadersNotLoggedInUsingGET(after, before, depth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get team Assessments report details
     * @param {string} before Filter reviews created before this date
     * @param {string} [after] Filter reviews created after this date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsageReportControllerApi
     */
    public findTeamStatusesUsingGET1(before: string, after?: string, options?: AxiosRequestConfig) {
        return UsageReportControllerApiFp(this.configuration).findTeamStatusesUsingGET1(before, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get summary of reviews and leaders
     * @param {string} before Filter reviews created before this date
     * @param {string} [after] Filter reviews created after this date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsageReportControllerApi
     */
    public usageReportSummaryUsingGET(before: string, after?: string, options?: AxiosRequestConfig) {
        return UsageReportControllerApiFp(this.configuration).usageReportSummaryUsingGET(before, after, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebMvcLinksHandlerApi - axios parameter creator
 * @export
 */
export const WebMvcLinksHandlerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebMvcLinksHandlerApi - functional programming interface
 * @export
 */
export const WebMvcLinksHandlerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebMvcLinksHandlerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linksUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Link; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linksUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebMvcLinksHandlerApi - factory interface
 * @export
 */
export const WebMvcLinksHandlerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebMvcLinksHandlerApiFp(configuration)
    return {
        /**
         * 
         * @summary links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksUsingGET(options?: any): AxiosPromise<{ [key: string]: { [key: string]: Link; }; }> {
            return localVarFp.linksUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebMvcLinksHandlerApi - object-oriented interface
 * @export
 * @class WebMvcLinksHandlerApi
 * @extends {BaseAPI}
 */
export class WebMvcLinksHandlerApi extends BaseAPI {
    /**
     * 
     * @summary links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebMvcLinksHandlerApi
     */
    public linksUsingGET(options?: AxiosRequestConfig) {
        return WebMvcLinksHandlerApiFp(this.configuration).linksUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


