import { AppButton } from '../../atoms/AppButton/AppButton'
import classNames from 'classnames'
import { useState } from 'react'
import PeriodConfModal, { PeriodConfModalModeEnum } from './PeriodConfModal'
import InputCalendar from '../../atoms/Icons/InputCalendar'
import ClockIcon4PM from '../../atoms/Icons/ClockIcon4PM'
import PencilSVG from '../../atoms/Icons/Pencil.svg'
import GarbageSVG from '../../atoms/Icons/Garbage.svg'
import { Period } from '../../../types/definitions/Period'

export const PeriodCardStatus = {
  PAST: 'Past',
  ACTIVE: 'Active',
  UPCOMING: 'Upcoming',
}

export interface PeriodCard {
  period: Period
  title: string
  range: string
  timeLeft?: string
  status: string
}

export interface PeriodCardsProps {
  periods: PeriodCard[]
}

const PeriodCards = ({ periods }: PeriodCardsProps): JSX.Element => {
  const [showPopup, setShowPopup] = useState(false)
  const [mode, setMode] = useState<string>(PeriodConfModalModeEnum.EDIT)
  const [currentPeriod, setCurrentPeriod] = useState<Period>()

  const handleEdit = (period: PeriodCard, index: number) => {
    setMode(PeriodConfModalModeEnum.EDIT)
    setCurrentPeriod(period.period)
    setShowPopup(true)
  }

  const handleDelete = (period: PeriodCard) => {
    setMode(PeriodConfModalModeEnum.DELETE)
    setCurrentPeriod(period.period)
    setShowPopup(true)
  }

  const handleClose = () => setShowPopup(false)

  return (
    <div className="flex flex-col gap-6">
      {periods.map((period, index) => (
        <div
          key={`period-card-${index}`}
          className={classNames('flex flex-row h-40 rounded-2xl p-6 items-center shadow-default', {
            'bg-just-gray': period.status === PeriodCardStatus.PAST,
            'border-l-green border-l-[0.6rem] border-solid py-6 pr-6 pl-[0.9rem]':
              period.status === PeriodCardStatus.ACTIVE,
          })}
        >
          <div className="flex flex-row w-3/5 h-full justify-start items-center">
            <div className="flex flex-col justify-start h-full gap-4 w-1/3 pl-4">
              <span
                className={classNames('rounded-3xl font-bold text-lg bg-green py-2 px-3 w-max', {
                  'bg-green bg-opacity-10 text-[#559E7A]': period.status === PeriodCardStatus.ACTIVE,
                  'bg-[#BDBDBD] bg-opacity-20 text-stone-gray': period.status !== PeriodCardStatus.ACTIVE,
                })}
              >
                {period.status}
              </span>
              <span
                className={classNames('font-bold text-3xl leading-8', {
                  'text-toast-hover': period.status === PeriodCardStatus.PAST,
                })}
              >
                {period.title}
              </span>
            </div>
            <div className="flex flex-col w-1/3 justify-start gap-4  h-full border-l-[0.5px] border-solid border-toast-hover pl-4">
              <div className="flex flex-row gap-3 items-center">
                <InputCalendar color="#979797" />
                <span className="font-bold text-xl text-toast-hover">Period:</span>
              </div>
              <span
                className={classNames('text-3xl leading-8', {
                  'text-toast-hover': period.status === PeriodCardStatus.PAST,
                })}
              >
                {period.range}
              </span>
            </div>
            {period.timeLeft && (
              <div className="flex flex-col self-stretch w-1/3 justify-start gap-4 h-full border-l-[0.5px] border-solid border-toast-hover pl-4">
                <div className="flex flex-row gap-3 items-center">
                  <ClockIcon4PM />
                  <span className="font-bold text-xl text-toast-hover">Time left:</span>
                </div>
                <span className="text-3xl leading-8">{period.timeLeft}</span>
              </div>
            )}
          </div>
          <div className="flex flex-row gap-2 w-2/5 justify-end">
            <AppButton onClick={() => handleDelete(period)} type="clear">
              <GarbageSVG width={12} height={12} color="#005ECA" /> Delete
            </AppButton>
            <AppButton onClick={() => handleEdit(period, index)} type="info">
              <PencilSVG width={12} height={12} color="white" /> Edit
            </AppButton>
          </div>
        </div>
      ))}
      {showPopup && <PeriodConfModal mode={mode} period={currentPeriod} onClose={() => handleClose()} />}
    </div>
  )
}

export default PeriodCards
