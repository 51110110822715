import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { FC, useState } from 'react'
import { AppMarkdownViewer } from '../AppMarkdownViewer/AppMarkdownViewer'
import LabelSliderText from '../LabelSliderText/LabelSliderText'

interface AppSliderTextProps {
  labels: string[]
  onChange?: (newValue: string | undefined) => void
}

const AppSliderText: FC<AppSliderTextProps> = ({ labels, onChange }) => {
  const [sliderValue, setSliderValue] = useState(0)
  const [text, setText] = useState<string>(labels[0])

  const handleChange = (value: number) => {
    setSliderValue(value)
    setText(labels[value])
    if (onChange) onChange(labels[value])
  }

  const labelsComponents = Array.from(Array(labels.length).keys()).map(number => (
    <LabelSliderText value={number + 1} sliderValue={sliderValue + 1} />
  ))

  const labelsObject = Object.assign({}, labelsComponents)

  return (
    <div>
      <Slider
        min={0}
        max={labels.length - 1}
        value={sliderValue || 0}
        onChange={handleChange}
        marks={labelsObject}
        handleStyle={{ borderColor: '#005ECA' }}
        trackStyle={{ backgroundColor: '#005ECA', borderRadius: 0 }}
        railStyle={{ backgroundColor: '#D3D3D3' }}
        dotStyle={{ borderRadius: 0, borderColor: '#D3D3D3', width: '1px', marginBottom: '2px' }}
        activeDotStyle={{ borderRadius: 0, borderColor: '#005ECA', width: '1px', marginBottom: '2px' }}
      />
      <div className="flex gap-2 mt-20 w-[95%]">
        <p className="text-2xl leading-8">{sliderValue + 1}.</p>
        <p className="text-2xl leading-8">
          <AppMarkdownViewer markdown={text} />
        </p>
      </div>
    </div>
  )
}

export default AppSliderText
