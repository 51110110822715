import { FC } from 'react'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import { RootState } from '../../..'
import { Employee } from '../../../api/openapi'
import HelpTooltip from '../HelpTooltip'
import { CategoryItem } from './CategoryItem'

const isCategoryCompleted = (completed: string[], category: string): boolean => {
  return !!completed.find(_category => category === _category)
}

const isCategoryUnfinished = (unfinished: string[], category: string): boolean => {
  return !!unfinished.find(_category => category === _category)
}

export interface CategorySelectorProps {
  categories: string[]
  unfinishedCategories?: string[]
  completedCategories?: string[]
  selectedCategory?: string
  showEmpty?: boolean
  showStepLines?: boolean
  tutorialReview?: boolean
  onCategorySelected?: (category: string) => void
}

export const CategorySelector: FC<CategorySelectorProps> = ({
  selectedCategory,
  onCategorySelected,
  categories = [],
  completedCategories = [],
  unfinishedCategories = [],
  showEmpty,
  showStepLines,
  tutorialReview = false,
}) => {
  // Current user
  const currentUser = useSelector<RootState, Employee>(state => state.auth.user as Employee)
  const handleCategorySelected = (selectedCategory: string) => {
    if (onCategorySelected) {
      onCategorySelected(selectedCategory)
    }
  }

  const helpTooltipClasses =
    'relative z-50 py-6 !max-w-[300px] bg-white flex flex-wrap w-fit items-center flex-1 overflow-hidden'
  const isUser = currentUser.role === 'user'

  if (tutorialReview) {
    return (
      <div className="flex flex-col gap-12">
        <HelpTooltip
          id={isUser ? 'user-assesment-tutorial-step-1' : 'review-tutorial-step-3'}
          placement="right-start"
          childrenClassNames={helpTooltipClasses}
          key={v4()}
        >
          <div className="category-selector">
            {categories.slice(0, categories.length - 2).map((category, i) => {
              const completed = isCategoryCompleted(completedCategories, category)
              const unfinishedCategory = isCategoryUnfinished(unfinishedCategories, category)
              const isFirstCategory = i === 0
              const isLastCategory = i === categories.length - 1
              return (
                <CategoryItem
                  category={category}
                  selectedCategory={selectedCategory}
                  isCompleted={completed}
                  isUnfinished={unfinishedCategory}
                  showEmpty={showEmpty}
                  showStepLines={showStepLines}
                  isFirstCategory={isFirstCategory}
                  isLastCategory={isLastCategory}
                  onClick={handleCategorySelected}
                  key={v4()}
                />
              )
            })}
          </div>
        </HelpTooltip>
        <HelpTooltip
          id={isUser ? 'user-assesment-tutorial-step-2' : 'review-tutorial-step-4'}
          key={v4()}
          childrenClassNames={helpTooltipClasses}
        >
          <div className="category-selector">
            {categories.slice(-2).map((category, i) => {
              const completed = isCategoryCompleted(completedCategories, category)
              const unfinishedCategory = isCategoryUnfinished(unfinishedCategories, category)
              const isFirstCategory = false
              const isLastCategory = i === 1
              return (
                <CategoryItem
                  category={category}
                  selectedCategory={selectedCategory}
                  isCompleted={completed}
                  isUnfinished={unfinishedCategory}
                  showEmpty={showEmpty}
                  showStepLines={showStepLines}
                  isFirstCategory={isFirstCategory}
                  isLastCategory={isLastCategory}
                  onClick={handleCategorySelected}
                  key={v4()}
                />
              )
            })}
          </div>
        </HelpTooltip>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-12">
      <HelpTooltip id="tech-tutorial-step-1" key={v4()} childrenClassNames={helpTooltipClasses}>
        <div className="category-selector">
          {categories.slice(0, categories.length - 2).map((category, i) => {
            const completed = isCategoryCompleted(completedCategories, category)
            const unfinishedCategory = isCategoryUnfinished(unfinishedCategories, category)
            const isFirstCategory = i === 0
            const isLastCategory = i === categories.length - 1
            return (
              <CategoryItem
                category={category}
                selectedCategory={selectedCategory}
                isCompleted={completed}
                isUnfinished={unfinishedCategory}
                showEmpty={showEmpty}
                showStepLines={showStepLines}
                isFirstCategory={isFirstCategory}
                isLastCategory={isLastCategory}
                onClick={handleCategorySelected}
                key={v4()}
              />
            )
          })}
        </div>
      </HelpTooltip>
      <HelpTooltip id="tech-tutorial-step-2" key={v4()} childrenClassNames={helpTooltipClasses}>
        <div className="category-selector">
          {categories.slice(-2).map((category, i) => {
            const completed = isCategoryCompleted(completedCategories, category)
            const unfinishedCategory = isCategoryUnfinished(unfinishedCategories, category)
            const isFirstCategory = false
            const isLastCategory = i === 1
            return (
              <CategoryItem
                category={category}
                selectedCategory={selectedCategory}
                isCompleted={completed}
                isUnfinished={unfinishedCategory}
                showEmpty={showEmpty}
                showStepLines={showStepLines}
                isFirstCategory={isFirstCategory}
                isLastCategory={isLastCategory}
                onClick={handleCategorySelected}
                key={v4()}
              />
            )
          })}
        </div>
      </HelpTooltip>
    </div>
  )
}
