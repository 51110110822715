import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../..'
import { ReportTutorialContent } from '../../utils/ReportTutorialContent'
import { AppButton } from '../atoms/AppButton/AppButton'
import { Popup } from './Popup'
import { finishReportTutorial, showReportTutorialIndex } from '../../slices/report-tutorial-slice'
import Spinner from '../atoms/Spinner'
import { SmallArrowButton } from '../atoms/SmallArrowButton/SmallArrowButton'

type ReportTutorialModalProps = {
  visible: boolean
}

interface ModalBodyProps {
  index: number
}

export const ReportTutorialModal = ({ visible }: ReportTutorialModalProps) => {
  const dispatch = useAppDispatch()
  const current = useSelector<RootState, number>(state => state.reportTutorial.current ?? 0)

  const handleSkipClick = () => {
    window.scrollTo(0, 0)
    dispatch(showReportTutorialIndex(-1))
    dispatch(finishReportTutorial())
  }

  const ModalBody = ({ index }: ModalBodyProps) => {
    const contentIndex = ReportTutorialContent[index]
    const isFirst = index === 0
    const isLast = index === ReportTutorialContent.length - 1

    const handleCloseClick = () => {
      window.scrollTo(0, 0)
      dispatch(showReportTutorialIndex(-1))
      dispatch(finishReportTutorial())
    }

    const handleBackClick = () => {
      dispatch(showReportTutorialIndex(index - 1))
    }

    const handleNextClick = () => {
      dispatch(showReportTutorialIndex(index + 1))
    }

    const Pagination = () => {
      return (
        <div className="absolute flex flex-col h-full w-full">
          <div className="grow" />
          <div className="bg-just-gray rounded-t-3xl z-20">
            <div className="flex justify-center m-3">
              <div className="flex shrink-0 w-4">
                {!isFirst && <SmallArrowButton type="previous" onClick={handleBackClick} />}
              </div>
              <h4 className="text-xl text-gray-3 text-center flex-initial w-52">
                Step {index + 1}/{ReportTutorialContent.length}
              </h4>
              <div className="flex shrink-0 w-4">
                {!isLast && <SmallArrowButton type="next" onClick={handleNextClick} />}
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (index === current) {
      return (
        <div className="flex flex-col -mr-[17px] justify-between rounded-[16px] overflow-auto h-[500px] lg:h-[600px] xl:h-[700px] max-h-[90vh] max-w-[90vw]">
          <div className="relative flex flex-auto justify-center items-center bg-white w-full bg-no-repeat bg-center bg-cover">
            <Spinner />
            <div
              style={{ backgroundImage: `url(${contentIndex.image})` }}
              title="Tutorial"
              className="bg-no-repeat bg-center bg-cover h-full w-full z-10"
            ></div>
            <Pagination />
          </div>
          <div className="flex flex-auto flex-grow-0 w-full p-6 bg-white z-10">
            <div className="flex flex-col items-start w-4/6">
              <h4 className="text-3xl font-medium leading-8 mb-4">{contentIndex.title}</h4>
              <h4 className="text-xl font-medium break-words leading-[18px]">{contentIndex.body}</h4>
            </div>
            <div className="flex justify-end items-center w-2/6">
              {!isLast && (
                <AppButton type="clear" className="!min-w-[0px] !w-1/2 !bg-white" onClick={handleSkipClick}>
                  Skip tutorial
                </AppButton>
              )}
              {isLast ? (
                <AppButton type="info" className="min-w-[8rem]" onClick={handleCloseClick}>
                  Finish
                </AppButton>
              ) : (
                <AppButton type="info" className="!min-w-[0px] !w-1/2" onClick={handleNextClick}>
                  Next
                </AppButton>
              )}
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <Popup
      visible={visible}
      classname="!-m-8 !max-h-none !max-w-none"
      containerClassName="!max-h-none !max-w-[1100px] !w-3/4"
      onClose={handleSkipClick}
    >
      {ReportTutorialContent.map((content, i) => (
        <ModalBody index={i} key={i} />
      ))}
    </Popup>
  )
}
