import { ArrowIcon } from '../atoms/Icons/ArrowIcon'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { goBack, historyState } from '../../utils/history'
export interface LocationState {
  prevPath: string
  report_id?: string
}

export enum BackLinkLabels {
  Assessments = 'Back to Assessments',
  Teams = 'Back to Teams',
  Employee_Report = 'Back to the Report',
  Team_Report = `Back to the Team's report`,
  Finder = 'Back to the Finder',
}

export const BackLink = (): JSX.Element => {
  function getLabelFromPath(): string {
    const path = historyState.prevPath
    if (path) {
      if (path.includes(createPathWithLang(NetworkConstants.URL_TEAMS_DASHBOARD))) return BackLinkLabels.Teams
      if (path.includes(createPathWithLang(NetworkConstants.URL_REVIEW_LIST))) return BackLinkLabels.Assessments
      if (path.includes(createPathWithLang(NetworkConstants.URL_FIND_RESOURCES))) return BackLinkLabels.Finder
      // Removes the specific part from the URL and compare it
      const teamReportPath = createPathWithLang(NetworkConstants.URL_TEAM_SUMMARY.replace('/:teamId', ''))
      if (path.includes(teamReportPath)) return BackLinkLabels.Team_Report
      // Redirects to the user report
      const reviewPath = createPathWithLang(NetworkConstants.URL_NEW_REVIEW.replace('/:employeeId', ''))
      const employeeReportPath = createPathWithLang(NetworkConstants.URL_REPORT.replace('/:employeeId', ''))
      if (path.includes(employeeReportPath) || path.includes(reviewPath)) return BackLinkLabels.Employee_Report
    }
    // Default case
    return BackLinkLabels.Assessments
  }

  const getForcedPath = (): string => {
    const currentPath = historyState.stack.at(-1)
    if (currentPath) {
      // for the /thanks/ path, force the back link to go to the review list, otherwise it goes
      // to the review, which takes you back to /thanks/, rendering the backlink button useless
      if (currentPath.includes(createPathWithLang(NetworkConstants.URL_REVIEW_FINISHED_MSG)))
        return createPathWithLang(NetworkConstants.URL_REVIEW_LIST)
    }
    return ''
  }

  return (
    <div className={`flex justify-between`}>
      <div className="flex flex-nowrap">
        <button
          className="text-stone-gray hover:text-stone-gray hover:underline text-2xl font-medium flex items-center"
          onClick={() => goBack(getForcedPath())}
        >
          <div className="mr-3">
            <ArrowIcon height={14} width={14} />
          </div>
          {getLabelFromPath()}
        </button>
      </div>
    </div>
  )
}
