import { FC, ReactElement } from 'react'
import { useDrop } from 'react-dnd'

import { ItemTypes } from '../../constants/drag-n-drop'

export type DropType = 'move' | 'add' | 'lead'

export interface DropAreaProps {
  dropId: number
  name: string
  type: DropType
}

const renderDisplayCopy = (type: DropType): string => {
  switch (type) {
    case 'add':
      return 'Add to'
    case 'lead':
      return 'Set as Leader'
    case 'move':
    default:
      return 'Move to'
  }
}

export const DropArea: FC<DropAreaProps> = ({ dropId, name, type }): ReactElement => {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.TEAM_NODE,
      drop: () => ({ dropId, name, type }),
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
    }),
    []
  )

  return (
    <div ref={drop} className={`dnd-drop-area dnd-drop-area--${isOver ? 'over' : 'out'}`}>
      {renderDisplayCopy(type)}
    </div>
  )
}
