export interface RadioButtonProps {
  value: string
  formValue: string
  onChecked: (v: string) => void
  disabled?: boolean
  label?: string
  labelStyles?: string
  radius?: number
}

const RadioButton = (props: RadioButtonProps): JSX.Element => {
  const size = props.radius || 10
  const checked = props.formValue === props.value

  return (
    <div className="my-1">
      <input
        type="radio"
        value={props.value}
        checked={checked}
        onChange={() => !checked && props.onChecked(props.value)}
        className={`appearance-none mt-2 float-left disabled:bg-soft-gray cursor-pointer bg-opacity-100 bg-white mr-2 w-${size} h-${size} border rounded-full border-hit-grey hover:scale-110 checked:bg-radio-gradient-radial`}
        disabled={props.disabled || false}
      />
      {props.label && (
        <label
          className={`block ml-8 leading-10 hover:text-hit-grey cursor-pointer ` + (props.labelStyles || '')}
          onClick={() => !checked && !props.disabled && props.onChecked(props.value)}
        >
          {props.label}
        </label>
      )}
    </div>
  )
}

export default RadioButton
