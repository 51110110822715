export const EMPLOYEE_STATUS_TODO = 'Not Started'
export const EMPLOYEE_STATUS_DONE = 'Completed'
export const EMPLOYEE_STATUS_UNFINISHED = 'Started'
export const EMPLOYEE_STATUS_REQUESTED = 'Requested'
export const EMPLOYEE_STATUS_SKIPPED = 'Skipped'

export const AVAILABLE_EMPLOYEE_STATUSES = [
  EMPLOYEE_STATUS_TODO,
  EMPLOYEE_STATUS_UNFINISHED,
  EMPLOYEE_STATUS_DONE,
  EMPLOYEE_STATUS_REQUESTED,
  EMPLOYEE_STATUS_SKIPPED,
]

export const EMPLOYEE_HIGHLIGHT_FLIGHT_RISK = 'Retention at Risk'
export const EMPLOYEE_HIGHLIGHT_MISMATCH = 'Underperforming'
export const EMPLOYEE_HIGHLIGHT_MISMATCH_LITERAL = 'Mismatch'
export const EMPLOYEE_HIGHLIGHT_ROCKSTAR = 'Rockstar'
export const EMPLOYEE_HIGHLIGHT_BURNOUT = 'Burnout'

export const AVAILABLE_EMPLOYEE_HIGHLIGHTS = [
  { label: EMPLOYEE_HIGHLIGHT_FLIGHT_RISK, value: EMPLOYEE_HIGHLIGHT_FLIGHT_RISK },
  { label: EMPLOYEE_HIGHLIGHT_MISMATCH, value: EMPLOYEE_HIGHLIGHT_MISMATCH_LITERAL },
  { label: EMPLOYEE_HIGHLIGHT_ROCKSTAR, value: EMPLOYEE_HIGHLIGHT_ROCKSTAR },
  { label: EMPLOYEE_HIGHLIGHT_BURNOUT, value: EMPLOYEE_HIGHLIGHT_BURNOUT },
]

export const AVAILABLE_EMPLOYEE_HIGHLIGHTS_LABELS = [
  EMPLOYEE_HIGHLIGHT_FLIGHT_RISK,
  EMPLOYEE_HIGHLIGHT_MISMATCH,
  EMPLOYEE_HIGHLIGHT_ROCKSTAR,
  EMPLOYEE_HIGHLIGHT_BURNOUT,
] as const

export const EMPLOYEE_HIGHLIGHT_TRIGGER_CRITERIA = [
  {
    question: 'Retention at Risk',
    value: 'High',
    result: EMPLOYEE_HIGHLIGHT_FLIGHT_RISK,
  },
  {
    question: 'Talent Rank',
    value: 'Mismatch',
    result: EMPLOYEE_HIGHLIGHT_MISMATCH,
  },
  {
    question: 'Talent Rank',
    value: 'Needs attention',
    result: EMPLOYEE_HIGHLIGHT_MISMATCH,
  },
  {
    question: 'Talent Rank',
    value: 'Top talent',
    result: EMPLOYEE_HIGHLIGHT_ROCKSTAR,
  },
  {
    question: 'Talent Rank',
    value: 'Rockstar',
    result: EMPLOYEE_HIGHLIGHT_ROCKSTAR,
  },
  {
    question: 'Burnout level',
    value: 'High',
    result: EMPLOYEE_HIGHLIGHT_BURNOUT,
  },
]

export const EMPLOYEE_HIGHLIGHT_ICON_MAP: Record<string, string> = {
  [EMPLOYEE_HIGHLIGHT_FLIGHT_RISK]: '✈️',
  [EMPLOYEE_HIGHLIGHT_MISMATCH]: '⚠️',
  [EMPLOYEE_HIGHLIGHT_MISMATCH_LITERAL]: '⚠️',
  [EMPLOYEE_HIGHLIGHT_ROCKSTAR]: '🎸',
  [EMPLOYEE_HIGHLIGHT_BURNOUT]: '🤒',
}

export const ALERT_TYPE_MAPPING = {
  flightRisk: 'Retention at Risk',
  mismatch: 'Mismatch',
  rockstar: 'Rockstar',
  burnout: 'Burnout',
}

export const ConstantIDs = {
  REVIEW_ID_PLACEHOLDER: 'reviewId',
  REVIEW_ID_PATH_PLACEHOLDER: ':reviewId',
  EMPLOYEE_ID_PATH_PLACEHOLDER: ':employeeId',
  FORM_ID_PATH_PLACEHOLDER: ':formId',
}

export const COACHING_FORM_NAME = 'Coaching'
