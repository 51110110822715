import React, { FC } from 'react'
import { Tooltip } from '@material-ui/core'
import { classNames } from 'primereact/utils'

interface TextProps {
  text: string
  tooltip?: boolean
  tooltipBody?: React.ReactNode
  blockClassname?: string
  textClassname?: string
  icon?: React.ReactNode
  iconClassname?: string
  onClickIcon?: () => void
}

export const CellText: FC<TextProps> = ({
  text,
  tooltip,
  tooltipBody,
  textClassname,
  icon,
  iconClassname,
  blockClassname,
  onClickIcon,
}) => {
  const renderCellContent = () => (
    <div className={classNames('flex text-stone-gray gap-4 items-center leading-6 w-48', blockClassname)}>
      <span className={classNames('text-stone-gray', textClassname)}>{text}</span>
      <div
        className={classNames('cursor-pointer', iconClassname)}
        onClick={e => {
          onClickIcon && onClickIcon()
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        {icon && icon}
      </div>
    </div>
  )

  return tooltip ? (
    <Tooltip
      title={tooltipBody || text || ''}
      arrow
      enterDelay={500}
      enterNextDelay={500}
      classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
    >
      {renderCellContent()}
    </Tooltip>
  ) : (
    renderCellContent()
  )
}
