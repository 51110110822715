import { useEffect, useState } from 'react'
import { Review } from '../../types/definitions/review'
import EmployeeResultsData from '../molecules/EmployeeResults/EmployeeResultsData'
import EmployeeResultsHistory from '../molecules/EmployeeResults/EmployeeResultsHistory'
import EmployeeResultsTeams from '../molecules/EmployeeResults/EmployeeResultsTeams'
import { Employee, EmployeeDetails } from '../../services/EmployeeService'
import { Form } from '../../types/definitions/form'
import { AppButton } from '../atoms/AppButton/AppButton'
import PencilSVG from '../atoms/Icons/Pencil.svg'
import { Link } from 'react-router-dom'
import GarbageSVG from '../atoms/Icons/Garbage.svg'
import { PlusIcon } from '../atoms/Icons/PlusIcon'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { ConstantIDs } from '../../constants/employee'
import { createPathToCreateNewReview } from '../../utils/employeePathUtils'

type EmployeeHeaderProps = {
  reviews: Review[]
  allForms: Form[] | undefined
  employee: Employee
  currentUser: EmployeeDetails | null
  lastOwnUnfinishedReviewId: number | undefined
  handleEditReview: (curretReview: Review) => void
  handleDeleteReview: (curretReview: Review) => void
}

export const EmployeeResultsCoachingVision = ({
  employee,
  reviews,
  allForms,
  currentUser,
  lastOwnUnfinishedReviewId,
  handleEditReview,
  handleDeleteReview,
}: EmployeeHeaderProps): JSX.Element => {
  const [currentReview, setCurrentReview] = useState<Review>(reviews[0])
  const [showEditButton, setShowEditButton] = useState(false)
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  useEffect(() => {
    setShowEditButton(currentReview?.reviewAuthor.id === currentUser?.id && !currentReview?.form.deprecated)
    setShowDeleteButton(currentReview?.reviewAuthor.id === currentUser?.id)
  }, [currentReview, currentUser])

  const pathToEditReview = (): string => {
    if (!currentReview || currentReview.form.deprecated) return ''
    return createPathWithLang(
      NetworkConstants.URL_EDIT_REVIEW.replace(ConstantIDs.REVIEW_ID_PATH_PLACEHOLDER, currentReview.id.toString())
    )
  }

  return (
    <>
      <div className="p-20 shadow-default rounded-2xl print:shadow-none print:!p-2 print:break-after-page">
        <div className="flex justify-between items-center w-full">
          <span className="text-4xl font-semibold text-dark-blue print:text-3xl">Coaching Vision</span>
        </div>
        <div className="text-xl font-bold mt-4">Assessed by</div>
        <EmployeeResultsHistory
          reviews={reviews}
          currentReview={currentReview}
          onChange={v => {
            setCurrentReview(v)
          }}
          allForms={allForms || []}
        />
        <div className="employee-results__body mb-12 print:mb-2 print:gap-2 print:flex-col-reverse print:w-full print:justify-center">
          <div className="employee-results__body-data">
            <EmployeeResultsData employee={employee} review={currentReview} />
          </div>
        </div>
        <div className="employee-results__footer flex mt-24 print:mt-0">
          {showEditButton && (
            <div>
              <Link to={pathToEditReview} onClick={() => handleEditReview(currentReview)}>
                <AppButton>
                  <PencilSVG color="white" width={14} height={14} />
                  <span className="ml-2">Edit assessment</span>
                </AppButton>
              </Link>
              <button
                onClick={() => handleDeleteReview(currentReview)}
                className="flex items-center mt-7 clickable-hover-effect clickable-hover-effect--soft-grey"
              >
                <GarbageSVG color="#005ECA" width={15} height={17} />
                <span className="text-blue text-2xl font-semibold ml-3 font-manrope">Delete assessment</span>
              </button>
            </div>
          )}
          <div>
            {lastOwnUnfinishedReviewId ? (
              <Link
                to={createPathWithLang(
                  NetworkConstants.URL_EDIT_REVIEW.replace(
                    ConstantIDs.REVIEW_ID_PATH_PLACEHOLDER,
                    lastOwnUnfinishedReviewId.toString()
                  )
                )}
              >
                <AppButton type="clear">
                  <PencilSVG />
                  <span className="ml-2">Continue assessment</span>
                </AppButton>
              </Link>
            ) : (
              <Link to={createPathToCreateNewReview(employee.id)}>
                <AppButton type="clear">
                  <PlusIcon />
                  <span className="ml-2">New assessment</span>
                </AppButton>
              </Link>
            )}
            {!showEditButton && showDeleteButton && (
              <button
                onClick={() => handleDeleteReview(currentReview)}
                className="flex items-center mt-4 clickable-hover-effect clickable-hover-effect--soft-grey"
              >
                <GarbageSVG color="#005ECA" width={13} height={15} />
                <span className="text-blue text-2xl font-semibold ml-3 font-manrope">Delete assessment</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
