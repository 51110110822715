import { Chart } from 'primereact/chart'
import { createRef, FC, useEffect, useState } from 'react'
import { Form } from '../../../types/definitions/form'
import { AnsweredQuestion } from '../../../types/definitions/question'
import { Review } from '../../../types/definitions/review'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { COLORS_TOPIC_TECH_PROFILE } from '../../../utils/utils'

interface ChartData {
  labels: string[]
  datasets: {
    data: number[]
    backgroundColor: string[]
    hoverBackgroundColor: string[]
    barThickness: number
    borderRadius: number
  }[]
}

interface levelWeightMapType {
  [level: string]: number
}

interface TechProfileDetailsProps {
  title: { title: string; color: string }
  values: string[]
  bottomSeparator?: boolean
}

interface TechProfileBodyProps {
  review: Review
  form: Form
}

export const TechProfileBody = (props: TechProfileBodyProps): JSX.Element => {
  const [currentReview] = useState<Review>(props.review)
  const chartRef = createRef<Chart>()
  const imgRef = createRef<HTMLImageElement>()

  // FIXME: Define the colors to be used and improve this system
  function getChartData(): ChartData {
    const levelWeightMap: levelWeightMapType = {
      Advanced: 5,
      Medium: 1,
      Basic: 0.2,
    }

    // process the data
    const currReview = JSON.parse(JSON.stringify(currentReview)) as Review
    const answers = currReview.answers
      .filter(answer => answer.type === 'RATE_SELECT')
      .sort((answer1, answer2) => {
        if (!answer1?.topic?.order || !answer2?.topic?.order) return -1
        if (answer1.topic.order === answer2.topic.order) return 0
        return answer1.topic.order > answer2.topic.order ? 1 : -1
      })
    // Get the number of tech by category
    const techByCategory: { [title: string]: AnsweredQuestion[] } = {}
    answers.forEach(answer => {
      if (answer.answer) {
        if (techByCategory[answer.title]) {
          techByCategory[answer.title].push(answer)
        } else {
          techByCategory[answer.title] = [answer]
        }
      }
    })
    // Weight the total amount
    const weightedAnswers: { [title: string]: number } = {}
    Object.entries(techByCategory).forEach(entry => {
      const [title, answers] = entry
      answers.forEach(answer => {
        const weight = levelWeightMap[answer.answer as string] || 0
        if (weightedAnswers[title]) {
          weightedAnswers[title] += weight
        } else {
          weightedAnswers[title] = weight
        }
      })
    })
    // Normalize the data
    Object.entries(weightedAnswers).forEach(entry => {
      const [title, value] = entry
      weightedAnswers[title] = Math.round((value / techByCategory[title].length / levelWeightMap['Advanced']) * 100)
    })
    const labels = Object.keys(weightedAnswers)
    const data = Object.values(weightedAnswers)
    const backgroundColors = labels.map((label: string) => COLORS_TOPIC_TECH_PROFILE[label])
    const chartData = {
      labels,
      datasets: [
        {
          data,
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors,
          barThickness: 20,
          borderRadius: 7.5,
        },
      ],
    }

    return chartData
  }

  // FIXME: Correct this harcoded after the sprint
  function getItems(answers: AnsweredQuestion[], value: string): string[] {
    if (answers.length === 0) return []

    if (value === 'Learn' || value === 'Teach') {
      const expectedTopicName = value === 'Teach' ? 'Would love to teach' : 'Want to learn/improve'
      return answers
        .filter(answer =>
          Array.isArray(answer.answer) && answer.answer.length > 0
            ? answer.topic?.name === expectedTopicName
            : undefined
        )
        .flatMap(answer =>
          answer.answer
            .toString()
            .replaceAll(/[[\]"]/g, '')
            .split(',')
        )
    } else {
      return answers.filter(answer => answer.answer === value).map(answer => answer.label)
    }
  }

  const TechProfileDetails: FC<TechProfileDetailsProps> = ({ title, values, bottomSeparator = true }) => {
    return values ? (
      <div
        className={`${
          bottomSeparator ? 'border-b border-solid border-tech-profile-border' : ''
        } pb-6 pr-2 mt-10 print:!pb-2 print:!mt-2`}
      >
        <div className={`text-2xl text-${title.color} font-bold mb-4`}>{title.title}</div>
        <div className="flex flex-row flex-wrap text-justify">
          {values.reduce((acc, value, index) => {
            return acc + value + (index != values.length - 1 ? ' \xa0•\xa0 ' : '')
          }, '')}
        </div>
      </div>
    ) : null
  }

  // REVIEW THIS AFTER THE DEMO
  const basicOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    layout: {
      padding: 40,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        labels: {
          render: 'value',
        },
        enabled: true,
        bodyFont: {
          size: 13,
        },
        rtl: true,
      },
      datalabels: {
        color: 'black',
        font: {
          weight: 'bold',
        },
        anchor: 'end',
        offset: -30,
        align: 'start',
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#979797',
          font: {
            size: 13,
            family: 'Manrope',
            weight: 400,
          },
          min: 0,
          max: 100,
        },
        grid: {
          display: false,
        },
        min: 0,
        max: 100,
      },
      y: {
        ticks: {
          color: '#979797',
          font: {
            size: 13,
            family: 'Manrope',
            weight: 400,
          },
        },
        grid: {
          color: '#fff',
        },
        display: true,
        position: 'left',
      },
    },
  }

  useEffect(() => {
    const canvasToImg = () => {
      if (chartRef.current) {
        const canvas = chartRef.current.getCanvas()
        const canvasImg = canvas.toDataURL('image/png')
        if (imgRef.current) {
          imgRef.current.src = canvasImg
        }
      }
    }
    canvasToImg()
    window.addEventListener('beforeprint', canvasToImg)
    return () => window.removeEventListener('beforeprint', canvasToImg)
  })

  return (
    <div className="employee-results__body gap-2 mb-10 print:m-0 print:flex-col-reverse print:gap-0">
      <div className="employee-results__body-data mt-8 w-full lg:w-1/2 print:w-full">
        <TechProfileDetails
          title={{ title: 'Advanced level', color: 'blue-gray' }}
          values={getItems(currentReview?.answers, 'Advanced')}
        />
        <TechProfileDetails
          title={{ title: 'Medium level', color: 'blue-gray' }}
          values={getItems(currentReview?.answers, 'Medium')}
        />
        <TechProfileDetails
          title={{ title: 'Basic level', color: 'blue-gray' }}
          values={getItems(currentReview?.answers, 'Basic')}
        />
        <TechProfileDetails
          title={{ title: 'I would like to learn/improve', color: 'toast-hover' }}
          values={getItems(currentReview?.answers, 'Learn')}
        />
        <TechProfileDetails
          title={{ title: 'I would love to teach', color: 'toast-hover' }}
          bottomSeparator={false}
          values={getItems(currentReview?.answers, 'Teach')}
        />
      </div>
      <div className="employee-results__body-chart print:max-w-full print:flex print:justify-center print:items-center">
        <div className="flex justify-center h-[50vh] print:hidden">
          <Chart
            ref={chartRef}
            type="bar"
            data={getChartData()}
            style={{ position: 'relative', width: '100%' }}
            options={basicOptions}
            plugins={[ChartDataLabels]}
          />
        </div>
        <div className="w-0 h-0 print:w-fit print:h-fit justify-center">
          <img ref={imgRef} src="" alt="Chart" className="object-contain print:h-[400px] w-auto" />
        </div>
      </div>
    </div>
  )
}
