import { Redirect, Route, RouteProps } from 'react-router-dom'

import { NetworkConstants } from '../../utils/NetworkConstants'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { Roles, Route as RouteModel } from '../../utils/routes'
import { useSelector } from 'react-redux'
import { RootState } from '../..'
import { EmployeeDetails } from '../../services/EmployeeService'

export type ProtectedRouteProps = {
  route: RouteModel
} & RouteProps

export default function ProtectedRoute({ route, ...routeProps }: ProtectedRouteProps): JSX.Element {
  const user = useSelector<RootState, EmployeeDetails | null>(state => state.auth.user)

  function IsAuthorizedRole(roles: Roles[]): boolean {
    return user?.role ? roles.includes(user.role) : false
  }

  function IsAuthenticatedUser(): boolean {
    return !!user
  }

  function IsAuthorizedRoute(route: RouteModel): boolean {
    return IsAuthenticatedUser() && IsAuthorizedRole(route.roles)
  }

  if (IsAuthorizedRoute(route)) {
    return <Route {...routeProps} />
  }

  if (IsAuthenticatedUser()) {
    return <Redirect to={createPathWithLang(NetworkConstants.URL_UNAUTHORIZED)} />
  }

  return <Redirect to={createPathWithLang(NetworkConstants.URL_LOGIN)} />
}
