import { Tooltip } from '@material-ui/core'
import React from 'react'
import { Employee } from '../../../services/EmployeeService'
import HierarchyIcon from '../../atoms/Icons/HierarchyIcon'
import { classNames } from 'primereact/utils'
import { getHoverColorForEmployee } from '../../../utils/hoverColorUtils'

interface EmployeeTableHierarchyBodyParams {
  handleHierarchyNavigation: (e: Employee) => void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const EmployeeTableHierarchyBody = ({ handleHierarchyNavigation }: EmployeeTableHierarchyBodyParams) => {
  return (rowData: Employee): JSX.Element => {
    const handleHierarchyButtonClick = (e: React.MouseEvent): void => {
      handleHierarchyNavigation?.(rowData)
      e.stopPropagation()
    }

    if (!rowData.leaderIn?.length) {
      return <span className="w-12 flex mx-auto justify-center">-</span>
    }

    return (
      <div className="w-full flex justify-center">
        <div className="w-12 flex justify-center">
          <Tooltip
            title="Click to open their team members."
            arrow
            enterDelay={500}
            enterNextDelay={500}
            classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
          >
            <button
              onClick={handleHierarchyButtonClick}
              className={classNames(
                'user-list-table-option-button border-b border-transparent hover:border-blue',
                'clickable-hover-effect',
                getHoverColorForEmployee(rowData)
              )}
            >
              <HierarchyIcon width={28} height={19} color="#005ECA" />
            </button>
          </Tooltip>
        </div>
      </div>
    )
  }
}
