import { createSlice } from '@reduxjs/toolkit'
import { TutorialsStorageConfiguration } from '../utils/HelpContent'
import { IntroductionContent } from '../utils/IntroductionContent'

const SliceConfiguration = {
  reducerTag: 'introduction',
}

export interface IntroductionState {
  current: string | null
  completed: boolean
}

const initialState: IntroductionState = {
  current: null,
  completed: localStorage.getItem(TutorialsStorageConfiguration.INTRODUCTION_TUTORIAL_COMPLETED) === 'true',
}

export const introductionSlice = createSlice({
  name: SliceConfiguration.reducerTag,
  initialState: initialState,
  reducers: {
    showIntroduction: state => {
      return {
        ...state,
        current: 'introduction-step-1',
      }
    },
    showIntroductionId: (state, { payload }) => {
      return {
        ...state,
        current: payload,
      }
    },
    hideIntroduction: state => {
      return {
        ...state,
        current: null,
      }
    },
    finishIntroduction: (state, { payload }) => {
      const finishKey = IntroductionContent[payload].finishKey
      if (finishKey) {
        localStorage.setItem(finishKey, 'true')
      }
      return {
        ...state,
        completed: !!finishKey,
      }
    },
  },
})

export const { hideIntroduction, showIntroduction, showIntroductionId, finishIntroduction } = introductionSlice.actions
