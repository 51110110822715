import { FC, useEffect, useState } from 'react'

import { AppTextarea } from '../../../atoms/AppTextarea/AppTextarea'

export interface TextareaQuestionProps {
  title: string
  value: string
  placeholder: string
  required?: boolean
  unfinished?: boolean
  onChange?: (value: string) => void
}

export const TextareaQuestion: FC<TextareaQuestionProps> = ({
  title,
  value,
  placeholder,
  unfinished,
  required = true,
  onChange,
}) => {
  const [textareaValue, setTextareaValue] = useState<string>(value)

  useEffect(() => {
    setTextareaValue(value)
  }, [value])

  const handleChange = (value: string): void => {
    onChange?.(value)
    setTextareaValue(value)
  }
  return (
    <div className="textarea-question">
      <AppTextarea
        label={title}
        placeholder={placeholder}
        value={textareaValue}
        unfinished={unfinished && required}
        required={required}
        onChange={handleChange}
      />
    </div>
  )
}
