import PencilSVG from '../../atoms/Icons/Pencil.svg'
import RoundedPlusIcon from '../../atoms/Icons/RoundedPlusIcon'
import { ReviewRequest } from '../../../services/ReviewService'
import { EMPLOYEE_STATUS_TODO, EMPLOYEE_STATUS_UNFINISHED } from '../../../constants/employee'
import { Link } from 'react-router-dom'

interface ReviewRequestTableOptionsBodyParams {
  getReviewPath: (reviewRequest: ReviewRequest) => string
}

interface OptionButtonProps {
  path: string
  text: string
  icon: React.ReactNode
}
const OptionButton = ({ path, text, icon }: OptionButtonProps) => {
  return (
    <Link to={path} className="border-none flex justify-center items-center gap-2 rounded-md default-action">
      {icon}
      <span className="hover:underline invisible lg:visible flex items-center text-blue text-xl">{text}</span>
    </Link>
  )
}

export const ReviewRequestTableOptionsBody = ({ getReviewPath }: ReviewRequestTableOptionsBodyParams) => {
  return (rowData: ReviewRequest): JSX.Element => {
    return (
      <div className="flex items-center justify-start flex-wrap gap-2 h-full w-full relative">
        {rowData?.status === EMPLOYEE_STATUS_TODO && (
          <OptionButton path={getReviewPath(rowData)} text="Start" icon={<RoundedPlusIcon />} />
        )}
        {rowData.status === EMPLOYEE_STATUS_UNFINISHED && (
          <OptionButton path={getReviewPath(rowData)} text="Continue" icon={<PencilSVG width={16} height={16} />} />
        )}
      </div>
    )
  }
}
