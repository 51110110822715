const PencilSVG = ({ color = '#005ECA', width = 16, height = 16 } = {}): JSX.Element => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0572 1.00052C11.3614 1.00052 10.6943 1.27523 10.2013 1.76607L2.1849 9.73718C2.12432 9.79776 2.07978 9.87225 2.0565 9.95466L1.04551 13.5166C0.796737 14.394 1.60886 15.2041 2.48556 14.954L5.99305 13.9516C6.07423 13.9283 6.14873 13.8844 6.20853 13.8245L14.15 5.88304C14.6954 5.3383 15.0021 4.598 15.0021 3.82698C15.0021 3.05606 14.6954 2.31577 14.15 1.77091C13.6565 1.2774 12.9866 1 12.2896 1V1.00267C12.2736 1.00067 12.2569 1 12.239 1H12.0574L12.0572 1.00052ZM5.59438 13.0279L2.21146 13.995C2.08574 14.0303 1.97004 13.9145 2.00523 13.7894L2.98103 10.3526L10.9051 2.4739C11.2111 2.16856 11.6255 1.99829 12.0572 1.99829H12.2894C12.7224 1.99829 13.1381 2.17056 13.4441 2.47655C13.8027 2.83511 14.0042 3.3213 14.0042 3.82752C14.0042 4.33375 13.8027 4.81991 13.4441 5.17849L5.59444 13.0282L5.59438 13.0279Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0572 1.00052C11.3614 1.00052 10.6943 1.27523 10.2013 1.76607L2.1849 9.73718C2.12432 9.79776 2.07978 9.87225 2.0565 9.95466L1.04551 13.5166C0.796737 14.394 1.60886 15.2041 2.48556 14.954L5.99305 13.9516C6.07423 13.9283 6.14873 13.8844 6.20853 13.8245L14.15 5.88304C14.6954 5.3383 15.0021 4.598 15.0021 3.82698C15.0021 3.05606 14.6954 2.31577 14.15 1.77091C13.6565 1.2774 12.9866 1 12.2896 1V1.00267C12.2736 1.00067 12.2569 1 12.239 1H12.0574L12.0572 1.00052ZM5.59438 13.0279L2.21146 13.995C2.08574 14.0303 1.97004 13.9145 2.00523 13.7894L2.98103 10.3526L10.9051 2.4739C11.2111 2.16856 11.6255 1.99829 12.0572 1.99829H12.2894C12.7224 1.99829 13.1381 2.17056 13.4441 2.47655C13.8027 2.83511 14.0042 3.3213 14.0042 3.82752C14.0042 4.33375 13.8027 4.81991 13.4441 5.17849L5.59444 13.0282L5.59438 13.0279Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0572 1.00052C11.3614 1.00052 10.6943 1.27523 10.2013 1.76607L2.1849 9.73718C2.12432 9.79776 2.07978 9.87225 2.0565 9.95466L1.04551 13.5166C0.796737 14.394 1.60886 15.2041 2.48556 14.954L5.99305 13.9516C6.07423 13.9283 6.14873 13.8844 6.20853 13.8245L14.15 5.88304C14.6954 5.3383 15.0021 4.598 15.0021 3.82698C15.0021 3.05606 14.6954 2.31577 14.15 1.77091C13.6565 1.2774 12.9866 1 12.2896 1V1.00267C12.2736 1.00067 12.2569 1 12.239 1H12.0574L12.0572 1.00052ZM5.59438 13.0279L2.21146 13.995C2.08574 14.0303 1.97004 13.9145 2.00523 13.7894L2.98103 10.3526L10.9051 2.4739C11.2111 2.16856 11.6255 1.99829 12.0572 1.99829H12.2894C12.7224 1.99829 13.1381 2.17056 13.4441 2.47655C13.8027 2.83511 14.0042 3.3213 14.0042 3.82752C14.0042 4.33375 13.8027 4.81991 13.4441 5.17849L5.59444 13.0282L5.59438 13.0279Z"
        stroke={color}
        strokeWidth="0.4"
        mask="url(#path-2-outside-1_7986_7300)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.82876 3.84734L12.1547 7.17324C12.3495 7.36811 12.6656 7.36811 12.8604 7.17324C13.0553 6.97904 13.0553 6.66234 12.8604 6.46815L9.53453 3.14225C9.33966 2.94738 9.02363 2.94738 8.82876 3.14225C8.63455 3.33645 8.63455 3.65315 8.82876 3.84734V3.84734Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.82876 3.84734L12.1547 7.17324C12.3495 7.36811 12.6656 7.36811 12.8604 7.17324C13.0553 6.97904 13.0553 6.66234 12.8604 6.46815L9.53453 3.14225C9.33966 2.94738 9.02363 2.94738 8.82876 3.14225C8.63455 3.33645 8.63455 3.65315 8.82876 3.84734V3.84734Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.82876 3.84734L12.1547 7.17324C12.3495 7.36811 12.6656 7.36811 12.8604 7.17324C13.0553 6.97904 13.0553 6.66234 12.8604 6.46815L9.53453 3.14225C9.33966 2.94738 9.02363 2.94738 8.82876 3.14225C8.63455 3.33645 8.63455 3.65315 8.82876 3.84734V3.84734Z"
        stroke={color}
        strokeWidth="0.4"
        mask="url(#path-3-outside-2_7986_7300)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.1769 10.4987L5.50281 13.8246C5.69768 14.0195 6.01371 14.0195 6.20858 13.8246C6.40345 13.6304 6.40345 13.3137 6.20858 13.1195L2.88268 9.79361C2.68781 9.59874 2.37178 9.59874 2.1769 9.79361C1.9827 9.98782 1.9827 10.3045 2.1769 10.4987V10.4987Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.1769 10.4987L5.50281 13.8246C5.69768 14.0195 6.01371 14.0195 6.20858 13.8246C6.40345 13.6304 6.40345 13.3137 6.20858 13.1195L2.88268 9.79361C2.68781 9.59874 2.37178 9.59874 2.1769 9.79361C1.9827 9.98782 1.9827 10.3045 2.1769 10.4987V10.4987Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.1769 10.4987L5.50281 13.8246C5.69768 14.0195 6.01371 14.0195 6.20858 13.8246C6.40345 13.6304 6.40345 13.3137 6.20858 13.1195L2.88268 9.79361C2.68781 9.59874 2.37178 9.59874 2.1769 9.79361C1.9827 9.98782 1.9827 10.3045 2.1769 10.4987V10.4987Z"
        stroke={color}
        strokeWidth="0.4"
        mask="url(#path-4-outside-3_7986_7300)"
      />
    </svg>
  )
}

export default PencilSVG
