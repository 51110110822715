interface ClockIcon4PMProps {
  width?: number
  height?: number
  color?: string
}

const ClockIcon4PM = ({ width = 16, height = 16, color = '#979797' }: ClockIcon4PMProps): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" version="1.1" id="svg4">
      <defs id="defs8" />
      <path
        d="M14.6667 8.00016C14.6667 11.6802 11.68 14.6668 8.00004 14.6668C4.32004 14.6668 1.33337 11.6802 1.33337 8.00016C1.33337 4.32016 4.32004 1.3335 8.00004 1.3335C11.68 1.3335 14.6667 4.32016 14.6667 8.00016Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="path2"
      />
      <g id="g67" transform="translate(7.2440334,4.0679567)">
        <path
          d="m 3.4343592,5.6528804 -2.06667,-1.23333 c -0.36,-0.21334 -0.653334,-0.72667 -0.653334,-1.14667 V 0.53955029"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          id="path58"
        />
      </g>
    </svg>
  )
}

export default ClockIcon4PM
