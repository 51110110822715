import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Employee, EmployeeReviewer, EmployeeService } from '../../services/EmployeeService'
import {
  EMPLOYEE_STATUS_DONE,
  EMPLOYEE_STATUS_REQUESTED,
  EMPLOYEE_STATUS_TODO,
  EMPLOYEE_STATUS_UNFINISHED,
} from '../../constants/employee'
import { EmployeeTableOptionsBody } from '../molecules/EmployeeTable/EmployeeTableOptionsBody'
import { TableCellBody } from '../molecules/AppTable/TableCellBody'
import { useEffect, useState } from 'react'
import { ColumnPropsIndexed } from './ReviewTable'

interface ExpandedReviewersTableParams {
  reviewers: EmployeeReviewer[] | undefined
  employee: Employee
  handleNewReview: (employee: Employee) => string
  handleOnEmployeeClicked: (employee: Employee) => string
}

export const ExpandedReviewersTable = ({
  reviewers,
  handleNewReview,
  employee,
  handleOnEmployeeClicked,
}: ExpandedReviewersTableParams): JSX.Element => {
  const [reviewersWithStatus, setReviewersWithStatus] = useState<EmployeeReviewer[]>([])
  const createNewReview = (employee: Employee): string => {
    return handleNewReview(employee)
  }

  const handleEmployeeSelected = (employee: Employee): string => {
    return handleOnEmployeeClicked(employee)
  }

  useEffect(() => {
    if (reviewers) {
      EmployeeService.setReviewersStatus(reviewers)
      setReviewersWithStatus([...reviewers])
    }
  }, [reviewers])

  const OptionsBody = EmployeeTableOptionsBody({
    createNewReview,
    handleEmployeeSelected,
  })

  const getEmployeeRecordForReviewer = (reviewer: EmployeeReviewer): Employee => {
    const parsedEmployee = { ...employee, lastOwnReview: reviewer.lastReview }
    delete parsedEmployee.lastReview
    if (!reviewer.lastReview) {
      delete parsedEmployee.lastOwnReview
    }
    return parsedEmployee
  }

  return (
    <DataTable
      className="user-list-container"
      tableClassName="user-list-table app-table hide-header table-fixed"
      rowClassName={(reviewer: EmployeeReviewer) => {
        return {
          'review-list-table-row [&_span]:text-dark-blue': true,
          'user-list-table-row --todo': reviewer.status === EMPLOYEE_STATUS_TODO,
          'user-list-table-row --done':
            reviewer.status === EMPLOYEE_STATUS_DONE || reviewer.status === EMPLOYEE_STATUS_REQUESTED,
          'user-list-table-row --unfinished': reviewer.status === EMPLOYEE_STATUS_UNFINISHED,
        }
      }}
      value={reviewersWithStatus}
      dataKey="id"
      editMode="row"
    >
      <Column field="reviewer.name" className="pl-[3rem]" body={TableCellBody<EmployeeReviewer>()} />
      <Column field="status" body={TableCellBody<EmployeeReviewer>()} />
      <Column
        bodyStyle={{ textAlign: 'center', position: 'relative' }}
        body={(rowData: EmployeeReviewer, props: ColumnPropsIndexed) => {
          return rowData.lastReview?.finishDate && OptionsBody(getEmployeeRecordForReviewer(rowData), props)
        }}
      />
    </DataTable>
  )
}
