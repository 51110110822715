import { Chart } from 'chart.js'
import { ChartDatasetType } from '../components/atoms/Charts/TeamSummaryChartRadar'
import { EmployeeOverview } from '../services/TeamService'

import { Point } from './mathUtils'
import { assignColor } from './utils'

export type ChartDefaultOptions = typeof chartDefaultOptions

export interface RadarChartData {
  values: number[]
  labels: string[]
}

export interface CharData {
  data: Record<string, ChartDatasetType>
  labels: string[]
}

interface EmployeeScores extends EmployeeOverview {
  isGlobalScore: boolean
  score: number
  skipped?: boolean
}

export const chartDefaultOptions = Object.freeze({
  responsive: true,
  maintainAspectRatio: false,
  borderWidth: 0,
  pointRadius: 2.5,
  layout: {
    padding: 45,
  },
  scales: {
    r: {
      grid: {
        color: '#00112F',
        lineWidth: 0.5,
        borderDash: [2, 2],
      },
      angleLines: {
        color: '#00112F',
        lineWidth: 0.5,
        borderDash: [2, 2],
      },
      ticks: {
        stepSize: 25,
        display: false,
      },
      pointLabels: {
        padding: 0,
        color: 'rgba(0,0,0,0)',
      },
      min: 0,
      max: 100,
    },
  },
  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: {
      display: false,
    },
    backgroundColor: 'green',
  },
})

enum ChartKinds {
  Rose,
  Radar,
  Undefined,
}

const kindOf = (chart: Chart): ChartKinds => {
  switch (chart.canvas.className) {
    case 'chart-rose':
      return ChartKinds.Rose
    case 'chart-radar':
      return ChartKinds.Radar
    default:
      return ChartKinds.Undefined
  }
}

const ctxOf = (chart: Chart): CanvasRenderingContext2D => {
  return chart.ctx
}

const datasetsOf = (chart: Chart): number[][] => {
  return chart.data.datasets.map(dataset => dataset.data) as unknown as number[][]
}

const teamDataSet = (chart: Chart): number[][] => {
  return chart.data.datasets.slice(-1).map(dataset => dataset.data) as unknown as number[][]
}

const colorsOf = (chart: Chart): string[] => {
  return chart.data.datasets.map(dataset => dataset.borderColor) as unknown as string[]
}

const labelsOf = (chart: Chart): string[] => {
  return chart.data.labels as string[]
}

const centerOf = (chart: Chart): Point => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const radialAxis = chart.scales['r'] as any
  const xOffset = radialAxis.xCenter
  const yOffset = radialAxis.yCenter

  const result = { x: xOffset, y: yOffset }
  return result
}

const paddingOf = (chart: Chart): number => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (chart as any).options.scales.r.pointLabels.padding
}

const offsetStepDegOf = (chart: Chart, stepDeg: number, stepSize: number): number => {
  return kindOf(chart) === ChartKinds.Rose ? stepDeg + stepSize / 2 : stepDeg
}

// calcLabelPositionsOf() calculates the initial position of the labels that come directly from
// chart.js.
const calcLabelPositionsOf = (chart: Chart): Array<Point> => {
  const steps = labelsOf(chart).length
  const center = centerOf(chart)
  const r = center.y

  const totalDegrees = 360
  // By default degree 0 is equal to 3:00 pm but we want to start
  // the labeling at 12:00 pm so we should go back 90deg A.K.A initialStepPosition
  const initialStepPosition = 90
  const stepSize = totalDegrees / steps

  const labelPositions = []

  for (let stepIndex = 0; stepIndex < steps; stepIndex++) {
    const stepDeg = stepIndex * stepSize - initialStepPosition
    const offsetStepDeg = offsetStepDegOf(chart, stepDeg, stepSize)
    const stepRad = (offsetStepDeg * Math.PI) / 180

    const x = Math.cos(stepRad) * r + center.x
    const y = Math.sin(stepRad) * r + center.y

    // Uncomment the line below to visualize lines drawn from the center of the chart to the label positions
    // drawLine(chart.ctx, x, y, center.x, center.y)

    labelPositions.push({ x, y })
  }

  return labelPositions
}

// calcInboundsLabelPositionOf() will return a position that grant that the label never renders outside the canvas
const calcInboundsLabelPositionOf = (
  labelPos: Point,
  labelWidth: number,
  labelHeight: number,
  chartWidth: number,
  chartHeight: number
): Point => {
  const result = Object.assign({}, labelPos)

  // check if label exceeds left of the canvas
  if (labelPos.x - labelWidth / 2 < 0) result.x = labelWidth / 2
  // check if label exceeds right of the canvas
  if (labelPos.x + labelWidth / 2 > chartWidth) result.x = chartWidth - labelWidth / 2
  // check if label exceeds top of the canvas
  if (labelPos.y < 0) result.y = 0
  // check if label exceeds bottom of the canvas
  if (labelPos.y + labelHeight > chartHeight) result.y = chartHeight - labelHeight

  return result
}

const calculateRadarChartDataTeamSummary = (employee: EmployeeOverview): RadarChartData => {
  const values = [] as number[]
  const labels = [] as string[]
  if (employee.avgAttitude) {
    values.push(employee.avgAttitude)
    labels.push('Attitude')
  }
  if (employee.avgCommunication) {
    values.push(employee.avgCommunication)
    labels.push('Communication')
  }
  if (employee.avgAptitude) {
    values.push(employee.avgAptitude)
    labels.push('Aptitude')
  }
  return { values, labels }
}

const calculateChartDataTeamSummary = (teamEmployees: EmployeeScores[]): CharData => {
  let labels: string[] = []
  const data: Record<string, ChartDatasetType> = {}
  teamEmployees.forEach((employee: EmployeeScores, index: number) => {
    const radarChartData: RadarChartData = calculateRadarChartDataTeamSummary(employee)

    if (radarChartData.values.length === 0) {
      const dataset: ChartDatasetType = {
        displayed: false,
        skippedReview: employee.skipped,
        values: [],
        color: assignColor(index),
        backgroundColor: employee.isGlobalScore ? 'rgb(176,227,255,0.4)' : 'transparent',
        reviewName: employee.name || '',
        jobTitle: employee.jobTitle || '',
        displayLabelValue: employee.isGlobalScore,
      }
      data[employee.name || ''] = dataset
      return
    }

    let values: number[] = []
    if (radarChartData.labels.length > labels.length) {
      labels = radarChartData.labels
      values = radarChartData.values
    } else {
      // Entering here means that we have more labels than values
      // therefore we need to fill the blank values with 0s to have
      // as many values as labels.
      const diff = labels.length - radarChartData.values.length
      const filler = new Array(diff).fill(0)
      values = radarChartData.values.concat(filler)
    }

    const dataset: ChartDatasetType = {
      displayed: employee.isGlobalScore ? true : false,
      values,
      color: employee.isGlobalScore ? '#598AFE' : assignColor(index),
      backgroundColor: employee.isGlobalScore ? 'rgb(176,227,255,0.4)' : 'transparent',
      reviewName: employee.name || '',
      jobTitle: employee.jobTitle || '',
      displayLabelValue: employee.isGlobalScore,
    }
    data[employee.name || ''] = dataset
  })
  return { labels, data }
}

export {
  ctxOf,
  datasetsOf,
  teamDataSet,
  colorsOf,
  labelsOf,
  kindOf,
  centerOf,
  paddingOf,
  calcLabelPositionsOf,
  calcInboundsLabelPositionOf,
  calculateChartDataTeamSummary,
  ChartKinds,
}
