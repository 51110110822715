import { useEffect, useRef, useState } from 'react'
import { Toast } from 'primereact/toast'

interface ToastMessageProps {
  message: string
  summary?: string
  duration: number
  type: 'success' | 'info' | 'warn' | 'error'
  deleteMessage: () => void
}

const ToastColours = {
  success: { bg: '#00BE4F', textcolor: 'black' },
  info: { bg: '#005ECA', textcolor: 'white' },
  warn: { bg: '#FFAA600', textcolor: 'black' },
  error: { bg: '#FE3B3B', textcolor: 'black' },
}

export const ToastMessage = ({ message, summary, duration = 3000, type, deleteMessage }: ToastMessageProps) => {
  const toast = useRef<Toast>(null)
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    // Avoid repetitions in toast rendering
    if (!rendered) {
      // Displays the toast message
      toast.current?.show({
        life: duration,
        severity: type,
        summary: summary,
        detail: message,
        style: {
          backgroundColor: ToastColours[type].bg,
          color: 'black',
        },
        contentStyle: {
          color: 'black',
        },
        className: 'text-2xl p-4 rounded-lg',
      })
      setRendered(true)
    }
  }, [duration, message, rendered, summary, type])

  const onHideHandler = () => {
    setRendered(false)
    deleteMessage()
  }

  return (
    <>
      <Toast ref={toast} onHide={onHideHandler} position="top-center" />
    </>
  )
}
