import { createSlice } from '@reduxjs/toolkit'
import { HelpContent, TutorialsStorageConfiguration } from '../utils/HelpContent'

const SliceConfiguration = {
  reducerTag: 'help',
}

export interface HelpState {
  current: string | null
  completed: boolean
  techProfileCompleted: boolean
  userAssesmentCompleted: boolean
}

const initialState: HelpState = {
  current: null,
  completed: localStorage.getItem(TutorialsStorageConfiguration.REVIEW_TUTORIAL_COMPLETED) === 'true',
  techProfileCompleted: localStorage.getItem(TutorialsStorageConfiguration.TECH_PROFILE_TUTORIAL_COMPLETED) === 'true',
  userAssesmentCompleted:
    localStorage.getItem(TutorialsStorageConfiguration.USER_ASSESSMENT_TUTORIAL_COMPLETED) === 'true',
}

export const helpSlice = createSlice({
  name: SliceConfiguration.reducerTag,
  initialState: initialState,
  reducers: {
    showTutorial: state => {
      return {
        ...state,
        current: 'review-tutorial-step-1',
      }
    },
    hideTutorial: state => {
      return {
        ...state,
        current: null,
      }
    },
    finishTutorial: (state, { payload }) => {
      const finishKey = HelpContent[payload].finishKey
      if (finishKey) {
        localStorage.setItem(finishKey, 'true')
      }
      return {
        ...state,
        completed: localStorage.getItem(TutorialsStorageConfiguration.REVIEW_TUTORIAL_COMPLETED) === 'true',
        techProfileCompleted:
          localStorage.getItem(TutorialsStorageConfiguration.TECH_PROFILE_TUTORIAL_COMPLETED) === 'true',
        userAssesmentCompleted:
          localStorage.getItem(TutorialsStorageConfiguration.USER_ASSESSMENT_TUTORIAL_COMPLETED) === 'true',
      }
    },
    showHelpId: (state, { payload }) => {
      return {
        ...state,
        current: payload,
      }
    },
  },
})

export const { hideTutorial, showTutorial, showHelpId, finishTutorial } = helpSlice.actions
