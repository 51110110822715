import { Dropdown } from 'primereact/dropdown'
import { FC, useState } from 'react'
import classNames from 'classnames'

export interface AppValueSelectProps {
  label?: string
  value?: string
  options: { value: string; label: string }[]
  required?: boolean
  className?: string
  onChange?: (inputValue: string) => void
}

export const AppValueSelect: FC<AppValueSelectProps> = ({
  label,
  value,
  options,
  required = true,
  className = '',
  onChange,
}: AppValueSelectProps): JSX.Element => {
  const [zIndex, setZIndex] = useState<number>(0)

  const handleChange = (value: string): void => {
    onChange?.(value !== 'None' ? value : '')
  }

  return (
    <div className={`app-select-wrapper ${className} h-14 min-w-[12rem] mb-6`} style={{ zIndex: zIndex }}>
      <Dropdown
        className={classNames({
          'app-select-select': true,
          'p-dropdown-trigger-icon-wrapper': value !== '',
          'bg-purple border-none': value === options[0].value,
          'bg-ruby border-none': value === options[1].value,
          'bg-cyan border-none': value === options[2].value,
          'border-dark-blue': value === '',
          'rounded-3xl': true,
        })}
        valueTemplate={<div className={`${value ? 'text-white' : 'text-dark-blue'} text-2xl`}>{label}</div>}
        panelClassName="app-select-select-panel"
        value={label}
        options={options}
        onChange={e => handleChange(e.value)}
        onShow={() => setZIndex(2)}
        onHide={() => setZIndex(1)}
        optionLabel="label"
        optionValue="value"
        placeholder={label}
      />
    </div>
  )
}
