import { FC } from 'react'

export const TableIcon: FC = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.28906 7.29102H17.8297" stroke="#598AFE" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7.28906 14.2358H17.8297" stroke="#598AFE" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7.28906 10.7642H17.8297" stroke="#598AFE" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7.28906 17.709H17.8297" stroke="#598AFE" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
