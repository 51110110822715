import React, { FC, ReactElement, useState } from 'react'
import { Prompt } from 'react-router-dom'
import OptionsModal from '../OptionsModal'
import { Action, Location } from 'history'
import WarningIcon from '../../atoms/Icons/WarningIcon.svg'

// TODO: ADD storybook

interface NavigationBlockerProps {
  modalMessage: ReactElement
  securePathRegExp: RegExp
  checkIfCanNavigate: () => boolean
  onConfirmNavigation: (path: string) => void
  isRefreshSensitive?: boolean
}

export const NavigationBlocker: FC<NavigationBlockerProps> = ({
  modalMessage,
  securePathRegExp,
  checkIfCanNavigate,
  onConfirmNavigation,
  isRefreshSensitive = false,
}) => {
  const [showNavigationBlockModal, setShowNavigationBlockModal] = useState<boolean>(false)
  const [isNavigationBlocked, setIsNavigationBlocked] = useState<boolean>(false)
  const [path, setPath] = useState<string>('')

  const handleCancelNavigate = () => {
    setPath('')
    setShowNavigationBlockModal(false)
  }

  const handleNavigate = (location: Location, action: Action): boolean => {
    const isSecureNavigation = securePathRegExp.test(location.pathname)
    if (isNavigationBlocked || isSecureNavigation) {
      return true
    }

    const canExit = checkIfCanNavigate()
    if (!isNavigationBlocked && !canExit) {
      setShowNavigationBlockModal(true)
      if (path !== '') {
        return true
      }
      setPath(location.pathname)
      return false
    }

    return true
  }

  const handleRefresh = (event: BeforeUnloadEvent) => {
    if (isRefreshSensitive) {
      const canExit = checkIfCanNavigate()
      if (!canExit) {
        event.preventDefault()
        event.returnValue = ''
        return ''
      }
    }
  }

  window.onbeforeunload = handleRefresh

  const handleConfirmExit = () => {
    setIsNavigationBlocked(true)
    onConfirmNavigation(path)
  }

  return (
    <>
      <Prompt message={handleNavigate} />
      <OptionsModal
        secondButtonText="Yes, continue"
        secondButtonType="info"
        firstButtonText="Cancel"
        firstButtonType="clear"
        visible={showNavigationBlockModal}
        onFirstButtonClick={handleCancelNavigate}
        onSecondButtonClick={handleConfirmExit}
        onClose={handleCancelNavigate}
        message={modalMessage}
        icon={<WarningIcon />}
      />
    </>
  )
}
