import { FC } from 'react'
import toast, { Toast, Toaster } from 'react-hot-toast'
import CrossSVG from '../atoms/Icons/Cross.svg'
import { ConfirmIcon } from '../atoms/Icons/ConfirmIcon'
import CheckSVG from '../atoms/Icons/Check.svg'

interface ToastBodyProps {
  title: string
  description: string
}

// This is because react-hot-toast body can be a function that receives the toast object. We will use it to close the toast with the X button.
export const createToastBody: (props: ToastBodyProps) => (t: Toast) => JSX.Element = ({ title, description }) => {
  return (t: Toast) => {
    const color = t.style?.color
    return (
      <div className="flex flex-row gap-8 w-full justify-between ml-2">
        <div>
          <div className="font-bold text-2xl">{title}</div>
          <div>{description}</div>
        </div>
        <div>
          <span className="cursor-pointer" onClick={() => toast.dismiss(t.id)}>
            <CrossSVG width="1.4rem" height="1.4rem" color={color} strokeWidth="1.3" />
          </span>
        </div>
      </div>
    )
  }
}

export const ToastNotification: FC = () => {
  return (
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={14}
      containerStyle={{
        top: 90,
        right: 40,
      }}
      toastOptions={{
        // Define default options
        duration: 5000,
        style: {
          background: '#ffffff',
          color: '#00112F',
          padding: '2.5rem',
          border: '1px solid #EEEEEE',
          textAlign: 'left',
          borderRadius: '1rem',
          boxShadow: '10px 10px 20px rgba(0, 17, 47, 0.15)',
          fontSize: '1.4rem',
          fontFamily: 'Manrope',
          minWidth: '45rem',
          maxWidth: '45rem',
          fontWeight: '400',
        },

        // Default options for specific types
        success: {
          style: {
            background: '#F1FCF6',
            border: '1px solid #E1ECE6',
            color: '#559E7A',
          },
          icon: <CheckSVG width="3rem" height="2.5rem" color="#559E7A" strokeWidth="0.8" />,
        },
        error: {
          duration: 10000,
          style: {
            background: '#F8E0E5',
            border: '1px solid #E8D0D5',
            color: '#CE2D4F',
          },
          icon: <ConfirmIcon width="3rem" height="3rem" color="#CE2D4F" />,
        },
        loading: {
          duration: 60 * 60 * 1000,
        },
      }}
    />
  )
}

export default toast
