import React, { useEffect, useState } from 'react'
import { AVAILABLE_EMPLOYEE_HIGHLIGHTS, EMPLOYEE_HIGHLIGHT_ICON_MAP } from '../../../constants/employee'
import { Employee, EmployeeKeys, EmployeeService, EmployeeSort } from '../../../services/EmployeeService'
import { SortOrderEnum } from '../../../types/enums/sort-order.enum'
import { initialSortingEmployees } from '../../../utils/sortUtils'
import AppFilterButton from '../../atoms/AppFilterButton/AppFilterButton'
import { HighlightsTable } from '../../organisms/HighlightsTable'
import { Filter } from '../../pages/ReviewsPage'

interface HighlightsSectionProps {
  employees: Employee[]
  defaultFilter?: Filter
  handleOnEmployeeClicked: (employee: Employee) => string
}

const HighlightsSection: React.FC<HighlightsSectionProps> = ({ employees, defaultFilter, handleOnEmployeeClicked }) => {
  const [enabledFilter, setEnabledFilter] = useState<Filter | undefined>(defaultFilter)
  const [filteredAndSortedEmployees, setFilteredAndSortedEmployees] = useState<Employee[]>([])
  const [selectedHighlightsSort, setSelectedHighlightsSort] = useState<EmployeeSort[]>([initialSortingEmployees])

  const highlightsCount: Record<string, number> = employees.reduce((acc: Record<string, number>, employee) => {
    employee.highlights?.forEach((highlight: string) => {
      acc[highlight] = (acc[highlight] ?? 0) + 1
    })
    return acc
  }, {})

  useEffect(() => {
    if (employees && enabledFilter) {
      const filteredResults: Employee[] = employees.filter(employee =>
        (employee?.[enabledFilter.property as EmployeeKeys] as string[])?.includes(enabledFilter.value)
      )
      setFilteredAndSortedEmployees(EmployeeService.sortEmployees(filteredResults, selectedHighlightsSort))
    }
  }, [employees, enabledFilter, selectedHighlightsSort])

  const handleHighlightFilterSelected = (highlight: string) => {
    setEnabledFilter({ property: 'highlights', value: highlight })
  }

  const handleHighlightSortSelected = (property: string, order: SortOrderEnum) => {
    setSelectedHighlightsSort([{ property: property as EmployeeKeys, order }])
  }

  const renderFilterButtons = () =>
    AVAILABLE_EMPLOYEE_HIGHLIGHTS.map(highlight => {
      const highlightValue = highlightsCount[highlight.value] || 0
      const active = enabledFilter?.value === highlight.value

      return (
        <AppFilterButton
          key={highlight.value}
          active={active}
          onClick={() => handleHighlightFilterSelected(highlight.value)}
        >
          {EMPLOYEE_HIGHLIGHT_ICON_MAP[highlight.value]}&nbsp;&nbsp;{highlight.label}
          {highlightValue > 0 && ` (${highlightValue})`}
        </AppFilterButton>
      )
    })

  return (
    <div>
      <h1 className="text-4xl font-semibold mb-12">Highlights</h1>
      <div className="grow flex flex-col justify-center gap-4 pb-6 rounded-2xl shadow-default py-5 my-3 px-12">
        <span className="text-3xl font-semibold my-10">🙋 Individual Highlights</span>
        <div className="flex flex-1 flex-wrap gap-4">{renderFilterButtons()}</div>
        <HighlightsTable
          employees={filteredAndSortedEmployees}
          handleOnEmployeeClicked={handleOnEmployeeClicked}
          alertFilter={enabledFilter?.value || ''}
          selectedSort={selectedHighlightsSort[0]}
          onSortSelected={handleHighlightSortSelected}
        />
      </div>
    </div>
  )
}

export default HighlightsSection
