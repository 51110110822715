import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Spinner from '../atoms/Spinner'
import { TeamInfo, TeamService } from '../../services/TeamService'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../services/EventTrackingService'
import TeamSummaryIssues from '../organisms/TeamSummary/TeamSummaryIssues'
import TeamSummaryChart from '../organisms/TeamSummary/TeamSummaryChart'
import TeamSummaryTable from '../organisms/TeamSummary/TeamSummaryTable'
import TeamSummaryInfoHeader from '../organisms/TeamSummary/TeamSummaryInfoHeader'
import {
  EMPLOYEE_HIGHLIGHT_FLIGHT_RISK,
  EMPLOYEE_HIGHLIGHT_ICON_MAP,
  EMPLOYEE_HIGHLIGHT_MISMATCH,
  EMPLOYEE_HIGHLIGHT_ROCKSTAR,
} from '../../constants/employee'
import { calculateAvgReviewForTeam, calculateEmployeeScore } from '../../utils/averageUtils'
import { EmployeeScores } from '../../services/EmployeeService'
import { hasEmployeeReviews } from '../../utils/employeeUtils'

const TOTAL_TOPICS = 5

export const TeamSummary = (): JSX.Element => {
  const { teamId } = useParams<{ teamId: string }>()
  const [teamEmployees, setTeamEmployees] = useState<EmployeeScores[]>([])
  const [teamInfo, setTeamInfo] = useState<TeamInfo>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    trackEvent(TrackingCategoryEnum.TEAM_REPORT, TrackingCategoryEventEnum.TEAM_REPORT.PAGE_ON_LOAD, '')
    const requestData = async () => {
      const team = await TeamService.findTeamInfo(Number(teamId))
      setTeamInfo(team)
      const employees = await TeamService.findTeamMembers(Number(teamId))

      employees.forEach(employee => {
        calculateEmployeeScore(employee as EmployeeScores, TOTAL_TOPICS)
        addAlertIcons(employee as EmployeeScores)
      })

      const teamScore = calculateAvgReviewForTeam(employees as EmployeeScores[], TOTAL_TOPICS)
      employees.unshift(teamScore)
      setTeamEmployees(employees as EmployeeScores[])
      setLoading(false)
    }
    requestData()
  }, [teamId])

  const addAlertIcons = (employee: EmployeeScores): EmployeeScores => {
    const iconRockstar = employee?.rockstar ? EMPLOYEE_HIGHLIGHT_ICON_MAP[EMPLOYEE_HIGHLIGHT_ROCKSTAR] : ''
    const iconFlightRisk = employee?.flightRisk ? EMPLOYEE_HIGHLIGHT_ICON_MAP[EMPLOYEE_HIGHLIGHT_FLIGHT_RISK] : ''
    const iconMisMatch = employee?.mismatch ? EMPLOYEE_HIGHLIGHT_ICON_MAP[EMPLOYEE_HIGHLIGHT_MISMATCH] : ''
    const highlightsIconsEmployee = `${iconRockstar} ${iconFlightRisk} ${iconMisMatch}`
    employee.name = `${highlightsIconsEmployee} ${employee.name}`
    return employee
  }

  const textInfoEmployeeWithoutReviews = teamEmployees.slice(1).some(employee => !hasEmployeeReviews(employee)) ? (
    <div className="text-stone-gray font-body text-xl leading-snug mt-8">
      <span>(*) </span>
      <span className="font-bold">No assessment has been completed for this user yet.</span>
      <span> Complete an assessment for this user to boost your understanding of the team.</span>
    </div>
  ) : null

  if (loading) return <Spinner />

  return (
    <>
      <div className="container mx-auto">
        <div className="employee-results">
          <TeamSummaryInfoHeader
            teamInfo={teamInfo as TeamInfo}
            onUpdateTeamInfo={teamUpdate => setTeamInfo(teamUpdate)}
          />
          <div className="p-20 shadow-default rounded-2xl mt-20">
            <div className="text-4xl font-semibold mb-8">Assessment</div>
            <TeamSummaryIssues employees={teamEmployees} />
            <TeamSummaryChart teamEmployees={teamEmployees} />
            <TeamSummaryTable teamEmployees={teamEmployees} />
          </div>
          {textInfoEmployeeWithoutReviews}
        </div>
      </div>
    </>
  )
}
