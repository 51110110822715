import moment from 'moment'
import { Period } from '../../types/definitions/Period'
import { timeDifferenceAsString, DateFormat } from '../../utils/periods/PeriodUtils'

interface PeriodCounterProps {
  currentPeriod: Period | undefined
}

export const PeriodCounter = ({ currentPeriod }: PeriodCounterProps) => {
  return (
    <div className="flex flex-col items-end gap-6">
      <span className="text-4xl leading-10 font-medium text-blue -mb-[2px]">
        {currentPeriod &&
          currentPeriod.startDate &&
          currentPeriod.endDate &&
          timeDifferenceAsString(moment(), moment(currentPeriod?.endDate))}{' '}
        left
      </span>
      <div className="text-2xl leading-9 font-medium ">
        {currentPeriod?.name} (Until {moment(currentPeriod?.endDate).format(DateFormat)})
      </div>
    </div>
  )
}
