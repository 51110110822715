import { Column, ColumnProps } from 'primereact/column'
import { DataTable, DataTableExpandedRows, DataTableRowClickEvent } from 'primereact/datatable'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../..'
import {
  EMPLOYEE_STATUS_SKIPPED,
  EMPLOYEE_STATUS_DONE,
  EMPLOYEE_STATUS_REQUESTED,
  EMPLOYEE_STATUS_TODO,
  EMPLOYEE_STATUS_UNFINISHED,
} from '../../constants/employee'
import { Employee, EmployeeReviewer } from '../../services/EmployeeService'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../services/EventTrackingService'
import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import { TableCellBody } from '../molecules/AppTable/TableCellBody'
import { EmployeeTableBody } from '../molecules/EmployeeTable/EmployeeTableBody'
import { EmployeeTableHeader } from '../molecules/EmployeeTable/EmployeeTableHeader'
import { EmployeeTableHierarchyBody } from '../molecules/EmployeeTable/EmployeeTableHierarchyBody'
import { EmployeeTableOptionsBody } from '../molecules/EmployeeTable/EmployeeTableOptionsBody'
import { ExpandedReviewersTable } from './ExpandedReviewersTable'
import { RequestReviewButton } from './RequestReviewButton'
import { getHoverColorForEmployee } from '../../utils/hoverColorUtils'

interface ReviewTableParams {
  employees: Employee[] | undefined
  allEmployees: Employee[] | undefined
  selectedSort?: { property: string; order: SortOrderEnum }
  handleNewReview: (employee: Employee) => string
  handleOnEmployeeClicked: (employee: Employee) => string
  onHierarchyNavigate: (employee: Employee) => void
  onSortSelected?: (field: string, newSortOrder: SortOrderEnum) => void
}

export interface ColumnPropsIndexed extends ColumnProps {
  rowIndex: number
}

export const ReviewTable = ({
  employees,
  allEmployees,
  selectedSort,
  handleNewReview,
  handleOnEmployeeClicked,
  onHierarchyNavigate,
  onSortSelected,
}: ReviewTableParams): JSX.Element => {
  const history = useHistory()
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | undefined>(undefined)
  const currentUser = useSelector<RootState, Employee>(state => state.auth.user as Employee)

  useEffect(() => {
    const newExpandedRows = { ...expandedRows }
    employees?.forEach(e => {
      if (e.reviewers && e.reviewers?.length < 2) return
      const rowToExpand = !(e.reviewers?.every(r => r.lastReview === null) && !e.skippedReviews?.length)
      if (rowToExpand) newExpandedRows[e.id] = true
    })
    setExpandedRows(newExpandedRows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleRowExpand = (r: Employee) => {
    const newExpandedRows = { ...expandedRows }
    if (newExpandedRows?.[r.id]) {
      delete newExpandedRows[r.id]
    } else {
      newExpandedRows[r.id] = true
    }
    setExpandedRows(newExpandedRows)
  }

  const createNewReview = (employee: Employee): string => {
    return handleNewReview(employee)
  }

  const handleEmployeeSelected = (employee: Employee): string => {
    return handleOnEmployeeClicked(employee)
  }

  const handleHierarchyNavigation = (employee: Employee): void => {
    onHierarchyNavigate(employee)
  }

  const handleSortSelected = (field: string) => (newSortOrder: SortOrderEnum) => {
    onSortSelected?.(field, newSortOrder)
  }

  const onRowClick = (e: DataTableRowClickEvent): void => {
    trackEvent(TrackingCategoryEnum.REVIEW_LIST, TrackingCategoryEventEnum.REVIEW_LIST.CLICK_EMPLOYEE_ROW, '')
    history.push(handleEmployeeSelected(e.data as Employee))
  }

  const dataTableExpandProps = {
    expandedRows,
    rowExpansionTemplate: (rowData: Employee) => (
      <div className="flex flex-row bg-white">
        <div className="flex-[7]"></div>
        <div className="flex-[4]">
          {
            <ExpandedReviewersTable
              employee={rowData}
              reviewers={
                rowData.reviewers?.filter(r => r.reviewer.id !== currentUser.id) as EmployeeReviewer[] | undefined
              }
              handleNewReview={handleNewReview}
              handleOnEmployeeClicked={handleOnEmployeeClicked}
            ></ExpandedReviewersTable>
          }
        </div>
      </div>
    ),
  }

  return (
    <DataTable
      className="user-list-container"
      tableClassName="user-list-table table-fixed"
      paginator
      rows={10}
      rowsPerPageOptions={[5, 10, 30, 50, 500]}
      alwaysShowPaginator={false}
      rowClassName={(employee: Employee) => ({
        'review-list-table-row': true,
        'user-list-table-row --todo': employee.status === EMPLOYEE_STATUS_TODO,
        'user-list-table-row --done':
          employee.status === EMPLOYEE_STATUS_DONE || employee.status === EMPLOYEE_STATUS_REQUESTED,
        'user-list-table-row --unfinished': employee.status === EMPLOYEE_STATUS_UNFINISHED,
        'bg-green-cell even:bg-green-cell/50 hover:border-green': employee.status === EMPLOYEE_STATUS_SKIPPED,
        'cursor-pointer': true,
      })}
      onRowClick={onRowClick}
      cellClassName={() => {
        return 'truncate'
      }}
      value={employees}
      dataKey="id"
      editMode="row"
      {...dataTableExpandProps}
    >
      <Column
        headerClassName="user-list-table-header"
        field="name"
        header={EmployeeTableHeader({
          title: 'Name',
          sortable: selectedSort?.property === 'name' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('name'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        headerClassName="user-list-table-header"
        header={EmployeeTableHeader({ title: 'Direct Reports' })}
        alignHeader={'center'}
        className={'justify-center'}
        body={EmployeeTableHierarchyBody({
          handleHierarchyNavigation,
        })}
      />
      <Column
        headerClassName="user-list-table-header "
        field="jobTitle"
        header={EmployeeTableHeader({
          title: 'Job Title',
          sortable: selectedSort?.property === 'jobTitle' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('jobTitle'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        headerClassName="user-list-table-header"
        field="teams"
        header={EmployeeTableHeader({
          title: 'Teams',
          sortable: selectedSort?.property === 'teams' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('teams'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        headerClassName="user-list-table-header"
        field="reviewer"
        header={EmployeeTableHeader({
          title: 'Reviewer',
          sortable: selectedSort?.property === 'leader' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('leader'),
        })}
        body={(data: Employee, props: ColumnPropsIndexed) => {
          const CellBody = TableCellBody<Employee>({
            tooltip: true,
            getValue: (rowData: Employee) => {
              const reviewers: string[] = []
              rowData.reviewers &&
                rowData.reviewers
                  .filter(reviewer => {
                    return expandedRows && expandedRows[rowData.id] ? reviewer.reviewer.id === currentUser.id : true
                  })
                  .map(reviewer => reviewers.push(reviewer.reviewer.name))
              return Array.from(new Set(reviewers)).join(', ') || '-'
            },
            onClick: data.reviewers && data.reviewers.length > 1 ? toggleRowExpand : undefined,
          })
          return (
            <>
              {CellBody(data, props)}
              <RequestReviewButton
                key={'request-review-button' + data.id}
                reviewersId={data.reviewers?.map(reviewer => reviewer.reviewer.id) || []}
                onRequestAdded={() => history.go(0)}
                allEmployees={allEmployees || []}
                employee={data}
                showBackgroundHover={true}
                backgroundHoverColor={getHoverColorForEmployee(data)}
              />
            </>
          )
        }}
      />
      <Column
        headerClassName="user-list-table-header"
        field="status"
        header={EmployeeTableHeader({
          title: 'Status',
          sortable: selectedSort?.property === 'status' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('status'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        rowEditor
        headerStyle={{ minWidth: '13rem' }}
        bodyStyle={{ textAlign: 'center', position: 'relative' }}
        body={EmployeeTableOptionsBody({
          createNewReview,
          handleEmployeeSelected,
          showBackgroundHover: true,
        })}
      />
    </DataTable>
  )
}
