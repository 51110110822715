import { AppButton } from '../atoms/AppButton/AppButton'
import { FailIcon } from '../atoms/Icons/FailIcon'

import { Popup } from './Popup'

type ErrorModalProps = {
  visible: boolean
  icon?: JSX.Element
  message?: JSX.Element
  buttonText?: string
  onClose: () => void
}

const ErrorModal = ({ visible, icon, message, buttonText = 'Ok', onClose }: ErrorModalProps): JSX.Element => {
  return (
    <Popup visible={visible} onClose={onClose}>
      <div className="text-center [&>svg]:m-auto">
        {icon || <FailIcon />}
        {message && <p className="text-3xl leading-[40px] py-20">{message}</p>}
      </div>
      <div className="flex justify-center gap-8">
        <AppButton type="info" onClick={onClose}>
          {buttonText}
        </AppButton>
      </div>
    </Popup>
  )
}

export default ErrorModal
