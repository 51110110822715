import { DataTable, DataTableRowClickEvent } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import { TeamStatus } from '../../services/TeamService'
import { EmployeeService, ReviewSimple } from '../../services/EmployeeService'
import { useEffect, useState } from 'react'
import { EmployeeSimpleReviewsTableCellBody } from '../molecules/EmployeeSimpleReviewsTableCellBody'
import { ReviewSimpleProjection } from '../../api/openapi'

interface EmployeeSimpleReviewsTableParams {
  employeeId: number
  selectedSort?: { property: string; order: SortOrderEnum }
  handleOnRowClicked?: (row: ReviewSimpleProjection, employeeId: number) => void
  onSortSelected?: (field: string, newSortOrder: SortOrderEnum) => void
}

export const EmployeeSimpleReviewsTable = ({
  employeeId,
  selectedSort,
  handleOnRowClicked,
  onSortSelected,
}: EmployeeSimpleReviewsTableParams): JSX.Element => {
  const [data, setData] = useState<ReviewSimple[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    EmployeeService.getSimpleReviewsForEmployeeId(employeeId).then(records => {
      // Sort the data by reviewer
      const sortedRecords = records.sort(({ reviewer: a }, { reviewer: b }) => {
        if (!a || !b) return 0
        if (a > b) return 1
        if (a < b) return -1
        return 0
      })
      // filter the data to just show the most recent by reviewer and types
      const filteredRecords: ReviewSimple[] = []
      for (const rec of sortedRecords) {
        if (filteredRecords.every(record => record.reviewer !== rec.reviewer || record.reviewType !== rec.reviewType)) {
          filteredRecords.push(rec)
        }
      }
      setData(filteredRecords)
      setLoading(false)
    })
  }, [employeeId])

  const handleRowSelected = (teamStatus: TeamStatus): void => {
    handleOnRowClicked && handleOnRowClicked(teamStatus as ReviewSimpleProjection, employeeId)
  }

  const onRowClick = (e: DataTableRowClickEvent) => {
    handleRowSelected(e.data as TeamStatus)
  }

  const rowClassNames = (row: TeamStatus) => {
    return {
      'rounded-xl': true,
      'cursor-pointer': handleOnRowClicked !== undefined,
      'user-list-table-row --done': setData.length,
    }
  }
  return (
    <DataTable
      className="app-table-container bg-white !-mx-4"
      tableClassName="app-table hide-header !px-0"
      loading={loading}
      header={null}
      rowClassName={rowClassNames}
      onRowClick={onRowClick}
      value={data}
      dataKey="id"
    >
      <Column
        headerClassName="w-[15rem]"
        field="reviewer"
        header={null}
        className="flex table-text-overflow"
        body={EmployeeSimpleReviewsTableCellBody()}
      />
      <Column
        headerClassName="w-[15rem]"
        field="reviewType"
        header={null}
        className="flex table-text-overflow"
        body={EmployeeSimpleReviewsTableCellBody()}
      />
      <Column
        headerClassName="w-[15rem]"
        field="attitude"
        header={null}
        className="flex justify-center"
        body={EmployeeSimpleReviewsTableCellBody({ numericStyles: true })}
      />
      <Column
        headerClassName="w-[15rem]"
        field="communication"
        header={null}
        className="flex justify-center"
        body={EmployeeSimpleReviewsTableCellBody({ numericStyles: true })}
      />
      <Column
        headerClassName="w-[15rem]"
        field="aptitude"
        header={null}
        className="flex justify-center"
        body={EmployeeSimpleReviewsTableCellBody({ numericStyles: true })}
      />
    </DataTable>
  )
}
