import React, { FC } from 'react'

const SearchIcon: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5.3529" cy="5.3529" r="4.8529" stroke="#005ECA" />
      <line x1="9.71387" y1="8.94971" x2="14.1849" y2="13.2929" stroke="#005ECA" strokeLinecap="round" />
    </svg>
  )
}

export default SearchIcon
