import classNames from 'classnames'
import history from '../../../utils/history'
import { Employee, EmployeeKeys } from '../../../services/EmployeeService'
import { EMPLOYEE_HIGHLIGHT_ICON_MAP } from '../../../constants/employee'
import React, { createRef } from 'react'
import { Tooltip } from '@material-ui/core'
import { createPathWithLang } from '../../../utils/languagePathUtils'
import { NetworkConstants } from '../../../utils/NetworkConstants'
import { Link } from 'react-router-dom'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../../services/EventTrackingService'
import { createPathToEmployeeResults } from '../../../utils/employeePathUtils'
import useIsOverflow, { DirectionsEnum } from '../../../hooks/useIsOverflow'
import { useSelector } from 'react-redux'
import { RootState } from '../../..'
import { ColumnPropsIndexed } from '../../organisms/ReviewTable'
import IntroductionTooltip from '../IntroductionTooltip'
import { formatDate } from '../../../utils/dateUtils'
import { DateFormat } from '../../../utils/periods/PeriodUtils'
import { getHoverColorForEmployee, hoverColorsObjectByEmployee } from '../../../utils/hoverColorUtils'

interface EmployeeTableBodyParams {
  handleEmployeeSelected?: (employee: Employee) => void
  handleTeamClicked?: (id: number) => void
  tooltip?: boolean
  tooltipBody?: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const EmployeeTableBody = ({
  handleEmployeeSelected,
  handleTeamClicked,
  tooltip,
  tooltipBody,
}: EmployeeTableBodyParams = {}) => {
  const currentPath = history?.location?.pathname
  const currentUser = useSelector<RootState, Employee>(state => state.auth.user as Employee)

  const nameCellRef = createRef<HTMLDivElement>()
  const nameCellOverflow = useIsOverflow(nameCellRef, DirectionsEnum.HORIZONTAL)

  return (rowData: Employee, props: ColumnPropsIndexed): JSX.Element => {
    // @ts-expect-error needs fix
    if (rowData.id === 'skeleton') {
      return (
        <span>
          <div className="skeleton" />
        </span>
      )
    }

    const isAdminPanel = currentPath?.match(/\/(admin)(\/|$)/g)
    const hasHighlights = rowData?.highlights?.length
    const defaultClassNames = {
      'text-ellipsis text-xl': true,
      'font-bold': hasHighlights && !isAdminPanel && props.field === 'name',
    }

    const hoverColor = getHoverColorForEmployee(rowData)

    const spanClassName = classNames(defaultClassNames)

    const hoverEffectClassNames = classNames('clickable-hover-effect', hoverColorsObjectByEmployee(rowData))
    const clickableTextClassNames = classNames({
      ...defaultClassNames,
      'cursor-pointer': true,
      'text-blue': true,
      'clickable-hover-effect': true,
      ...hoverColorsObjectByEmployee(rowData),
    })

    const clickableClassName = classNames(hoverEffectClassNames, clickableTextClassNames)

    const getCellValue = () => {
      switch (props.field) {
        case 'name':
          return rowData.name
        case 'teams':
          return (rowData.teams || []).map(team => team.name).join(', ') || '-'
        case 'reviewer':
          const reviewers: string[] = []
          rowData.reviewers && rowData.reviewers.map(reviewer => reviewers.push(reviewer.reviewer.name))
          reviewers.sort((a, b) => {
            // Current user it is the 1º element
            if (a === currentUser.name) return -1
            if (b === currentUser.name) return 1
            if (a > b) return 1
            if (a < b) return -1
            return 0
          })
          return Array.from(new Set(reviewers)).join(', ') || '-'
        case 'leaders':
          const leaders: string[] = []
          rowData.leaders && rowData.leaders.map(leader => leaders.push(leader.name))
          leaders.sort((a, b) => {
            // Current user it is the 1º element
            if (a === currentUser.name) return -1
            if (b === currentUser.name) return 1
            if (a > b) return 1
            if (a < b) return -1
            return 0
          })
          return Array.from(new Set(leaders)).join(', ') || '-'
        case 'status':
          return rowData.status || '-'
        case 'alertDate':
          return formatDate(rowData.alerts[0]?.lastUpdate || '', DateFormat) || '-'
        default:
          return rowData[props.field as EmployeeKeys] || '-'
      }
    }
    const defaultTableCell = (cellValue: React.ReactNode) => {
      return tooltip ? (
        <div className=" leading-8">
          <Tooltip
            title={tooltipBody || cellValue || ''}
            arrow
            enterDelay={500}
            enterNextDelay={500}
            classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
          >
            <span className={spanClassName}>{cellValue}</span>
          </Tooltip>
        </div>
      ) : (
        <div className=" leading-8">
          <span className={spanClassName}>{cellValue}</span>
        </div>
      )
    }

    const goToTeamSummary = (teamId: number): string => {
      return createPathWithLang(NetworkConstants.URL_TEAM_SUMMARY.replace(':teamId', teamId.toString()))
    }

    const handleClickTeam = (id: number) => {
      return goToTeamSummary(id)
    }

    const trackTeamClickEvent = () => {
      rowData.teams &&
        trackEvent(
          TrackingCategoryEnum.REVIEW_LIST,
          TrackingCategoryEventEnum.REVIEW_LIST.CLICK_EMPLOYEE_TEAM_LINK,
          JSON.stringify({ selectedTeamId: rowData.teams[0].id })
        )
    }

    const _teamsCell = (rowData: Employee): JSX.Element => {
      // TODO: FIX AFTER "DEMO LEADERS MEETING FEB2021"
      return isAdminPanel ? (
        <span className={spanClassName}>{(rowData.teams || []).map(team => team.name).join(', ') || '-'}</span>
      ) : (
        <Tooltip
          title="Click to navigate to the team's report"
          arrow
          enterDelay={500}
          enterNextDelay={500}
          classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
        >
          <span className={spanClassName}>
            {(rowData.teams || []).map((team, i) => {
              let teamText = '-'
              if (rowData.teams) {
                teamText = i < rowData.teams.length - 1 && rowData.teams.length > 1 ? `${team.name}, ` : team.name
              }
              return (
                <Link
                  onMouseUp={trackTeamClickEvent}
                  to={handleClickTeam(team.id)}
                  key={i}
                  onClick={e => e.stopPropagation()}
                >
                  <div className={hoverEffectClassNames}>
                    <span className={clickableTextClassNames}>
                      <p>{teamText}</p>
                    </span>
                  </div>
                </Link>
              )
            })}
          </span>
        </Tooltip>
      )
    }

    const cellValue = getCellValue()

    switch (props.field) {
      case 'name':
        const highlightsIcons = rowData?.highlights?.map(highlight => EMPLOYEE_HIGHLIGHT_ICON_MAP[highlight]).join('  ')
        const tooltipBaseTitle = "Click to navigate to the employee's report"
        const tooltipTitle = nameCellOverflow ? `${cellValue} (${tooltipBaseTitle})` : tooltipBaseTitle
        if (props.rowIndex <= 0) {
          return (
            <IntroductionTooltip id="introduction-step-1" placement="bottom">
              <Tooltip
                title={isAdminPanel ? cellValue : tooltipTitle}
                arrow
                enterDelay={500}
                enterNextDelay={500}
                classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
              >
                <div className=" py-2" ref={nameCellRef}>
                  {isAdminPanel ? (
                    <span>{cellValue}</span>
                  ) : (
                    <Link to={createPathToEmployeeResults(rowData.id)} onClick={e => e.stopPropagation()}>
                      <span className={clickableClassName}>
                        {highlightsIcons && <span className="mr-2">{highlightsIcons}</span>}
                        <span>{cellValue}</span>
                      </span>
                    </Link>
                  )}
                </div>
              </Tooltip>
            </IntroductionTooltip>
          )
        }
        return (
          <Tooltip
            title={isAdminPanel ? cellValue : tooltipTitle}
            arrow
            enterDelay={500}
            enterNextDelay={500}
            classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
          >
            <div className=" py-2" ref={nameCellRef}>
              {isAdminPanel ? (
                <span>{cellValue}</span>
              ) : (
                <Link to={createPathToEmployeeResults(rowData.id)} onClick={e => e.stopPropagation()}>
                  <span className={clickableClassName}>
                    {highlightsIcons && <span className="mr-2">{highlightsIcons}</span>}
                    <span>{cellValue}</span>
                  </span>
                </Link>
              )}
            </div>
          </Tooltip>
        )
      case 'teams':
        return _teamsCell(rowData)
      default:
        return defaultTableCell(cellValue)
    }
  }
}
