type RowContent = string | { answer: string; styles: string }

type TableProps = {
  rows: Record<string, RowContent>[]
}

const Table = (props: TableProps): JSX.Element => {
  const generateRow = (row: Record<string, RowContent>, index: number): JSX.Element => {
    const [key, value] = Object.entries(row)[0]
    let answer = ''
    let styles = ''
    if (typeof value === 'string') {
      answer = value
      styles = ''
    } else {
      answer = value.answer
      styles = value.styles
    }

    return (
      <div className="flex-auto flex-col w-1/2" key={index}>
        <b className={`cell ${styles}`}>{key}</b>
        <div className={`cell ${styles}`}>{answer}</div>
      </div>
    )
  }

  return <div className="top-separator flex">{props.rows.map((row, index) => generateRow(row, index))}</div>
}

export default Table
