import axios from 'axios'
import { useEffect } from 'react'
import { appDispatch } from '../..'
import { logout, StorageConfiguration } from '../../slices/auth-slice'
import jwt_decode, { JwtPayload } from 'jwt-decode'
interface DecodedJwtToken {
  role: string | undefined
  permissions: string[] | undefined
}
export const useAuthJWT = (): void => {
  useEffect(() => {
    const currentToken = localStorage.getItem(StorageConfiguration.tokenStorageTag)
    if (currentToken != null) {
      const decodedToken = jwt_decode<DecodedJwtToken>(currentToken)
      if (!decodedToken.permissions) {
        appDispatch(logout())
      }
    }

    // Intercept request to add auth token
    axios.interceptors.request.use(
      function (config) {
        if (localStorage.getItem(StorageConfiguration.tokenStorageTag) != null) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${localStorage.getItem(StorageConfiguration.tokenStorageTag)}`,
          }
        }
        return config
      },
      function (error) {
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      response => response,
      error => {
        const message = error?.response?.data?.message
        const token = localStorage.getItem(StorageConfiguration.tokenStorageTag) || ''
        const decodedToken = jwt_decode<JwtPayload>(token)
        const decodedDate = decodedToken.exp ?? 0
        const currentDate = Date.now() / 1000
        if ((message && message.includes('JWT')) || currentDate > decodedDate) {
          appDispatch(logout())
        }

        return Promise.reject(error)
      }
    )
  }, [])
}
