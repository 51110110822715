import StopHandIcon from '../atoms/Icons/StopHandIcon.svg'
import ErrorMessage from '../organisms/ErrorMessage'

const Unauthorized = (): JSX.Element => {
  const message = (
    <p>
      Sorry, you are not authorized
      <br />
      to access this page
    </p>
  )
  return (
    <ErrorMessage
      className="mt-32"
      icon={<StopHandIcon />}
      title={message}
      body={'Contact an admin user for more information'}
    />
  )
}

export default Unauthorized
