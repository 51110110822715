import { FC, useState } from 'react'
import { TeamInfo } from '../../../services/TeamService'
import { AppInput } from '../../atoms/AppInput/AppInput'
import { AppSmallButton } from '../../atoms/AppSmallButton/AppSmallButton'
import CheckEditSVG from '../../atoms/Icons/CheckEdit.svg'
import CrossEditSVG from '../../atoms/Icons/CrossEdit.svg'

interface EditTeamNameProps {
  team: TeamInfo
  onIsEditTeamName: () => void
  onSaveTeamNameChanged: (teamId: number, newTeamName: string) => void
}

const EditTeamName: FC<EditTeamNameProps> = ({ team, onIsEditTeamName, onSaveTeamNameChanged }) => {
  const [value, setValue] = useState<string>(team.name)

  const handleCheck = (): boolean => value === team?.name

  const handleSaveTeamName = () => {
    onSaveTeamNameChanged(team.id, value)
    onIsEditTeamName()
  }

  return (
    <div className="flex gap-2">
      <AppInput placeholder="Team" value={value} onChange={value => setValue(value)} />
      <div className="flex gap-2 h-[100%]">
        <AppSmallButton type="info" disabled={handleCheck()} onClick={handleSaveTeamName}>
          <CheckEditSVG />
        </AppSmallButton>
        <AppSmallButton type="clear" onClick={onIsEditTeamName}>
          <CrossEditSVG />
        </AppSmallButton>
      </div>
    </div>
  )
}

export default EditTeamName
