import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef } from 'react'
import Validator from 'validator'

export interface AppInputProps {
  type?: string
  placeholder?: string
  searchIcon?: boolean
  label?: string
  value?: string
  disabled?: boolean
  helpText?: boolean
  warningText?: string
  className?: string
  autoFocus?: boolean
  onChange?: (inputValue: string) => void
  onSubmit?: (inputValue: string) => void
  setValidation?: (isValid: boolean) => void
  onClear?: () => void
}

export const AppInput: FC<AppInputProps> = ({
  type,
  placeholder,
  searchIcon,
  label,
  value,
  disabled,
  helpText,
  warningText,
  className = '',
  autoFocus = false,
  onChange,
  onSubmit,
  setValidation,
  onClear,
}: AppInputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null)
  const maxLength = 240

  const isValid = (): boolean => {
    if (inputRef.current === null) return false
    const value = inputRef.current.value
    return !Validator.isEmpty(value) && Validator.isLength(value, { max: maxLength })
  }

  const handleChange = (_: ChangeEvent<HTMLInputElement>): void => {
    if (inputRef.current != null) {
      setValidation?.(isValid())
      onChange?.(inputRef.current.value)
    }
  }

  const handleKeyUp = (keyPressed: KeyboardEvent): void => {
    if (keyPressed.key === 'Enter' && inputRef.current != null) {
      onSubmit?.(inputRef.current.value)
    }
  }

  useEffect(() => {
    if (autoFocus) inputRef?.current?.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setValidation?.(isValid())
  })

  return (
    <div className="app-input-wrapper w-[100%] flex justify-center">
      {label && <span className="app-input-label">{label}</span>}
      <input
        className={`app-input-input ${searchIcon ? 'searchIcon' : ''} ${className}`}
        type={type || 'text'}
        ref={inputRef}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        maxLength={maxLength}
        disabled={disabled}
      />
      {onClear ? (
        <button className="absolute flex self-end mr-5" onClick={onClear}>
          <span className="text-2xl font-medium">x</span>
        </button>
      ) : null}
      {warningText ? (
        <span>
          <p className="app-input-warning-text">{warningText}</p>
        </span>
      ) : (
        helpText && (
          <p className="app-input-warning-text">
            <br />
          </p>
        )
      )}
    </div>
  )
}
