import { useEffect } from 'react'

interface props {
  isPreviousDisabled: boolean
  isNextDisabled: boolean
  handleGoToNextTopic: () => void
  handleBackToPreviousTopic: () => void
}

const useNextPrevKeyboard = ({
  isPreviousDisabled,
  isNextDisabled,
  handleBackToPreviousTopic,
  handleGoToNextTopic,
}: props) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (!isNextDisabled && event.key === 'ArrowRight') {
        handleGoToNextTopic()
      } else if (!isPreviousDisabled && event.key === 'ArrowLeft') {
        handleBackToPreviousTopic()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleGoToNextTopic, handleBackToPreviousTopic, isPreviousDisabled, isNextDisabled])
}

export default useNextPrevKeyboard
