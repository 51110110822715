import { FC } from 'react'

interface TrackProps {
  trackClasses?: string
  showStepLines?: boolean
  style?: React.CSSProperties
}

const Track: FC<TrackProps> = ({ trackClasses = '', style = {}, showStepLines }) => {
  const trackBaseClasses = 'absolute w-[0.2rem] left-2/4 -translate-x-2/4'
  return (
    <div
      className={`${trackBaseClasses} ${trackClasses} ${showStepLines ? 'bg-[#f2f2f2]' : ''}`}
      style={{ ...style }}
    ></div>
  )
}

interface StepProps {
  isFirstStep?: boolean
  isLastStep?: boolean
  stepsOffset?: string
  containerClasses?: string
  children?: React.ReactNode
  showStepLines?: boolean
}

export const CategorySelectorStep: FC<StepProps> = ({
  isFirstStep,
  isLastStep,
  stepsOffset,
  containerClasses = '',
  children,
  showStepLines = true,
}) => {
  const trackHeightStyles = {
    height: `calc(50% + ${stepsOffset || '0px'})`,
  }

  const trackPositionStyles = {
    topTrack: {
      ...(stepsOffset && { top: `-${stepsOffset}` }),
    },
    bottomTrack: {
      ...(stepsOffset && { bottom: `-${stepsOffset}` }),
    },
  }

  const TopTrack = () => (
    <Track style={{ ...trackHeightStyles, ...trackPositionStyles.topTrack }} showStepLines={showStepLines} />
  )
  const BottomTrack = () => (
    <Track style={{ ...trackHeightStyles, ...trackPositionStyles.bottomTrack }} showStepLines={showStepLines} />
  )

  return (
    <div className={`flex items-center relative ${containerClasses}`}>
      <div className="w-full h-full flex justify-center items-center">
        {!isFirstStep && <TopTrack />}
        {children && <div className="z-10">{children}</div>}
        {!isLastStep && <BottomTrack />}
      </div>
    </div>
  )
}
