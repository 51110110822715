import { FC } from 'react'
import classNames from 'classnames'

export interface AppButtonProps {
  type?: AppButtonType
  disabled?: boolean
  className?: string
  onClick?: () => void
}

export type AppButtonType = 'info' | 'error' | 'clear'

export const AppButton: FC<AppButtonProps> = ({ onClick, children, type = 'info', disabled = false, className }) => {
  className = classNames({
    'min-w-[14rem] h-16 flex justify-center items-center py-5 px-10 gap-2 text-2xl font-semibold border-none rounded-xl duration-200':
      true,
    'text-white bg-blue hover:bg-hover-blue': type === 'info' && !disabled,
    'text-white text-opacity-50 bg-disabled-blue cursor-not-allowed': type === 'info' && disabled,
    'text-blue bg-pale-blue hover:bg-pale-blue-hover': type === 'clear' && !disabled,
    'text-blue text-opacity-50 bg-pale-blue cursor-not-allowed': type === 'clear' && disabled,
    'text-white bg-red-button hover:bg-red-button-hover': type === 'error' && !disabled,
    'text-white text-opacity-50 bg-red-button-disabled cursor-not-allowed': type === 'error' && disabled,
    [className ? className : '']: true,
  })

  return (
    <button className={className} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  )
}
