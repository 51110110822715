import { FC, useEffect, useState } from 'react'
import { Form } from '../../types/definitions/form'
import { Period } from '../../types/definitions/Period'
import { Review } from '../../types/definitions/review'
import ChartRadar, { ChartDatasetType } from '../atoms/Charts/ChartRadar'
import EmployeeResultsChartHeader from '../molecules/EmployeeResults/EmployeeResultsChartHeader'

interface EmployeeResultsChartProps {
  radarDataSet: Record<string, ChartDatasetType>
  radarLabels: string[]
  currentReview: Review
  reviewToCompare?: Review
  selectableReviews: Review[]
  allForms: Form[]
  allPeriods: Period[]
  onReviewToggle: (reviewDate: string) => void
  onComparableChange?: (review: Review) => void
}

const EmployeeResultsChart: FC<EmployeeResultsChartProps> = ({
  radarDataSet,
  radarLabels,
  currentReview,
  reviewToCompare,
  selectableReviews,
  allForms,
  allPeriods,
  onReviewToggle,
  onComparableChange,
}) => {
  const [selectedReviews, setSelectedReviews] = useState<string[]>([currentReview.finishDate || ''])

  useEffect(() => {
    setSelectedReviews([currentReview.finishDate || ''])
  }, [currentReview])

  const handleChange = (reviewDate: string) => {
    if (selectedReviews.includes(reviewDate)) {
      const selectedReviewsFiltered = selectedReviews.filter(review => review !== reviewDate)
      setSelectedReviews(selectedReviewsFiltered)
      onReviewToggle(reviewDate)
      return
    }
    setSelectedReviews([...selectedReviews, reviewDate])
    onReviewToggle(reviewDate)
  }

  const valuesRadarDataSet = Object.keys(radarDataSet).map(reviewDate => ({
    ...radarDataSet[reviewDate],
  }))

  const differentForms = valuesRadarDataSet.some(review => review.formName !== valuesRadarDataSet[0].formName)

  const handleOnComparableChange = (comparableReview: Review): void => {
    onComparableChange?.(comparableReview)
  }

  return valuesRadarDataSet.length ? (
    <div className="employee-results__body-chart print:m-0 print:!w-auto print:!h-auto print:!max-w-none print:flex print:flex-col print:justify-center">
      <EmployeeResultsChartHeader
        reviews={valuesRadarDataSet}
        onChange={handleChange}
        currentReview={currentReview}
        reviewToCompare={reviewToCompare}
        selectableReviews={selectableReviews}
        allForms={allForms}
        allPeriods={allPeriods}
        onComparableChange={v => handleOnComparableChange(v)}
      />
      <ChartRadar labels={radarLabels} datasets={valuesRadarDataSet} />
      {differentForms && (
        <p className="text-2xl text-stone-gray mt-16 print:hidden">
          📍{' '}
          <span className="italic">
            When comparing different assessment types, the results may not be completely accurate, particularly with
            Professional Skills.
          </span>
        </p>
      )}
    </div>
  ) : null
}

export default EmployeeResultsChart
