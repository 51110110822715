import { Column } from 'primereact/column'
import { DataTable, DataTableRowClickEvent } from 'primereact/datatable'
import { useHistory } from 'react-router-dom'
import { ALERT_TYPE_MAPPING, EMPLOYEE_STATUS_TODO } from '../../constants/employee'
import { Employee } from '../../services/EmployeeService'
import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import { EmployeeTableBody } from '../molecules/EmployeeTable/EmployeeTableBody'
import { EmployeeTableHeader } from '../molecules/EmployeeTable/EmployeeTableHeader'
import { EmployeeTableOptionsBody } from '../molecules/EmployeeTable/EmployeeTableOptionsBody'

interface HighlightsTableProps {
  employees?: Employee[]
  selectedSort?: { property: string; order: SortOrderEnum }
  alertFilter: string
  handleOnEmployeeClicked: (employee: Employee) => string
  onSortSelected?: (field: string, newSortOrder: SortOrderEnum) => void
}

export const HighlightsTable: React.FC<HighlightsTableProps> = ({
  employees,
  selectedSort,
  alertFilter,
  handleOnEmployeeClicked,
  onSortSelected,
}) => {
  const history = useHistory()

  const handleEmployeeSelected = (employee: Employee): string => {
    return handleOnEmployeeClicked(employee)
  }

  const handleSortSelected = (field: string) => (newSortOrder: SortOrderEnum) => {
    onSortSelected?.(field, newSortOrder)
  }

  const onRowClick = (e: DataTableRowClickEvent): void => {
    history.push(handleEmployeeSelected(e.data as Employee))
  }

  const filterEmployeeAlerts = (employees?: Employee[]): Employee[] => {
    const filteredEmployees = employees?.map(employee => {
      const alertTypeToFilter =
        Object.keys(ALERT_TYPE_MAPPING).find(
          key => ALERT_TYPE_MAPPING[key as keyof typeof ALERT_TYPE_MAPPING] === alertFilter
        ) ?? alertFilter
      const filteredAlerts = employee.alerts?.filter(alert => alert.type === alertTypeToFilter)
      return { ...employee, alerts: filteredAlerts }
    })
    return filteredEmployees as Employee[]
  }

  return (
    <DataTable
      className="user-list-container pb-10"
      tableClassName="user-list-table table-fixed"
      paginator
      rows={5}
      rowsPerPageOptions={[5, 10, 30, 50, 500]}
      alwaysShowPaginator={false}
      rowClassName={(employee: Employee) => ({
        'review-list-table-row': true,
        'user-list-table-row --todo': employee.status === EMPLOYEE_STATUS_TODO,
        'cursor-pointer': true,
      })}
      onRowClick={onRowClick}
      value={filterEmployeeAlerts(employees)}
      dataKey="id"
    >
      <Column
        headerClassName="user-list-table-header max-w-[24rem]"
        className="max-w-[24rem]"
        field="name"
        header={EmployeeTableHeader({
          title: 'Name',
          sortable: selectedSort?.property === 'name' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('name'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        headerClassName="user-list-table-header max-w-[24rem]"
        className="max-w-[24rem]"
        field="jobTitle"
        header={EmployeeTableHeader({
          title: 'Job Title',
          sortable: selectedSort?.property === 'jobTitle' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('jobTitle'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        headerClassName="user-list-table-header max-w-[24rem]"
        className="max-w-[24rem]"
        field="reviewer"
        header={EmployeeTableHeader({
          title: 'Leader',
          sortable: selectedSort?.property === 'leader' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('leader'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        headerClassName="user-list-table-header max-w-[24rem]"
        className="max-w-[24rem]"
        field="teams"
        header={EmployeeTableHeader({
          title: 'Teams',
          sortable: selectedSort?.property === 'teams' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('teams'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        headerClassName="user-list-table-header max-w-[24rem]"
        className="max-w-[24rem]"
        field="alertDate"
        header={EmployeeTableHeader({
          title: 'Date',
          sortable: selectedSort?.property === 'alerts' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('alerts'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        rowEditor
        headerStyle={{ minWidth: '13rem' }}
        bodyStyle={{ textAlign: 'center', position: 'relative' }}
        body={EmployeeTableOptionsBody({
          handleEmployeeSelected,
        })}
      />
    </DataTable>
  )
}
