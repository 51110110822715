import AppFilterButton from '../atoms/AppFilterButton/AppFilterButton'

export class Filter {
  key: string
  label: string | JSX.Element
  constructor(key: string, label: string | JSX.Element) {
    this.key = key
    this.label = label
  }
}
interface TabFilterParams {
  filters: Filter[]
  enabledFilter?: Filter
  onActiveFilterChange: (f: Filter) => void
}
export const TabFilter = ({ filters, onActiveFilterChange, enabledFilter }: TabFilterParams): JSX.Element => {
  return (
    <>
      <div className="flex flex-1 flex-wrap gap-4">
        {filters.map((f, i) => {
          return (
            <AppFilterButton
              key={`filter-button-${i}`}
              active={enabledFilter && enabledFilter.key === f.key}
              onClick={() => {
                onActiveFilterChange(f)
              }}
            >
              {f.label}
            </AppFilterButton>
          )
        })}
      </div>
    </>
  )
}
