const GarbageSVG = ({ color = '#005ECA', width = 16, height = 18 } = {}): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7179 3.13333L12.9248 15.6933C12.9178 16.0377 12.7743 16.3662 12.5245 16.6097C12.2747 16.8533 11.9379 16.9932 11.5846 17H4.63761C4.28436 16.9932 3.94754 16.8533 3.69771 16.6097C3.44788 16.3662 3.30443 16.0377 3.29744 15.6933L2.50427 3.13333M1 3.13333H15.2222H1ZM5.37607 3.13333V2.04C5.37607 1.76417 5.48845 1.49965 5.68849 1.30461C5.88853 1.10957 6.15984 1 6.44274 1H9.77949C9.91956 1 10.0583 1.0269 10.1877 1.07917C10.3171 1.13143 10.4347 1.20804 10.5337 1.30461C10.6328 1.40118 10.7114 1.51583 10.765 1.64201C10.8186 1.76819 10.8462 1.90343 10.8462 2.04V3.13333H5.37607Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M10.8509 5.2666L10.3039 14.8666M8.1159 5.2666V14.8666V5.2666ZM5.38086 5.2666L5.92787 14.8666L5.38086 5.2666Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  )
}

export default GarbageSVG
