import moment from 'moment'
import AppFilterButton from '../../../atoms/AppFilterButton/AppFilterButton'
import { Period } from '../../../../types/definitions/Period'
import { AvailablePeriods, timeDifferenceAsString } from '../../../../utils/periods/PeriodUtils'

interface GeneralStatusPeriodOverviewProps {
  currentPeriod?: Period
  previousPeriod?: Period
  selectedPeriod: AvailablePeriods
  onChange: (selectedPeriod: AvailablePeriods) => void
}

const GeneralStatusPeriodOverview: React.FC<GeneralStatusPeriodOverviewProps> = ({
  currentPeriod,
  previousPeriod,
  selectedPeriod = AvailablePeriods.Current,
  onChange,
}) => {
  const selectPeriod = (selectedPeriod: AvailablePeriods) => {
    onChange(selectedPeriod)
  }

  const previousPeriodLabel = previousPeriod
    ? `${moment(previousPeriod.startDate).format('MMM')}-${moment(previousPeriod.endDate).format('MMM')}`
    : ''

  const currentPeriodLabel = currentPeriod
    ? `${moment(currentPeriod.startDate).format('MMM')}-${moment(currentPeriod.endDate).format('MMM')}`
    : ''

  const getTimeRemainingLabel = () => {
    if (!currentPeriod || !currentPeriod.endDate) {
      return ''
    }
    return `${timeDifferenceAsString(moment(), moment(currentPeriod.endDate))} left`
  }

  return (
    <div className="flex items-end justify-between mb-8 h-[3rem]">
      <div className="flex flex-1 flex-wrap gap-4 my-4">
        <AppFilterButton
          active={selectedPeriod === AvailablePeriods.Current}
          onClick={() => selectPeriod(AvailablePeriods.Current)}
        >
          Current Period {currentPeriodLabel}
        </AppFilterButton>
        <AppFilterButton
          disabled={!previousPeriod}
          active={selectedPeriod === AvailablePeriods.Previous}
          onClick={() => selectPeriod(AvailablePeriods.Previous)}
        >
          Previous Period {previousPeriodLabel}
        </AppFilterButton>
      </div>
      {selectedPeriod === AvailablePeriods.Current ? (
        <div className="flex items-end pb-4 gap-4">
          <span className="text-2xl font-bold">{moment().format('MM/DD/YYYY')}</span>
          <span className="text-4xl font-medium text-blue -mb-[2px]">{getTimeRemainingLabel()}</span>
        </div>
      ) : (
        <div className="flex items-end pb-4 gap-2">
          <span className="text-4xl font-medium text-toast-hover">Period finished</span>
        </div>
      )}
    </div>
  )
}

export default GeneralStatusPeriodOverview
