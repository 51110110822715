import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../..'
import { EMPLOYEE_HIGHLIGHT_FLIGHT_RISK } from '../../constants/employee'
import { useQuery } from '../../hooks/useQuery'
import { Employee, EmployeeDetails, EmployeeService } from '../../services/EmployeeService'
import { PeriodService } from '../../services/PeriodService'
import { Team, TeamDetails, TeamService } from '../../services/TeamService'
import { Period } from '../../types/definitions/Period'
import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import { createPathToEmployeeResults } from '../../utils/employeePathUtils'
import { AvailablePeriods, sortPeriods } from '../../utils/periods/PeriodUtils'
import { GeneralStatusSortingType, sortByTypeGeneralStatus } from '../../utils/sortUtils'
import { BreadcrumbItem } from '../atoms/Breadcrumb/Breadcrumb'
import Spinner from '../atoms/Spinner'
import DashboardHeader from '../molecules/Dashboard/DashboardHeader'
import HighlightsSection from '../molecules/Dashboard/HighlightsSection'
import GeneralStatusContent from '../organisms/GeneralStatus/GeneralStatusContent'
import TeamChart from '../organisms/TeamChart'
import { SectionType } from './ReviewsPage'

const initialSortingTeams: GeneralStatusSortingType = {
  property: 'name',
  order: SortOrderEnum.Ascending,
}

const Dashboard = (): JSX.Element => {
  const query = useQuery()
  const history = useHistory()
  const [teamAssessments, setTeamAssessments] = useState<TeamDetails[]>([])
  const [sortedTeams, setSortedTeamAssessments] = useState<TeamDetails[]>([])
  const [filteredTeamAssessments, setFilteredTeamAssessments] = useState<TeamDetails[]>([])
  const [selectedSort, setSelectedSort] = useState<GeneralStatusSortingType>(initialSortingTeams)
  const [loading, setLoading] = useState(false)
  const [loadingPeriods, setLoadingPeriods] = useState(true)
  const [currentPeriod, setCurrentPeriod] = useState<Period>()
  const [previousPeriod, setPreviousPeriod] = useState<Period>()
  const [selectedPeriod, setSelectedPeriod] = useState<AvailablePeriods>(AvailablePeriods.Current)
  const [employees, setEmployees] = useState<Employee[]>([])
  const [prevPeriodEmployees, setPrevPeriodEmployees] = useState<Employee[]>([])
  const [activeSection, setActiveSection] = useState<SectionType>(query.get('view') === 'diagram' ? 'diagram' : 'table')
  const [teamsHierarchy, setAssessmentTeamHierarchy] = useState<number[]>([])
  const [breadcrumbData, setBreadcrumbData] = useState<BreadcrumbItem[]>([])
  const [rootTeams, setRootTeams] = useState<number[]>([])

  const currentUser = useSelector<RootState, EmployeeDetails>(state => state.auth.user as EmployeeDetails)

  useEffect(() => {
    const initializeView = async () => {
      setLoading(true)
      const teams = await TeamService.findRootTeamsId()
      setRootTeams(teams)
      setAssessmentTeamHierarchy(teams)
      setLoadingPeriods(true)
      getPeriods()
      if (currentUser.role === 'admin') getEmployees()
    }

    initializeView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentPeriod && !loadingPeriods) getGeneralStatusData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPeriod, previousPeriod, loadingPeriods, selectedPeriod])

  useEffect(() => {
    if (previousPeriod) {
      EmployeeService.getDashboardInformation(previousPeriod.id).then(prevEmployees =>
        setPrevPeriodEmployees(prevEmployees)
      )
    }
  }, [previousPeriod])

  useEffect(() => {
    if (selectedSort) {
      const GENERAL_STATUS_DATA = sortByTypeGeneralStatus(teamAssessments, employees, selectedSort)
      const wantedProperty = selectedSort.property as keyof typeof GENERAL_STATUS_DATA
      const newData = GENERAL_STATUS_DATA[wantedProperty] || GENERAL_STATUS_DATA['default']
      setSortedTeamAssessments(newData as TeamDetails[])
    }
  }, [selectedSort, teamAssessments, employees])

  useEffect(() => {
    const _filteredTeams = sortedTeams.filter(team => {
      const belongToHierarchyChildren = teamsHierarchy.every(teamId => {
        const currTeam = sortedTeams.find(team => team.id === teamId)
        return currTeam?.children?.some(child => child.id === team.id)
      })
      return belongToHierarchyChildren
    })
    setFilteredTeamAssessments(_filteredTeams)
    if (breadcrumbData.length === 0) setBreadcrumbData(() => [{ id: rootTeams[0], label: `All Teams` }])
  }, [sortedTeams, teamsHierarchy, currentUser, breadcrumbData.length, rootTeams])

  const getEmployees = async () => {
    try {
      const employees = await EmployeeService.findAllWithAccess()
      setEmployees(employees)
    } catch (error) {
      console.error(error)
    }
  }

  const handleBreadCrumbClick = (id: number) => {
    setAssessmentTeamHierarchy([id])
    const idx = breadcrumbData.map(bc => bc.id ?? 0).indexOf(id)
    setBreadcrumbData(arr => arr.slice(0, idx + 1))
  }

  const getTeamList = (teams: TeamDetails[], currentList: TeamDetails[] = []) => {
    teams.forEach(team => {
      currentList.push(team)
      if (team.children) getTeamList(team.children, currentList)
    })
    return currentList
  }

  const getGeneralStatusData = async () => {
    try {
      setLoading(true)

      const currentTeamsData = await TeamService.findAllDetailed()

      const currentTeamsList = getTeamList(currentTeamsData)

      if (currentTeamsList) {
        const teamAssessmentsData = currentTeamsList
        setTeamAssessments(teamAssessmentsData)
        setSortedTeamAssessments(teamAssessmentsData)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const getPeriods = () => {
    PeriodService.findAll().then(allPeriods => {
      const sortedPeriods = sortPeriods(allPeriods)
      const currentDate = new Date()
      const activeIndex = sortedPeriods.findIndex(
        period => period.startDate <= currentDate && period.endDate > currentDate
      )
      if (activeIndex !== -1) {
        setCurrentPeriod(sortedPeriods.at(activeIndex))
        if (activeIndex < sortedPeriods.length - 1) setPreviousPeriod(sortedPeriods.at(activeIndex + 1))
        setLoadingPeriods(false)
      } // TODO: what is there is no active period?
    })
  }

  const handleSelectedPeriod = (selectedPeriod: AvailablePeriods) => {
    setSelectedPeriod(selectedPeriod)
  }

  const navigateToSection = (section: SectionType): void => {
    if (!section) return

    setActiveSection(section)
    query.set('view', section)
    history.replace({
      search: query.toString(),
    })
  }

  const handleOnEmployeeClicked = (employee: Employee) => createPathToEmployeeResults(employee.id)

  const handleHierarchyNavigation = (team: Team) => {
    if (team.id && team.children.length) {
      setAssessmentTeamHierarchy([team.id])
      setBreadcrumbData(arr => [...arr, { id: team.id, label: team.name }])
    }
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="container mx-auto">
      {currentUser.role === 'admin' && (
        <div className="mb-24">
          <DashboardHeader activeSection={activeSection} onSectionChange={section => navigateToSection(section)} />
          {activeSection === 'table' ? (
            <HighlightsSection
              employees={employees}
              defaultFilter={{ property: 'highlights', value: EMPLOYEE_HIGHLIGHT_FLIGHT_RISK }}
              handleOnEmployeeClicked={handleOnEmployeeClicked}
            />
          ) : null}
        </div>
      )}
      {activeSection === 'table' ? (
        <GeneralStatusContent
          teams={filteredTeamAssessments}
          employees={employees}
          previousEmployees={prevPeriodEmployees}
          selectedSort={selectedSort}
          setSelectedSort={(property, order) => {
            setSelectedSort({ property, order })
          }}
          handleHierarchyNavigation={handleHierarchyNavigation}
          breadcrumbData={breadcrumbData}
          handleBreadCrumbClick={handleBreadCrumbClick}
          currentUser={currentUser}
          currentPeriod={currentPeriod}
          previousPeriod={previousPeriod}
          selectedPeriod={selectedPeriod}
          handleSelectedPeriod={handleSelectedPeriod}
        />
      ) : null}
      {activeSection === 'diagram' && <TeamChart employees={employees} currentUser={currentUser} />}
    </div>
  )
}

export default Dashboard
