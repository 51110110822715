import React from 'react'
import image1 from '../assets/images/report-tutorial/report-tutorial-1.png'
import image2 from '../assets/images/report-tutorial/report-tutorial-2.png'
import image3 from '../assets/images/report-tutorial/report-tutorial-3.png'

interface ReportTutorialContentI {
  title: string
  body: React.ReactNode
  image: string
}

export const ReportTutorialContent: ReportTutorialContentI[] = [
  {
    title: 'Review assessments',
    body: `Select an assessment from the top dropdown menu. Use the right-hand side toggles to compare against previous assessments and understand the evolution.`,
    image: image1,
  },
  {
    title: 'Technical profile',
    body: `The technical profile shows your team member's technical skillset and their learning/teaching preferences.`,
    image: image2,
  },
  {
    title: `GitHub activity`,
    body: `GitHub metrics at the bottom add objective context about day-to-day activities. This information comes from your team member's company GitHub profile.`,
    image: image3,
  },
]
