import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { authSlice } from './slices/auth-slice'
import { helpSlice } from './slices/help-slice'
import { introductionSlice } from './slices/introduction-slice'
import { imageIntroductionSlice } from './slices/image-introduction-slice'
import { reportTutorialSlice } from './slices/report-tutorial-slice'

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    help: helpSlice.reducer,
    introduction: introductionSlice.reducer,
    imageIntroduction: imageIntroductionSlice.reducer,
    reportTutorial: reportTutorialSlice.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const appDispatch = store.dispatch
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
