import React from 'react'
import image1 from '../assets/images/introduction-images-tutorial/introduction-images-tutorial-1.png'
import image2 from '../assets/images/introduction-images-tutorial/introduction-images-tutorial-2.png'
import image3 from '../assets/images/introduction-images-tutorial/introduction-images-tutorial-3.png'
import image4 from '../assets/images/introduction-images-tutorial/introduction-images-tutorial-4.png'
import image5 from '../assets/images/introduction-images-tutorial/introduction-images-tutorial-5.png'
import image6 from '../assets/images/introduction-images-tutorial/introduction-images-tutorial-6.png'

interface IntroductionImagesContentI {
  title: string
  body: React.ReactNode
  image: string
}

export const IntroductionImagesContent: IntroductionImagesContentI[] = [
  {
    title: 'Individual reports',
    body: `In the Reviews page you will find your team member profiles where you can view reports and create new assessments.`,
    image: image1,
  },
  {
    title: 'Add more reviewers',
    body: `The (＋) button lets you invite other teammates to assess specific people in your team, and obtain new perspectives.`,
    image: image2,
  },
  {
    title: 'Important alerts',
    body: `Profiles will show alert icons ( ✈ ⚠️ 🎸) to flag important issues.`,
    image: image3,
  },
  {
    title: 'Teams overview',
    body: `Review all your teams together, spot issues, and access specific team reports.`,
    image: image4,
  },
  {
    title: 'Find resources',
    body: `Find other resources within the organization, according to your specific skill needs.`,
    image: image5,
  },
  {
    title: 'New assessment',
    body: `Click on the Start button to begin assessing your team members.`,
    image: image6,
  },
]
