import { classNames } from 'primereact/utils'
import { FC, useState } from 'react'
import CheckSVG from '../../../atoms/Icons/Check.svg'

export interface TrueFalseQuestionProps {
  title: string
  label: string
  value: boolean
  required?: boolean
  unfinished?: boolean
  onChange?: (value: boolean) => void
}

export const TrueFalseQuestion: FC<TrueFalseQuestionProps> = ({
  title,
  label,
  value,
  unfinished,
  required = true,
  onChange,
}) => {
  const [currentValue, setCurrentValue] = useState(value)

  const onClickHandler = (value: boolean) => {
    setCurrentValue(!value)
    onChange?.(!value)
  }

  return (
    <div className="flex px-12 py-16 w-full min-h-[13rem] gap-4 rounded-[0.8rem] bg-pale-cyan">
      <div className="flex flex-col w-full gap-8">
        <div className="flex">
          {title && <span className="font-semibold text-[1.6rem]">{title}</span>}
          {!required && <p className="font-semibold text-stone-gray ml-auto">Optional</p>}
        </div>
        <div className="flex flex-col h-5 text-2xl select-none gap-6">
          <div className="check-grp" onClick={() => onClickHandler(value)}>
            <div
              className={classNames({
                check: true,
                'check--checked': currentValue,
                'check--unfinished': !currentValue && unfinished,
              })}
            >
              <CheckSVG color="#FFFFFF" />
            </div>
            {label && <label className="text-dark-blue">{label}</label>}
          </div>
        </div>
      </div>
    </div>
  )
}
