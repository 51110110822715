import { v4 as uuidv4 } from 'uuid'
// FIXME: We don't need to use inputSwitch anymore when we migrate to next.
// Tailwind allows to easily create a toggle button wich will be more "change color" friendly
import { InputSwitch } from 'primereact/inputswitch'
import { useLayoutEffect, useState } from 'react'

interface ToggleWithTextProps {
  color: string
  checked?: boolean
  onChange: (checked: boolean, text: string) => void
  text: Record<string, string>
  disabled?: boolean
  periodAverage?: boolean
}

const ToggleWithText = (props: ToggleWithTextProps): JSX.Element => {
  // I'm using id and not useRef because InputSwitch is an HTML wrapper
  // and I can't reach the HTML otherwise
  const [id] = useState(`toggle-${uuidv4()}`)

  useLayoutEffect(() => {
    const style = document.createElement('style')

    style.innerHTML = `
      #${id}.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
        background-color: ${props.color};
      }
    `

    document.getElementById(id)?.append(style)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnChange = (): void => {
    const newCheckedStatus = !props.checked
    props.onChange(newCheckedStatus, props.text.text)
  }

  return (
    <div className="toggle-with-text flex gap-3">
      <div className="min-w-8 flex items-center h-full">
        <InputSwitch
          id={id}
          checked={props.checked ?? false}
          onChange={handleOnChange}
          className="toggle-btn"
          disabled={props.disabled}
        />
      </div>
      <div className="flex flex-col">
        <span className="font-medium text-xl leading-6 min-h-6">{props.text.text}</span>
        {props.periodAverage ?? <span className="font-medium text-xl leading-6">{props.text.form}</span>}
      </div>
    </div>
  )
}

export default ToggleWithText
