import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { TeamService, TeamStatus } from '../../services/TeamService'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'
import AppFilterButton from '../atoms/AppFilterButton/AppFilterButton'
import Spinner from '../atoms/Spinner'
import TextLine from '../atoms/TextLine'
import { TeamIssuesTable } from '../organisms/TeamIssuesTable'
import Breadcrumb, { BreadcrumbItem } from '../atoms/Breadcrumb/Breadcrumb'
import { SortingType, sortData, TeamSortType } from '../../utils/sortUtils'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../services/EventTrackingService'
import { TeamStatusDTO } from '../../api/openapi'
import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import { PeriodCounter } from '../molecules/PeriodCounter'
import { Period } from '../../types/definitions/Period'
import { PeriodService } from '../../services/PeriodService'
import { Filter } from './ReviewsPage'

const initialSorting: SortingType = {
  property: 'issues',
  order: SortOrderEnum.Ascending,
}

const TeamIssuesPage = (): JSX.Element => {
  const [data, setData] = useState<TeamStatus[]>([])
  const [hasChangedDataTable, setHasChangedDataTable] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [breadcrumbData, setBreadcrumbData] = useState<BreadcrumbItem[]>([])
  const [textSearch, setTextSearch] = useState<string>('')
  const [filteredAndSortedData, setFilteredAndSortedData] = useState<TeamStatus[]>([])
  const [selectedSort, setSelectedSort] = useState<SortingType>(initialSorting)
  const [amountIssues, setAmountIssues] = useState<number>(0)
  const [currentPeriod, setCurrentPeriod] = useState<Period>()
  const history = useHistory()
  const [enabledFilter, setEnabledFilter] = useState<Filter>()

  useEffect(() => {
    trackEvent(TrackingCategoryEnum.TEAM_ISSUES, TrackingCategoryEventEnum.TEAM_ISSUES.PAGE_ON_LOAD, '')
    _loadPeriod()
    TeamService.findTeamStatus().then((teamStatus: Set<TeamStatus>) => {
      const newData = Array.from(teamStatus.values())
      setData(newData)
      setFilteredAndSortedData(newData)
      const amount = newData.filter(filterWithIssues).length
      setAmountIssues(amount)
      setBreadcrumbData([{ label: `Teams (${newData.length} results)` }])
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (selectedSort && filteredAndSortedData.length) {
      const newData = sortData(filteredAndSortedData, selectedSort, initialSorting)
      setFilteredAndSortedData(newData as TeamStatus[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChangedDataTable, data, selectedSort, filteredAndSortedData.length])

  useEffect(() => {
    if (textSearch.length > 0) {
      const filteredData = data.filter(filterByText)
      const newData = sortData(filteredData, selectedSort, initialSorting)
      setFilteredAndSortedData(newData as TeamStatus[])
      setBreadcrumbData([{ label: `Search (${filteredData.length} results)` }])
      setEnabledFilter({ property: 'text', value: textSearch })
    } else {
      if (enabledFilter?.property === 'text') {
        setEnabledFilter({ property: 'all', value: 'all' })
        setBreadcrumbData([{ label: `Teams (${data.length} results)` }])
        const newData = sortData(data, selectedSort, initialSorting)
        setFilteredAndSortedData(newData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textSearch, data, hasChangedDataTable])

  const _loadPeriod = () => {
    PeriodService.findActive().then(period => {
      setCurrentPeriod(period)
    })
  }

  const filterByText = (teamStatus: TeamStatus) => {
    const leaders = Array.from(teamStatus.leaders as Set<string>)
    const hasSomeLeaderWithTextSearch = leaders.some(lead => lead.toLowerCase().includes(textSearch.toLowerCase()))
    const hasSomeTeamWithTextSearch = teamStatus.name?.toLowerCase().includes(textSearch.toLowerCase())
    return hasSomeTeamWithTextSearch || hasSomeLeaderWithTextSearch
  }

  const filterWithIssues = (teamStatus: TeamStatus) => {
    return (teamStatus.mismatches || 0) > 0 || (teamStatus.flightRisks || 0) > 0
  }

  const handleAllSearch = (): void => {
    trackEvent(
      TrackingCategoryEnum.TEAM_ISSUES,
      TrackingCategoryEventEnum.TEAM_ISSUES.CLICK_NAVIGATION_TABS,
      JSON.stringify({ selectedTab: 'all' })
    )
    setEnabledFilter({ property: 'all', value: 'all' })
    setBreadcrumbData([{ label: `Teams (${data.length} results)` }])
    setTextSearch('')
    selectedSort
      ? setFilteredAndSortedData(TeamService.sortTeamStatus(data, [selectedSort]))
      : setFilteredAndSortedData(data)
  }

  const handleIssuesSearch = (): void => {
    trackEvent(
      TrackingCategoryEnum.TEAM_ISSUES,
      TrackingCategoryEventEnum.TEAM_ISSUES.CLICK_NAVIGATION_TABS,
      JSON.stringify({ selectedTab: 'issues' })
    )
    setEnabledFilter({ property: 'issues', value: 'issues' })
    setTextSearch('')
    const filteredData = data.filter(filterWithIssues)
    setBreadcrumbData([{ label: `Issues (${filteredData.length} results)` }])
    selectedSort
      ? setFilteredAndSortedData(TeamService.sortTeamStatus(filteredData, [selectedSort]))
      : setFilteredAndSortedData(filteredData)
  }

  const handleUpdateTeamNameInData = (newTeamStatus: TeamStatus) => {
    const newFilteredData: TeamStatus[] = filteredAndSortedData.map(team => {
      if (team.id === newTeamStatus.id) {
        return { ...team, name: newTeamStatus.name }
      }
      return team
    })
    setFilteredAndSortedData([...newFilteredData])
    setHasChangedDataTable(!hasChangedDataTable)
  }

  function handleClickOnRow(rowData: TeamStatusDTO) {
    const summaryLink = createPathWithLang(
      NetworkConstants.URL_TEAM_SUMMARY.replace(':teamId', rowData?.id?.toString() || '')
    )
    if (rowData.finished !== undefined && rowData.finished > 0) {
      history.push(summaryLink)
    }
  }

  if (loading) return <Spinner />

  return (
    <div className="px-4 flex-grow flex flex-col">
      <div className="container mt-10 flex flex-wrap">
        <div className="flex flex-col gap-2">
          <h1 id="title" className=" text-6xl text-dark-blue font-semibold">
            Teams
          </h1>
        </div>
        <div className="flex flex-col items-center align-middle mx-auto gap-4">
          <TextLine
            className="text-border border-blue chooseInput"
            type="text"
            value={textSearch}
            searchIcon={true}
            onChange={value => setTextSearch(value)}
            onClear={() => setTextSearch('')}
            onSubmit={value => setTextSearch(value)}
            placeholder={'Quick find any team or leader'}
          />
        </div>
        <PeriodCounter currentPeriod={currentPeriod} />
      </div>
      <div className="container mt-10 flex flex-wrap">
        <div className="flex flex-1 flex-wrap gap-4">
          <AppFilterButton active={!enabledFilter || enabledFilter.property === 'all'} onClick={handleAllSearch}>
            All
          </AppFilterButton>
          <AppFilterButton active={enabledFilter?.property === 'issues'} onClick={handleIssuesSearch}>
            Issues ({amountIssues})
          </AppFilterButton>
        </div>
      </div>
      <div className="container my-10 flex flex-wrap">
        <div className="mt-6 mb-4 flex flex-col ">
          <Breadcrumb items={breadcrumbData} />
        </div>
        <TeamIssuesTable
          selectedSort={selectedSort}
          data={filteredAndSortedData}
          handleOnRowClicked={(rowData: TeamStatus) => handleClickOnRow(rowData)}
          onSortSelected={(property: TeamSortType, order) => setSelectedSort({ property, order })}
          onUpdateTeamName={handleUpdateTeamNameInData}
        />
      </div>
    </div>
  )
}

export default TeamIssuesPage
