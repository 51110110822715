import { Employee } from '../../../services/EmployeeService'
import { Review } from '../../../types/definitions/review'
import { AnsweredQuestion } from '../../../types/definitions/question'
import { useEffect, useState } from 'react'
import { AppMarkdownViewer } from '../../atoms/AppMarkdownViewer/AppMarkdownViewer'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../../services/EventTrackingService'

type EmployeeHeaderProps = {
  employee?: Employee
  review?: Review
}

type AnswersByTopic = Map<string, AnsweredQuestion[]>

const getAnswersByTopic = (answers: AnsweredQuestion[]): AnswersByTopic => {
  answers
    .sort((a, b) => {
      return (a?.topic?.order || 0) - (b?.topic?.order || 0)
    })
    .sort((a, b) => {
      return (a?.order || 0) - (b?.order || 0)
    })
  const grouped: AnswersByTopic = answers.reduce((curr, value) => {
    const topicName = value?.topic?.name || ''
    const topic = curr.get(topicName) || []
    if (value.reportLayout?.type === null || value.reportLayout?.type === 'CHART') topic.push(value)
    curr.set(topicName, topic)
    return curr
  }, new Map<string, AnsweredQuestion[]>())
  return grouped
}

type ReviewDetailsTableProps = {
  answersByTopic: AnswersByTopic
}

type ReviewDetailsTableRowProps = {
  topic: string
  answers: AnsweredQuestion[]
}

const ReviewDetailsTableRow = ({ topic, answers }: ReviewDetailsTableRowProps): JSX.Element => {
  if (!answers.length) return <></>

  const questionSample = answers.find(answer => answer.type === 'SCALE')
  const valueRangeText =
    questionSample && questionSample.min && questionSample.max ? ` (${questionSample.min}-${questionSample.max})` : ''

  return (
    <div className="border-solid border-t border-dark-blue pb-20 print:pb-2 print:break-before-page">
      <div className="flex justify-between flex-row text-3xl pr-3 mt-12 mb-4">
        <p>
          <span className="mr-2 capitalize">{topic}</span>
          <span className="text-blue">{answers.find(answer => answer.type === 'NUMERIC')?.answer}</span>
        </p>
        <p className="w-40 text-center font-bold mr-3">
          Value{valueRangeText && <span className="font-normal text-gray-3"> {valueRangeText}</span>}
        </p>
      </div>
      {answers
        .filter(answer => answer.type !== 'NUMERIC')
        .map((item, index) => {
          const rowClassName = item.label ? 'bg-light-blue text-white' : 'bg-pale-cyan text-dark-blue'
          const classes = `flex justify-between items-center rounded-xl min-h-[4.8rem] border-white border-solid border-2 overflow-hidden p-4 ${rowClassName} flex-row text-2xl`
          return (
            <div className={classes} key={item.id}>
              <div className="flex flex-row gap-3">
                <AppMarkdownViewer markdown={item.label || item.title} />
              </div>
              <p className="min-w-[3rem] w-40 text-center">
                {item.reportLayout?.type === 'LIST' ? (item.answer as string[]).join(', ') : item.answer}
              </p>
            </div>
          )
        })}
    </div>
  )
}

const ReviewDetailsTable = ({ answersByTopic }: ReviewDetailsTableProps): JSX.Element => {
  const topics = [...answersByTopic.keys()]
  return (
    <div>
      {topics.map(item => (
        <ReviewDetailsTableRow key={item} topic={item} answers={answersByTopic.get(item) || []}></ReviewDetailsTableRow>
      ))}
    </div>
  )
}

const EmployeeResultsDetails = ({ employee, review }: EmployeeHeaderProps): JSX.Element => {
  const [answersByTopic, setAnswersByTopic] = useState<AnswersByTopic>()
  const [isOpen = false, setOpen] = useState<boolean>()
  useEffect(() => {
    setAnswersByTopic(getAnswersByTopic(review?.answers || []))
  }, [review])

  const hideDetailsClass = isOpen ? 'h-auto' : 'h-0'
  const arrowDirectionClass = isOpen ? 'pi-chevron-up' : 'pi-chevron-down'
  return (
    <div className={`employee-results-details print:hidden`}>
      <div
        className="cursor-pointer"
        onClick={() => {
          trackEvent(
            TrackingCategoryEnum.INDIVIUAL_REPORT,
            TrackingCategoryEventEnum.INDIVIUAL_REPORT.CLICK_DETAILS,
            JSON.stringify({ reviewId: review?.id })
          )
          setOpen(!isOpen)
        }}
      >
        <div className="flex print:hidden">
          <span className="flex items-center gap-2 text-2xl text-blue font-bold clickable-hover-effect clickable-hover-effect--soft-grey">
            <p>{`${!isOpen ? 'Show' : 'Hide'} details`}</p>
            <p className={`pi ${arrowDirectionClass} ml-3 font-bold text-light-blue`} />
          </span>
        </div>
      </div>
      <div
        style={{ height: isOpen ? 'auto' : '0px' }}
        className={`mt-7 pt-7 transition-200 mb-0 ${hideDetailsClass} overflow-hidden print:mt-0 print:p-0 print:break-before-page print:break-inside-avoid`}
      >
        <ReviewDetailsTable answersByTopic={answersByTopic || new Map()} />
      </div>
    </div>
  )
}

export default EmployeeResultsDetails
