const LogoSVG = ({ color = '#00112f', width = '111', height = '32' } = {}): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 111 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.7009 10.8966C40.1034 12.049 39.8047 13.372 39.8047 14.8871C39.8047 16.4022 40.0821 17.7253 40.6796 18.8776C41.2771 20.0299 42.1093 20.9262 43.1763 21.545C44.2433 22.1852 45.5023 22.484 46.9107 22.484C47.8923 22.484 48.7459 22.3132 49.4928 21.9291C50.2183 21.5664 50.8158 21.0969 51.264 20.4994L51.6054 22.3559H53.8247V14.0549H47.1454V16.5943H50.8585V16.7863C50.7091 17.7679 50.2823 18.5148 49.5781 19.027C48.8526 19.5391 48.0417 19.7738 47.1241 19.7738C45.8437 19.7738 44.8621 19.3257 44.1366 18.4295C43.411 17.5332 43.0696 16.3595 43.0696 14.8871C43.0696 13.9482 43.219 13.0946 43.5604 12.3477C43.8805 11.6222 44.35 11.046 44.9688 10.6192C45.5663 10.2138 46.2919 10.0004 47.1241 10.0004C48.0204 10.0004 48.7672 10.2565 49.3647 10.7473C49.9623 11.2381 50.3464 11.8996 50.5598 12.7318H53.718C53.59 11.6649 53.2272 10.7259 52.651 9.89369C52.0749 9.08279 51.328 8.44261 50.389 7.97314C49.4501 7.52502 48.3831 7.29028 47.2095 7.29028C45.6944 7.29028 44.3713 7.61037 43.2617 8.22922C42.152 8.8694 41.2984 9.74432 40.7009 10.8966Z"
        fill="#00112F"
      />
      <path
        d="M62.8304 11.3875H62.3183C61.5074 11.3875 60.8459 11.5368 60.3764 11.8142C59.8856 12.0917 59.4801 12.4971 59.16 13.0306L58.8826 11.3875H56.1512V22.3559H59.16V16.9784C59.16 16.1035 59.3521 15.3993 59.7575 14.8871C60.1417 14.375 60.7818 14.1189 61.6354 14.1189H62.8304V11.3875Z"
        fill="#00112F"
      />
      <path
        d="M74.6595 19.8165H74.318C74.062 19.8165 73.8912 19.7738 73.7845 19.6671C73.6565 19.5818 73.6138 19.3897 73.6138 19.1123V15.2499C73.6138 13.9695 73.187 12.9666 72.3335 12.2837C71.4799 11.6008 70.2849 11.2594 68.7484 11.2594C67.2547 11.2594 66.081 11.5795 65.1848 12.1984C64.2885 12.8172 63.7764 13.6921 63.691 14.8018H66.5932C66.6572 14.3963 66.8706 14.0762 67.2333 13.8201C67.5748 13.5854 68.0442 13.4574 68.5991 13.4574C69.2179 13.4574 69.7087 13.6068 70.0928 13.9055C70.4556 14.2043 70.6477 14.5884 70.6477 15.0792V15.6127H68.471C66.8279 15.6127 65.5689 15.9327 64.7153 16.5729C63.8617 17.2131 63.4349 18.1094 63.4349 19.2617C63.4349 20.286 63.7977 21.0969 64.5446 21.6517C65.2915 22.2065 66.2731 22.484 67.5108 22.484C68.3217 22.484 69.0259 22.3346 69.6234 21.9931C70.1995 21.6731 70.6903 21.2036 71.0958 20.5634C71.0531 21.7584 71.736 22.3559 73.123 22.3559H74.6595V19.8165ZM70.6477 17.6186V17.832C70.6263 18.6002 70.3916 19.1977 69.9435 19.6458C69.474 20.0939 68.8551 20.3073 68.0869 20.3073C67.5748 20.3073 67.1907 20.2006 66.8919 19.9659C66.5932 19.7525 66.4651 19.4324 66.4651 19.0483C66.4651 18.6002 66.6358 18.2587 66.9773 18.0027C67.3187 17.7466 67.8095 17.6186 68.4497 17.6186H70.6477Z"
        fill="#00112F"
      />
      <path
        d="M84.7647 11.9636C83.9965 11.4941 83.1429 11.2594 82.1613 11.2594C81.3931 11.2594 80.7315 11.4088 80.1554 11.7075C79.5792 12.0063 79.1097 12.4117 78.747 12.9026L78.4482 11.3875H75.8235V26.6238H78.8323V20.9689C79.6219 21.9931 80.7315 22.484 82.1399 22.484C83.1215 22.484 83.9965 22.2706 84.7647 21.8011C85.5329 21.3316 86.1304 20.6914 86.5572 19.8379C86.984 19.0056 87.2187 18.024 87.2187 16.893C87.2187 15.7834 86.984 14.7804 86.5572 13.9268C86.1304 13.0733 85.5329 12.4331 84.7647 11.9636ZM83.4203 19.155C82.9081 19.7525 82.268 20.0299 81.4571 20.0299C80.6462 20.0299 79.9846 19.7525 79.5152 19.155C79.0244 18.5788 78.7896 17.8106 78.7896 16.8503C78.7896 15.9114 79.0244 15.1645 79.5152 14.5884C79.9846 14.0122 80.6462 13.7134 81.4571 13.7134C82.268 13.7134 82.9081 14.0122 83.4203 14.5884C83.9111 15.1645 84.1672 15.9114 84.1672 16.8503C84.1672 17.7893 83.9111 18.5575 83.4203 19.155Z"
        fill="#00112F"
      />
      <path
        d="M89.1458 13.9268C88.6763 14.7804 88.4629 15.762 88.4629 16.893C88.4629 18.024 88.6976 19.0056 89.1671 19.8592C89.6366 20.7128 90.3194 21.353 91.2157 21.8011C92.1119 22.2706 93.1576 22.484 94.3953 22.484C95.3342 22.484 96.1664 22.3346 96.892 21.9931C97.6175 21.6517 98.1937 21.2036 98.6418 20.6061C99.0686 20.0299 99.346 19.3684 99.474 18.6429H96.5079C96.3798 19.155 96.1024 19.5605 95.697 19.8165C95.2915 20.0726 94.7794 20.2006 94.1819 20.2006C93.4136 20.2006 92.7948 19.9872 92.368 19.5178C91.9199 19.0696 91.6638 18.4508 91.5998 17.6399V17.5545H99.5594C99.6021 17.2558 99.6448 16.9144 99.6448 16.5303C99.6234 15.4633 99.3673 14.5457 98.8979 13.7561C98.4284 12.9666 97.7669 12.3477 96.9133 11.9209C96.0597 11.4941 95.0995 11.2594 93.9898 11.2594C92.8802 11.2594 91.9199 11.4941 91.0877 11.9636C90.2554 12.4331 89.6152 13.0733 89.1458 13.9268ZM96.6359 15.5913H91.6638C91.7492 14.9511 92.0052 14.4603 92.4534 14.0762C92.9015 13.6921 93.4563 13.5001 94.1178 13.5001C94.8007 13.5001 95.3555 13.6921 95.8037 14.0549C96.2518 14.4177 96.5292 14.9298 96.6359 15.5913Z"
        fill="#00112F"
      />
      <path
        d="M103.66 18.7069H100.8C100.843 19.8805 101.355 20.7981 102.315 21.481C103.254 22.1639 104.492 22.484 106.028 22.484C107.01 22.484 107.864 22.3559 108.61 22.0572C109.357 21.7584 109.934 21.353 110.36 20.7981C110.787 20.2647 111 19.6458 111 18.9203C111 17.8106 110.595 17.0211 109.805 16.5516C109.016 16.0821 107.885 15.762 106.413 15.5913C105.708 15.5273 105.218 15.4633 104.919 15.3993C104.62 15.3566 104.364 15.2499 104.193 15.1219C104.001 14.9938 103.916 14.8018 103.916 14.5457C103.916 14.2043 104.087 13.9055 104.428 13.6921C104.769 13.4787 105.239 13.372 105.815 13.372C106.413 13.372 106.925 13.5214 107.309 13.7775C107.693 14.0335 107.928 14.375 107.97 14.7804H110.83C110.744 13.6708 110.254 12.8172 109.336 12.177C108.418 11.5582 107.223 11.2381 105.73 11.2167C104.791 11.2167 103.98 11.3661 103.276 11.6649C102.55 11.9636 101.995 12.3904 101.611 12.9452C101.227 13.5001 101.035 14.1189 101.035 14.8231C101.035 15.5486 101.227 16.1035 101.611 16.5303C101.995 16.957 102.507 17.2771 103.148 17.4692C103.788 17.6826 104.577 17.832 105.559 17.96C106.263 18.0454 106.775 18.1307 107.095 18.1947C107.394 18.2587 107.65 18.3654 107.821 18.4935C107.992 18.6429 108.077 18.8562 108.077 19.1337C108.077 19.5178 107.885 19.8165 107.522 20.0299C107.138 20.2433 106.647 20.3287 106.007 20.3287C105.324 20.3287 104.791 20.1793 104.364 19.8805C103.937 19.5818 103.702 19.1977 103.66 18.7069Z"
        fill="#00112F"
      />
      <circle cx="4.57273" cy="15.7502" r="4.57273" fill={color} />
      <circle cx="10.1626" cy="26.9282" r="4.57273" fill={color} />
      <circle cx="10.1626" cy="4.57273" r="4.57273" fill={color} />
      <circle cx="15.7485" cy="15.7502" r="4.57273" fill={color} />
      <circle cx="21.3384" cy="26.9282" r="4.57273" fill={color} />
      <circle cx="21.3384" cy="4.57273" r="4.57273" fill={color} />
      <circle cx="26.9282" cy="15.7502" r="4.57273" fill={color} />
    </svg>
  )
}

export default LogoSVG
