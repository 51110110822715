import { FC, useEffect, useState } from 'react'

import { AppSelect, AppSelectOptionProps } from '../../../atoms/AppSelect/AppSelect'
import SuccessModal from '../../SuccessModal'
import TalentRankMessage from './TalentRankMessage'
import RetentionAtRiskMessage from './RetentionAtRiskMessage'

export interface DropdownQuestionProps {
  title: string
  value: string
  placeholder: string
  description?: string
  options: { label: string; value: string }[]
  unfinished?: boolean
  required?: boolean
  onChange?: (value: string) => void
  previousAnswer?: string
  suggested?: boolean
  averageScore?: number
}

export const DropdownQuestion: FC<DropdownQuestionProps> = ({
  title,
  value,
  options,
  placeholder,
  description,
  onChange,
  unfinished,
  required = true,
  previousAnswer,
  suggested = false,
  averageScore,
}) => {
  const [selectValue, setSelectValue] = useState(value)
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false)

  useEffect(() => {
    setSelectValue(value)
  }, [value])

  const handleSelectedOption = (selectedOption: string): void => {
    onChange?.(selectedOption)
    setSelectValue(selectedOption)
  }

  const questionOptionsSortFn = (): void => {
    return
  }

  const moreInformationMessage = (): JSX.Element | undefined => {
    // TODO: Correct this hardcoded string
    switch (title) {
      case 'Talent Rank':
        return (
          <div className="flex justify-between -mb-2">
            <span className="app-select-label">{title}</span>
            <div className="flex items-center">
              <button
                onClick={() => setShowInfoModal(true)}
                className="p-2 rounded-md text-blue font-extrabold mx-2 underline decoration-1"
              >
                More information
              </button>
            </div>
            {!required && <p className="optional-question ml-auto">Optional</p>}
          </div>
        )
      case 'Retention at Risk':
        return (
          <div className="flex justify-between -mb-2">
            <span className="app-select-label">{title}</span>
            <div className="flex items-center">
              <button
                onClick={() => setShowInfoModal(true)}
                className="p-2 rounded-md text-blue font-extrabold mx-2 underline decoration-1"
              >
                More information
              </button>
            </div>
            {!required && <p className="optional-question ml-auto">Optional</p>}
          </div>
        )
      default:
        return undefined
    }
  }

  const messageInSuccessModal = (): JSX.Element | undefined => {
    switch (title) {
      case 'Talent Rank':
        return <TalentRankMessage />
      case 'Retention at Risk':
        return <RetentionAtRiskMessage />
      default:
        return undefined
    }
  }

  const processOptions = (options: { label: string; value: string }[]): AppSelectOptionProps[] => {
    const newOptions: AppSelectOptionProps[] = []
    options.forEach(option => {
      const _extraLabels: { label: string; color?: string }[] = []
      // only show the previous answer extra label when the previous answer coincides
      // with this option AND when there is no answer yet. Once there is an answer, do not show
      // the previous label
      const isPreviousAnswer = previousAnswer === option.value && value === ''
      const isSuggestedOption =
        suggested &&
        averageScore !== undefined &&
        ((averageScore >= 90 && option.label === 'Rockstar 🎸') ||
          (averageScore <= 30 && option.label === 'Needs attention ⚠️'))
      if (isPreviousAnswer) _extraLabels.push({ label: 'Previous' })
      if (isSuggestedOption) _extraLabels.push({ label: 'Suggested', color: 'text-[#F2994A]' })
      newOptions.push({
        label: option.label,
        value: option.value,
        extraLabels: _extraLabels,
      })
    })
    return newOptions
  }

  return (
    <div className="dropdown-question">
      <AppSelect
        value={selectValue}
        label={title}
        placeholder={placeholder}
        options={processOptions(options)}
        unfinished={unfinished}
        required={required}
        customSortFn={questionOptionsSortFn}
        onChange={handleSelectedOption}
        className="text-dark-blue h-28"
        labelBody={moreInformationMessage()}
      />
      <SuccessModal
        visible={showInfoModal}
        icon={false}
        onClose={() => {
          setShowInfoModal(false)
        }}
        message={messageInSuccessModal()}
        buttonText="Close"
      />
    </div>
  )
}
