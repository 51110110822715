import { Chart, ChartData, ChartDataset, ChartOptions, registerables } from 'chart.js'
import { cloneDeep, isEmpty, isEqual, sortBy, xorWith } from 'lodash'
import { createRef, useEffect, useState } from 'react'

import customLabelPlugin from '../../../utils/chartPlugins/customLabelPlugin'
import { chartDefaultOptions } from '../../../utils/chartUtils'

export interface ChartDatasetType {
  formName?: string
  reviewerName: string
  reviewDate: string
  displayed: boolean
  values: number[]
  color: string
  periodAverage?: boolean
}

interface ChartRadarPropsType {
  datasets: ChartDatasetType[]
  labels: string[]
  minZoom?: number
  maxZoom?: number
  stepSize?: number
}

const ChartRadar = (props: ChartRadarPropsType): JSX.Element => {
  const { datasets, labels, minZoom = 0, maxZoom = 100, stepSize = 25 } = props
  const [chart, setChart] = useState<Chart<'radar'>>()
  const ref = createRef<HTMLCanvasElement>()
  const [imgUrl, setImgUrl] = useState('')
  const [previousProps, setPreviousProps] = useState<ChartRadarPropsType>()

  const displayedDatasetsFrom = (datasets: ChartDatasetType[]): ChartDatasetType[] => {
    return datasets.filter(review => review.displayed === true)
  }

  const formatDatasets = (datasets: ChartDatasetType[]): ChartDatasetType[] => {
    const result = displayedDatasetsFrom(datasets)
    return result
  }

  const getDatasets = (): ChartDataset<'radar'>[] => {
    const comparable = datasets.length === 2 && datasets[0].reviewerName === datasets[1].reviewerName
    return formatDatasets(datasets).map((d, i) => {
      if (d.values.length > 0) {
        return {
          label: i.toString(),
          data: d.values,
          fill: false,
          borderWidth: '2',
          borderColor: d.color,
          compare: comparable,
        } as unknown as ChartDataset<'radar'>
      }

      throw new Error('There is no data to show in the chart')
    })
  }

  const getData = (): ChartData<'radar'> => {
    return {
      labels: labels,
      datasets: getDatasets(),
    }
  }

  const getOptions = (): ChartOptions<'radar'> => {
    const options = JSON.parse(JSON.stringify(chartDefaultOptions))
    return options
  }

  const initializeChart = (): void => {
    if (!ref.current) return
    Chart.register(...registerables)

    chart?.destroy()
    const newChart = new Chart(ref.current, {
      type: 'radar',
      data: getData(),
      options: {
        ...getOptions(),
        animation: {
          onComplete: () => {
            setImgUrl(newChart.toBase64Image())
          },
        },
      },
      plugins: [customLabelPlugin],
    })
    setChart(newChart)
  }

  const updateChart = (): void => {
    if (!chart?.options?.scales?.['r']?.ticks) return
    chart.options.scales.r.min = minZoom
    chart.options.scales.r.max = maxZoom
    chart.options.scales.r.ticks.stepSize = stepSize
    chart.data = getData()
    chart.update()
  }

  // This method may remind you of React.memo. And the truth is that I've also
  // tried recreating the same behaviour using It but with no success. For some reason
  // the previous props you get by using the CHECK_FUNCTION from React.memo(component, CHECK_FUNCTION)
  // will always be the same as the next props.
  const canUpdateChart = (): boolean => {
    if (!previousProps) return false
    const sameLabels = isEqual(sortBy(previousProps.labels), sortBy(props.labels))
    const sameDatasets = isEmpty(xorWith(previousProps.datasets, props.datasets, isEqual))
    const sameMinZoom = isEqual(previousProps.minZoom, props.minZoom)
    const sameMaxZoom = isEqual(previousProps.maxZoom, props.maxZoom)
    const sameStepSize = isEqual(previousProps.stepSize, props.stepSize)
    return !(sameDatasets && sameLabels && sameMinZoom && sameMaxZoom && sameStepSize)
  }

  useEffect(() => {
    initializeChart()
    setPreviousProps(cloneDeep(props))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!canUpdateChart()) return
    updateChart()
    setPreviousProps(cloneDeep(props))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasets])

  return (
    <>
      <div className="chart-radar-container max-h-[42rem] print:hidden">
        <canvas ref={ref} className="chart-radar" />
      </div>
      <div className="hidden print:flex print:w-full print:h-fit justify-center">
        <img src={imgUrl} alt="Chart" className="object-contain print:h-[400px] w-auto" />
      </div>
    </>
  )
}

export default ChartRadar
