import React from 'react'
import { AppButton } from '../../atoms/AppButton/AppButton'
import { DiagramIcon } from '../../atoms/Icons/DiagramIcon'
import { TableIcon } from '../../atoms/Icons/TableIcon'

interface DashboardHeaderProps {
  activeSection: 'diagram' | 'table'
  onSectionChange: (section: 'diagram' | 'table') => void
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ activeSection, onSectionChange }) => (
  <div className="flex items-end justify-between mb-24">
    <h1 className="text-6xl font-semibold">Dashboard</h1>
    {activeSection === 'diagram' ? (
      <AppButton type="clear" onClick={() => onSectionChange('table')}>
        <TableIcon />
        <span>Table</span>
      </AppButton>
    ) : (
      <AppButton type="clear" onClick={() => onSectionChange('diagram')}>
        <DiagramIcon />
        <span>Org chart</span>
      </AppButton>
    )}
  </div>
)

export default DashboardHeader
