import moment, { Moment } from 'moment'
import { employeeAPI, suggestionAPI, usageAPI } from '../api'
import {
  CreateEmployeeRequest,
  EmployeeInfoResponse,
  EmployeeMetaSearchRequest,
  EmployeeMetaSearchResponse,
  EmployeeNotLoggedInResponse,
  EmployeeReviewerResponse,
  EmployeeSummaryResponse,
  EmployeeWithTeamsAndReviewsResponse,
  MapScoreResponse,
  ReviewSimpleProjection,
  SuggestionBoxMessageRequest,
  TeamInfoResponse,
  UpdateEmployeeRequest,
} from '../api/openapi'
import {
  EMPLOYEE_HIGHLIGHT_BURNOUT,
  EMPLOYEE_HIGHLIGHT_FLIGHT_RISK,
  EMPLOYEE_HIGHLIGHT_MISMATCH_LITERAL,
  EMPLOYEE_HIGHLIGHT_ROCKSTAR,
  EMPLOYEE_STATUS_DONE,
  EMPLOYEE_STATUS_SKIPPED,
  EMPLOYEE_STATUS_TODO,
  EMPLOYEE_STATUS_UNFINISHED,
} from '../constants/employee'
import { Period } from '../types/definitions/Period'
import { Team } from '../types/definitions/team'
import { Roles } from '../utils/routes'
import { SortBy } from '../utils/sortUtils'
import { simpleTextCompareFn } from '../utils/text'
import { cloneDeep } from '../utils/utils'
import { PeriodService } from './PeriodService'
import { EmployeeOverview, TeamDetails } from './TeamService'

// TODO: Mask the values received from EmployeeSummaryResponse and EmployeeWithTeamsAndReviewsResponse
export interface Employee extends EmployeeSummaryResponse {
  role: Roles
  status?: string
  weight?: number
  highlights?: string[]
  leadersAsString?: string
  leaders?: EmployeeInfoResponse[]
  mapScores?: MapScoreResponse[]
}

export type EmployeeKeys = keyof Employee
export type EmployeeValues = Employee[EmployeeKeys]
export type ReviewSimple = ReviewSimpleProjection
export interface EmployeeReviewer extends EmployeeReviewerResponse {
  status: string
}
export interface EmployeeDetails extends EmployeeWithTeamsAndReviewsResponse {
  role: Roles
}

export interface EmployeeScores extends EmployeeOverview {
  isGlobalScore: boolean
  score: number
  skipped: boolean
}

export type EmployeeSort = SortBy

export const getHighlightCount = (employees: Employee[], highlight: string): number => {
  if (employees?.length) {
    return employees.filter(employee => employee.highlights?.includes(highlight)).length
  }
  return 0
}

export const getEmployeesFromTeam = (team: TeamDetails, employees: Employee[]): Employee[] => {
  const teamEmployees = employees.filter(employee =>
    team.employees.some(teamEmployee => teamEmployee.id === employee.id)
  )
  return teamEmployees
}

const teamsCompareFn = (teams1: Team[], teams2: Team[]) => {
  const teamNames1: string = (teams1 || [])
    .map(({ name }) => name)
    .join('')
    .toLowerCase()
  const teamNames2: string = (teams2 || [])
    .map(({ name }) => name)
    .join('')
    .toLowerCase()
  return simpleTextCompareFn(teamNames1, teamNames2)
}

const statusOrder: Record<string, number> = { Completed: 0, Requested: 1, Skipped: 2, Started: 3, 'Not Started': 4 }
const statusCompareFn = (status1: string, status2: string) => {
  return (statusOrder[status1 || ''] || -1) - (statusOrder[status2 || ''] || -1)
}

const teamLeaderCompareFn = (teams1: TeamInfoResponse[] = [], teams2: TeamInfoResponse[] = []) => {
  const teamLeader1: string = teams1
    .map(team => team.leaders.map(leader => leader.name))
    .join('')
    .toLowerCase()
  const teamLeader2: string = teams2
    .map(team => team.leaders.map(leader => leader.name))
    .join('')
    .toLowerCase()
  return simpleTextCompareFn(teamLeader1, teamLeader2)
}

const leadersCompareFn = (leaders1: string[] = [], leaders2: string[] = []) => {
  const leader1: string = leaders1.join(', ')
  const leader2: string = leaders2.join(', ')
  return simpleTextCompareFn(leader1, leader2)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EmployeeSortFn: Partial<Record<string, (item1: any, item2: any) => number>> = {
  id: (emp1, emp2) => simpleTextCompareFn(emp1.id, emp2.id),
  email: (emp1, emp2) => simpleTextCompareFn(emp1.email, emp2.email),
  name: (emp1, emp2) => simpleTextCompareFn(emp1.name, emp2.name),
  jobTitle: (emp1, emp2) => simpleTextCompareFn(emp1.jobTitle, emp2.jobTitle),
  lastReviewType: (emp1, emp2) => simpleTextCompareFn(emp2.lastReviewType || '', emp1.lastReviewType || ''),
  createdDate: (emp1, emp2) => simpleTextCompareFn(emp1.createdDate, emp2.createdDate),
  salaryGrade: (emp1, emp2) => simpleTextCompareFn(emp1.salaryGrade, emp2.salaryGrade),
  teams: (emp1, emp2) => teamsCompareFn(emp1.teams, emp2.teams),
  status: (emp1, emp2) => statusCompareFn(emp1.status, emp2.status),
  leader: (emp1, emp2) => teamLeaderCompareFn(emp1.teams, emp2.teams),
  leaders: (emp1, emp2) => leadersCompareFn(emp1.leaders, emp2.leaders),
  role: (emp1, emp2) => simpleTextCompareFn(emp1.role, emp2.role),
}

export const checkReviewer = (employee: Employee, currentUser?: Employee): boolean => {
  const leaderInclude = (employee.teams || []).filter(team =>
    team.leaders?.some(leader => leader.id === currentUser?.id)
  ).length
  const reviewersInclude = (employee.reviewers || []).filter(
    reviewer => reviewer.reviewer.id === currentUser?.id
  ).length
  return leaderInclude > 0 || reviewersInclude > 0
}

// DELETE THIS FUNCTION WHEN THE BACKEND WILL BE UPDATED
export const filterLastReviewByPeriod = (
  employee: EmployeeSummaryResponse,
  currentPeriod: Period
): EmployeeSummaryResponse => {
  const { lastOwnReview, lastReview } = employee

  const startPeriodDate = new Date(currentPeriod?.startDate || 0)
  const lastOwnReviewDate = new Date(lastOwnReview?.createdDate || 0)
  const lastReviewDate = new Date(lastReview?.createdDate || 0)

  if (startPeriodDate > lastOwnReviewDate) employee.lastOwnReview = undefined
  if (startPeriodDate > lastReviewDate) employee.lastReview = undefined
  employee.skippedReviews = employee.skippedReviews?.filter(r => new Date(r.createdDate || 0) > startPeriodDate)

  return employee
}

const getEmployeeStatus = (employee: Employee, currentUser?: Employee) => {
  const { lastOwnReview, lastReview } = employee

  if (checkReviewer(employee, currentUser) && !lastOwnReview) return EMPLOYEE_STATUS_TODO

  if (lastOwnReview && !lastOwnReview?.finishDate) return EMPLOYEE_STATUS_UNFINISHED

  if (lastOwnReview?.finishDate || (checkReviewer(employee, currentUser) && lastReview?.finishDate))
    return EMPLOYEE_STATUS_DONE

  if (employee.skippedReviews?.length) return EMPLOYEE_STATUS_SKIPPED

  return EMPLOYEE_STATUS_TODO
}

export const getEmployeeHighlights = (employee: Employee): string[] => {
  const highlights: string[] = []

  employee.alerts?.forEach(alert => {
    if (alert.type === 'rockstar') highlights.push(EMPLOYEE_HIGHLIGHT_ROCKSTAR)
    if (alert.type === 'mismatch') highlights.push(EMPLOYEE_HIGHLIGHT_MISMATCH_LITERAL)
    if (alert.type === 'flightRisk') highlights.push(EMPLOYEE_HIGHLIGHT_FLIGHT_RISK)
    if (alert.type === 'burnout') highlights.push(EMPLOYEE_HIGHLIGHT_BURNOUT)
  })
  return highlights
}

const mapEmployeeResponseRecord = (employee: EmployeeSummaryResponse, currentPeriod: Period) => {
  const updatedEmployee = filterLastReviewByPeriod(employee, currentPeriod)
  const status = getEmployeeStatus(updatedEmployee as Employee)
  let weight

  switch (status) {
    case EMPLOYEE_STATUS_TODO:
      weight = 0
      break
    case EMPLOYEE_STATUS_UNFINISHED:
      weight = 1
      break
    case EMPLOYEE_STATUS_DONE:
    default:
      weight = 2
      break
  }

  return {
    ...employee,
    status,
    weight,
    highlights: getEmployeeHighlights(employee as Employee),
    leadersAsString: getEmployeeLeadersAsString(employee as Employee),
  }
}

const getEmployeeLeadersAsString = (employee: Employee): string => {
  const teams = (employee.teams || []).flatMap(({ leaders, name }) => {
    const leaderNames = leaders?.map(leader => leader?.name).filter(Boolean) || []
    if (!leaderNames.length) {
      console.warn('No team leader assigned in team', name)
    }
    return leaderNames
  })

  return teams.join(', ')
}

const sortByAlertDate = (employee1: Employee, employee2: Employee): number => {
  const alert1 = employee1.alerts?.[0]
  const alert2 = employee2.alerts?.[0]
  const date1 = alert1 ? new Date(alert1.lastUpdate || '') : null
  const date2 = alert2 ? new Date(alert2.lastUpdate || '') : null
  return date1 && date2 ? date1.getTime() - date2.getTime() : 0
}

export class EmployeeService {
  static async findAllWithAccess(depth?: number, activePeriod?: Period): Promise<Employee[]> {
    const currentPeriod = activePeriod || (await PeriodService.findActive())
    return employeeAPI.findUsingGET1(depth).then(response => {
      return response.data.map(x => mapEmployeeResponseRecord(x, currentPeriod)) as Employee[]
    })
  }

  static async findAll(): Promise<Employee[]> {
    return employeeAPI.findAllUsingGET().then(response => {
      return response.data as Employee[]
    })
  }

  static async findAllAsAdmin(
    page?: number,
    size?: number,
    sortDirection?: string,
    sortField?: string,
    activePeriod?: Period
  ): Promise<Employee[]> {
    const currentPeriod = activePeriod || (await PeriodService.findActive())
    return employeeAPI.findAsAdminUsingGET(page, size, sortDirection, sortField).then(response => {
      return response.data.content?.map(x => mapEmployeeResponseRecord(x, currentPeriod)) as Employee[]
    })
  }

  static async findDirectUnder(employeeId: number, currentUser?: Employee, activePeriod?: Period): Promise<Employee[]> {
    const currentPeriod = activePeriod || (await PeriodService.findActive())
    return employeeAPI.findUsingGET2(employeeId, 0).then(response => {
      return response.data.map(employee => {
        const updatedEmployee = filterLastReviewByPeriod(employee, currentPeriod)
        const status = getEmployeeStatus(updatedEmployee as Employee)
        let weight

        switch (status) {
          case EMPLOYEE_STATUS_TODO:
            weight = 0
            break
          case EMPLOYEE_STATUS_UNFINISHED:
            weight = 1
            break
          case EMPLOYEE_STATUS_DONE:
          default:
            weight = 2
            break
        }

        return {
          ...employee,
          status,
          weight,
          highlights: getEmployeeHighlights(employee as Employee),
        }
      }) as Employee[]
    })
  }

  static async findOne(employeeId: number): Promise<EmployeeDetails> {
    return employeeAPI.getByIdUsingGET(employeeId).then(response => {
      return {
        ...(response.data as EmployeeDetails),
        highlights: getEmployeeHighlights(response.data as Employee),
      }
    })
  }

  static async getDashboardInformation(periodId?: number): Promise<Employee[]> {
    const period = periodId ? await PeriodService.findById(periodId) : await PeriodService.findActive()
    return employeeAPI
      .getDashboardInformationUsingGET(period.id)
      .then(({ data }) => data.map(employee => mapEmployeeResponseRecord(employee, period) as Employee))
  }

  static async update(employeeId: number, employee: UpdateEmployeeRequest): Promise<void> {
    return employeeAPI.setEmployeeTeamsUsingPATCH(employeeId, employee).then()
  }

  static async create(employee: CreateEmployeeRequest): Promise<EmployeeWithTeamsAndReviewsResponse> {
    return new Promise((resolve, reject) => {
      employeeAPI
        .createUsingPOST1(employee)
        .then(r => {
          resolve(r.data)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  static async delete(employeeId: number): Promise<void> {
    return employeeAPI.deleteUsingDELETE(employeeId).then()
  }

  static sortEmployees<T>(employees: T[], sorting: EmployeeSort[]): T[] {
    const result = cloneDeep<T>(employees)
    return result.sort((employee1, employee2) => {
      for (const sort of sorting) {
        if (sort.property === 'alerts') {
          const alertSortResult = sortByAlertDate(employee1 as unknown as Employee, employee2 as unknown as Employee)
          if (alertSortResult !== 0) {
            return sort.order === 'asc' ? alertSortResult : alertSortResult * -1
          }
        } else {
          const sortFn = EmployeeSortFn[sort.property]
          const sortResult = sortFn?.(employee1, employee2) || 0
          if (sortResult !== 0) {
            return sort.order === 'asc' ? sortResult : sortResult * -1
          }
        }
      }
      // if there is no sort param, return a default value
      return 0
    }) as T[]
  }

  static async getSimpleReviewsForEmployeeId(id: number): Promise<ReviewSimple[]> {
    return new Promise((resolve, reject) => {
      employeeAPI
        .getReviewsSimpleByEmployeeUsingGET(id)
        .then(r => {
          resolve([...r.data] as ReviewSimple[])
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  static getEmployeeActivity = async (employeeId: number, after?: Moment) => {
    const afterAsString = after?.format('YYYY-MM-DDT00:00:00')
    const r = await employeeAPI.getActivityUsingGET(moment().format('YYYY-MM-DDT23:59:59'), employeeId, afterAsString)
    return r.data
  }

  static getEmployeeActivityCalendar = async (employeeId: number) => {
    const r = await employeeAPI.getActivityCalendarUsingGET(employeeId)
    return r.data
  }

  static getEmployeeReviewerStatus = (reviewer: EmployeeReviewer): string => {
    if (reviewer.lastReview) {
      return reviewer.lastReview.finishDate ? EMPLOYEE_STATUS_DONE : EMPLOYEE_STATUS_UNFINISHED
    }
    return EMPLOYEE_STATUS_TODO
  }

  static setReviewersStatus(reviewers: EmployeeReviewer[]) {
    reviewers.map(reviewer => (reviewer.status = EmployeeService.getEmployeeReviewerStatus(reviewer)))
  }

  static async getJobTitles(): Promise<string[]> {
    return employeeAPI.findJobTitleUsingGET().then(response => {
      return response.data as unknown as string[]
    })
  }

  static async getFilteredEmployees(
    metaSearchRequest: EmployeeMetaSearchRequest
  ): Promise<EmployeeMetaSearchResponse[]> {
    return employeeAPI.metaSearchUsingPOST(metaSearchRequest).then(response => {
      return response.data as EmployeeMetaSearchResponse[]
    })
  }

  static async createSuggestion(messageRequest: SuggestionBoxMessageRequest) {
    return suggestionAPI.createUsingPOST1(messageRequest)
  }

  static async getNotLoggedLeaders(
    afterDate?: string,
    beforeDate?: string,
    depth?: number
  ): Promise<EmployeeNotLoggedInResponse[]> {
    return usageAPI.findLeadersNotLoggedInUsingGET(afterDate, beforeDate, depth).then(response => {
      return response.data as EmployeeNotLoggedInResponse[]
    })
  }
}
