import { AppButton } from '../../atoms/AppButton/AppButton'
import DownloadIcon from '../../atoms/Icons/DownloadIcon'

interface DownloadButtonProps {
  downloadFunction: () => void
  disabled: boolean
}

export const DownloadPDFButton = ({ downloadFunction, disabled }: DownloadButtonProps) => {
  return (
    <AppButton type="clear" onClick={downloadFunction} className="flex items-center" disabled={disabled}>
      <DownloadIcon />
      <span className="ml-2">Download full report</span>
    </AppButton>
  )
}
