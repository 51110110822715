interface Props {
  width?: string
  height?: string
}

const MailIcon = ({ width = '14', height = '11' }: Props): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.89648 12.1484H13.3418C14.0117 12.1484 14.5335 11.957 14.9072 11.5742C15.2809 11.196 15.4678 10.6423 15.4678 9.91309V2.7627C15.4678 2.03809 15.2741 1.48438 14.8867 1.10156C14.5039 0.71875 13.9502 0.527344 13.2256 0.527344H2.77344C2.10352 0.527344 1.58171 0.71875 1.20801 1.10156C0.83431 1.48438 0.647461 2.03809 0.647461 2.7627V9.91309C0.647461 10.6423 0.838867 11.196 1.22168 11.5742C1.60905 11.957 2.16732 12.1484 2.89648 12.1484ZM2.94434 10.665C2.67546 10.665 2.4681 10.5967 2.32227 10.46C2.18099 10.3232 2.11035 10.109 2.11035 9.81738V2.8584C2.11035 2.57129 2.18099 2.35938 2.32227 2.22266C2.4681 2.08594 2.67546 2.01758 2.94434 2.01758H13.1709C13.4398 2.01758 13.6449 2.08594 13.7861 2.22266C13.932 2.35938 14.0049 2.57129 14.0049 2.8584V9.82422C14.0049 10.1113 13.932 10.3232 13.7861 10.46C13.6449 10.5967 13.4398 10.665 13.1709 10.665H2.94434ZM8.06445 8.14258C8.32422 8.14258 8.57487 8.08789 8.81641 7.97852C9.05794 7.86914 9.29948 7.69596 9.54102 7.45898L14.6748 2.40039L13.7656 1.48438L8.74805 6.44043C8.61589 6.56803 8.49512 6.66146 8.38574 6.7207C8.28092 6.77539 8.17383 6.80273 8.06445 6.80273C7.95508 6.80273 7.8457 6.77539 7.73633 6.7207C7.63151 6.66146 7.51302 6.56803 7.38086 6.44043L2.34961 1.46387L1.43359 2.38672L6.58789 7.45898C6.82943 7.69596 7.07096 7.86914 7.3125 7.97852C7.55404 8.08789 7.80469 8.14258 8.06445 8.14258ZM13.6562 11.0068L14.5723 10.0908L10.5186 6.05762L9.5957 6.97363L13.6562 11.0068ZM1.5498 10.0908L2.46582 11.0068L6.5332 6.97363L5.61035 6.05762L1.5498 10.0908Z"
        fill="#005ECA"
      />
    </svg>
  )
}

export default MailIcon
