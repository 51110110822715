import { PeriodApi } from '../api'
import { PeriodRequest, PeriodResponse } from '../api/openapi'
import { Period } from '../types/definitions/Period'
import { dateStringToDate } from '../utils/periods/PeriodUtils'

export class PeriodService {
  static findAll(): Promise<Period[]> {
    return PeriodApi.findAllUsingGET1().then(response => {
      return (response.data || []).map(periodResponse => this.periodResponseToPeriod(periodResponse))
    })
  }

  static findById(id: number): Promise<Period> {
    return PeriodApi.findUsingGET4(id).then(response => {
      return this.periodResponseToPeriod(response.data)
    })
  }

  static findActive(): Promise<Period> {
    return PeriodApi.findActiveUsingGET().then(response => {
      return this.periodResponseToPeriod(response.data)
    })
  }

  static async deletePeriod(id: number): Promise<void> {
    await PeriodApi.deleteUsingDELETE(id)
  }

  static createPeriod(periodRequest: PeriodRequest): Promise<number> {
    return PeriodApi.createUsingPOST(periodRequest).then(response => {
      return response.data.id
    })
  }

  static async updatePeriod(id: number, periodRequest: PeriodRequest): Promise<void> {
    await PeriodApi.updateUsingPUT(id, periodRequest)
  }

  static periodResponseToPeriod(periodResponse: PeriodResponse): Period {
    return {
      id: periodResponse.id,
      name: periodResponse.name,
      description: periodResponse.description,
      startDate: dateStringToDate(periodResponse.startDate),
      endDate: dateStringToDate(periodResponse.endDate),
      active: periodResponse.active,
    } as Period
  }
}
