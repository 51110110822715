import React, { FC } from 'react'

interface ArrowIconProps {
  width?: number
  height?: number
  color?: string
}

export const ArrowIcon: FC<ArrowIconProps> = ({ width = 138, height = 113, color = '#979797' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 138 113" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 56.5C0 59.0878 0.961338 61.2922 2.88401 63.1132L50.1818 110.197C52.0564 112.066 54.1473 113 56.4545 113C59.0982 113 61.2372 112.185 62.8715 110.556C64.5057 108.927 65.3229 106.914 65.3229 104.518C65.3229 103.176 65.0585 101.978 64.5298 100.924C64.0491 99.8694 63.4002 98.9349 62.5831 98.1202L46.4326 81.8747L20.837 58.5846L17.5204 63.9758L43.7649 65.4854H128.843C131.583 65.4854 133.794 64.6707 135.476 63.0413C137.159 61.3641 138 59.1836 138 56.5C138 53.8643 137.159 51.7318 135.476 50.1024C133.794 48.4251 131.583 47.5865 128.843 47.5865H43.7649L17.5204 49.0242L20.837 54.5592L46.4326 31.1253L62.5831 14.8798C63.4002 14.0651 64.0491 13.1306 64.5298 12.0763C65.0585 10.9741 65.3229 9.77608 65.3229 8.48219C65.3229 6.08609 64.5057 4.07337 62.8715 2.44402C61.2372 0.814673 59.0982 0 56.4545 0C55.349 0 54.2435 0.23961 53.1379 0.71883C52.0805 1.15013 51.047 1.86896 50.0376 2.87532L2.88401 49.8868C0.961338 51.7078 0 53.9122 0 56.5Z"
        fill={color}
      />
    </svg>
  )
}
