const DownloadIcon = ({ color = '#005ECA', width = 12, height = 15 } = {}): JSX.Element => {
  return (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.26074 14.249H9.73926C10.4684 14.249 11.0244 14.0576 11.4072 13.6748C11.7946 13.2965 11.9883 12.7451 11.9883 12.0205V5.7793C11.9883 5.05469 11.7946 4.50098 11.4072 4.11816C11.0244 3.73535 10.4684 3.54395 9.73926 3.54395H8.05762V5.12305H9.58203C9.85091 5.12305 10.056 5.19141 10.1973 5.32812C10.3385 5.46029 10.4092 5.66992 10.4092 5.95703V11.8359C10.4092 12.1185 10.3385 12.3281 10.1973 12.4648C10.056 12.6016 9.85091 12.6699 9.58203 12.6699H2.41797C2.14909 12.6699 1.94401 12.6016 1.80273 12.4648C1.66146 12.3281 1.59082 12.1185 1.59082 11.8359V5.95703C1.59082 5.66992 1.66146 5.46029 1.80273 5.32812C1.94401 5.19141 2.14909 5.12305 2.41797 5.12305H3.96973V3.54395H2.26074C1.53158 3.54395 0.973307 3.73535 0.585938 4.11816C0.203125 4.49642 0.0117188 5.05013 0.0117188 5.7793V12.0205C0.0117188 12.7451 0.203125 13.2965 0.585938 13.6748C0.973307 14.0576 1.53158 14.249 2.26074 14.249ZM6 10.1475C6.0957 10.1475 6.18913 10.1292 6.28027 10.0928C6.37142 10.0518 6.46484 9.98568 6.56055 9.89453L8.70703 7.83008C8.83464 7.69792 8.89844 7.5498 8.89844 7.38574C8.89844 7.20801 8.83691 7.06445 8.71387 6.95508C8.59082 6.8457 8.44499 6.79102 8.27637 6.79102C8.09408 6.79102 7.93913 6.85938 7.81152 6.99609L7.00488 7.85742L6.62207 8.34961L6.71094 7.39941V0.84375C6.71094 0.656901 6.64258 0.492839 6.50586 0.351562C6.36914 0.210286 6.20052 0.139648 6 0.139648C5.79948 0.139648 5.62858 0.210286 5.4873 0.351562C5.35059 0.492839 5.28223 0.656901 5.28223 0.84375V7.39941L5.37793 8.35645L4.99512 7.85742L4.18164 6.99609C4.05859 6.85938 3.90137 6.79102 3.70996 6.79102C3.5459 6.79102 3.40234 6.8457 3.2793 6.95508C3.15625 7.06445 3.09473 7.20801 3.09473 7.38574C3.09473 7.5498 3.15853 7.69792 3.28613 7.83008L5.43945 9.89453C5.5306 9.98568 5.62174 10.0518 5.71289 10.0928C5.80859 10.1292 5.9043 10.1475 6 10.1475Z"
        fill={color}
      />
    </svg>
  )
}

export default DownloadIcon
