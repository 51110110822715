import { Employee } from '../../services/EmployeeService'
import { PlusIcon } from '../atoms/Icons/PlusIcon'
import AppTooltip from '../atoms/AppTooltip'
import { RequestReviewModal } from './RequestReviewModal'
import React, { useState } from 'react'
import { classNames } from 'primereact/utils'
import { getHoverColorForEmployee } from '../../utils/hoverColorUtils'

interface RequestReviewButtonProps {
  employee: Employee
  allEmployees: Employee[]
  reviewersId: number[]
  onRequestAdded?: () => void
  buttonBody?: JSX.Element
  showBackgroundHover?: boolean
  backgroundHoverColor?: string
}

export const RequestReviewButton = ({
  employee,
  allEmployees,
  reviewersId,
  onRequestAdded,
  buttonBody,
  showBackgroundHover,
  backgroundHoverColor,
}: RequestReviewButtonProps): JSX.Element => {
  const [showRequestReviewModal, setShowRequestReviewModal] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowRequestReviewModal(true)
    event.stopPropagation()
  }

  const onClose = (requestAdded: boolean) => {
    setShowRequestReviewModal(false)
    requestAdded && onRequestAdded && onRequestAdded()
  }

  return (
    <AppTooltip
      title={'Add Reviewer'}
      arrow
      enterDelay={500}
      enterNextDelay={500}
      classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}
    >
      <div
        className={classNames('relative overflow-visible ml-[1.125rem] mb-2', backgroundHoverColor, {
          'px-[0.2rem] clickable-hover-effect --rounded ': showBackgroundHover,
        })}
      >
        <span onClick={handleClick}>
          {buttonBody ? (
            buttonBody
          ) : (
            <span className="flex justify-center items-center gap-x-0.5 text-blue font-semibold text-lg hover:decoration-underline border-b border-transparent hover:border-blue">
              <PlusIcon width={12} height={12} />
            </span>
          )}
        </span>
        {showRequestReviewModal && (
          <RequestReviewModal
            employee={employee}
            allEmployees={allEmployees}
            reviewersId={reviewersId}
            onClose={onClose}
          />
        )}
      </div>
    </AppTooltip>
  )
}
