import { EmployeeScores } from '../services/EmployeeService'

export const calculateEmployeeScore = (employee: EmployeeScores, totalTopics: number): EmployeeScores => {
  employee.score = Math.round(
    ((employee.avgAttitude || 0) + (employee.avgCommunication || 0) + (employee.avgAptitude || 0)) / totalTopics
  )
  return employee
}

export const calculateAvgReviewForTeam = (teamEmployees: EmployeeScores[], totalTopics: number): EmployeeScores => {
  const result: EmployeeScores = {
    id: -1,
    name: 'Team Average',
    avgAttitude: 0,
    avgAptitude: 0,
    avgCommunication: 0,
    score: 0,
    isGlobalScore: true,
    skipped: false,
  }

  if (teamEmployees && teamEmployees.length > 0) {
    const amountWithReviews = teamEmployees.filter(e => e.lastReviewType != null).length || 1
    teamEmployees.forEach(review => {
      result.avgAttitude = (result.avgAttitude || 0) + (review.avgAttitude || 0)
      result.avgCommunication = (result.avgCommunication || 0) + (review.avgCommunication || 0)
      result.avgAptitude = (result.avgAptitude || 0) + (review.avgAptitude || 0)
    })
    result.avgAttitude = Math.round((result.avgAttitude || 0) / amountWithReviews)
    result.avgAptitude = Math.round((result.avgAptitude || 0) / amountWithReviews)
    result.avgCommunication = Math.round((result.avgCommunication || 0) / amountWithReviews)
    result.score = Math.round((result.avgAttitude + result.avgAptitude + result.avgCommunication) / totalTopics)
  }
  return result
}
