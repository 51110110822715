import { cloneDeep } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from '../..'
import { COACHING_FORM_NAME, ConstantIDs } from '../../constants/employee'
import { useQuery } from '../../hooks/useQuery'
import { Employee, EmployeeDetails, EmployeeService } from '../../services/EmployeeService'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../services/EventTrackingService'
import { FormService } from '../../services/FormService'
import { ReviewService } from '../../services/ReviewService'
import { finishReportTutorial, hideReportTutorial, showReportTutorialIndex } from '../../slices/report-tutorial-slice'
import { Form } from '../../types/definitions/form'
import { AnsweredQuestion } from '../../types/definitions/question'
import { Review, ReviewEmployee } from '../../types/definitions/review'
import { MapScoreResponse, ReviewResponse, ReviewSkippedResponse } from '../../api/openapi'
import { ReportLayoutTypeEnum } from '../../types/enums/report-layout-type.enum'
import { formatDate } from '../../utils/dateUtils'
import { createPathToCreateNewReview } from '../../utils/employeePathUtils'
import history from '../../utils/history'
import { createPathWithLang } from '../../utils/languagePathUtils'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { Roles } from '../../utils/routes'
import { assignColor } from '../../utils/utils'
import { AppButton } from '../atoms/AppButton/AppButton'
import { ChartDatasetType } from '../atoms/Charts/ChartRadar'
import DeleteIcon from '../atoms/Icons/DeleteIcon.svg'
import GarbageSVG from '../atoms/Icons/Garbage.svg'
import PencilSVG from '../atoms/Icons/Pencil.svg'
import { PlusIcon } from '../atoms/Icons/PlusIcon'
import Spinner from '../atoms/Spinner'
import EmployeeResultsData from '../molecules/EmployeeResults/EmployeeResultsData'
import EmployeeResultsDetails from '../molecules/EmployeeResults/EmployeeResultsDetails'
import EmployeeResultsHeader from '../molecules/EmployeeResults/EmployeeResultsHeader'
import EmployeeResultsHistory from '../molecules/EmployeeResults/EmployeeResultsHistory'
import EmployeeResultsTeams from '../molecules/EmployeeResults/EmployeeResultsTeams'
import OptionsModal from '../molecules/OptionsModal'
import { ReportTutorialModal } from '../molecules/ReportTutorialModal'
import { TechProfileBody } from '../molecules/TechProfile/TechProfileBody'
import { TutorialModal } from '../molecules/TutorialModal'
import EmployeeResultsChart from '../organisms/EmployeeResultsChart'
import { RecommendedResourcesSection } from '../organisms/RecommendedResourcesSection'
import { MapScoreSection } from '../organisms/MapScoreSection'
import { RequestReviewButton } from '../organisms/RequestReviewButton'
import { PeriodService } from '../../services/PeriodService'
import { Period } from '../../types/definitions/Period'
import { MapScoresService } from '../../services/MapScoresService'
import AddPersonIcon from '../atoms/Icons/AddPersonIcon'
import { EmployeeResultsStrengthWekness } from '../molecules/EmployeeResults/EmployeeResultsStrengthWekness'
import { EmployeeResultsCoachingVision } from '../organisms/EmployeeResultsCoachingVision'

const redirectToNotFound = (): void => {
  history.push(createPathWithLang(NetworkConstants.URL_NOT_FOUND))
}

const redirectToNotAuthorized = (): void => {
  history.push(createPathWithLang(NetworkConstants.URL_UNAUTHORIZED))
}

const deleteModalMessage = (
  <p className={`modal-title`}>Are you sure the you want to delete all the data from this review?</p>
)

const TutorialIntroduction = () => {
  return (
    <div className="container flex justify-center my-auto">
      <div className="flex flex-col justify-center">
        <div className="w-full text-center leading-[3rem]">
          <p className="text-6xl font-medium mb-16">Let’s introduce the Reports</p>
          <div className="text-3xl font-normal mb-12 flex flex-col gap-8">
            <p>Reports show the status and progression of your team member's professional development.</p>
            <p>
              You can retrieve previous assessments completed by you and any previous reviewers. You can also access
              their technical profile data and available GitHub metrics.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

interface RadarChartData {
  values: number[]
  labels: string[]
}

interface RadarChartTopic {
  label: string
  value: number
  order?: number
}

export const sortReviewsByCreationDate = (r1: Review, r2: Review): number => {
  const d1 = moment(r1.createdDate)
  const d2 = moment(r2.createdDate)
  if (d1 > d2) {
    return 1
  }
  if (d1 < d2) {
    return -1
  }
  return 0
}

export const EmployeeResults = (): JSX.Element => {
  const query = useQuery()
  const dispatch = useAppDispatch()
  const { employeeId, formId } = useParams<{ employeeId: string; formId: string }>()
  const [allForms, setAllForms] = useState<Form[]>()
  const [selectedFormId, setSelectedFormId] = useState<number>()
  const [currentReview, setCurrentReview] = useState<Review>()
  const [reviewToCompare, setReviewToCompare] = useState<Review>()
  const [reviews, setReviews] = useState<Review[]>()
  const [coachingReviews, setCoachingReviews] = useState<Review[]>()
  const [technicalProfile, setTechnicalProfile] = useState<Review>()
  const [finishedReviews, setFinishedReviews] = useState<Review[]>()
  const [reviewsSkipped, setReviewsSkipped] = useState<ReviewSkippedResponse[]>([])
  const [lastOwnUnfinishedReviewId, setLastOwnUnfinishedReviewId] = useState<number>()
  const [allReviewers, setAllReviewers] = useState<ReviewEmployee[]>()
  const [employee, setEmployee] = useState<Employee>()
  const [radarDataSet, setRadarDataSet] = useState<Record<string, ChartDatasetType>>({})
  const [radarLabels, setRadarLabels] = useState<string[]>([])
  const [showEditButton, setShowEditButton] = useState<boolean>()
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [deleteReviewId, setDeleteReviewId] = useState<number>()
  const [allEmployees, setAllEmployees] = useState<Employee[]>([])
  const [selfForm, setSelfForm] = useState<Form>()
  const [loading, setLoading] = useState<boolean>(true)
  const currentUser = useSelector<RootState, EmployeeDetails | null>(state => state.auth.user)
  const [showDownloadButton, setShowDownloadButton] = useState(false)
  const [allPeriods, setAllPeriods] = useState<Period[]>([])
  const [mapScores, setMapScores] = useState<MapScoreResponse[]>([])
  // Tutorial
  const tutorialCompleted = useSelector<RootState, boolean>(state => state.reportTutorial.completed)
  const [introductionView, setIntroductionView] = useState<boolean>(tutorialCompleted)

  useEffect(() => {
    setShowEditButton(currentReview?.reviewAuthor.id === currentUser?.id && !currentReview?.form.deprecated)
    setShowDeleteButton(currentReview?.reviewAuthor.id === currentUser?.id)
  }, [currentReview, currentUser])

  useEffect(() => {
    setShowDownloadButton(
      currentUser?.leaderIn?.some(team => {
        const commomTeams = employee?.teams?.filter(t => t.id === team.id)
        if (commomTeams) {
          return (
            commomTeams.length > 0 ||
            currentUser?.role === Roles.ADMIN_ROLE ||
            currentReview?.reviewAuthor.id === currentUser.id
          )
        }
        return currentUser?.role === Roles.ADMIN_ROLE || currentReview?.reviewAuthor.id === currentUser.id
      }) ?? false
    )
  }, [currentUser, employee, currentReview?.reviewAuthor.id])

  const getRadarChartData = (answeredQuestions: AnsweredQuestion[]): RadarChartData => {
    const radarRawData: RadarChartTopic[] = [] as RadarChartTopic[]
    answeredQuestions.forEach((answeredQuestion: AnsweredQuestion) => {
      if (answeredQuestion.reportLayout?.type === ReportLayoutTypeEnum.CHART) {
        if (answeredQuestion.topic?.name && !radarRawData.some(data => data.label === answeredQuestion.topic?.name)) {
          radarRawData.push({
            label: answeredQuestion.topic.name,
            value: answeredQuestion.answer as number,
            order: answeredQuestion.topic.order,
          })
        }
      }
    })
    const sortByOrderAsc = (a: RadarChartTopic, b: RadarChartTopic) => {
      if (!a.order || !b.order) return -1
      if (a.order === b.order) return 0
      return a.order > b.order ? 1 : -1
    }
    const radarChartData = radarRawData.sort(sortByOrderAsc).reduce(
      (acc: RadarChartData, curr: RadarChartTopic) => {
        const { labels, values } = { ...acc }
        labels.push(curr.label)
        values.push(curr.value)
        return { labels, values }
      },
      { labels: [], values: [] }
    )
    return radarChartData
  }

  const getPeriods = () => {
    PeriodService.findAll().then(allPeriods => setAllPeriods(allPeriods))
  }

  const getEmployeeMapScores = (employeeId: number) => {
    MapScoresService.getMapScoresForEmployee(employeeId).then(mapScores => setMapScores(mapScores))
  }

  const setChartData = (): void => {
    let labels: string[] = []
    const data: Record<string, ChartDatasetType> = {}

    const reviews: Review[] = []
    if (currentReview) reviews.push(currentReview)
    if (reviewToCompare) reviews.push(reviewToCompare)

    reviews.forEach((review: Review, index: number) => {
      const sortedAnswers = review.answers.sort(sortAnswersByOrder)
      const reviewResult: RadarChartData = getRadarChartData(sortedAnswers)

      if (reviewResult.values.length === 0) {
        return
      }

      let values: number[] = []
      if (reviewResult.labels.length > labels.length) {
        labels = reviewResult.labels
        values = reviewResult.values
      } else {
        // Entering here means that we have more labels than values
        // therefore we need to fill the blank values with 0s to have
        // as many values as labels.
        const diff = labels.length - reviewResult.values.length
        const filler = new Array(diff).fill(0)
        values = reviewResult.values.concat(filler)
      }

      const reviewDate = review.finishDate || ''
      const dataset: ChartDatasetType = {
        formName: review.form.name,
        reviewDate,
        displayed: true,
        values,
        color: assignColor(index),
        reviewerName: review.reviewAuthor.name,
        periodAverage: review.periodAverage,
      }
      data[reviewDate] = dataset
    })
    setRadarLabels(labels)
    setRadarDataSet(data)
  }

  useEffect(() => {
    const requestEmployee = async () => {
      const employee = await EmployeeService.findOne(Number(employeeId))
      setEmployee(employee as unknown as Employee)
      const skipped = employee.skippedReviewsReceived as ReviewSkippedResponse[]
      setReviewsSkipped(skipped.sort(sortReviewsSkippedByCreationDate))
      // find the last unfinished review for this employee+user combination
      // if there is one
      const unfinishedIdArr = employee.reviewsReceived?.filter((r: ReviewResponse): boolean => {
        if (r.finishDate || r.reviewAuthor.id !== currentUser?.id) return false
        else return true
      })
      if (unfinishedIdArr?.length) {
        setLastOwnUnfinishedReviewId(unfinishedIdArr[0].id)
      }
      getEmployeeMapScores(employee.id)
    }

    requestEmployee().catch(error => {
      console.error('Employee service error: ' + error.message)
      redirectToNotFound()
    })
  }, [employeeId, currentUser])

  useEffect(() => {
    const requestAllReviews = async () => {
      try {
        if (employee) {
          const rawReviews = await ReviewService.findByEmployeeId(employee.id)
          const finishedReviews = rawReviews.filter(review => review.finishDate)
          const usedFormsIds = [...new Set(finishedReviews.map(r => r.form.id as number))]
          const filteredForms = await Promise.all(usedFormsIds.map(formId => FormService.findById(formId))).catch(e => {
            throw new Error('Error getting forms')
          })

          setAllForms(filteredForms)
          setFinishedReviews(finishedReviews ?? [])
          setAllReviewers(rawReviews.map(reviews => reviews.reviewAuthor))

          const lastReview = finishedReviews.sort(sortReviewsByCreationDate).at(-1)
          if (formId) {
            setSelectedFormId(Number(formId))
          } else if (lastReview && lastReview.form.id) {
            setSelectedFormId(lastReview.form.id)
          }

          const techProfile = await ReviewService.findSelfReviewByEmployeeId(employee.id)
          const selfForm = filteredForms.find(form => form.id === techProfile.form.id)
          setTechnicalProfile(techProfile)
          setSelfForm(selfForm)
        }
        // eslint-disable-next-line
      } catch (error: any) {
        if (error.response.status === 403) redirectToNotAuthorized()
        console.error('Request service error: ', error)
      }
    }

    requestAllReviews().catch(console.error)
  }, [employee, formId])

  useEffect(() => {
    dispatch(hideReportTutorial())
    trackEvent(TrackingCategoryEnum.INDIVIUAL_REPORT, TrackingCategoryEventEnum.INDIVIUAL_REPORT.PAGE_ON_LOAD, '')
    void EmployeeService.findAll()
      .then(async employees => {
        setAllEmployees(employees as unknown as Employee[])
      })
      .catch(error => {
        console.error('Employee service error: ' + error.message)
      })
  }, [dispatch])

  useEffect(() => {
    if (finishedReviews && selectedFormId !== undefined) {
      const orderedReviews = cloneDeep(finishedReviews).sort(sortReviewsByCreationDate).reverse()
      const normalReviews = orderedReviews.filter(review => review?.form?.name !== COACHING_FORM_NAME)
      const coachingReviews = orderedReviews.filter(review => review?.form?.name === COACHING_FORM_NAME)
      setCoachingReviews(coachingReviews)
      setReviews(normalReviews)

      let filteringFormId = selectedFormId
      const selectedFormName = allForms?.find(form => form.id === selectedFormId)?.name
      if (selectedFormName === COACHING_FORM_NAME) {
        // because coaching reviews are not displayed here
        filteringFormId = normalReviews[0]?.form.id || 0
        setSelectedFormId(filteringFormId)
      }
      const filteredReviews = normalReviews.filter(review => review?.form?.id === filteringFormId)

      let reviewToSelect
      if (query.get(ConstantIDs.REVIEW_ID_PLACEHOLDER)) {
        const reviewFromQuery = filteredReviews.find(review => {
          return review.id === Number(query.get(ConstantIDs.REVIEW_ID_PLACEHOLDER))
        })
        reviewToSelect = reviewFromQuery || filteredReviews[0]
      } else {
        reviewToSelect = filteredReviews[0]
      }
      setCurrentReview(reviewToSelect)
      setReviewToCompare(normalReviews[1])
      setChartData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedReviews, selectedFormId])

  useEffect(() => {
    if (reviews && currentReview) setChartData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReview, reviewToCompare])

  useEffect(() => {
    if (currentReview || finishedReviews) {
      setLoading(false)
    }
  }, [currentReview, finishedReviews])

  useEffect(() => {
    getPeriods()
  }, [])

  function sortAnswersByOrder(q1: AnsweredQuestion, q2: AnsweredQuestion): number {
    if (!q1.order || !q2.order) {
      return 1
    }
    if (q1.order === q2.order) {
      return 0
    }
    return q1.order > q2.order ? -1 : 1
  }

  function sortReviewsSkippedByCreationDate(r1: ReviewSkippedResponse, r2: ReviewSkippedResponse): number {
    const d1 = moment(r1.createdDate)
    const d2 = moment(r2.createdDate)
    if (d1 > d2) {
      return 1
    }
    if (d1 < d2) {
      return -1
    }
    return 0
  }

  const pathToEditReview = (): string => {
    if (!currentReview || currentReview.form.deprecated) return ''
    return createPathWithLang(
      NetworkConstants.URL_EDIT_REVIEW.replace(ConstantIDs.REVIEW_ID_PATH_PLACEHOLDER, currentReview.id.toString())
    )
  }

  const handleClickOnEditReview = () => {
    trackEvent(
      TrackingCategoryEnum.INDIVIUAL_REPORT,
      TrackingCategoryEventEnum.INDIVIUAL_REPORT.CLICK_EDIT_BTN,
      JSON.stringify({ reviewId: currentReview?.id })
    )
    if (!currentReview || currentReview.form.deprecated) return ''
    return createPathWithLang(
      NetworkConstants.URL_EDIT_REVIEW.replace(ConstantIDs.REVIEW_ID_PATH_PLACEHOLDER, currentReview.id.toString())
    )
  }

  const handleCoachingEditReview = (coachingReview: Review) => {
    trackEvent(
      TrackingCategoryEnum.INDIVIUAL_REPORT,
      TrackingCategoryEventEnum.INDIVIUAL_REPORT.CLICK_EDIT_BTN,
      JSON.stringify({ reviewId: coachingReview?.id })
    )
    if (!coachingReview || coachingReview.form.deprecated) return ''
    return createPathWithLang(
      NetworkConstants.URL_EDIT_REVIEW.replace(ConstantIDs.REVIEW_ID_PATH_PLACEHOLDER, coachingReview.id.toString())
    )
  }

  // FIXME: This component shouldn't be handling this kind of stuff, this should be
  // on a deeper component like EmployeeReportRightSide together with the right side HTML
  const handleReviewToggled = (selectedReviewDate: string): void => {
    trackEvent(
      TrackingCategoryEnum.INDIVIUAL_REPORT,
      TrackingCategoryEventEnum.INDIVIUAL_REPORT.CLICK_CHART_SWITCHES,
      ''
    )
    const selectedRadarDataSet = radarDataSet[selectedReviewDate]
    if (selectedRadarDataSet) {
      selectedRadarDataSet.displayed = !selectedRadarDataSet?.displayed
    }
    setRadarDataSet({ ...radarDataSet, [selectedReviewDate]: selectedRadarDataSet })
  }
  const handleCoachingDeleteReview = (review: Review) => {
    setDeleteReviewId(review.id)
    setShowDeleteModal(true)
  }

  const handleDeleteReview = () => {
    setDeleteReviewId(currentReview?.id)
    setShowDeleteModal(true)
  }

  const handleCancelDelete = () => setShowDeleteModal(false)
  const handleConfirmDelete = async () => {
    const reviewIdToDelete = deleteReviewId

    trackEvent(
      TrackingCategoryEnum.INDIVIUAL_REPORT,
      TrackingCategoryEventEnum.INDIVIUAL_REPORT.CLICK_DELETE_ASSESSMENT,
      ''
    )
    try {
      if (reviewIdToDelete) {
        await deleteReview(reviewIdToDelete)
        const remainingReviews = finishedReviews?.filter(rev => rev.id !== reviewIdToDelete)
        setFinishedReviews(remainingReviews)
        setShowDeleteModal(false)
        if (remainingReviews) setCurrentReview(remainingReviews.at(-1))
        history.push(createPathWithLang(NetworkConstants.URL_REPORT.replace(':employeeId', employeeId)))
        return
      }

      history.goBack()
    } catch (error) {
      console.error(error)
    }
  }

  const deleteReview = async (id: number) => await ReviewService.deleteReview(id)

  if (!finishedReviews || loading) {
    return <Spinner />
  }

  return (
    <div className="container mx-auto print:p-16 print:-mt-12">
      <div className="employee-results !pb-0 mb-8 print:p-0 print:m-0 print:break-after-page print:break-before-page">
        {employee && (
          <div className="employee-results__content print:m-0">
            <TutorialModal
              visible={!introductionView}
              onClose={() => {
                setIntroductionView(true)
                dispatch(showReportTutorialIndex(0))
              }}
              skipTutorial={() => {
                setIntroductionView(true)
                dispatch(finishReportTutorial())
              }}
              icon={false}
              startButtonText="Quick tutorial"
              message={<TutorialIntroduction />}
            />
            <ReportTutorialModal visible={!tutorialCompleted && introductionView} />
            <EmployeeResultsHeader
              employee={employee}
              employees={allEmployees}
              reviews={reviews}
              addReviewer={true}
              showDownloadButton={showDownloadButton}
            />
            {/*
            Show the skipped review box if there are no assessments and there are skipped reviews.
            We only show the latest one for now
            */}
            {!finishedReviews.length && reviewsSkipped.length ? (
              <div className="p-20 mt-20 shadow-default rounded-2xl print:shadow-none print:!p-2 print:break-before-page print:!pt-16 print:!my-0">
                <div className="flex items-center justify-between w-full">
                  <div className="flex flex-col w-3/5">
                    <div className="flex flex-row w-70">
                      <span className="text-3xl font-bold text-stone-gray block mt-4 print:m-0 mb-4 mr-5">
                        {'🕘 Assessment Skipped by ' +
                          (reviewsSkipped[0].author?.name || '') +
                          ' on ' +
                          moment(reviewsSkipped[0].createdDate).format('MM/DD/YYYY')}
                      </span>
                    </div>
                    <span className="text-4xl font-semibold text-dark-blue leading-snug">
                      {reviewsSkipped[0].reason}
                    </span>
                    {reviewsSkipped[0].notes && (
                      <div>
                        <span className="text-2xl font-bold block mt-4 print:m-0">Note</span>
                        <span className="text-2xl text-stone-gray mt-2 block print:m-0">{reviewsSkipped[0].notes}</span>
                      </div>
                    )}
                  </div>
                  {lastOwnUnfinishedReviewId ? (
                    <Link
                      className="w-1/3 !max-w-[240px]"
                      to={createPathWithLang(
                        NetworkConstants.URL_EDIT_REVIEW.replace(
                          ConstantIDs.REVIEW_ID_PATH_PLACEHOLDER,
                          lastOwnUnfinishedReviewId.toString()
                        )
                      )}
                    >
                      <AppButton type="clear">
                        <PencilSVG />
                        Continue assessment
                      </AppButton>
                    </Link>
                  ) : (
                    <Link className="w-1/3 !max-w-[240px]" to={createPathToCreateNewReview(employee.id)}>
                      <AppButton type="clear">
                        <PlusIcon />
                        New assessment
                      </AppButton>
                    </Link>
                  )}
                </div>
              </div>
            ) : undefined}
            {currentReview && reviews && Object.keys(currentReview).length > 1 && (
              <>
                <div className="p-20 shadow-default rounded-2xl print:shadow-none print:!p-2 print:break-after-page">
                  <div className="flex justify-between items-center w-full">
                    <span className="text-4xl font-semibold text-dark-blue print:text-3xl">Talent assessment</span>
                    <RequestReviewButton
                      key={'request-review-button' + employee.id}
                      reviewersId={allReviewers?.map(reviewer => reviewer.id) || []}
                      onRequestAdded={() => {
                        history.go(0)
                      }}
                      allEmployees={allEmployees || []}
                      employee={employee}
                      buttonBody={
                        <AppButton type="clear">
                          <AddPersonIcon />
                          <span className="ml-2">Add reviewer</span>
                        </AppButton>
                      }
                    />
                  </div>
                  <div className="text-xl font-bold mt-4">Assessed by</div>
                  <EmployeeResultsHistory
                    reviews={reviews}
                    currentReview={currentReview}
                    onChange={v => {
                      setCurrentReview(v)
                    }}
                    allForms={allForms || []}
                  />
                  <div className="employee-results__body mb-12 print:mb-2 print:gap-2 print:flex-col-reverse print:w-full print:justify-center">
                    <div className="employee-results__body-data">
                      <EmployeeResultsTeams employee={employee} />
                      <EmployeeResultsData employee={employee} review={currentReview} />
                    </div>
                    <EmployeeResultsChart
                      radarDataSet={radarDataSet}
                      radarLabels={radarLabels}
                      currentReview={currentReview}
                      reviewToCompare={reviewToCompare}
                      onReviewToggle={handleReviewToggled}
                      selectableReviews={reviews}
                      allForms={allForms || []}
                      allPeriods={allPeriods}
                      onComparableChange={v => {
                        setReviewToCompare(v)
                      }}
                    />
                  </div>
                  <EmployeeResultsDetails employee={employee} review={currentReview} />
                  <div className="employee-results__footer flex mt-24 print:mt-0">
                    {showEditButton && (
                      <div>
                        <Link to={pathToEditReview} onClick={handleClickOnEditReview}>
                          <AppButton>
                            <PencilSVG color="white" width={14} height={14} />
                            <span className="ml-2">Edit assessment</span>
                          </AppButton>
                        </Link>
                        <button
                          onClick={handleDeleteReview}
                          className="flex items-center mt-7 clickable-hover-effect clickable-hover-effect--soft-grey"
                        >
                          <GarbageSVG color="#005ECA" width={15} height={17} />
                          <span className="text-blue text-2xl font-semibold ml-3 font-manrope">Delete assessment</span>
                        </button>
                      </div>
                    )}
                    <div>
                      {lastOwnUnfinishedReviewId ? (
                        <Link
                          to={createPathWithLang(
                            NetworkConstants.URL_EDIT_REVIEW.replace(
                              ConstantIDs.REVIEW_ID_PATH_PLACEHOLDER,
                              lastOwnUnfinishedReviewId.toString()
                            )
                          )}
                        >
                          <AppButton type="clear">
                            <PencilSVG />
                            <span className="ml-2">Continue assessment</span>
                          </AppButton>
                        </Link>
                      ) : (
                        <Link to={createPathToCreateNewReview(employee.id)}>
                          <AppButton type="clear">
                            <PlusIcon />
                            <span className="ml-2">New assessment</span>
                          </AppButton>
                        </Link>
                      )}
                      {!showEditButton && showDeleteButton && (
                        <button
                          onClick={handleDeleteReview}
                          className="flex items-center mt-4 clickable-hover-effect clickable-hover-effect--soft-grey"
                        >
                          <GarbageSVG color="#005ECA" width={13} height={15} />
                          <span className="text-blue text-2xl font-semibold ml-3 font-manrope">Delete assessment</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <EmployeeResultsStrengthWekness reviews={reviews} />
              </>
            )}
            {coachingReviews && coachingReviews.length > 0 && currentUser?.role === 'admin' && (
              <EmployeeResultsCoachingVision
                reviews={coachingReviews}
                allForms={allForms}
                employee={employee}
                currentUser={currentUser}
                lastOwnUnfinishedReviewId={lastOwnUnfinishedReviewId}
                handleEditReview={handleCoachingEditReview}
                handleDeleteReview={handleCoachingDeleteReview}
              />
            )}

            {!finishedReviews.length && !reviewsSkipped.length && (
              <div className="p-20 mt-20 shadow-default rounded-2xl print:shadow-none print:!p-2 print:break-before-page print:!pt-16 print:!my-0">
                <div className="flex items-center justify-between w-full">
                  <div className="flex flex-col w-4/5">
                    <span className="text-4xl font-semibold text-dark-blue">No assessments yet</span>
                    <span className="text-2xl text-stone-gray block mt-4 print:m-0">
                      The responsible leaders have not completed an assessment yet.
                    </span>
                  </div>
                  {lastOwnUnfinishedReviewId ? (
                    <Link
                      className="w-1/3 !max-w-[240px]"
                      to={createPathWithLang(
                        NetworkConstants.URL_EDIT_REVIEW.replace(
                          ConstantIDs.REVIEW_ID_PATH_PLACEHOLDER,
                          lastOwnUnfinishedReviewId.toString()
                        )
                      )}
                    >
                      <AppButton type="clear">
                        <PencilSVG />
                        Continue assessment
                      </AppButton>
                    </Link>
                  ) : (
                    <Link className="w-1/3 !max-w-[240px]" to={createPathToCreateNewReview(employee.id)}>
                      <AppButton type="clear">
                        <PlusIcon />
                        New assessment
                      </AppButton>
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {/* TODO RE-ENABLE AFTER NAYARA APPROVES
      currentReview && <RecommendedResourcesSection currentReview={currentReview} /> */}
      {mapScores.length > 0 && <MapScoreSection mapScores={mapScores} />}
      {/*TODO: TECHNICAL PROFILE COMPONENT*/}
      {technicalProfile?.finishDate ? (
        <div className="p-20 shadow-default rounded-2xl print:shadow-none print:!p-0 print:break-before-page print:!pt-16">
          <span className="text-4xl font-semibold text-dark-blue">Technical Profile</span>
          <span className="text-xl font-medium text-stone-gray block mt-4 print:!m-0">
            {formatDate(technicalProfile?.lastModifiedDate)}
          </span>
          <div className="employee-results">
            <div className="employee-results__content print:text-2xl">
              <TechProfileBody review={technicalProfile} form={selfForm || {}} />
            </div>
          </div>
        </div>
      ) : (
        <div className="p-20 mt-20 shadow-default rounded-2xl print:shadow-none print:!p-2 print:break-before-page print:!pt-16 print:!my-0">
          <span className="text-4xl font-semibold text-dark-blue">Technical Profile</span>
          <span className="text-2xl text-stone-gray block mt-8">
            <b>This user hasn't completed</b> a technical profile yet.
          </span>
        </div>
      )}
      <OptionsModal
        icon={<DeleteIcon />}
        secondButtonText="Delete"
        secondButtonType="error"
        firstButtonText="Cancel"
        firstButtonType="clear"
        visible={showDeleteModal}
        onFirstButtonClick={handleCancelDelete}
        onSecondButtonClick={handleConfirmDelete}
        onClose={handleCancelDelete}
        message={deleteModalMessage}
      />
    </div>
  )
}
