import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { EmployeeMetaSearchResponse } from '../../api/openapi'
import { Employee } from '../../services/EmployeeService'
import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import { EmployeeTableBody } from '../molecules/EmployeeTable/EmployeeTableBody'
import { EmployeeTableHeader } from '../molecules/EmployeeTable/EmployeeTableHeader'
import { EmployeeTableOptionsBody } from '../molecules/EmployeeTable/EmployeeTableOptionsBody'

interface ResourcesTableParams {
  employees: EmployeeMetaSearchResponse[] | undefined
  selectedSort?: { property: string; order: SortOrderEnum }
  handleOnEmployeeClicked: (employee: Employee) => string
  onSortSelected?: (field: string, newSortOrder: SortOrderEnum) => void
}

export const ResourcesTable = ({
  employees,
  selectedSort,
  handleOnEmployeeClicked,
  onSortSelected,
}: ResourcesTableParams): JSX.Element => {
  const handleSortSelected = (field: string) => (newSortOrder: SortOrderEnum) => {
    onSortSelected?.(field, newSortOrder)
  }

  const handleEmployeeSelected = (employee: Employee): string => {
    return handleOnEmployeeClicked(employee)
  }

  return (
    <DataTable
      className="user-list-container"
      tableClassName="user-list-table"
      paginator
      rows={10}
      rowsPerPageOptions={[5, 10, 30, 50, 500]}
      alwaysShowPaginator={false}
      rowClassName={() => ({
        'review-list-table-row': true,
        'cursor-pointer': true,
      })}
      value={employees}
      dataKey="id"
      editMode="row"
      responsiveLayout="scroll"
    >
      <Column
        headerClassName="user-list-table-header"
        field="name"
        header={EmployeeTableHeader({
          title: 'Name',
          sortable: selectedSort?.property === 'name' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('name'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        headerClassName="user-list-table-header "
        field="jobTitle"
        header={EmployeeTableHeader({
          title: 'Job Title',
          sortable: selectedSort?.property === 'jobTitle' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('jobTitle'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        headerClassName="user-list-table-header"
        field="teams"
        header={EmployeeTableHeader({
          title: 'Teams',
          sortable: selectedSort?.property === 'teams' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('teams'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        headerClassName="user-list-table-header"
        field="leaders"
        header={EmployeeTableHeader({
          title: 'Leaders',
          sortable: selectedSort?.property === 'leader' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('leader'),
        })}
        body={EmployeeTableBody()}
      />
      <Column
        rowEditor
        headerStyle={{ minWidth: '13rem' }}
        bodyStyle={{ textAlign: 'center', position: 'relative' }}
        body={EmployeeTableOptionsBody({
          isMetaSearch: true,
          handleEmployeeSelected,
        })}
      />
    </DataTable>
  )
}
