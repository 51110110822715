import { Employee } from '../../services/EmployeeService'
import { TeamAssessmentsReportResponseExtended, TeamDetails, TeamStatus } from '../../services/TeamService'

export const getRootTeam = (teams: TeamDetails[]): TeamDetails | undefined => {
  // TODO: change BE to return the parentId and be able to calculate the root team effectivelyƒ
  return teams.find(team => team.leaders.some(leader => leader.name === 'Jaime Lopez'))
}

/**
 * Flattens the hierarchical team structure into a one-dimensional array.
 *
 * @param {TeamDetails[]} teams - The array of team details with hierarchical structure.
 * @returns {TeamDetails[]} - A flattened array containing all teams in a one-dimensional structure.
 */
export const flattenTeams = (teams: TeamDetails[]): TeamDetails[] => {
  return teams.flatMap(team => {
    const flattenedTeam: TeamDetails[] = [{ ...team }]

    if (team.children) {
      flattenedTeam.push(...flattenTeams(team.children))
    }

    return flattenedTeam
  })
}

export const getTeamsFromEmployees = (employees: Employee[]) => {
  const teams = employees.map(employee => (employee.teams && employee.teams[0]) || null).filter(team => team)
  return [...new Set(teams.map(a => JSON.stringify(a)))].map(a => JSON.parse(a))
}

export const addPreviousComparisonToTeamData = (
  currentTeamAssessments: TeamAssessmentsReportResponseExtended[],
  previousTeamAssessments: TeamAssessmentsReportResponseExtended[]
): TeamAssessmentsReportResponseExtended[] => {
  return currentTeamAssessments.map(teamAssessment => {
    const previousTeamAssessment = previousTeamAssessments.find(previous => previous.id === teamAssessment.id)
    const { finished = 0, skipped = 0, teamSize = 0 } = previousTeamAssessment || {}
    const previousProgress = Math.round(((finished + skipped) / teamSize) * 100)
    return { ...teamAssessment, previousProgress }
  })
}

export const getTeamStatusesFlags = (row: TeamStatus) => {
  const hasError =
    (row.mismatches && row.mismatches > 0 && row.flightRisks && row.flightRisks > 0) ||
    (row.mismatches && row.mismatches > 1) ||
    (row.flightRisks && row.flightRisks > 1)
  const hasAlert = !hasError && ((row.mismatches && row.mismatches > 0) || (row.flightRisks && row.flightRisks > 0))
  const hasReviews = row?.finished && row?.finished > 0
  const finishedAllReviews = row?.finished === row?.teamSize
  const missesReviewsWithoutErrorsAndAlerts = hasReviews && !finishedAllReviews && !hasError && !hasAlert
  const finishedReviewsWithoutErrorsAndAlerts = finishedAllReviews && !hasError && !hasAlert
  return {
    hasError,
    hasAlert,
    hasReviews,
    finishedReviewsWithoutErrorsAndAlerts,
    missesReviewsWithoutErrorsAndAlerts,
  }
}
