import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { SortOrderEnum } from '../../../types/enums/sort-order.enum'
import { GeneralStatusSortingProps } from '../../../utils/sortUtils'
import { EmployeeTableHeader } from '../../molecules/EmployeeTable/EmployeeTableHeader'
import { GeneralStatusTableBody } from '../../molecules/Dashboard/GeneralStatus/GeneralStatusTableBody'
import {
  AVAILABLE_EMPLOYEE_HIGHLIGHTS,
  AVAILABLE_EMPLOYEE_HIGHLIGHTS_LABELS,
  EMPLOYEE_HIGHLIGHT_ICON_MAP,
} from '../../../constants/employee'
import { GeneralStatusTableHierarchyBody } from '../../molecules/Dashboard/GeneralStatus/GeneralStatusTableHierarchyBody'
import { TeamDetails } from '../../../services/TeamService'
import { Employee, getEmployeesFromTeam } from '../../../services/EmployeeService'

interface GeneralStatusTableProps {
  teams: TeamDetails[] | undefined
  employees: Employee[]
  selectedSort?: { property: GeneralStatusSortingProps; order: SortOrderEnum }
  onSortSelected?: (field: GeneralStatusSortingProps, newSortOrder: SortOrderEnum) => void
  handleHierarchyNavigation: (teams: TeamDetails) => void
}

const GeneralStatusTable: React.FC<GeneralStatusTableProps> = ({
  teams,
  employees,
  selectedSort,
  onSortSelected,
  handleHierarchyNavigation,
}) => {
  const handleSortSelected = (field: GeneralStatusSortingProps) => (newSortOrder: SortOrderEnum) => {
    onSortSelected?.(field, newSortOrder)
  }

  const tableData = teams?.map(team => {
    return { team: team, employees: getEmployeesFromTeam(team, employees) }
  })

  return (
    <DataTable
      className="app-table-container"
      tableClassName="app-table team-table"
      rowClassName={() => ({
        'user-list-table-row --todo': true,
        'cursor-pointer': false,
      })}
      value={tableData}
      dataKey="id"
      responsiveLayout="scroll"
      rows={10}
      rowsPerPageOptions={[5, 10, 30, 50, 500]}
      alwaysShowPaginator={false}
    >
      <Column
        headerClassName="user-list-table-header w-[15rem] max-w-[130px]"
        field="name"
        className="w-[15rem] max-w-[130px]"
        bodyClassName="truncate"
        header={EmployeeTableHeader({
          title: 'Team Name',
          sortable: selectedSort?.property === 'name' ? { sortOrder: selectedSort.order } : undefined,
          onSortSelected: handleSortSelected('name'),
        })}
        body={GeneralStatusTableBody}
      />
      <Column
        headerClassName="user-list-table-header w-[15rem] max-w-[130px]"
        header={EmployeeTableHeader({ title: 'Direct Reports' })}
        alignHeader={'center'}
        className={'justify-center w-[15rem] max-w-[130px]'}
        body={GeneralStatusTableHierarchyBody({ handleHierarchyNavigation })}
      />
      {AVAILABLE_EMPLOYEE_HIGHLIGHTS.map((highlight, index) => {
        return (
          <Column
            headerClassName="user-list-table-header w-[15rem] max-w-[130px]"
            key={index}
            field={`highlight-${highlight.label}`}
            className="w-[15rem] max-w-[130px]"
            header={EmployeeTableHeader({
              title: EMPLOYEE_HIGHLIGHT_ICON_MAP[highlight.label] || highlight.label,
              sortable: highlight.label === selectedSort?.property ? { sortOrder: selectedSort.order } : undefined,
              onSortSelected: handleSortSelected(highlight.label as GeneralStatusSortingProps),
            })}
            body={GeneralStatusTableBody}
          />
        )
      })}
    </DataTable>
  )
}

export default GeneralStatusTable
