import { useState } from 'react'
import { DataTable, DataTableRowEditEvent } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { EmployeeTableHeader } from '../molecules/EmployeeTable/EmployeeTableHeader'
import { EmployeeTableBody } from '../molecules/EmployeeTable/EmployeeTableBody'
import { Employee, EmployeeKeys, EmployeeValues } from '../../services/EmployeeService'
import OptionsModal from '../molecules/OptionsModal'
import { EmployeeTableEditor } from '../molecules/EmployeeTable/EmployeeTableEditor'
import { EmployeeTableOptionsBody } from '../molecules/EmployeeTable/EmployeeTableOptionsBody'
import { SortOrderEnum } from '../../types/enums/sort-order.enum'
import { Role } from '../../types/definitions/employee'
import WarningIcon from '../atoms/Icons/WarningIcon.svg'
import { ToastMessage } from '../atoms/ToastMessage/ToastMessage'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../services/EventTrackingService'
import { getTeamsFromEmployees } from '../../utils/teams/teamsUtils'

interface EmployeeAdminTableParams {
  employees: Employee[]
  selectedSort?: { property: string; order: SortOrderEnum }
  onEdit: (employee: Employee) => Promise<void>
  onDelete: (employeeId: number) => Promise<void>
  onSortSelected?: (field: string, newSortOrder: SortOrderEnum) => void
}

interface UpdateResultType {
  message: string
  summary: string
  message_type: 'success' | 'info' | 'warn' | 'error'
}

const EmployeeAdminTable = ({
  employees,
  selectedSort,
  onEdit,
  onDelete,
  onSortSelected,
}: EmployeeAdminTableParams): JSX.Element => {
  const [editingRows, setEditingRows] = useState<{ [key: number | string]: boolean }>({})
  const [editingEmployees, setEditingEmployees] = useState<{ [key: string]: Employee }>({})
  const [showDeleteModal, setShowDeleteModal] = useState<number>()
  const [updateResultMessage, setUpdateResultMessage] = useState<UpdateResultType>({
    message: '',
    summary: '',
    message_type: 'success',
  })

  const handleEmployeeEdited = (employeeId: number, employeeField: EmployeeKeys, newValue: EmployeeValues): void => {
    // The typing of the following variable is to avoid problems with Typescript when assigning the new value
    const employeeEdited = editingEmployees[employeeId] as Record<EmployeeKeys, EmployeeValues>
    employeeEdited[employeeField] = newValue
    setEditingEmployees({ ...editingEmployees, [employeeId]: employeeEdited })
  }

  const handleRowEditChange = (event: DataTableRowEditEvent): void => {
    setEditingRows({ editingRows: event.data as unknown as boolean })
  }

  const handleToggleEdit = (employeeId: number): void => {
    if (editingRows[employeeId]) {
      delete editingRows[employeeId]
      setEditingRows({ ...editingRows })
    } else {
      trackEvent(TrackingCategoryEnum.ADMIN, TrackingCategoryEventEnum.ADMIN.CLICK_EDIT_ICON)
      const employeeToEdit = employees.find(e => e.id === employeeId)
      if (employeeToEdit) {
        setEditingEmployees({ ...editingEmployees, [employeeId]: JSON.parse(JSON.stringify(employeeToEdit)) })
        setEditingRows({ ...editingRows, [employeeId]: true })
      }
    }
  }

  const handleRowEditSave = async (employee: Employee): Promise<void> => {
    trackEvent(TrackingCategoryEnum.ADMIN, TrackingCategoryEventEnum.ADMIN.CLICK_SAVE_EDIT_ICON)
    const editedEmployee = editingEmployees[employee.id]
    if (editedEmployee) {
      try {
        await onEdit({ ...editedEmployee })
        setUpdateResultMessage({
          summary: 'Data updated',
          message: 'The changes were applied',
          message_type: 'success',
        })
      } catch (error) {
        setUpdateResultMessage({
          summary: 'Operation failed',
          message: "The data weren't updated",
          message_type: 'error',
        })
      } finally {
        handleToggleEdit(employee.id)
      }
    }
  }

  const handleRowEditCancel = (employee: Employee): void => {
    trackEvent(TrackingCategoryEnum.ADMIN, TrackingCategoryEventEnum.ADMIN.CLICK_CANCEL_EDIT_ICON)
    handleToggleEdit(employee.id)
  }

  const handleDeleteRow = async (employeeId: number): Promise<void> => {
    trackEvent(TrackingCategoryEnum.ADMIN, TrackingCategoryEventEnum.ADMIN.CLICK_DELETE_ICON)
    setShowDeleteModal(employeeId)
  }

  const handleDeleteModal = (): void => {
    trackEvent(TrackingCategoryEnum.ADMIN, TrackingCategoryEventEnum.ADMIN.CLICK_DELETE_ACTION)
    if (showDeleteModal) void onDelete(showDeleteModal)
    setShowDeleteModal(undefined)
  }

  const handleCloseModal = (): void => {
    trackEvent(TrackingCategoryEnum.ADMIN, TrackingCategoryEventEnum.ADMIN.CLICK_CANCEL_DELETE)
    setShowDeleteModal(undefined)
  }

  const handleSortSelected = (field: string) => (newSortOrder: SortOrderEnum) => {
    onSortSelected?.(field, newSortOrder)
  }

  const removeUpdateResultMessage = () => {
    setUpdateResultMessage({ message: '', summary: '', message_type: 'success' })
  }

  const teams = getTeamsFromEmployees(employees)

  return (
    <>
      <DataTable
        className="relative text-lg text-left"
        tableClassName="w-full background-white border-separate border-spacing-y-[8px] table-fixed"
        rowClassName={() => ({
          'bg-gray-cell border-2 border-solid cursor-pointer row-hover': true,
        })}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 30, 50, 500]}
        alwaysShowPaginator={false}
        value={employees}
        dataKey="id"
        editMode="row"
        editingRows={editingRows}
        onRowEditChange={handleRowEditChange}
      >
        <Column
          field="name"
          headerClassName="text-xl bg-transparent border-none lg:text-2xl py-0 lg:py-4"
          bodyClassName="align-middle text-left text-xl rounded-l-xl truncate"
          header={EmployeeTableHeader({
            title: 'Name',
            sortable: selectedSort?.property === 'name' ? { sortOrder: selectedSort.order } : undefined,
            onSortSelected: handleSortSelected('name'),
          })}
          editor={EmployeeTableEditor(editingEmployees, handleEmployeeEdited)}
          body={EmployeeTableBody()}
        />
        <Column
          field="email"
          headerClassName="text-xl bg-transparent border-none lg:text-2xl py-0 lg:py-4"
          bodyClassName="align-middle text-left text-xl truncate"
          header={EmployeeTableHeader({
            title: 'Email',
            sortable: selectedSort?.property === 'email' ? { sortOrder: selectedSort.order } : undefined,
            onSortSelected: handleSortSelected('email'),
          })}
          editor={EmployeeTableEditor(editingEmployees, handleEmployeeEdited)}
          body={EmployeeTableBody({ tooltip: true })}
        />
        <Column
          field="jobTitle"
          headerClassName="text-xl bg-transparent border-none lg:text-2xl py-0 lg:py-4"
          bodyClassName="align-middle text-left text-xl truncate"
          header={EmployeeTableHeader({
            title: 'Job Title',
            sortable: selectedSort?.property === 'jobTitle' ? { sortOrder: selectedSort.order } : undefined,
            onSortSelected: handleSortSelected('jobTitle'),
          })}
          editor={EmployeeTableEditor(editingEmployees, handleEmployeeEdited)}
          body={EmployeeTableBody()}
        />
        <Column
          field="role"
          headerClassName="text-xl bg-transparent border-none lg:text-2xl py-0 lg:py-4 w-1/12"
          bodyClassName="align-middle text-left text-xl w-1/12"
          header={EmployeeTableHeader({
            title: 'Role',
            sortable: selectedSort?.property === 'role' ? { sortOrder: selectedSort.order } : undefined,
            onSortSelected: handleSortSelected('role'),
          })}
          editor={EmployeeTableEditor(editingEmployees, handleEmployeeEdited, {
            possibleRoleValues: Object.values(Role).map(role => ({ value: role, label: role })),
            placeholder: 'Select a role',
          })}
          body={EmployeeTableBody()}
        />
        <Column
          field="teams"
          headerClassName="text-xl bg-transparent border-none lg:text-2xl py-0 lg:py-4"
          bodyClassName="align-middle text-left text-xl"
          header={EmployeeTableHeader({
            title: 'Team',
            sortable: selectedSort?.property === 'teams' ? { sortOrder: selectedSort.order } : undefined,
            onSortSelected: handleSortSelected('teams'),
          })}
          editor={EmployeeTableEditor(editingEmployees, handleEmployeeEdited, {
            possibleTeamsValues: teams.map(team => ({ value: team.id, label: team.name })),
            placeholder: 'Select teams',
          })}
          body={EmployeeTableBody()}
        />
        <Column
          field="leadersAsString"
          headerClassName="text-xl bg-transparent border-none lg:text-2xl py-0 lg:py-4"
          bodyClassName="align-middle text-left text-xl truncate"
          header={EmployeeTableHeader({
            title: 'Leader',
            sortable: selectedSort?.property === 'leader' ? { sortOrder: selectedSort.order } : undefined,
            onSortSelected: handleSortSelected('leader'),
          })}
          editor={EmployeeTableEditor(editingEmployees, handleEmployeeEdited)}
          body={EmployeeTableBody({ tooltip: true })}
        />
        <Column
          rowEditor
          headerClassName="bg-transparent border-none py-0 lg:py-4 w-1/12"
          bodyClassName="align-middle p-row-editor text-left rounded-r-xl w-1/12"
          body={EmployeeTableOptionsBody({
            rowEditSaveHandler: handleRowEditSave,
            rowEditCancelHandler: handleRowEditCancel,
            toggleEditHandler: handleToggleEdit,
            deleteRowHandler: handleDeleteRow,
          })}
        />
      </DataTable>
      {updateResultMessage.message && (
        <ToastMessage
          message={updateResultMessage.message}
          summary={updateResultMessage.summary}
          duration={3000}
          type={updateResultMessage.message_type}
          deleteMessage={removeUpdateResultMessage}
        />
      )}
      {!!showDeleteModal && (
        <OptionsModal
          visible={!!showDeleteModal}
          message={<p className={`modal-title`}>Are you sure you want to remove this employee?</p>}
          firstButtonText="Cancel"
          firstButtonType="clear"
          secondButtonText="Yes"
          secondButtonType="info"
          onFirstButtonClick={handleCloseModal}
          onSecondButtonClick={handleDeleteModal}
          onClose={handleCloseModal}
          icon={<WarningIcon />}
        />
      )}
    </>
  )
}

export default EmployeeAdminTable
