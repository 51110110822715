import { useSelector } from 'react-redux'
import { RootState } from '../../..'
import { checkReviewer, Employee, EmployeeDetails } from '../../../services/EmployeeService'
import CheckAdminSVG from '../../atoms/Icons/CheckAdmin.svg'
import CrossSVG from '../../atoms/Icons/Cross.svg'
import DocumentNavigationSVG from '../../atoms/Icons/DocumentNavigation.svg'
import GarbageSVG from '../../atoms/Icons/Garbage.svg'
import PencilSVG from '../../atoms/Icons/Pencil.svg'
import RoundedPlusIcon from '../../atoms/Icons/RoundedPlusIcon'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../../services/EventTrackingService'
import { Link } from 'react-router-dom'
import { ColumnPropsIndexed } from '../../organisms/ReviewTable'
import IntroductionTooltip from '../IntroductionTooltip'
import { ClockIcon } from '../../atoms/Icons/ClockIcon'
import { classNames } from 'primereact/utils'
import { getHoverColorForEmployee } from '../../../utils/hoverColorUtils'

type EmployeeFunction = (e: Employee) => void
type EmployeeFunctionPath = (e: Employee) => string
type EmployeeIdFunction = (id: number) => void

interface EmployeeTableOptionsBodyParams {
  isMetaSearch?: boolean
  rowEditSaveHandler?: EmployeeFunction
  rowEditCancelHandler?: EmployeeFunction
  toggleEditHandler?: EmployeeIdFunction
  deleteRowHandler?: EmployeeIdFunction
  createNewReview?: EmployeeFunctionPath
  handleEmployeeSelected?: EmployeeFunctionPath
  goToEmployeeReport?: EmployeeFunctionPath
  showBackgroundHover?: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const EmployeeTableOptionsBody = ({
  isMetaSearch,
  rowEditSaveHandler,
  rowEditCancelHandler,
  toggleEditHandler,
  deleteRowHandler,
  createNewReview,
  handleEmployeeSelected,
  goToEmployeeReport,
  showBackgroundHover,
}: EmployeeTableOptionsBodyParams) => {
  const user = useSelector<RootState, EmployeeDetails | null>(state => state.auth.user)
  return (rowData: Employee, props: ColumnPropsIndexed): JSX.Element => {
    const handleRowEditSave = (): void => {
      rowEditSaveHandler?.(rowData)
    }

    const handleRowEditCancel = (): void => {
      rowEditCancelHandler?.(rowData)
    }

    const handleToggleEdit = (): void => {
      toggleEditHandler?.(rowData.id)
    }

    const handleDeleteRow = (): void => {
      deleteRowHandler?.(rowData.id)
    }

    const newReview = (): string => {
      if (createNewReview) return createNewReview(rowData)
      return ''
    }

    const handleEmployeeData = (): string => {
      return handleEmployeeSelected?.(rowData) || ''
    }

    const getActionButtons = (rowData: Employee): unknown => {
      const actionButtonStyle = 'border-none flex justify-center items-center gap-4 rounded-md'
      const actionButtonHover = 'invisible lg:visible text-xl text-blue flex items-center pt-1 font-semibold'
      const backgroundHoverClassNames = showBackgroundHover
        ? 'clickable-hover-effect ' + getHoverColorForEmployee(rowData)
        : ''

      const handleSeeReport = () => {
        trackEvent(TrackingCategoryEnum.REVIEW_LIST, TrackingCategoryEventEnum.REVIEW_LIST.CLICK_EMPLOYEE_SEE_REPORT)
      }

      const handleContinueReview = () => {
        trackEvent(
          TrackingCategoryEnum.REVIEW_LIST,
          TrackingCategoryEventEnum.REVIEW_LIST.CLICK_EMPLOYEE_CONTINUE_REVIEW
        )
      }

      const handleStartReview = () => {
        trackEvent(TrackingCategoryEnum.REVIEW_LIST, TrackingCategoryEventEnum.REVIEW_LIST.CLICK_EMPLOYEE_START_REVIEW)
      }

      if (createNewReview && isReviewer && !rowData.lastOwnReview && !hasSkippedReviewByCurrentUser) {
        if (props.rowIndex <= 0) {
          return (
            <IntroductionTooltip id="introduction-step-5" placement="bottom-end">
              <Link to={newReview} className={classNames(actionButtonStyle, backgroundHoverClassNames)}>
                <RoundedPlusIcon />
                <span className={actionButtonHover}>Start</span>
              </Link>
            </IntroductionTooltip>
          )
        }
        return (
          <Link
            to={newReview}
            className={classNames(actionButtonStyle, backgroundHoverClassNames)}
            onClick={handleStartReview}
          >
            <RoundedPlusIcon width={19} height={19} />
            <span className={actionButtonHover}>Start</span>
          </Link>
        )
      }

      if (handleEmployeeSelected && rowData.lastOwnReview && !rowData.lastOwnReview?.finishDate) {
        return (
          <Link
            to={handleEmployeeData}
            className={classNames(actionButtonStyle, backgroundHoverClassNames)}
            onClick={handleContinueReview}
          >
            <PencilSVG width={16} height={16} />
            <span className={actionButtonHover}>Continue</span>
          </Link>
        )
      }

      if (
        (handleEmployeeSelected && (rowData.lastOwnReview?.finishDate || rowData.lastReview?.finishDate)) ||
        isMetaSearch
      ) {
        return (
          <Link
            to={handleEmployeeData}
            className={classNames(actionButtonStyle, backgroundHoverClassNames)}
            onClick={handleSeeReport}
          >
            <DocumentNavigationSVG />
            <span className={actionButtonHover}>See Report</span>
          </Link>
        )
      }

      if (handleEmployeeSelected && isReviewer && hasSkippedReviewByCurrentUser) {
        return (
          <Link
            to={newReview}
            className={classNames(actionButtonStyle, backgroundHoverClassNames)}
            onClick={handleStartReview}
          >
            <ClockIcon />
            <span className={actionButtonHover}>Start</span>
          </Link>
        )
      }

      if (handleEmployeeSelected && rowData.skippedReviews?.length) {
        return (
          <Link
            to={handleEmployeeData}
            className={classNames(actionButtonStyle, backgroundHoverClassNames)}
            onClick={handleSeeReport}
          >
            <ClockIcon />
            <span className={actionButtonHover}>More info</span>
          </Link>
        )
      }

      return (
        <div className={actionButtonStyle}>
          <DocumentNavigationSVG color="#979797" />
          <span className="invisible lg:visible --disabled text-xl text-toast-hover flex items-center">
            No report yet
          </span>
        </div>
      )
    }

    const rowEditor = props?.rowEditor as unknown
    const isEditing = (rowEditor as unknown as { editing: boolean })?.editing
    const isReviewer = checkReviewer(rowData, user as Employee)
    const hasSkippedReviewByCurrentUser = rowData.skippedReviews?.some(review => review.author.id === user?.id)

    return isEditing ? (
      <div className="flex items-center justify-start flex-wrap gap-2 h-full w-full relative bg-inactive-gray">
        <button
          onClick={handleRowEditSave}
          className="border-none flex justify-center items-center rounded-md bg-lime-green hover:bg-lime-green-hover duration-200 h-10 w-10"
        >
          <CheckAdminSVG />
        </button>
        <button
          onClick={handleRowEditCancel}
          className="border-none flex justify-center items-center rounded-md bg-error-red hover:bg-error-red-hover duration-200 h-10 w-10"
        >
          <CrossSVG />
        </button>
      </div>
    ) : (
      <div className="flex items-center justify-start flex-wrap gap-2 h-full w-full">
        {toggleEditHandler && (
          <button
            onClick={handleToggleEdit}
            className="border-none flex justify-center items-center rounded-md h-10 w-10"
          >
            <PencilSVG width={16} height={16} />
          </button>
        )}
        {deleteRowHandler && (
          <button
            onClick={handleDeleteRow}
            className="border-none flex justify-center items-center rounded-md h-10 w-10"
          >
            <GarbageSVG width={16} height={16} />
          </button>
        )}
        {(createNewReview || handleEmployeeSelected) && getActionButtons(rowData)}
      </div>
    )
  }
}
