import React, { useState, useLayoutEffect } from 'react'

export enum DirectionsEnum {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

const useIsOverflow = (
  ref: React.RefObject<HTMLElement>,
  direction?: DirectionsEnum,
  callback?: (arg: boolean) => void
): boolean => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false)

  useLayoutEffect(() => {
    const { current } = ref

    const checkOverflow = () => {
      const curr = current as HTMLElement
      const hasOverflow =
        direction === DirectionsEnum.HORIZONTAL
          ? curr.scrollWidth > curr.clientWidth
          : curr.scrollHeight > curr.clientHeight

      setIsOverflow(hasOverflow)

      if (callback) callback(hasOverflow)
    }

    if (current) {
      checkOverflow()
    }
  }, [callback, ref, direction])

  return isOverflow
}

export default useIsOverflow
