import { FC, useEffect, useState } from 'react'

import { AppSlider } from '../../../atoms/AppSlider/AppSlider'
import usePrevious from '../../../../hooks/usePrevious'
import { AppMarkdownViewer } from '../../../atoms/AppMarkdownViewer/AppMarkdownViewer'

export interface SliderQuestionProps {
  title: string
  description?: string
  value: number
  index: number
  min: number
  max: number
  minLabel: string
  maxLabel: string
  suggested?: number
  unfinished?: boolean
  required?: boolean
  onChange?: (value: number | undefined) => void
  onUpdateSuggested?: (value: number | undefined) => void
}

export const SliderQuestion: FC<SliderQuestionProps> = ({
  index,
  value,
  min,
  max,
  maxLabel,
  minLabel,
  title,
  description,
  suggested,
  unfinished,
  required = true,
  onChange,
  onUpdateSuggested,
}) => {
  const [currentValue, setCurrentValue] = useState<number>(value)
  const previous = usePrevious({ suggested, value })

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  useEffect(() => {
    if (suggested !== undefined && previous?.suggested === value && previous?.suggested !== suggested) {
      onChange?.(suggested)
    }
    onUpdateSuggested?.(suggested)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggested])

  const handleChange = (newValue: number | undefined): void => {
    onChange?.(newValue)
  }

  return (
    <div className="slider-question mt-[16px]">
      <div className="slider-question-body">
        <div className="slider-question-title text-blue font-semibold">
          <AppMarkdownViewer markdown={title} />
          {!required && <p className="optional-question ml-auto">Optional</p>}
        </div>
        <p className="text-2xl leading-8 mt-2 font-normal">
          <AppMarkdownViewer markdown={description || ''} />
        </p>
        <div className="slider-question-input">
          <AppSlider
            min={min}
            max={max}
            value={currentValue ?? 0}
            suggested={suggested}
            unfinished={unfinished}
            onChange={handleChange}
          />
          <div className="slider-question-labels pl-[2rem]">
            <div className="flex flex-col gap-4 mt-3">
              <span className="text-[1.6rem] font-bold text-blue">0</span>
              <span className="text-blue text-xl font-medium">{minLabel}</span>
            </div>
            <div className="flex flex-col gap-4 mt-3 items-end">
              <span className="text-[1.6rem] font-bold text-blue">100</span>
              <span className="text-blue text-xl font-medium">{maxLabel}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
