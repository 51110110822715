import { createContext, useEffect, useState } from 'react'
import { PeriodRequest } from '../../api/openapi'
import { PeriodService } from '../../services/PeriodService'
import { Period } from '../../types/definitions/Period'
import {
  DateToPeriodRequestDateString,
  checkDateRangeInclusiveOverlapsRange,
  periodToPeriodCard,
  sortPeriods,
} from '../../utils/periods/PeriodUtils'
import { AppButton } from '../atoms/AppButton/AppButton'
import { PlusIcon } from '../atoms/Icons/PlusIcon'
import Spinner from '../atoms/Spinner'
import PeriodCards, { PeriodCard } from '../organisms/PeriodManagement/PeriodCards'
import PeriodConfModal from '../organisms/PeriodManagement/PeriodConfModal'

interface PeriodContextType {
  createPeriod: (name: string, startDate: Date, endDate: Date) => Promise<void>
  updatePeriod: (id: number, name: string, startDate: Date, endDate: Date) => Promise<void>
  deletePeriod: (id: number) => Promise<void>
  pullPeriods: () => void
  checkRangeOverlapsPeriods: (startDate: Date, endDate: Date, periodIdToExclude?: number) => Period | undefined
}

export const PeriodContext = createContext<PeriodContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createPeriod: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updatePeriod: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deletePeriod: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  pullPeriods: () => {},
  checkRangeOverlapsPeriods: () => undefined,
})

const PeriodManagement = (): JSX.Element => {
  const [showCreatePeriodModal, setShowCreatePeriodModal] = useState(false)
  const [periodCards, setPeriodCards] = useState<PeriodCard[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    pullPeriods()
  }, [])

  const handleCloseCreatePeriodModal = () => {
    setShowCreatePeriodModal(false)
  }

  const createPeriod = async (name: string, startDate: Date, endDate: Date) => {
    await PeriodService.createPeriod({
      name: name,
      description: '',
      active: false,
      deleted: false,
      startDate: DateToPeriodRequestDateString(startDate),
      endDate: DateToPeriodRequestDateString(endDate),
    } as PeriodRequest)
  }

  const updatePeriod = async (id: number, name: string, startDate: Date, endDate: Date) => {
    await PeriodService.updatePeriod(id, {
      name: name,
      startDate: DateToPeriodRequestDateString(startDate),
      endDate: DateToPeriodRequestDateString(endDate),
    } as PeriodRequest)
  }

  const deletePeriod = async (id: number) => {
    await PeriodService.deletePeriod(id)
  }

  const pullPeriods = async () => {
    setLoading(true)
    await PeriodService.findAll()
      .then(allPeriods => setPeriodCards(sortPeriods(allPeriods).map(period => periodToPeriodCard(period))))
      .then(() => setLoading(false))
  }

  const checkRangeOverlapsPeriods = (
    startDate: Date,
    endDate: Date,
    periodIdToExclude?: number
  ): Period | undefined => {
    for (const periodCard of periodCards) {
      if (!periodIdToExclude || periodCard.period.id !== periodIdToExclude) {
        if (
          checkDateRangeInclusiveOverlapsRange(
            startDate,
            endDate,
            periodCard.period.startDate,
            periodCard.period.endDate
          )
        )
          return periodCard.period
        else return undefined
      }
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <PeriodContext.Provider
      value={{ createPeriod, updatePeriod, deletePeriod, pullPeriods, checkRangeOverlapsPeriods }}
    >
      <div className="container mx-auto mt-10">
        <div className="flex flex-row items-center justify-between mb-14">
          <span className="font-medium text-6xl leading-10">Manage periods</span>
          <div className="w-1/3 !max-w-[280px]">
            <AppButton onClick={() => setShowCreatePeriodModal(true)} type="clear">
              <PlusIcon />
              Create new assessment period
            </AppButton>
          </div>
        </div>
        <PeriodCards periods={periodCards} />
        {showCreatePeriodModal && <PeriodConfModal onClose={handleCloseCreatePeriodModal} />}
      </div>
    </PeriodContext.Provider>
  )
}

export default PeriodManagement
