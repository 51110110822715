import { MultiSelect } from 'primereact/multiselect'
import { FC, useEffect, useState } from 'react'

export interface AppMultiselectProps {
  placeholder?: string
  label?: string
  display: 'comma' | 'chip'
  values?: number[]
  options: { value: number; label: string }[]
  disabled?: boolean
  helpText?: boolean
  warningText?: string
  className?: string
  customSortFn?: (options: { value: number; label: string }[]) => void
  onChange?: (inputValue: number[] | undefined) => void
}

export const AppMultiselect: FC<AppMultiselectProps> = ({
  placeholder,
  options,
  label,
  values,
  disabled,
  helpText,
  warningText,
  className = '',
  onChange,
  customSortFn,
  display = 'chip',
}: AppMultiselectProps): JSX.Element => {
  const [selectedValues, setSelectedValues] = useState<number[]>([])
  const [zIndex, setZIndex] = useState<number>(0)

  useEffect(() => {
    if (values) setSelectedValues(values)
  }, [values])

  const handleChange = (newValues: number[]): void => {
    setSelectedValues(newValues)
    if (newValues.length > 0) {
      onChange?.(newValues)
    } else {
      onChange?.(undefined)
    }
  }

  if (customSortFn) {
    customSortFn(options)
  } else {
    options.sort((o1, o2) => o1.label.localeCompare(o2.label))
  }
  return (
    <div className={`app-multiselect-wrapper ${className}`} style={{ zIndex: zIndex }}>
      {label && <span className="app-multiselect-label text-dark-blue">{label}</span>}
      <MultiSelect
        className={`app-multiselect-select ${className}`}
        panelClassName="app-multiselect-select-panel"
        display={display}
        value={selectedValues}
        options={options}
        disabled={disabled}
        onShow={() => setZIndex(1)}
        onHide={() => setZIndex(1)}
        onChange={e => handleChange(e.value)}
        optionLabel="label"
        optionValue="value"
        placeholder={placeholder}
        panelHeaderTemplate={<div className="app-select-select-panel-header" />}
      />

      {warningText ? (
        <span>
          <p className="app-input-warning-text">{warningText}</p>
        </span>
      ) : (
        helpText && (
          <p className="app-input-warning-text">
            <br />
          </p>
        )
      )}
    </div>
  )
}
