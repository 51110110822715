import { FC, useEffect, useState } from 'react'
import { trackEvent, TrackingCategoryEnum, TrackingCategoryEventEnum } from '../../../services/EventTrackingService'
import { EmployeeOverview } from '../../../services/TeamService'
import { calculateChartDataTeamSummary } from '../../../utils/chartUtils'
import TeamSummaryChartRadar, { ChartDatasetType } from '../../atoms/Charts/TeamSummaryChartRadar'
import TeamSummaryResultsChartHeader from './TeamSummaryResultsChartHeader'

interface EmployeeScores extends EmployeeOverview {
  isGlobalScore: boolean
  score: number
}

interface TeamSummaryChartProps {
  teamEmployees: EmployeeScores[]
}

const TeamSummaryChart: FC<TeamSummaryChartProps> = ({ teamEmployees }) => {
  const [radarDataSet, setRadarDataSet] = useState<Record<string, ChartDatasetType>>({})
  const [radarLabels, setRadarLabels] = useState<string[]>([])

  useEffect(() => {
    if (teamEmployees) {
      const { labels, data } = calculateChartDataTeamSummary(teamEmployees)
      setRadarLabels(labels)
      setRadarDataSet(data)
    }
  }, [teamEmployees])

  const handleReviewToggled = (selectedReviewDate: string): void => {
    trackEvent(TrackingCategoryEnum.TEAM_REPORT, TrackingCategoryEventEnum.TEAM_REPORT.CLICK_CHART_SWITCHES, '')
    const selectedRadarDataSet = radarDataSet[selectedReviewDate]
    if (selectedRadarDataSet) selectedRadarDataSet.displayed = !selectedRadarDataSet?.displayed
    setRadarDataSet({ ...radarDataSet, [selectedReviewDate]: selectedRadarDataSet })
  }

  const handleDisplayAll = (display: boolean): void => {
    const dataSetCopy = { ...radarDataSet }
    for (const key in dataSetCopy) {
      if (dataSetCopy[key].values.length > 0 && dataSetCopy[key].reviewName !== 'Team Average')
        dataSetCopy[key].displayed = display
    }
    setRadarDataSet(dataSetCopy)
  }

  const getMemberReviewsText = (employees: EmployeeScores[]): string => {
    const amountWithReviews = employees.filter(e => e.lastReviewType != null).length || 0
    return `${amountWithReviews}/${employees.length - 1}`
  }

  return (
    <div className="items-center mx-auto">
      <div className="mt-5 py-5 text-2xl text-dark-blue font-semibold">
        Members assessed {getMemberReviewsText(teamEmployees)}
      </div>
      {!!Object.keys(radarDataSet).length && (
        <div className="mx-auto flex">
          <div className="employee-results__body-data w-[20%]">
            <TeamSummaryResultsChartHeader
              teamMembersReviews={Object.values(radarDataSet)}
              onChange={handleReviewToggled}
              display={handleDisplayAll}
            />
          </div>
          <div className="employee-results__body-chart flex-1 w-[80%] justify-center">
            <TeamSummaryChartRadar labels={radarLabels} datasets={Object.values(radarDataSet)} />
          </div>
        </div>
      )}
    </div>
  )
}

export default TeamSummaryChart
