import { FC, ReactElement } from 'react'

import { DraggableNode } from './DraggableNode'
import { EmployeeNode } from './EmployeeNode'
import { EmployeeData } from '../organisms/TeamChart'
import classNames from 'classnames'

interface DnDTeamProps {
  teamName: string
  employees: EmployeeData[]
  leaders?: boolean
  highlight?: number[]
  color?: string
}

export const DnDMembers: FC<DnDTeamProps> = ({
  teamName,
  leaders = false,
  employees,
  highlight,
  color = '#f8d65f',
}): ReactElement => {
  if (!employees.length) return <></>

  const employeeClassName = (id: number) => {
    const isSelected = highlight && highlight.indexOf(id) !== -1

    return classNames({
      'opacity-50': highlight && !isSelected,
      [`ring-offset-2 ring-2 ring-yellow`]: highlight && isSelected,
    })
  }

  return (
    <>
      {employees?.map(employee => (
        <DraggableNode key={employee.id} nodeId={employee.id} teamId={teamName} name={employee.name}>
          <EmployeeNode
            currentTeamColor={color}
            className={employeeClassName(employee.id)}
            modifier={leaders ? 'leader' : undefined}
            member={employee}
            teamData={employee.teamData}
          />
        </DraggableNode>
      ))}
    </>
  )
}
