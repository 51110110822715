import classnames from 'classnames'
import { FC, ReactElement, useState } from 'react'
import { useDrop } from 'react-dnd'

import { ItemTypes } from '../../constants/drag-n-drop'
import { DropArea } from '../atoms/DropArea'
import { DnDMembers } from '../molecules/DnDMembers'
import { EmployeeData } from './TeamChart'

interface DnDTeamProps {
  teamId: number
  teamName: string
  employees: EmployeeData[]
  leaders: EmployeeData[]
  color?: string
  highlight?: number[]
}

export const DnDTeam: FC<DnDTeamProps> = ({ teamId, teamName, employees, leaders, color, highlight }): ReactElement => {
  const [collapsed, setCollapsed] = useState(false)
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.TEAM_NODE,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    // TODO allow drag&drop when the time comes
    canDrop: (/* item: DropResult */) => false, // !members.some(member => member.id === item.nodeId),
  }))

  const isActive = canDrop && isOver
  const wrapperClass = classnames({
    'org-team': true,
    'dnd-zone': true,
    'dnd-zone--blocked': isOver && !canDrop,
  })

  const chevronClass = classnames({
    pi: true,
    [`pi-chevron-${collapsed ? 'up' : 'down'}`]: true,
  })

  return (
    <div ref={drop} className={wrapperClass}>
      <div
        className="org-header"
        style={{ backgroundColor: color ? color : '#f2f2f2' }}
        onClick={() => setCollapsed(!collapsed)}
      >
        {teamName}
        <button className="org-team__toggler">
          <i className={chevronClass} />
        </button>
      </div>
      {isActive ? (
        <div className={`dnd-container dnd-container--${isActive ? 'active' : 'inactive'}`}>
          <DropArea type="lead" dropId={teamId} name={teamName} />
        </div>
      ) : null}
      {!collapsed || isOver ? (
        <DnDMembers leaders teamName={teamName} employees={leaders} highlight={highlight} color={color} />
      ) : null}
      {isActive ? (
        <div className={`dnd-container dnd-container--${isActive ? 'active' : 'inactive'}`}>
          <DropArea type="move" dropId={teamId} name={teamName} />
          <DropArea type="add" dropId={teamId} name={teamName} />
        </div>
      ) : null}
      {!collapsed || isOver ? (
        <DnDMembers teamName={teamName} employees={employees} highlight={highlight} color={color} />
      ) : null}
    </div>
  )
}
