import { teamAPI, usageAPI } from '../api'
import {
  EmployeeInfoResponse,
  EmployeeOverviewProjection,
  TeamAssessmentsReportResponse,
  TeamDeepResponse,
  TeamInfoResponse,
  TeamResponse,
  TeamStatusDTO,
  TeamTreeResponse,
  UpdateTeamRequest,
  UsageReportSummaryResponse,
} from '../api/openapi'
import { SortBy, sort } from '../utils/sortUtils'
import { simpleTextCompareFn } from '../utils/text'

export type EmployeeInfo = EmployeeInfoResponse
export type Team = TeamTreeResponse
export type TeamInfo = TeamInfoResponse
export type TeamStatus = TeamStatusDTO
export type EmployeeOverview = EmployeeOverviewProjection
export type TeamUpdate = UpdateTeamRequest

export interface TeamDetails extends TeamDeepResponse {
  employees: EmployeeInfo[]
  leaders: EmployeeInfo[]
}

export interface TeamAssessmentsReportResponseExtended extends TeamAssessmentsReportResponse {
  notStarted?: number
  previousProgress?: number
  hasChildren?: boolean
}

export const addHasChildren = (
  teams: TeamAssessmentsReportResponseExtended[]
): TeamAssessmentsReportResponseExtended[] => {
  return teams.map(team => {
    return { ...team, hasChildren: teams.some(t => t.parentId === team.id) }
  })
}

const flattenTeams = (teams: TeamTreeResponse[]): TeamTreeResponse[] => {
  let flatTeams: TeamTreeResponse[] = []

  teams.forEach(team => {
    flatTeams.push(team)

    if (team.children) {
      flatTeams = [...flatTeams, ...flattenTeams(team.children)]
    }
  })

  return flatTeams
}

const TeamStatusCustomSortFn: Partial<Record<string, (item1: TeamStatus, item2: TeamStatus) => number>> = {
  issues: (item1, item2) =>
    (item2.mismatches || 0) + (item2.flightRisks || 0) > (item1.mismatches || 0) + (item1.flightRisks || 0) ? 1 : -1,
  score: (item1, item2) => ((item2.score || 0) > (item1.score || 0) ? 1 : -1),
  finished: (item1, item2) => ((item2.finished || 0) > (item1.finished || 0) ? 1 : -1),
  leaders: (item1, item2) => {
    const item1Leaders = item1.leaders || []
    const item2Leaders = item2.leaders || []
    return simpleTextCompareFn(
      Array.from(item1Leaders.values()).join(', '),
      Array.from(item2Leaders.values()).join(', ')
    )
  },
}

export class TeamService {
  static async findAllDetailed(): Promise<TeamDetails[]> {
    return teamAPI.findDeepUsingGET().then(response => {
      return response.data as TeamDetails[]
    })
  }

  static async findAll(): Promise<Team[]> {
    return teamAPI.findUsingGET5().then(response => {
      return response.data as Team[]
    })
  }

  static async findAllFlat(): Promise<Team[]> {
    return this.findAll().then(teams => {
      return flattenTeams(teams)
    })
  }

  static async findOne(teamId: number): Promise<TeamResponse> {
    return teamAPI.getByIdUsingGET2(teamId).then(response => {
      return response.data as TeamResponse
    })
  }

  static async findTeamStatus(): Promise<Set<TeamStatus>> {
    return teamAPI.findTeamStatusesUsingGET().then(response => {
      return response.data as Set<TeamStatus>
    })
  }

  static async findTeamInfo(teamId: number): Promise<TeamInfoResponse> {
    return teamAPI.getTeamInfoByIdUsingGET(teamId).then(response => {
      return response.data as TeamResponse
    })
  }

  static async findRootTeamsId() {
    return teamAPI
      .getRootTeamUsingGET()
      .then(({ data }) => data as TeamResponse[])
      .then(teams => teams.map(team => team.id))
  }

  static sortTeamStatus(records: TeamStatus[], sorting: SortBy[]): TeamStatus[] {
    return sort<TeamStatus>(records, sorting, TeamStatusCustomSortFn)
  }

  static async findTeamMembers(teamId: number): Promise<EmployeeOverview[]> {
    return teamAPI.getTeamEmployeesUsingGET(teamId).then(response => {
      return Array.from(response.data.values()) as EmployeeOverview[]
    })
  }

  static async updateTeamName(teamId: number, newTeamName: string) {
    await teamAPI.updateTeamNameUsingPUT(teamId, newTeamName)
  }

  static async getUsageReportSummary(before: string, after: string): Promise<UsageReportSummaryResponse> {
    return usageAPI.usageReportSummaryUsingGET(before, after).then(response => {
      return response.data as UsageReportSummaryResponse
    })
  }

  static async getTeamAssessments(before: string, after: string): Promise<TeamAssessmentsReportResponseExtended[]> {
    return usageAPI.findTeamStatusesUsingGET1(before, after).then(response => {
      const updatedData: TeamAssessmentsReportResponseExtended[] = Array.from(response.data.values())
      updatedData.map(
        teamData =>
          (teamData.notStarted =
            (teamData.teamSize as number) -
            ((teamData.finished as number) + (teamData.skipped as number) + (teamData.unfinished as number)))
      )
      return updatedData as TeamAssessmentsReportResponseExtended[]
    })
  }
}
