import { useRef, useState } from 'react'
import { EmployeeService } from '../../services/EmployeeService'
import { MessageIcon, MessageIconlarge } from '../atoms/Icons/MessageIcon'
import WarningIcon from '../atoms/Icons/WarningIcon.svg'
import Spinner from '../atoms/Spinner'
import ErrorModal from './ErrorModal'
import OptionsModal from './OptionsModal'
import SuccessModal from './SuccessModal'
import { CONTACT_EMAIL } from '../../constants/general'

const EMAIL = `mailto:${CONTACT_EMAIL}`

export const SuggestionsBox = () => {
  const [showSuggestionsModal, setShowSuggestionsModal] = useState<boolean>(false)
  const [showCloseConfirmation, setShowCloseConfirmation] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [showEmptyMessageModal, setShowEmptyMessageModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [lastMessage, setLastMessage] = useState<string>('')
  const suggestionRef = useRef<HTMLTextAreaElement | null>(null)

  const SuggestionsBoxTemplate = () => {
    return (
      <div className="w-[87%] mx-auto">
        <p className="text-[1.8rem] leading-[2.6rem] mb-4">
          If you have any suggestions, questions, or have experienced any issues with Grapes, we’d love to know. Your
          feedback is gold to us!
        </p>
        <h3 className="text-left text-2xl font-bold leading-6 mb-4">Message</h3>
        <div className="app-textarea-wrapper">
          <textarea
            ref={suggestionRef}
            className="app-textarea-textarea app-textarea-textarea--suggestion resize-none"
            placeholder="Write your message here and we’ll get back to you ASAP"
          >
            {lastMessage}
          </textarea>
        </div>
      </div>
    )
  }

  const successModalMessage = !isLoading ? (
    <p>
      <span className="text-[2.5rem]">Suggestion successfully sent.</span>
      <br />
      <span className="text-stone-gray">Our team will look at your suggestions carefully.</span>
    </p>
  ) : (
    <div className="flex flex-1 justify-center mt-32">
      <Spinner />
    </div>
  )

  // An uncontrolled textarea was needed because, when inside a modal, Primereact InpuTextarea was losing focus every time the input changes.
  const handleSend = async () => {
    setShowSuggestionsModal(false)
    const message = suggestionRef?.current?.value
    setLastMessage(message ?? '')
    if (!message?.length) {
      setShowEmptyMessageModal(true)
    } else {
      setIsLoading(true)
      setShowSuccessModal(true)
      try {
        message && (await EmployeeService.createSuggestion({ message: message }))
      } catch (error) {
        setShowSuccessModal(false)
        setShowErrorModal(true)
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false)
  }

  const closeSuggestionBox = () => {
    const message = suggestionRef?.current?.value || ''
    setLastMessage(message)
    if (message) {
      setShowSuggestionsModal(false)
      setShowCloseConfirmation(true)
    } else {
      setShowSuggestionsModal(false)
      setShowCloseConfirmation(false)
    }
  }

  const returnToSuggestionBox = () => {
    setShowCloseConfirmation(false)
    setShowSuggestionsModal(true)
  }

  const closeConfirmationAndSuggestion = () => {
    setShowCloseConfirmation(false)
    setShowSuggestionsModal(false)
    setLastMessage('')
  }

  return (
    <>
      <div className="flex-1 flex justify-end items-center">
        <div
          className="flex items-center gap-1 cursor-pointer text-blue text-xl font-bold hover:underline"
          onClick={() => setShowSuggestionsModal(!showSuggestionsModal)}
        >
          <MessageIcon />
          Suggestions
        </div>
      </div>
      {showCloseConfirmation && (
        <OptionsModal
          icon={<WarningIcon width={67} height={72}></WarningIcon>}
          visible={showCloseConfirmation}
          message={
            <div>
              <p className="text-[3.4rem] font-normal w-[50%] mb-8 mx-auto">Are you sure you want to close?</p>
              <p className="text-[1.8rem] font-bold">Your message has not been sent and will be lost.</p>
            </div>
          }
          firstButtonText="No, go back."
          firstButtonType="clear"
          secondButtonText="Yes"
          secondButtonType="info"
          onFirstButtonClick={returnToSuggestionBox}
          onSecondButtonClick={closeConfirmationAndSuggestion}
          onClose={returnToSuggestionBox}
        />
      )}
      {showSuggestionsModal && (
        <OptionsModal
          icon={<MessageIconlarge />}
          visible={showSuggestionsModal}
          message={<SuggestionsBoxTemplate />}
          firstButtonText="Close"
          firstButtonType="clear"
          secondButtonText="Send"
          secondButtonType="info"
          onFirstButtonClick={closeSuggestionBox}
          onSecondButtonClick={handleSend}
          onClose={closeSuggestionBox}
        />
      )}
      {showErrorModal && (
        <ErrorModal
          icon={<WarningIcon />}
          visible={showErrorModal}
          message={
            <p>
              <span className={`modal-title`}>Something went wrong while sending your suggestion.</span>
              <br />
              <span className="text-grey">
                <b>Try reloading the page and send it again.</b> If the problem persists,{' '}
                <a href={EMAIL}>contact support</a>.
              </span>
            </p>
          }
          onClose={() => {
            setShowErrorModal(false)
            setShowSuggestionsModal(true)
          }}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          visible={showSuccessModal}
          onClose={handleCloseSuccessModal}
          loading={isLoading}
          message={successModalMessage}
        />
      )}
      {showEmptyMessageModal && (
        <ErrorModal
          icon={<WarningIcon />}
          visible={showEmptyMessageModal}
          message={
            <p>
              <span className={`modal-title`}>Your suggestion message is empty</span>
              <br />
              <span className="text-grey">
                <b>Please submit a non-empty message.</b>
              </span>
            </p>
          }
          onClose={() => {
            setShowEmptyMessageModal(false)
            setShowSuggestionsModal(true)
          }}
        />
      )}
    </>
  )
}
