import { FC } from 'react'
import classNames from 'classnames'

export interface AppSmallButtonProps {
  type?: AppSmallButtonType
  disabled?: boolean
  className?: string
  onClick: () => void
}

export type AppSmallButtonType = 'info' | 'error' | 'clear'

export const AppSmallButton: FC<AppSmallButtonProps> = ({
  onClick,
  children,
  type = 'info',
  disabled = false,
  className,
}) => {
  className = classNames({
    'w-[30px] h-[30px] flex justify-center items-center text-2xl font-semibold border-none rounded-xl duration-200':
      true,
    'text-white bg-blue hover:bg-hover-blue': type === 'info' && !disabled,
    'text-white text-opacity-50 bg-disabled-blue cursor-not-allowed': type === 'info' && disabled,
    'text-blue bg-pale-blue hover:bg-pale-blue-hover': type === 'clear' && !disabled,
    'text-blue text-opacity-50 bg-pale-blue cursor-not-allowed': type === 'clear' && disabled,
    'text-white bg-red-button hover:bg-red-button-hover': type === 'error' && !disabled,
    'text-white text-opacity-50 bg-red-button-disabled cursor-not-allowed': type === 'error' && disabled,
    [className ? className : '']: true,
  })

  return (
    <button
      className={className}
      disabled={disabled}
      onClick={e => {
        onClick()
        e.stopPropagation()
      }}
    >
      {children}
    </button>
  )
}
