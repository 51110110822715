//================================
// Index (Search using CTRL + F)
//
// 0. Common Settings & Interfaces
// 1. Calculations
// 2. Painting
// 3. Plugin
//================================

import { Chart, Plugin } from 'chart.js'

import { drawText, Font, font, widthOf } from '../canvasUtils'
import { calcInboundsLabelPositionOf, calcLabelPositionsOf, colorsOf, ctxOf, datasetsOf, labelsOf } from '../chartUtils'
import { point, Point } from '../mathUtils'

//================================
// 0. Common Settings & Interfaces
//================================

const loadCTXSettings = (ctx: CanvasRenderingContext2D, font: Font): CanvasRenderingContext2D => {
  ctx.font = font.style + ' ' + font.size + 'px ' + font.family
  ctx.textBaseline = 'top'
  ctx.textAlign = 'left'
  return ctx
}

type CustomLabel = {
  position: Point
  title: {
    position: Point
    text: string
    color: string
    font: Font
  }
  body: {
    position: Point
    values: string[]
    colors: string[]
    separator: string
    font: Font
    comparable: boolean
  }
}

//================================
//        1. Calculations
//================================

const valuesOf = (chart: Chart, valuesIndex: number): string[] => {
  const datasets = datasetsOf(chart)
  return datasets.length > 0 ? formatValues(datasetsOf(chart).map(dataset => dataset[valuesIndex])) : ['']
}

const formatValues = (values: number[]): string[] => {
  return values.map(value => (value >= 10 ? String(value) : String('0' + value)))
}

const calcHeightOf = (rows: number, lineHeight: number, pxBetweenRows: number): number => {
  return rows * (lineHeight + pxBetweenRows) - pxBetweenRows
}

const calcLabelHeight = (labelHeight: number, bodyHeight: number, pxBetweenRows: number): number => {
  return labelHeight + pxBetweenRows + bodyHeight
}

const calcValuesWidth = (ctx: CanvasRenderingContext2D, values: string[]): number => {
  return values.reduce((remainder, v) => widthOf(ctx, v) + remainder, 0)
}

const calcSeparatorsWidth = (ctx: CanvasRenderingContext2D, separator: string, valueAmount: number): number => {
  return widthOf(ctx, separator) * (valueAmount - 1)
}

const calcBodyWidth = (ctx: CanvasRenderingContext2D, values: string[], separator: string): number => {
  return calcValuesWidth(ctx, values) + calcSeparatorsWidth(ctx, separator, values.length)
}

const calcTitlePos = (titleWidth: number): Point => {
  return point(-titleWidth / 2, 0)
}

const calcBodyPos = (bodyWidth: number, titleHeight: number, pxBetweenRows: number): Point => {
  return point(-bodyWidth / 2, titleHeight + pxBetweenRows)
}

// calcStartingPos() will return the top-middle position of the custom label
// where we should start painting in order to render a centered label
const calcStartingPos = (labelPosition: Point, labelHeight: number): Point => {
  return point(labelPosition.x, labelPosition.y - labelHeight / 2)
}

const customLabel = (
  chart: Chart,
  chartJSLabel: Point,
  pxBetweenRows: number,
  titleText: string,
  titleFont: Font,
  bodySeparator: string,
  bodyValues: string[],
  bodyColors: string[],
  bodyFont: Font,
  comparable: boolean
): CustomLabel => {
  const ctx = ctxOf(chart)

  // without loadCTXSettings() the widthOf methods wouldn't work properly
  ctx.save()
  loadCTXSettings(ctx, titleFont)

  // line calculations
  // TODO: Implement a word wrapper in case the title or body is too wide
  // It can be rendered in one line without exceeding the canvas limits
  const rowsForTitle = 1
  const rowsForBody = 1

  // height
  const titleHeight = calcHeightOf(rowsForTitle, titleFont.size, pxBetweenRows)
  const bodyHeight = calcHeightOf(rowsForBody, bodyFont.size, pxBetweenRows)
  const labelHeight = calcLabelHeight(titleHeight, bodyHeight, pxBetweenRows)

  // width
  const titleWidth = widthOf(ctx, titleText)
  const bodyWidth = calcBodyWidth(ctx, bodyValues, bodySeparator)
  const labelWidth = Math.max(titleWidth, bodyWidth)

  // positions
  const unsafeLabelPos = calcStartingPos(chartJSLabel, labelHeight)
  const labelPos = calcInboundsLabelPositionOf(unsafeLabelPos, labelWidth, labelHeight, chart.width, chart.height)
  const titlePos = calcTitlePos(titleWidth)
  const bodyPos = calcBodyPos(bodyWidth, titleHeight, pxBetweenRows)

  const result = {
    position: labelPos,
    title: {
      text: titleText,
      position: titlePos,
      color: '#00112F',
      font: titleFont,
    },
    body: {
      values: bodyValues,
      colors: bodyColors,
      separator: bodySeparator,
      position: bodyPos,
      font: bodyFont,
      comparable: comparable,
    },
  }
  ctx.restore()
  return result
}

const customLabelsOf = (chart: Chart): CustomLabel[] => {
  const chartJSLabelPosition = calcLabelPositionsOf(chart)
  const labelAmount = chartJSLabelPosition.length

  const result: CustomLabel[] = [...Array(labelAmount)].map((_, i) => {
    // common values
    const titleFont = font(14, 'Manrope', '500')
    const bodyFont = font(14, 'Manrope', '600')
    const pxBetweenRows = 5

    // texts to render
    const titleText = labelsOf(chart)[i]
    const bodySeparator = ' · '
    const bodyValues = valuesOf(chart, i)
    const bodyColors = colorsOf(chart)
    const comparable = chart.config.data.datasets.every(data => data?.compare) || false

    return customLabel(
      chart,
      chartJSLabelPosition[i],
      pxBetweenRows,
      titleText,
      titleFont,
      bodySeparator,
      bodyValues,
      bodyColors,
      bodyFont,
      comparable
    )
  })

  if (result.every(r => r.body.comparable === true && r.body.values.length === 2)) {
    result.forEach(r => {
      r.body.separator = ' < '
      r.body.colors[1] = '#979797'
      r.body.colors[0] =
        r.body.values[0] > r.body.values[1] ? '#00BE4F' : r.body.values[0] < r.body.values[1] ? '#FE3B3B' : '#00112F'
    })
  }

  return result
}

//================================
//         2. Painting
//================================

const paintLabelTitle = (ctx: CanvasRenderingContext2D, label: CustomLabel): void => {
  const { title } = label
  ctx.save()
  ctx.translate(label.position.x, label.position.y)
  drawText(ctx, title.text, title.position, title.color, title.font)
  ctx.restore()
}

const paintLabelValues = (ctx: CanvasRenderingContext2D, label: CustomLabel): void => {
  const { title, body } = label
  const { values, colors, separator, font } = body

  ctx.save()
  ctx.translate(label.position.x, label.position.y)
  ctx.translate(body.position.x, body.position.y)

  drawText(ctx, values[0], point(0, 0), colors[0], font)

  values.slice(1).forEach((value, i) => {
    const valueWidth = widthOf(ctx, values[i])
    ctx.translate(valueWidth, 0)

    drawText(ctx, separator, point(0, 0), title.color, font)
    ctx.translate(widthOf(ctx, separator), 0)

    drawText(ctx, value, point(0, 0), colors[i + 1], font)
  })

  ctx.restore()
}

const paintLabel = (chart: Chart, label: CustomLabel): void => {
  const ctx = ctxOf(chart)

  ctx.save()
  loadCTXSettings(ctx, label.title.font)
  paintLabelTitle(ctx, label)
  paintLabelValues(ctx, label)
  ctx.restore()
}

//================================
//          3. Plugin
//================================

const customLabelPlugin: Plugin = {
  id: 'customLabelPlugin',
  afterDraw: function (chart: Chart) {
    customLabelsOf(chart).forEach(customLabel => paintLabel(chart, customLabel))
  },
}

// NOTE - customLabelPlugin currently only works for radial axis
export default customLabelPlugin
