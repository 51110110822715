import { CheckIcon } from '../atoms/Icons/CheckIcon'
import Message from './Message'

interface SuccessMessageProps {
  title?: React.ReactNode
  body: React.ReactNode
  icon?: React.ReactNode
  className?: string
}
const SuccessMessage = ({ title, body, icon = <CheckIcon />, className }: SuccessMessageProps): JSX.Element => {
  return <Message icon={icon} title={title} body={body} className={className} />
}

export default SuccessMessage
