import { FailIcon } from '../atoms/Icons/FailIcon'
import Message from './Message'

interface ErrorMessageProps {
  body: React.ReactNode
  title?: React.ReactNode
  icon?: React.ReactNode
  className?: string
}
const ErrorMessage = ({ body, icon = <FailIcon />, title, className }: ErrorMessageProps): JSX.Element => {
  return <Message body={body} icon={icon} title={title} className={className} />
}

export default ErrorMessage
