import { FC } from 'react'

interface CrossButtonProps {
  onClick: () => void
}

export const CrossButton: FC<CrossButtonProps> = ({ onClick }) => {
  return (
    <button className="flex justify-end">
      <div onClick={onClick} className="cursor-pointer">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1L1 13M13 13L1 1" stroke="#00112F" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </div>
    </button>
  )
}
