import { FC, useEffect, useState } from 'react'
import { AppValueSelect } from '../../../atoms/AppValueSelect/AppValueSelect'

export interface RateQuestionProps {
  label: string
  options: string[]
  selectedOption?: string
  required?: boolean
  onChange?: (value: string) => void
}

export const RateQuestion: FC<RateQuestionProps> = ({
  label,
  options,
  selectedOption = '',
  required = true,
  onChange,
}) => {
  const [selectValue, setSelectValue] = useState<string>(selectedOption)

  useEffect(() => {
    setSelectValue(selectedOption)
  }, [selectedOption])

  const handleChange = (value: string): void => {
    setSelectValue(value)
    onChange?.(value)
  }

  return (
    <div className="mr-4">
      <AppValueSelect
        value={selectValue}
        label={label}
        options={options.map(option => ({ value: option, label: option }))}
        onChange={handleChange}
      />
    </div>
  )
}
