import { cloneDeep } from 'lodash'
import { employeeAPI, ReviewApi, ReviewRequestApi } from '../api'
import { ReviewCountResponse, ReviewRequestResponse, ReviewWithAnswersResponse } from '../api/openapi'
import { EMPLOYEE_STATUS_DONE, EMPLOYEE_STATUS_TODO, EMPLOYEE_STATUS_UNFINISHED } from '../constants/employee'
import { AnsweredQuestion } from '../types/definitions/question'
import { Review } from '../types/definitions/review'
import { QuestionService } from './QuestionService'
import { sort, SortBy } from '../utils/sortUtils'
import { namedObjectsArrayCompareFn } from '../utils/objectUtils'

export const reviewWithAnswerResponseToReview = (reviewWithAnswersResponse: ReviewWithAnswersResponse): Review => {
  const { id, ...reviewData } = cloneDeep(reviewWithAnswersResponse)
  return {
    ...reviewData,
    id: id,
    createdDate: `${reviewData.createdDate}`,
    lastModifiedDate: `${reviewData.lastModifiedDate}`,
    reviewAuthor: { ...reviewData.reviewAuthor, id: reviewData.reviewAuthor.id },
    reviewedEmployee: { ...reviewData.reviewedEmployee, id: reviewData.reviewedEmployee.id },
    answers: (reviewData.answers || []).map(({ question, answer, skipped, suggestedAnswer }) => {
      const parsedQuestion = QuestionService.questionResponseToQuestion(question)
      let parsedAnswer
      try {
        parsedAnswer = JSON.parse(answer)
      } catch (error) {
        parsedAnswer = answer
      }
      return { ...parsedQuestion, answer: parsedAnswer, skipped, suggestedAnswer } as unknown as AnsweredQuestion
    }),
  }
}

export interface AnswerToBeAddedToReview {
  answer: string
  suggestedAnswer?: string
  question: number
  review: number
  skipped: boolean
}

export interface ReviewRequest extends ReviewRequestResponse {
  status?: string
  weight?: number
}

const getReviewRequestStatus = (reviewRequest: ReviewRequestResponse) => {
  const { review } = reviewRequest

  if (!review.answers.length) return EMPLOYEE_STATUS_TODO

  if (!review.finishDate) return EMPLOYEE_STATUS_UNFINISHED

  return EMPLOYEE_STATUS_DONE
}

const mapReviewRequest = (reviewRequest: ReviewRequestResponse) => {
  const status = getReviewRequestStatus(reviewRequest)
  let weight = 0
  switch (status) {
    case EMPLOYEE_STATUS_TODO:
      weight = 0
      break
    case EMPLOYEE_STATUS_UNFINISHED:
      weight = 1
      break
    case EMPLOYEE_STATUS_DONE:
    default:
      weight = 2
      break
  }
  return {
    ...reviewRequest,
    status,
    weight,
  }
}

const ReviewRequestCustomSortFn: Partial<Record<string, (item1: ReviewRequest, item2: ReviewRequest) => number>> = {
  teams: (item1, item2) =>
    namedObjectsArrayCompareFn(item1.reviewedEmployee.teams || [], item2.reviewedEmployee.teams || []),
}

export class ReviewService {
  static async countReviewedEmloyees(): Promise<ReviewCountResponse> {
    return ReviewApi.countReviewsUsingGET().then(response => {
      return response.data as ReviewCountResponse
    })
  }

  static async findAll(): Promise<Review[]> {
    return ReviewApi.findReviewsUsingGET().then(response => {
      return (response.data as ReviewWithAnswersResponse[]).map(reviewwithAnswerResponse =>
        reviewWithAnswerResponseToReview(reviewwithAnswerResponse)
      )
    })
  }

  static async findOne(reviewId: number): Promise<Review> {
    return ReviewApi.getByIdUsingGET1(reviewId).then(axiosResponse => {
      return reviewWithAnswerResponseToReview(axiosResponse.data)
    })
  }

  static async findByEmployeeId(employeeId: number): Promise<Review[]> {
    // FIXME: The use of local time must be fixed
    const beforeDate = new Date(Date.now() + 1000 * 60 * 60 * 24).toISOString().slice(0, -1)
    return employeeAPI.getReviewsByReviewedEmployeeUsingGET(beforeDate, employeeId).then(axiosResponse => {
      return axiosResponse.data.map(reviewResponse => reviewWithAnswerResponseToReview(reviewResponse))
    })
  }

  static async findSelfReview(): Promise<Review> {
    return ReviewApi.getSelfReviewUsingGET().then(axiosResponse => {
      return reviewWithAnswerResponseToReview(axiosResponse.data)
    })
  }

  static async findSelfReviewByEmployeeId(employeeId: number): Promise<Review> {
    return ReviewApi.getSelfReviewOfEmployeeUsingGET(employeeId).then(axiosResponse => {
      return reviewWithAnswerResponseToReview(axiosResponse.data)
    })
  }

  static createEmptyReview(reviewedEmployee: number, form: number): Promise<number> {
    return ReviewApi.createReviewUsingPOST({ reviewedEmployee, form }).then(({ data }) => {
      return data.id
    })
  }

  static createReviewRequest(requestedTo: number, employeeToReview: number, formId: number): Promise<number> {
    return ReviewRequestApi.createReviewRequestUsingPOST({ requestedTo, employeeToReview, formId }).then(({ data }) => {
      return data.id
    })
  }

  static getReviewRequests(): Promise<ReviewRequest[]> {
    return ReviewRequestApi.getReviewRequestsUsingGET().then(({ data }) => {
      return data.map(mapReviewRequest)
    })
  }

  static resetReview(id: number, formId: number): Promise<number> {
    return ReviewApi.changeReviewFormUsingPUT(id, { id: id, formId }).then(({ data }) => {
      return data.id
    })
  }

  static async saveAnswers(reviewId: number, answers: AnswerToBeAddedToReview[]): Promise<void> {
    await ReviewApi.addAnswerToReviewUsingPUT(reviewId, { answers })
  }

  static async finishReview(reviewId: number): Promise<void> {
    await ReviewApi.finishReviewUsingPOST(reviewId)
  }

  static async deleteReview(reviewId: number): Promise<void> {
    await ReviewApi.deleteReviewUsingDELETE(reviewId)
  }

  static sortReviewRequests(records: ReviewRequest[], sorting: SortBy[]): ReviewRequest[] {
    return sort<ReviewRequest>(records, sorting, ReviewRequestCustomSortFn)
  }

  static async skipAssessment(employeeId: string, reason: string, notes: string): Promise<void> {
    const createReviewSkippedRequest = {
      skippedEmployee: Number(employeeId),
      reason,
      notes,
    }
    await ReviewApi.createReviewSkippedUsingPOST(createReviewSkippedRequest)
  }
}
