import React, { FC } from 'react'
import { AppButton } from '../../atoms/AppButton/AppButton'

interface TechProfileStartCardProps {
  onStart: () => void
}

const TechProfileStartCard: FC<TechProfileStartCardProps> = ({ onStart }) => {
  return (
    <div className="w-1/2 justify-between items-center p-20 shadow-default rounded-2xl">
      <div className="w-3/4 h-3/4 mb-10">
        <span className="text-4xl font-bold text-dark-blue">🛠️ Technical profile</span>
        <span className="text-3xl text-gray block mt-8 leading-snug">
          Build your very own profile to <b>keep track of your technical knowledge</b> and growth over time. Share what
          you'd <b>like to learn</b>, and what you'd <b>love to teach.</b>
          <br />
          <br />
          Get the <b>full report</b> to really understand your technical abilities.
        </span>
      </div>
      <div className="mt-12">
        <AppButton onClick={onStart}>Start now</AppButton>
      </div>
    </div>
  )
}

export default TechProfileStartCard
