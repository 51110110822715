// FIXME remove this tooltip
import { Tooltip } from '@material-ui/core'
import { FC } from 'react'

export interface DotProps {
  color?: string
  label?: string
}

export const Dot: FC<DotProps> = ({ color, label }) => {
  return label ? (
    <Tooltip title={label} arrow classes={{ tooltip: 'dot-tooltip', arrow: 'dot-tooltip-arrow' }}>
      <div className="app-dot" style={{ backgroundColor: color }} />
    </Tooltip>
  ) : (
    <div className="app-dot" style={{ backgroundColor: color }} />
  )
}

export default Dot
