import React from 'react'
import { TutorialsStorageConfiguration } from './HelpContent'

interface IntroductionContentI {
  title: string
  subtitle: string
  body: React.ReactNode
  next?: IntroductionContentKeys
  previous?: IntroductionContentKeys
  skip?: boolean
  finishKey?: TutorialsStorageConfiguration
  finish?: boolean
}
export const IntroductionContent: Record<string, IntroductionContentI> = {
  'introduction-step-1': {
    title: 'Individual reports',
    subtitle: 'Step 1/5',
    body: `In the Reviews page you will find your team member profiles to view reports and create new assessments.`,
    next: 'introduction-step-2',
    skip: true,
    finishKey: TutorialsStorageConfiguration.INTRODUCTION_TUTORIAL_COMPLETED,
  },
  'introduction-step-2': {
    title: 'Add more reviewers',
    subtitle: 'Step 2/5',
    body: `The (+) button lets you invite other teammates to assess specific people in your team, and obtain new perspectives.`,
    next: 'introduction-step-3',
    previous: 'introduction-step-1',
    skip: true,
    finishKey: TutorialsStorageConfiguration.INTRODUCTION_TUTORIAL_COMPLETED,
  },
  'introduction-step-3': {
    title: 'Important alerts',
    subtitle: 'Step 3/5',
    body: `Profiles will show alert icons ( ✈ ⚠️ 🎸) to flag important issues. Use the tabs to focus on specific alert types.`,
    next: 'introduction-step-4',
    previous: 'introduction-step-2',
    skip: true,
    finishKey: TutorialsStorageConfiguration.INTRODUCTION_TUTORIAL_COMPLETED,
  },
  'introduction-step-4': {
    title: 'Teams overview',
    subtitle: 'Step 4/5',
    body: `Review all your teams together, spot issues, and access specific team reports.`,
    next: 'introduction-step-5',
    previous: 'introduction-step-3',
    skip: true,
    finishKey: TutorialsStorageConfiguration.INTRODUCTION_TUTORIAL_COMPLETED,
  },
  'introduction-step-5': {
    title: `New assessment`,
    subtitle: 'Step 5/5',
    body: `Click on the Start button to begin assessing your team members.`,
    previous: 'introduction-step-4',
    skip: false,
    finishKey: TutorialsStorageConfiguration.INTRODUCTION_TUTORIAL_COMPLETED,
    finish: true,
  },
}

export type IntroductionContentKeys = keyof typeof IntroductionContent
