import Validator from './Validator'

export default class FormField<Type> {
  key: string
  validators: Validator<Type>[]
  value: Type
  onChange: undefined | ((value: Type) => void)
  errorMessages: string[] = []
  hasError = false
  constructor(key: string, value: Type, validators: Validator<Type>[] = [], onChange?: (value: Type) => void) {
    this.key = key
    this.value = value
    this.validators = validators
    this.onChange = onChange
  }

  validate = (): void => {
    this.hasError = false
    this.errorMessages = []
    this.validators.forEach(validator => {
      if (!validator.validate(this.value)) {
        this.errorMessages.push(validator.getErrorMessage())
        this.hasError = true
      }
    })
  }

  handleChange = (value: Type): void => {
    this.value = value
    this.validate()
    this.onChange && this.onChange(value)
  }
}
