interface Indexable {
  [key: string]: ValidatorOptions<any>
}
const emailRegex = new RegExp(/[^\s@]+@+[^\s@.,]+\.+[^\s@,]*[^\s@,.]{2,}$/)
const DefaultValidators = {
  required: {
    errorMessage: 'This field is required',
    validationFunction: (value: any) => {
      return value !== undefined && value !== '' && (typeof value !== 'object' || value.length > 0)
    },
  },
  email: {
    errorMessage: 'Please write a valid email',
    validationFunction: (value: any) => {
      const isEmail = emailRegex.test(value)
      return typeof value === 'string' && isEmail
    },
  },
} as Indexable

interface ValidatorOptions<Type> {
  label?: string
  errorMessage?: string
  validationFunction?: (value: Type) => boolean
}
export default class Validator<Type> {
  hasError = false
  type!: string
  options?: ValidatorOptions<Type>
  constructor(type: string, options?: ValidatorOptions<Type>) {
    this.type = type
    this.options = { ...DefaultValidators[type], ...options }
  }

  validate = (value: Type): boolean => {
    this.hasError = this.options?.validationFunction ? this.options?.validationFunction(value) : true
    return this.hasError
  }

  getErrorMessage = (): string => {
    return this.options?.errorMessage || ''
  }
}
