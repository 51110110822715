import LoginPage from '../components/pages/LoginPage'
import LoginTokenPage from '../components/pages/LoginTokenPage'
import Charts from '../components/pages/Charts'
import { EmployeeReview } from '../components/pages/EmployeeReview'
import { inDevMode } from './env.service'
import { NetworkConstants } from './NetworkConstants'
import ChangePasswordPage from '../components/pages/ChangePasswordPage'
import { EmployeeResults } from '../components/pages/EmployeeResults'
import AdminPanelPage from '../components/pages/AdminPanelPage'
import { TeamSummary } from '../components/pages/TeamSummary'
import CreateAccount from '../components/pages/CreateAccount'
import { TechEvaluation } from '../components/pages/TechEvaluation'
import ReviewFinishedPage from '../components/pages/ReviewFinishedPage'
import Unauthorized from '../components/pages/Unauthorized'
import IntroductionPage from '../components/pages/IntroductionPage'
import ReviewsPage from '../components/pages/ReviewsPage'
import { TechProfile } from '../components/pages/TechProfile'
import TeamIssuesPage from '../components/pages/TeamIssuesPage'
import { FindResources } from '../components/pages/FindResources'
import SSOProcess from '../components/pages/SSOProcess'
import UsageReport from '../components/pages/Dashboard'
import { FaqPage } from '../components/pages/FaqPage'
import PeriodManagement from '../components/pages/PeriodManagement'

// TODO: The Roles are not related justs to routes, so we might one to move
// this enum to a different class (UserInfo maybe?).  Also we need to replace
// this roles with the right ones defined on server side

export enum Roles {
  ADMIN_ROLE = 'admin',
  REVIEWER_ROLE = 'reviewer',
  USER_ROLE = 'user',
}

export interface Route {
  path: string
  component: () => JSX.Element
  roles: Roles[]
  fullPage?: boolean
}

const chartsTestingRoute: Route = {
  path: NetworkConstants.URL_CHARTS,
  component: () => <Charts />,
  roles: [Roles.ADMIN_ROLE],
  fullPage: true,
}

const defaultRoute: Route = {
  path: NetworkConstants.URL_LOGIN,
  component: () => <LoginPage />,
  roles: [],
  fullPage: true,
}

const loginTokenRoute: Route = {
  path: NetworkConstants.URL_LOGIN_TOKEN,
  component: () => <LoginTokenPage />,
  roles: [],
  fullPage: true,
}

const adminPanelRoute: Route = {
  path: NetworkConstants.URL_ADMIN_PANEL,
  component: () => <AdminPanelPage />,
  roles: [Roles.ADMIN_ROLE],
}

const createAccountRoute: Route = {
  path: NetworkConstants.URL_CREATE_ACCOUNT,
  component: () => <CreateAccount />,
  roles: [Roles.ADMIN_ROLE],
}

const reviewListRoute: Route = {
  path: NetworkConstants.URL_REVIEW_LIST,
  component: () => <ReviewsPage />,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE],
}

const newReviewRoute: Route = {
  path: NetworkConstants.URL_NEW_REVIEW,
  component: () => <EmployeeReview />,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE, Roles.USER_ROLE],
}

const editReviewRoute: Route = {
  path: NetworkConstants.URL_EDIT_REVIEW,
  component: EmployeeReview,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE, Roles.USER_ROLE],
}

const reportRoute: Route = {
  path: NetworkConstants.URL_REPORT,
  component: EmployeeResults,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE],
}

const reportWithFormRoute: Route = {
  path: NetworkConstants.URL_REPORT_FORM,
  component: EmployeeResults,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE],
}

const changePasswordRoute: Route = {
  path: NetworkConstants.URL_CHANGE_PASSWORD,
  component: () => <ChangePasswordPage />,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE, Roles.USER_ROLE],
}

const teamSummaryRoute: Route = {
  path: NetworkConstants.URL_TEAM_SUMMARY,
  component: TeamSummary,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE],
}

const reviewFinishedRoute: Route = {
  path: NetworkConstants.URL_REVIEW_FINISHED_MSG,
  component: () => <ReviewFinishedPage />,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE, Roles.USER_ROLE],
}

const unauthorizedRoute: Route = {
  path: NetworkConstants.URL_UNAUTHORIZED,
  component: Unauthorized,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE, Roles.USER_ROLE],
}

const introductionRoute: Route = {
  path: NetworkConstants.URL_INTRODUCTION,
  component: IntroductionPage,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE, Roles.USER_ROLE],
}

const techEvaluationRoute: Route = {
  path: NetworkConstants.URL_TECH_EVALUATION,
  component: TechEvaluation,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE, Roles.USER_ROLE],
}

const teamsDashboardRoute: Route = {
  path: NetworkConstants.URL_TEAMS_DASHBOARD,
  component: TeamIssuesPage,
  roles: [Roles.ADMIN_ROLE],
}

const techProfileRoute: Route = {
  path: NetworkConstants.URL_TECH_PROFILE,
  component: TechProfile,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE, Roles.USER_ROLE],
}

const findResourcesRoute: Route = {
  path: NetworkConstants.URL_FIND_RESOURCES,
  component: FindResources,
  roles: [Roles.ADMIN_ROLE],
}

const ssoProcessRoute: Route = {
  path: NetworkConstants.URL_SSO_PROCESS,
  component: SSOProcess,
  roles: [],
  fullPage: true,
}

const usageReportRoute: Route = {
  path: NetworkConstants.URL_USAGE_REPORT,
  component: UsageReport,
  roles: [Roles.ADMIN_ROLE],
}

const dashboardRoute: Route = {
  path: NetworkConstants.URL_DASHBOARD,
  component: UsageReport,
  roles: [Roles.ADMIN_ROLE],
}

const periodManagementRoute: Route = {
  path: NetworkConstants.URL_PERIOD_MANAGEMENT,
  component: PeriodManagement,
  roles: [Roles.ADMIN_ROLE],
}

const faqPageRoute: Route = {
  path: NetworkConstants.URL_FAQ,
  component: FaqPage,
  roles: [],
  fullPage: true,
}

const faqLoggedPageRoute: Route = {
  path: NetworkConstants.URL_FAQ_LOGGED,
  component: FaqPage,
  roles: [Roles.ADMIN_ROLE, Roles.REVIEWER_ROLE, Roles.USER_ROLE],
}

const devRoutes = inDevMode() ? [chartsTestingRoute] : []

const routes: Route[] = [
  loginTokenRoute,
  defaultRoute,
  createAccountRoute,
  adminPanelRoute,
  reviewListRoute,
  newReviewRoute,
  editReviewRoute,
  reportWithFormRoute,
  reportRoute,
  changePasswordRoute,
  teamSummaryRoute,
  reviewFinishedRoute,
  unauthorizedRoute,
  introductionRoute,
  techEvaluationRoute,
  techProfileRoute,
  teamsDashboardRoute,
  findResourcesRoute,
  ssoProcessRoute,
  usageReportRoute,
  dashboardRoute,
  periodManagementRoute,
  faqPageRoute,
  faqLoggedPageRoute,
  ...devRoutes,
]

export { defaultRoute, reviewListRoute, loginTokenRoute }
export default routes
