import { FC } from 'react'
import classNames from 'classnames'

import CheckSVG from './Icons/Check.svg'

export interface CheckBoxParams {
  label?: string
  value: unknown
  checked: boolean
  unfinished?: boolean
  disabled?: boolean
  onChecked: (value: unknown) => void
  checkedStyle: boolean
  extraLabel?: string
}

// FIXME why on earth we have a hidden SVG element in there?
export const CheckBox: FC<CheckBoxParams> = ({
  label,
  value,
  checked,
  disabled = false,
  onChecked,
  unfinished,
  checkedStyle,
  extraLabel,
}) => {
  const onClickHandler = (value: unknown) => {
    if (!disabled) onChecked(value)
  }

  return (
    <div className="check-grp" onClick={() => onClickHandler(value)}>
      <div
        className={classNames({
          check: true,
          'check--checked': checked,
          'check--unfinished': !checked && unfinished,
          'check--disabled': disabled,
        })}
      >
        <CheckSVG color="#FFFFFF" />
      </div>
      {label && (
        <>
          <label
            className={classNames({
              'text-dark-blue': checked || (!checked && checkedStyle),
              'text-toast-hover': (!checked && !checkedStyle) || disabled,
            })}
          >
            {label}
          </label>
          <label className="text-dark-grey text-base">{extraLabel}</label>
        </>
      )}
    </div>
  )
}
