import SuccessMessage from '../organisms/SuccessMessage'

const ReviewFinishedPage = (): JSX.Element => {
  return (
    <SuccessMessage
      className="mt-32"
      title={
        <>
          Review successfully <br />
          completed!
        </>
      }
      body={
        <>
          We will notify the person who <br />
          requested the review by email. Thank you!
        </>
      }
    />
  )
}

export default ReviewFinishedPage
