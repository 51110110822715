import { AppButton } from '../atoms/AppButton/AppButton'
import SuccessIcon from '../atoms/Icons/SuccessIcon.svg'

import { Popup } from './Popup'

type TutorialModalProps = {
  visible: boolean
  icon?: boolean
  message?: JSX.Element | React.ReactElement
  startButtonText?: string
  skipButtonText?: string
  onClose: () => void
  skipTutorial: () => void
}

export const TutorialModal = ({
  visible,
  icon = true,
  message,
  startButtonText = 'Start tutorial',
  skipButtonText = 'Skip tutorial',
  onClose,
  skipTutorial,
}: TutorialModalProps): JSX.Element => {
  return (
    <Popup
      visible={visible}
      onClose={onClose}
      showCloseButton={false}
      classname="p-8"
      containerClassName="flex items-center max-h-[90vh] max-w-[90vw] overflow-auto"
    >
      <div className="modal-body">
        {icon && <SuccessIcon />}
        {message && <div className="modal-text">{message}</div>}
      </div>
      <div className="modal-footer flex flex-col items-center gap-6">
        <AppButton type="info" onClick={onClose} className="font-light w-1/3">
          <b>{startButtonText}</b> · 1 min
        </AppButton>
        <AppButton type="clear" onClick={skipTutorial} className="!bg-white w-1/3">
          {skipButtonText}
        </AppButton>
      </div>
    </Popup>
  )
}
